import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplaySwitch } from 'components/settings/SettingDisplaySwitch';
import { SettingDisplayText } from 'components/settings/SettingDisplayText';

export const ComtrexSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({ settings, locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplayText title="Vendor Name" text={settings?.comtrex?.vendorName} />
                <SettingDisplayText
                    title="Loyalty At POS Brand Name"
                    text={settings?.comtrex?.loyaltyAtPosBrandName}
                />
                <SettingDisplaySwitch
                    title="Route Traffic Via Proxy"
                    checked={settings?.comtrex?.proxyEnabled ?? true}
                />
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <>
                <SettingDisplayText title="Host" text={settings?.comtrex?.host} />
                <SettingDisplayText title="Payment Type Id" text={settings?.comtrex?.paymentTypeId} />
                <SettingDisplayText title="Tip Item Number" text={settings?.comtrex?.tipItemNumber} />
                <SettingDisplayText
                    title="Note Fire Message ID"
                    text={settings?.comtrex?.noteFireMessageId}
                />
                <SettingDisplayText
                    title="Allergen List Number"
                    text={settings?.comtrex?.allergenListNumber}
                />
                <SettingDisplaySwitch
                    title="Map Allergen As Modifier"
                    checked={settings?.comtrex?.mapAllergenAsCondiment}
                />
                <SettingDisplayText
                    title="Order To Table Sales Type Number"
                    text={settings?.comtrex?.ORDER_TO_TABLE_salesTypeNumber}
                />
                <SettingDisplayText
                    title="Pre-Order Sales Type Number"
                    text={settings?.comtrex?.PREORDER_salesTypeNumber}
                />
                <SettingDisplayText
                    title="Table Sales Type Number"
                    text={settings?.comtrex?.TABLE_salesTypeNumber}
                />
                <SettingDisplayText
                    title="Tab Sales Type Number"
                    text={settings?.comtrex?.TAB_salesTypeNumber}
                />
                <SettingDisplayText
                    title="Loyalty At POS Store Id"
                    text={settings?.comtrex?.loyaltyAtPosStoreId}
                />
                <SettingDisplayText
                    title="Loyalty At POS Password"
                    text={settings?.comtrex?.loyaltyAtPosPassword}
                />
            </>
        )}
        {/* Both */}
        <SettingDisplayText
            title="Filter for Menu Category Ids"
            text={settings?.comtrex?.menuCategoryFilter}
            crop
        />
    </>
);
