import * as React from 'react';
import { Checkbox, MenuItem, Select, SelectChangeEvent, Switch, TextField, Typography } from '@mui/material';
import get from 'get-value';
import { ChipInput } from 'lib/ChipInput';
import { DateRangePickerInput } from 'lib/DateRangePickerInput';
import { MuiImagePicker } from 'lib/image-picker';
import { FormItem } from 'lib/LegacyForm';
import { MuiGeosuggest } from 'lib/MuiGeosuggest';
import { MultiSelectSuggest } from 'lib/MultiSelectSuggest';
import { OpeningHoursInput } from 'lib/OpeningHoursInput';
import { Option } from 'lib/types';
import { Column } from 'ui/Flex';
import { KeyFileInput } from './fields/KeyFileInput';
import { SortableField } from './fields/SortableField';
import { AutoFormFieldWithLabel } from './AutoFormFieldWithLabel';
import { MuiColorPicker, PrefixInput } from './fields';
import { RequiredStar } from './RequiredStar';
import { isNumber } from 'lib/typeguards';

export type AutoFormFieldProps = FormItem & {
    onChange: (name: string, value: any) => void;
    onBlur?: (name: string, value: any) => void;
    defaultValue?: Option & string;
    name: string;
    error?: string;
    disabled?: boolean;
    onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    value: string & Option & Option[];
};

export class AutoFormField extends React.PureComponent<AutoFormFieldProps> {
    handleChange = (value: any) => {
        this.props.onChange(this.props.name, value);
        if (this.props.onBlur) {
            this.props.onBlur(this.props.name, value);
        }
    };
    handleTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.onChange(this.props.name, event.target.value);
        if (this.props.onBlur) {
            this.props.onBlur(this.props.name, event.target.value);
        }
    };
    handleSelectChange = (event: SelectChangeEvent<HTMLSelectElement>) => {
        this.props.onChange(this.props.name, event.target.value);
        if (this.props.onBlur) {
            this.props.onBlur(this.props.name, event.target.value);
        }
    };
    handleControlChange = (_: never, checked: boolean) => {
        this.props.onChange(this.props.name, checked);
        if (this.props.onBlur) {
            this.props.onBlur(this.props.name, checked);
        }
    };
    render() {
        const {
            label,
            type,
            inputType,
            FormComponent,
            fields,
            itemForm,
            error,
            onKeyDown,
            builtInLabel,
            onBlur: _onBlur,
            ...rest
        } = this.props;
        const required = rest.validation && rest.validation.length && rest.validation.includes('required');
        let field;
        switch (type) {
            case 'select':
                field = (
                    <Select fullWidth {...rest} onChange={this.handleSelectChange}>
                        {rest.options &&
                            rest.options.map(({ description, value, label: optionLabel }, index) => (
                                <MenuItem key={`select-${index}-${value}`} value={value}>
                                    {description ? (
                                        <Column>
                                            {optionLabel}
                                            <Typography variant="caption">{description}</Typography>
                                        </Column>
                                    ) : (
                                        optionLabel
                                    )}
                                </MenuItem>
                            ))}
                    </Select>
                );
                break;
            case 'searchableSelect':
                field = (
                    <MultiSelectSuggest
                        isMulti={false}
                        label={label}
                        options={rest.options}
                        {...rest}
                        onChange={this.handleChange}
                    />
                );
                break;
            case 'multiSelect':
                field = (
                    <MultiSelectSuggest
                        label={label}
                        options={rest.options}
                        {...rest}
                        onChange={this.handleChange}
                    />
                );
                break;
            case 'checkbox':
                field = <Checkbox {...rest} onChange={this.handleControlChange} checked={!!rest.value} />;
                break;
            case 'switch':
                field = (
                    <Switch
                        color="primary"
                        {...rest}
                        checked={!!rest.value}
                        onChange={this.handleControlChange}
                    />
                );
                break;
            case 'openingHoursInput':
                field = <OpeningHoursInput value={rest.value} label={label} onChange={this.handleChange} />;
                break;
            case 'number':
                field = (
                    <TextField
                        fullWidth
                        onKeyDown={onKeyDown}
                        type="number"
                        label={
                            builtInLabel ? (
                                <React.Fragment>
                                    {label}
                                    {required && !rest.disabled && <RequiredStar />}
                                </React.Fragment>
                            ) : undefined
                        }
                        {...rest}
                        onChange={this.handleTextChange}
                    />
                );
                break;
            case 'keyFile':
                field = (
                    <KeyFileInput
                        label={label}
                        fullWidth
                        value={rest.value}
                        onKeyChange={this.handleChange}
                    />
                );
                break;
            case 'multiline':
                field = (
                    <TextField
                        fullWidth
                        error={!!error}
                        label={
                            <React.Fragment>
                                {label}
                                {required && !rest.disabled && <RequiredStar />}
                            </React.Fragment>
                        }
                        multiline
                        maxRows="4"
                        {...rest}
                        onChange={this.handleTextChange}
                    />
                );
                break;
            case 'color':
                field = (
                    <MuiColorPicker
                        {...rest}
                        onChange={this.handleChange}
                        value={rest.value}
                        fallbackToDefaultColour={this.props.fallbackToDefaultColour}
                    />
                );
                break;
            case 'geo':
                field = (
                    <MuiGeosuggest
                        {...rest}
                        onChange={null}
                        value={rest.value}
                        onSuggestSelect={this.handleChange}
                    />
                );
                break;
            case 'image':
                field = (
                    <MuiImagePicker
                        {...rest}
                        width={isNumber(rest.width) ? rest.width : undefined}
                        url={rest.value}
                        onSelect={this.handleChange}
                    />
                );
                break;
            case 'sortableField':
                field = (
                    <SortableField
                        fields={fields}
                        itemForm={itemForm}
                        FormComponent={FormComponent}
                        {...rest}
                        onChange={this.handleChange}
                    />
                );
                break;
            case 'label':
                field = (
                    <Typography component="div" style={{ width: '100%' }} variant="caption">
                        {label}
                    </Typography>
                );
                break;
            case 'chips':
                field = (
                    <ChipInput label={label} options={rest.options} {...rest} onChange={this.handleChange} />
                );
                break;
            case 'prefixInput':
                field = (
                    <TextField
                        fullWidth
                        onKeyDown={onKeyDown}
                        error={!!error}
                        {...rest}
                        label={
                            <React.Fragment>
                                {label}
                                {required && !rest.disabled && <RequiredStar />}
                            </React.Fragment>
                        }
                        type={inputType}
                        onChange={this.handleTextChange}
                        inputProps={{ prefix: get(rest, 'prefix') }}
                        InputProps={{
                            inputComponent: PrefixInput
                        }}
                    />
                );
                break;
            case 'dateRangePicker':
                field = <DateRangePickerInput {...this.props} onChange={this.handleChange} disablePast />;
                break;
            default:
                field = (
                    <TextField
                        fullWidth
                        onKeyDown={onKeyDown}
                        error={!!error}
                        {...rest}
                        label={
                            <React.Fragment>
                                {label}
                                {required && !rest.disabled && <RequiredStar />}
                            </React.Fragment>
                        }
                        type={inputType}
                        onChange={this.handleTextChange}
                    />
                );
                break;
        }
        return (
            <AutoFormFieldWithLabel
                {...rest}
                fieldType={type}
                required={required}
                label={label}
                error={error}
                builtInLabel={builtInLabel}
            >
                {field}
            </AutoFormFieldWithLabel>
        );
    }
}
