import { Grid } from '@mui/material';
import { TieredLoyaltyStateModal } from 'components/metrics/modals/TieredLoyaltyStateModal';
import { TieredLoyaltyMetrics } from 'components/metrics/tiered-loyalty/TieredLoyaltyMetrics';
import { TieredLoyaltyOverview } from 'components/metrics/tiered-loyalty/TieredLoyaltyOverview';
import { TieredLoyaltyTiers } from 'components/metrics/tiered-loyalty/TieredLoyaltyTiers';
import { useTieredLoyaltyTiers } from 'components/metrics/hooks/useLoyaltyTiers';
import { usePremiumLoyaltyStateModal } from 'components/metrics/hooks/usePremiumLoyaltyStateModal';
import { useTieredLoyaltyEditModal } from 'components/metrics/tiered-loyalty/hooks/useTieredLoyaltyEditModal';
import {
    EPremiumLoyaltyScheme,
    useUserMetricDefinition
} from 'components/metrics/hooks/useUserMetricDefinition';
import { MainLayout } from 'layouts/MainLayout';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAudiences } from 'store/audiences/audiencesActions';
import { getAllLocations } from 'store/locations/locationsActions';
import { getAllPerks } from 'store/perks/perksActions';
import { ApplicationState } from 'store/store';
import { EditPremiumLoyaltyModal } from 'components/metrics/modals/EditPremiumLoyaltyModal';
import { UserMetricDefinition } from 'components/metrics/model';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_TIERED_LOYALTY_STATE_EDIT_ERROR,
    MESSAGE_TIERED_LOYALTY_STATE_EDIT_SUCCESS
} from 'config/messages';

export const TieredLoyalty = () => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: ApplicationState) => state.audiences);
    const {
        metricDefinition,
        isSetup,
        state,
        isValidMetricDefinition,
        setMetricDefinition,
        finishSetup,
        loading: metricDefinitionLoading,
        loadPremiumLoyalty
    } = useUserMetricDefinition(EPremiumLoyaltyScheme.Tier);
    const { tiers } = useTieredLoyaltyTiers(metricDefinition);
    const handleStateUpdateSuccess = React.useCallback(
        (metric: UserMetricDefinition) => {
            setMetricDefinition(metric);
            dispatch(enqueueSnackbar(MESSAGE_TIERED_LOYALTY_STATE_EDIT_SUCCESS, { variant: 'success' }));
        },
        [dispatch, setMetricDefinition]
    );
    const handleStateUpdateError = React.useCallback(() => {
        dispatch(enqueueSnackbar(MESSAGE_TIERED_LOYALTY_STATE_EDIT_ERROR, { variant: 'error' }));
    }, [dispatch]);
    const {
        stateModalOpen,
        handleStateChangeOpen,
        handleStateChangeClose,
        handleStateChangeSubmit,
        loading: stateChangeLoading
    } = usePremiumLoyaltyStateModal(metricDefinition, handleStateUpdateError, handleStateUpdateSuccess);
    const {
        editModalOpen,
        handleDefinitionEditOpen,
        handleDefinitionEditClose,
        handleDefinitionEditSubmit,
        loading: editLoading
    } = useTieredLoyaltyEditModal(metricDefinition, setMetricDefinition, finishSetup);
    React.useEffect(() => {
        getAllAudiences()(dispatch);
        getAllPerks()(dispatch);
        getAllLocations()(dispatch);
    }, [dispatch]);
    const tiersLoading = React.useMemo(
        () => metricDefinitionLoading || isLoading,
        [metricDefinitionLoading, isLoading]
    );
    return (
        <MainLayout
            title="Tier Schemes"
            pageName="Tier Schemes"
            pageDescription="Audience based loyalty tiers"
        >
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} lg={4}>
                    <TieredLoyaltyOverview
                        metricDefinition={metricDefinition}
                        loading={metricDefinitionLoading}
                        onEdit={handleDefinitionEditOpen}
                        onStateChange={handleStateChangeOpen}
                        readyToActivate={isValidMetricDefinition}
                        state={state}
                    />
                </Grid>
                <Grid item xs={12} lg={8}>
                    <TieredLoyaltyMetrics
                        metricDefinition={metricDefinition}
                        loading={metricDefinitionLoading}
                        onDefinitionUpdate={setMetricDefinition}
                    />
                </Grid>
            </Grid>
            <TieredLoyaltyTiers
                tiers={tiers}
                loading={tiersLoading}
                loadPremiumLoyalty={loadPremiumLoyalty}
                metricDefinition={metricDefinition}
                onDefinitionUpdate={setMetricDefinition}
            />
            <EditPremiumLoyaltyModal
                open={editModalOpen || isSetup}
                onClose={handleDefinitionEditClose}
                onSubmit={handleDefinitionEditSubmit}
                loading={editLoading}
                metricDefinition={metricDefinition}
                scheme={EPremiumLoyaltyScheme.Tier}
            />
            <TieredLoyaltyStateModal
                open={stateModalOpen}
                onClose={handleStateChangeClose}
                loading={stateChangeLoading}
                onSubmit={handleStateChangeSubmit}
                tierState={state}
                readyToActivate={isValidMetricDefinition}
            />
        </MainLayout>
    );
};
