import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { titleize } from 'lib/helpers';
import { LegacyForm } from 'lib/LegacyForm';
import { AutoForm } from 'ui/form/AutoForm';
import {
    createModuleForm,
    transformFormFieldToModuleFields,
    transformModuleToFormFields
} from '../forms/createModuleForm';
import { ContentModule, ContentModuleType, EHeightOption } from '../models/ContentModule';
import { Row } from 'ui/Flex';
import { NEW_MODULE_ID } from 'store/content/contentActions';
import { RowCopy } from 'lib/RowCopy';

interface ModuleFormProps {
    module: ContentModule;
    moduleId: string;
    handleSubmit: () => void;
    updateCurrentModuleProperties: (fieldName: string, value: any, id: string) => void;
    onDelete: (id: string) => void;
}

export const ModuleForm: React.FunctionComponent<ModuleFormProps> = ({
    module,
    moduleId,
    onDelete,
    handleSubmit,
    updateCurrentModuleProperties
}) => {
    const isReady = !!createModuleForm(module.type);
    const [display, setDisplay] = React.useState(module.display);
    const [formData, setFormData] = React.useState(null);
    const form = new LegacyForm(createModuleForm(module.type, { display }), handleSubmit).update({
        ...transformModuleToFormFields(module)
    });

    const handleBlur = React.useCallback(
        (name: string, value: any) => {
            setFormData({ ...formData, [name]: value });
            if (name === 'display') {
                setDisplay({ height: value });
            }
            const transformedFields = transformFormFieldToModuleFields(
                module,
                name,
                value,
                form.getFormData()
            );
            transformedFields.forEach(({ name, value }) =>
                updateCurrentModuleProperties(name, value, moduleId)
            );
        },
        [form, formData, module, moduleId, updateCurrentModuleProperties]
    );

    React.useEffect(() => {
        setDisplay(module.display);
    }, [module.display]);

    const handleDelete = React.useCallback(() => {
        onDelete(moduleId);
    }, [onDelete, moduleId]);

    const buttonDisabled = React.useMemo(() => {
        const formData = form.getFormData();
        if (module.type === ContentModuleType.MARKETING && !!display) {
            if (display.height === EHeightOption.LARGE_SQUARE) {
                return formData.items?.length < 2;
            }

            if (display?.height === EHeightOption.SMALL_SQUARE) {
                return formData.items?.length < 3;
            }
        }
    }, [display, form, module.type]);

    const renderButtons = React.useCallback(
        (submitButton: JSX.Element) => (
            <Row align="space-between">
                <Row>
                    <Button variant="outlined" color="error" onClick={handleDelete}>
                        Delete Module
                    </Button>
                </Row>
                {submitButton}
            </Row>
        ),
        [handleDelete]
    );

    if (!isReady) {
        return <Typography>Sorry, work in progress</Typography>;
    }

    return (
        <Box padding={2}>
            <AutoForm
                buttonDisabled={buttonDisabled}
                onBlur={handleBlur}
                form={form}
                submitLabel="Save"
                renderButtons={renderButtons}
                title={`Configure your ${titleize(module.type.replace(/_/g, ' ').toLowerCase())} module`}
            >
                {(fields, render) => (
                    <>
                        {!!module.id && module.id !== NEW_MODULE_ID && (
                            <Grid container spacing={2} ml={0}>
                                <Grid item xl={12} xs={12}>
                                    <RowCopy value={module.id} text="ID" variant="subtitle2" />
                                </Grid>
                            </Grid>
                        )}
                        {render(fields)}
                    </>
                )}
            </AutoForm>
        </Box>
    );
};
