import { Box, Paper, TablePagination } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { DisputeReasonCell } from 'components/pepper-pay/disputes/DisputeReasonCell';
import { DisputesFilters } from 'components/pepper-pay/disputes/DisputesFilter';
import { DisputeStatusCell } from 'components/pepper-pay/disputes/DisputeStatusCell';
import {
    DISPUTES_PAGE_LIMIT,
    usePepperDisputes
} from 'components/pepper-pay/disputes/hooks/usePepperDisputes';
import { usePepperDisputesFilter } from 'components/pepper-pay/disputes/hooks/usePepperDisputesFilter';

import { PEPPER_PAY_DISPUTES_VIEW } from 'config/routes';
import { MainLayout } from 'layouts/MainLayout';
import { defaultDateTimeValueFormatter } from 'lib/MuiGrid/helpers';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAllLocations } from 'store/locations/locationsActions';

export const DisputesPage: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [filter, onFilterChange] = usePepperDisputesFilter();
    const { formattedDisputes, page, onPageChange, loading, count } = usePepperDisputes(filter);
    React.useEffect(() => {
        getAllLocations()(dispatch);
    }, [dispatch]);
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'ID',
                field: 'transactionId',
                disableReorder: true,
                width: 300
            },
            {
                headerName: 'Amount',
                field: 'amount',
                type: 'number',
                width: 130,
                sortable: false,
                headerAlign: 'left'
            },
            {
                headerName: 'Status',
                field: 'status',
                width: 160,
                sortable: false,
                renderCell: DisputeStatusCell
            },
            {
                headerName: 'Reason',
                field: 'reason',
                width: 210,
                sortable: false,
                renderCell: DisputeReasonCell
            },
            {
                headerName: 'Disputed on',
                field: 'date',
                type: 'dateTime',
                sortable: false,
                width: 180,
                valueGetter: defaultDateTimeValueFormatter
            },
            {
                headerName: 'Respond by',
                field: 'dueBy',
                type: 'dateTime',
                sortable: false,
                width: 180,
                valueGetter: defaultDateTimeValueFormatter
            },
            {
                headerName: 'Customer ID',
                field: 'customerId',
                width: 210,
                sortable: false,
                hide: true
            },
            {
                headerName: 'Intent ID',
                field: 'paymentIntentId',
                width: 260,
                sortable: false,
                hide: true
            },
            {
                headerName: 'Charge ID',
                field: 'paymentChargeId',
                width: 260,
                sortable: false,
                hide: true
            }
        ],
        []
    );
    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            onPageChange(page);
        },
        [onPageChange]
    );
    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={count}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={DISPUTES_PAGE_LIMIT}
                rowsPerPageOptions={[DISPUTES_PAGE_LIMIT]}
                component="div"
            />
        ),
        [count, handlePageChange, page]
    );
    const handleRowClick = React.useCallback(
        (params: GridRowParams) => {
            history.push(
                PEPPER_PAY_DISPUTES_VIEW.replace(':transactionId', String(params?.row.transactionId))
            );
        },
        [history]
    );
    return (
        <MainLayout pageName="Disputes" pageDescription="View and interact with disputes" noScroll>
            <Box paddingBottom={1}>
                <DisputesFilters filter={filter} onChange={onFilterChange} />
            </Box>
            <Paper sx={{ flex: 1, display: 'flex', minHeight: 0 }}>
                <MuiGrid
                    rows={formattedDisputes}
                    columns={columns}
                    disableMultipleRowSelection
                    hideFooter={false}
                    hideFooterRowCount
                    pagination
                    paginationMode="server"
                    loading={loading}
                    disableColumnFilter
                    enableRowClick
                    onRowClick={handleRowClick}
                    slots={{
                        pagination: Pagination
                    }}
                    storageName={GridStorageName.Disputes}
                />
            </Paper>
        </MainLayout>
    );
};
