import { Box } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { CategoryChange, MenuChangeSchema, ProductChange, TaxChange } from '@pepperhq/menu-sdk';
import { menuApi } from 'components/menu/MenuApi';
import { MultiSelectGridColumn } from 'lib/MuiGrid/Columns';
import { EMuiGridStatus, MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React, { useContext } from 'react';
import {
    BaseMenuTab,
    getTaxesData,
    getTaxesToCopy,
    scenarioLabel,
    TaxesData,
    useMenuChange
} from '../model/menu';
import merge from 'deepmerge';
import * as Yup from 'yup';
import { useLeavePageBlock } from 'lib/hooks/useLeavePageBlock';
import { useDispatch } from 'react-redux';
import logger from 'lib/logger';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_TAXES_UPDATE_ERROR, MESSAGE_TAXES_UPDATE_SUCCESS } from 'config/messages';
import { TaxCategoriesGridColumn } from '../taxes/TaxCategories';
import { TaxProductsGridColumn } from '../taxes/TaxProducts';
import { useMenuGridSearch } from '../model/useMenuGridSearch';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { isEmptyString } from 'lib/typeguards';
import { LocationsContext, useTabCopy } from '../useCopyTab';
import { ActionsHeader } from '../ActionsHeader';
import { useMenuSelection } from '../useMenuSelection';
import { useMenuDeleteControls } from '../model/useMenuDeleteControls';

type TaxesTabProps = BaseMenuTab;

const validationSchema = {
    title: Yup.string().required('Required'),
    rate: Yup.number().min(0, 'Between 0 to 100').max(100, 'Between 0 to 100').required('Required')
};

export const TaxesTab: React.FC<TaxesTabProps> = ({
    menu,
    menuChangeId,
    loadFreshMenu,
    onUpdate,
    change: externalChange,
    search,
    onSearchChange
}) => {
    const locations = useContext(LocationsContext);
    const dispatch = useDispatch();
    const {
        change: [change, setChange],
        readyToSave,
        handleCancel,
        handleStatusChange,
        gridRef
    } = useMenuChange(externalChange);

    const {
        selectedIds,
        resetSelection,
        selectedCell,
        handleSelectChange,
        handleCellSelection,
        copyEnabled,
        handleCellBlur
    } = useMenuSelection(!!locations.length);

    const { toggleIsCopyModalOpen, setMenuChangeToCopy, activeOperationId, renderLocationPickerModal } =
        useTabCopy(resetSelection, selectedCell, ['applyForScenario']);

    const {
        editedOrDeleted,
        handleCancelEditing,
        handleDelete,
        deleted,
        deleteEnabled,
        resetDeleted,
        isCellEditable
    } = useMenuDeleteControls(selectedIds, readyToSave, handleCancel, resetSelection);

    const handleRefreshMenu = React.useCallback(() => {
        loadFreshMenu();
        gridRef.current.reset();
    }, [gridRef, loadFreshMenu]);

    const { taxes, categoryIdToTitle, productIdToTitle, categoryProducts } = React.useMemo<TaxesData>(() => {
        if (!menu || !change) {
            return {
                taxes: [],
                categoryIdToTitle: new Map(),
                productIdToTitle: new Map(),
                categoryProducts: {}
            };
        }
        return getTaxesData(menu, change);
    }, [change, menu]);

    const isTaxRemovable = React.useCallback(
        (id: string | number) => !menu?.options?.taxes || !menu?.options?.taxes.find(item => item.id === id),
        [menu]
    );

    useLeavePageBlock(editedOrDeleted);
    const gridSearchOptions = React.useMemo(
        () => [{ column: 'id' }, { column: 'title' }, { column: 'description' }],
        []
    );
    const { filterModel, renderInput } = useMenuGridSearch(
        gridSearchOptions,
        search,
        onSearchChange,
        'taxes',
        'Search by ID, title or description'
    );

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'id',
                headerName: 'ID',
                width: 120
            },
            {
                field: 'title',
                headerName: 'Title',
                editable: true,
                width: 200
            },
            {
                field: 'description',
                headerName: 'Description',
                editable: true,
                width: 200
            },
            {
                field: 'inclusive',
                headerName: 'Inclusive',
                type: 'boolean',
                width: 200,
                editable: true,
                headerAlign: 'left'
            },
            {
                field: 'rate',
                headerName: 'Rate (%)',
                editable: true,
                type: 'number',
                width: 160,
                description:
                    'The rate in which the tax will be applied to a product, ranging from 0 to 100 percent.',
                headerAlign: 'left'
            },
            {
                field: 'applyForScenario',
                headerName: 'Ordering Scenario',
                editable: true,
                width: 300,
                ...MultiSelectGridColumn(scenarioLabel)
            },
            {
                field: 'categoryIds',
                headerName: 'Categories',
                width: 200,
                editable: true,
                ...TaxCategoriesGridColumn(categoryIdToTitle)
            },
            {
                field: 'productIds',
                headerName: 'Products',
                width: 200,
                editable: true,
                ...TaxProductsGridColumn(productIdToTitle, categoryProducts)
            }
        ],
        [categoryIdToTitle, categoryProducts, productIdToTitle]
    );

    const handleCreate = React.useCallback(() => {
        const { changes } = gridRef.current?.getState() ?? {};
        const taxChanges = merge(change?.options?.taxes || {}, changes);
        let newId: string;
        let i = 1;
        while (!newId) {
            if (!taxChanges[`${100 + i}`]) {
                newId = `${100 + i}`;
            } else {
                i++;
            }
        }
        setChange({
            ...change,
            options: {
                taxes: {
                    ...taxChanges,
                    [newId]: {
                        title: '',
                        description: '',
                        inclusive: false,
                        rate: 0,
                        applyForScenario: []
                    }
                }
            }
        });
        gridRef.current?.createItem(newId, 'title', {
            title: '',
            description: '',
            inclusive: false,
            rate: 0,
            applyForScenario: [],
            categoryIds: [],
            productIds: []
        });
        handleStatusChange(EMuiGridStatus.CHANGED);
    }, [change, gridRef, handleStatusChange, setChange]);
    const isEditable = React.useCallback(
        (params: GridCellParams) =>
            isTaxRemovable(params.id)
                ? isCellEditable(params)
                : params.field === 'applyForScenario' ||
                  params.field === 'categoryIds' ||
                  params.field === 'productIds',
        [isCellEditable, isTaxRemovable]
    );
    const handleSubmit = React.useCallback(async () => {
        try {
            if (!gridRef.current) {
                throw new Error(MESSAGE_TAXES_UPDATE_ERROR);
            }
            const errors = gridRef.current.validate();
            if (errors) {
                return;
            }
            const { changes } = gridRef.current.getState();
            const taxCategories: Record<string, string[]> = {};
            const taxProducts: Record<string, string[]> = {};
            taxes.forEach(tax => {
                if ('categoryIds' in tax) {
                    if (Array.isArray(tax.categoryIds)) {
                        taxCategories[tax.id] = tax.categoryIds;
                    } else {
                        taxCategories[tax.id] = null;
                    }
                }
                if ('productIds' in tax) {
                    if (Array.isArray(tax.productIds)) {
                        taxProducts[tax.id] = tax.productIds;
                    } else {
                        taxProducts[tax.id] = null;
                    }
                }
            });
            const changeTaxes = Object.entries(changes).reduce<Record<string, TaxChange>>(
                (acc, [id, { _overriden, ...tax }]) => {
                    const { categoryIds, productIds, ...item } = tax;
                    if ('categoryIds' in tax) {
                        if (Array.isArray(categoryIds)) {
                            taxCategories[id] = categoryIds;
                        } else {
                            taxCategories[id] = null;
                        }
                    }
                    if ('productIds' in tax) {
                        if (Array.isArray(productIds)) {
                            taxProducts[id] = productIds;
                        } else {
                            taxProducts[id] = null;
                        }
                    }
                    if (!change?.options?.taxes || !change.options.taxes[id]) {
                        acc[id] = item;
                        if ('rate' in acc[id]) {
                            acc[id].rate = acc[id].rate / 100;
                        }
                        return acc;
                    }
                    acc[id] = { ...(change?.options?.taxes[id] || {}), ...item };
                    if ('rate' in item) {
                        acc[id].rate = item.rate / 100;
                    }
                    if (isEmptyString(acc[id].inclusive)) {
                        acc[id].inclusive = false;
                    }
                    if (isEmptyString(acc[id].applyForScenario)) {
                        acc[id].applyForScenario = undefined;
                    }
                    return acc;
                },
                {}
            );
            if (deleted) {
                deleted.forEach(deletedTax => {
                    changeTaxes[deletedTax] = null;
                    taxProducts[deletedTax] = null;
                    taxCategories[deletedTax] = null;
                });
            }
            const categories: Record<string, Partial<CategoryChange>> = {};
            const products: Record<string, Partial<ProductChange>> = {};
            Object.keys(externalChange?.categories || {}).forEach(categoryId => {
                categories[categoryId] = { taxIds: null };
            });
            Object.keys(externalChange?.products || {}).forEach(productId => {
                products[productId] = { taxIds: null };
            });
            Object.entries(taxCategories).forEach(([taxId, categoryIds]) => {
                if (Array.isArray(categoryIds) && categoryIds.length) {
                    categoryIds.forEach(categoryId => {
                        categories[categoryId] = {
                            taxIds: [...(categories[categoryId]?.taxIds ?? []), taxId]
                        };
                    });
                }
            });
            Object.entries(taxProducts).forEach(([taxId, productIds]) => {
                if (Array.isArray(productIds) && productIds.length) {
                    productIds.forEach(productId => {
                        products[productId] = {
                            taxIds: [...(products[productId]?.taxIds ?? []), taxId]
                        };
                    });
                }
            });
            Object.entries(categories).forEach(([id, categoryChange]) => {
                if (Array.isArray(categoryChange?.taxIds)) {
                    categories[id] = { taxIds: [...new Set(categoryChange.taxIds)] };
                }
            });
            Object.entries(products).forEach(([id, productChange]) => {
                if (Array.isArray(productChange?.taxIds)) {
                    products[id] = { taxIds: [...new Set(productChange.taxIds)] };
                }
            });
            const result = await menuApi.updateMenuChange(menuChangeId, {
                categories,
                products,
                options: {
                    taxes: changeTaxes
                },
                schema: MenuChangeSchema.v5
            });
            if (!result.ok) {
                throw new Error(result.body.message);
            }
            setChange(result.body);
            onUpdate(result.body);
            resetDeleted();
            dispatch(enqueueSnackbar(MESSAGE_TAXES_UPDATE_SUCCESS, { variant: 'success' }));
            gridRef.current.reset();
        } catch (e) {
            logger.error(e);
            dispatch(enqueueSnackbar(e.message, { variant: 'error' }));
        }
    }, [
        gridRef,
        taxes,
        deleted,
        externalChange?.categories,
        externalChange?.products,
        menuChangeId,
        setChange,
        onUpdate,
        resetDeleted,
        dispatch,
        change?.options?.taxes
    ]);

    const handleCopyTo = React.useCallback(() => {
        toggleIsCopyModalOpen();
        const taxChanges = getTaxesToCopy(change, selectedIds, selectedCell, row => row.id);

        setMenuChangeToCopy({
            options: {
                taxes: taxChanges
            },
            schema: MenuChangeSchema.v5
        });
    }, [toggleIsCopyModalOpen, change, selectedIds, selectedCell, setMenuChangeToCopy]);

    return (
        <React.Fragment>
            <Box pb={1}>
                <ActionsHeader
                    onCreate={handleCreate}
                    menu={menu}
                    onCancelEditing={handleCancelEditing}
                    onSubmit={handleSubmit}
                    activeOperationId={activeOperationId}
                    onCopy={handleCopyTo}
                    onRefreshMenu={handleRefreshMenu}
                    isEditedOrDeleted={editedOrDeleted}
                    copyEnabled={copyEnabled}
                    onDelete={handleDelete}
                    deleteEnabled={deleteEnabled}
                />
                {renderInput()}
            </Box>
            <MuiGrid
                rows={taxes}
                columns={columns}
                stateRef={gridRef}
                onStatusChange={handleStatusChange}
                validationScheme={validationSchema}
                deleted={deleted}
                disableColumnFilter
                filterModel={filterModel}
                storageName={GridStorageName.Taxes}
                isCellEditable={isEditable}
                checkboxSelection
                checkboxVisibility
                onRowSelectionModelChange={handleSelectChange}
                rowSelectionModel={selectedIds}
                onCellClick={handleCellSelection}
                handleCellBlur={handleCellBlur}
            />
            {renderLocationPickerModal()}
        </React.Fragment>
    );
};
