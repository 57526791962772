import React from 'react';
import { Box, Divider, Paper, Stack, styled, TablePagination, Typography } from '@mui/material';
import { NotificationReceiptFilters } from 'components/notification/filters/NotificationReceiptFilters';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import { MuiTheme } from 'config/theme';
import { useNotificationReceiptsFilter } from 'components/notification/hooks/useNotificationReceiptsFilter';
import { useNotificationReceipts } from 'components/notification/hooks/useNotificationReceipts';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { DEFAULT_PAGE_LIMIT } from 'lib/hooks/usePagedData';
import { SelectGridColumn } from 'lib/MuiGrid/Columns';
import { NotificationReceiptStatusLabels } from 'components/notification/filters/NotificationReceiptStatusSelect';
import { CUSTOMERS_VIEW, MARKETING_NOTIFICATIONS_VIEW } from 'config/routes';
import { renderNotificationReceiptStatusCell } from './receipt/NotificationReceiptStatusCell';
import { LinkIdCell } from 'lib/MuiGrid/LinkIdCell';
import { QueryParameter } from 'lib/HttpClient';
import { Notification } from 'components/notification/models/Notification';
import { defaultDateTimeValueFormatter } from 'lib/MuiGrid/helpers';

export interface NotificationReceiptsTableProps {
    notificationId?: string;
    notifications?: Notification<string>[];
    extraFilters?: QueryParameter[]; // ruleId, scheduledTaskId
    showNotificationIdFilter?: boolean;
    isUnsent?: boolean;
}

const StyledGrid = styled(MuiGrid)({
    border: 'none',
    borderRadius: 0
});

export const NotificationReceiptsTable: React.FC<NotificationReceiptsTableProps> = ({
    notificationId,
    notifications,
    extraFilters,
    showNotificationIdFilter = false,
    isUnsent
}) => {
    const [filter, onFilterChange, isEmptyFilter] = useNotificationReceiptsFilter(
        showNotificationIdFilter,
        notificationId
    );
    const { page, onPageChange, loading, count, formattedReceipts, sortModel, onSortModelChange, noData } =
        useNotificationReceipts(filter, extraFilters, isEmptyFilter);
    const renderNotificationViewCell = React.useCallback<GridColDef['renderCell']>(
        params => (
            <LinkIdCell id={params.value} param="notificationId" path={MARKETING_NOTIFICATIONS_VIEW}>
                {params.value || params.row.notificationMessageId}
            </LinkIdCell>
        ),
        []
    );
    const renderCustomerViewCell = React.useCallback<GridColDef['renderCell']>(
        params => (
            <LinkIdCell id={params.row.userId} param="customerId" path={CUSTOMERS_VIEW}>
                {params.value || params.row.userId}
            </LinkIdCell>
        ),
        []
    );
    const columns = React.useMemo<GridColDef[]>(
        () => [
            ...(showNotificationIdFilter
                ? [
                      {
                          headerName: 'Notification ID',
                          field: 'notificationId',
                          sortable: false,
                          width: 240,
                          renderCell: renderNotificationViewCell
                      }
                  ]
                : []),
            {
                headerName: 'Customer',
                field: 'userFullName',
                sortable: false,
                width: 240,
                flex: 1,
                renderCell: renderCustomerViewCell
            },
            {
                headerName: 'Status',
                field: 'status',
                sortable: false,
                width: 90,
                align: 'left',
                ...SelectGridColumn(NotificationReceiptStatusLabels),
                renderCell: renderNotificationReceiptStatusCell
            },
            {
                headerName: 'Sent On',
                field: 'sentAt',
                sortable: true,
                type: 'dateTime',
                width: 180,
                valueGetter: defaultDateTimeValueFormatter
            }
        ],
        [showNotificationIdFilter, renderCustomerViewCell, renderNotificationViewCell]
    );
    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            onPageChange(page);
        },
        [onPageChange]
    );
    const handleSortChange = React.useCallback(
        (model: GridSortModel) => {
            onSortModelChange(model);
        },
        [onSortModelChange]
    );
    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={count}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={DEFAULT_PAGE_LIMIT}
                rowsPerPageOptions={[DEFAULT_PAGE_LIMIT]}
                component="div"
                sx={{
                    '&:last-child': {
                        paddingRight: MuiTheme.spacing(6)
                    }
                }}
            />
        ),
        [count, handlePageChange, page]
    );
    const NoRowsOverlay = React.useCallback(
        () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
                {isUnsent ? 'This has not been sent yet.' : 'This has not been delivered to any users.'}
            </Stack>
        ),
        [isUnsent]
    );

    return (
        <Paper sx={{ minHeight: 400, display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Box padding={2}>
                <Typography>Receipts</Typography>
            </Box>
            <Divider />
            <Box paddingX={2} paddingBottom={2}>
                <NotificationReceiptFilters
                    disabled={loading || (isUnsent && noData)}
                    notifications={notifications}
                    showNotificationIdFilter={showNotificationIdFilter}
                    filter={filter}
                    onChange={onFilterChange}
                />
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                sx={{
                    minHeight: 400,
                    maxHeight: 800
                }}
            >
                <StyledGrid
                    rows={formattedReceipts}
                    columns={columns}
                    hideFooter={false}
                    rowCount={count}
                    pagination
                    storageName={GridStorageName.NotificationReceipts}
                    disableColumnFilter
                    paginationMode="server"
                    loading={loading}
                    sortModel={sortModel}
                    onSortModelChange={handleSortChange}
                    sortingMode="server"
                    slots={{
                        pagination: Pagination,
                        noRowsOverlay: NoRowsOverlay
                    }}
                />
            </Box>
        </Paper>
    );
};
