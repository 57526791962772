import React from 'react';
import { Option } from 'lib/types';
import { MuiModal } from 'ui/MuiModal';
import { Notification, NotificationCreate } from '../models/Notification';
import { notificationApi } from '../notificationApi';
import { NotificationCreationStepper } from 'components/notification/modals/NotificationCreationStepper';
import { ContentModule } from 'components/content/models/ContentModule';
import { ConfirmDialog } from 'ui/dialogs/ConfirmDialog';
import { styled } from '@mui/material';

interface CreateNotificationModalProps {
    open: boolean;
    onClose?: () => void;
    onError: (message: string) => void;
    onSuccess: (notification: Notification<string>) => void;
    notification?: Notification<string>;
    segments: Option[];
    locations: Option[];
    modules: ContentModule[];
}

const StyledMuiModal = styled(MuiModal)(() => ({
    overflow: 'visible' // Needed for the dropdowns
}));

export const CreateNotificationModal: React.FunctionComponent<CreateNotificationModalProps> = props => {
    const { open, onClose } = props;
    return (
        <StyledMuiModal open={open} onClose={onClose}>
            <CreateNotificationModalComponent {...props} />
        </StyledMuiModal>
    );
};

const CreateNotificationModalComponent: React.FC<CreateNotificationModalProps> = props => {
    const { onClose, onError, onSuccess, notification, segments, locations, modules } = props;

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const onSubmit = React.useCallback(
        async (data: NotificationCreate) => {
            const result = await notificationApi.create({
                body: data
            });

            if (!result.ok) {
                onError(result.body.message);
            } else {
                onSuccess(result.body);
            }
        },
        [onError, onSuccess]
    );
    const handleOnClose = React.useCallback(() => {
        setOpenConfirm(true);
    }, [setOpenConfirm]);
    const handleDialogOnCancel = React.useCallback(() => {
        setOpenConfirm(false);
    }, [setOpenConfirm]);

    return (
        <>
            <StyledMuiModal open={true} onClose={handleOnClose}>
                <NotificationCreationStepper
                    initialData={notification}
                    locations={locations}
                    segments={segments}
                    modules={modules}
                    onClose={handleOnClose}
                    onSubmit={onSubmit}
                />
            </StyledMuiModal>
            <ConfirmDialog
                open={openConfirm}
                title="Hold On!"
                content="Are you sure you want to cancel this notification creation process? All changes you made will be lost."
                onCancel={handleDialogOnCancel}
                onConfirm={onClose}
                cancelLabel={"No, don't cancel"}
                confirmLabel={'Yes, cancel'}
            />
        </>
    );
};
