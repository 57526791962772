import React from 'react';
import { Box, Grid, styled } from '@mui/material';
import { Panel } from 'ui/Panel';
import { CardViewChipRow, CardViewTextRow, CardViewTitle } from 'ui/viewComponents';
import { contentApi } from 'components/content/contentApi';
import { ResourceText } from 'lib/ResourceText';
import { renderDeepLinkTitle } from 'components/notification/helpers';
import { RuleResource } from 'components/rules/rules.types';
import { green, grey } from '@mui/material/colors';
import { formatDateTime } from 'lib/timeHelpers';
import { getActionTypeOptions } from 'components/actions/utils';
import { flattenRuleConditions } from 'components/rules/helpers';
import { ICreateScheduledTaskCreatePushNotification } from 'components/scheduled-tasks/scheduledTasks.types';
import { relativeTimeStringToDisplay, validateRelativeTimeString } from 'lib/relativeTimeString';

const PREFIX = 'RuleScheduledTaskNotificationDetailsCard';

const classes = {
    stateChip: `${PREFIX}-stateChip`
};

const StyledBox = styled(Box)<{ active: boolean }>(({ theme, active }) => ({
    [`& .${classes.stateChip}`]: {
        backgroundColor: active ? green['A700'] : grey['500'],
        color: theme.palette.primary.contrastText,
        fontSize: '0.8rem'
    }
}));

interface RuleScheduledTaskNotificationDetailsCardProps {
    rule: RuleResource;
}

export const RuleScheduledTaskNotificationDetailsCard: React.FC<
    RuleScheduledTaskNotificationDetailsCardProps
> = props => {
    const { rule } = props;
    const impactParameters = React.useMemo<ICreateScheduledTaskCreatePushNotification>(
        () => rule?.effect?.impact?.parameters || {},
        [rule]
    );
    const actionType = React.useMemo<string>(() => {
        const flattenedConditions = flattenRuleConditions(rule);
        const option = getActionTypeOptions().find(n => n.value === flattenedConditions.actionType);
        return `${option?.label || 'Unknown'}`;
    }, [rule]);
    const relativeTime = React.useMemo<string>(() => {
        if (!validateRelativeTimeString(impactParameters.triggerAt)) {
            return;
        }
        return relativeTimeStringToDisplay(impactParameters.triggerAt, { symbols: false });
    }, [impactParameters]);

    return (
        <Panel title="Details" divider>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <CardViewTextRow title="Send On" noTransform value={actionType} fallback="Processing" />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CardViewTextRow
                        title="Delay By"
                        noTransform
                        value={relativeTime}
                        fallback="Processing"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CardViewTextRow
                        title="Created On"
                        noTransform
                        value={formatDateTime(rule?.created)}
                        fallback="Processing"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CardViewTextRow
                        title="Updated On"
                        noTransform
                        value={formatDateTime(rule?.updated)}
                        fallback="Processing"
                    />
                </Grid>
                <Grid item xs={12}>
                    <StyledBox active={rule.active}>
                        <CardViewChipRow
                            {...props}
                            title="State"
                            value={rule.active ? 'Running' : 'Paused'}
                            chipClasses={{ root: classes.stateChip }}
                        />
                    </StyledBox>
                </Grid>
                {impactParameters.parameters?.title && (
                    <Grid item xs={12} lg={6}>
                        <CardViewTextRow title="Title" value={impactParameters.parameters.title} />
                    </Grid>
                )}
                <Grid item xs={12} lg={6}>
                    <CardViewTextRow title="Message" value={impactParameters.parameters?.message} />
                </Grid>
                {impactParameters.parameters?.imageUrl && (
                    <Grid item xs={12} lg={6}>
                        <Grid item xs={12}>
                            <CardViewTitle title="Image" />
                            <img
                                src={impactParameters.parameters.imageUrl}
                                alt="Image"
                                style={{
                                    aspectRatio: 'initial',
                                    maxWidth: '100%',
                                    maxHeight: '200px'
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
                {impactParameters.parameters?.deepLink && (
                    <Grid item xs={12} lg={6}>
                        <Grid item xs={12}>
                            <CardViewTitle title="Deep Link" noTransform />
                            <ResourceText
                                id={impactParameters.parameters.deepLink?.moduleId}
                                crud={contentApi.modules}
                                title={renderDeepLinkTitle(impactParameters.parameters.deepLink)}
                            />
                        </Grid>
                    </Grid>
                )}
                {impactParameters.parameters?.tag && (
                    <Grid item xs={12} lg={6}>
                        <CardViewTextRow title="Tag" value={impactParameters.parameters.tag} />
                    </Grid>
                )}
            </Grid>
        </Panel>
    );
};
