import { isArrayOf, isBoolean, isNumber, isOptional, isOptionalString, isString } from 'lib/typeguards';

export enum CustomerCredentialProvider {
    MOBILE = 'MOBILE',
    EMAIL = 'EMAIL',
    FACEBOOK = 'FACEBOOK',
    CFD = 'CFD',
    APPLE = 'APPLE',
    GOOGLE = 'GOOGLE'
}

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE'
}

export enum EPrimaryPlatform {
    IOS = 'IOS',
    WEB = 'WEB',
    ANDROID = 'ANDROID',
    UNKNOWN = 'UNKNOWN'
}

export enum CustomerRole {
    USER = 'USER',
    ADMIN = 'ADMIN',
    GUEST = 'GUEST'
}

export type AllowedCustomerRoleFilter = Exclude<CustomerRole, typeof CustomerRole.ADMIN> | 'ALL';

export enum CustomerState {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING'
}

export enum CardMethodType {
    CARD = 'CARD',
    PAYPAL = 'PAYPAL'
}

export enum CardType {
    STRIPE = 'STRIPE',
    JUDOPAY = 'JUDOPAY',
    BRAINTREE = 'BRAINTREE',
    SQUARE = 'SQUARE'
}

export interface CustomerTokens {
    activation?: string;
    reset?: string;
}
export interface CustomerSegment {
    _id: string;
    title: string;
    included: boolean;
}

export interface Customer {
    _id: string;
    fullName: string;
    email: string;
    card: CustomerCard;
    created: string;
    tier?: string;
    defaultTip: null | {
        scheme: 'ABSOLUTE' | 'PERCENTAGE';
        amount: number;
    };
    favouriteLocations?: string[];
    favouriteProducts: any[];
    extended: any;
    reviewCandidate: string;
    resetUniqueDeviceVerification?: boolean;
    uniqueDeviceIdAndroid: string;
    updatedAt: string;
    firstName: string;
    lastName: string;
    tenantId: string;
    balance?: number;
    deleted?: boolean;
    demo?: boolean;
    points: number;
    roles: CustomerRole[];
    state: CustomerState;
    tokens: CustomerTokens;
    hasAgreedToShareData?: boolean;
    hasAgreedToReceiveMarketing?: boolean;
    primaryPlatform: EPrimaryPlatform;
    birthdate?: string;
    phoneNumberLastUsedToOrder?: string;
    subscribed: boolean;
    hasPaymentCard: boolean;
    hasProfilePhoto: boolean;
    isRegisteredForPushNotifications: boolean;
    contacts: string[];
    segments?: CustomerSegment[];
    credentials: CustomerCredential[];
    gender?: string;
}

interface CustomerCard {
    methodType: CardMethodType;
    expires?: string;
    gateway: CardType;
    type?: string;
    _id: string;
    last4: string;
    autoTopUp: CardAutoTopUp;
}

interface CardAutoTopUp {
    enabled: boolean;
    amount: boolean;
    trigger: boolean;
}

export interface CustomerCredential {
    id: string;
    isPrimary?: boolean;
    isVerified?: boolean;
    parentCredentialId?: string;
    provider: CustomerCredentialProvider;
    _id?: string;
}

interface Page {
    nextKey?: string;
    startKey: string;
}

interface CustomerData {
    items: Customer[];
    page: Page;
}

export const isCustomer = (user: any): user is Customer =>
    !!user &&
    isString(user._id) &&
    isString(user.fullName) &&
    isOptional(isBoolean, user.deleted) &&
    isString(user.tenantId) &&
    isOptional(isNumber, user.points) &&
    isOptionalString(user.created) &&
    (user.state === CustomerState.ACTIVE || user.state === CustomerState.PENDING) &&
    isOptional(isBoolean, user.hasAgreedToShareData) &&
    isOptional(isBoolean, user.hasAgreedToReceiveMarketing);

export const isCustomerData = (data: any): data is CustomerData =>
    !!data &&
    isArrayOf(isCustomer, data.items) &&
    data.page &&
    isOptionalString(data.page.nextKey) &&
    isString(data.page.startKey);

export function isAllowedCustomerRoleFilter(filter: any): filter is AllowedCustomerRoleFilter {
    return filter === CustomerRole.GUEST || filter === CustomerRole.USER || filter === 'ALL';
}
