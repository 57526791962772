import { styled } from '@mui/material';
import { TextAlignment, VerticalAlignment } from 'components/loyalty/models/PerkModel';
import React from 'react';

type ExpiryLabelDetailIconProps = {
    vertical: VerticalAlignment.TOP | VerticalAlignment.BOTTOM;
    horizontal: TextAlignment;
};

const childClass = 'ExpiryLabelChipIconClass';

const ExpiryLabelDetailIcon = styled('div')<ExpiryLabelDetailIconProps>(
    ({ theme, vertical, horizontal }) => ({
        display: 'flex',
        alignItems: vertical === VerticalAlignment.TOP ? 'start' : 'end',
        justifyContent: horizontal === TextAlignment.CENTER ? 'center' : 'normal',
        height: theme.spacing(8),
        width: theme.spacing(8),
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
        [`& .${childClass}`]: {
            width: horizontal === TextAlignment.JUSTIFY ? '100%' : '35%',
            height: theme.spacing(1.25),
            backgroundColor: theme.palette.primary.main,
            ...(vertical === VerticalAlignment.BOTTOM && horizontal === TextAlignment.CENTER
                ? {
                      borderTopRightRadius: theme.shape.borderRadius,
                      borderTopLeftRadius: theme.shape.borderRadius
                  }
                : {}),
            ...(vertical === VerticalAlignment.TOP && horizontal === TextAlignment.CENTER
                ? {
                      borderBottomRightRadius: theme.shape.borderRadius,
                      borderBottomLeftRadius: theme.shape.borderRadius
                  }
                : {})
        }
    })
);

const BottomFullWidthBarIcon = () => (
    <ExpiryLabelDetailIcon vertical={VerticalAlignment.BOTTOM} horizontal={TextAlignment.JUSTIFY}>
        <div className={childClass} />
    </ExpiryLabelDetailIcon>
);

const BottomMiddleChipIcon = () => (
    <ExpiryLabelDetailIcon vertical={VerticalAlignment.BOTTOM} horizontal={TextAlignment.CENTER}>
        <div className={childClass} />
    </ExpiryLabelDetailIcon>
);

const TopFullWidthBarIcon = () => (
    <ExpiryLabelDetailIcon vertical={VerticalAlignment.TOP} horizontal={TextAlignment.JUSTIFY}>
        <div className={childClass} />
    </ExpiryLabelDetailIcon>
);

const TopMiddleChipIcon = () => (
    <ExpiryLabelDetailIcon vertical={VerticalAlignment.TOP} horizontal={TextAlignment.CENTER}>
        <div className={childClass} />
    </ExpiryLabelDetailIcon>
);

export { BottomFullWidthBarIcon, BottomMiddleChipIcon, TopFullWidthBarIcon, TopMiddleChipIcon };
