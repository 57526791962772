import { Card, CardActionArea, CardContent, CircularProgress, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import { titleize } from 'lib/helpers';
import React from 'react';
import { LocationBusyness } from '../LocationTimeSlotSettings';

interface IProps {
    busynessSetting: LocationBusyness;
    busynessForCard: LocationBusyness;
    isDisabled: boolean;
    isLoading: boolean;
    handleClick: () => void;
    ordersPerTimeSlot: number;
    timeSlotFrequencyInMins: number;
}

const PREFIX = 'LocationTimeSlotOptionCard';

const classes = {
    cardLoading: `${PREFIX}-cardLoading`
};

const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: theme.spacing(40),
    minHeight: theme.spacing(10),
    margin: theme.spacing(1),
    borderRadius: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
        minWidth: theme.spacing(25),
        minHeight: theme.spacing(6)
    },
    [`&.${classes.cardLoading}`]: {
        justifyContent: 'center'
    }
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'space-between',
        padding: theme.spacing(1, 2)
    }
}));

export const LocationTimeSlotOptionCard = (props: IProps) => {
    const {
        busynessSetting,
        busynessForCard,
        isLoading,
        isDisabled,
        handleClick,
        ordersPerTimeSlot,
        timeSlotFrequencyInMins
    } = props;

    return (
        <StyledCard
            className={clsx({ [classes.cardLoading]: isLoading })}
            variant="outlined"
            title={busynessForCard}
        >
            {isLoading ? (
                <CircularProgress />
            ) : (
                <CardActionArea disabled={isDisabled} onClick={handleClick}>
                    <StyledCardContent>
                        <Typography
                            variant="h5"
                            component="div"
                            color={busynessSetting !== busynessForCard ? 'primary' : 'secondary'}
                        >
                            {titleize(busynessForCard)}
                        </Typography>
                        <Typography variant="body2">
                            {ordersPerTimeSlot} orders every {timeSlotFrequencyInMins} minutes
                        </Typography>
                    </StyledCardContent>
                </CardActionArea>
            )}
        </StyledCard>
    );
};
