import { GridCellParams } from '@mui/x-data-grid-pro';
import { isDefined } from 'lib/typeguards';
import React from 'react';

export function useMenuSelection<T = any, V = any>(globalCopyEnabled = true) {
    const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
    const [selectedCell, setSelectedCell] = React.useState<GridCellParams<T, V> | undefined>();

    const handleSelectChange = (newSelection: string[]) => {
        setSelectedIds(newSelection);
    };

    const handleCellBlur = React.useCallback(() => {
        setSelectedCell(undefined);
    }, []);

    const handleCellSelection = React.useCallback((params: GridCellParams<T, V>) => {
        setSelectedCell(params);
    }, []);

    const resetSelection = React.useCallback(() => {
        setSelectedIds([]);
        setSelectedCell(undefined);
    }, []);

    const copyEnabled = React.useMemo(
        () => (selectedIds.length > 0 || isDefined(selectedCell)) && globalCopyEnabled,
        [globalCopyEnabled, selectedCell, selectedIds.length]
    );

    return {
        selectedIds,
        selectedCell,
        handleSelectChange,
        handleCellBlur,
        resetSelection,
        handleCellSelection,
        copyEnabled
    };
}
