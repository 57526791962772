import { UserMetricDefinition } from 'components/metrics/model';
import { isDefined } from 'lib/typeguards';
import React from 'react';

export function useTieredLoyaltyMetrics(
    metricDefinition: UserMetricDefinition | undefined,
    selectedMetricId: string | undefined
) {
    const selectedMetric = React.useMemo(() => {
        if (isDefined(selectedMetricId) && !!metricDefinition) {
            return metricDefinition.metrics[selectedMetricId];
        }
        return undefined;
    }, [metricDefinition, selectedMetricId]);
    const editable = React.useMemo(() => !selectedMetric, [selectedMetric]);
    const deletable = React.useMemo(
        () =>
            !!selectedMetric &&
            metricDefinition?.effects.every(item =>
                item.conditions?.every(
                    condition => !('metricId' in condition) || condition.metricId !== selectedMetricId
                )
            ),
        [metricDefinition?.effects, selectedMetricId, selectedMetric]
    );
    return {
        metricEditable: editable,
        metricDeletable: deletable,
        selectedMetric
    };
}
