import { getNormalizedDateTime } from 'lib/timeHelpers';
import { isDefined, isEmptyString } from 'lib/typeguards';
import { getMinsSinceMidnight } from 'components/perks/award-enrichments/utils';
import { AdjustmentSchemeMutationBody, AdjustmentSchemeType } from '../adjustmentSchemeModel';
import { ConfigureSchemeGeneralFormData } from '../forms/AdjustmentSchemeGeneralForm';
import { AdjustmentSchemeRedemptionFormData } from '../forms/redemption-form/AdjustmentSchemeRedemptionForm';

export const getAdjustmentSchemeMutationBody = (
    data: {
        general: ConfigureSchemeGeneralFormData;
        redemption: AdjustmentSchemeRedemptionFormData;
    },
    merchantTimezone?: string
): AdjustmentSchemeMutationBody => {
    const { general, redemption } = data;

    const hasDiscountFields =
        !!redemption.discount && !!Object.values(redemption.discount).filter(Boolean).length;
    const hasBasketQualificationFields =
        !!redemption.basketQualification &&
        !!Object.values(redemption.basketQualification).filter(Boolean).length;
    const hasDateTimeRestrictionFields =
        !!redemption.dateTimeRestriction &&
        !!Object.values(redemption.dateTimeRestriction).filter(Boolean).length;

    let { startDate, endDate } = redemption.dateTimeRestriction;
    const startTimeMins = getMinsSinceMidnight(redemption.dateTimeRestriction.startTime) ?? 0;
    const endTimeMins = getMinsSinceMidnight(redemption.dateTimeRestriction.endTime) ?? 0;

    if (startDate) {
        startDate = getNormalizedDateTime(startDate, merchantTimezone)
            .set({ hour: Math.floor(startTimeMins / 60), minute: startTimeMins % 60 })
            .toISO();
    }

    if (endDate) {
        endDate = getNormalizedDateTime(endDate, merchantTimezone)
            .set({ hour: Math.floor(endTimeMins / 60), minute: endTimeMins % 60 })
            .toISO();
    }

    const weekDays = redemption.dateTimeRestriction.daysOfWeek?.length
        ? redemption.dateTimeRestriction.daysOfWeek.reduce((acc, weekday) => {
              acc += Number(weekday);

              return acc;
          }, 0)
        : undefined;

    return {
        title: general.title,
        internalTitle: general.internalTitle,
        schemeType: AdjustmentSchemeType.COUPON,
        externalPosId: general.externalPosId,
        locationIds: general.locationIds,
        enabled: general.enabled,
        discount: hasDiscountFields
            ? {
                  value:
                      isDefined(redemption.discount.value) && !isEmptyString(redemption.discount.value)
                          ? Number(redemption.discount.value)
                          : undefined,
                  scope: redemption.discount.scope,
                  type: redemption.discount.type,
                  scopeMatchCode: redemption.discount.scopeMatchCode,
                  rule: redemption.discount.rule || 'CHEAPEST',
                  childItemRule: redemption.discount.childItemRule || 'INCLUDE'
              }
            : undefined,
        basketQualification: hasBasketQualificationFields
            ? {
                  matchCode: redemption.basketQualification.matchCode || undefined,
                  minimumItems:
                      redemption.basketQualification.minimumItems ||
                      (hasBasketQualificationFields && !redemption.basketQualification.minimumValue && 1) ||
                      undefined,
                  minimumValue: redemption.basketQualification.minimumValue || undefined,
                  scope: redemption.basketQualification.scope
              }
            : undefined,
        dateTimeRestriction: hasDateTimeRestrictionFields
            ? {
                  startDate: startDate || undefined,
                  endDate: endDate || undefined,
                  startTime: startTimeMins * 60 || undefined,
                  endTime: endTimeMins * 60 || undefined,
                  daysOfWeek: weekDays
              }
            : undefined
    };
};
