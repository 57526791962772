import { Button, InputBaseComponentProps, TextField } from '@mui/material';
import { useInputState } from 'lib/hooks/useInputState';
import { isEmptyString } from 'lib/typeguards';
import React from 'react';
import MaskedInput from 'react-text-mask';
import { Row } from 'ui/Flex';

const ExpiryInput = (props: InputBaseComponentProps) => (
    <MaskedInput mask={[/[0-1]/, /[0-9]/, '/', '2', '0', /[2-9]/, /[0-9]/]} {...props} />
);

interface TransactionExpiryInputProps {
    onChange: (value?: string) => void;
    value?: string;
    disabled?: boolean;
}

function isValidExpiry(value?: string) {
    return !value || value.length === 7;
}

export const TransactionExpiryInput: React.FC<TransactionExpiryInputProps> = ({
    onChange,
    value,
    disabled
}) => {
    const [field, handleChange] = useInputState(value);
    const handleClick = React.useCallback(() => {
        onChange(isEmptyString(field) ? undefined : field);
    }, [field, onChange]);
    const renderButton = React.useCallback(
        () => (
            <Button
                disabled={(value === field && isValidExpiry(value)) || disabled}
                onClick={handleClick}
                variant="outlined"
            >
                {value === field || field.length ? 'Submit' : 'Clear'}
            </Button>
        ),
        [disabled, field, handleClick, value]
    );
    return (
        <Row>
            <TextField
                variant="outlined"
                value={field}
                onChange={handleChange}
                label="Card Expiry"
                InputProps={{ endAdornment: renderButton(), inputComponent: ExpiryInput }}
                InputLabelProps={{ shrink: !!field }}
                disabled={disabled}
            />
        </Row>
    );
};
