import React, { useMemo } from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Grid
} from '@mui/material';
import { Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { MuiForm } from 'lib/Form';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';
import { NotificationDeeplink } from 'components/notification/models/Notification';
import { ImageFormField } from 'lib/form/fields/ImageFormField';
import { NotificationDeepLinkField } from 'components/notification/notification/NotificationDeepLinkField';
import { ContentModule } from 'components/content/models/ContentModule';
import { isNonEmptyString } from '@pepperhq/menu-sdk/dist/libs/typeUtils';
import { isUndefined } from 'lib/typeguards';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import {
    NOTIFICATION_IMAGE_ASPECT,
    NOTIFICATION_IMAGE_HEIGHT,
    NOTIFICATION_IMAGE_WIDTH
} from 'components/notification/helpers';
import { useSomethingNewPopupState } from 'lib/hooks/useSomethingNewPopupState';
import { ELocalStorageKey, ESessionStorageKey } from 'config/storageKeys';
import { EGAEventName, useGAHelpers } from 'lib/hooks/useGAHelper';

export type NotificationGeneralFormData = {
    draft: boolean;
    title?: string;
    message: string;
    imageUrl?: string;
    deepLink?: NotificationDeeplink;
    buttonText?: string;
};

interface NotificationGeneralFormProps {
    open: boolean;
    onSubmit: (data: NotificationGeneralFormData) => void;
    onPrevious: (data: Partial<NotificationGeneralFormData>) => void;
    initialData?: Partial<Omit<NotificationGeneralFormData, 'draft'>>;
    modules: ContentModule[];
    isScheduledTask?: boolean;
    isRule?: boolean;
}

const validationSchema = Yup.object().shape({
    title: Yup.string().required('You must specify a title for this notification.'),
    message: Yup.string().required('You must specify a message to send in this notification.'),
    deepLink: Yup.mixed().test(
        'is-valid-deepLink',
        ' ',
        value =>
            (value && isNonEmptyString(value.moduleId) && isNonEmptyString(value.moduleItemId)) ||
            isUndefined(value)
    ),
    buttonText: Yup.string().when('deepLink', {
        is: (value?: NotificationDeeplink) => !!value,
        then: Yup.string().required(' ')
    })
});

export const NotificationGeneralForm: React.FC<NotificationGeneralFormProps> = ({
    open,
    onSubmit,
    onPrevious,
    initialData,
    modules,
    isScheduledTask,
    isRule
}) => {
    const initialValues: NotificationGeneralFormData = useMemo(
        () => ({
            title: initialData?.title,
            message: initialData?.message || '',
            imageUrl: initialData?.imageUrl,
            deepLink: initialData?.deepLink,
            buttonText: initialData?.buttonText,
            draft: false
        }),
        [initialData]
    );

    if (!open) {
        return null;
    }
    return (
        <MuiForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount
        >
            {props => (
                <NotificationGeneralFormFields
                    {...props}
                    onPrevious={onPrevious}
                    modules={modules}
                    isScheduledTask={isScheduledTask}
                    isRule={isRule}
                />
            )}
        </MuiForm>
    );
};

const NotificationGeneralFormFields: React.FC<
    FormikProps<NotificationGeneralFormData> &
        Pick<NotificationGeneralFormProps, 'onPrevious' | 'modules' | 'isScheduledTask' | 'isRule'>
> = ({
    submitForm,
    isSubmitting,
    isValid,
    values,
    setFieldValue,
    onPrevious,
    modules,
    isScheduledTask,
    isRule
}) => {
    // TODO: Remove this popup when the feature is no longer new
    const [titleTouched, setTitleTouched] = React.useState(false);
    const [confirmClicked, setConfirmClicked] = React.useState(false);
    const [doNotShowAgain, setDoNotShowAgain] = React.useState(false);
    const { logUserEvent } = useGAHelpers();
    const { setIsConfirmed, incrementCountShown, shouldShow } = useSomethingNewPopupState(
        ESessionStorageKey.NotificationsTitleModal,
        ELocalStorageKey.NotificationsTitleInfoShown,
        3
    );
    const handleCheckboxChange = React.useCallback((_: never, checked: boolean) => {
        setDoNotShowAgain(checked);
    }, []);
    const handleModalClose = React.useCallback(() => {
        logUserEvent(EGAEventName.NotificationsTitleInputClick);
        setConfirmClicked(true);
        incrementCountShown(doNotShowAgain);
        setIsConfirmed();
    }, [doNotShowAgain, incrementCountShown, logUserEvent, setIsConfirmed]);
    const showModal = React.useMemo(
        () => !isScheduledTask && !isRule && !confirmClicked && titleTouched && shouldShow(),
        [confirmClicked, isRule, isScheduledTask, shouldShow, titleTouched]
    ); // Only show for ASAP notifications
    const handleTitleInteract = React.useCallback(() => {
        setTitleTouched(true);
    }, []);

    // Actual form logic begins here
    const handlePrevious = React.useCallback(() => onPrevious(values), [onPrevious, values]);
    const handleSubmitDraft = React.useCallback(async () => {
        await setFieldValue('draft', true);
        await submitForm();
    }, [setFieldValue, submitForm]);
    const handleSubmitScheduled = React.useCallback(async () => {
        await setFieldValue('draft', false);
        await submitForm();
    }, [setFieldValue, submitForm]);

    return (
        <>
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextFormField
                            required
                            label={<MuiFormLabel required>Title</MuiFormLabel>}
                            name="title"
                            onKeyDown={handleTitleInteract}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFormField
                            required
                            label={<MuiFormLabel required>Message</MuiFormLabel>}
                            multiline
                            maxRows={4}
                            name="message"
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: 16 }}>
                        <ImageFormField
                            aspect={NOTIFICATION_IMAGE_ASPECT}
                            description={`${NOTIFICATION_IMAGE_WIDTH}px by ${NOTIFICATION_IMAGE_HEIGHT}px PNG. If no image is uploaded,
                                it will default to your default alert asset.`}
                            accept={['image/png', 'image/jpeg']}
                            name="imageUrl"
                            fullWidth={true}
                            label={<MuiFormLabel>Image</MuiFormLabel>}
                            labelStyle={{ left: -12, top: 8 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <NotificationDeepLinkField name="deepLink" modules={modules} />
                    </Grid>
                    {!!values.deepLink && (
                        <Grid item xs={12}>
                            <TextFormField
                                required
                                name="buttonText"
                                label={<MuiFormLabel required>Alert Button Text</MuiFormLabel>}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Row gutter align="flex-end" valign="center">
                            <Button variant="outlined" onClick={handlePrevious}>
                                Back
                            </Button>
                            {(isScheduledTask || isRule) && (
                                <LoadingButton
                                    variant="outlined"
                                    color="primary"
                                    disabled={isSubmitting || !isValid}
                                    loading={isSubmitting}
                                    onClick={handleSubmitDraft}
                                >
                                    {isRule ? 'Confirm & Pause' : 'Save Draft'}
                                </LoadingButton>
                            )}
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting || !isValid}
                                loading={isSubmitting}
                                onClick={handleSubmitScheduled}
                            >
                                Confirm{isRule && ' & Activate'}
                            </LoadingButton>
                        </Row>
                    </Grid>
                </Grid>
            </Form>
            <Dialog open={showModal}>
                <DialogTitle>New app build may be required</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        To see the new title, image and deep link functionality on the iOS and Android Pepper
                        app, ensure you have installed a build dated later than December 27, 2024. If the
                        latest version available on the App Store does not display the new title, please
                        contact Pepper Support for assistance.
                    </DialogContentText>
                    <FormControlLabel
                        control={<Checkbox value={doNotShowAgain} onChange={handleCheckboxChange} />}
                        label="Do not show again"
                    />
                    <Row flex={1} align="flex-end">
                        <Button variant="contained" color="primary" onClick={handleModalClose}>
                            UNDERSTOOD
                        </Button>
                    </Row>
                </DialogContent>
            </Dialog>
        </>
    );
};
