import React from 'react';
import { Button, Grid } from '@mui/material';
import { Form } from 'formik';
import * as Yup from 'yup';
import { MuiForm } from 'lib/Form';
import { MultiSelectFormField } from 'lib/form/fields/MultiSelectFormField';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { Option } from 'lib/types';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';
import { getSelectedOptions } from 'components/utils/getSelectedOptions';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { ScheduledTaskType } from '../../scheduledTasks.types';
import { NotificationTarget } from 'components/notification/forms/NotificationTargetForm';
import { DateTimePickerFormField } from 'lib/form/fields/DateTimePickerFormField';

export interface ScheduledTaskNotificationTargetFormData {
    type: ScheduledTaskType.CREATE_PUSH_NOTIFICATION;
    triggerAt: string;
    tag?: string;
    segmentIds?: string[];
    locationIds?: string[];
    target?: NotificationTarget;
}

export interface ScheduledTaskNotificationTargetInternalFormData {
    segments: Option[];
    locations: Option[];
}

interface ScheduledTaskNotificationTargetFormProps {
    open: boolean;
    onNext: (data: ScheduledTaskNotificationTargetFormData) => void;
    onCancel: () => void;
    initialData?: Partial<ScheduledTaskNotificationTargetFormData>;
    locations: Option[];
    segments: Option[];
}

const validationSchema = Yup.object().shape({
    triggerAt: Yup.date()
        .typeError('You must select a send time before proceeding.')
        .min(new Date(), 'You must select a send time before proceeding.'),
    segments: Yup.array().when('target', {
        is: (target: NotificationTarget) => target === NotificationTarget.AUDIENCE,
        then: Yup.array().min(1, 'You must select at least one Audience.'),
        otherwise: Yup.array().notRequired()
    }),
    locations: Yup.array().when('target', {
        is: (target: NotificationTarget) => target === NotificationTarget.LOCATION,
        then: Yup.array().min(1, 'You must select at least one Location.'),
        otherwise: Yup.array().notRequired()
    })
});

const targetOptions = [
    { value: NotificationTarget.GLOBAL, label: 'All Users' },
    { value: NotificationTarget.AUDIENCE, label: 'Audience' },
    { value: NotificationTarget.LOCATION, label: 'Location' }
];

const typeOptions: Option[] = [
    {
        value: ScheduledTaskType.CREATE_PUSH_NOTIFICATION,
        label: 'Push Notification'
    }
];

export const ScheduledTaskNotificationTargetForm: React.FC<ScheduledTaskNotificationTargetFormProps> = ({
    open,
    onNext,
    onCancel,
    initialData,
    segments,
    locations
}) => {
    const target = React.useMemo(
        () =>
            initialData?.target ||
            (initialData?.segmentIds?.length > 0 && NotificationTarget.AUDIENCE) ||
            (initialData?.locationIds?.length > 0 && NotificationTarget.LOCATION) ||
            NotificationTarget.GLOBAL,
        [initialData]
    );

    const selectedLocations = React.useMemo(
        () => getSelectedOptions(locations, initialData?.locationIds),
        [initialData, locations]
    );
    const selectedSegments = React.useMemo(
        () => getSelectedOptions(segments, initialData?.segmentIds),
        [initialData, segments]
    );

    const initialValues = React.useMemo<
        ScheduledTaskNotificationTargetFormData & ScheduledTaskNotificationTargetInternalFormData
    >(
        () => ({
            type: ScheduledTaskType.CREATE_PUSH_NOTIFICATION,
            triggerAt: initialData?.triggerAt || null,
            target,
            tag: initialData?.tag || '',
            segments: selectedSegments || [],
            locations: selectedLocations || []
        }),
        [initialData, selectedLocations, selectedSegments, target]
    );

    const handleSubmit = React.useCallback(
        (data: ScheduledTaskNotificationTargetFormData & ScheduledTaskNotificationTargetInternalFormData) => {
            onNext({
                type: data.type,
                triggerAt: data.triggerAt,
                tag: data.tag,
                segmentIds: data.segments.map((segment: Option) => segment.value),
                locationIds: data.locations.map((location: Option) => location.value),
                target: data.target
            });
        },
        [onNext]
    );
    if (!open) {
        return null;
    }
    return (
        <MuiForm
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount
        >
            {({ submitForm, isSubmitting, values: { target }, isValid }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SelectFormField
                                name="type"
                                options={typeOptions}
                                label={<MuiFormLabel required>Type</MuiFormLabel>}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectFormField
                                name="target"
                                options={targetOptions}
                                label={<MuiFormLabel required>Recipient(s)</MuiFormLabel>}
                                description="This is who the notification will be sent to. You can choose between sending
                                it to everyone, sending it to users who are currently checked in at a set of locations or
                                have favourited one of them, or sending it to the members in a set of audiences."
                            />
                        </Grid>
                        {target === NotificationTarget.AUDIENCE && (
                            <Grid item xs={12}>
                                <MultiSelectFormField
                                    name="segments"
                                    label={<MuiFormLabel required>Audiences</MuiFormLabel>}
                                    options={segments}
                                    isMulti
                                />
                            </Grid>
                        )}
                        {target === NotificationTarget.LOCATION && (
                            <Grid item xs={12}>
                                <MultiSelectFormField
                                    name="locations"
                                    label={<MuiFormLabel required>Locations</MuiFormLabel>}
                                    options={locations}
                                    isMulti
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextFormField
                                name="tag"
                                description="Add a label to this notification to group it with similar ones."
                                label={<MuiFormLabel>Tag</MuiFormLabel>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DateTimePickerFormField
                                name="triggerAt"
                                label={<MuiFormLabel required>Send Time</MuiFormLabel>}
                                description="The notification will start sending to users at the specific time. For larger
                                audiences the delivery time for all users may be extended as the request is processed."
                                disablePast
                                disableIgnoringDatePartForTimeValidation
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Row gutter align="flex-end" valign="center">
                                <Button variant="outlined" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting || !isValid}
                                    loading={isSubmitting}
                                    onClick={submitForm}
                                >
                                    Next
                                </LoadingButton>
                            </Row>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </MuiForm>
    );
};
