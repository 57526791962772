import React from 'react';
import { Option } from 'lib/types';
import { MenuItem, Select } from '@mui/material';

const ANY_STATUS = 'ANY';

interface RuleActiveFilterProps {
    onChange: (value?: string) => void;
    value?: string;
}

export const RuleActiveLabels: Record<number, string> = {
    [`${true}`]: 'Running',
    [`${false}`]: 'Paused'
};

const options = Object.entries(RuleActiveLabels).map(([key, value]) => ({
    label: value,
    value: key
}));

export const RuleActiveFilter: React.FC<RuleActiveFilterProps> = ({ value, onChange }) => {
    const handleStateChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === ANY_STATUS) {
                onChange(undefined);
            } else {
                onChange(e.target.value);
            }
        },
        [onChange]
    );
    const renderOption = React.useCallback(
        (option: Option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ),
        []
    );
    return (
        <Select value={value ?? ANY_STATUS} onChange={handleStateChange} variant="outlined" size="small">
            <MenuItem value={ANY_STATUS}>All Statuses</MenuItem>
            {options.map(renderOption)}
        </Select>
    );
};
