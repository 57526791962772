import React from 'react';
import { styled } from '@mui/material';
import { MuiModal } from 'ui/MuiModal';
import { ContentModule } from 'components/content/models/ContentModule';
import { ConfirmDialog } from 'ui/dialogs/ConfirmDialog';
import { CreateRuleResource, RuleResource } from 'components/rules/rules.types';
import { rulesApi } from 'components/rules/rulesApi';
import { RuleScheduledTaskNotificationStepper } from './RuleScheduledTaskNotificationStepper';

interface UpdateRuleScheduledTaskNotificationModalProps {
    open: boolean;
    onClose?: () => void;
    onError: (message: string) => void;
    onSuccess: (rule: RuleResource) => void;
    rule: RuleResource;
    modules: ContentModule[];
}

const StyledMuiModal = styled(MuiModal)(() => ({
    overflow: 'visible' // Needed for the dropdowns
}));

export const UpdateRuleScheduledTaskNotificationModal: React.FunctionComponent<
    UpdateRuleScheduledTaskNotificationModalProps
> = props => {
    const { open, onClose } = props;
    return (
        <StyledMuiModal open={open} onClose={onClose}>
            <UpdateRuleScheduledTaskNotificationModalComponent {...props} />
        </StyledMuiModal>
    );
};

const UpdateRuleScheduledTaskNotificationModalComponent: React.FC<
    UpdateRuleScheduledTaskNotificationModalProps
> = props => {
    const { onClose, onError, onSuccess, rule, modules } = props;

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const onSubmit = React.useCallback(
        async (data: CreateRuleResource) => {
            const result = await rulesApi.update(rule._id, {
                body: data
            });

            if (!result.ok) {
                onError(result.body.message);
            } else {
                onSuccess(result.body);
            }
        },
        [onError, onSuccess, rule._id]
    );
    const handleOnClose = React.useCallback(() => {
        setOpenConfirm(true);
    }, [setOpenConfirm]);
    const handleDialogOnCancel = React.useCallback(() => {
        setOpenConfirm(false);
    }, [setOpenConfirm]);

    return (
        <>
            <StyledMuiModal open={true} onClose={handleOnClose}>
                <RuleScheduledTaskNotificationStepper
                    initialData={rule}
                    modules={modules}
                    onClose={handleOnClose}
                    onSubmit={onSubmit}
                />
            </StyledMuiModal>
            <ConfirmDialog
                open={openConfirm}
                title="Hold On!"
                content="Are you sure you want to cancel this triggered notification update process? All changes you made will be lost."
                onCancel={handleDialogOnCancel}
                onConfirm={onClose}
                cancelLabel={"No, don't cancel"}
                confirmLabel={'Yes, cancel'}
            />
        </>
    );
};
