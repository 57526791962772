import { isNumber } from 'lib/typeguards';

const numberFormatter = new Intl.NumberFormat('en-GB', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
});

export function decimalValueFormatter(value: string | number) {
    return isNumber(value) ? numberFormatter.format(value) : '-';
}

export function numberValueFormatter(value: string | number) {
    return isNumber(value) ? String(value) : '-';
}

export const deepDiffMapper = (function () {
    return {
        VALUE_CREATED: 'created',
        VALUE_UPDATED: 'updated',
        VALUE_DELETED: 'deleted',
        VALUE_UNCHANGED: 'unchanged',
        map(obj1: any, obj2: any) {
            if (this.isFunction(obj1) || this.isFunction(obj2)) {
                throw new Error('Invalid argument. Function given, object expected.');
            }
            if (this.isValue(obj1) || this.isValue(obj2)) {
                return {
                    type: this.compareValues(obj1, obj2),
                    data: obj2
                };
            }

            const diff: any = {};
            for (const key in obj1) {
                if (this.isFunction(obj1[key])) {
                    continue;
                }

                let value2 = undefined;
                if (obj2[key] !== undefined) {
                    value2 = obj2[key];
                }

                diff[key] = this.map(obj1[key], value2);
            }
            for (const key in obj2) {
                if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
                    continue;
                }

                diff[key] = this.map(undefined, obj2[key]);
            }

            return diff;
        },
        compareValues(value1: any, value2: any) {
            if (value1 === value2) {
                return this.VALUE_UNCHANGED;
            }
            if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
                return this.VALUE_UNCHANGED;
            }
            if (value1 === undefined) {
                return this.VALUE_CREATED;
            }
            if (value2 === undefined) {
                return this.VALUE_DELETED;
            }
            return this.VALUE_UPDATED;
        },
        isFunction(x: any) {
            return Object.prototype.toString.call(x) === '[object Function]';
        },
        isArray(x: any) {
            return Object.prototype.toString.call(x) === '[object Array]';
        },
        isDate(x: any) {
            return Object.prototype.toString.call(x) === '[object Date]';
        },
        isObject(x: any) {
            return Object.prototype.toString.call(x) === '[object Object]';
        },
        isValue(x: any) {
            return !this.isObject(x) && !this.isArray(x);
        }
    };
})();

export function defaultDateTimeValueFormatter(value: string) {
    return new Date(value);
}
