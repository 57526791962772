import React from 'react';
import { Chip, Grid, styled, Tooltip, Typography } from '@mui/material';
import { ChipClassKey } from '@mui/material/Chip';
import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import clsx from 'clsx';
import { format, isValid } from 'date-fns';
import { titleize } from 'lib/helpers';
import { isArray } from 'lib/typeguards';
import { Column, Row } from 'ui/Flex';

const PREFIX = 'CardViewTitle';

const classes = {
    chipRoot: `${PREFIX}-chipRoot`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.chipRoot}`]: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export const CardViewTitle: React.FC<CardViewRowProps> = ({ title, tooltip, noTransform }) => {
    if (!title) {
        return null;
    }

    if (!!tooltip) {
        return (
            <Tooltip placement="left" title={tooltip}>
                <Typography variant="body2" sx={{ wordWrap: 'break-word' }} gutterBottom>
                    <b>{noTransform ? title : titleize(title)}</b>
                </Typography>
            </Tooltip>
        );
    }
    if (!!title) {
        return (
            <Typography variant="body2" sx={{ wordWrap: 'break-word' }} gutterBottom>
                <b>{noTransform ? title : titleize(title)}</b>
            </Typography>
        );
    }

    return null;
};

interface CardViewRowProps {
    title?: string;
    value?: boolean | string | any[];
    tooltip?: string;
    fallback?: string;
    vertical?: boolean;
    noTransform?: boolean;
}

export function CardViewSwitchRow(props: CardViewRowProps) {
    const { value, vertical } = props;
    return (
        <Grid item xs={12}>
            {vertical ? (
                <Column align="space-between" valign="center" flex={1}>
                    <CardViewTitle {...props} />
                    {!!value && <Done />}
                    {!value && <Close />}
                </Column>
            ) : (
                <Row align="space-between" valign="center" flex={1}>
                    <CardViewTitle {...props} />
                    {!!value && <Done />}
                    {!value && <Close />}
                </Row>
            )}
        </Grid>
    );
}

export function CardViewTextRow(props: CardViewRowProps) {
    const { value, fallback } = props;
    return (
        <Grid item xs={12}>
            <CardViewTitle {...props} />
            <CardViewTextRowValue value={value && String(value)} fallback={fallback} />
        </Grid>
    );
}

export function CardViewDateRow(props: CardViewRowProps) {
    const { value, fallback } = props;
    const date = value ? new Date(String(value)) : undefined;
    let resultString = '';
    if (!!date && isValid(date)) {
        resultString = format(date, 'dd/MM/yyyy');
    }
    return (
        <Grid item xs={12}>
            <CardViewTitle {...props} />
            <CardViewTextRowValue value={resultString} fallback={fallback} />
        </Grid>
    );
}

export function CardViewTextRowValue(props: CardViewRowProps) {
    const { value, fallback } = props;

    return value ? (
        <Typography sx={{ wordWrap: 'break-word' }}>{value}</Typography>
    ) : (
        <Typography color="textSecondary" variant="body2">
            {fallback || 'N/A'}
        </Typography>
    );
}

export interface CardViewChipRowProps extends CardViewRowProps {
    chipClasses?: Partial<Record<ChipClassKey, string>>;
}

export function CardViewChipRow(props: CardViewChipRowProps) {
    const { value, chipClasses, fallback } = props;
    return (
        <Grid item xs={12}>
            <CardViewTitle {...props} />
            {value ? (
                <Chip classes={chipClasses} label={value} />
            ) : (
                <Typography color="textSecondary" variant="body2">
                    {fallback || 'N/A'}
                </Typography>
            )}
        </Grid>
    );
}

export function CardViewChipArrayRow(props: CardViewChipRowProps) {
    const { value, chipClasses, fallback } = props;

    const nonEmptyValues = (value && isArray(value) && value.filter(item => item !== '')) || [];

    return (
        <StyledGrid item xs={12}>
            <CardViewTitle {...props} />
            {nonEmptyValues.length > 0 ? (
                nonEmptyValues.map((item, index) => (
                    <Chip
                        classes={{ ...chipClasses, root: clsx(classes.chipRoot, chipClasses?.root) }}
                        key={index + item}
                        label={item}
                    />
                ))
            ) : (
                <Typography variant="body2" color="textSecondary">
                    {fallback || 'N/A'}
                </Typography>
            )}
        </StyledGrid>
    );
}

CardViewChipArrayRow.defaultProps = { chipClasses: {} };
