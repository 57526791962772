import React from 'react';
import { HttpClientResponseContent } from 'lib/HttpClient';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_BIRTHDAY_LOYALTY_CREATE_SUCCESS,
    MESSAGE_BIRTHDAY_LOYALTY_EDIT_ERROR,
    MESSAGE_BIRTHDAY_LOYALTY_EDIT_SUCCESS
} from 'config/messages';
import logger from 'lib/logger';
import { EditPremiumLoyaltyForm, UserMetricDefinition } from 'components/metrics/model';
import { metricsApi } from 'components/metrics/metricsApi';
import { EPremiumLoyaltyScheme } from 'components/metrics/hooks/useUserMetricDefinition';

export function useBirthdayLoyaltyEditModal(
    metricDefinition: UserMetricDefinition | undefined,
    onUpdate: (value: UserMetricDefinition) => void,
    onCloseEffect: () => void
) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handleOpen = React.useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = React.useCallback(() => {
        setOpen(false);
        onCloseEffect();
    }, [onCloseEffect]);
    const handleSubmit = React.useCallback(
        async (data: EditPremiumLoyaltyForm) => {
            setLoading(true);
            let result: HttpClientResponseContent<UserMetricDefinition>;
            const query = {
                ...(metricDefinition?.query || {})
            };
            if (!metricDefinition) {
                result = await metricsApi.create({
                    body: {
                        name: data.name,
                        displayName: data.name,
                        realtime: false,
                        scheduled: false,
                        metrics: {},
                        effects: [],
                        tags: [EPremiumLoyaltyScheme.Birthday],
                        recomputeOn: ['USER_CREATED', 'USER_CHANGED']
                    }
                });
            } else {
                result = await metricsApi.update(metricDefinition?._id, {
                    body: {
                        name: data.name,
                        query
                    }
                });
            }
            setLoading(false);
            if (!result.ok) {
                logger.error(result.body.message, result.body);
                dispatch(enqueueSnackbar(MESSAGE_BIRTHDAY_LOYALTY_EDIT_ERROR, { variant: 'error' }));
                return;
            }
            if (!metricDefinition) {
                dispatch(enqueueSnackbar(MESSAGE_BIRTHDAY_LOYALTY_CREATE_SUCCESS, { variant: 'success' }));
            } else {
                dispatch(enqueueSnackbar(MESSAGE_BIRTHDAY_LOYALTY_EDIT_SUCCESS, { variant: 'success' }));
            }
            onUpdate(result.body);
            setOpen(false);
            onCloseEffect();
        },
        [dispatch, metricDefinition, onCloseEffect, onUpdate]
    );
    return {
        editModalOpen: open,
        handleDefinitionEditOpen: handleOpen,
        handleDefinitionEditClose: handleClose,
        handleDefinitionEditSubmit: handleSubmit,
        loading
    };
}
