import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MainLayout } from 'layouts/MainLayout';
import { CAMPAIGNS_SCHEDULED } from 'config/routes';
import { isDefined, isString } from 'lib/typeguards';
import logger from 'lib/logger';
import {
    isScheduledTaskOfType,
    ScheduledTask,
    ScheduledTaskType
} from 'components/scheduled-tasks/scheduledTasks.types';
import { scheduledTasksApi } from 'components/scheduled-tasks/scheduledTasksApi';
import { ScheduledTaskNotificationView } from 'components/scheduled-tasks/notification/ScheduledTaskNotificationView';
import { notificationApi } from 'components/notification/notificationApi';
import { QueryParameter } from 'lib/HttpClient';

const getScheduledTask = async (id: string): Promise<ScheduledTask<any>> => {
    try {
        const response = await scheduledTasksApi.get(id);

        if (response.ok) {
            if (isScheduledTaskOfType(ScheduledTaskType.CREATE_PUSH_NOTIFICATION, response.body)) {
                return response.body;
            }
            throw new Error(
                `Expected ScheduledTask<CREATE_PUSH_NOTIFICATION> but got ${JSON.stringify(response.body)}`
            );
        }
        throw new Error(response.body.message);
    } catch (error) {
        logger.error(error);
        return error;
    }
};

const getMetrics = async (
    id: string
): Promise<{ sent: number; skipped: number; failures: number } | undefined> => {
    try {
        const queryParameters: QueryParameter[] = [{ key: 'scheduledTaskId', value: id }];
        const response = await notificationApi.getAggregatedMetrics({ queryParameters });

        if (response.ok) {
            if (isDefined(response.body)) {
                return response.body;
            }
            throw new Error(
                `Expected ScheduledTask<CREATE_PUSH_NOTIFICATION> but got ${JSON.stringify(response.body)}`
            );
        } else {
            logger.error(response.body.message);
        }
    } catch (error) {
        logger.error(error);
        return error;
    }
};

const getRelatedNotifications = async (scheduledTask: ScheduledTask<any>) => {
    try {
        const response = await notificationApi.getList({
            queryParameters: [{ key: 'scheduledTaskId', value: scheduledTask._id }],
            headers: { 'x-api-version': 2 }
        });

        if (response.ok) {
            return response.body;
        }
        throw new Error(response.body.message);
    } catch (error) {
        logger.error(error);
        return error;
    }
};

const breadcrumbs = [
    {
        label: 'Scheduled Campaigns',
        url: CAMPAIGNS_SCHEDULED
    }
];

export const ScheduledCampaignViewPage = () => {
    const [scheduledTask, setScheduledTask] = React.useState(null);
    const [metrics, setMetrics] = React.useState(null);
    const [notifications, setNotifications] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const history = useHistory();
    const { scheduleId: id } = useParams<{ scheduleId: string }>();
    React.useEffect(() => {
        async function load() {
            try {
                if (!isString(id)) {
                    throw new Error(`Expected notification id, but got ${id}`);
                }
                const [newScheduledTask, newMetrics] = await Promise.all([
                    getScheduledTask(id),
                    getMetrics(id)
                ]);
                setScheduledTask(newScheduledTask);
                setMetrics(newMetrics);
                const newNotifications = await getRelatedNotifications(newScheduledTask);
                setNotifications(newNotifications.items);
            } catch (e) {
                history.push(CAMPAIGNS_SCHEDULED);
                setScheduledTask(null);
            } finally {
                setLoading(false);
            }
        }
        load();
    }, [history, id]);
    return (
        <MainLayout
            breadcrumbs={breadcrumbs}
            pageName="Schedule Details"
            pageDescription="View the results of your scheduled campaign"
        >
            <ScheduledTaskNotificationView
                scheduledTask={scheduledTask}
                notifications={notifications}
                metrics={metrics}
                loading={loading}
            />
        </MainLayout>
    );
};
