import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import { authApi } from 'components/auth/AuthApi';
import { SignUpForm } from 'components/auth/forms/SignUpForm';
import { MarketingLayout } from 'components/auth/MarketingLayout';
import { SignUpData } from 'components/auth/models/SignUpData';
import { FORGOT_YOUR_PASSWORD, SIGN_IN, USER_MERCHANTS } from 'config/routes';
import { signIn } from 'store/auth/authActions';
import { ApplicationState } from 'store/store';
import { Link } from 'ui/Link';
import logger from 'lib/logger';

export const SignUpPage: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [error, setError] = React.useState<string>();
    const { error: signInError } = useSelector((state: ApplicationState) => state.auth);
    const handleSubmit = React.useCallback(
        async (data: SignUpData) => {
            const { email, password } = data;
            // Sign-up
            try {
                setError(undefined);
                const result = await authApi.signUp(data);
                if (!result.ok) {
                    setError(result.body && result.body.message);
                    throw new Error(result.body.message);
                }
                // Sign-in
                await signIn(email, password)(dispatch);
                const params = new URLSearchParams(location.search);
                const redirectTo = params.get('redirectTo');
                history.push(redirectTo || USER_MERCHANTS);
            } catch (error) {
                logger.error(error);
            }
        },
        [dispatch, history, location.search]
    );
    return (
        <MarketingLayout
            after={
                <React.Fragment>
                    <Typography align="center" variant="caption" color="textSecondary">
                        <Link href={FORGOT_YOUR_PASSWORD}>Forgot your password? </Link>
                    </Typography>
                    <Typography align="center" variant="caption" color="textSecondary">
                        Already have an Account? <Link href={SIGN_IN}>Sign In</Link>
                    </Typography>
                </React.Fragment>
            }
        >
            <SignUpForm onSubmit={handleSubmit} error={error || signInError} />
        </MarketingLayout>
    );
};

export default SignUpPage;
