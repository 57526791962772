import React from 'react';
import { useLocation } from 'react-router';
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import { SnackbarProviderWrapper } from './SnackbarProvider';
import { MuiTheme } from 'config/theme';
import { MuiRouterContext } from 'lib/MuiRouterContext';
import { MuiNotifier } from 'ui/MuiNotifier';
import { ErrorBoundary } from './ErrorBoundary';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';

const cache = createCache({
    key: 'css'
});

interface AppWrapperProps {
    children: React.ReactNode;
}

export function AppWrapper(props: AppWrapperProps) {
    const { children } = props;
    const location = useLocation();

    return (
        <MuiRouterContext.Provider value={{ pathname: location.pathname }}>
            <CacheProvider value={cache}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={MuiTheme}>
                        <CssBaseline />
                        <SnackbarProviderWrapper>
                            <ErrorBoundary>
                                <MuiNotifier />
                                {/* fix the dateAdapter type after the material ui and mui-x upgrades */}
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    {children}
                                </LocalizationProvider>
                            </ErrorBoundary>
                        </SnackbarProviderWrapper>
                    </ThemeProvider>
                </StyledEngineProvider>
            </CacheProvider>
        </MuiRouterContext.Provider>
    );
}
