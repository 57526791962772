import { getCurrencyString } from 'lib/helpers';
import { isNumber } from 'lib/typeguards';
import React from 'react';

interface MuiGridPriceViewProps {
    value?: number | string;
    currencyCode: string;
}

export const MuiGridPriceView: React.FC<MuiGridPriceViewProps> = ({ value, currencyCode }) => {
    const formattedValue = React.useMemo(
        () => String(isNumber(value) ? getCurrencyString(value, currencyCode || 'GBP') : value),
        [currencyCode, value]
    );
    return <div>{formattedValue}</div>;
};
