/* eslint-disable max-len */
import { PerksGrid } from 'components/perks/PerksGrid';
import { EmptyState } from 'components/utils/emptyState';
import React from 'react';
import { useSelector } from 'react-redux';
import EmptyImage from 'static/perks-empty-state.svg';
import { ApplicationState } from 'store/store';
import { InfoOutlined, Link } from '@mui/icons-material';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { AwardCreationModal } from 'components/perks/award-enrichments/modals/AwardCreationModal';
import { LoyaltyProvider } from 'components/loyalty/enums';
import { AwardEditModal } from 'components/perks/award-enrichments/modals/AwardEditModal';
import { ConfirmDialog } from 'ui/dialogs/ConfirmDialog';
import { Box, Button, TextField, Tooltip, styled } from '@mui/material';
import { useExternalPerksData } from 'components/perks/hooks/external-perks/useExternalPerksData';
import { useExternalPerksUtils } from 'components/perks/hooks/external-perks/useExternalPerksUtils';
import { useExternalPerksMutation } from 'components/perks/hooks/external-perks/useExternalPerksMutation';
import { useExternalPerksModals } from 'components/perks/hooks/external-perks/useExternalPerksModals';
import { PerksSearchInfoTooltip } from 'components/perks/PerksSearchInfoTooltip';
import { MuiTheme } from 'config/theme';
import { GridStorageName } from 'lib/MuiGrid/StateController';

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
    alignItems: 'flex-end'
}));

const loyaltyProviderToLabel: Record<string, string> = {
    ATREEMO: 'Atreemo',
    REVEL: 'Revel',
    SQUARE: 'Square',
    POWERCARD: 'Powercard'
};

interface IProps {
    loyaltyProvider: string;
}

export const ExternalPerksView: React.FC<IProps> = ({ loyaltyProvider }) => {
    const { awardEnrichments, loading, searchValue, setAwardEnrichments, handleSearchValueChange } =
        useExternalPerksData();

    const {
        selectedAward,
        rowSelectionModel,
        selectedToEdit,
        selectedToDuplicate,
        indexToDelete,
        setIndexToDelete,
        handleSelectRow,
        handleReviewAndEditClick,
        handleDuplicateClick,
        handleDeleteClick,
        handleResetEditSelection,
        handleResetDuplicateSelection
    } = useExternalPerksUtils({ awardEnrichments });

    const {
        isCreateOpen,
        isCreateConfirmationOpen,
        isUpdateConfirmationOpen,
        setIsCreateOpen,
        setIsCreateConfirmationOpen,
        setIsUpdateConfirmationOpen,
        handleCloseCreateDialog,
        handleCloseUpdateDialog,
        handleCloseDeleteDialog
    } = useExternalPerksModals({
        handleResetEditSelection,
        handleResetDuplicateSelection,
        setIndexToDelete
    });

    const { isProcessing, handleCreateSubmit, handleUpdateSuccess, handleConfirmDelete } =
        useExternalPerksMutation({
            awardEnrichments,
            indexToDelete,
            selectedToDuplicate,
            setIsCreateOpen,
            setAwardEnrichments,
            setIndexToDelete,
            handleResetEditSelection,
            handleResetDuplicateSelection
        });

    const currencySymbol = useSelector(
        (state: ApplicationState) => state.settings.settings.region?.currencySymbol
    );

    const primaryActionBackgroundColour = useSelector(
        (state: ApplicationState) =>
            state.content.currentLayout?.anchor.properties.primaryActionBackgroundColour
    );

    const columnsToExclude = React.useMemo(
        () => ['_id', 'internalTitle', 'template', 'isGlobal', 'enabled', 'auto', 'locations'],
        []
    );

    const deleteDialogPerkTitle = React.useMemo(() => {
        if (indexToDelete >= 0 && awardEnrichments.length > 0 && awardEnrichments[indexToDelete]?.title) {
            return `Remove "${awardEnrichments[indexToDelete].title}" award?`;
        }
        return 'Remove award?';
    }, [indexToDelete, awardEnrichments]);

    const renderSearchInfoButton = React.useCallback(
        () => (
            <Tooltip title={<PerksSearchInfoTooltip excludedColumns={columnsToExclude} />}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: MuiTheme.palette.grey[600],
                        cursor: 'default'
                    }}
                >
                    <InfoOutlined />
                </Box>
            </Tooltip>
        ),
        [columnsToExclude]
    );

    return (
        <>
            {!loading && awardEnrichments.length === 0 && (
                <EmptyState
                    headerText="You haven't linked any awards yet "
                    paragraphText={`Awards can be awarded to users in ${loyaltyProviderToLabel[loyaltyProvider]} to increase app usage & encourage repeat purchases.`}
                    buttonText={`LINK ${loyaltyProvider} AWARD`}
                    imageUrl={EmptyImage}
                    onClick={setIsCreateOpen}
                />
            )}

            {(loading || awardEnrichments.length > 0) && (
                <>
                    <StyledBox>
                        <TextField
                            value={searchValue}
                            label={'Search by field'}
                            InputProps={{ endAdornment: renderSearchInfoButton() }}
                            onChange={handleSearchValueChange}
                            sx={{ width: 350 }}
                        />
                        <Button
                            variant="contained"
                            sx={{ marginLeft: 'auto' }}
                            startIcon={<Link />}
                            onClick={setIsCreateOpen}
                            size="small"
                        >
                            Link {loyaltyProvider} Award
                        </Button>
                        <Button
                            variant="outlined"
                            disabled={!selectedAward}
                            onClick={handleReviewAndEditClick}
                            size="small"
                        >
                            Review & Edit
                        </Button>
                        {selectedAward && (
                            <Button
                                variant="outlined"
                                disabled={!selectedAward}
                                onClick={handleDuplicateClick}
                                size="small"
                            >
                                Duplicate
                            </Button>
                        )}
                        <Button
                            variant="outlined"
                            disabled={!selectedAward}
                            onClick={handleDeleteClick}
                            size="small"
                        >
                            Delete
                        </Button>
                    </StyledBox>
                    <PerksGrid
                        data={awardEnrichments}
                        isLoading={loading}
                        columnsToExclude={columnsToExclude}
                        selectedRow={rowSelectionModel}
                        searchValue={searchValue}
                        handleSelectRow={handleSelectRow}
                        storageName={GridStorageName.ExternalPerks}
                    />
                </>
            )}

            <ConfirmDialog
                confirmLabel="Yes, Cancel"
                cancelLabel="No, don't cancel"
                title="Hold On!"
                content="Are you sure you want to cancel linking this award? All changes you made will be lost."
                open={isCreateConfirmationOpen}
                onConfirm={handleCloseCreateDialog}
                onCancel={setIsCreateConfirmationOpen}
            />
            <ConfirmDialog
                confirmLabel="Yes, Cancel"
                cancelLabel="No, don't cancel"
                title="Hold On!"
                content="Are you sure you want to cancel updating this award? All changes you made will be lost."
                open={isUpdateConfirmationOpen}
                onConfirm={handleCloseUpdateDialog}
                onCancel={setIsUpdateConfirmationOpen}
            />
            <DeleteDialog
                title={deleteDialogPerkTitle}
                // eslint-disable-next-line max-len
                content="If you remove this award, you won't be able to recover it. To confirm deletion, type 'DELETE' in the text input field."
                open={indexToDelete >= 0}
                onDelete={handleConfirmDelete}
                onClose={handleCloseDeleteDialog}
                loading={isProcessing}
                protectionWord="DELETE"
            />
            <AwardCreationModal
                currencySymbol={currencySymbol}
                duplicateAward={selectedToDuplicate}
                open={isCreateOpen || !!selectedToDuplicate}
                primaryActionBackgroundColour={primaryActionBackgroundColour}
                onClose={setIsCreateConfirmationOpen}
                onSubmit={handleCreateSubmit}
                loyaltyProvider={loyaltyProvider as LoyaltyProvider}
            />
            <AwardEditModal
                currencySymbol={currencySymbol}
                primaryActionBackgroundColour={primaryActionBackgroundColour}
                open={!!selectedToEdit}
                onClose={setIsUpdateConfirmationOpen}
                onSubmit={handleUpdateSuccess}
                loyaltyProvider={loyaltyProvider as LoyaltyProvider}
                award={selectedToEdit}
            />
        </>
    );
};
