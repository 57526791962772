import React from 'react';
import { GridColTypeDef, GridRenderEditCellParams, GridSortCellParams } from '@mui/x-data-grid-pro';
import { renderMuiGridTextView } from 'lib/MuiGrid/text/MuiGridTextView';
import { isDefined, isString } from 'lib/typeguards';
import { MuiGridSelect } from 'lib/MuiGrid/select/MuiGridSelect';
import { ModifierOptionsMap } from './model/menu';

interface SelectedModifierSelectProps extends GridRenderEditCellParams {
    labelsMap: ModifierOptionsMap;
}

const SelectedModifierSelect: React.FC<SelectedModifierSelectProps> = ({ labelsMap, ...props }) => {
    const options = React.useMemo(() => {
        const labels = labelsMap.get(isString(props.id) ? props.id : '');
        if (!labels) {
            return [];
        }
        return Object.entries(labels).map(([value, label]) => ({ value, label: label.title }));
    }, [labelsMap, props.id]);
    return <MuiGridSelect {...props} options={options} multiple />;
};

function renderSelectedModifierGridColumn(props: GridRenderEditCellParams, labelsMap: ModifierOptionsMap) {
    return <SelectedModifierSelect {...props} labelsMap={labelsMap} />;
}

export function SelectedModifierGridColumn(labelsMap: ModifierOptionsMap): GridColTypeDef {
    const getName = (value: string[], props: GridSortCellParams) =>
        Array.isArray(value) && isDefined(labelsMap?.get('' + props.id))
            ? value.map((item: string) => labelsMap.get('' + props.id)[item]?.title).join(', ')
            : '';
    const sort = (
        v1: string[],
        v2: string[],
        cellParams1: GridSortCellParams,
        cellParams2: GridSortCellParams
    ) => getName(v1, cellParams1).localeCompare(getName(v2, cellParams2));
    return {
        sortComparator: sort,
        renderCell: (props: any) =>
            renderMuiGridTextView(
                props,
                Array.isArray(props.value) && isDefined(labelsMap?.get(props.id))
                    ? props.value.map((item: string) => labelsMap.get(props.id)[item]?.title).join(', ')
                    : ''
            ),
        renderEditCell: props => renderSelectedModifierGridColumn(props, labelsMap)
    };
}
