/* eslint-disable @typescript-eslint/member-ordering */
import * as React from 'react';
import { LocationOpeningHoursCardComponent } from 'components/location/cards/LocationOpeningHoursCardComponent';
import { createHTMLLocationOpeningHoursForm } from 'components/location/forms/createLocationOpeningHoursForm';
import { DAYS_OF_THE_WEEK, Location, LocationOpeningHours } from 'components/location/models/LocationModel';
import { LegacyForm } from 'lib/LegacyForm';
import { AutoForm } from 'ui/form/AutoForm';
import { Panel } from 'ui/Panel';
import { locationApi } from '../LocationApi';
import { Box } from '@mui/system';
import { LocationOpeningHoursEditForm } from '../forms/LocationOpeningHoursEditForm';

interface LocationOpeningHoursCardProps {
    currentLocation: Location;
    setCurrentLocation: (location: Location) => void;
}

interface RequestBody {
    openingHours?: LocationOpeningHours;
    alternativeOpeningHoursText?: string;
}

type OpeningHoursMode = 'STANDART' | 'HTML';

interface State {
    isEditing: OpeningHoursMode | null;
}

export class LocationOpeningHoursCard extends React.Component<LocationOpeningHoursCardProps, State> {
    state: State = {
        isEditing: null
    };
    handleSubmit = async (data: LocationOpeningHours & { alternativeOpeningHoursText: string }) => {
        const { alternativeOpeningHoursText, ...rest } = data;
        const locationId = this.props.currentLocation._id;
        const body: RequestBody = {};
        if (this.state.isEditing === 'STANDART') {
            body.openingHours = DAYS_OF_THE_WEEK.reduce((acc, day) => {
                acc[day] = rest[day];

                return acc;
            }, {} as LocationOpeningHours);
        } else {
            body.alternativeOpeningHoursText = alternativeOpeningHoursText;
        }

        const response = await locationApi.update(locationId, { body });
        if (response.ok) {
            this.updateCustomOpeningHours(response.body);
            this.props.setCurrentLocation(response.body);
        }
        this.setState({ isEditing: null });
    };
    htmlOpeningHoursForm = new LegacyForm(createHTMLLocationOpeningHoursForm(), this.handleSubmit);
    componentDidMount() {
        this.updateCustomOpeningHours(this.props.currentLocation);
    }
    updateCustomOpeningHours = ({ alternativeOpeningHoursText }: Location) => {
        this.htmlOpeningHoursForm.update({ alternativeOpeningHoursText });
    };
    toggleState = (type: OpeningHoursMode) => () => {
        this.setState(state => ({ isEditing: type === state.isEditing ? null : type }));

        if (type === 'HTML') {
            this.updateCustomOpeningHours(this.props.currentLocation);
        }
    };
    render() {
        const { isEditing } = this.state;
        return (
            <>
                <Box marginBottom={2}>
                    <Panel
                        title="Opening Hours"
                        showAction={!isEditing}
                        onClick={this.toggleState('STANDART')}
                        divider
                    >
                        {isEditing === 'STANDART' ? (
                            <LocationOpeningHoursEditForm
                                onSubmit={this.handleSubmit}
                                onClose={this.toggleState('STANDART')}
                                location={this.props.currentLocation}
                            />
                        ) : (
                            <LocationOpeningHoursCardComponent
                                mode="STANDART"
                                location={this.props.currentLocation}
                            />
                        )}
                    </Panel>
                </Box>
                <Box marginTop={2}>
                    <Panel
                        title="Custom opening hours"
                        showAction={!isEditing}
                        onClick={this.toggleState('HTML')}
                        divider
                    >
                        {isEditing === 'HTML' ? (
                            <AutoForm onClose={this.toggleState('HTML')} form={this.htmlOpeningHoursForm} />
                        ) : (
                            <LocationOpeningHoursCardComponent
                                mode="HTML"
                                location={this.props.currentLocation}
                            />
                        )}
                    </Panel>
                </Box>
            </>
        );
    }
}
