import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Step, StepButton, StepLabel, Stepper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AwardTemplate } from 'components/customers/models/Award';
import { Perk } from 'components/loyalty/models/PerkModel';
import { perkApi } from 'components/loyalty/PerkApi';
import { MESSAGE_MARKETING_PERK_CREATE_ERROR, MESSAGE_MARKETING_PERK_CREATE_SUCCESS } from 'config/messages';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { IconButton } from 'ui/buttons/IconButton';
import { Column, Row } from 'ui/Flex';
import { perkGeneralDefaultFormValues, PerkGeneralForm, PerkGeneralFormData } from './forms/PerkGeneralForm';
import { PerkReviewForm } from './forms/PerkReviewForm';
import { PerkSettingsForm, PerkSettingsFormData } from './forms/PerkSettingsForm';
import { PerkAddEffectFormData, PerkClubForm } from './forms/PerkClubForm';
import { generateCreatePerkBody, generatePerkGrantImpactBody, generatePerkGrantRevokeBody } from './helpers';
import { rulesApi } from 'components/rules/rulesApi';
import { PerksActionType } from 'store/perks/perksActionTypes';
import { ApplicationState } from 'store/store';
import {
    generateAwardRedemptionFormData,
    generateSettingsFormData,
    generateVisualisationFormData
} from './award-enrichments/utils';
import { AwardRedemptionFormData, PerkRedemptionForm } from './forms/RedemptionForm/PerkRedemptionForm';
import {
    PerkVisualisationForm,
    PerkVisualisationFormData
} from 'components/perks/forms/PerkVisualisationForm';

export interface PerksCreationStepperProps {
    onClose: () => void;
    onSubmit: ({ perk, stepFour }?: { perk: Perk; stepFour?: boolean }) => void;
    currencySymbol: string;
    primaryActionBackgroundColour: string;
    initialGeneralData?: Partial<PerkGeneralFormData>;
    duplicatePerk?: Perk | null;
}

const createAdHoc = async (body: Partial<Perk>) => {
    const perkResult = await perkApi.create({ body });
    if (!perkResult.ok) {
        throw new Error(perkResult.body.message);
    }
    return perkResult;
};

const createPseudoCurrency = async (body: Partial<Perk>) => {
    const perkResult = await perkApi.create({ body });
    if (!perkResult.ok) {
        throw new Error(perkResult.body.message);
    }
    return perkResult;
};

const createStampCard = async (body: Partial<Perk>) => {
    const perkResult = await perkApi.create({ body });

    return perkResult;
};

const createPerk = (perk: Partial<Perk>, settingsData: PerkSettingsFormData) => {
    switch (settingsData.perkType) {
        case AwardTemplate.PSEUDO_CURRENCY:
            return createPseudoCurrency(perk);
        case AwardTemplate.STAMP_CARD:
            return createStampCard(perk);
        default:
            return createAdHoc(perk);
    }
};

export const PerksCreationStepper: React.FC<PerksCreationStepperProps> = ({
    onClose,
    onSubmit,
    initialGeneralData = {},
    currencySymbol,
    duplicatePerk,
    primaryActionBackgroundColour
}) => {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const [steps, setSteps] = React.useState([
        'Define',
        'Configure',
        'Visualisation',
        'Redemption',
        'Review'
    ]);
    const [perkIsCreated, setPerkIsCreated] = React.useState(false);
    const [isLoadingAddTrigger, setIsLoadingAddTrigger] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [generalData, setGeneralData] = React.useState<PerkGeneralFormData>({
        ...perkGeneralDefaultFormValues,
        ...initialGeneralData,
        ...(duplicatePerk && {
            title: duplicatePerk.title,
            order: duplicatePerk.priority,
            internalTitle: duplicatePerk.internalTitle,
            description: duplicatePerk.visualisation.description
        })
    });
    const [perkId, setPerkId] = React.useState();
    const [settingsData, setSettingsData] = React.useState<PerkSettingsFormData>(
        duplicatePerk && generateSettingsFormData(duplicatePerk)
    );
    const [visualisationData, setVisualisationData] = React.useState<PerkVisualisationFormData>(
        duplicatePerk && generateVisualisationFormData(duplicatePerk)
    );
    const [redemptionData, setRedemptionData] = React.useState<AwardRedemptionFormData>(
        duplicatePerk && generateAwardRedemptionFormData(duplicatePerk)
    );
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useDispatch();

    const handleStepClick = React.useCallback(
        (index: number) => () => {
            if (!perkIsCreated) {
                setActiveStep(index);
            }
        },
        [perkIsCreated]
    );
    const handleGeneralSubmit = React.useCallback((data: PerkGeneralFormData) => {
        setGeneralData(data);
        setActiveStep(1);
    }, []);
    const handleSettingsSubmit = React.useCallback(
        (data: PerkSettingsFormData) => {
            setSettingsData(data);
            setVisualisationData({ ...visualisationData, perkType: data.perkType });
            setActiveStep(2);
        },
        [visualisationData]
    );
    const handleVisualisationSubmit = React.useCallback((data: PerkVisualisationFormData) => {
        setVisualisationData(data);
        setActiveStep(3);
    }, []);
    const handleRedemptionSubmit = React.useCallback((values: AwardRedemptionFormData) => {
        setRedemptionData(values);
        setActiveStep(4);
    }, []);
    const handlePrevious = React.useCallback(() => {
        setActiveStep(activeStep - 1);
    }, [activeStep]);

    const handleCreatePerk = React.useCallback(async () => {
        setIsLoading(true);
        const body = generateCreatePerkBody(
            generalData,
            settingsData,
            redemptionData,
            visualisationData,
            settings?.region?.timezone,
            !!settings?.app?.awardsScreenBarcodePosition
        );
        try {
            const result = await createPerk(body, settingsData);
            dispatch(enqueueSnackbar(MESSAGE_MARKETING_PERK_CREATE_SUCCESS, { variant: 'success' }));
            return onSubmit({ perk: result.body });
        } catch (e) {
            dispatch(enqueueSnackbar(MESSAGE_MARKETING_PERK_CREATE_ERROR, { variant: 'error' }));
        } finally {
            setIsLoading(false);
        }
    }, [
        generalData,
        settingsData,
        redemptionData,
        visualisationData,
        settings?.region?.timezone,
        settings?.app?.awardsScreenBarcodePosition,
        dispatch,
        onSubmit
    ]);

    const handleCreatePerkContinueAddTrigger = React.useCallback(async () => {
        setIsLoadingAddTrigger(true);
        if (perkIsCreated) {
            setActiveStep(5);
        } else {
            const body = generateCreatePerkBody(
                generalData,
                settingsData,
                redemptionData,
                visualisationData,
                settings?.region?.timezone,
                !!settings?.app?.awardsScreenBarcodePosition
            );
            try {
                const result = await createPerk(body, settingsData);
                setPerkId(result.body._id);
                dispatch(enqueueSnackbar(MESSAGE_MARKETING_PERK_CREATE_SUCCESS, { variant: 'success' }));
                onSubmit({ perk: result.body, stepFour: true });
                setPerkIsCreated(true);
                setActiveStep(5);
            } catch (e) {
                dispatch(enqueueSnackbar(MESSAGE_MARKETING_PERK_CREATE_ERROR, { variant: 'error' }));
            } finally {
                setIsLoadingAddTrigger(false);
            }
        }
    }, [
        dispatch,
        generalData,
        onSubmit,
        perkIsCreated,
        redemptionData,
        settings?.region?.timezone,
        settings?.app?.awardsScreenBarcodePosition,
        settingsData,
        visualisationData
    ]);

    const handleAddTriggerToPerk = React.useCallback(
        async (values: PerkAddEffectFormData) => {
            const { grantPerkFromImpact, revokeFromImpact, audienceId } = values;
            setIsLoadingAddTrigger(true);
            try {
                const bodyImpact = generatePerkGrantImpactBody(audienceId, perkId);
                const bodyRevoke = generatePerkGrantRevokeBody(audienceId, perkId);

                if (grantPerkFromImpact && revokeFromImpact) {
                    await rulesApi.create({ body: bodyImpact });
                    await rulesApi.create({ body: bodyRevoke });
                }
                if (grantPerkFromImpact && !revokeFromImpact) {
                    await rulesApi.create({ body: bodyImpact });
                }
                if (revokeFromImpact && !grantPerkFromImpact) {
                    await rulesApi.create({ body: bodyRevoke });
                }

                dispatch({ type: PerksActionType.TOGGLE_CREATE_MODAL });
                dispatch(enqueueSnackbar('Triggers successfully added to perk.', { variant: 'success' }));
            } catch (e) {
                dispatch(enqueueSnackbar('Something went wrong.', { variant: 'error' }));
            } finally {
                setIsLoadingAddTrigger(false);
            }
        },
        [dispatch, perkId]
    );

    const onPerkTypeChange = React.useCallback(
        (perkType: AwardTemplate) => {
            if (perkType === AwardTemplate.CLUB) {
                if (steps.length < 6) {
                    setSteps(previousSteps => [...previousSteps, 'Audience']);
                }
            } else if (steps.length === 6) {
                setSteps(['Define', 'Configure', 'Visualisation', 'Redemption', 'Review']);
            }
        },
        [steps]
    );
    const hasFiveSteps = React.useMemo(() => steps.length === 6, [steps]);

    return (
        <Box width="100%" maxWidth={1600}>
            <Column>
                <Row valign="flex-start" align="center">
                    <Box flex={1} maxWidth={500}>
                        <Stepper nonLinear alternativeLabel activeStep={activeStep}>
                            {steps.map((item, index) => (
                                <Step key={`step-button-${item}`} completed={activeStep > index}>
                                    <StepButton
                                        disableRipple
                                        disabled={activeStep < index}
                                        onClick={handleStepClick(index)}
                                        color="inherit"
                                    >
                                        <StepLabel>{item}</StepLabel>
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <Box position="absolute" top={0} right={0}>
                        <IconButton size="large" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Row>
                <PerkGeneralForm
                    open={activeStep === 0}
                    onSubmit={handleGeneralSubmit}
                    initialValues={generalData}
                />
                <PerkSettingsForm
                    open={activeStep === 1}
                    clickPrevious={handlePrevious}
                    onSubmit={handleSettingsSubmit}
                    onPerkTypeChange={onPerkTypeChange}
                    initialValues={settingsData}
                    currencySymbol={currencySymbol}
                    primaryActionBackgroundColour={primaryActionBackgroundColour}
                    title={generalData && generalData.title}
                />
                <PerkVisualisationForm
                    open={activeStep === 2}
                    onSubmit={handleVisualisationSubmit}
                    isLoading={isLoading}
                    primaryActionBackgroundColour={primaryActionBackgroundColour}
                    initialValues={visualisationData}
                    onPrevious={handlePrevious}
                />
                {activeStep === 3 && (
                    <PerkRedemptionForm
                        initialData={redemptionData}
                        onSubmit={handleRedemptionSubmit}
                        clickPrevious={handlePrevious}
                        currencySymbol={currencySymbol}
                        perkType={settingsData?.perkType}
                        auto={settingsData?.auto}
                    />
                )}
                <PerkReviewForm
                    open={activeStep === 4}
                    showButtonStepFive={hasFiveSteps}
                    submit={handleCreatePerk}
                    clickPrevious={handlePrevious}
                    submitContinueAddTrigger={handleCreatePerkContinueAddTrigger}
                    isLoading={isLoading}
                    isLoadingContinueAddTrigger={isLoadingAddTrigger}
                    label={hasFiveSteps ? undefined : 'Save & Close'}
                    data={{ ...generalData, ...settingsData, ...visualisationData }}
                    primaryActionBackgroundColour={primaryActionBackgroundColour}
                />
                <PerkClubForm
                    open={activeStep === 5}
                    submit={handleAddTriggerToPerk}
                    onClose={onClose}
                    isLoading={isLoadingAddTrigger}
                />
            </Column>
        </Box>
    );
};
