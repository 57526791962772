import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MainLayout } from 'layouts/MainLayout';
import { CAMPAIGNS_TRIGGERED } from 'config/routes';
import { isDefined, isString } from 'lib/typeguards';
import logger from 'lib/logger';
import { notificationApi } from 'components/notification/notificationApi';
import { QueryParameter } from 'lib/HttpClient';
import { rulesApi } from 'components/rules/rulesApi';
import { RuleScheduledTaskNotificationView } from 'components/rules/scheduled-notification/RuleScheduledTaskNotificationView';
import { RuleResource } from 'components/rules/rules.types';

const getRule = async (id: string): Promise<RuleResource> => {
    try {
        const response = await rulesApi.get(id);

        if (response.ok) {
            if (isDefined(response.body)) {
                return response.body;
            }
            throw new Error(`Expected Rule but got ${JSON.stringify(response.body)}`);
        }
        throw new Error(response.body.message);
    } catch (error) {
        logger.error(error);
        return error;
    }
};

const getMetrics = async (
    id: string
): Promise<{ sent: number; skipped: number; failures: number } | undefined> => {
    try {
        const queryParameters: QueryParameter[] = [{ key: 'ruleId', value: id }];
        const response = await notificationApi.getAggregatedMetrics({ queryParameters });

        if (response.ok) {
            if (isDefined(response.body)) {
                return response.body;
            }
            throw new Error(`Expected notification metrics but got ${JSON.stringify(response.body)}`);
        } else {
            logger.error(response.body.message);
        }
    } catch (error) {
        logger.error(error);
        return error;
    }
};

const getRelatedNotifications = async (id: string) => {
    try {
        const response = await notificationApi.getList({
            queryParameters: [{ key: 'ruleId', value: id }],
            headers: { 'x-api-version': 2 }
        });

        if (response.ok) {
            return response.body;
        }
        throw new Error(response.body.message);
    } catch (error) {
        logger.error(error);
        return error;
    }
};

const breadcrumbs = [
    {
        label: 'Triggered Campaigns',
        url: CAMPAIGNS_TRIGGERED
    }
];

export const TriggeredCampaignViewPage = () => {
    const [rule, setRule] = React.useState(null);
    const [metrics, setMetrics] = React.useState(null);
    const [notifications, setNotifications] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const history = useHistory();
    const { triggerId: id } = useParams<{ triggerId: string }>();
    React.useEffect(() => {
        async function load() {
            try {
                if (!isString(id)) {
                    throw new Error(`Expected notification id, but got ${id}`);
                }
                const [newRule, newMetrics, newNotifications] = await Promise.all([
                    getRule(id),
                    getMetrics(id),
                    getRelatedNotifications(id)
                ]);
                setRule(newRule);
                setMetrics(newMetrics);
                setNotifications(newNotifications.items);
            } catch (e) {
                history.push(CAMPAIGNS_TRIGGERED);
                setRule(null);
            } finally {
                setLoading(false);
            }
        }

        load();
    }, [history, id]);
    return (
        <MainLayout
            breadcrumbs={breadcrumbs}
            pageName="Trigger Details"
            pageDescription="View the results of your triggered campaign"
        >
            <RuleScheduledTaskNotificationView
                rule={rule}
                notifications={notifications}
                metrics={metrics}
                loading={loading}
            />
        </MainLayout>
    );
};
