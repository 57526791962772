import React from 'react';
import { useDispatch } from 'react-redux';
import { MainLayout } from 'layouts/MainLayout';
import { segmentsApi } from 'components/audience/segmentsApi';
import { CustomerSegment } from 'components/customers/models/Customer';
import { locationApi } from 'components/location/LocationApi';
import { Location } from 'components/location/models/LocationModel';
import { CreateNotificationModal } from 'components/notification/modals/CreateNotificationModal';
import { Notification } from 'components/notification/models/Notification';
import { NotificationsTable } from 'components/notification/NotificationsTable';
import notificationsReducer from 'components/notification/reducers/notificationsReducer';
import {
    MESSAGE_MARKETING_NOTIFICATION_CREATE_ERROR,
    MESSAGE_MARKETING_NOTIFICATION_CREATE_IN_PROGRESS
} from 'config/messages';
import logger from 'lib/logger';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { ContentModule } from 'components/content/models/ContentModule';
import { contentApi } from 'components/content/contentApi';

const { reducer, initialState, ActionType } = notificationsReducer;

async function getLocations(): Promise<Location[]> {
    const result = await locationApi.getList();
    if (!result.ok) {
        return [];
    }
    return result.body.locations;
}

async function getSegments(): Promise<CustomerSegment[]> {
    try {
        return segmentsApi.getAll();
    } catch (err) {
        return [];
    }
}

async function getModules(): Promise<ContentModule[]> {
    const { ok, body: modules } = await contentApi.modules.getList();
    if (!ok) {
        return [];
    }
    return modules;
}

const NotificationsPage = () => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const reduxDispatch = useDispatch();

    const {
        isLoading,
        locations,
        segments,
        modules,
        createNotificationModalOpen,
        notificationToResend,
        actionedNotification
    } = state;

    React.useEffect(() => {
        async function load() {
            try {
                dispatch({ type: ActionType.START_REQUEST });
                const [locations, segments, modules] = await Promise.all([
                    getLocations(),
                    getSegments(),
                    getModules()
                ]);
                dispatch({
                    type: ActionType.LOAD_SUCCESS,
                    locations,
                    segments,
                    modules
                });
            } catch (e) {
                logger.error('Failed to load notifications', e);
                dispatch({ type: ActionType.LOAD_ERROR });
            }
        }
        load();
    }, []);

    const handleCreateClose = React.useCallback(() => {
        dispatch({ isOpen: false, type: ActionType.MODAL_ACTION });
    }, [dispatch]);
    const handleCreateError = React.useCallback(
        (errorMessage: string) => {
            dispatch({ isOpen: false, type: ActionType.MODAL_ACTION });
            reduxDispatch(
                enqueueSnackbar(
                    MESSAGE_MARKETING_NOTIFICATION_CREATE_ERROR.replace('{{errorMessage}}', errorMessage),
                    {
                        variant: 'error'
                    }
                )
            );
        },
        [dispatch, reduxDispatch]
    );
    const handleCreateSuccess = React.useCallback(
        (notification: Notification<string>) => {
            dispatch({ notification, type: ActionType.CREATE_SUCCESS });
            reduxDispatch(
                enqueueSnackbar(MESSAGE_MARKETING_NOTIFICATION_CREATE_IN_PROGRESS, { variant: 'info' })
            );
        },
        [reduxDispatch, dispatch]
    );
    const handleCreateClick = React.useCallback(
        (notification?: Notification<string>) =>
            dispatch({ isOpen: true, type: ActionType.MODAL_ACTION, notificationToResend: notification }),
        [dispatch]
    );
    const segmentOptions = React.useMemo(
        () =>
            segments.map((segment: CustomerSegment) => ({
                label: segment.title,
                value: segment._id
            })),
        [segments]
    );
    const locationOptions = React.useMemo(
        () =>
            locations.map((segment: Location) => ({
                label: segment.title,
                value: segment._id
            })),
        [locations]
    );

    return (
        <MainLayout
            pageName="Notifications"
            pageDescription="Push marketing notifications to your customers."
            noScroll
        >
            <NotificationsTable
                actionedNotification={actionedNotification}
                isLoading={isLoading}
                onCreateClick={handleCreateClick}
                segments={segments}
                locations={locations}
            />
            <CreateNotificationModal
                open={createNotificationModalOpen}
                onClose={handleCreateClose}
                onSuccess={handleCreateSuccess}
                onError={handleCreateError}
                notification={notificationToResend}
                segments={segmentOptions}
                locations={locationOptions}
                modules={modules}
            />
        </MainLayout>
    );
};

export default NotificationsPage;
