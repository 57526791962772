import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { MainLayout } from 'layouts/MainLayout';
import { locationApi } from 'components/location/LocationApi';
import { CONTENT_AVAILABILITY, LOCATIONS } from 'config/routes';
import { Throbber } from 'ui/Throbber';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { LocationProductAvailability } from 'components/location/overrides/LocationProductAvailability';
import { ErrorLayout } from 'layouts/ErrorLayout';
import {
    getMenuOverrideEditRows,
    IAvailabilityRowItem,
    MenuOverridesFilter
} from 'components/location/models/Overrides';
import { LocationTimeSlotSettings } from 'components/location/LocationTimeSlotSettings';
import { menuOverridesApi } from 'components/menu/MenuOverridesApi';
import { Permission, Resource } from '@pepperhq/auth-client';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export const AvailabilityView: React.FC = () => {
    const [currentLocation, setCurrentLocation] = React.useState(null);
    const [generalSettings, setGeneralSettings] = React.useState(null);
    const [locationSettings, setLocationSettings] = React.useState(null);
    const [locationLoading, setLocationLoading] = React.useState(true);
    const [generalSettingsLoading, setGeneralSettingsLoading] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const [data, setData] = React.useState<IAvailabilityRowItem[]>([]);
    const [locationSettingsLoading, setLocationSettingsLoading] = React.useState(true);
    const [overridesLoading, setOverridesLoading] = React.useState(true);
    const [zones, setZones] = React.useState<string[]>([]);
    const [filter, setFilter] = React.useState<MenuOverridesFilter>({
        zone: undefined,
        scenario: undefined,
        date: new Date().toString(),
        time: new Date().toString()
    });

    const { claims } = useSelector((state: ApplicationState) => state.auth);

    const { locationId } = useParams<{ locationId: string }>();

    const getGeneralSettings = React.useCallback(async () => {
        try {
            const settings = await new LocationSettingsApi().getPublicSettings();
            setGeneralSettings(settings);
        } catch (error) {
            setErrorMessage('Failed to load global settings');
        }
        setGeneralSettingsLoading(false);
    }, []);
    const getLocationSettings = React.useCallback(async (locationId: string) => {
        try {
            const settings = await new LocationSettingsApi().getPublicSettings(null, locationId);
            setLocationSettings(settings);
        } catch (error) {
            setErrorMessage('Failed to load location settings');
        }
        setLocationSettingsLoading(false);
    }, []);
    const getLocation = React.useCallback(async (locationId: string) => {
        const locationResponse = await locationApi.get(locationId);
        if (locationResponse.ok) {
            setCurrentLocation(locationResponse.body);
        } else {
            setErrorMessage('Failed to load location. Try again later');
        }
        setLocationLoading(false);
    }, []);

    const handleSettingsSaved = React.useCallback(async () => {
        await getLocationSettings(locationId);
    }, [getLocationSettings, locationId]);

    const loadOverridesEdit = React.useCallback(
        async (updatedFilter?: MenuOverridesFilter) => {
            const filterValue = updatedFilter || filter;

            const res = await menuOverridesApi
                .getMenuOverridesEdit(locationId, filterValue)
                .then(res => {
                    if (res.ok) {
                        const responseBody = res.body;

                        if (responseBody.categoryGroups || responseBody.categories) {
                            setData(getMenuOverrideEditRows(responseBody));
                        }
                        setZones(responseBody.zones);
                    }

                    return res;
                })
                .finally(() => {
                    setOverridesLoading(false);
                });

            return res;
        },
        [filter, locationId]
    );

    const resetOverrides = React.useCallback(() => {
        setData(current => [...current]);
    }, []);

    React.useEffect(() => {
        setOverridesLoading(true);
        loadOverridesEdit(filter).finally(() => {
            setOverridesLoading(false);
        });
    }, [filter, loadOverridesEdit]);
    const handleFilterChange = React.useCallback(
        (filter: MenuOverridesFilter) => {
            setFilter(filter);
            setOverridesLoading(true);
            loadOverridesEdit(filter).finally(() => {
                setOverridesLoading(false);
            });
        },
        [loadOverridesEdit]
    );

    const isLoading = React.useMemo(() => {
        const isLoading =
            locationSettingsLoading || generalSettingsLoading || overridesLoading || locationLoading;

        return isLoading;
    }, [locationSettingsLoading, overridesLoading, generalSettingsLoading, locationLoading]);

    React.useEffect(() => {
        getLocation(locationId);
        getLocationSettings(locationId);
        getGeneralSettings();
    }, [getLocation, getGeneralSettings, locationId, getLocationSettings]);

    const editingEnabled = React.useMemo(
        () => claims.hasPermission(Resource.Menu, Permission.write),
        [claims]
    );

    if (!locationId) {
        return <Redirect to={LOCATIONS} />;
    }

    if (errorMessage) {
        return <ErrorLayout title={errorMessage} fallbackTitle="Locations" fallbackUrl={LOCATIONS} />;
    }

    if (!currentLocation || isLoading) {
        return <Throbber />;
    }

    return (
        <MainLayout
            breadcrumbs={[{ label: 'Ordering Availability', url: CONTENT_AVAILABILITY }]}
            pageName={currentLocation.title}
            pageDescription="Manage product availability for your location"
            noScroll
        >
            {locationSettings.timeSlotsEnabled && (
                <LocationTimeSlotSettings
                    settings={locationSettings}
                    locationId={locationId}
                    handleSettingsSaved={handleSettingsSaved}
                    disabled={!editingEnabled}
                />
            )}
            <LocationProductAvailability
                settings={generalSettings}
                locationId={locationId}
                overrides={data}
                zones={zones}
                filter={filter}
                onFilterChange={handleFilterChange}
                onRefreshOverrides={loadOverridesEdit}
                resetOverrides={resetOverrides}
            />
        </MainLayout>
    );
};
