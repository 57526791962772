import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material';
import { MainLayout } from 'layouts/MainLayout';
import { LayoutConfigStepper } from 'components/content/layout-config/LayoutConfigStepper';
import { LayoutConfiguration } from 'components/content/layout-config/LayoutConfiguration';
import { LayoutPreview } from 'components/content/layout-config/LayoutPreview';
import { MOBILE_CONTENT } from 'config/routes';
import { isDefined, isString } from 'lib/typeguards';
import { clearCurrentModules, getLayoutFromId } from 'store/content/contentActions';
import { ApplicationState } from 'store/store';
import { Row } from 'ui/Flex';
import { Throbber } from 'ui/Throbber';

const PREFIX = 'mobile-layout-config';

const classes = {
    config: `${PREFIX}-config`,
    preview: `${PREFIX}-preview`
};

const StyledRow = styled(Row)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    [`& .${classes.config}`]: {
        padding: theme.spacing(2),
        flexBasis: '35%'
    },
    [`& .${classes.preview}`]: {
        paddingLeft: theme.spacing(4),
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0
    }
}));

const ConfigureMobileContentPage = () => {
    const dispatch = useDispatch();
    const currentLayout = useSelector((state: ApplicationState) => state.content.currentLayout);
    const { layoutId } = useParams<{ layoutId: string }>();
    React.useEffect(() => {
        async function load() {
            if (isString(layoutId)) {
                if (!isDefined(currentLayout?.id)) {
                    await getLayoutFromId(layoutId)(dispatch);
                } else if (currentLayout?.id !== layoutId) {
                    clearCurrentModules()(dispatch);
                    await getLayoutFromId(layoutId)(dispatch);
                }
            }
        }
        load();
    }, [dispatch, layoutId, currentLayout?.id]);

    if (!currentLayout) {
        return (
            <MainLayout
                columnProps={{ align: 'center' }}
                breadcrumbs={[{ label: 'Mobile content', url: MOBILE_CONTENT }]}
                pageName="Layout configuration"
                pageDescription="Configure your app"
            >
                <Throbber />
            </MainLayout>
        );
    }

    return (
        <MainLayout
            columnProps={{ align: 'center' }}
            breadcrumbs={[{ label: 'Mobile content', url: MOBILE_CONTENT }]}
            pageName="Layout configuration"
            pageDescription="Configure your app"
        >
            <LayoutConfigStepper />
            <StyledRow valign="start" align="center">
                <LayoutConfiguration className={classes.config} />
                <LayoutPreview className={classes.preview} />
            </StyledRow>
        </MainLayout>
    );
};

export default ConfigureMobileContentPage;
