import { Box, Button, Paper, Stack, styled, TablePagination } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';

import { ORDERS_VIEW } from 'config/routes';
import { MainLayout } from 'layouts/MainLayout';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React from 'react';
import { MuiTheme } from 'config/theme';
import { EOrderStatusFilter, useOrdersFilter } from 'components/order/hooks/useOrdersFilter';
import { ORDERS_PAGE_LIMIT, usePepperOrders } from 'components/order/hooks/usePepperOrders';
import { OrdersFilters } from 'components/order/OrdersFilters';
import { SelectGridColumn, SelectTitleColumn } from 'lib/MuiGrid/Columns';
import { OrderScenarioLabels } from 'components/order/model/Order';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { renderOrderStatusCell } from 'components/order/order-details/OrderStatusCell';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { Permission, Resource } from '@pepperhq/auth-client';
import { CloseOrderDialog } from 'components/order/order-close/CloseOrderDialog';
import { defaultDateTimeValueFormatter } from 'lib/MuiGrid/helpers';
import { useHistory } from 'react-router-dom';

const StyledBox = styled(Box)(({ theme }) => ({
    borderTop: '1px solid',
    borderColor: theme.palette.divider,
    padding: theme.spacing(0.5, 1.75),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > button': {
        marginRight: 'auto'
    }
}));

export const OrdersPage: React.FC = () => {
    const history = useHistory();
    const { claims } = useSelector((state: ApplicationState) => state.auth);
    const [isCloseOrdersDialogOpen, setIsCloseOrdersDialogOpen] = React.useState(false);
    const canForceCloseOrders = React.useMemo(
        () => claims.hasPermission(Resource.OrderForceClose, Permission.write),
        [claims]
    );

    const [filter, onFilterChange] = useOrdersFilter();
    const { formattedOrders, page, onPageChange, loading, count } = usePepperOrders(filter);
    const { locationIdToLocationName, isLoading: locationsLoading } = useLocationHelpers();
    const handleRowClick = React.useCallback(
        (params: GridRowParams) => {
            history.push(ORDERS_VIEW.replace(':orderId', String(params?.id)));
        },
        [history]
    );

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'Order Number',
                field: 'id',
                disableReorder: true,
                disableExport: true,
                sortable: false,
                width: 160
            },
            {
                headerName: 'Scenario',
                field: 'scenario',
                sortable: false,
                width: 130,
                ...SelectGridColumn(OrderScenarioLabels)
            },
            {
                headerName: 'Date Created',
                field: 'createdAt',
                sortable: false,
                type: 'dateTime',
                width: 180,
                valueGetter: defaultDateTimeValueFormatter
            },
            {
                headerName: 'Status',
                field: 'isOpen',
                sortable: false,
                width: 90,
                renderCell: renderOrderStatusCell
            },
            {
                headerName: 'Location',
                field: 'locationId',
                sortable: false,
                width: 250,
                ...SelectTitleColumn(locationIdToLocationName)
            },
            {
                headerName: 'Table',
                field: 'deliveryLocation',
                width: 90,
                sortable: false,
                align: 'right'
            },
            {
                headerName: 'Collection Time',
                field: 'timeSlot',
                sortable: false,
                width: 300
            },
            {
                headerName: 'Total',
                field: 'total',
                width: 110,
                sortable: false,
                align: 'right'
            },
            {
                headerName: 'Items',
                field: 'items',
                sortable: false,
                width: 250
            },
            {
                headerName: 'Payments',
                field: 'payments',
                sortable: false,
                width: 200,
                align: 'right'
            },
            {
                headerName: 'Customers',
                field: 'users',
                sortable: false,
                width: 300
            }
        ],
        [locationIdToLocationName]
    );
    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            onPageChange(page);
        },
        [onPageChange]
    );
    const openCloseOrdersDialog = React.useCallback(() => {
        setIsCloseOrdersDialogOpen(true);
    }, []);
    const closeDeletePopUp = React.useCallback(() => {
        setIsCloseOrdersDialogOpen(false);
    }, []);

    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={count}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={ORDERS_PAGE_LIMIT}
                rowsPerPageOptions={[ORDERS_PAGE_LIMIT]}
                component="div"
                sx={{
                    '&:last-child': {
                        paddingRight: MuiTheme.spacing(6)
                    }
                }}
            />
        ),
        [count, handlePageChange, page]
    );
    const NoRowsOverlay = React.useCallback(
        () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
                No orders found
            </Stack>
        ),
        []
    );
    const ForceCloseOrdersButton = React.useCallback(
        () => (
            <Button variant="outlined" color="error" size="small" onClick={openCloseOrdersDialog}>
                Force Close All Open Orders
            </Button>
        ),
        [openCloseOrdersDialog]
    );
    const Footer = React.useCallback(
        () => (
            <StyledBox>
                {canForceCloseOrders && count > 0 && filter.status === EOrderStatusFilter.Open && (
                    <ForceCloseOrdersButton />
                )}
                <Pagination />
            </StyledBox>
        ),
        [ForceCloseOrdersButton, Pagination, canForceCloseOrders, count, filter.status]
    );

    return (
        <MainLayout pageName="Orders" pageDescription="View and interact with orders." noScroll>
            <Box paddingBottom={18} height="100%">
                <Box paddingBottom={1}>
                    <OrdersFilters filter={filter} onChange={onFilterChange} />
                </Box>
                <Paper sx={{ height: '100%', display: 'flex' }}>
                    <MuiGrid
                        rows={formattedOrders}
                        columns={columns}
                        disableMultipleRowSelection
                        enableRowClick
                        onRowClick={handleRowClick}
                        hideFooter={false}
                        rowCount={count}
                        pagination
                        storageName={GridStorageName.Orders}
                        disableColumnFilter
                        paginationMode="server"
                        loading={loading || locationsLoading}
                        slots={{
                            footer: Footer,
                            noRowsOverlay: NoRowsOverlay
                        }}
                    />
                </Paper>
            </Box>
            <CloseOrderDialog
                isCloseOrdersDialogOpen={isCloseOrdersDialogOpen}
                closeDialog={closeDeletePopUp}
            />
        </MainLayout>
    );
};
