import React from 'react';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { MuiModal } from 'ui/MuiModal';
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    styled,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import CalendarToday from '@mui/icons-material/CalendarToday';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';
import { formatDateRange } from 'lib/helpers';
import { addDays, isBefore, subDays, startOfToday, differenceInDays } from 'date-fns';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_ACTION_EXPORT_DATE_RANGE_WARNING,
    MESSAGE_ACTION_EXPORT_DELAY_WARNING,
    MESSAGE_ACTION_EXPORT_ERROR,
    MESSAGE_ACTION_EXPORT_IN_PROGRESS
} from 'config/messages';
import { actionExportsApi } from 'components/analytics/export/actionExportsApi';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { Option } from 'lib/types';
import { isDefined } from 'lib/typeguards';
import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton } from 'ui/buttons/IconButton';
import { IActionOperation, INITIAL_OPERATION_RESULT } from 'components/analytics/export/models';
import { CustomersTimelineDateFilter } from 'components/customers/modals/CustomersTimelineDateFilter';
import { LocationsPickerModal } from 'components/location/modal/LocationsPickerModal';
import { ActionTypesDialog } from 'components/actions/dialogs/ActionTypesDialog';
import { Row } from 'ui/Flex';

const PREFIX = 'ExportAnalyticsDialog';

const classes = {
    cursorPointer: `${PREFIX}-cursorPointer`,
    submitTooltip: `${PREFIX}-submitTooltip`
};

const StyledModal = styled(MuiModal)({
    [`& .${classes.cursorPointer}`]: {
        cursor: 'pointer'
    },
    [`& .${classes.submitTooltip}`]: {
        fontSize: '0.8rem'
    }
});

interface ExportAnalyticsDialogProps {
    open: boolean;
    onCancel: () => void;
    onActionExportCreation?: (operation: IActionOperation) => void;
}

const MAXIMUM_DATE_RANGE_DAYS = 90;

const FILE_SIZE_OPTIONS: Option[] = [
    {
        value: '500',
        label: '500 MB'
    },
    {
        value: '1000',
        label: '1 GB'
    },
    {
        value: '2000',
        label: '2 GB'
    },
    {
        value: '4000',
        label: '4 GB'
    },
    {
        value: '8000',
        label: '8 GB'
    }
];

const today = startOfToday();

export const ExportAnalyticsDialog: React.FC<ExportAnalyticsDialogProps> = ({
    open,
    onCancel,
    onActionExportCreation
}) => {
    const dispatch = useDispatch();

    const [actionTypes, setActionTypes] = React.useState<string[]>([]);
    const [isExportLoading, setExportLoading] = React.useState(false);
    const [fileSize, setFileSize] = React.useState('2000');
    const [anchorDateElement, setAnchorDateElement] = React.useState<HTMLElement>(null);
    const [isLocationPickerOpen, setLocationPickerOpen] = React.useState(false);
    const [isActionTypesDialogOpen, setActionTypesDialogOpen] = React.useState(false);
    const [exportDate, setExportDate] = React.useState<DateRangePickerValue>({
        startDate: subDays(today, 7),
        endDate: today
    });

    const [locationIds, setLocationIds] = React.useState<string[]>([]);
    const {
        locations,
        isLoading: areLocationsLoading,
        summary
    } = useSelector((state: ApplicationState) => state.locations);

    const dateRangeExceedsLimit = React.useMemo(() => {
        if (exportDate?.startDate && exportDate?.endDate) {
            return differenceInDays(exportDate.endDate, exportDate.startDate) > MAXIMUM_DATE_RANGE_DAYS;
        }

        return false;
    }, [exportDate]);

    const isExportDisabled = React.useMemo(
        () => !actionTypes.length || !exportDate || dateRangeExceedsLimit,
        [actionTypes.length, exportDate, dateRangeExceedsLimit]
    );
    const formattedExportDate = React.useMemo(() => formatDateRange(exportDate, true), [exportDate]);
    const selectLocationsString = React.useMemo(() => {
        if (locationIds.length === 0) {
            return undefined;
        }
        if (summary && summary.total === locationIds.length) {
            return 'All Locations';
        }
        if (locationIds.length === 1) {
            return '1 Location';
        }
        return `${locationIds.length} Locations`;
    }, [locationIds, summary]);
    const actionTypesString = React.useMemo(() => {
        if (actionTypes.length === 0) {
            return undefined;
        }
        if (actionTypes.length === 1) {
            return '1 Action Type';
        }
        return `${actionTypes.length} Action Types`;
    }, [actionTypes]);

    const handleActionTypesDialogClose = React.useCallback(() => {
        setActionTypesDialogOpen(false);
    }, []);
    const handleActionTypesSubmit = React.useCallback(
        (values: string[]) => {
            setActionTypes(values);
            handleActionTypesDialogClose();
        },
        [handleActionTypesDialogClose]
    );
    const handleActionTypesDialogOpen = React.useCallback(() => {
        setActionTypesDialogOpen(true);
    }, []);

    const handleDateFilter = React.useCallback(
        (value?: DateRangePickerValue) => {
            setExportDate(value);
            if (differenceInDays(value.endDate, value.startDate) > MAXIMUM_DATE_RANGE_DAYS) {
                dispatch(
                    enqueueSnackbar(MESSAGE_ACTION_EXPORT_DATE_RANGE_WARNING, {
                        variant: 'warning'
                    })
                );
            }
        },
        [dispatch]
    );
    const handleDateFilterListOpen = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorDateElement(event.currentTarget);
    }, []);

    const handleDateFilterClose = React.useCallback(() => {
        setAnchorDateElement(null);
    }, []);
    const handleDeleteDateFilter = React.useCallback(() => {
        setExportDate(undefined);
    }, []);
    const handleLocationsPickerModalClose = React.useCallback(() => {
        setLocationPickerOpen(false);
    }, []);
    const handleLocationFilterSubmit = React.useCallback((locationIds: string[]) => {
        setActionTypes([]);
        setLocationIds(locationIds);
    }, []);
    const handleLocationsFilterOpen = React.useCallback(() => {
        setLocationPickerOpen(true);
    }, []);
    const handleFileSizeChange = React.useCallback((event: SelectChangeEvent) => {
        setFileSize(event.target.value);
    }, []);
    const handleExportClick = React.useCallback(() => {
        setExportLoading(true);
        if (!!exportDate) {
            if (isBefore(addDays(exportDate.startDate, 7), exportDate.endDate)) {
                dispatch(
                    enqueueSnackbar(MESSAGE_ACTION_EXPORT_DELAY_WARNING, {
                        variant: 'warning'
                    })
                );
            }
            actionExportsApi
                .createExport({
                    maxFileSizeMB: +fileSize,
                    locationIds,
                    types: actionTypes,
                    from: exportDate.startDate ? exportDate.startDate.toISOString() : undefined,
                    to: exportDate.endDate
                        ? exportDate.endDate.toISOString()
                        : (exportDate.startDate ? addDays(exportDate.startDate, 1) : today).toISOString()
                })
                .then(response => {
                    if (onActionExportCreation) {
                        onActionExportCreation({ result: INITIAL_OPERATION_RESULT, ...response.body });
                    }
                    dispatch(enqueueSnackbar(MESSAGE_ACTION_EXPORT_IN_PROGRESS, { variant: 'info' }));
                    onCancel();
                })
                .catch(() => {
                    dispatch(enqueueSnackbar(MESSAGE_ACTION_EXPORT_ERROR, { variant: 'error' }));
                })
                .finally(() => {
                    setExportLoading(false);
                });
        }
    }, [actionTypes, dispatch, exportDate, fileSize, locationIds, onActionExportCreation, onCancel]);

    const dateSelectionButton = React.useMemo(
        () => (
            <>
                {exportDate && formattedExportDate ? (
                    <IconButton onClick={handleDeleteDateFilter} size="large">
                        <CancelIcon />
                    </IconButton>
                ) : (
                    <CalendarToday />
                )}
                <ExpandMore />
            </>
        ),
        [exportDate, formattedExportDate, handleDeleteDateFilter]
    );

    return (
        <React.Fragment>
            <StyledModal open={open} onClose={onCancel}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" color="primary">
                                    Export Data
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={selectLocationsString ?? ''}
                                    onClick={handleLocationsFilterOpen}
                                    InputLabelProps={{ shrink: !!locationIds?.length }}
                                    label="Locations"
                                    InputProps={{
                                        readOnly: true,
                                        className: classes.cursorPointer
                                    }}
                                    inputProps={{ className: classes.cursorPointer }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={actionTypesString ?? ''}
                                    onClick={handleActionTypesDialogOpen}
                                    InputLabelProps={{ shrink: !!actionTypesString }}
                                    label="Action types"
                                    InputProps={{
                                        readOnly: true,
                                        className: classes.cursorPointer
                                    }}
                                    inputProps={{ className: classes.cursorPointer }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Box>
                                    <FormControl fullWidth>
                                        <InputLabel id="action-export-file-size">File size</InputLabel>
                                        <Select
                                            labelId="action-export-file-size"
                                            variant="outlined"
                                            value={fileSize}
                                            label="File size"
                                            fullWidth
                                            onChange={handleFileSizeChange}
                                        >
                                            {FILE_SIZE_OPTIONS.map(({ value, label }) => (
                                                <MenuItem key={`select-file-size-${value}`} value={value}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={formattedExportDate ?? ''}
                                    onClick={handleDateFilterListOpen}
                                    InputLabelProps={{ shrink: isDefined(formattedExportDate) }}
                                    label="Date"
                                    InputProps={{
                                        endAdornment: dateSelectionButton,
                                        readOnly: true,
                                        className: classes.cursorPointer
                                    }}
                                    inputProps={{ className: classes.cursorPointer }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Row gutter align="flex-end">
                                    <Button color="primary" variant="outlined" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                    {dateRangeExceedsLimit ? (
                                        <Tooltip
                                            title={MESSAGE_ACTION_EXPORT_DATE_RANGE_WARNING}
                                            classes={{ tooltip: classes.submitTooltip }}
                                        >
                                            <div>
                                                <LoadingButton
                                                    loading={isExportLoading}
                                                    disabled
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleExportClick}
                                                >
                                                    Submit
                                                </LoadingButton>
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        <LoadingButton
                                            loading={isExportLoading}
                                            disabled={isExportDisabled || isExportLoading}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleExportClick}
                                        >
                                            Submit
                                        </LoadingButton>
                                    )}
                                </Row>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledModal>
            <CustomersTimelineDateFilter
                anchorElement={anchorDateElement}
                value={exportDate}
                onSelect={handleDateFilter}
                onClose={handleDateFilterClose}
            />
            <LocationsPickerModal
                open={isLocationPickerOpen}
                onClose={handleLocationsPickerModalClose}
                locations={locations}
                onSubmit={handleLocationFilterSubmit}
                loading={areLocationsLoading}
                selectedLocationIds={locationIds}
                allowEmpty
            />
            <ActionTypesDialog
                open={isActionTypesDialogOpen}
                onClose={handleActionTypesDialogClose}
                onSubmit={handleActionTypesSubmit}
                selectedOptions={actionTypes}
                hasLocationsSelected={!!locationIds.length}
            />
        </React.Fragment>
    );
};
