import React from 'react';
import { styled } from '@mui/material';
import { MuiModal } from 'ui/MuiModal';
import { AwardGeneralFormData } from '../forms/AwardGeneralForm';
import { AwardEditStepper, AwardEditStepperProps } from '../AwardEnrichmentEditStepper';
import { IAwardEnrichment } from 'components/loyalty/models/AwardEnrichment';

interface AwardEditModalProps extends AwardEditStepperProps {
    open: boolean;
    currencySymbol: string;
    primaryActionBackgroundColour: string;
    initialGeneralData?: Partial<AwardGeneralFormData>;
    award: IAwardEnrichment | undefined;
}

const StyledMuiModal = styled(MuiModal)(({ theme }) => ({
    width: `calc(100% - ${theme.spacing(4)})`,
    maxWidth: theme.spacing(160)
}));

export const AwardEditModal: React.FC<AwardEditModalProps> = props => {
    const { open, onClose, ...rest } = props;
    return (
        <StyledMuiModal open={open} onClose={onClose}>
            <AwardEditStepper onClose={onClose} {...rest} />
        </StyledMuiModal>
    );
};
