import { ScheduledTaskStatus } from '../scheduledTasks.types';
import React from 'react';
import { Option } from 'lib/types';
import { MenuItem, Select } from '@mui/material';

const ANY_STATUS = 'ANY';

interface ScheduledTaskStatusFilterProps {
    onChange: (value?: string) => void;
    value?: string;
}

export const ScheduledTaskStatusLabels: Record<ScheduledTaskStatus, string> = {
    [ScheduledTaskStatus.DRAFT]: 'Draft',
    [ScheduledTaskStatus.SCHEDULED]: 'Scheduled',
    [ScheduledTaskStatus.QUEUED]: 'Queued',
    [ScheduledTaskStatus.PROCESSING]: 'Processing',
    [ScheduledTaskStatus.PROCESSED]: 'Processed',
    [ScheduledTaskStatus.ERROR]: 'Error'
};

const options = Object.entries(ScheduledTaskStatusLabels).map(([key, value]) => ({
    label: value,
    value: key
}));

export const ScheduledTaskStatusFilter: React.FC<ScheduledTaskStatusFilterProps> = ({ value, onChange }) => {
    const handleStateChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === ANY_STATUS) {
                onChange(undefined);
            } else {
                onChange(e.target.value);
            }
        },
        [onChange]
    );
    const renderOption = React.useCallback(
        (option: Option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ),
        []
    );
    return (
        <Select value={value ?? ANY_STATUS} onChange={handleStateChange} variant="outlined" size="small">
            <MenuItem value={ANY_STATUS}>All Statuses</MenuItem>
            {options.map(renderOption)}
        </Select>
    );
};
