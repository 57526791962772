import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplayText } from '../../SettingDisplayText';
import { SettingDisplay } from '../../../../lib/SettingDisplay';
import Chip from '@mui/material/Chip';
import { OrderScenario } from '@pepperhq/menu-sdk';
import { EOrderPaymentType } from '../../../order/model/Order';

export const SimphonySettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({ settings, locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplayText title="API Host URL" text={settings?.simphony?.host} />
                <SettingDisplayText
                    title="Enterprise Short Name"
                    text={settings?.simphony?.enterpriseShortName}
                />
                <SettingDisplayText title="Client ID" text={settings?.simphony?.clientId} />
                <SettingDisplayText title="Username" text={settings?.simphony?.username} />
                <SettingDisplayText title="Password" text={settings?.simphony?.password} />
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <>
                <SettingDisplayText title="Location Reference" text={settings?.simphony?.locRef} />
                <SettingDisplayText title="Revenue Center Reference" text={settings?.simphony?.rvcRef} />
                <SettingDisplayText title="Employee Reference" text={settings?.simphony?.checkEmployeeRef} />
                <SettingDisplayText
                    title="Default Price Sequence"
                    text={settings?.simphony?.defaultPriceSequence}
                />
                <SettingDisplay label="Price Sequences">
                    {settings?.simphony?.priceSequences?.map(sequence => (
                        <Chip sx={{ mr: 1 }} color="primary" key={sequence} label={sequence} />
                    ))}
                </SettingDisplay>
                <SettingDisplayText
                    title="Order Type Reference - Default"
                    text={settings?.simphony?.orderTypeRef?.DEFAULT}
                />
                <SettingDisplayText
                    title="Order Type Reference - Pre-Order"
                    text={settings?.simphony?.orderTypeRef?.[OrderScenario.PREORDER]}
                />
                <SettingDisplayText
                    title="Order Type Reference - Order To Table"
                    text={settings?.simphony?.orderTypeRef?.[OrderScenario.ORDER_TO_TABLE]}
                />
                <SettingDisplayText
                    title="Tender ID - Default"
                    text={settings?.simphony?.tenderId?.DEFAULT}
                />
                <SettingDisplayText
                    title="Tender ID - Card"
                    text={settings?.simphony?.tenderId?.[EOrderPaymentType.Card]}
                />
                <SettingDisplayText
                    title="Tender ID - Cash"
                    text={settings?.simphony?.tenderId?.[EOrderPaymentType.Cash]}
                />
                <SettingDisplayText
                    title="Tender ID - Applepay"
                    text={settings?.simphony?.tenderId?.[EOrderPaymentType.Applepay]}
                />
                <SettingDisplayText
                    title="Tender ID - Googlepay"
                    text={settings?.simphony?.tenderId?.[EOrderPaymentType.Googlepay]}
                />
                <SettingDisplayText
                    title="Tender ID - Terminal"
                    text={settings?.simphony?.tenderId?.[EOrderPaymentType.Terminal]}
                />
                <SettingDisplayText
                    title="Tender ID - Giftcard"
                    text={settings?.simphony?.tenderId?.[EOrderPaymentType.Giftcard]}
                />
            </>
        )}
    </>
);
