export enum ELocalStorageKey {
    ProductMenuChangeSeen = 'menu-product-change-seen',
    CalculatedRefundInfoShown = 'l-calc-ref-count-s',
    JoinTokenQueryParameter = 'token',
    NotificationsRead = 'pep-not-r',
    NotificationsPopup = 'pep-not-p',
    PerkTitleVisualisationInfoShown = 'p-t-v-m-s',
    NotificationsTitleInfoShown = 'n-t-m-s'
}

export enum ESessionStorageKey {
    RefundSessionIsConfirmed = 's-calc-ref-c',
    PerkTitleVisualisationModal = 'p-t-v-m',
    NotificationsTitleModal = 'n-t-m'
}
