import { styled } from '@mui/material';
import { GridEditCellProps } from '@mui/x-data-grid-pro';
import { isTimePeriod } from '@pepperhq/menu-sdk';
import React from 'react';
import { TimeRangeInput } from './TimeRangeInput';

const StyledTimeRangeInput = styled(TimeRangeInput)({
    border: 'none',
    boxShadow: 'none',
    width: '100%'
});

const GridTimeCellPicker: React.FC<GridEditCellProps> = ({ value, api, id, field }) => {
    const previousValue = React.useMemo(
        () => (Array.isArray(value) && isTimePeriod(value[0]) ? value[0] : ''),
        [value]
    );
    const [localValue, setLocalValue] = React.useState(previousValue);
    const handleChange = React.useCallback((value: string) => {
        setLocalValue(value);
    }, []);
    const handleBlur = React.useCallback(() => {
        if (previousValue !== localValue) {
            if (isTimePeriod(localValue)) {
                api.setEditCellValue({ id, field, value: [localValue] });
            } else {
                api.setEditCellValue({ id, field, value: '' });
            }
            api.stopCellEditMode({ id, field });
        }
    }, [api, field, id, localValue, previousValue]);
    return (
        <StyledTimeRangeInput
            mountFocus
            placeholder="00:00 - 23:59"
            initTime={localValue}
            onTimeChange={handleChange}
            onBlur={handleBlur}
        />
    );
};

export const GridTimeRangeCell = (props: GridEditCellProps) => <GridTimeCellPicker {...props} />;
