import React from 'react';
import { NotificationReceiptStatusFilter } from 'components/notification/filters/NotificationReceiptStatusSelect';
import { GridFilterInput } from 'lib/grid/GridFilterInput';
import { Box } from '@mui/material';
import { Row } from 'ui/Flex';
import { NotificationReceiptStatus } from 'components/notification/models/NotificationReceipt';
import { Notification } from 'components/notification/models/Notification';
import { NotificationReceiptNotificationFilter } from 'components/notification/filters/NotificationReceiptNotificationFilter';

export interface NotificationReceiptsFilter {
    notificationId?: string;
    scheduledTaskId?: string;
    status?: NotificationReceiptStatus;
    userId?: string;
}

export type NotificationReceiptsFilterChange = (
    field: keyof NotificationReceiptsFilter,
    value: string
) => void;

interface NotificationReceiptFiltersProps {
    disabled?: boolean;
    notifications: Notification<string>[];
    filter: NotificationReceiptsFilter;
    onChange: NotificationReceiptsFilterChange;
    showNotificationIdFilter?: boolean;
}

export const NotificationReceiptFilters: React.FC<NotificationReceiptFiltersProps> = ({
    disabled,
    notifications,
    filter,
    onChange,
    showNotificationIdFilter
}) => {
    const handleNotificationChange = React.useCallback(
        (value?: string) => {
            onChange('notificationId', value);
        },
        [onChange]
    );
    const handleStatusChange = React.useCallback(
        (value?: string) => {
            onChange('status', value);
        },
        [onChange]
    );
    const handleUserChange = React.useCallback(
        (value?: string) => {
            onChange('userId', value);
        },
        [onChange]
    );

    return (
        <Row flex={1} wrap="wrap" valign="center">
            {showNotificationIdFilter && (
                <Box marginTop={2} marginRight={1}>
                    <NotificationReceiptNotificationFilter
                        disabled={disabled}
                        value={filter.notificationId}
                        notifications={notifications}
                        onChange={handleNotificationChange}
                    />
                </Box>
            )}
            <Box marginTop={2} marginRight={1}>
                <GridFilterInput
                    disabled={disabled}
                    value={filter.userId}
                    onChange={handleUserChange}
                    label="User ID"
                    name="userId"
                />
            </Box>
            <Box marginTop={2}>
                <NotificationReceiptStatusFilter
                    disabled={disabled}
                    value={filter.status}
                    onChange={handleStatusChange}
                />
            </Box>
        </Row>
    );
};
