import {
    ScheduledTasksFilterChange,
    ScheduledTasksFilter
} from 'components/scheduled-tasks/filters/ScheduledTaskFilters';
import { useHistoryFilters } from 'lib/hooks/useHistoryFilters';
import React from 'react';
import { QueryParameter } from 'lib/HttpClient';
import { ScheduledTaskType } from 'components/scheduled-tasks/scheduledTasks.types';

export function useScheduledTasksFilter(
    type?: ScheduledTaskType
): [ScheduledTasksFilter, ScheduledTasksFilterChange, boolean, boolean] {
    const { search, onSearch } = useHistoryFilters();
    const [filterTouched, setFilterTouched] = React.useState(false);
    const [filter, setFilter] = React.useState<ScheduledTasksFilter>(() => ({
        type,
        status: search.status || undefined,
        search: search.search || undefined
    }));
    const onFilterChange = React.useCallback<ScheduledTasksFilterChange>((field, value) => {
        setFilterTouched(true);
        setFilter(currentFilter => ({ ...currentFilter, [field]: value }));
    }, []);
    React.useEffect(() => {
        const { type: _type, ...rest } = filter;
        onSearch({ ...rest });
    }, [filter, onSearch]);
    const isEmpty = React.useMemo(() => !filter.status && !filter.search, [filter]);
    return [filter, onFilterChange, isEmpty, filterTouched];
}

export const getScheduledTasksFilterQuery = (filter: ScheduledTasksFilter): QueryParameter[] => {
    const queryParameters = [];
    if (filter.type) {
        queryParameters.push({ key: 'type', value: filter.type });
    }
    if (filter.status) {
        queryParameters.push({ key: 'status', value: filter.status });
    }
    if (filter.search) {
        queryParameters.push({ key: 'search', value: filter.search });
    }
    return queryParameters;
};
