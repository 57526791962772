import React from 'react';
import { useSelector } from 'react-redux';
import { Box, styled } from '@mui/material';
import { IPublicAndPrivateSettings } from '@pepperhq/location-sdk';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { MuiCardForm } from 'lib/form/MuiCardForm';
import logger from 'lib/logger';
import { SettingDisplay } from 'lib/SettingDisplay';
import { ILocationScopedSettingsComponentProps } from './LocationScopedSettings';
import { SettingDisplaySwitch } from './SettingDisplaySwitch';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { SettingDisplayText } from './SettingDisplayText';
import { FormikValues } from 'formik';
import { NumberFormField } from 'lib/form/fields/NumberFormField';
import { parseIntOrNull } from 'lib/helpers';
import { useMediaCheck } from 'lib/hooks/useMediaCheck';
import { useDownloadAsset } from 'lib/hooks/useDownloadAsset';
import { FileInputFormField } from 'lib/form/fields/FileInputFormField';
import { uploadFile } from 'components/content/uploadFile';
import { ApplicationState } from 'store/store';
import { SettingDisplayFile } from './SettingDisplayFile';

const PREFIX = 'LocationSettings';

const classes = {
    settingsCard: `${PREFIX}-settingsCard`,
    displayClassName: `${PREFIX}-displayClassName`
};

const StyledMuiCardForm = styled(MuiCardForm)(({ theme }) => ({
    [`&.${classes.settingsCard}`]: {
        marginBottom: theme.spacing(2)
    },
    [`& .${classes.displayClassName}`]: {
        padding: theme.spacing(2)
    }
}));

enum ETerminalAccessProvider {
    PEPPER = 'PEPPER',
    OMNIVORE_NCR_ALOHA = 'OMNIVORE_NCR_ALOHA'
}

const terminalAccessProviderLabels: Record<ETerminalAccessProvider, string> = {
    [ETerminalAccessProvider.PEPPER]: 'Pepper',
    [ETerminalAccessProvider.OMNIVORE_NCR_ALOHA]: 'Omnivore NCR Aloha'
};

const getTerminalAccessProviderLabel = (provider: string | undefined): string => {
    if (provider === undefined) {
        return terminalAccessProviderLabels[ETerminalAccessProvider.PEPPER];
    }

    return terminalAccessProviderLabels[provider as ETerminalAccessProvider];
};

export const TerminalSettings: React.FC<ILocationScopedSettingsComponentProps> = ({
    settings,
    onSettingsSaved,
    locationId
}) => {
    const { tenantId } = useSelector((state: ApplicationState) => state.auth.tenant);
    const [
        globalFloorPlanUrl,
        globalFloorPlanFileLoading,
        checkGlobalFloorPlanFile,
        getGlobalFloorPlanFileUrl
    ] = useMediaCheck(
        `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/floor_plans/global`,
        'global'
    );

    const { handleDownload: handleGlobalFloorPlanDownload } = useDownloadAsset({
        src: getGlobalFloorPlanFileUrl(),
        fileName: globalFloorPlanUrl
    });

    const [
        locationFloorPlanUrl,
        locationFloorPlanFileLoading,
        checkLocationFloorPlanFile,
        getLocationFloorPlanFileUrl
    ] = useMediaCheck(
        `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/floor_plans/${locationId}`,
        `${locationId}`
    );

    const { handleDownload: handleLocationFloorPlanDownload } = useDownloadAsset({
        src: getLocationFloorPlanFileUrl(),
        fileName: locationFloorPlanUrl
    });

    const handleSubmit = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                const folderName = encodeURIComponent('app_assets/floor_plans');

                if (values.tablePlanFile) {
                    const { ok } = await uploadFile(
                        new File([values.tablePlanFile.slice()], values.tablePlanFile.name, {
                            type: 'application/pdf'
                        }),
                        'global',
                        folderName
                    );
                    if (ok) {
                        checkGlobalFloorPlanFile();
                    }
                }

                if (values.locationTablePlanFile) {
                    const { ok } = await uploadFile(
                        new File([values.locationTablePlanFile.slice()], values.locationTablePlanFile.name, {
                            type: 'application/pdf'
                        }),
                        `${locationId}`,
                        folderName
                    );
                    if (ok) {
                        checkLocationFloorPlanFile();
                    }
                }

                let settingsToUpdate: IPublicAndPrivateSettings = {
                    terminal: {
                        blockAmexPayments: values.blockAmexPayments,
                        coversInputEnabled: values.coversInputEnabled,
                        coversInputRequired: values.coversInputRequired && values.coversInputEnabled,
                        maxCovers: values.coversInputEnabled ? parseIntOrNull(values.maxCovers) : null,
                        billSplittingByItemEnabled: values.billSplittingByItemEnabled,
                        billSplittingByPercentageEnabled: values.billSplittingByPercentageEnabled,
                        billSplittingByCustomAmountEnabled: values.billSplittingByCustomAmountEnabled
                    }
                };

                if (location !== undefined) {
                    const sanitizedLocationTableUrl = new URL(getLocationFloorPlanFileUrl());
                    for (const key of sanitizedLocationTableUrl.searchParams.keys()) {
                        sanitizedLocationTableUrl.searchParams.delete(key);
                    }
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        terminal: {
                            ...settingsToUpdate.terminal,
                            tablePlanUrl: sanitizedLocationTableUrl.toString()
                        }
                    };
                }

                // Global Settings
                if (locationId === undefined) {
                    const sanitizedTableUrl = new URL(getGlobalFloorPlanFileUrl());
                    for (const key of sanitizedTableUrl.searchParams.keys()) {
                        sanitizedTableUrl.searchParams.delete(key);
                    }
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        terminal: {
                            ...settingsToUpdate.terminal,
                            orderingEnabled: values.orderingEnabled,
                            counterServiceOrderingEnabled: values.counterServiceOrderingEnabled,
                            orderToTableOrderingEnabled: values.orderToTableOrderingEnabled,
                            openTableOrderingEnabled: values.openTableOrderingEnabled,
                            uncapturedPaymentHandlingEnabled: values.uncapturedPaymentHandlingEnabled,
                            tableNumberKeyboardInputEnabled: values.tableNumberKeyboardInputEnabled,
                            tablesViewEnabled: values.tablesViewEnabled,
                            createStandalonePaymentButtonEnabled: values.createStandalonePaymentButtonEnabled,
                            allergenCaptureEnabled: values.allergenCaptureEnabled,
                            tablePlanUrl: sanitizedTableUrl.toString()
                        },
                        terminalAccessProvider: values.terminalAccessProvider,
                        orderNotesEnabled: {
                            item: values.itemOrderNotesEnabled
                        }
                    };
                }
                if (!Object.keys(settingsToUpdate).length) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);
                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [
            locationId,
            onSettingsSaved,
            checkGlobalFloorPlanFile,
            getGlobalFloorPlanFileUrl,
            checkLocationFloorPlanFile,
            getLocationFloorPlanFileUrl
        ]
    );

    const renderEdit = React.useCallback(
        (values: FormikValues) => (
            <Box display="flex" flexDirection="column">
                {locationId === undefined && (
                    <React.Fragment>
                        <SettingDisplay label="Quickpad Ordering Enabled">
                            <SwitchFormField name="orderingEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Counter Service Enabled">
                            <SwitchFormField name="counterServiceOrderingEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Order To Table Enabled">
                            <SwitchFormField name="orderToTableOrderingEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Open Table Ordering Enabled">
                            <SwitchFormField name="openTableOrderingEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Item Bill Splitting Enabled">
                            <SwitchFormField name="billSplittingByItemEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Percentage Share Bill Splitting Enabled">
                            <SwitchFormField name="billSplittingByPercentageEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Custom Amount Bill Splitting Enabled">
                            <SwitchFormField name="billSplittingByCustomAmountEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Table Number Keyboard Input Enabled">
                            <SwitchFormField name="tableNumberKeyboardInputEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Tables View Enabled">
                            <SwitchFormField name="tablesViewEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Create Standalone Payment">
                            <SwitchFormField name="createStandalonePaymentButtonEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Capture and Void Pending Payments Enabled">
                            <SwitchFormField name="uncapturedPaymentHandlingEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Capture Allergens">
                            <SwitchFormField name="allergenCaptureEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Item Level Notes">
                            <SwitchFormField name="itemOrderNotesEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Clerk Access Provider">
                            <SelectFormField
                                name="terminalAccessProvider"
                                options={[
                                    {
                                        label: terminalAccessProviderLabels[ETerminalAccessProvider.PEPPER],
                                        value: ETerminalAccessProvider.PEPPER
                                    },
                                    {
                                        label: terminalAccessProviderLabels[
                                            ETerminalAccessProvider.OMNIVORE_NCR_ALOHA
                                        ],
                                        value: ETerminalAccessProvider.OMNIVORE_NCR_ALOHA
                                    }
                                ]}
                            />
                        </SettingDisplay>
                    </React.Fragment>
                )}
                {locationId !== undefined && (
                    <React.Fragment>
                        <SettingDisplay label="Item Bill Splitting Enabled">
                            <SwitchFormField name="billSplittingByItemEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Percentage Share Bill Splitting Enabled">
                            <SwitchFormField name="billSplittingByPercentageEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Custom Amount Bill Splitting Enabled">
                            <SwitchFormField name="billSplittingByCustomAmountEnabled" />
                        </SettingDisplay>
                    </React.Fragment>
                )}
                <SettingDisplay label="Block Amex Payments">
                    <SwitchFormField name="blockAmexPayments" />
                </SettingDisplay>
                <SettingDisplay label="Covers Input Enabled">
                    <SwitchFormField name="coversInputEnabled" />
                </SettingDisplay>
                {values.coversInputEnabled && (
                    <React.Fragment>
                        <SettingDisplay label="Covers Input Required">
                            <SwitchFormField name="coversInputRequired" />
                        </SettingDisplay>
                        <SettingDisplay label="Maximum Covers">
                            <NumberFormField name="maxCovers" />
                        </SettingDisplay>
                    </React.Fragment>
                )}
                {locationId !== undefined ? (
                    <SettingDisplay label="Table Plan" description="PDF only">
                        <FileInputFormField
                            name="locationTablePlanFile"
                            attributes={{ accept: '.pdf' }}
                            path={locationFloorPlanUrl}
                            isLoading={locationFloorPlanFileLoading}
                        />
                    </SettingDisplay>
                ) : (
                    <SettingDisplay label="Table Plan" description="PDF only">
                        <FileInputFormField
                            name="tablePlanFile"
                            attributes={{ accept: '.pdf' }}
                            path={globalFloorPlanUrl}
                            isLoading={globalFloorPlanFileLoading}
                        />
                    </SettingDisplay>
                )}
            </Box>
        ),
        [
            locationId,
            globalFloorPlanUrl,
            globalFloorPlanFileLoading,
            locationFloorPlanUrl,
            locationFloorPlanFileLoading
        ]
    );

    return (
        <Box minWidth="500px">
            <StyledMuiCardForm
                title="General"
                initialValues={{
                    orderingEnabled: settings?.terminal?.orderingEnabled ?? true,
                    counterServiceOrderingEnabled: settings?.terminal?.counterServiceOrderingEnabled ?? false,
                    orderToTableOrderingEnabled: settings?.terminal?.orderToTableOrderingEnabled ?? false,
                    openTableOrderingEnabled: settings?.terminal?.openTableOrderingEnabled ?? true,
                    uncapturedPaymentHandlingEnabled:
                        settings?.terminal?.uncapturedPaymentHandlingEnabled ?? false,
                    tableNumberKeyboardInputEnabled:
                        settings?.terminal?.tableNumberKeyboardInputEnabled ?? false,
                    tablesViewEnabled: settings?.terminal?.tablesViewEnabled ?? false,
                    createStandalonePaymentButtonEnabled:
                        settings?.terminal?.createStandalonePaymentButtonEnabled ?? false,
                    allergenCaptureEnabled: settings?.terminal?.allergenCaptureEnabled ?? false,
                    itemOrderNotesEnabled: settings?.orderNotesEnabled?.item ?? false,
                    terminalAccessProvider: settings?.terminalAccessProvider ?? 'PEPPER',
                    blockAmexPayments: settings?.terminal?.blockAmexPayments ?? false,
                    coversInputEnabled: settings?.terminal?.coversInputEnabled ?? false,
                    coversInputRequired: settings?.terminal?.coversInputRequired ?? false,
                    maxCovers: settings?.terminal?.maxCovers ?? null,
                    tablePlanFile: undefined,
                    locationTablePlanFile: undefined,
                    billSplittingByItemEnabled: settings?.terminal?.billSplittingByItemEnabled ?? true,
                    billSplittingByPercentageEnabled:
                        settings?.terminal?.billSplittingByPercentageEnabled ?? true,
                    billSplittingByCustomAmountEnabled:
                        settings?.terminal?.billSplittingByCustomAmountEnabled ?? true
                }}
                onSubmit={handleSubmit}
                childrenWhileDisplaying={
                    <Box display="flex" flexDirection="column">
                        {locationId === undefined && (
                            <React.Fragment>
                                <SettingDisplaySwitch
                                    title="Ordering Enabled"
                                    checked={settings?.terminal?.orderingEnabled ?? true}
                                />
                                <SettingDisplaySwitch
                                    title="Counter Service Enabled"
                                    checked={settings?.terminal?.counterServiceOrderingEnabled}
                                />
                                <SettingDisplaySwitch
                                    title="Order To Table Enabled"
                                    checked={settings?.terminal?.orderToTableOrderingEnabled}
                                />
                                <SettingDisplaySwitch
                                    title="Open Table Ordering Enabled"
                                    checked={settings?.terminal?.openTableOrderingEnabled ?? true}
                                />
                                <SettingDisplaySwitch
                                    title="Item Bill Splitting Enabled"
                                    checked={settings?.terminal?.billSplittingByItemEnabled ?? true}
                                />
                                <SettingDisplaySwitch
                                    title="Percentage Share Bill Splitting Enabled"
                                    checked={settings?.terminal?.billSplittingByPercentageEnabled ?? true}
                                />
                                <SettingDisplaySwitch
                                    title="Custom Amount Bill Splitting Enabled"
                                    checked={settings?.terminal?.billSplittingByCustomAmountEnabled ?? true}
                                />
                                <SettingDisplaySwitch
                                    title="Table Number Keyboard Input Enabled"
                                    checked={settings?.terminal?.tableNumberKeyboardInputEnabled}
                                />
                                <SettingDisplaySwitch
                                    title="Tables View Enabled"
                                    checked={settings?.terminal?.tablesViewEnabled}
                                />
                                <SettingDisplaySwitch
                                    title="Create Standalone Payment"
                                    checked={settings?.terminal?.createStandalonePaymentButtonEnabled}
                                />
                                <SettingDisplaySwitch
                                    title="Capture and Void Pending Payments Enabled"
                                    checked={settings?.terminal?.uncapturedPaymentHandlingEnabled}
                                />
                                <SettingDisplaySwitch
                                    title="Capture Allergens"
                                    checked={settings?.terminal?.allergenCaptureEnabled}
                                />
                                <SettingDisplaySwitch
                                    title="Item Level Notes"
                                    checked={settings?.orderNotesEnabled?.item}
                                />
                                <SettingDisplayText
                                    title="Clerk Access Provider"
                                    text={getTerminalAccessProviderLabel(settings?.terminalAccessProvider)}
                                />
                            </React.Fragment>
                        )}
                        {locationId !== undefined && (
                            <React.Fragment>
                                <SettingDisplaySwitch
                                    title="Item Bill Splitting Enabled"
                                    checked={settings?.terminal?.billSplittingByItemEnabled ?? true}
                                />
                                <SettingDisplaySwitch
                                    title="Percentage Share Bill Splitting Enabled"
                                    checked={settings?.terminal?.billSplittingByPercentageEnabled ?? true}
                                />
                                <SettingDisplaySwitch
                                    title="Custom Amount Bill Splitting Enabled"
                                    checked={settings?.terminal?.billSplittingByCustomAmountEnabled ?? true}
                                />
                            </React.Fragment>
                        )}
                        <SettingDisplaySwitch
                            title="Block Amex Payments"
                            checked={settings?.terminal?.blockAmexPayments}
                        />
                        <SettingDisplaySwitch
                            title="Covers Input Enabled"
                            checked={settings?.terminal?.coversInputEnabled}
                        />
                        {settings?.terminal?.coversInputEnabled && (
                            <React.Fragment>
                                <SettingDisplaySwitch
                                    title="Covers Input Required"
                                    checked={settings?.terminal?.coversInputRequired}
                                />
                                <SettingDisplayText
                                    title="Maximum Covers"
                                    text={settings?.terminal?.maxCovers}
                                />
                            </React.Fragment>
                        )}
                        {locationId !== undefined ? (
                            <SettingDisplayFile
                                title="Table Plan"
                                description="PDF only"
                                noFileText="Click Edit to Upload Table Plan"
                                variant="body2"
                                url={getLocationFloorPlanFileUrl()}
                                fileName={locationFloorPlanUrl}
                                showDownloadButton={true}
                                handleDownload={handleLocationFloorPlanDownload}
                            />
                        ) : (
                            <SettingDisplayFile
                                title="Table Plan"
                                description="PDF only"
                                noFileText="Click Edit to Upload Table Plan"
                                variant="body2"
                                url={getGlobalFloorPlanFileUrl()}
                                fileName={globalFloorPlanUrl}
                                showDownloadButton={true}
                                handleDownload={handleGlobalFloorPlanDownload}
                            />
                        )}
                    </Box>
                }
                childrenWhileEditing={renderEdit}
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />
        </Box>
    );
};
