import React from 'react';
import { FormControl, FormControlLabel, FormHelperText, Switch } from '@mui/material';
import { Field, FieldAttributes, FieldProps } from 'formik';

interface SwitchProps {
    label?: React.ReactNode;
    description?: string;
    disabled?: boolean;
}

export const SwitchFormField = ({
    label,
    description,
    disabled,
    ...props
}: FieldAttributes<SwitchProps>): JSX.Element => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <SwitchFormFieldComponent
                label={label}
                description={description}
                disabled={disabled}
                {...childProps}
            />
        )}
    </Field>
);

const SwitchFormFieldComponent = ({
    field,
    meta,
    label,
    description,
    disabled
}: FieldProps & SwitchProps): JSX.Element => {
    const isError = meta.touched && !!meta.error;
    return (
        <FormControl error={isError} fullWidth>
            <FormControlLabel
                control={
                    <Switch
                        color="primary"
                        name={field.name}
                        checked={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        disabled={disabled}
                    />
                }
                label={label}
            />
            {(isError || description) && (
                <FormHelperText sx={{ mx: 0 }} error={isError}>
                    {isError ? meta.error : description}
                </FormHelperText>
            )}
        </FormControl>
    );
};
