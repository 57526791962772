import { metricsApi } from 'components/metrics/metricsApi';
import {
    BirthdayAudience,
    EPremiumLoyaltyConditionOperator,
    EPremiumLoyaltyConditionUserProperty,
    isUserMetricBirthdayCondition,
    UserMetricDefinition,
    UserMetricUserPropertyQuery,
    UserMetricUserPropertyTimeUnitComparators
} from 'components/metrics/model';
import {
    MESSAGE_BIRTHDAY_LOYALTY_AUDIENCE_CREATE_ERROR,
    MESSAGE_BIRTHDAY_LOYALTY_AUDIENCE_CREATE_SUCCESS,
    MESSAGE_BIRTHDAY_LOYALTY_AUDIENCE_DELETE_ERROR,
    MESSAGE_BIRTHDAY_LOYALTY_AUDIENCE_DELETE_SUCCESS,
    MESSAGE_BIRTHDAY_LOYALTY_AUDIENCE_EDIT_ERROR,
    MESSAGE_BIRTHDAY_LOYALTY_AUDIENCE_EDIT_SUCCESS
} from 'config/messages';
import logger from 'lib/logger';
import { birthdayDaysToRelativeTimeString } from 'lib/relativeTimeString';
import { isNumber, isUndefined } from 'lib/typeguards';
import React from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';

const generateBirthdateQuery = (from: number, to: number, toDaysAfterBirthday: number[]) => {
    const orArray: UserMetricUserPropertyTimeUnitComparators[] = [];
    const stopWhen = to > 0 ? 0 : to;
    for (let i = from; i <= stopWhen; i++) {
        orArray.push({
            month: { eq: birthdayDaysToRelativeTimeString(i) },
            dayOfMonth: { eq: birthdayDaysToRelativeTimeString(i) }
        });
    }
    toDaysAfterBirthday.forEach(day => {
        orArray.push({
            month: { eq: birthdayDaysToRelativeTimeString(day) },
            dayOfMonth: { eq: birthdayDaysToRelativeTimeString(day) }
        });
    });
    return {
        or: orArray
    };
};

export function useBirthdayAudienceActions(
    definition: UserMetricDefinition,
    birthdayAudiences: BirthdayAudience[],
    onSuccess: (definition: UserMetricDefinition) => void,
    selectedAudience?: BirthdayAudience
) {
    const dispatch = useDispatch();
    const [isChangeLoading, setIsChangeLoading] = React.useState(false);
    const updateQuery = React.useCallback(
        async (currentAudienceWindow?: { fromDays: number; toDays: number }) => {
            let fromMin: number;
            let toMax: number;
            const toDaysAfter: number[] = [];
            if (currentAudienceWindow) {
                fromMin = currentAudienceWindow.fromDays;
                toMax = currentAudienceWindow.toDays;
                if (currentAudienceWindow.toDays > 0) {
                    toDaysAfter.push(currentAudienceWindow.toDays);
                }
            }
            birthdayAudiences.forEach(audience => {
                if (audience.id !== selectedAudience?.id) {
                    if (isUndefined(fromMin) || audience.fromDays < fromMin) {
                        fromMin = audience.fromDays;
                    }
                    if (isUndefined(toMax) || audience.toDays > toMax) {
                        toMax = audience.toDays;
                    }
                    if (audience.toDays > 0) {
                        toDaysAfter.push(audience.toDays);
                    }
                }
            });
            let query: UserMetricUserPropertyQuery = {};
            const { birthdate: _, ...rest } = definition?.query || {};
            if (Object.keys(rest).length > 0) {
                query = rest;
            }
            if (isNumber(fromMin) && isNumber(toMax)) {
                query = {
                    ...(query || {}),
                    birthdate: generateBirthdateQuery(fromMin, toMax, toDaysAfter)
                };
            }
            const result = await metricsApi.update(definition?._id, {
                body: {
                    query
                }
            });
            if (!result.ok) {
                throw new Error("Couldn't update the query");
            }
            return result.body;
        },
        [birthdayAudiences, definition?._id, definition?.query, selectedAudience?.id]
    );
    const onBirthdayAudienceChange = React.useCallback(
        async (data: { audienceId: string; fromDays: number; toDays: number }) => {
            const errorMessage = selectedAudience
                ? MESSAGE_BIRTHDAY_LOYALTY_AUDIENCE_EDIT_ERROR
                : MESSAGE_BIRTHDAY_LOYALTY_AUDIENCE_CREATE_ERROR;
            const successMessage = selectedAudience
                ? MESSAGE_BIRTHDAY_LOYALTY_AUDIENCE_EDIT_SUCCESS
                : MESSAGE_BIRTHDAY_LOYALTY_AUDIENCE_CREATE_SUCCESS;
            try {
                setIsChangeLoading(true);
                // Find existing add and remove effects for current audience
                const validListenersIds = new Set<string>();
                const addToAudienceListeners =
                    definition?.effects.filter(
                        listener =>
                            listener.type === 'ADD_TO_SEGMENT' &&
                            listener.segmentId === data.audienceId &&
                            listener.conditions?.find(condition => isUserMetricBirthdayCondition(condition))
                    ) ?? [];
                const removeFromAudienceListeners =
                    definition?.effects.filter(
                        listener =>
                            listener.type === 'REMOVE_FROM_SEGMENT' &&
                            listener.segmentId === data.audienceId &&
                            listener.conditions?.find(condition => isUserMetricBirthdayCondition(condition))
                    ) ?? [];
                // Form a list of "Add to the audience" effects by day that don't exist
                const daysToAdd: number[] = [];
                const to = data.toDays > 0 ? 0 : data.toDays - 1;
                for (let daysFromBirthday = data.fromDays; daysFromBirthday <= to; daysFromBirthday++) {
                    const existingListener = addToAudienceListeners.find(
                        listener =>
                            listener.conditions?.find(
                                condition =>
                                    isUserMetricBirthdayCondition(condition) &&
                                    condition.datePart === 'dayOfMonth' &&
                                    condition.value.includes(
                                        birthdayDaysToRelativeTimeString(daysFromBirthday)
                                    )
                            ) &&
                            listener.conditions?.find(
                                condition =>
                                    isUserMetricBirthdayCondition(condition) &&
                                    condition.datePart === 'month' &&
                                    condition.value.includes(
                                        birthdayDaysToRelativeTimeString(daysFromBirthday)
                                    )
                            )
                    );
                    if (!existingListener) {
                        daysToAdd.push(daysFromBirthday);
                    } else {
                        validListenersIds.add(existingListener._id);
                    }
                }
                // Add all missing "Add to the audience" effects
                const results = await Promise.all(
                    daysToAdd.map(item =>
                        metricsApi.addEffect(definition?._id, {
                            type: 'ADD_TO_SEGMENT',
                            segmentId: data.audienceId,
                            conditions: [
                                {
                                    userProperty: EPremiumLoyaltyConditionUserProperty.Birthdate,
                                    operator: EPremiumLoyaltyConditionOperator.Equal,
                                    datePart: 'month',
                                    value: birthdayDaysToRelativeTimeString(item)
                                },
                                {
                                    userProperty: EPremiumLoyaltyConditionUserProperty.Birthdate,
                                    operator: EPremiumLoyaltyConditionOperator.Equal,
                                    datePart: 'dayOfMonth',
                                    value: birthdayDaysToRelativeTimeString(item)
                                }
                            ]
                        })
                    )
                );
                if (results.some(item => !item.ok)) {
                    throw new Error("Couldn't add a listener");
                }
                // Find or add a "Remove from the audience" listener
                const removeListenerDay = birthdayDaysToRelativeTimeString(data.toDays);
                const validRemoveListener = removeFromAudienceListeners.find(listener =>
                    listener?.conditions.find(
                        condition =>
                            isUserMetricBirthdayCondition(condition) &&
                            condition.value.includes(removeListenerDay)
                    )
                );
                // Add a new remove listener
                if (validRemoveListener) {
                    validListenersIds.add(validRemoveListener._id);
                } else {
                    const result = await metricsApi.addEffect(definition?._id, {
                        type: 'REMOVE_FROM_SEGMENT',
                        segmentId: data.audienceId,
                        conditions: [
                            {
                                userProperty: EPremiumLoyaltyConditionUserProperty.Birthdate,
                                operator: EPremiumLoyaltyConditionOperator.Equal,
                                datePart: 'month',
                                value: removeListenerDay
                            },
                            {
                                userProperty: EPremiumLoyaltyConditionUserProperty.Birthdate,
                                operator: EPremiumLoyaltyConditionOperator.Equal,
                                datePart: 'dayOfMonth',
                                value: removeListenerDay
                            }
                        ]
                    });
                    if (!result.ok) {
                        throw new Error(result.body.message);
                    }
                }
                // Remove effects that are no longer needed
                const removeListenerResults = await Promise.all(
                    [
                        ...addToAudienceListeners.filter(item => !validListenersIds.has(item._id)),
                        ...removeFromAudienceListeners.filter(item => !validListenersIds.has(item._id))
                    ].map(listener => metricsApi.deleteEffect(definition?._id, listener._id))
                );
                if (removeListenerResults.some(item => !item.ok)) {
                    throw new Error("Couldn't remove a listener");
                }
                // update the query
                const newMetricDefnition = await updateQuery(data);
                onSuccess(newMetricDefnition);
                dispatch(enqueueSnackbar(successMessage, { variant: 'success' }));
            } catch (e) {
                logger.error(e.message, e);
                dispatch(enqueueSnackbar(errorMessage, { variant: 'error' }));
            } finally {
                setIsChangeLoading(false);
            }
        },
        [definition?._id, definition?.effects, dispatch, onSuccess, selectedAudience, updateQuery]
    );
    const onBirthdayAudienceDelete = React.useCallback(async () => {
        try {
            setIsChangeLoading(true);
            const audienceListeners =
                definition?.effects.filter(
                    listener =>
                        (listener.type === 'ADD_TO_SEGMENT' || listener.type === 'REMOVE_FROM_SEGMENT') &&
                        listener.segmentId === selectedAudience?.id
                ) ?? [];
            const removeListenerResults = await Promise.all(
                audienceListeners.map(listener => metricsApi.deleteEffect(definition?._id, listener._id))
            );
            if (removeListenerResults.some(item => !item.ok)) {
                throw new Error("Couldn't remove a listener");
            }
            // update the query
            const newMetricDefnition = await updateQuery();
            onSuccess(newMetricDefnition);
            dispatch(
                enqueueSnackbar(MESSAGE_BIRTHDAY_LOYALTY_AUDIENCE_DELETE_SUCCESS, { variant: 'success' })
            );
        } catch (e) {
            logger.error(e.message, e);
            dispatch(enqueueSnackbar(MESSAGE_BIRTHDAY_LOYALTY_AUDIENCE_DELETE_ERROR, { variant: 'error' }));
        } finally {
            setIsChangeLoading(false);
        }
    }, [definition?._id, definition?.effects, dispatch, onSuccess, selectedAudience?.id, updateQuery]);
    return {
        onBirthdayAudienceChange,
        onBirthdayAudienceDelete,
        isChangeLoading
    };
}
