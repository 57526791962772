import { Crud, HttpClient, httpClient, HttpClientResponse, RequestOptions } from 'lib/HttpClient';

class ContentTemplatesApi extends Crud {
    constructor(client: HttpClient) {
        const headers = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        };
        super(client, process.env.CONTENT_SERVICE_URL, 'apptemplates', headers);
    }
}

class ContentLayoutsApi extends Crud {
    constructor(client: HttpClient) {
        const headers = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        };
        super(client, process.env.CONTENT_SERVICE_URL, 'applayouts', headers);
    }

    public setActiveLayout(body: { layoutId: string }) {
        const headers = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        };
        return this.httpClient.post(`${this.api}/applayouts/active`, { headers, body });
    }

    public getActiveLayout() {
        const headers = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        };
        return this.httpClient.get(`${this.api}/applayouts/active`, { headers });
    }
}

class ContentModulesApi extends Crud {
    constructor(client: HttpClient) {
        const headers = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        };
        super(client, process.env.CONTENT_SERVICE_URL, 'appmodules', headers);
    }
}

interface StripeFileUpload {
    created: number;
    filename: string;
    id: string;
    object: 'file';
    purpose: string;
    size: number;
    type: string;
    url: string;
}
class ContentMediaApi extends Crud {
    constructor(client: HttpClient) {
        const headers: Record<string, string> = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
            'Content-Type': undefined
        };
        super(client, process.env.CONTENT_SERVICE_URL, 'media', headers);
    }

    // This function is specific to uploading files in the media API
    // it requires a folder path to work, such as 'content', 'app_assets' or so.
    public uploadFile(options: RequestOptions, folder = 'content') {
        const headers = options && options.headers ? { ...this.headers, ...options.headers } : this.headers;
        return this.httpClient.post(`${this.api}/${this.instanceName}/${folder}`, {
            ...options,
            headers
        });
    }

    public uploadStripeFile(
        options: RequestOptions,
        purpose = 'dispute_evidence'
    ): HttpClientResponse<StripeFileUpload> {
        const headers = options && options.headers ? { ...this.headers, ...options.headers } : this.headers;
        return this.httpClient.post(`${process.env.PAY_ACCOUNT_SERVICE_URL}/fileUploads/${purpose}`, {
            ...options,
            headers
        });
    }

    public loadStripeFile(options: RequestOptions, id: string): HttpClientResponse<StripeFileUpload> {
        const headers = options && options.headers ? { ...this.headers, ...options.headers } : this.headers;
        return this.httpClient.get(`${process.env.PAY_ACCOUNT_SERVICE_URL}/fileUploads/${id}`, {
            ...options,
            headers
        });
    }
}

class ContentApi {
    templates: ContentTemplatesApi;
    layouts: ContentLayoutsApi;
    modules: ContentModulesApi;
    media: ContentMediaApi;
    constructor(client: HttpClient) {
        this.templates = new ContentTemplatesApi(client);
        this.layouts = new ContentLayoutsApi(client);
        this.modules = new ContentModulesApi(client);
        this.media = new ContentMediaApi(client);
    }
}

export const contentApi = new ContentApi(httpClient);
