import React from 'react';
import { Location } from 'components/location/models/LocationModel';
import { ButtonProps, Tooltip } from '@mui/material';
import { LoadingButton } from 'ui/buttons/LoadingButton';

interface CopyToButtonProps {
    readyToSave?: boolean;
    disabled?: boolean;
    otherLocations: Location[];
    onCopy: () => void;
    className?: string;
    loading?: boolean;
    size?: ButtonProps['size'];
}

export const CopyToButton: React.FC<CopyToButtonProps> = ({
    disabled,
    readyToSave,
    otherLocations,
    className,
    loading,
    onCopy,
    size
}) => {
    const tooltipTitle = React.useMemo(() => {
        if (disabled) {
            return 'You must select at least one row or cell to copy to other locations';
        }
        if (readyToSave) {
            return 'You must save or discard unsaved changes before copying to other locations';
        }
        return '';
    }, [disabled, readyToSave]);

    const handleMouseDown = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        // Keep the focus in the cell
        event.preventDefault();
    }, []);

    if (otherLocations.length < 1) {
        return null;
    }
    return (
        <Tooltip title={tooltipTitle}>
            <div className={className}>
                <LoadingButton
                    size={size}
                    loading={loading}
                    onClick={onCopy}
                    onMouseDown={handleMouseDown}
                    disabled={disabled || readyToSave}
                    variant="outlined"
                    color="primary"
                >
                    Copy To
                </LoadingButton>
            </div>
        </Tooltip>
    );
};
