import React from 'react';
import { MainLayout } from 'layouts/MainLayout';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { EmptyState } from 'components/utils/emptyState';
import EmptyStateImage from 'static/merchants-empty-state.svg';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { AccessStateCell } from 'components/user/AccessStateCell';
import { Box, Button, Paper, TablePagination, TextField, Typography, styled } from '@mui/material';
import MerchantSignUpForm from 'components/merchant/forms/merchantSignUpForm';
import { Row } from 'ui/Flex';
import { PersonAdd } from '@mui/icons-material';
import { MuiTheme } from 'config/theme';
import { AccessCell } from 'components/user/AccessCell';
import { TENANT_ACCESS_ITEMS_PER_PAGE, useTenantAccess } from 'components/user/useTenantAccess';
import { Throbber } from 'ui/Throbber';
import { useTenantSearch } from 'components/user/useTenantSearch';
import { useTenantCreate } from 'components/user/useTenantCreate';

const StyledButton = styled(Button)(({ theme }) => ({
    marginLeft: 'auto',
    padding: theme.spacing(1, 2)
}));

const StyledGrid = styled(MuiGrid)(() => ({
    '[data-field="access"]': {
        paddingLeft: 0
    }
}));

export const MerchantsPage = () => {
    const {
        tenantAccess,
        nextKey,
        page,
        isTenantsLoading,
        isAccessLoading,
        roles,
        updateTenantAccess,
        updateNextKey,
        updatePage,
        handleAccessClick,
        handlePageChange
    } = useTenantAccess();

    const {
        searchValue,
        searchFilter,
        isSearchPending,
        handleSearchClick,
        handleSearchKeyDown,
        handleSearchValueChange
    } = useTenantSearch({ updateTenantAccess, updateNextKey, updatePage });

    const {
        merchantCreateOpen,
        isCreateLoading,
        handleMerchantCreateClick,
        handleMerchantCreateInfo,
        handleMerchantCreateSuccess,
        handleMerchantCreateError
    } = useTenantCreate({ updateTenantAccess, updateNextKey });

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                headerName: '',
                field: 'access',
                disableColumnMenu: true,
                disableReorder: true,
                disableExport: true,
                hideable: false,
                width: 64,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => (
                    <AccessCell params={params} onClick={handleAccessClick(params.row.id)} />
                )
            },
            {
                field: 'tenantName',
                headerName: 'Merchant Name',
                width: 200,
                hideable: false
            },
            {
                field: 'tenantId',
                headerName: 'Merchant ID',
                width: 280
            },
            {
                field: 'accessState',
                headerName: 'Access State',
                width: 160,
                hideable: false,
                renderCell: AccessStateCell
            },
            {
                field: 'roleId',
                headerName: 'Access Level',
                width: 180,
                hideable: false,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const role = roles.find(role => role.id === params.row.roleId);
                    return role?.name ?? 'Unknown';
                }
            },
            {
                field: 'tenantDisabled',
                headerName: 'Disabled',
                width: 100,
                renderCell: (params: GridRenderCellParams) =>
                    params.row.tenantDisabled ? (
                        <Typography variant="body2" color="error">
                            Yes
                        </Typography>
                    ) : (
                        ''
                    )
            }
        ],
        [handleAccessClick, roles]
    );

    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={nextKey ? -1 : tenantAccess?.length ?? 0}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={TENANT_ACCESS_ITEMS_PER_PAGE}
                rowsPerPageOptions={[TENANT_ACCESS_ITEMS_PER_PAGE]}
                component="div"
                sx={{
                    '&:last-child': {
                        paddingRight: MuiTheme.spacing(6)
                    }
                }}
            />
        ),
        [handlePageChange, nextKey, page, tenantAccess?.length]
    );

    const renderSearchButton = React.useCallback(
        () => (
            <Button
                variant="outlined"
                disabled={isSearchPending}
                sx={{ py: 0.5, px: 2 }}
                onClick={handleSearchClick}
            >
                Search
            </Button>
        ),
        [handleSearchClick, isSearchPending]
    );

    const isLoading = isTenantsLoading || isAccessLoading;

    const showData =
        isLoading ||
        isSearchPending ||
        !!nextKey ||
        !!searchFilter ||
        (tenantAccess && tenantAccess?.length > 0);

    const currentPageData = React.useMemo(
        () =>
            tenantAccess?.slice(
                page * TENANT_ACCESS_ITEMS_PER_PAGE,
                (page + 1) * TENANT_ACCESS_ITEMS_PER_PAGE
            ),
        [page, tenantAccess]
    );

    if (isLoading) {
        return (
            <Box height="100%">
                <Throbber />
            </Box>
        );
    }

    return (
        <MainLayout
            noBreadcrumbs
            pageName={showData ? 'Merchants' : ''}
            pageDescription={showData ? 'Manage and access your existing merchants here' : ''}
            noScroll
        >
            <Box paddingBottom={10} height="100%">
                {showData ? (
                    <>
                        <Box paddingBottom={2} paddingTop={2}>
                            <Row flex={1} wrap="wrap" valign="end">
                                <Box marginRight={1}>
                                    <TextField
                                        variant="outlined"
                                        value={searchValue}
                                        label={'Search by merchant name'}
                                        disabled={isSearchPending}
                                        InputProps={{ endAdornment: renderSearchButton() }}
                                        sx={{ width: 350 }}
                                        onChange={handleSearchValueChange}
                                        onKeyDown={handleSearchKeyDown}
                                    />
                                </Box>
                                <StyledButton
                                    startIcon={<PersonAdd />}
                                    variant="outlined"
                                    onClick={handleMerchantCreateClick}
                                >
                                    New Merchant
                                </StyledButton>
                            </Row>
                        </Box>
                        <Paper sx={{ height: '100%', display: 'flex' }}>
                            <StyledGrid
                                rows={(!isSearchPending && currentPageData) || []}
                                columns={columns}
                                loading={isSearchPending}
                                localeText={{ noRowsLabel: 'No merchants found' }}
                                hideFooter={false}
                                pagination
                                disableColumnFilter
                                paginationMode="server"
                                slots={{
                                    pagination: Pagination
                                }}
                            />
                        </Paper>
                    </>
                ) : (
                    <EmptyState
                        headerText="Begin your journey by setting up a merchant!"
                        paragraphText="From there, you will be able to add locations, configure settings and start creating an app!"
                        buttonText="Set up a new merchant"
                        imageUrl={EmptyStateImage}
                        onClick={handleMerchantCreateClick}
                    />
                )}
                {merchantCreateOpen && (
                    <MerchantSignUpForm
                        title="Set up a new merchant"
                        open={merchantCreateOpen}
                        isLoading={isCreateLoading}
                        onClose={handleMerchantCreateClick}
                        onInfo={handleMerchantCreateInfo}
                        onError={handleMerchantCreateError}
                        onSuccess={handleMerchantCreateSuccess}
                    />
                )}
            </Box>
        </MainLayout>
    );
};

export default MerchantsPage;
