import React from 'react';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import { PerksActionType } from 'store/perks/perksActionTypes';
import { ApplicationState } from 'store/store';
import { perkApi } from 'components/loyalty/PerkApi';
import { isNumber } from 'lib/typeguards';

export const usePepperPerksUtils = () => {
    const dispatch = useDispatch();
    const { perks, selectedRow } = useSelector((state: ApplicationState) => state.perks);

    const selectedPerk = React.useMemo(
        () => perks.find(perk => perk._id === selectedRow[0]),
        [perks, selectedRow]
    );

    const handleSelectRow = React.useCallback(
        (newSelectedRow: GridRowSelectionModel) => {
            // Enforces single selection
            if (newSelectedRow.length > 1) {
                dispatch({
                    selectedRow: [newSelectedRow[newSelectedRow.length - 1]],
                    type: PerksActionType.SET_SELECTED_ROW
                });
            } else {
                dispatch({ selectedRow: newSelectedRow, type: PerksActionType.SET_SELECTED_ROW });
            }
        },
        [dispatch]
    );

    const setItemToReset = React.useCallback(
        (index: number) => dispatch({ index, type: PerksActionType.SET_ITEM_TO_RESET }),
        [dispatch]
    );

    const setItemToDelete = React.useCallback(
        (index: number) => dispatch({ index, type: PerksActionType.SET_ITEM_TO_DELETE }),
        [dispatch]
    );

    const setOpenItem = React.useCallback(
        (index: number) => dispatch({ index, type: PerksActionType.SET_OPEN_ITEM }),
        [dispatch]
    );

    const setItemToDuplicate = React.useCallback(
        (index: number) => dispatch({ index, type: PerksActionType.SET_ITEM_TO_DUPLICATE }),
        [dispatch]
    );

    const handleReviewAndEditClick = React.useCallback(() => {
        const index = perks.findIndex(perk => perk._id === selectedPerk._id);

        setOpenItem(index);
    }, [perks, selectedPerk, setOpenItem]);

    const handleDuplicateClick = React.useCallback(() => {
        const index = perks.findIndex(perk => perk._id === selectedPerk._id);

        setItemToDuplicate(index);
    }, [perks, selectedPerk, setItemToDuplicate]);

    const handleResetClick = React.useCallback(async () => {
        if (!selectedPerk) {
            return;
        }

        const index = perks.findIndex(perk => perk._id === selectedPerk._id);
        setItemToReset(index);

        const count = await getPerkAwardCount(selectedPerk._id);
        dispatch({ count, type: PerksActionType.SET_PERK_AWARD_COUNT });
    }, [dispatch, perks, selectedPerk, setItemToReset]);

    const handleDeleteClick = React.useCallback(() => {
        const index = perks.findIndex(perk => perk._id === selectedPerk._id);

        setItemToDelete(index);
    }, [perks, selectedPerk, setItemToDelete]);

    return {
        selectedRow,
        selectedPerk,
        handleSelectRow,
        handleReviewAndEditClick,
        handleResetClick,
        handleDeleteClick,
        handleDuplicateClick
    };
};

const getPerkAwardCount = async (perkId: string): Promise<number> => {
    const response = await perkApi.getPointPerk(perkId, {
        queryParameters: [{ key: 'include', value: 'awardCount' }]
    });

    if (response.ok) {
        if (isNumber(response.body.awardCount)) {
            return response.body.awardCount;
        }
        throw new Error(`Expected Perk but got ${JSON.stringify(response.body)}`);
    }

    throw new Error(response.body.message);
};
