import {
    Notification,
    NotificationCreate,
    NotificationType
} from 'components/notification/models/Notification';
import React from 'react';
import { Column } from 'ui/Flex';
import { Box, Step, StepButton, StepLabel, Stepper } from '@mui/material';
import { IconButton } from 'ui/buttons/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
    NotificationTarget,
    NotificationTargetForm,
    NotificationTargetFormData
} from 'components/notification/forms/NotificationTargetForm';
import { Option } from 'lib/types';
import {
    NotificationGeneralForm,
    NotificationGeneralFormData
} from 'components/notification/forms/NotificationGeneralForm';
import { ContentModule } from 'components/content/models/ContentModule';

export interface NotificationCreationStepperProps {
    onClose: () => void;
    onSubmit: (notification: NotificationCreate) => void;
    initialData?: Partial<Notification<string>>;
    locations: Option[];
    segments: Option[];
    modules: ContentModule[];
}

const notificationDefaultData: NotificationCreate = {
    global: false,
    isProcessingMessage: false,
    type: NotificationType.Alert,
    value: '',
    message: '',
    locationIds: [],
    segmentIds: [],
    userIds: []
};

export const NotificationCreationStepper: React.FC<NotificationCreationStepperProps> = ({
    onClose,
    onSubmit,
    initialData = {},
    segments,
    locations,
    modules
}) => {
    const [steps, _setSteps] = React.useState(['Audience', 'Set Up']);
    const [activeStep, setActiveStep] = React.useState(0);
    const [targetData, setTargetData] = React.useState<Partial<NotificationTargetFormData>>(initialData);
    const [generalData, setGeneralData] = React.useState<Partial<NotificationGeneralFormData>>(initialData);

    const handleStepClick = React.useCallback(
        (index: number) => () => {
            setActiveStep(index);
        },
        []
    );
    const handlePrevious = React.useCallback(
        (data: Partial<NotificationGeneralFormData>) => {
            setGeneralData(data);
            setActiveStep(activeStep - 1);
        },
        [activeStep]
    );
    const handleTargetSubmit = React.useCallback((data: NotificationTargetFormData) => {
        setTargetData(data);
        setActiveStep(1);
    }, []);
    const handleGeneralSubmit = React.useCallback(
        (data: Partial<NotificationGeneralFormData>) => {
            setGeneralData(data);
            const body: NotificationCreate = {
                ...notificationDefaultData,
                // Target
                global: targetData.target === NotificationTarget.GLOBAL,
                locationIds: targetData.target === NotificationTarget.LOCATION ? targetData.locationIds : [],
                segmentIds: targetData.target === NotificationTarget.AUDIENCE ? targetData.segmentIds : [],
                tag: targetData.tag,
                // General
                title: data.title,
                message: data.message,
                ...(data.imageUrl && { imageUrl: data.imageUrl }),
                deepLink: data.deepLink,
                buttonText: data.buttonText
            };

            onSubmit(body);
        },
        [targetData, onSubmit]
    );

    return (
        <Box width="100%">
            <Column>
                <Box flex={1} pb={2}>
                    <Stepper nonLinear alternativeLabel activeStep={activeStep}>
                        {steps.map((item, index) => (
                            <Step key={`step-button-${item}`} completed={activeStep > index}>
                                <StepButton
                                    disableRipple
                                    disabled={activeStep < index}
                                    onClick={handleStepClick(index)}
                                    color="inherit"
                                >
                                    <StepLabel>{item}</StepLabel>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                <Box position="absolute" top={0} right={0}>
                    <IconButton size="large">
                        <CloseIcon onClick={onClose} />
                    </IconButton>
                </Box>
                <NotificationTargetForm
                    open={activeStep === 0}
                    onNext={handleTargetSubmit}
                    onCancel={onClose}
                    initialData={targetData}
                    locations={locations}
                    segments={segments}
                />
                <NotificationGeneralForm
                    open={activeStep === 1}
                    onSubmit={handleGeneralSubmit}
                    onPrevious={handlePrevious}
                    initialData={generalData}
                    modules={modules}
                />
            </Column>
        </Box>
    );
};
