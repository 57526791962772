import { MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import React from 'react';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { orderApi } from '../orderApi';
import { MESSAGE_ORDER_FORCE_CLOSE_ERROR, MESSAGE_ORDER_FORCE_CLOSE_INITIATED } from 'config/messages';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

const StyledSelect = styled(Select)(({ theme }) => ({
    width: 'auto',
    '& div': {
        paddingLeft: theme.spacing(1)
    }
}));

const closeOrdersDialogTitle = 'Close open orders';
const closeOrderCutoffOptions = [
    { label: '24 hours', value: 24 },
    { label: '48 hours', value: 48 },
    { label: '1 week', value: 168 }
];

interface ICloseOrderDialogProps {
    isCloseOrdersDialogOpen: boolean;
    closeDialog: () => void;
}

const protectionWord = 'PERMANENTLY CLOSE';

export const CloseOrderDialog: React.FC<ICloseOrderDialogProps> = ({
    isCloseOrdersDialogOpen,
    closeDialog
}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [cutoff, setCutoff] = React.useState<number>(48);

    const handleCutoffChange = React.useCallback(
        (e: SelectChangeEvent) => {
            setCutoff(parseInt(e.target.value));
        },
        [setCutoff]
    );

    const handleCloseError = React.useCallback(() => {
        setLoading(false);
        dispatch(enqueueSnackbar(MESSAGE_ORDER_FORCE_CLOSE_ERROR, { variant: 'error' }));
        closeDialog();
    }, [closeDialog, dispatch]);

    const handleCloseSuccess = React.useCallback(() => {
        setLoading(false);
        dispatch(enqueueSnackbar(MESSAGE_ORDER_FORCE_CLOSE_INITIATED, { variant: 'success' }));
        closeDialog();
    }, [closeDialog, dispatch]);

    const handleSubmit = React.useCallback(async () => {
        setLoading(true);
        const cutoffDate = new Date(Date.now() - cutoff * 60 * 60 * 1000);
        const res = await orderApi.closeOpenOrders(cutoffDate);
        if (!res.ok) {
            handleCloseError();
            return;
        }
        handleCloseSuccess();
    }, [cutoff, handleCloseError, handleCloseSuccess]);

    const content = React.useMemo(
        () => (
            <Content
                protectionWord={protectionWord}
                cutoff={cutoff}
                handleCutoffChange={handleCutoffChange}
            />
        ),
        [cutoff, handleCutoffChange]
    );

    return (
        <DeleteDialog
            title={closeOrdersDialogTitle}
            loading={loading}
            content={content}
            open={isCloseOrdersDialogOpen}
            onDelete={handleSubmit}
            onClose={closeDialog}
            protectionWord={protectionWord}
            submitLabel="Force Close Orders"
        />
    );
};

interface IContentProps {
    protectionWord: string;
    cutoff: number;
    handleCutoffChange: (e: SelectChangeEvent) => void;
}

const Content: React.FC<IContentProps> = ({ protectionWord, cutoff, handleCutoffChange }) => {
    const { claims } = useSelector((state: ApplicationState) => state.auth);

    const options = React.useMemo(
        () =>
            closeOrderCutoffOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            )),
        []
    );

    return (
        <React.Fragment>
            <span style={{ whiteSpace: 'nowrap' }}>
                This action will irreversibly close all open orders at{' '}
                <strong>{claims.locationIds?.length || 'all'}</strong> locations older than
                <StyledSelect name="cutoff" onChange={handleCutoffChange} value={cutoff} variant="standard">
                    {options}
                </StyledSelect>
            </span>
            {'\n\n'}It may take a few minutes to close all open orders.
            {'\n\n'}To confirm, please type <strong>{protectionWord}</strong> in the field below.{'\n\n'}
        </React.Fragment>
    );
};
