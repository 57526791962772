import { initializeApp, getApp, getApps, FirebaseApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';

export const auth: () => Auth = () => {
    const apps = getApps();
    let app: FirebaseApp;
    if (!apps.length) {
        app = initializeApp({
            apiKey: process.env.GOOGLE_API_KEY,
            authDomain: process.env.CONSOLE_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.CONSOLE_FIREBASE_DATABASE_URL,
            projectId: process.env.CONSOLE_FIREBASE_PROJECT_ID,
            storageBucket: process.env.CONSOLE_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.CONSOLE_FIREBASE_MESSAGING_SENDER_ID
        });
    } else {
        app = getApp();
    }
    return getAuth(app);
};
