import { contentApi } from 'components/content/contentApi';
import { MAX_IMAGE_UPLOAD_SIZE, MAX_IMAGE_UPLOAD_SIZE_ERROR } from 'config/const';
import { resizeImage } from 'lib/helpers';
import { isString } from 'lib/typeguards';
import React from 'react';
import { IAdditionalUpload } from '.';
import logger from 'lib/logger';
import { v4 } from 'uuid';

interface IConfig {
    aspect?: number;
    height?: number;
    width?: number;
    url?: string;
    uploadFolder?: string;
    additionalUploads?: IAdditionalUpload[];
    onSelect: (imageUrl: string | null) => void;
    randomNamePrefix?: string;
    uploadFileName?: string;
}

export function useImagePicker(config: IConfig, onFileSelectSuccess: () => void, onCloseSuccess: () => void) {
    const [src, setSrc] = React.useState<string>();
    const [file, setFile] = React.useState<File>();
    const [error, setError] = React.useState<string>();
    const [loading, setLoading] = React.useState(false);
    const [externalUrl, setExternalUrl] = React.useState(
        config.url ? `${config.url}?t=${new Date().toTimeString()}` : config.url
    );

    const aspect = React.useMemo(() => {
        if (config.aspect) {
            // eslint-disable-next-line prefer-destructuring
            return config.aspect;
        }
        return config.width && config.height ? config.width / config.height : null;
    }, [config.aspect, config.height, config.width]);

    const onFileSelect = React.useCallback(
        (file: File) => {
            setFile(file);
            onFileSelectSuccess();
            if (file?.size > MAX_IMAGE_UPLOAD_SIZE) {
                setError(MAX_IMAGE_UPLOAD_SIZE_ERROR);
            }
        },
        [onFileSelectSuccess]
    );
    const onClear = React.useCallback(() => {
        setFile(null);
        setExternalUrl(null);
        config.onSelect(null);
    }, [config]);
    const onClose = React.useCallback(() => {
        setError(undefined);
        setFile(null);
        onCloseSuccess();
    }, [onCloseSuccess]);
    const onUploadSuccess = React.useCallback(
        (resultUrl: string) => {
            setExternalUrl(resultUrl);
            onClose();
        },
        [onClose]
    );
    const onSubmit = React.useCallback(async () => {
        setLoading(true);
        const formData = new FormData();
        if (file) {
            formData.append(
                'files',
                file,
                config.uploadFileName ??
                    (config.randomNamePrefix ? `${config.randomNamePrefix}${v4()}` : file.name)
            );
        }
        try {
            const result = await contentApi.media.uploadFile(
                { body: formData, skipStringify: true },
                config.uploadFolder
            );
            if (!result.ok) {
                throw new Error('Failed to upload an image');
            }

            // There may be additional uploads to consider, with specific sizes
            // This is used in branding images, for iOS icons and such.
            if (config.additionalUploads) {
                await Promise.all(
                    config.additionalUploads.map(
                        async ({ width, height, format, uploadFolder, fileName }) => {
                            const resizedFile = await resizeImage(file, width, height, format);
                            const formData = new FormData();
                            formData.append(
                                'files',
                                resizedFile,
                                config.randomNamePrefix ? `${config.randomNamePrefix}${v4()}` : fileName
                            );
                            await contentApi.media.uploadFile(
                                { body: formData, skipStringify: true },
                                uploadFolder
                            );
                        }
                    )
                );
            }
            // TODO: find a way to do it better
            const resultImageUrl = result.body.items[0].url;
            config.onSelect(resultImageUrl);
            const date = new Date().toTimeString();
            setLoading(false);
            onUploadSuccess(`${resultImageUrl}?${date}`);
        } catch (e) {
            logger.error('Failed to upload an image', e);
            setError(e.message);
            setLoading(false);
        }
    }, [config, file, onUploadSuccess]);

    React.useEffect(() => {
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const binaryStr = reader.result;
                if (isString(binaryStr)) {
                    setSrc(binaryStr);
                } else {
                    setError("Couldn't load the file, please try again later.");
                }
            };
            reader.readAsDataURL(file);
        } else {
            setSrc(undefined);
        }
    }, [file]);
    return {
        src,
        file,
        error,
        loading,
        externalUrl,
        aspect,
        onFileSelect,
        onClear,
        onClose,
        onSubmit,
        onUploadSuccess
    };
}
