import React from 'react';
import { DEFAULT_PAGE_LIMIT } from 'lib/hooks/usePagedData';
import { scheduledTasksApi } from 'components/scheduled-tasks/scheduledTasksApi';
import logger from 'lib/logger';
import { toDotNotation, updateArrayItem } from 'lib/helpers';
import { ScheduledTasksFilter } from 'components/scheduled-tasks/filters/ScheduledTaskFilters';
import { getScheduledTasksFilterQuery } from 'components/scheduled-tasks/hooks/useScheduledTasksFilter';
import { ScheduledTask, ScheduledTaskType } from 'components/scheduled-tasks/scheduledTasks.types';
import { GridSortModel } from '@mui/x-data-grid-pro';

export function useScheduledTasks<Type extends ScheduledTaskType = any>(
    filter: ScheduledTasksFilter,
    emptyFilter: boolean
) {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [endOfData, setEndOfData] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(0);
    const [pages, setPages] = React.useState([undefined]);
    const [scheduledTasks, setScheduledTasks] = React.useState<ScheduledTask<Type>[]>([]);
    const [internalFilter, setInternalFilter] = React.useState<ScheduledTasksFilter>();
    const [noData, setNoData] = React.useState<boolean>(false);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'createdAt',
            sort: 'desc'
        }
    ]);
    const reset = React.useCallback(() => {
        setScheduledTasks([]);
        setPages([undefined]);
        setPage(0);
        setLoading(true);
        setEndOfData(false);
    }, []);
    const generateSortString = React.useCallback((model: GridSortModel) => {
        if (model && model[0]) {
            const sortDirection = model[0]?.sort ?? 'desc';
            const sortField = model[0]?.field ?? 'createdAt';
            return `${sortDirection === 'asc' ? '' : '-'}${sortField}`;
        }
        return undefined;
    }, []);
    React.useEffect(() => {
        const getScheduledTasks = async () => {
            if (!internalFilter || endOfData) {
                return;
            }
            setLoading(true);
            try {
                const queryParameters = getScheduledTasksFilterQuery(internalFilter);
                queryParameters.push({ key: 'limit', value: DEFAULT_PAGE_LIMIT.toString() });
                if (pages[page]) {
                    queryParameters.push({ key: 'startKey', value: pages[page] });
                }
                if (sortModel?.length) {
                    queryParameters.push({ key: 'sort', value: generateSortString(sortModel) });
                }
                const { body, ok } = await scheduledTasksApi.getList({
                    queryParameters
                });
                if (!ok) {
                    throw new Error(body.message);
                }
                if (!body.page.nextKey) {
                    setEndOfData(true);
                }
                setScheduledTasks(currentTasks => [
                    ...currentTasks,
                    ...body.items.map((item: ScheduledTask<Type>) => ({ ...item, id: item._id }))
                ]);
                setPages(currentPages => updateArrayItem(currentPages, page + 1, body.page.nextKey));
                setNoData(emptyFilter && scheduledTasks.length === 0 && body.items.length === 0);
            } catch (error) {
                logger.error(error);
                setEndOfData(true);
                setScheduledTasks([]);
            } finally {
                setLoading(false);
            }
        };
        if (
            (page === 0 && scheduledTasks.length === 0) ||
            (!pages[page + 1] && scheduledTasks.length < pages.length * DEFAULT_PAGE_LIMIT)
        ) {
            getScheduledTasks();
        }
    }, [
        endOfData,
        internalFilter,
        page,
        pages,
        scheduledTasks.length,
        sortModel,
        generateSortString,
        emptyFilter
    ]);
    React.useEffect(() => {
        setInternalFilter(filter);
        reset();
    }, [filter, reset]);
    const onPageChange = React.useCallback(
        (pageNumber: number) => {
            if (!loading) {
                setPage(pageNumber);
            }
        },
        [loading]
    );
    const onSortModelChange = React.useCallback(
        (model: GridSortModel) => {
            setSortModel(model);
            reset();
        },
        [reset]
    );
    const count = React.useMemo(() => {
        if (endOfData) {
            return scheduledTasks.length;
        }
        return -1;
    }, [endOfData, scheduledTasks.length]);
    const formattedScheduledTasks = React.useMemo(() => {
        if (!Array.isArray(scheduledTasks)) {
            return [];
        }
        return scheduledTasks
            .slice(page * DEFAULT_PAGE_LIMIT, page * DEFAULT_PAGE_LIMIT + DEFAULT_PAGE_LIMIT)
            .map(task => {
                // map parameters to parameters.field
                const parameters = toDotNotation(task.parameters, 'parameters', 1);
                return {
                    id: task._id,
                    createdAt: task.createdAt,
                    updatedAt: task.updatedAt,
                    triggerAt: task.triggerAt,
                    type: task.type,
                    status: task.status,
                    ...parameters
                };
            });
    }, [page, scheduledTasks]);
    return {
        scheduledTasks,
        page,
        onPageChange,
        loading,
        count,
        formattedScheduledTasks,
        sortModel,
        onSortModelChange,
        reset,
        noData
    };
}
