import React from 'react';
import { styled } from '@mui/material';
import { Option } from 'lib/types';
import { MuiModal } from 'ui/MuiModal';
import { ContentModule } from 'components/content/models/ContentModule';
import { ConfirmDialog } from 'ui/dialogs/ConfirmDialog';
import {
    ICreateScheduledTaskCreatePushNotification,
    IScheduledTaskCreatePushNotification
} from 'components/scheduled-tasks/scheduledTasks.types';
import { ScheduledTaskNotificationStepper } from 'components/scheduled-tasks/notification/modals/ScheduledTaskNotificationStepper';
import { scheduledTasksApi } from 'components/scheduled-tasks/scheduledTasksApi';

interface UpdateScheduledTaskNotificationModalProps {
    open: boolean;
    onClose?: () => void;
    onError: (message: string) => void;
    onSuccess: (scheduledTask: IScheduledTaskCreatePushNotification) => void;
    scheduledTask: IScheduledTaskCreatePushNotification;
    segments: Option[];
    locations: Option[];
    modules: ContentModule[];
}

const StyledMuiModal = styled(MuiModal)(() => ({
    overflow: 'visible' // Needed for the dropdowns
}));

export const UpdateScheduledTaskNotificationModal: React.FunctionComponent<
    UpdateScheduledTaskNotificationModalProps
> = props => {
    const { open, onClose } = props;
    return (
        <StyledMuiModal open={open} onClose={onClose}>
            <UpdateScheduledTaskNotificationModalComponent {...props} />
        </StyledMuiModal>
    );
};

const UpdateScheduledTaskNotificationModalComponent: React.FC<
    UpdateScheduledTaskNotificationModalProps
> = props => {
    const { onClose, onError, onSuccess, scheduledTask, segments, locations, modules } = props;

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const onSubmit = React.useCallback(
        async (data: ICreateScheduledTaskCreatePushNotification) => {
            const result = await scheduledTasksApi.update(scheduledTask._id, {
                body: data
            });

            if (!result.ok) {
                onError(result.body.message);
            } else {
                onSuccess(result.body);
            }
        },
        [onError, onSuccess, scheduledTask._id]
    );
    const handleOnClose = React.useCallback(() => {
        setOpenConfirm(true);
    }, [setOpenConfirm]);
    const handleDialogOnCancel = React.useCallback(() => {
        setOpenConfirm(false);
    }, [setOpenConfirm]);

    return (
        <>
            <StyledMuiModal open={true} onClose={handleOnClose}>
                <ScheduledTaskNotificationStepper
                    initialData={scheduledTask}
                    locations={locations}
                    segments={segments}
                    modules={modules}
                    onClose={handleOnClose}
                    onSubmit={onSubmit}
                />
            </StyledMuiModal>
            <ConfirmDialog
                open={openConfirm}
                title="Hold On!"
                content="Are you sure you want to cancel this scheduled notification update process? All changes you made will be lost."
                onCancel={handleDialogOnCancel}
                onConfirm={onClose}
                cancelLabel={"No, don't cancel"}
                confirmLabel={'Yes, cancel'}
            />
        </>
    );
};
