import { Box, Button, Grid, IconButton, styled, Typography } from '@mui/material';
import { NutrientValue, NutritionInfo } from '@pepperhq/menu-sdk';
import { FieldArrayRenderProps, FormikProps } from 'formik';
import React from 'react';
import DragHandle from '@mui/icons-material/DragHandle';
import {
    SortableContainer as sortableContainer,
    SortableElement as sortableElement,
    SortableHandle as sortableHandle
} from 'react-sortable-hoc';
import Add from '@mui/icons-material/Add';
import { TextFormField } from 'lib/form/fields/TextFormField';
import DeleteIcon from '@mui/icons-material/Delete';

const SortableContainer = sortableContainer(({ children }: { children: React.ReactNodeArray }) => (
    <ul>{children}</ul>
));
const CustomDragHandle = sortableHandle(({ className }: { className: string }) => (
    <DragHandle className={className} />
));

interface NutritionValuesFormProps extends FieldArrayRenderProps {
    form: FormikProps<NutritionInfo>;
}

interface NutrientValueFormProps {
    remove: (index: number) => void;
    index: number;
    internalIndex: number;
}

const PREFIX = 'NutrientValueForm';

const classes = {
    iconContainer: `${PREFIX}-iconContainer`,
    dragIcon: `${PREFIX}-dragIcon`
};

const StyledBox = styled(Box)(({ theme }) => ({
    zIndex: 999999,
    backgroundColor: theme.palette.background.paper,
    [`& .${classes.dragIcon}`]: {
        cursor: 'pointer'
    },
    [`& .${classes.iconContainer}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

const NutrientValueForm: React.FC<NutrientValueFormProps> = ({ remove, internalIndex }) => {
    const removeItem = React.useCallback(() => {
        remove(internalIndex);
    }, [internalIndex, remove]);

    return (
        <StyledBox zIndex={999999}>
            <Grid container spacing={2}>
                <Grid item xs={1} className={classes.iconContainer}>
                    <CustomDragHandle className={classes.dragIcon} />
                </Grid>
                <Grid item xs={5}>
                    <TextFormField name={`values[${internalIndex}].nutrient`} />
                </Grid>
                <Grid item xs={5}>
                    <TextFormField name={`values[${internalIndex}].value`} />
                </Grid>
                <Grid item xs={1} className={classes.iconContainer}>
                    <IconButton onClick={removeItem} size="large">
                        <DeleteIcon color="error" />
                    </IconButton>
                </Grid>
            </Grid>
        </StyledBox>
    );
};

const SortableItem = sortableElement((props: NutrientValueFormProps) => <NutrientValueForm {...props} />);

export const NutritionValuesForm: React.FC<NutritionValuesFormProps> = ({ form, remove, move, push }) => {
    const mapFields = React.useCallback(
        (_: NutrientValue, index: number) => (
            <SortableItem key={`sortable-${index}`} index={index} internalIndex={index} remove={remove} />
        ),
        [remove]
    );
    const handleSortEnd = React.useCallback(
        ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
            move(oldIndex, newIndex);
        },
        [move]
    );
    const handleAddItem = React.useCallback(() => {
        push({ nutrient: '', value: '' });
    }, [push]);
    if (Array.isArray(form.values?.values)) {
        return (
            <SortableContainer useDragHandle lockAxis="y" onSortEnd={handleSortEnd}>
                <Grid container spacing={2}>
                    <Grid item xs={1}>
                        <div />
                    </Grid>
                    <Grid item xs={5}>
                        <Typography align="center">Nutrient</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography align="center">Value</Typography>
                    </Grid>
                </Grid>
                {form.values.values.map(mapFields)}
                <Button variant="outlined" onClick={handleAddItem}>
                    <Add />
                </Button>
            </SortableContainer>
        );
    }
};
