import React from 'react';
import { isValid } from 'date-fns';
import { TimePicker } from '@mui/x-date-pickers-pro';

interface IProps {
    onChange: (value?: string) => void;
    value?: string;
    error?: string;
}

export const AvailabilityTimeFilter: React.FC<IProps> = ({ value, error, onChange }) => {
    const handleDateChange = React.useCallback(
        (selectedDate: Date | null) => {
            if (selectedDate) {
                onChange(selectedDate.toString());
            } else {
                onChange(null);
            }
        },
        [onChange]
    );

    return (
        <TimePicker
            label="Select time"
            value={value && isValid(new Date(value)) ? new Date(value) : null}
            onChange={handleDateChange}
            slotProps={{ textField: { variant: 'outlined', helperText: error, error: !!error } }}
            views={['hours', 'minutes']}
        />
    );
};
