import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stepper, Step, StepButton, StepLabel, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Column, Row } from 'ui/Flex';
import { generateAwardRedemptionFormData } from 'components/perks/award-enrichments/utils';
import { AdjustmentSchemeGeneralForm, ConfigureSchemeGeneralFormData } from './AdjustmentSchemeGeneralForm';
import {
    AdjustmentSchemeRedemptionForm,
    AdjustmentSchemeRedemptionFormData
} from './redemption-form/AdjustmentSchemeRedemptionForm';
import { AdjustmentScheme } from '../adjustmentSchemeModel';
import { getAdjustmentSchemeMutationBody } from '../utils/adjustment-schemes-utils';
import { adjustmentSchemesApi } from '../adjustmentSchemesApi';
import { ApplicationState } from 'store/store';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_MARKETING_ADJUSTMENT_SCHEME_UPDATE_ERROR,
    MESSAGE_MARKETING_ADJUSTMENT_SCHEME_UPDATE_SUCCESS
} from 'config/messages';

const steps = ['Define', 'Redemption'];

export enum AdjustmentSchemeRedemptionFormStep {
    GENERAL = 'GENERAL',
    REDEMPTION = 'REDEMPTION'
}

type StepUpdateMetadata =
    | {
          type: AdjustmentSchemeRedemptionFormStep.GENERAL;
          values: ConfigureSchemeGeneralFormData;
      }
    | {
          type: AdjustmentSchemeRedemptionFormStep.REDEMPTION;
          values: AdjustmentSchemeRedemptionFormData;
      };

export interface AdjustmentSchemeEditStepperProps {
    scheme: AdjustmentScheme;
    currencySymbol: string;
    existingCodes: string[];
    onClose: () => void;
    onSuccess: (scheme: AdjustmentScheme) => void;
}

export const AdjustmentSchemeEditStepper: React.FC<AdjustmentSchemeEditStepperProps> = ({
    currencySymbol,
    scheme,
    existingCodes,
    onClose,
    onSuccess
}) => {
    const dispatch = useDispatch();
    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const [activeStep, setActiveStep] = React.useState(0);
    const [isValid, setIsValid] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);

    const [generalData, setGeneralData] = React.useState<ConfigureSchemeGeneralFormData>({
        title: scheme.title,
        schemeId: scheme.schemeId,
        externalPosId: scheme.externalPosId,
        enabled: scheme.enabled,
        locationIds: scheme.locationIds || [],
        internalTitle: scheme.internalTitle
    });

    const [redemptionData, setRedemptionData] = React.useState<AdjustmentSchemeRedemptionFormData>(
        generateAwardRedemptionFormData(scheme)
    );

    const filteredExistingCodes = React.useMemo(
        () => existingCodes.filter(code => code !== scheme.schemeId),
        [existingCodes, scheme]
    );

    const handleGeneralSubmit = React.useCallback((data: ConfigureSchemeGeneralFormData) => {
        setGeneralData(data);
        setActiveStep(1);
    }, []);

    const handleRedemptionPrevious = React.useCallback(
        (isFormValid: boolean, values: AdjustmentSchemeRedemptionFormData) => {
            setIsValid(isFormValid);
            setRedemptionData(values);
            setActiveStep(0);
        },
        []
    );

    const handlePrevious = React.useCallback(() => {
        setActiveStep(0);
    }, []);

    const handleRedemptionSubmit = React.useCallback((data: AdjustmentSchemeRedemptionFormData) => {
        setRedemptionData(data);
        setActiveStep(1);
    }, []);

    const updateScheme = React.useCallback(
        async (data: {
            general: ConfigureSchemeGeneralFormData;
            redemption: AdjustmentSchemeRedemptionFormData;
        }) => {
            setIsLoading(true);
            const body = getAdjustmentSchemeMutationBody(data, settings?.region?.timezone);
            try {
                const result = await adjustmentSchemesApi.update(scheme.id, { body });
                if (!result.ok) {
                    throw new Error(result.body.message);
                }
                setIsLoading(false);
                dispatch(
                    enqueueSnackbar(MESSAGE_MARKETING_ADJUSTMENT_SCHEME_UPDATE_SUCCESS, {
                        variant: 'success'
                    })
                );
                return onSuccess(result.body);
            } catch (error) {
                setIsLoading(false);
                dispatch(
                    enqueueSnackbar(MESSAGE_MARKETING_ADJUSTMENT_SCHEME_UPDATE_ERROR, { variant: 'error' })
                );
            }
        },
        [dispatch, onSuccess, scheme.id, settings?.region?.timezone]
    );

    const handleStepUpdate = React.useCallback(
        (metadata: StepUpdateMetadata) => {
            switch (metadata.type) {
                case AdjustmentSchemeRedemptionFormStep.GENERAL:
                    setGeneralData(metadata.values);
                    break;
                case AdjustmentSchemeRedemptionFormStep.REDEMPTION:
                    setRedemptionData(metadata.values);
                    break;
                default:
                    break;
            }

            updateScheme({
                general: {
                    ...generalData,
                    ...metadata.values
                },
                redemption: {
                    ...redemptionData,
                    ...metadata.values
                }
            });
        },
        [updateScheme, generalData, redemptionData]
    );

    return (
        <Box width="100%" maxWidth={1600}>
            <Column>
                <Row valign="flex-start" align="center">
                    <Box flex={1} maxWidth={500}>
                        <Stepper nonLinear alternativeLabel activeStep={activeStep}>
                            {steps.map((item, index) => (
                                <Step key={`step-button-${item}`} completed={activeStep > index}>
                                    <StepButton disableRipple disabled={activeStep < index} color="inherit">
                                        <StepLabel>{item}</StepLabel>
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <Box position="absolute" top={0} right={0}>
                        <IconButton size="large" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Row>
            </Column>
            <AdjustmentSchemeGeneralForm
                open={activeStep === 0}
                onSubmit={handleGeneralSubmit}
                initialValues={generalData}
                isLoading={isLoading}
                isFormValid={isValid}
                onUpdate={handleStepUpdate}
                isEdit={true}
                existingCodes={filteredExistingCodes}
            />
            <AdjustmentSchemeRedemptionForm
                open={activeStep === 1}
                isEdit={true}
                currencySymbol={currencySymbol}
                initialData={redemptionData}
                isLoading={isLoading}
                isFormValid={isValid}
                clickPrevious={handlePrevious}
                onPrevious={handleRedemptionPrevious}
                onSubmit={handleRedemptionSubmit}
                onUpdate={handleStepUpdate}
            />
        </Box>
    );
};
