import React from 'react';
import { getCountries, isValidPhoneNumber } from 'libphonenumber-js';
import { KeyValuePair, toKeyValuePairs } from './useRecordSetting';

const validCountryCodes: Set<string> = new Set(getCountries());

export const useTwilioNumbers = (initialValues: Record<string, string[]>) => {
    const [values, setValues] = React.useState<KeyValuePair[]>([]);
    const [hasInitialized, setHasInitialized] = React.useState(false);

    React.useEffect(() => {
        if (initialValues && !hasInitialized) {
            setValues(toKeyValuePairs(initialValues));
            setHasInitialized(true);
        }
    }, [hasInitialized, initialValues]);

    const onChange = React.useCallback(
        (updatedValues: KeyValuePair[]) => {
            setValues(updatedValues);
        },
        [setValues]
    );

    const validate = React.useCallback(
        () =>
            values.reduce<Record<string, string[]>>((acc, { key, value }) => {
                if (!key || !value) {
                    return acc;
                }

                const countryCodes = value.split(',').map(code => code.trim().toUpperCase());
                for (const code of countryCodes) {
                    if (!validCountryCodes.has(code)) {
                        throw new Error(`${code} is not a valid country code`);
                    }
                }

                const isValidNumber = isValidPhoneNumber(key);
                if (!isValidNumber) {
                    throw new Error(`${key} is not a valid phone number`);
                }

                acc[key] = countryCodes;

                return acc;
            }, {}),
        [values]
    );

    return {
        values,
        onChange,
        validate
    };
};
