import React from 'react';
import { defaults } from 'chart.js';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { Card, Chip, Grid, styled, Typography } from '@mui/material';
import NotInterested from '@mui/icons-material/NotInterested';
import clsx from 'clsx';
import { isNumber } from 'lib/typeguards';
import { Column, Row } from 'ui/Flex';
import { Panel } from 'ui/Panel';
import { TableSkeletonComponent } from 'ui/skeleton/TableSkeleton';
import { green, orange, red } from '@mui/material/colors';

interface NotificationSuccessRateProps {
    isLoading?: boolean;
    isOngoing?: boolean;
    metrics?: { sent: number; skipped: number; failures: number };
}

defaults.font.family = 'Ubuntu, Railway, Helvetica Neue';

const colors = {
    sent: green[500],
    skipped: orange[500],
    failures: red[400]
};

const PREFIX = 'NotificationSuccessRateCard';

const classes = {
    statsBottomWrapper: `${PREFIX}-statsBottomWrapper`,
    failedChip: `${PREFIX}-failedChip`,
    skippedChip: `${PREFIX}-skippedChip`,
    sentChip: `${PREFIX}-sentChip`,
    chip: `${PREFIX}-chip`,
    statsMargin: `${PREFIX}-statsMargin`,
    noDataText: `${PREFIX}-noDataText`,
    noDataIcon: `${PREFIX}-noDataIcon`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.statsBottomWrapper}`]: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5)
        }
    },
    [`& .${classes.failedChip}`]: {
        backgroundColor: colors.failures
    },
    [`& .${classes.skippedChip}`]: {
        backgroundColor: colors.skipped
    },
    [`& .${classes.sentChip}`]: {
        backgroundColor: colors.sent
    },
    [`& .${classes.chip}`]: {
        width: theme.spacing(10),
        height: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        cursor: 'pointer'
    },
    [`& .${classes.statsMargin}`]: {
        marginLeft: theme.spacing(4)
    },
    [`& .${classes.noDataText}`]: {
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.text.disabled,
        padding: theme.spacing(3),
        paddingTop: 0
    },
    [`& .${classes.noDataIcon}`]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        color: theme.palette.text.disabled,
        padding: theme.spacing(3),
        paddingBottom: 0,
        fontSize: theme.spacing(10)
    }
}));

const buildPieData = (sent: number, skipped: number, failures: number) => {
    const data = {
        labels: ['Sent', 'Skipped', 'Failures'],
        datasets: [
            {
                data: [sent, skipped, failures],
                backgroundColor: [colors.sent, colors.skipped, colors.failures],
                hoverBackgroundColor: [colors.sent, colors.skipped, colors.failures]
            }
        ]
    };

    return data;
};

const percentage = (partialValue: number, totalValue: number): string => {
    if (!isNumber(partialValue) || !isNumber(totalValue) || totalValue === 0) {
        return '';
    }
    const percet = ((100 * partialValue) / totalValue).toFixed(1);
    const result = `(${percet}%)`;
    return result;
};

export const NotificationResultsCard: React.FC<NotificationSuccessRateProps> = ({
    isLoading,
    metrics,
    isOngoing
}) => {
    const { sent, skipped, failures } = metrics || { sent: 0, skipped: 0, failures: 0 };
    const totalValue = sent + skipped + failures;
    const data = buildPieData(sent, skipped, failures);
    const rateValues = [sent, skipped, failures];

    const renderNoData = () => (
        <div>
            <NotInterested className={classes.noDataIcon} strokeWidth="1.5" />
            <Typography variant="h6" className={classes.noDataText} color="inherit">
                No data
            </Typography>
        </div>
    );

    const renderSkeleton = () => (
        <Card>
            <Grid container spacing={2}>
                <Grid item xl={12} xs={12}>
                    <TableSkeletonComponent tableBodyRows={2} tableHeadColumns={1} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xl={4} xs={4}>
                    <TableSkeletonComponent tableBodyRows={1} tableHeadColumns={1} />
                </Grid>
                <Grid item xl={4} xs={4}>
                    <TableSkeletonComponent tableBodyRows={1} tableHeadColumns={1} />
                </Grid>
                <Grid item xl={4} xs={4}>
                    <TableSkeletonComponent tableBodyRows={1} tableHeadColumns={1} />
                </Grid>
            </Grid>
        </Card>
    );

    return isLoading ? (
        renderSkeleton()
    ) : (
        <Panel title={isOngoing ? 'Ongoing Results' : 'Results'} divider>
            <StyledGrid container spacing={2}>
                {isNumber(totalValue) && totalValue > 0 ? (
                    <React.Fragment>
                        <Grid item xl={12} xs={12}>
                            <Doughnut
                                data={data}
                                options={{
                                    plugins: {
                                        legend: {
                                            display: false
                                        }
                                    },
                                    responsive: true,
                                    maintainAspectRatio: false
                                }}
                            />
                        </Grid>
                        <Grid item xl={12} xs={12}>
                            <Row flex={1} align="center" className={classes.statsBottomWrapper}>
                                <Column className={classes.statsMargin}>
                                    <Chip className={clsx(classes.failedChip, classes.chip)} size="small" />
                                    <Typography align="center">Failed</Typography>
                                    <Typography align="center">{`${failures} ${percentage(
                                        rateValues[2],
                                        totalValue
                                    )}`}</Typography>
                                </Column>
                                <Column className={classes.statsMargin}>
                                    <Chip className={clsx(classes.skippedChip, classes.chip)} size="small" />
                                    <Typography align="center">Skipped</Typography>
                                    <Typography align="center">{`${skipped} ${percentage(
                                        rateValues[1],
                                        totalValue
                                    )}`}</Typography>
                                </Column>
                                <Column className={classes.statsMargin}>
                                    <Chip className={clsx(classes.sentChip, classes.chip)} size="small" />
                                    <Typography align="center">Sent</Typography>
                                    <Typography align="center">{`${sent} ${percentage(
                                        rateValues[0],
                                        totalValue
                                    )}`}</Typography>
                                </Column>
                            </Row>
                        </Grid>
                    </React.Fragment>
                ) : (
                    <Grid item xl={12} xs={12}>
                        {renderNoData()}
                    </Grid>
                )}
            </StyledGrid>
        </Panel>
    );
};
