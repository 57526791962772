import { InputAdornment, TextField } from '@mui/material';
import { GridFilterItem, GridFilterModel, GridFilterOperator, GridLogicOperator } from '@mui/x-data-grid-pro';
import React from 'react';
import Search from '@mui/icons-material/Search';
import { debounce } from 'debounce';

export type GridSearchItem = {
    column: string;
    labels?: Record<string, string> | Map<string, string>;
};

export type GridSearchItems = GridSearchItem[];

export function useOverridesGridSearch(items: GridSearchItems, placeholder?: string) {
    const [searchValue, setSearchValue] = React.useState('');
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
        logicOperator: GridLogicOperator.And
    });
    const filterOperators = React.useMemo(() => {
        const operators: Record<string, GridFilterOperator | undefined> = {};
        if (Array.isArray(items)) {
            items.forEach(item => {
                operators[item.column] = {
                    label: 'Deep Search',
                    value: 'deep-search',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                            return null;
                        }
                        return (_, row) =>
                            row.searchKeys.some((key: string) =>
                                key.toLowerCase().includes(filterItem.value.toLowerCase())
                            ) || row._id.includes(filterItem.value.toLowerCase());
                    }
                };
            });
        }
        return operators;
    }, [items]);
    React.useEffect(() => {
        if (!searchValue || !searchValue.length) {
            setFilterModel({ items: [], logicOperator: GridLogicOperator.Or });
        } else {
            const newItems: GridFilterItem[] = [];
            items.forEach(item => {
                newItems.push({
                    id: item.column,
                    field: item.column,
                    operator: 'deep-search',
                    value: searchValue
                });
            });
            setFilterModel({ items: newItems, logicOperator: GridLogicOperator.Or });
        }
    }, [searchValue, items]);

    const handleSearchChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    }, []);
    const handleSearchChangeDebounced = debounce(handleSearchChange, 250);

    const renderInput = React.useCallback(
        () => (
            <TextField
                sx={{ minWidth: 280 }}
                variant="outlined"
                onChange={handleSearchChangeDebounced}
                placeholder={placeholder ?? 'Search'}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    )
                }}
            />
        ),
        [handleSearchChangeDebounced, placeholder]
    );

    return { filterModel, filterOperators, searchValue, renderInput };
}
