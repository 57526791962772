import React from 'react';

export function useSimpleModalState(): [boolean, () => void, () => void] {
    const [open, setOpen] = React.useState(false);
    const handleOpen = React.useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    return [open, handleOpen, handleClose];
}
