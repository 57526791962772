import React from 'react';
import { styled } from '@mui/material';
import { MuiModal } from 'ui/MuiModal';
import { PerksEditStepper, PerksEditStepperProps } from '../PerksEditStepper';

interface PerksEditModalProps extends PerksEditStepperProps {
    open: boolean;
    currencySymbol: string;
    primaryActionBackgroundColour: string;
}

const StyledMuiModal = styled(MuiModal)(({ theme }) => ({
    width: `calc(100% - ${theme.spacing(4)})`,
    maxWidth: theme.spacing(160)
}));

export const PerksEditModal: React.FC<PerksEditModalProps> = props => {
    const { open, onClose, ...rest } = props;
    return (
        <StyledMuiModal open={open} onClose={onClose}>
            <PerksEditStepper onClose={onClose} {...rest} />
        </StyledMuiModal>
    );
};
