import { Box, Button, Paper, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React from 'react';
import { filterKeyToFilterChecker, MenuSummaryFilter } from './MenuManagerModel';
import { MenuManagerTableFilter } from './filter/MenuManagerTableFilter';
import { MENU_MANAGER_ENRICHMENTS_EMPTY } from 'config/routes';
import { useHistory } from 'react-router-dom';
import { MenuLocationsTooltip } from './MenuLocationsTooltip';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { isDefined } from 'lib/typeguards';
import { MenuSummary } from '@pepperhq/menu-sdk';
import { useMenuManagerFilter } from './filter/useMenuManagerFilter';
import { GridStorageName } from 'lib/MuiGrid/StateController';
interface IProps {
    isLoading: boolean;
    menuSummaryList: MenuSummary[];
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`.${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.paper,
        padding: 0
    }
}));

const initialState = {
    columns: {
        columnVisibilityModel: { menuId: false, menuChangeId: false }
    }
};

export const MenuManagerTable: React.FC<IProps> = ({ menuSummaryList, isLoading }) => {
    const { locationIdToLocationName } = useLocationHelpers();
    const history = useHistory();
    const [activeLocationPicker, setActiveLocationPicker] = React.useState<string>(null);

    const [filter, handleFilterChange] = useMenuManagerFilter();

    const handleClickLocationsCell = React.useCallback(
        (id?: string) => () => setActiveLocationPicker(id),
        []
    );

    const handleStopPropagation = React.useCallback(e => {
        e.stopPropagation();
    }, []);

    const renderLocationsCell = React.useCallback(
        (params: GridRenderCellParams) => {
            const [firstLocationId] = params.value as MenuSummary['locationIds'];
            const locationCount = (params.value as MenuSummary['locationIds']).length;

            return (
                <Box height="100%" onClick={handleStopPropagation}>
                    <StyledTooltip
                        disableFocusListener
                        disableHoverListener
                        open={activeLocationPicker === `${params.row.menuId}-${params.row.menuChangeId}`}
                        arrow
                        title={
                            <MenuLocationsTooltip
                                onClose={handleClickLocationsCell()}
                                locationIds={params.value as MenuSummary['locationIds']}
                            />
                        }
                    >
                        <Button
                            sx={{ textTransform: 'initial', justifyContent: 'flex-start', height: '100%' }}
                            variant="text"
                            onClick={handleClickLocationsCell(
                                `${params.row.menuId}-${params.row.menuChangeId}`
                            )}
                        >
                            {locationCount > 1
                                ? `${locationCount} Locations`
                                : locationIdToLocationName.get(firstLocationId)}
                        </Button>
                    </StyledTooltip>
                </Box>
            );
        },
        [activeLocationPicker, handleClickLocationsCell, handleStopPropagation, locationIdToLocationName]
    );

    const locationSortComparator = React.useCallback(
        (location1: string[], location2: string[]) => {
            const l1 =
                location1.length > 1
                    ? `${location1.length} Locations`
                    : locationIdToLocationName.get(location1[0]);
            const l2 =
                location2.length > 1
                    ? `${location2.length} Locations`
                    : locationIdToLocationName.get(location2[0]);

            if (!l1) {
                return 1;
            }

            if (!l2) {
                return -1;
            }

            return l1.localeCompare(l2);
        },
        [locationIdToLocationName]
    );

    const getUniqueKey = React.useCallback(
        (row: GridValidRowModel) => `${row.menuId}-${row.menuChangeId}`,
        []
    );
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'Menu ID',
                field: 'menuId',
                disableReorder: true,
                disableExport: true,
                sortable: true,
                width: 300
            },
            {
                headerName: 'Menu Name',
                field: 'menuDescription',
                disableReorder: true,
                disableExport: true,
                width: 400,
                sortable: true
            },
            {
                headerName: 'Locations',
                field: 'locationIds',
                disableReorder: true,
                disableExport: true,
                width: 240,
                filterable: false,
                sortable: true,
                sortComparator: locationSortComparator,
                renderCell: renderLocationsCell
            },
            {
                headerName: 'Menu Change ID',
                field: 'menuChangeId',
                disableReorder: true,
                disableExport: true,
                sortable: true,
                width: 240
            }
        ],
        [renderLocationsCell, locationSortComparator]
    );
    const filteredMenuSummaries = React.useMemo(
        () =>
            menuSummaryList.filter(summary =>
                Object.entries(filter)
                    .filter(([, value]) => isDefined(value))
                    .every(([key, value]: [keyof MenuSummaryFilter, string]) => {
                        let meta = {};

                        if (key === 'search') {
                            meta = { locationIdToLocationName };
                        }
                        return filterKeyToFilterChecker[key](summary, value, meta);
                    })
            ),
        [filter, locationIdToLocationName, menuSummaryList]
    );

    const handleRowClick = React.useCallback(
        (params: GridRowParams) => {
            const [locationId] = params.row.locationIds;
            const pathname = MENU_MANAGER_ENRICHMENTS_EMPTY.replace(':menuId', params.row.menuId)
                .replace(':locationId', locationId)
                .replace(':menuChangeId', params.row.menuChangeId);
            history.push(pathname);
        },
        [history]
    );

    return (
        <React.Fragment>
            <Box paddingBottom={1}>
                <MenuManagerTableFilter onFilterChange={handleFilterChange} filter={filter} />
            </Box>
            <Paper sx={{ flex: 1, display: 'flex', minHeight: 0 }}>
                <MuiGrid
                    initialState={initialState}
                    rows={filteredMenuSummaries}
                    columns={columns}
                    hideFooter={false}
                    hideFooterRowCount
                    loading={isLoading}
                    getRowId={getUniqueKey}
                    enableRowClick
                    onRowClick={handleRowClick}
                    storageName={GridStorageName.Menus}
                />
            </Paper>
        </React.Fragment>
    );
};
