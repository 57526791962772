import React from 'react';
import { Row } from 'ui/Flex';
import { Box, InputAdornment } from '@mui/material';
import { RuleActiveFilter } from 'components/rules/filters/RuleActiveFilter';
import { DebouncedGridFilterInput } from 'lib/grid/DebouncedGridFilterInput';
import Search from '@mui/icons-material/Search';

export interface RulesFilter {
    method?: string;
    controller?: string;
    parameterData?: any;
    active?: 'true' | 'false';
    search?: string;
}
export type RulesFilterChange = (field: keyof RulesFilter, value: string) => void;

interface RulesProps {
    filter: RulesFilter;
    onChange: RulesFilterChange;
}

export const RuleFilters: React.FC<RulesProps> = ({ filter, onChange }) => {
    const handleActiveChange = React.useCallback(
        (value?: string) => {
            onChange('active', value);
        },
        [onChange]
    );
    const handleSearchChange = React.useCallback(
        (value?: string) => {
            onChange('search', value);
        },
        [onChange]
    );

    // Only scheduled tasks from impact are supported for now on the frontend
    return (
        <Row flex={1} wrap="wrap" valign="center">
            <Box marginRight={1}>
                <DebouncedGridFilterInput
                    value={filter.search}
                    onChange={handleSearchChange}
                    name="search"
                    placeholder="Search by title, message or tag"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        )
                    }}
                />
            </Box>
            <Box marginRight={1}>
                <RuleActiveFilter value={filter.active} onChange={handleActiveChange} />
            </Box>
        </Row>
    );
};
