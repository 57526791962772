/* eslint-disable react/jsx-no-bind */
import { Box, Button, Grid, Typography } from '@mui/material';
import { isValid } from 'date-fns';
import { Form, FormikBag } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { LoadingButton } from 'ui/buttons/LoadingButton';
import { LoyaltyScope } from 'components/loyalty/enums';
import { IAwardEnrichmentDateTimeRestriction } from 'components/loyalty/models/AwardEnrichment';
import { IPerkBasketQualification, PerkDiscount } from 'components/loyalty/models/PerkModel';
import {
    getChildItemRuleOptions,
    getPerkDiscountScopeOptions,
    showChildItemRulePerkForm
} from 'components/perks/helpers';
import { MuiForm } from 'lib/Form';
import { formatDecimalToPercentage, roundToDecimal } from 'lib/helpers';
import { isDefined, isUndefined } from 'lib/typeguards';
import { Option } from 'lib/types';
import { ApplicationState } from 'store/store';
import { Row } from 'ui/Flex';
import { AdjustmentSchemeRedemptionFormStep } from '../AdjustmentSchemeEditStepper';
import { AdjustmentSchemeBasketForm } from './AdjustmentSchemeBasketForm';
import { AdjustmentSchemeDateTimeForm } from './AdjustmentSchemeDateTimeForm';
import { AdjustmentSchemeDiscountForm } from './AdjustmentSchemeDiscountForm';

export interface AdjustmentSchemeRedemptionFormData {
    token?: string;
    discount?: Partial<Omit<PerkDiscount, 'maxValue'>>;
    basketQualification?: Omit<IPerkBasketQualification, 'scope'> & {
        scope?: LoyaltyScope;
    };
    dateTimeRestriction: Omit<IAwardEnrichmentDateTimeRestriction, 'startTime' | 'daysOfWeek' | 'endTime'> & {
        startTime: string | undefined;
        endTime: string | undefined;
        daysOfWeek: string[];
    };
}

// these are UI only fields, do not forget to exclude them from the request
export interface AdjustmentSchemeRedemptionFullFormData extends AdjustmentSchemeRedemptionFormData {
    dateTimeRestriction: AdjustmentSchemeRedemptionFormData['dateTimeRestriction'] & {
        dateTimeRestrictionOption: DateTimeOptionValue[];
    };
    basketQualification: AdjustmentSchemeRedemptionFormData['basketQualification'] & {
        disableBasketQualification: boolean;
    };
}

const dateAndTimeRestrictionOptions: Option[] = [
    {
        value: 'date',
        label: 'specific start and end date'
    },
    {
        value: 'time',
        label: 'specific start and end time'
    },
    {
        value: 'day',
        label: 'particular weekday'
    }
];

type DateTimeOptionValue = (typeof dateAndTimeRestrictionOptions)[number]['value'];

interface ConfigureAdjustmentSchemeRedemptionFormProps {
    initialData?: AdjustmentSchemeRedemptionFormData;
    open: boolean;
    isEdit?: boolean;
    isFormValid?: boolean;
    isLoading?: boolean;
    currencySymbol?: string;
    onSubmit: (data: AdjustmentSchemeRedemptionFormData) => void;
    onUpdate?: (values: {
        type: AdjustmentSchemeRedemptionFormStep.REDEMPTION;
        values: AdjustmentSchemeRedemptionFormData;
    }) => void;
    onPrevious?: (isValid?: boolean, values?: AdjustmentSchemeRedemptionFormData) => void;
    clickPrevious: () => void;
}

export const adjustmentSchemeRedemptionDefaultFormValues: AdjustmentSchemeRedemptionFullFormData = {
    token: '',
    discount: {
        scope: undefined,
        scopeMatchCode: undefined,
        value: undefined,
        rule: undefined,
        childItemRule: undefined,
        type: undefined
    },
    basketQualification: {
        scope: undefined,
        matchCode: undefined,
        minimumItems: undefined,
        minimumValue: undefined,
        disableBasketQualification: true
    },
    dateTimeRestriction: {
        startDate: undefined,
        startTime: undefined,
        endDate: undefined,
        endTime: undefined,
        daysOfWeek: [],
        dateTimeRestrictionOption: []
    }
};

const generateDateOptions = (initialData?: AdjustmentSchemeRedemptionFormData) => {
    if (!initialData?.dateTimeRestriction) {
        return [];
    }
    const dateFields = [];
    if (initialData?.dateTimeRestriction?.startDate) {
        dateFields.push('date');
    }
    if (initialData?.dateTimeRestriction?.startTime) {
        dateFields.push('time');
    }
    if (
        initialData?.dateTimeRestriction?.daysOfWeek &&
        initialData?.dateTimeRestriction?.daysOfWeek?.length
    ) {
        dateFields.push('day');
    }
    return dateFields;
};

const generateToggleOptions = (initialData?: AdjustmentSchemeRedemptionFormData) => {
    if (!initialData?.basketQualification) {
        return [];
    }
    const basketQualificationFields = [];
    if (isDefined(initialData.basketQualification.minimumValue)) {
        basketQualificationFields.push('basketQualification.minimumValue');
    }

    if (isDefined(initialData.basketQualification.minimumItems)) {
        basketQualificationFields.push('basketQualification.minimumItems');
    }
    return basketQualificationFields;
};

const generateFormData = (
    data: AdjustmentSchemeRedemptionFullFormData
): AdjustmentSchemeRedemptionFormData => {
    const { dateTimeRestrictionOption: _, ...dateTime } = data.dateTimeRestriction;
    const scopeMatchCode = data.discount.scope === 'BASKET' ? undefined : data.discount.scopeMatchCode;
    const matchCode = data.basketQualification?.matchCode;

    return {
        ...data,
        basketQualification: !data.basketQualification?.disableBasketQualification
            ? {
                  ...(data.basketQualification ?? {}),
                  matchCode
              }
            : undefined,
        discount: {
            ...data.discount,
            scopeMatchCode,
            value:
                data.discount.type === 'PERCENTAGE'
                    ? roundToDecimal(data.discount.value / 100, 4)
                    : data.discount.value,
            rule: data.discount.scope === 'BASKET' ? 'ALL_ALLOCATE' : data.discount.rule
        },
        dateTimeRestriction: {
            ...dateTime,
            daysOfWeek: dateTime.daysOfWeek ? dateTime.daysOfWeek : []
        }
    };
};

const discountValidationSchema = (posProvider: string) =>
    Yup.object().shape({
        value: Yup.string().required('This field is required.'),
        type: Yup.mixed().oneOf(['VALUE', 'PERCENTAGE', 'OVERRIDE']).required('This field is required.'),
        scope: Yup.mixed()
            .oneOf(getPerkDiscountScopeOptions(posProvider).map(option => option.value))
            .required('This field is required.'),
        scopeMatchCode: Yup.string().when('scope', {
            is: (value: string) => value === 'BASKET',
            then: Yup.string().notRequired(),
            otherwise: Yup.string().required('This field is required.')
        }),
        rule: Yup.mixed().when('scope', (value, schema) =>
            value !== 'BASKET'
                ? schema
                      .oneOf([
                          'CHEAPEST',
                          'COSTLIEST',
                          'ALL_ALLOCATE',
                          'ALL',
                          'CHEAPEST_FIRST',
                          'COSTLIEST_FIRST'
                      ])
                      .required('This field is required.')
                : schema.notRequired()
        ),
        childItemRule:
            showChildItemRulePerkForm(posProvider) &&
            Yup.mixed().when('scope', {
                is: (value: string) => value === 'ITEM' || value === 'ITEM_OR_CATEGORY',
                then: Yup.mixed().oneOf(['INCLUDE', 'EXCLUDE', 'INCLUDE_MATCHED']),
                otherwise: Yup.mixed().oneOf(['INCLUDE', 'EXCLUDE'])
            })
    });

const basketQualificationValidationSchema = (isEdit: boolean) =>
    Yup.object().shape({
        // when disableBasketQualification is true, basketQualification is not required
        scope: Yup.mixed().when('disableBasketQualification', {
            is: (value: boolean) => value,
            then: schema => schema.notRequired(),
            otherwise: schema => schema.oneOf(Object.values(LoyaltyScope)).required('This field is required.')
        }),
        matchCode: Yup.string().when(['scope', 'disableBasketQualification'], {
            is: (scope: LoyaltyScope, disableBasketQualification: boolean) =>
                !isEdit && scope !== LoyaltyScope.BASKET && !disableBasketQualification,
            then: schema => schema.required('This field is required'),
            otherwise: schema => schema.notRequired()
        }),
        minimumItems: Yup.number().when('disableBasketQualification', {
            is: (value: boolean) => value,
            then: schema => schema.notRequired(),
            otherwise: schema => schema.min(0, 'Please enter the value more than or equal to 0.')
        }),
        minimumValue: Yup.number().when('disableBasketQualification', {
            is: (value: boolean) => value,
            then: schema => schema.notRequired(),
            otherwise: schema => schema.min(0, 'Please enter the value more than or equal to 0.')
        })
    });

const dateTimeRestrictionValidationSchema = () =>
    Yup.object().shape({
        startTime: Yup.string().when('dateTimeRestrictionOption', {
            is: (value: string[]) => value.includes('time'),
            then: Yup.string().required('This field is required.'),
            otherwise: Yup.string().notRequired()
        }),
        endTime: Yup.string().when('dateTimeRestrictionOption', {
            is: (value: string[]) => value.includes('time'),
            then: Yup.string().required('This field is required.'),
            otherwise: Yup.string().notRequired()
        }),
        startDate: Yup.string().when('dateTimeRestrictionOption', {
            is: (value: string[]) => value.includes('date'),
            then: Yup.string()
                .required('This field is required.')
                .test('valid-date', 'Please enter valid date.', value => {
                    if (value && !isValid(new Date(value))) {
                        return false;
                    }

                    return true;
                }),
            otherwise: Yup.string().notRequired()
        }),
        endDate: Yup.string().when('dateTimeRestrictionOption', {
            is: (value: string[]) => value.includes('date'),
            then: Yup.string()
                .required('This field is required.')
                .test('valid-date', 'Please enter valid date.', value => {
                    if (value && !isValid(new Date(value))) {
                        return false;
                    }

                    return true;
                }),
            otherwise: Yup.string().notRequired()
        }),
        daysOfWeek: Yup.array().notRequired()
    });

export const AdjustmentSchemeRedemptionForm: React.FC<ConfigureAdjustmentSchemeRedemptionFormProps> = ({
    open,
    isEdit,
    initialData,
    clickPrevious,
    onPrevious,
    isLoading,
    currencySymbol,
    onSubmit,
    onUpdate
}) => {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const posProvider = React.useMemo(() => settings?.posProvider, [settings]);

    const [toggledFields, setToggledFields] = React.useState<string[]>(generateToggleOptions(initialData));

    const validationSchema = React.useMemo(
        () =>
            Yup.object().shape({
                discount: discountValidationSchema(posProvider),
                basketQualification: basketQualificationValidationSchema(isEdit),
                dateTimeRestriction: dateTimeRestrictionValidationSchema()
            }),
        [isEdit, posProvider]
    );

    const initialValues = React.useMemo(() => {
        if (initialData) {
            const dateTime = {
                ...(initialData.dateTimeRestriction ?? {}),
                dateTimeRestrictionOption: generateDateOptions(initialData)
            };
            const scopeMatchCode = Array.isArray(initialData.discount?.scopeMatchCode)
                ? initialData.discount.scopeMatchCode.join(',')
                : initialData.discount?.scopeMatchCode;
            const matchCode = Array.isArray(initialData.basketQualification?.matchCode)
                ? initialData.basketQualification.matchCode.join(',')
                : initialData.basketQualification?.matchCode;
            const disableBasketQualification = isDefined(initialData.basketQualification)
                ? Object.values(initialData.basketQualification).every(isUndefined)
                : true;
            if (initialData.discount && initialData.discount.type === 'PERCENTAGE') {
                return {
                    ...initialData,
                    basketQualification: {
                        ...(initialData.basketQualification ?? {}),
                        matchCode,
                        disableBasketQualification
                    },
                    discount: {
                        ...initialData.discount,
                        value: formatDecimalToPercentage(initialData.discount.value),
                        scopeMatchCode
                    },
                    dateTimeRestriction: dateTime
                } as AdjustmentSchemeRedemptionFullFormData;
            }
            return {
                ...initialData,
                basketQualification: {
                    ...(initialData.basketQualification ?? {}),
                    matchCode,
                    disableBasketQualification
                },
                discount: { ...initialData.discount, scopeMatchCode },
                dateTimeRestriction: dateTime
            } as AdjustmentSchemeRedemptionFullFormData;
        }
        return adjustmentSchemeRedemptionDefaultFormValues;
    }, [initialData]);

    const showChildItemRule = React.useMemo(() => showChildItemRulePerkForm(posProvider), [posProvider]);

    const discountScopeOptions = React.useMemo(() => getPerkDiscountScopeOptions(posProvider), [posProvider]);

    const handleSubmit = React.useCallback(
        (
            data: AdjustmentSchemeRedemptionFullFormData,
            formikBag: FormikBag<any, AdjustmentSchemeRedemptionFullFormData>
        ) => {
            const formData = generateFormData(data);

            onSubmit(formData);

            formikBag.setSubmitting(false);
        },
        [onSubmit]
    );

    const handleUpdate = React.useCallback(
        (data: AdjustmentSchemeRedemptionFullFormData) => {
            const formData = generateFormData(data);
            onUpdate({
                type: AdjustmentSchemeRedemptionFormStep.REDEMPTION,
                values: formData
            });
        },
        [onUpdate]
    );

    React.useEffect(() => {
        if (initialData?.basketQualification) {
            setToggledFields(generateToggleOptions(initialData));
        }
    }, [initialData]);

    if (!open) {
        return null;
    }

    return (
        <MuiForm<AdjustmentSchemeRedemptionFullFormData>
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            initialValues={initialValues || adjustmentSchemeRedemptionDefaultFormValues}
        >
            {({ submitForm, setFieldValue, setFieldTouched, values, isValid }) => {
                const discountValueString = () => {
                    if (values.discount?.type === 'OVERRIDE') {
                        return `${currencySymbol}${values.discount?.value} override`;
                    }
                    if (values.discount?.type === 'PERCENTAGE') {
                        return `${values.discount?.value}% off`;
                    }
                    return `${currencySymbol}${values.discount?.value} off`;
                };

                const handleSubmit = () => {
                    // For some reason it doesn't become touched by itself when submitting the form
                    setFieldTouched('discount.scopeMatchCode', true, true);
                    setTimeout(() => {
                        submitForm();
                    }, 0);
                };

                const isBasketQualificationVisible = () => {
                    if (values.discount?.scope === 'BASKET') {
                        return true;
                    }
                    return [
                        values.discount?.scope,
                        values.discount?.rule,
                        values.discount?.scopeMatchCode,
                        values.discount?.value,
                        values.discount?.type
                    ].every(isDefined);
                };

                const isDateTimeRestrictionVisible = () => {
                    const isBasketQualificationValid = (
                        values.basketQualification?.scope === LoyaltyScope.BASKET
                            ? [values.basketQualification.scope]
                            : [values.basketQualification?.scope, values.basketQualification?.matchCode]
                    ).every(isDefined);
                    // Skip basket validation if it is disabled
                    if (values.basketQualification?.disableBasketQualification) {
                        return isBasketQualificationVisible();
                    }
                    return isBasketQualificationValid;
                };

                const handleFormUpdate = () => {
                    handleUpdate(values);
                };

                const handlePreviousStep = () => {
                    if (isEdit) {
                        const formData = generateFormData(values);
                        onPrevious(isValid, formData);
                        return;
                    }
                    clickPrevious();
                };

                const handleToggleField = (field: string) => () => {
                    setToggledFields(state => {
                        if (state.includes(field)) {
                            setFieldValue(field, undefined);
                            return state.filter(val => val !== field);
                        }

                        return [...state, field];
                    });
                };

                return (
                    <Form>
                        <Box maxWidth={866}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box padding={2}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography variant="h4">2. Redemption</Typography>
                                                <Typography variant="body2">
                                                    Set up how the adjustment scheme will behave for your
                                                    users when redeeming through ordering in your app
                                                </Typography>
                                            </Grid>
                                            <AdjustmentSchemeDiscountForm
                                                discountScopeOptions={discountScopeOptions}
                                                currencySymbol={currencySymbol}
                                                posProvider={posProvider}
                                                showChildItemRule={showChildItemRule}
                                                getChildItemRuleOptions={getChildItemRuleOptions}
                                            />

                                            {isBasketQualificationVisible() && (
                                                <AdjustmentSchemeBasketForm
                                                    isEdit={isEdit}
                                                    currencySymbol={currencySymbol}
                                                    toggledFields={toggledFields}
                                                    discountValueString={discountValueString}
                                                    handleToggleField={handleToggleField}
                                                />
                                            )}
                                            {isDateTimeRestrictionVisible() && (
                                                <AdjustmentSchemeDateTimeForm
                                                    discountValueString={discountValueString}
                                                />
                                            )}
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Row flex={1} gutter align="flex-end">
                                        <Button
                                            onClick={handlePreviousStep}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            Previous
                                        </Button>
                                        {isEdit ? (
                                            <LoadingButton
                                                loading={isLoading}
                                                disabled={isLoading || !isValid}
                                                onClick={handleFormUpdate}
                                                color="primary"
                                                variant="contained"
                                            >
                                                Update & Close
                                            </LoadingButton>
                                        ) : (
                                            <LoadingButton
                                                loading={isLoading}
                                                disabled={!isValid || isLoading}
                                                onClick={handleSubmit}
                                                color="primary"
                                                variant="contained"
                                            >
                                                Save & Close
                                            </LoadingButton>
                                        )}
                                    </Row>
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                );
            }}
        </MuiForm>
    );
};
