import { Box, Button } from '@mui/material';
import { MenuChannel, MenuChannelFlags } from '@pepperhq/menu-sdk';
import { menuChannelLabel } from 'components/menu/model/menu';
import { OrderScenario, OrderScenarioLabels } from 'components/order/model/Order';
import { REQUIRED_ERROR_MESSAGE } from 'config/messages';
import { Form, Formik, useFormikContext } from 'formik';
import { ChipInputFormField } from 'lib/form/fields/ChipInputFormField';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { Option } from 'lib/types';
import * as React from 'react';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { MuiModal, MuiModalProps } from 'ui/MuiModal';
import * as Yup from 'yup';
import { LocationOrderNote } from '../models/LocationModel';

export interface ILocationOrderNoteForm {
    scenario: OrderScenario;
    title: string;
    description: string;
    channels?: Array<MenuChannel>;
    values?: Array<Option>;
}

const scenarios = [
    { label: OrderScenarioLabels[OrderScenario.PREORDER], value: OrderScenario.PREORDER },
    { label: 'Order to Table & Pay at Table', value: OrderScenario.ORDER_TO_TABLE }
];

const channelOptions = Object.values(MenuChannel).map(channel => ({
    label: menuChannelLabel[channel],
    value: channel
}));

const validationSchema = Yup.object().shape({
    title: Yup.string().required(REQUIRED_ERROR_MESSAGE),
    description: Yup.string().required(REQUIRED_ERROR_MESSAGE),
    scenario: Yup.mixed().oneOf([OrderScenario.ORDER_TO_TABLE, OrderScenario.PREORDER]),
    channels: Yup.array().min(1, 'At least one channel is required.')
});

const LocationOrderNoteForm: React.FC<{ onCancel: () => void }> = ({ onCancel }) => {
    const { submitForm } = useFormikContext<ILocationOrderNoteForm>();

    return (
        <Box minWidth="500px">
            <Box width="100%" mt={2}>
                <SelectFormField name="scenario" options={scenarios} />
            </Box>
            <Box width="100%" mt={2}>
                <SelectFormField label="Channels" name="channels" options={channelOptions} multiple />
            </Box>
            <Box width="100%" mt={2}>
                <TextFormField label="Title" name="title" />
            </Box>
            <Box width="100%" mt={2}>
                <TextFormField label="Description" name="description" />
            </Box>
            <Box width="100%" mt={2}>
                <ChipInputFormField
                    name="values"
                    multiple
                    label="Predefined Messages"
                    placeholder="Enter messages"
                    description="Predefined Messages are only visible on Quickpad"
                    isCreatable={true}
                    showNoOptionsMessage={false}
                />
            </Box>
            <Box width="100%" display="flex" mt={2} justifyContent="flex-end" gap={2} alignItems="center">
                <Button variant="outlined" onClick={onCancel}>
                    Cancel
                </Button>
                <LoadingButton color="primary" variant="contained" onClick={submitForm}>
                    Save
                </LoadingButton>
            </Box>
        </Box>
    );
};

interface ICreateLocationOrderNote {
    onAddition: (zoneDetails: LocationOrderNote, scenario: OrderScenario) => void;
    onClose: () => void;
    title: string;
    open: boolean;
}

export const CreateOrderNoteModal: React.FC<ICreateLocationOrderNote> = ({
    onAddition,
    onClose,
    ...modalProps
}) => {
    const initialValues: ILocationOrderNoteForm = {
        scenario: OrderScenario.PREORDER,
        title: '',
        description: '',
        channels: Object.values(MenuChannel)
    };

    const handleSubmit = React.useCallback(
        (formValues: ILocationOrderNoteForm) => {
            const channels = Object.values(MenuChannel).reduce((acc, channel) => {
                acc[channel] = formValues.channels?.includes(channel) ?? false;
                return acc;
            }, {} as MenuChannelFlags);

            const messages = formValues.values?.map(value => value.value) ?? [];

            onAddition(
                {
                    title: formValues.title,
                    description: formValues.description,
                    channels,
                    values: messages
                },
                formValues.scenario
            );
        },
        [onAddition]
    );

    return (
        <MuiModal {...modalProps}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                <Form>
                    <LocationOrderNoteForm onCancel={onClose} />
                </Form>
            </Formik>
        </MuiModal>
    );
};

interface IEditLocationOrderNote extends Omit<MuiModalProps, 'children'> {
    initialValues: ILocationOrderNoteForm;
    onEdit: (values: ILocationOrderNoteForm) => void;
    open: boolean;
    onClose: () => void;
}

export const EditLocationOrderNote: React.FC<IEditLocationOrderNote> = ({
    initialValues = {},
    onEdit,
    onClose,
    ...modalProps
}) => {
    const handleSubmit = React.useCallback(
        (values: ILocationOrderNoteForm) => {
            onEdit(values);
        },
        [onEdit]
    );

    return (
        <MuiModal {...modalProps} title={`Edit "${initialValues.title}"`}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                <Form>
                    <LocationOrderNoteForm onCancel={onClose} />
                </Form>
            </Formik>
        </MuiModal>
    );
};
