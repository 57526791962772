import { hexToRgb, Popover, Typography, styled, Button, Box, ClickAwayListener } from '@mui/material';
import { lightGreen } from '@mui/material/colors';
import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import React, { ChangeEvent, MouseEventHandler } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import logger from 'lib/logger';
import { isString } from 'lib/typeguards';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { MAX_IMAGE_UPLOAD_SIZE } from 'config/const';
import { useImagePicker } from 'lib/image-picker/useImagePicker';
import { MuiImagePickerStep, useImagePickerState } from 'lib/image-picker/useImagePickerState';
import { useImageCrop } from 'lib/image-picker/useImageCrop';
import { ImagePickerPreviewModal } from 'lib/image-picker/ImagePickerPreviewModal';
import { ImagePickerCropModal } from 'lib/image-picker/ImagePickerCropModal';

const PREFIX = 'MuiGridImage';

const classes = {
    wrapper: `${PREFIX}-wrapper`,
    root: `${PREFIX}-root`,
    error: `${PREFIX}-error`,
    iconContainer: `${PREFIX}-iconContainer`,
    icon: `${PREFIX}-icon`,
    fakeInput: `${PREFIX}-fakeInput`
};

const StyledPopover = styled(Popover, {
    shouldForwardProp: (prop: string) => prop !== 'pointerEventsEnabled'
})<{ pointerEventsEnabled?: boolean }>(({ pointerEventsEnabled = true }) => ({
    pointerEvents: pointerEventsEnabled ? 'initial' : 'none'
}));

const StyledDiv = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    paddingRight: theme.spacing(1),
    [`& .${classes.root}`]: {
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        position: 'relative',
        width: '100%',
        height: '100%',
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(1.25),
        userSelect: 'none',
        lineHeight: '1.6rem'
    },

    [`& .${classes.error}`]: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        lineHeight: 1,
        color: theme.palette.error.main,
        backgroundColor: hexToRgb(lightGreen[100]).replace(')', `, ${0.8})`),
        borderRadius: `${theme.shape.borderRadius} 0`
    },

    [`& .${classes.iconContainer}`]: {
        position: 'absolute',
        right: 0,
        top: 0,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },

    [`& .${classes.icon}`]: {
        opacity: 0.4,
        fontSize: '1.1em'
    },

    [`& .${classes.fakeInput}`]: {
        height: 1,
        width: 1,
        opacity: 0,
        position: 'absolute',
        top: 0,
        left: 0
    }
}));

const StyledImageDiv = styled('div')(() => ({
    height: 200,
    width: 300,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
}));

const ViewImageIcon = styled(VisibilityIcon)(({ theme }) => ({
    marginRight: theme.spacing(5 / 4),
    verticalAlign: 'middle',
    cursor: 'pointer'
}));

interface MuiGridImageProps extends GridRenderEditCellParams {
    filePrefix?: string;
    aspect: number;
}

const MuiGridImage: React.FC<MuiGridImageProps> = ({
    id,
    value,
    api,
    field,
    isEditable,
    error
}: GridRenderEditCellParams) => {
    const [isViewingImage, setIsViewingImage] = React.useState(false);
    const anchor = React.useRef();

    const handleEdit = React.useCallback<MouseEventHandler>(
        e => {
            e.preventDefault();
            e.stopPropagation();
            api.startCellEditMode({ id, field });
        },
        [api, field, id]
    );
    const formattedValue = React.useMemo(() => {
        if (isString(value)) {
            const valueParts = value.split('/');
            return valueParts[valueParts.length - 1];
        }
        return value;
    }, [value]);
    const handleRemove = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            // Hack to delete cell value
            api.startCellEditMode({ id, field, deleteValue: true });
            setIsViewingImage(false);
        },
        [api, field, id]
    );
    const handleView = React.useCallback(() => setIsViewingImage(val => !val), []);
    const stopPropagation = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
    }, []);

    return (
        <StyledDiv onDoubleClick={stopPropagation}>
            <div className={classes.root}>
                {formattedValue && (
                    <ViewImageIcon onClick={handleView} ref={anchor} className={classes.icon} />
                )}
                {formattedValue}
                {isEditable && (
                    <div className={classes.iconContainer}>
                        <AttachFileIcon className={classes.icon} onClick={handleEdit} />
                    </div>
                )}
                {!!error && (
                    <Typography className={classes.error} variant="caption" color="error">
                        {error}
                    </Typography>
                )}
            </div>
            <StyledPopover
                open={isViewingImage && isString(value) && !!anchor.current}
                anchorEl={anchor.current ?? null}
                tabIndex={-1}
                disableAutoFocus
                disableScrollLock
                disableEnforceFocus
                disableRestoreFocus
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <ClickAwayListener onClickAway={handleView}>
                    <div>
                        {isString(value) && <StyledImageDiv style={{ backgroundImage: `url(${value})` }} />}
                        <Box display="flex" justifyContent="flex-end" padding={0.5} paddingTop={0}>
                            <Button onClick={handleRemove} variant="contained" color="primary">
                                Clear
                            </Button>
                        </Box>
                    </div>
                </ClickAwayListener>
            </StyledPopover>
        </StyledDiv>
    );
};

const MuiGridImageEdit: React.FC<MuiGridImageProps> = ({
    id,
    field,
    api,
    value,
    aspect: externalASpect,
    filePrefix,
    hasFocus
}) => {
    const dispatch = useDispatch();
    const anchor = React.useRef();
    const formattedValue = React.useMemo(() => {
        if (isString(value)) {
            const valueParts = value.split('/');
            return valueParts[valueParts.length - 1];
        }
        return value;
    }, [value]);
    const reference = React.useRef<HTMLInputElement>();

    const handleImageSelect = React.useCallback(
        (imageUrl: string) => {
            api.setEditCellValue({ id, field, value: imageUrl });
            api.stopCellEditMode({ id, field });
        },
        [api, field, id]
    );

    const {
        step,
        onCloseStateChange,
        onCropCancelStateChange,
        onCropStartStateChange,
        onFileSelectStateChange
    } = useImagePickerState();
    const handleClose = React.useCallback(() => {
        onCloseStateChange();
        api.stopCellEditMode({ id, field });
    }, [api, field, id, onCloseStateChange]);
    const { src, file, error, loading, aspect, onFileSelect, onClose, onSubmit, onUploadSuccess } =
        useImagePicker(
            { aspect: externalASpect, onSelect: handleImageSelect, randomNamePrefix: filePrefix },
            onFileSelectStateChange,
            handleClose
        );
    const {
        crop,
        cropLoading,
        cropError,
        isCropAvailable,
        onCropClose,
        onCropStart,
        onCropChange,
        onCropComplete,
        onImageLoaded,
        onCropSubmit
    } = useImageCrop(
        { aspect, onSelect: handleImageSelect, randomNamePrefix: filePrefix },
        onCropStartStateChange,
        onCropCancelStateChange,
        onUploadSuccess,
        file
    );

    const handleInputChange = React.useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            try {
                const [file] = (event.target as any).files as FileList;
                onFileSelect(file);
            } catch (e) {
                logger.error(e.message);
                dispatch(
                    enqueueSnackbar(
                        e.message ||
                            `Failed to upload an image. Please make sure the image size is less than ${Math.round(
                                MAX_IMAGE_UPLOAD_SIZE / 1000000
                            )}MB.`,
                        { variant: 'warning' }
                    )
                );
            }
        },
        [dispatch, onFileSelect]
    );
    const handleClick = React.useCallback(() => {
        reference.current?.click();
    }, []);
    React.useLayoutEffect(() => {
        reference.current?.click();
    }, []);

    return (
        <StyledDiv>
            <div className={classes.root} ref={anchor}>
                {formattedValue}
                <input
                    type="file"
                    accept="image/*"
                    className={classes.fakeInput}
                    ref={reference}
                    onChange={handleInputChange}
                />
                <div className={classes.iconContainer}>
                    <AttachFileIcon className={classes.icon} onClick={handleClick} />
                </div>
                {!!error && (
                    <Typography className={classes.error} variant="caption" color="error">
                        {error}
                    </Typography>
                )}
            </div>
            <ImagePickerPreviewModal
                open={step === MuiImagePickerStep.SELECTED}
                onClose={onClose}
                onCropStart={onCropStart}
                isCropAvailable={isCropAvailable}
                onSubmit={onSubmit}
                loading={loading}
                error={error}
                src={src}
                file={file}
                aspect={aspect}
                disabled={!!error || loading}
            />
            <ImagePickerCropModal
                open={step === MuiImagePickerStep.CROPPING}
                onClose={onCropClose}
                crop={crop}
                src={src}
                onImageLoaded={onImageLoaded}
                onCropComplete={onCropComplete}
                onCropChange={onCropChange}
                error={cropError}
                loading={cropLoading}
                onSubmit={onCropSubmit}
                disabled={!!cropError || cropLoading}
            />
            <StyledPopover
                open={hasFocus && isString(value) && !!anchor.current}
                anchorEl={anchor.current ?? null}
                tabIndex={-1}
                disableAutoFocus
                disableScrollLock
                disableEnforceFocus
                disableRestoreFocus
                pointerEventsEnabled={false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                {isString(value) && <StyledImageDiv style={{ backgroundImage: `url(${value})` }} />}
            </StyledPopover>
        </StyledDiv>
    );
};

export const renderMuiGridImage = (props: GridRenderEditCellParams, aspect = 1, isEditable = false) => (
    <MuiGridImage {...props} aspect={aspect} isEditable={isEditable} />
);
export const renderMuiGridImageEdit = (props: GridRenderEditCellParams, filePrefix?: string, aspect = 1) => (
    <MuiGridImageEdit {...props} aspect={aspect} filePrefix={filePrefix} />
);
