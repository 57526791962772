import React from 'react';
import { UserMetricDefinition } from '../model';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { LinkIdCell, LinkIdCellRowModel } from 'lib/MuiGrid/LinkIdCell';
import { MARKETING_AUDIENCES_VIEW } from 'config/routes';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Row } from 'ui/Flex';
import { useBirthdayAudiences } from './hooks/useBirthdayAudiences';
import { BirthdayAudienceModal } from '../modals/BirthdayAudienceModal';
import { useGridSingleSelection } from 'lib/MuiGrid/useGridSingleSelection';
import { useBirthdayAudienceActions } from './hooks/useBirthdayAudienceActions';
import { useSimpleModalState } from 'lib/hooks/useSimpleModalState';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { GridStorageName } from 'lib/MuiGrid/StateController';

interface BirthdayAudiencesProps {
    metricDefinition?: UserMetricDefinition;
    loading?: boolean;
    updateMetricDefinition: (definition: UserMetricDefinition) => void;
}

export const BirthdayAudiences: React.FC<BirthdayAudiencesProps> = ({
    loading,
    metricDefinition,
    updateMetricDefinition
}) => {
    const { birthdayAudiences } = useBirthdayAudiences(metricDefinition);

    const [editOpen, handleEditOpen, handleEditClose] = useSimpleModalState();
    const [deleteOpen, handleDeleteOpen, handleDeleteClose] = useSimpleModalState();

    const [selectedBirthdayAudience, rowSelectionModel, handleSelectRow] =
        useGridSingleSelection(birthdayAudiences);
    const handleSuccess = React.useCallback(
        (newMetricDefinition: UserMetricDefinition) => {
            updateMetricDefinition(newMetricDefinition);
            handleEditClose();
            handleDeleteClose();
        },
        [handleDeleteClose, handleEditClose, updateMetricDefinition]
    );
    const { onBirthdayAudienceChange, isChangeLoading, onBirthdayAudienceDelete } =
        useBirthdayAudienceActions(
            metricDefinition,
            birthdayAudiences,
            handleSuccess,
            selectedBirthdayAudience
        );
    const renderViewCell = React.useCallback(
        (params: GridCellParams<LinkIdCellRowModel, string>) => (
            <LinkIdCell id={params.value} param="audienceId" path={MARKETING_AUDIENCES_VIEW} />
        ),
        []
    );
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'name',
                headerName: 'Audience',
                width: 300
            },
            {
                headerName: 'ID',
                field: 'id',
                width: 300,
                renderCell: renderViewCell
            },
            {
                field: 'fromDaysString',
                headerName: 'From',
                width: 200
            },
            {
                field: 'toDaysString',
                headerName: 'To',
                width: 200
            }
        ],
        [renderViewCell]
    );
    const handleAdd = React.useCallback(() => {
        handleSelectRow([]);
        handleEditOpen();
    }, [handleEditOpen, handleSelectRow]);

    const deleteTitle = React.useMemo(() => 'Delete audience conditions?', []);
    const deleteContent = React.useMemo(
        () =>
            `Are you sure you want to delete conditions associated with ${selectedBirthdayAudience?.name}? This action is irreversible.`,
        [selectedBirthdayAudience?.name]
    );

    return (
        <Paper sx={{ p: 1, pb: 2 }}>
            <Row align="space-between" flex={1} valign="center">
                <Typography variant="h6" sx={{ ml: 1 }}>
                    Audience conditions
                </Typography>
                <Row gutter>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleEditOpen}
                        disabled={!selectedBirthdayAudience}
                    >
                        View
                    </Button>
                    <Button variant="contained" color="primary" size="small" onClick={handleAdd}>
                        Add
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        sx={{ mr: 1 }}
                        disabled={!selectedBirthdayAudience}
                        onClick={handleDeleteOpen}
                    >
                        Delete
                    </Button>
                </Row>
            </Row>
            <Box height="300px" mt={1}>
                <MuiGrid
                    loading={loading}
                    columns={columns}
                    rows={birthdayAudiences}
                    disableMultipleRowSelection
                    disableRowSelectionOnClick={false}
                    onRowSelectionModelChange={handleSelectRow}
                    rowSelectionModel={rowSelectionModel}
                    storageName={GridStorageName.BirthdaySchemes}
                />
            </Box>
            {editOpen && (
                <BirthdayAudienceModal
                    open={editOpen}
                    onClose={handleEditClose}
                    birthdayAudience={selectedBirthdayAudience}
                    onSubmit={onBirthdayAudienceChange}
                    loading={isChangeLoading}
                />
            )}
            <DeleteDialog
                open={deleteOpen}
                onClose={handleDeleteClose}
                title={deleteTitle}
                content={deleteContent}
                loading={isChangeLoading}
                onDelete={onBirthdayAudienceDelete}
            />
        </Paper>
    );
};
