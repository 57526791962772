import { Button, Grid, Tooltip } from '@mui/material';
import React from 'react';
import { Option } from 'lib/types';
import {
    EPremiumLoyaltyConditionOperator,
    EPremiumLoyaltyConditionType,
    EPremiumLoyaltyConditionUserProperty,
    getPremiumLoyaltyConditionType,
    PremiumLoyaltyConditionBody,
    PremiumLoyaltyConditionFieldName,
    UserMetric,
    UserMetricListenerCondition
} from './model';
import { EPrimaryPlatform } from 'components/customers/models/Customer';

import { isDefined, isEmptyString } from 'lib/typeguards';
import { isNull } from '@pepperhq/menu-sdk/dist/libs/typeUtils';
import {
    PremiumLoyaltyAudienceFormField,
    PremiumLoyaltyConditionField
} from './PremiumLoyaltyAudienceFormField';

interface TieredLoyaltyTierFormProps {
    metrics?: Record<string, UserMetric>;
    item?: UserMetricListenerCondition;
    audienceOptions: Option[];
    locationOptions: Option[];
    onSubmit?: (data: PremiumLoyaltyConditionBody) => void;
    onDelete?: (id: string) => void;
    loading?: boolean;
    metricRequired?: boolean;
    defaultItem?: Partial<Record<PremiumLoyaltyConditionFieldName, any>>;
}

const tierTypeOptions = [
    { value: EPremiumLoyaltyConditionType.Metric, label: 'Metric' },
    { value: EPremiumLoyaltyConditionType.Audience, label: 'Audience' },
    { value: EPremiumLoyaltyConditionType.UserProperty, label: 'User property' }
];

const metricOperatorOptions = [
    { value: EPremiumLoyaltyConditionOperator.Equal, label: 'Equal' },
    { value: EPremiumLoyaltyConditionOperator.NotEqual, label: 'Not equal' },
    { value: EPremiumLoyaltyConditionOperator.GreaterThan, label: 'Greater than' },
    { value: EPremiumLoyaltyConditionOperator.LessThan, label: 'Less than' }
];

const audienceOperatorOptions = [
    { value: EPremiumLoyaltyConditionOperator.IsInAudience, label: 'In audience' },
    { value: EPremiumLoyaltyConditionOperator.IsNotInAudience, label: 'Not in audience' }
];

const primaryPlatformOperatorOptions = [
    { value: EPremiumLoyaltyConditionOperator.Equal, label: 'Is' },
    { value: EPremiumLoyaltyConditionOperator.NotEqual, label: 'Is not' }
];

const primaryPlatformValueOptions = [
    { value: EPrimaryPlatform.ANDROID, label: 'Android' },
    { value: EPrimaryPlatform.IOS, label: 'iOS' },
    { value: EPrimaryPlatform.WEB, label: 'Web' },
    { value: EPrimaryPlatform.UNKNOWN, label: 'Unknown' }
];

const favouriteLocationsOperatorOptions = [
    { value: EPremiumLoyaltyConditionOperator.Contains, label: 'Contain' },
    { value: EPremiumLoyaltyConditionOperator.NotContains, label: "Don't contain" }
];

const marketingOperatorOptions = [
    { value: EPremiumLoyaltyConditionOperator.Equal, label: 'Agreed' },
    { value: EPremiumLoyaltyConditionOperator.NotEqual, label: "Didn't agree" }
];

const userPropertyOptions = [
    { value: EPremiumLoyaltyConditionUserProperty.FavouriteLocations, label: 'Favourite locations' },
    { value: EPremiumLoyaltyConditionUserProperty.PrimaryPlatform, label: 'Primary platform' },
    { value: EPremiumLoyaltyConditionUserProperty.HasAgreedToShareData, label: 'Has agreed to share data' },
    {
        value: EPremiumLoyaltyConditionUserProperty.HasAgreedToReceiveMarketing,
        label: 'Has agreed to marketing'
    }
];

const getFormValues = (
    item: UserMetricListenerCondition | undefined,
    locationOptions: Option[],
    audienceOptions: Option[]
) => ({
    type: getPremiumLoyaltyConditionType(item) ?? EPremiumLoyaltyConditionType.Metric,
    metricId: item && 'metricId' in item ? item?.metricId : undefined,
    metricOperator: item?.operator,
    value: isDefined(item?.value) ? String(item?.value) : undefined,
    audienceOperator: item?.operator ?? EPremiumLoyaltyConditionOperator.IsInAudience,
    audience: isDefined(item?.value) ? audienceOptions?.find(option => option.value === item?.value) : null,
    userProperty:
        item && 'userProperty' in item
            ? item.userProperty
            : EPremiumLoyaltyConditionUserProperty.HasAgreedToReceiveMarketing,
    primaryPlatformOperator: item?.operator ?? EPremiumLoyaltyConditionOperator.Equal,
    primaryPlatformValue: item?.value,
    favouriteLocationsOperator: item?.operator ?? EPremiumLoyaltyConditionOperator.Contains,
    favouriteLocationsValue: isDefined(item?.value)
        ? locationOptions?.find(option => option.value === item?.value)
        : null,
    marketingOperator: item?.operator
});

export const PremiumLoyaltyConditionRow: React.FC<TieredLoyaltyTierFormProps> = ({
    metrics = {},
    item,
    audienceOptions,
    onSubmit,
    onDelete,
    locationOptions,
    loading,
    metricRequired,
    defaultItem
}) => {
    const [formState, setFormState] = React.useState<Record<PremiumLoyaltyConditionFieldName, any>>(
        getFormValues(item, locationOptions, audienceOptions)
    );
    const [touched, setTouched] = React.useState(false);
    const readOnly = React.useMemo(() => !!item, [item]);
    const metricOptions = React.useMemo(
        () => Object.entries(metrics).map(([id, metric]) => ({ value: id, label: metric.name })),
        [metrics]
    );
    const fieldDefinitions: Record<PremiumLoyaltyConditionFieldName, PremiumLoyaltyConditionField> =
        React.useMemo(
            () => ({
                type: {
                    select: true,
                    options: tierTypeOptions,
                    width: 2
                },
                metricId: {
                    select: true,
                    options: metricOptions,
                    width: 4,
                    label: 'Metric',
                    fieldMapper: (body, value) => {
                        body.metricId = value;
                    }
                },
                metricOperator: {
                    select: true,
                    options: metricOperatorOptions,
                    width: 3,
                    label: 'Operator',
                    fieldMapper: (body, value) => {
                        body.operator = value;
                    }
                },
                value: {
                    width: 2,
                    label: 'Value',
                    fieldMapper: (body, value) => {
                        body.value = Number(value);
                    }
                },
                audienceOperator: {
                    select: true,
                    options: audienceOperatorOptions,
                    width: 4,
                    fieldMapper: (body, value) => {
                        body.operator = value;
                    }
                },
                audience: {
                    searchable: true,
                    options: audienceOptions,
                    width: 5,
                    placeholder: 'Select an audience',
                    fieldMapper: (body, value) => {
                        body.value = value ? value.value : value;
                    }
                },
                userProperty: {
                    select: true,
                    options: userPropertyOptions,
                    width: 4,
                    placeholder: 'Select a user property',
                    fieldMapper: (body, value) => {
                        body.userProperty = value;
                    }
                },
                primaryPlatformOperator: {
                    select: true,
                    options: primaryPlatformOperatorOptions,
                    width: 2,
                    placeholder: 'Select an operator',
                    fieldMapper: (body, value) => {
                        body.operator = value;
                    }
                },
                primaryPlatformValue: {
                    select: true,
                    options: primaryPlatformValueOptions,
                    width: 3,
                    placeholder: 'Select a primary platform',
                    fieldMapper: (body, value) => {
                        body.value = value;
                    }
                },
                favouriteLocationsOperator: {
                    select: true,
                    options: favouriteLocationsOperatorOptions,
                    width: 2,
                    placeholder: 'Select an operator',
                    fieldMapper: (body, value) => {
                        body.operator = value;
                    }
                },
                favouriteLocationsValue: {
                    select: true,
                    options: locationOptions,
                    width: 3,
                    searchable: true,
                    placeholder: 'Select a location',
                    fieldMapper: (body, value) => {
                        body.value = value ? value.value : value;
                    }
                },
                marketingOperator: {
                    select: true,
                    options: marketingOperatorOptions,
                    width: 5,
                    placeholder: 'Select an option',
                    fieldMapper: (body, value) => {
                        body.value = true;
                        body.operator = value;
                    }
                }
            }),
            [audienceOptions, locationOptions, metricOptions]
        );
    const fieldsToUse: PremiumLoyaltyConditionFieldName[] = React.useMemo(() => {
        switch (formState.type) {
            case EPremiumLoyaltyConditionType.UserProperty: {
                switch (formState.userProperty) {
                    case EPremiumLoyaltyConditionUserProperty.FavouriteLocations:
                        return [
                            'type',
                            'userProperty',
                            'favouriteLocationsOperator',
                            'favouriteLocationsValue'
                        ];
                    case EPremiumLoyaltyConditionUserProperty.HasAgreedToShareData:
                    case EPremiumLoyaltyConditionUserProperty.HasAgreedToReceiveMarketing:
                        return ['type', 'userProperty', 'marketingOperator'];
                    case EPremiumLoyaltyConditionUserProperty.PrimaryPlatform:
                        return ['type', 'userProperty', 'primaryPlatformOperator', 'primaryPlatformValue'];
                    default:
                        return ['type', 'userProperty'];
                }
            }
            case EPremiumLoyaltyConditionType.Audience:
                return ['type', 'audienceOperator', 'audience'];
            case EPremiumLoyaltyConditionType.Metric:
            default:
                return ['type', 'metricId', 'metricOperator', 'value'];
        }
    }, [formState.type, formState.userProperty]);
    const errors = React.useMemo<string[]>(() => {
        if (item) {
            return [];
        }
        const emptyFields = fieldsToUse.filter(
            field =>
                !isDefined(formState[field]) || isNull(formState[field]) || isEmptyString(formState[field])
        );
        return emptyFields;
    }, [fieldsToUse, formState, item]);
    const handleFieldChange = React.useCallback((name: PremiumLoyaltyConditionFieldName, value: any) => {
        setFormState(state => ({ ...state, [name]: value }));
    }, []);
    const renderField = React.useCallback(
        (name: PremiumLoyaltyConditionFieldName) => (
            <PremiumLoyaltyAudienceFormField
                key={name}
                field={fieldDefinitions[name]}
                name={name}
                value={formState[name]}
                onChange={handleFieldChange}
                readOnly={readOnly || (defaultItem && isDefined(defaultItem[name]))}
                loading={loading}
                error={touched && errors.includes(name)}
            />
        ),
        [defaultItem, errors, fieldDefinitions, formState, handleFieldChange, loading, readOnly, touched]
    );
    // ==========
    const handleSave = React.useCallback(() => {
        if (!onSubmit) {
            throw new Error('No submit handler provided!');
        }
        setTouched(true);
        if (errors.length) {
            return;
        }
        const body: PremiumLoyaltyConditionBody = {};
        fieldsToUse.forEach(field => {
            const definition = fieldDefinitions[field];
            if (definition?.fieldMapper) {
                definition.fieldMapper(body, formState[field]);
            }
        });
        onSubmit(body);
    }, [errors.length, fieldsToUse, fieldDefinitions, formState, onSubmit]);
    const handleDelete = React.useCallback(() => {
        if (!onDelete) {
            throw new Error('No submit handler provided!');
        }
        onDelete(item?._id);
    }, [item?._id, onDelete]);
    // ==========
    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {fieldsToUse.map(renderField)}
            {!item && (
                <Grid item xs={12} lg={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        fullWidth
                        disabled={loading || (!!errors.length && touched)}
                    >
                        Add
                    </Button>
                </Grid>
            )}
            {!!item && metricRequired && (
                <Tooltip title="You must have at least one metric condition" arrow>
                    <Grid item xs={12} lg={1}>
                        <Button variant="outlined" color="error" fullWidth disabled>
                            Delete
                        </Button>
                    </Grid>
                </Tooltip>
            )}
            {!!item && !metricRequired && (
                <Grid item xs={12} lg={1}>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={handleDelete}
                        fullWidth
                        disabled={loading}
                    >
                        Delete
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};
