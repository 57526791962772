/* eslint-disable no-bitwise */
import React from 'react';
import { Redirect, Switch } from 'react-router';
import { Permission, permission, Resource } from '@pepperhq/auth-client';
import { PrivateLayout } from 'layouts/PrivateLayout';
import * as routes from 'config/routes';
import { RestrictedRoute } from 'lib/RestrictedRoute';
import Analytics from 'pages/analytics';
import AnalyticsDashboardView from 'pages/analytics/dashboards/view';
import AnalyticsExport from 'pages/analytics/export';
import Customers from 'pages/customers';
import CustomersView from 'pages/customers/view';
import Locations from 'pages/locations';
import LocationTags from 'pages/location-tags';
import LocationView from 'pages/locations/view';
import Marketing from 'pages/marketing';
import MarketingAudiencesView from 'pages/marketing/audiences/view';
import MarketingNotifications from 'pages/marketing/notifications';
import MarketingNotificationsView from 'pages/marketing/notifications/view';
import MarketingPerks from 'pages/marketing/perks';
import MarketingVouchers from 'pages/marketing/vouchers';
import MarketingVouchersView from 'pages/marketing/vouchers/view';
import { AdjustmentSchemesPage } from 'src/pages/marketing/adjustment-schemes';
import { AccessManagementPage } from 'pages/merchant-settings/access-management';
import MobileContent from 'pages/mobile-content';
import MobileContentConfirm from 'pages/mobile-content/confirm';
import LayoutConfig from 'pages/mobile-content/layout-config';
import ModuleConfig from 'pages/mobile-content/module-config';
import TemplateSelection from 'pages/mobile-content/template-selection';
import Merchants from 'pages/user/merchants';
import { BrandingSettingsPage } from './settings/branding';
import { GlobalSettingsPage } from './settings/global';
import { LocationsAndFavouritesSettingsPage } from './settings/location';
import { MarketingSettingsPage } from './settings/marketing';
import { OrderingSettingsPage } from './settings/ordering';
import { PaymentsSettingsPage } from './settings/payments';
import { PointOfSaleSettingsPage } from './settings/pos';
import { MenuPage } from './menu';
import { OverwritesPage } from './overwrites';
import { SettingsPage } from './settings';
import { RedirectToNewMenu } from 'components/menu/RedirectToNewMenu';
import { TransactionsPage } from './pepper-pay/transactions';
import { ViewTransactionPage } from './pepper-pay/view-transaction';
import { AvailabilityView } from './locations/content/availability/view';
import { ReportingPage } from './pepper-pay/reports/reports';
import { DisputesPage } from './pepper-pay/disputes';
import { ViewDisputePage } from './pepper-pay/view-dispute';
import { BlockList } from './pepper-pay/block-list';
import AnalyticsOrdersPage from './analytics/orders';
import AnalyticsLoyaltyPage from './analytics/loyalty';
import AnalyticsCustomersPage from './analytics/customers';
import { MenuManager } from './menu/MenuManager';
import { MenuScheduling } from './menu/MenuScheduling';
import { TerminalAccessPage } from './terminal';
import { ClerksPage } from './merchant-settings/clerks';
import { GiftingActivityPage } from './analytics/gifting/activity';
import { GiftingLiabilityPage } from 'pages/analytics/gifting/liability';
import { OrdersPage } from './orders';
import { OrderViewPage } from './orders/view';
import { TerminalSettingsPage } from './settings/terminal';
import { TieredLoyalty } from './schemes/tiered-loyalty';
import { OrderingAvailabilityPage } from './locations/content/availability';
import { BirthdayLoyalty } from './schemes/birthday-loyalty';
import { AudiencesPage } from './marketing/audiences';
import Campaigns from 'pages/campaigns';
import { ScheduledCampaignsPage } from 'pages/campaigns/scheduled';
import { ScheduledCampaignViewPage } from 'pages/campaigns/scheduled/view';
import { TriggeredCampaignsPage } from 'pages/campaigns/triggered';
import { TriggeredCampaignViewPage } from 'pages/campaigns/triggered/view';

export default () => (
    <PrivateLayout>
        <Switch>
            <RestrictedRoute
                path={routes.USER_MERCHANTS}
                component={Merchants}
                resources={{ ...permission(Resource.User, Permission.read) }}
            />
            {/* Mobile content */}
            <RestrictedRoute
                exact
                path={routes.MOBILE_CONTENT}
                component={MobileContent}
                resources={{ ...permission(Resource.Layout, Permission.read | Permission.write) }}
            />
            <RestrictedRoute
                path={routes.MOBILE_CONTENT_LAYOUT_CONFIG}
                component={LayoutConfig}
                resources={{ ...permission(Resource.Layout, Permission.write) }}
            />
            <RestrictedRoute
                path={routes.MOBILE_CONTENT_MODULE_CONFIGURATION}
                component={ModuleConfig}
                resources={{ ...permission(Resource.Layout, Permission.write) }}
            />
            <RestrictedRoute
                path={routes.MOBILE_CONTENT_TEMPLATE_SELECTION}
                component={TemplateSelection}
                resources={{ ...permission(Resource.Layout, Permission.read) }}
            />
            <RestrictedRoute
                path={routes.MOBILE_CONTENT_CONFIRMATION}
                component={MobileContentConfirm}
                resources={{ ...permission(Resource.Layout, Permission.write) }}
            />
            {/* Location */}
            <RestrictedRoute
                exact
                path={routes.LOCATIONS}
                component={Locations}
                resources={{ ...permission(Resource.Location, Permission.read) }}
            />
            <RestrictedRoute
                exact
                path={routes.LOCATION_VIEW}
                component={LocationView}
                resources={{ ...permission(Resource.Location, Permission.read) }}
            />
            <RestrictedRoute
                exact
                path={routes.LOCATION_TAGS}
                component={LocationTags}
                resources={{ ...permission(Resource.Location, Permission.read) }}
            />
            <RestrictedRoute
                exact
                path={routes.CONTENT_AVAILABILITY}
                component={OrderingAvailabilityPage}
                resources={{ ...permission(Resource.Menu, Permission.read) }}
            />
            <RestrictedRoute
                path={routes.CONTENT_AVAILABILITY_VIEW}
                exact
                component={AvailabilityView}
                resources={{ ...permission(Resource.Menu, Permission.read) }}
            />
            <RestrictedRoute
                path={routes.MENU_MANAGER_ENRICHMENTS}
                component={MenuPage}
                resources={{ ...permission(Resource.Menu, Permission.read) }}
            />
            <RestrictedRoute
                exact
                path={routes.MENU_MANAGER_ENRICHMENTS_EMPTY}
                component={RedirectToNewMenu}
                resources={{ ...permission(Resource.Menu, Permission.read) }}
            />
            {/* Analytics */}
            <RestrictedRoute path={routes.ANALYTICS} component={Analytics} exact />
            <RestrictedRoute
                exact
                path={routes.ANALYTICS_DASHBOARD_VIEW}
                component={AnalyticsDashboardView}
                resources={{ ...permission(Resource.Analytics, Permission.read) }}
            />
            <RestrictedRoute
                exact
                path={routes.ANALYTICS_ORDERS}
                component={AnalyticsOrdersPage}
                resources={{
                    ...permission(Resource.Analytics, Permission.read)
                }}
            />
            <RestrictedRoute
                exact
                path={routes.ANALYTICS_LOYALTY}
                component={AnalyticsLoyaltyPage}
                resources={{
                    ...permission(Resource.Analytics, Permission.read)
                }}
            />
            <RestrictedRoute
                exact
                path={routes.ANALYTICS_CUSTOMERS}
                component={AnalyticsCustomersPage}
                resources={{
                    ...permission(Resource.Analytics, Permission.read)
                }}
            />
            <RestrictedRoute
                path={routes.ANALYTICS_EXPORT}
                component={AnalyticsExport}
                resources={{
                    ...permission(Resource.Analytics, Permission.read)
                }}
            />
            {/* Gifting Analytics */}
            <RestrictedRoute
                exact
                path={routes.ANALYTICS_GIFTING_ACTIVITY}
                component={GiftingActivityPage}
                resources={{
                    ...permission(Resource.Analytics, Permission.read)
                }}
            />
            <RestrictedRoute
                exact
                path={routes.ANALYTICS_GIFTING_LIABILITY}
                component={GiftingLiabilityPage}
                resources={{
                    ...permission(Resource.Analytics, Permission.read)
                }}
            />
            {/* Customers */}
            <RestrictedRoute
                exact
                path={routes.CUSTOMERS}
                component={Customers}
                resources={{
                    ...permission(Resource.Customer, Permission.read)
                }}
            />
            <RestrictedRoute
                exact
                path={routes.CUSTOMERS_VIEW}
                component={CustomersView}
                resources={{
                    ...permission(Resource.Customer, Permission.read)
                }}
            />
            {/* Marketing */}
            <RestrictedRoute exact path={routes.MARKETING} component={Marketing} />
            <RestrictedRoute
                exact
                path={routes.MARKETING_AUDIENCES}
                component={AudiencesPage}
                resources={{ ...permission(Resource.Segment, Permission.read) }}
            />
            <RestrictedRoute
                exact
                path={routes.MARKETING_AUDIENCES_VIEW}
                component={MarketingAudiencesView}
                resources={{ ...permission(Resource.Segment, Permission.read) }}
            />
            <RestrictedRoute
                exact
                path={routes.MARKETING_NOTIFICATIONS}
                component={MarketingNotifications}
                resources={{ ...permission(Resource.Message, Permission.read) }}
            />
            <RestrictedRoute
                exact
                path={routes.MARKETING_NOTIFICATIONS_VIEW}
                component={MarketingNotificationsView}
                resources={{ ...permission(Resource.Message, Permission.read) }}
            />
            <RestrictedRoute
                path={`${routes.MARKETING_PERKS}/:type`}
                component={MarketingPerks}
                resources={{ ...permission(Resource.Perk, Permission.read) }}
            />
            <RestrictedRoute
                exact
                path={routes.MARKETING_VOUCHERS}
                component={MarketingVouchers}
                resources={{ ...permission(Resource.Voucher, Permission.read) }}
            />
            <RestrictedRoute
                exact
                path={routes.MARKETING_VOUCHERS_VIEW}
                component={MarketingVouchersView}
                resources={{ ...permission(Resource.Voucher, Permission.read) }}
            />
            <RestrictedRoute
                exact
                path={routes.MARKETING_ADJUSTMENT_SCHEMES}
                component={AdjustmentSchemesPage}
                resources={{ ...permission(Resource.AdjustmentSchemes, Permission.read) }}
            />
            {/* Campaigns */}
            <RestrictedRoute
                exact
                path={routes.CAMPAIGNS}
                component={Campaigns}
                resources={{ ...permission(Resource.Message, Permission.read) }}
            />
            <RestrictedRoute
                exact
                path={routes.CAMPAIGNS_SCHEDULED}
                component={ScheduledCampaignsPage}
                resources={{ ...permission(Resource.Message, Permission.read) }}
            />
            <RestrictedRoute
                exact
                path={routes.CAMPAIGNS_SCHEDULED_VIEW}
                component={ScheduledCampaignViewPage}
                resources={{ ...permission(Resource.Message, Permission.read) }}
            />
            <RestrictedRoute
                exact
                path={routes.CAMPAIGNS_TRIGGERED}
                component={TriggeredCampaignsPage}
                resources={{ ...permission(Resource.Message, Permission.read) }}
            />
            <RestrictedRoute
                exact
                path={routes.CAMPAIGNS_TRIGGERED_VIEW}
                component={TriggeredCampaignViewPage}
                resources={{ ...permission(Resource.Message, Permission.read) }}
            />
            {/* Merchant Settings */}
            <RestrictedRoute
                path={routes.MERCHANT_CONFIG}
                component={AccessManagementPage}
                resources={{ ...permission(Resource.Tenant, Permission.write) }}
            />
            <Redirect exact from={routes.MERCHANT_CONFIG_EMPTY} to={routes.MERCHANT_CONFIG_USER} />
            <RestrictedRoute
                path={routes.MERCHANT_SETTINGS_CLERKS}
                component={ClerksPage}
                resources={{
                    ...permission(
                        Resource.TerminalAccess,
                        Permission.read | Permission.write | Permission.delete
                    )
                }}
            />
            <RestrictedRoute
                path={routes.MERCHANT_SETTINGS_GLOBAL}
                component={GlobalSettingsPage}
                resources={{ ...permission(Resource.Setting, Permission.write) }}
            />
            <RestrictedRoute
                path={routes.MERCHANT_SETTINGS_BRANDING}
                component={BrandingSettingsPage}
                resources={{ ...permission(Resource.Setting, Permission.write) }}
            />
            <RestrictedRoute
                path={routes.MERCHANT_SETTINGS_ORDERING}
                component={OrderingSettingsPage}
                resources={{ ...permission(Resource.Setting, Permission.write) }}
            />
            <RestrictedRoute
                path={routes.MERCHANT_SETTINGS_PAYMENTS}
                component={PaymentsSettingsPage}
                resources={{ ...permission(Resource.Setting, Permission.write) }}
            />
            <RestrictedRoute
                path={routes.MERCHANT_SETTINGS_MARKETING}
                component={MarketingSettingsPage}
                resources={{ ...permission(Resource.Setting, Permission.write) }}
            />
            <RestrictedRoute
                path={routes.MERCHANT_SETTINGS_LOCATIONS_AND_FAVOURITES}
                component={LocationsAndFavouritesSettingsPage}
                resources={{ ...permission(Resource.Setting, Permission.write) }}
            />
            <RestrictedRoute
                path={routes.MERCHANT_SETTINGS_POS}
                component={PointOfSaleSettingsPage}
                resources={{ ...permission(Resource.Setting, Permission.write) }}
            />
            <RestrictedRoute
                path={routes.MERCHANT_SETTINGS_TERMINAL}
                component={TerminalSettingsPage}
                resources={{ ...permission(Resource.Setting, Permission.write) }}
            />
            <RestrictedRoute
                path={routes.MERCHANT_MOBILE_SETTINGS}
                component={SettingsPage}
                resources={{ ...permission(Resource.Setting, Permission.write) }}
            />
            <RestrictedRoute
                path={routes.ORDERS}
                component={OrdersPage}
                resources={{ ...permission(Resource.Order, Permission.read) }}
                exact
            />
            <RestrictedRoute
                path={routes.ORDERS_VIEW}
                component={OrderViewPage}
                resources={{ ...permission(Resource.Order, Permission.read) }}
            />
            <RestrictedRoute
                path={routes.CONTENT_OVERWRITES}
                component={OverwritesPage}
                resources={{ ...permission(Resource.Menu, Permission.all) }}
            />
            <RestrictedRoute
                exact
                path={routes.MENU_MANAGER}
                component={MenuManager}
                resources={{ ...permission(Resource.Menu, Permission.read) }}
            />
            <RestrictedRoute
                exact
                path={routes.MENU_MANAGER_SCHEDULING}
                component={MenuScheduling}
                resources={{ ...permission(Resource.Menu, Permission.read) }}
            />
            {/* Pepper Pay */}
            <RestrictedRoute
                path={routes.PEPPER_PAY_TRANSACTIONS}
                component={TransactionsPage}
                exact
                resources={{ ...permission(Resource.Pay, Permission.read) }}
            />
            <RestrictedRoute
                path={routes.PEPPER_PAY_REPORTING_EMPTY}
                component={ReportingPage}
                resources={{ ...permission(Resource.PayReporting, Permission.read) }}
            />
            <RestrictedRoute
                path={routes.PEPPER_PAY_BLOCK_LIST}
                component={BlockList}
                exact
                resources={{ ...permission(Resource.Pay, Permission.read) }}
            />
            <RestrictedRoute
                path={routes.PEPPER_PAY_TRANSACTIONS_VIEW}
                component={ViewTransactionPage}
                resources={{ ...permission(Resource.Pay, Permission.read) }}
            />
            <RestrictedRoute
                path={routes.PEPPER_PAY_DISPUTES}
                component={DisputesPage}
                exact
                resources={{ ...permission(Resource.Pay, Permission.read) }}
            />
            <RestrictedRoute
                path={routes.PEPPER_PAY_DISPUTES_VIEW}
                component={ViewDisputePage}
                resources={{ ...permission(Resource.Pay, Permission.read) }}
            />
            <RestrictedRoute
                path={routes.TERMINAL}
                component={TerminalAccessPage}
                resources={{ ...permission(Resource.TerminalAccess, Permission.read) }}
            />
            <RestrictedRoute
                path={routes.TIERED_LOYALTY}
                component={TieredLoyalty}
                resources={{ ...permission(Resource.TieredLoyaltySchemes, Permission.read) }}
            />
            <RestrictedRoute
                path={routes.BIRTHDAY_LOYALTY}
                component={BirthdayLoyalty}
                resources={{ ...permission(Resource.BirthdayLoyaltySchemes, Permission.read) }}
            />
            {/* Fallback */}
            <StrangeRedirect exact from="/" to={routes.USER_MERCHANTS} />
        </Switch>
    </PrivateLayout>
);

const StrangeRedirect = (props: any) => <Redirect {...props} />;
