import { RuleResource } from 'components/rules/rules.types';

const subjectMap: Record<string, string> = {
    type: 'actionType',
    'context.location._id': 'locationId',
    'when.daypart': 'dayPart',
    'when.dayofweek': 'dayOfWeek',
    'when.weekofyear': 'weekOfYear',
    'when.monthofyear': 'monthOfYear',
    'context.user.primaryPlatform': 'primaryPlatform',
    'metadata.value.amount': 'amount',
    'metadata.order.scenario': 'scenario',
    'metadata.channel': 'channel',
    'metadata.audienceId': 'audienceId'
};

export function flattenRuleConditions(rule: Partial<Pick<RuleResource, 'conditions'>>): Record<string, any> {
    return rule?.conditions?.reduce((acc, condition) => {
        const { subject, predicate, object } = condition;
        const key = subjectMap[subject] || subject;
        const value = parseRuleConditionValue(key, object);
        return { ...acc, [key]: value, ...(key === 'amount' && { amountPredicate: predicate }) };
    }, {});
}

export function parseRuleConditionValue(key: string, value: any): string | string[] {
    switch (key) {
        case 'dayOfWeek':
        case 'monthOfYear':
            return value.toString();
        case 'dayPart':
            return value[0];
        default:
            return value;
    }
}
