import { Box, Button, Paper } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { useAudiences } from 'components/audience/hooks/useAudiences';
import { useTriggersModal } from 'components/audience/hooks/useTriggersModal';
import { AudienceAddTriggersModal } from 'components/audience/modals/AudienceAddTriggersModal';
import { AudienceCreateModal } from 'components/audience/modals/AudienceCreateModal';
import { getMetricFormatting } from 'components/audience/models/metrics';
import { Audience } from 'components/audience/models/segmentModel';
import { EmptyState } from 'components/utils/emptyState';

import { MARKETING_AUDIENCES_VIEW } from 'config/routes';
import { MainLayout } from 'layouts/MainLayout';
import { useSimpleModalState } from 'lib/hooks/useSimpleModalState';
import { defaultDateTimeValueFormatter } from 'lib/MuiGrid/helpers';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { useGridSearch } from 'lib/MuiGrid/useGridSearch';
import { getSecondsToDaysHours } from 'lib/timeHelpers';
import { isNumber } from 'lib/typeguards';
import React from 'react';
import { useHistory } from 'react-router-dom';
import EmptyImage from 'static/audience-empty-state.svg';
import { Row } from 'ui/Flex';

const emptyStateDescription =
    'Audiences unlock new ways to target marketing content at your customer base by grouping them based on their habits within the app.';

function expiryValueFormatter(value: number | string) {
    return isNumber(value) ? getSecondsToDaysHours(value) : '-';
}

export const AudiencesPage: React.FC = () => {
    const { loading, presentMetrics, audiences } = useAudiences();
    const {
        handleAddTriggersClose,
        handleAddTriggersError,
        handleAddTriggersSuccess,
        initTriggersModal,
        addTriggersData
    } = useTriggersModal();
    const history = useHistory();
    const handleViewClick = React.useCallback(
        (params: GridRowParams) => {
            history.push(MARKETING_AUDIENCES_VIEW.replace(':audienceId', String(params?.id)));
        },
        [history]
    );
    const columns = React.useMemo<GridColDef[]>(() => {
        const permanentColumns: GridColDef[] = [
            {
                headerName: 'Audience ID',
                field: 'id',
                disableReorder: true,
                disableExport: true,
                sortable: false,
                width: 240
            },
            {
                headerName: 'Title',
                field: 'title',
                width: 240
            },
            {
                headerName: 'Description',
                field: 'description',
                width: 200
            },
            {
                headerName: 'Type',
                field: 'type',
                width: 150
            },
            {
                headerName: 'Customers',
                field: 'count',
                align: 'right',
                headerAlign: 'left',
                width: 150
            },
            {
                headerName: 'Expiry Time',
                align: 'right',
                headerAlign: 'left',
                field: 'expiryHorizon',
                width: 160,
                valueFormatter: expiryValueFormatter
            },
            {
                headerName: 'Created',
                field: 'updated',
                width: 180,
                type: 'dateTime',
                valueFormatter: defaultDateTimeValueFormatter
            }
        ];
        if (presentMetrics.length > 0) {
            presentMetrics.forEach(item => {
                const { title, valueFormatter } = getMetricFormatting(item);
                permanentColumns.push({
                    headerName: title,
                    align: 'right',
                    headerAlign: 'left',
                    type: 'number',
                    field: item,
                    width: 250,
                    valueFormatter
                });
            });
        }
        return permanentColumns;
    }, [presentMetrics]);
    const [addAudienceOpen, openModal, closeModal] = useSimpleModalState();

    const handleEmptyStateClick = React.useCallback(() => {
        openModal();
    }, [openModal]);

    const handleAddAudienceCreateSuccess = React.useCallback(
        (audienceData: Audience) => {
            closeModal();
            initTriggersModal(audienceData);
        },
        [closeModal, initTriggersModal]
    );

    const gridSearchOptions = React.useMemo(
        () => [{ column: 'id' }, { column: 'title' }, { column: 'description' }, { column: 'type' }],
        []
    );
    const { filterModel, renderInput } = useGridSearch(
        gridSearchOptions,
        'Search by ID, title, description or type'
    );

    if (!loading && (!Array.isArray(audiences) || audiences.length === 0)) {
        return (
            <React.Fragment>
                <EmptyState
                    headerText="Group your customers into audiences"
                    paragraphText={emptyStateDescription}
                    buttonText="Create an audience"
                    imageUrl={EmptyImage}
                    onClick={handleEmptyStateClick}
                />
                <AudienceCreateModal
                    open={addAudienceOpen}
                    onClose={closeModal}
                    onSuccess={handleAddAudienceCreateSuccess}
                />
                <AudienceAddTriggersModal
                    {...addTriggersData}
                    onClose={handleAddTriggersClose}
                    onSuccess={handleAddTriggersSuccess}
                    onError={handleAddTriggersError}
                />
            </React.Fragment>
        );
    }
    return (
        <MainLayout
            pageName="Audiences"
            pageDescription="Group users together based on their activity."
            noScroll
        >
            <>
                <Box paddingY={1}>
                    <Row align="space-between">
                        {renderInput()}
                        <Button variant="contained" size="small" onClick={openModal} disabled={loading}>
                            Create audience
                        </Button>
                    </Row>
                </Box>
                <Paper sx={{ flex: 1, display: 'flex', minHeight: 0 }}>
                    <MuiGrid
                        sx={{
                            borderRadius: 0,
                            borderBottom: 'none',
                            borderLeft: 'none',
                            borderRight: 'none'
                        }}
                        rows={audiences}
                        columns={columns}
                        hideFooter={false}
                        pagination
                        loading={loading}
                        pageSizeOptions={[100]}
                        filterModel={filterModel}
                        onRowClick={handleViewClick}
                        disableColumnFilter
                        storageName={GridStorageName.Audiences}
                        enableRowClick
                    />
                </Paper>
            </>
            <AudienceCreateModal
                open={addAudienceOpen}
                onClose={closeModal}
                onSuccess={handleAddAudienceCreateSuccess}
            />
            <AudienceAddTriggersModal
                {...addTriggersData}
                onClose={handleAddTriggersClose}
                onSuccess={handleAddTriggersSuccess}
                onError={handleAddTriggersError}
            />
        </MainLayout>
    );
};
