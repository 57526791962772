import React from 'react';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { MultiSelectSuggest } from 'lib/MultiSelectSuggest';
import { Option } from 'lib/types';
import { ValueType } from 'react-select';

interface MultiSelectFormFieldProps {
    label?: React.ReactNode;
    isCreatable?: boolean;
    isMulti?: boolean;
    placeholder?: string;
    disabled?: boolean;
    options: Option[];
    description?: React.ReactNode;
}

export const MultiSelectFormField: React.FC<FieldAttributes<MultiSelectFormFieldProps>> = ({
    label,
    description,
    isMulti,
    disabled,
    isCreatable,
    options,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <MultiSelectFormFieldComponent
                label={label}
                description={description}
                isMulti={isMulti}
                disabled={disabled}
                isCreatable={isCreatable}
                options={options}
                {...childProps}
            />
        )}
    </Field>
);

const MultiSelectFormFieldComponent: React.FC<FieldProps & MultiSelectFormFieldProps> = ({
    description,
    placeholder,
    isMulti,
    isCreatable,
    field,
    disabled,
    meta,
    label,
    options
}) => {
    const isError = meta.touched && !!meta.error;
    const handleChange = React.useCallback(
        (value: ValueType<Option, true> | ValueType<Option, false>) => {
            field.onChange({ target: { value, name: field.name } });
        },
        [field]
    );
    return (
        <MultiSelectSuggest
            label={label}
            value={field.value}
            isMulti={isMulti}
            disabled={disabled}
            isCreatable={isCreatable}
            placeholder={placeholder}
            name={field.name}
            onBlur={field.onBlur}
            error={isError}
            helperText={isError ? meta.error : description}
            onChange={handleChange}
            options={options}
        />
    );
};
