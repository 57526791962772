import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MainLayout } from 'layouts/MainLayout';
import { customerApi } from 'components/customers/customerApi';
import { CustomerView } from 'components/customers/CustomerView';
import { Award, isAward } from 'components/customers/models/Award';
import { Customer, isCustomer } from 'components/customers/models/Customer';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { CUSTOMERS } from 'config/routes';
import { isArrayOf, isDefined } from 'lib/typeguards';
import { Throbber } from 'ui/Throbber';
import { IUserDeletionSetting } from '@pepperhq/location-sdk';

interface CustomersData {
    customer: Customer;
    awards: Award[];
    currency: string;
    paymentMode: string;
    currencySymbol: string;
    uniqueDeviceVerification: boolean;
    userDeletion: Partial<IUserDeletionSetting>;
}

const CustomersViewPage = () => {
    const [customerData, setCustomerData] = React.useState({} as CustomersData);
    const history = useHistory();
    const { customerId } = useParams<{ customerId: string }>();
    const loadCustomer = React.useCallback(async () => {
        const customerResponse = await customerApi.getUser(customerId, [
            { key: 'include', value: 'credentials, segments, balance, resetUniqueDeviceVerification' }
        ]);
        let customer;
        let awards: Award[] = [];
        if (customerResponse.ok) {
            customer = customerResponse.body;
        }
        if (!isCustomer(customer)) {
            history.replace(CUSTOMERS);
        }
        const awardsResponse = await customerApi.getAwards(customerId);
        if (awardsResponse.ok && awardsResponse.body && isArrayOf(isAward, awardsResponse.body.awards)) {
            awards = awardsResponse.body.awards.map((item: Award) => {
                if (!isDefined(item.title) || item.title.trim() === '') {
                    return { ...item, title: item.internalTitle ?? '' };
                }
                return item;
            });
        }
        const settings = await new LocationSettingsApi().getPublicSettings();
        const paymentMode = settings.payments?.mode; // we need to exclude PAYG credit
        const currency = settings.region?.currencyCode; // we need to show currency
        const currencySymbol = settings.region?.currencySymbol; // we need to show currency
        const uniqueDeviceVerification = settings.uniqueDeviceVerificationEnabled;
        setCustomerData({
            customer,
            awards,
            paymentMode,
            currency,
            currencySymbol,
            uniqueDeviceVerification,
            userDeletion: settings.userDeletion as Partial<IUserDeletionSetting>
        });
    }, [customerId, history]);
    React.useEffect(() => {
        loadCustomer();
    }, [loadCustomer]);
    const {
        awards,
        currency,
        paymentMode,
        userDeletion,
        currencySymbol,
        customer,
        uniqueDeviceVerification
    } = customerData;
    if (!customer) {
        return (
            <MainLayout
                breadcrumbs={[{ label: 'Customers', url: CUSTOMERS }]}
                pageName="User Name"
                pageDescription="View and interact with your customers."
            >
                <Throbber />
            </MainLayout>
        );
    }
    return (
        <MainLayout
            breadcrumbs={[{ label: 'Customers', url: CUSTOMERS }]}
            pageName={customer.fullName}
            pageDescription="View and interact with your customers."
        >
            <CustomerView
                awards={awards}
                currency={currency}
                paymentMode={paymentMode}
                currencySymbol={currencySymbol}
                customer={customer}
                userDeletionSetting={userDeletion}
                uniqueDeviceVerification={uniqueDeviceVerification}
                loadCustomer={loadCustomer}
            />
        </MainLayout>
    );
};

export default CustomersViewPage;
