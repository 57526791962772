import * as React from 'react';
import { useFormikContext } from 'formik';
import { Grid, Box, Typography, styled } from '@mui/material';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { Option } from 'lib/types';
import { isDefined } from 'lib/typeguards';
import { PerkDiscountScopeType } from 'components/loyalty/models/PerkModel';
import { AdjustmentSchemeRedemptionFullFormData } from './AdjustmentSchemeRedemptionForm';

const discountRules: Option[] = [
    {
        value: 'ALL',
        label: 'all'
    },
    {
        value: 'CHEAPEST',
        label: 'the cheapest'
    },
    {
        value: 'COSTLIEST',
        label: 'the costliest'
    },
    {
        value: 'ALL_ALLOCATE',
        label: 'the allocated'
    }
];

const StyledSelectFormField = styled(SelectFormField)(() => ({
    width: 'auto'
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}));

interface AdjustmentSchemeDiscountFormProps {
    discountScopeOptions: Option[];
    currencySymbol?: string;
    posProvider: string;
    showChildItemRule: boolean;
    getChildItemRuleOptions: (posProvider: string, scope?: PerkDiscountScopeType) => Option[];
}

const scopeOptionsLabels: Record<PerkDiscountScopeType, string> = {
    ITEM: 'product',
    CATEGORY: 'category',
    BASKET: 'entire basket',
    ITEM_OR_CATEGORY: 'category and/or product',
    CHILD_ITEM: 'modifier product',
    ITEM_AND_CHILD_ITEM: 'product and modifier product'
};

export const AdjustmentSchemeDiscountForm: React.FC<AdjustmentSchemeDiscountFormProps> = ({
    discountScopeOptions,
    currencySymbol,
    posProvider,
    showChildItemRule,
    getChildItemRuleOptions
}) => {
    const { values, setFieldValue } = useFormikContext<AdjustmentSchemeRedemptionFullFormData>();

    const handleDiscountScopeSelect = React.useCallback(
        (value: any) => {
            if (value === 'CHILD_ITEM' || value === 'ITEM_AND_CHILD_ITEM') {
                setFieldValue('discount.childItemRule', 'EXCLUDE');
            } else {
                setFieldValue('discount.childItemRule', undefined);
            }
        },
        [setFieldValue]
    );

    const discountRulesPerDiscountType = React.useMemo(
        () =>
            values.discount?.type === 'PERCENTAGE'
                ? discountRules.filter(rule => rule.value !== 'ALL_ALLOCATE')
                : discountRules,
        [values]
    );

    const types: Option[] = React.useMemo(
        () => [
            {
                value: 'VALUE',
                label: currencySymbol || 'Value'
            },
            {
                value: 'PERCENTAGE',
                label: '%'
            }
        ],
        [currencySymbol]
    );

    return (
        <Grid item xs={12}>
            <Box display="flex" flexDirection="column" marginBottom={1.5}>
                <StyledTitle>2.1 Setting up the discount</StyledTitle>
                <Typography variant="caption">
                    This section controls the discountable items. It determines based on the rules that are
                    supplied to it, what items should be discounted and by how much
                </Typography>
            </Box>
            <Grid container spacing={1.5}>
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center" columnGap="4px">
                        <Typography variant="body2">This adjustment scheme applies a</Typography>
                        <Box width={values.discount?.type === 'OVERRIDE' ? 130 : 40}>
                            <SelectFormField options={types} name="discount.type" />
                        </Box>
                        {values.discount?.type === 'OVERRIDE' && (
                            <Typography variant="body2">of {currencySymbol ?? ''}</Typography>
                        )}
                        <Box width={44}>
                            <TextFormField name="discount.value" type="number" />
                        </Box>
                        <Typography variant="body2">
                            {values.discount?.type === 'OVERRIDE' ? 'to' : 'discount to'}
                        </Typography>
                        <Box>
                            <StyledSelectFormField
                                options={discountScopeOptions}
                                name="discount.scope"
                                onSelect={handleDiscountScopeSelect}
                            />
                        </Box>
                    </Box>
                </Grid>
                {isDefined(values.discount?.scope) &&
                    isDefined(values.discount?.type) &&
                    isDefined(values.discount?.value) &&
                    values.discount?.scope !== 'BASKET' && (
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center" columnGap="4px">
                                <Typography variant="body2">
                                    {`${
                                        values.discount.type === 'PERCENTAGE'
                                            ? `${values.discount.value}%`
                                            : `${currencySymbol}${values.discount.value}`
                                    }`}{' '}
                                    off is redeemed on
                                </Typography>
                                <Box>
                                    <StyledSelectFormField
                                        options={discountRulesPerDiscountType}
                                        name="discount.rule"
                                    />
                                </Box>
                                <Typography variant="body2">product(s) in the basket</Typography>
                            </Box>
                        </Grid>
                    )}
                {isDefined(values.discount?.rule) &&
                    values.discount?.scope !== 'BASKET' &&
                    showChildItemRule && (
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center" columnGap="4px">
                                <Typography variant="body2">The cost of modifier products are</Typography>
                                <Box>
                                    <StyledSelectFormField
                                        options={getChildItemRuleOptions(posProvider, values.discount?.scope)}
                                        name="discount.childItemRule"
                                        disabled={
                                            values.discount?.scope === 'CHILD_ITEM' ||
                                            values.discount?.scope === 'ITEM_AND_CHILD_ITEM'
                                        }
                                    />
                                </Box>
                                <Typography variant="body2">when calculating the discount</Typography>
                            </Box>
                        </Grid>
                    )}
                {values.discount?.scope !== 'BASKET' &&
                    (values.discount?.childItemRule ||
                        (isDefined(values.discount?.rule) && !showChildItemRule)) && (
                        <Grid item xs={12}>
                            <Box display="inline" lineHeight="2">
                                <Typography variant="body2" display="inline">
                                    Assign the adjustment scheme code &ldquo;
                                </Typography>
                                <Box width={250} display="inline-block">
                                    <TextFormField
                                        name="discount.scopeMatchCode"
                                        placeholder={'e.g. free drink, free hot drink'}
                                        multiline={true}
                                        maxRows={4}
                                    />
                                </Box>
                                <Typography variant="body2" display="inline">
                                    &rdquo; to the {scopeOptionsLabels[values.discount.scope]} in your menu
                                    via the menu manager page under the Perks column in the table
                                </Typography>
                            </Box>
                        </Grid>
                    )}
            </Grid>
        </Grid>
    );
};
