import { SettingDisplay } from 'lib/SettingDisplay';
import { Box, Chip, ListItem } from '@mui/material';
import React from 'react';
import { Option } from 'lib/types';

interface SettingDisplayChipsProps {
    title: string;
    description?: string;
    value: Option[];
}

export const SettingDisplayChips: React.FC<SettingDisplayChipsProps> = ({ title, description, value }) => {
    const renderedChips = React.useMemo(
        () =>
            value.length ? (
                value.map((value, index) => (
                    <ListItem key={index} sx={{ px: 0, flex: 1 }}>
                        <Chip label={value.label} color="primary" />
                    </ListItem>
                ))
            ) : (
                <ListItem sx={{ px: 0, flex: 1 }}>
                    <Chip label="None" color="primary" />
                </ListItem>
            ),
        [value]
    );

    return (
        <SettingDisplay label={title} description={description}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    columnGap: 1,
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    m: 0
                }}
                component="ul"
            >
                {renderedChips}
            </Box>
        </SettingDisplay>
    );
};

interface SettingDisplayChipProps {
    title: string;
    description?: string;
    value: Option;
}

export const SettingDisplayChip: React.FC<SettingDisplayChipProps> = ({ title, description, value }) => (
    <SettingDisplay label={title} description={description}>
        <Chip label={value?.label ?? 'None'} color="primary" />
    </SettingDisplay>
);
