import * as React from 'react';

import { Box, Button, Paper, styled } from '@mui/material';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import Add from '@mui/icons-material/Add';

import { renderLocationsCell } from 'components/perks/PerkLocationsCell';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { AdjustmentScheme } from '../adjustmentSchemeModel';
import { useGridSearch } from 'lib/MuiGrid/useGridSearch';

interface AdjustmentSchemeTableProps {
    loading: boolean;
    schemes: AdjustmentScheme[];
    count: number;
    selectedRow: GridRowSelectionModel;
    selectedScheme: AdjustmentScheme | undefined;
    handleSelectRow: (newSelectedRow: GridRowSelectionModel) => void;
    onCreateClick: () => void;
    onUpdateClick: (scheme: AdjustmentScheme) => void;
    onDeleteClick: (scheme: AdjustmentScheme) => void;
}

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
    alignItems: 'flex-end'
}));

const SEARCHABLE_FIELDS: Array<{ column: string }> = [
    { column: 'id' },
    { column: 'schemeId' },
    { column: 'title' },
    { column: 'internalTitle' }
];

export const AdjustmentSchemesTable: React.FC<AdjustmentSchemeTableProps> = ({
    selectedRow,
    selectedScheme,
    handleSelectRow,
    loading,
    schemes,
    count,
    onCreateClick,
    onUpdateClick,
    onDeleteClick
}) => {
    const { filterModel, renderInput } = useGridSearch(SEARCHABLE_FIELDS, 'Search by ID or title');

    const columns = React.useMemo<GridColDef[]>(
        () => [
            { headerName: 'ID', field: 'id', width: 340 },
            {
                headerName: 'Title',
                field: 'title',
                width: 240
            },
            {
                headerName: 'Internal Title',
                field: 'internalTitle',
                width: 240,
                renderCell: params => params.value || '-'
            },
            {
                headerName: 'Live',
                field: 'enabled',
                type: 'boolean'
            },
            {
                headerName: 'Scheme ID',
                field: 'schemeId',
                renderCell: params => params.value || '-'
            },
            {
                headerName: 'POS Adjustment Code',
                field: 'externalPosId',
                renderCell: params => params.value || '-'
            },
            {
                headerName: 'Locations',
                field: 'locations',
                width: 240,
                renderCell: renderLocationsCell,
                sortable: false
            }
        ],
        []
    );

    const rows = React.useMemo(
        () =>
            schemes.map(item => ({
                ...item,
                locations: 'locationIds' in item ? item.locationIds : []
            })),
        [schemes]
    );

    const handleCreateClick = React.useCallback(() => {
        onCreateClick();
    }, [onCreateClick]);

    const handleUpdateClick = React.useCallback(() => {
        if (selectedScheme) {
            onUpdateClick(selectedScheme);
        }
    }, [selectedScheme, onUpdateClick]);

    const handleDeleteClick = React.useCallback(() => {
        if (selectedScheme) {
            onDeleteClick(selectedScheme);
        }
    }, [selectedScheme, onDeleteClick]);

    return (
        <>
            <StyledBox>
                <Box sx={{ marginRight: 'auto' }}>{renderInput()}</Box>
                {!selectedScheme && (
                    <Button onClick={handleCreateClick} startIcon={<Add />} variant="contained" size="small">
                        Create
                    </Button>
                )}
                <Button
                    disabled={!selectedScheme}
                    onClick={handleUpdateClick}
                    variant="outlined"
                    size="small"
                >
                    Update
                </Button>
                <Button
                    disabled={!selectedScheme}
                    onClick={handleDeleteClick}
                    variant="outlined"
                    size="small"
                >
                    Delete
                </Button>
            </StyledBox>
            <Paper sx={{ flex: 1, display: 'flex', minHeight: 0 }}>
                <MuiGrid
                    rows={rows}
                    columns={columns}
                    loading={loading}
                    rowSelectionModel={selectedRow}
                    onRowSelectionModelChange={handleSelectRow}
                    disableMultipleRowSelection
                    storageName={GridStorageName.AdjustmentSchemes}
                    hideFooter={false}
                    rowCount={count}
                    disableRowSelectionOnClick={false}
                    filterModel={filterModel}
                    disableColumnFilter
                />
            </Paper>
        </>
    );
};
