import React from 'react';
import { useScheduledTasks } from 'components/scheduled-tasks/hooks/useScheduledTasks';
import { useScheduledTasksFilter } from 'components/scheduled-tasks/hooks/useScheduledTasksFilter';
import { GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import {
    ScheduledTask,
    ScheduledTaskStatus,
    ScheduledTaskType
} from 'components/scheduled-tasks/scheduledTasks.types';
import { Box, Button, Paper, Stack, TablePagination } from '@mui/material';
import { DEFAULT_PAGE_LIMIT } from 'lib/hooks/usePagedData';
import { MuiTheme } from 'config/theme';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { ScheduledTaskFilters } from 'components/scheduled-tasks/filters/ScheduledTaskFilters';
import { renderScheduledTaskStatusCell } from '../ScheduledTaskStatusCell';
import { CAMPAIGNS_SCHEDULED_VIEW } from 'config/routes';
import { CustomerSegment } from 'components/customers/models/Customer';
import { Location } from 'components/location/models/LocationModel';
import { MultiSelectGridColumn, SelectGridColumn } from 'lib/MuiGrid/Columns';
import { ScheduledTaskTypeLabelMap } from 'components/scheduled-tasks/helpers';
import { deepLinkValueFormatter } from 'components/notification/helpers';
import { defaultDateTimeValueFormatter } from 'lib/MuiGrid/helpers';
import { Row } from 'ui/Flex';
import { useGridSingleSelection } from 'lib/MuiGrid/useGridSingleSelection';
import { useHistory } from 'react-router-dom';

interface ScheduledTasksTableProps {
    isLoading: boolean;
    onCreateClick: (scheduledTask?: ScheduledTask<any>) => void;
    onUpdateClick: (scheduledTask: ScheduledTask<any>) => void;
    onDeleteClick: (scheduledTask: ScheduledTask<any>) => void;
    actionedScheduledTask: { id: string; status: 'created' | 'updated' | 'deleted' } | null;
    segments: CustomerSegment[];
    locations: Location[];
}

export const ScheduledTasksTable: React.FC<ScheduledTasksTableProps> = ({
    isLoading,
    onCreateClick,
    onUpdateClick,
    onDeleteClick,
    actionedScheduledTask,
    segments,
    locations
}) => {
    const history = useHistory();
    const [filter, onFilterChange, isEmptyFilter] = useScheduledTasksFilter(
        ScheduledTaskType.CREATE_PUSH_NOTIFICATION
    );
    const {
        page,
        onPageChange,
        loading,
        count,
        formattedScheduledTasks,
        sortModel,
        onSortModelChange,
        scheduledTasks,
        reset,
        noData
    } = useScheduledTasks(filter, isEmptyFilter);
    const locationTitleMap = React.useMemo(
        () => locations?.reduce((acc, l) => ({ ...acc, [l._id]: l.title || l._id }), {}),
        [locations]
    );
    const segmentTitleMap = React.useMemo(
        () => segments?.reduce((acc, s) => ({ ...acc, [s._id]: s.title || s._id }), {}),
        [segments]
    );
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'ID',
                field: 'id',
                disableReorder: true,
                disableExport: true,
                sortable: false,
                width: 240
            },
            {
                headerName: 'Created',
                field: 'createdAt',
                sortable: true,
                type: 'dateTime',
                width: 180,
                valueGetter: defaultDateTimeValueFormatter
            },
            {
                headerName: 'Updated',
                field: 'updatedAt',
                sortable: true,
                type: 'dateTime',
                width: 180,
                valueGetter: defaultDateTimeValueFormatter
            },
            {
                headerName: 'Type',
                field: 'type',
                sortable: false,
                width: 180,
                ...SelectGridColumn(ScheduledTaskTypeLabelMap)
            },
            {
                headerName: 'Status',
                field: 'status',
                sortable: false,
                width: 90,
                renderCell: renderScheduledTaskStatusCell
            },
            {
                headerName: 'Send On',
                field: 'triggerAt',
                sortable: true,
                type: 'dateTime',
                width: 180,
                valueGetter: defaultDateTimeValueFormatter
            },
            {
                headerName: 'Title',
                field: 'parameters.title',
                sortable: false,
                width: 180
            },
            {
                headerName: 'Message',
                field: 'parameters.message',
                sortable: false,
                width: 360
            },
            {
                headerName: 'Tag',
                field: 'parameters.tag',
                sortable: true,
                width: 180
            },
            {
                headerName: 'Locations',
                field: 'parameters.locationIds',
                sortable: false,
                width: 220,
                ...MultiSelectGridColumn(locationTitleMap, '')
            },
            {
                headerName: 'Audiences',
                field: 'parameters.segmentIds',
                sortable: false,
                width: 220,
                ...MultiSelectGridColumn(segmentTitleMap, '')
            },
            {
                headerName: 'Deep Link',
                field: 'parameters.deepLink',
                sortable: false,
                width: 90,
                valueGetter: deepLinkValueFormatter
            }
        ],
        [locationTitleMap, segmentTitleMap]
    );
    const [selectedTask, rowSelectionModel, handleSelectionChange] = useGridSingleSelection(scheduledTasks);
    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            onPageChange(page);
            handleSelectionChange([]);
        },
        [handleSelectionChange, onPageChange]
    );
    const handleSortChange = React.useCallback(
        (model: GridSortModel) => {
            onSortModelChange(model);
            handleSelectionChange([]);
        },
        [handleSelectionChange, onSortModelChange]
    );

    React.useEffect(() => {
        handleSelectionChange([]);
        reset();
    }, [actionedScheduledTask, handleSelectionChange, reset]);

    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={count}
                onPageChange={handlePageChange}
                page={page}
                disabled={isLoading || loading}
                rowsPerPage={DEFAULT_PAGE_LIMIT}
                rowsPerPageOptions={[DEFAULT_PAGE_LIMIT]}
                component="div"
                sx={{
                    '&:last-child': {
                        paddingRight: MuiTheme.spacing(1)
                    }
                }}
            />
        ),
        [count, handlePageChange, page, isLoading, loading]
    );
    const NoRowsOverlay = React.useCallback(
        () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
                {isEmptyFilter && !isLoading && !loading && !count
                    ? 'No schedules created'
                    : 'No notifications found. Please adjust your filter'}
            </Stack>
        ),
        [count, isEmptyFilter, isLoading, loading]
    );

    const handleCreateClick = React.useCallback(() => {
        onCreateClick(selectedTask);
    }, [onCreateClick, selectedTask]);
    const handleUpdateClick = React.useCallback(() => {
        onUpdateClick(selectedTask);
    }, [onUpdateClick, selectedTask]);
    const handleDeleteClick = React.useCallback(() => {
        onDeleteClick(selectedTask);
    }, [onDeleteClick, selectedTask]);

    const handleView = React.useCallback(() => {
        history.push(CAMPAIGNS_SCHEDULED_VIEW.replace(':scheduleId', selectedTask?.id));
    }, [history, selectedTask?.id]);

    const createEnabled = React.useMemo(
        () =>
            !selectedTask ||
            [
                ScheduledTaskStatus.PROCESSING,
                ScheduledTaskStatus.PROCESSED,
                ScheduledTaskStatus.ERROR
            ].includes(selectedTask.status),
        [selectedTask]
    );
    const deleteEnabled = React.useMemo(
        () =>
            selectedTask &&
            [ScheduledTaskStatus.DRAFT, ScheduledTaskStatus.SCHEDULED, ScheduledTaskStatus.QUEUED].includes(
                selectedTask.status
            ),
        [selectedTask]
    );
    const updateEnabled = React.useMemo(
        () =>
            selectedTask &&
            [ScheduledTaskStatus.DRAFT, ScheduledTaskStatus.SCHEDULED].includes(selectedTask.status),
        [selectedTask]
    );

    React.useEffect(() => {
        if (noData) {
            handleCreateClick();
        }
    }, [handleCreateClick, noData]);

    return (
        <React.Fragment>
            <Box paddingY={1}>
                <Row align="space-between" valign="flex-end" gutter>
                    <ScheduledTaskFilters filter={filter} onChange={onFilterChange} />
                    <LoadingButton
                        variant="contained"
                        onClick={handleCreateClick}
                        loading={isLoading}
                        disabled={isLoading || !createEnabled}
                        size="small"
                        sx={{ minWidth: 80 }}
                    >
                        {!selectedTask ? 'Create' : 'Resend'}
                    </LoadingButton>
                    <Button
                        variant="contained"
                        onClick={handleView}
                        size="small"
                        disabled={isLoading || !selectedTask}
                    >
                        View
                    </Button>
                    <LoadingButton
                        variant="outlined"
                        onClick={handleUpdateClick}
                        loading={isLoading}
                        disabled={isLoading || !updateEnabled}
                        size="small"
                    >
                        Update
                    </LoadingButton>
                    <LoadingButton
                        variant="outlined"
                        color="error"
                        onClick={handleDeleteClick}
                        loading={isLoading}
                        disabled={isLoading || !deleteEnabled}
                        size="small"
                    >
                        Delete
                    </LoadingButton>
                </Row>
            </Box>
            <Paper sx={{ flex: 1, display: 'flex', minHeight: 0 }}>
                <MuiGrid
                    rows={formattedScheduledTasks}
                    columns={columns}
                    hideFooter={false}
                    rowCount={count}
                    pagination
                    storageName={GridStorageName.ScheduledTasks}
                    disableColumnFilter
                    paginationMode="server"
                    loading={loading}
                    sortModel={sortModel}
                    onSortModelChange={handleSortChange}
                    sortingMode="server"
                    disableMultipleRowSelection
                    disableMultipleColumnsSorting
                    disableRowSelectionOnClick={false}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={handleSelectionChange}
                    slots={{
                        pagination: Pagination,
                        noRowsOverlay: NoRowsOverlay
                    }}
                />
            </Paper>
        </React.Fragment>
    );
};
