import { IconButton, Typography, TypographyVariant } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_COPY_TO_CLIPBOARD_SUCCESS } from 'config/messages';
import { copyToClipboard } from 'lib/helpers';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Row } from 'ui/Flex';

export interface RowCopyProps {
    value: string;
    text?: string;
    variant?: TypographyVariant;
    color?: string;
}

export const RowCopy: React.FC<RowCopyProps> = ({ value, text, variant, color }) => {
    const dispatch = useDispatch();
    const handleClipboardClick = React.useCallback(() => {
        copyToClipboard(value);
        dispatch(
            enqueueSnackbar(MESSAGE_COPY_TO_CLIPBOARD_SUCCESS.replace('{{something}}', value), {
                variant: 'default'
            })
        );
    }, [dispatch, value]);

    return (
        <Row valign="center" align="flex-start">
            <Typography variant={variant} justifyContent="center" color={color}>
                {text ? `${text}: ` : ''}
                {value}
            </Typography>
            <IconButton size="small" onClick={handleClipboardClick}>
                <ContentCopyIcon />
            </IconButton>
        </Row>
    );
};
