import { Button, styled } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import clsx from 'clsx';

const PREFIX = 'EditCell';

const classes = {
    button: `${PREFIX}-button`,
    deletedButton: `${PREFIX}-deletedButton`
};

const StyledButton = styled(Button)(({ theme }) => ({
    [`&.${classes.button}`]: {
        color: theme.palette.divider,
        '.MuiDataGrid-row:hover &': {
            color: theme.palette.primary.main
        }
    },
    [`&.${classes.deletedButton}`]: {
        color: theme.palette.primary.main
    }
}));

interface EditCellProps {
    params: GridRenderEditCellParams;
    onClick: (id: string | number) => void;
    deleted?: boolean;
}

export const EditCell: React.FC<EditCellProps> = ({ params, onClick, deleted }) => {
    const handleClick = React.useCallback(() => {
        onClick(params.id);
    }, [onClick, params.id]);
    return (
        <StyledButton
            variant="text"
            disabled={deleted}
            size="small"
            onClick={handleClick}
            className={clsx({ [classes.button]: true, [classes.deletedButton]: deleted })}
        >
            <EditIcon />
        </StyledButton>
    );
};
