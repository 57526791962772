import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, styled } from '@mui/material';
import { MainLayout } from 'layouts/MainLayout';
import { contentApi } from 'components/content/contentApi';
import { LayoutConfigStepper } from 'components/content/layout-config/LayoutConfigStepper';
import { LayoutPreview } from 'components/content/layout-config/LayoutPreview';
import {
    ContentModuleType,
    EHeightOption,
    Height,
    marketingHeightToDisplay,
    MarketingMessageItem
} from 'components/content/models/ContentModule';
import { ModulesConfiguration } from 'components/content/modules-config';
import {
    MESSAGE_MOBILE_CONTENT_CREATE_MODULE_SUCCESS,
    MESSAGE_MOBILE_CONTENT_UPDATE_MODULE_ERROR,
    MESSAGE_MOBILE_CONTENT_UPDATE_MODULE_SUCCESS
} from 'config/messages';
import { MOBILE_CONTENT, MOBILE_CONTENT_CONFIRMATION } from 'config/routes';
import { isString } from 'lib/typeguards';
import {
    addNewModule,
    deleteContentModule,
    deleteSelectedModule,
    getLayoutFromId,
    NEW_MODULE_ID,
    setCurrentLayoutSuccess,
    updateCurrentModuleSuccess,
    updateSelectedModule
} from 'store/content/contentActions';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { ApplicationState } from 'store/store';
import { Column, Row } from 'ui/Flex';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { AddModuleMenu } from 'components/content/modules-config/AddModuleMenu';

const PREFIX = 'mobile-module-config';

const classes = {
    row: `${PREFIX}-row`,
    preview: `${PREFIX}-preview`,
    nextStepButton: `${PREFIX}-nextStepButton`,
    config: `${PREFIX}-config`
};

const StyledRow = styled(Row)(({ theme }) => ({
    [`& .${classes.row}`]: {
        width: '100%'
    },
    [`& .${classes.preview}`]: {
        position: 'sticky',
        alignSelf: 'flex-start',
        height: 'auto',
        top: 0,
        paddingLeft: theme.spacing(4)
    },
    [`& .${classes.nextStepButton}`]: {
        marginTop: theme.spacing(1)
    },
    [`& .${classes.config}`]: {
        flexBasis: '35%',
        maxWidth: theme.spacing(62.5),
        minWidth: theme.spacing(62.5),
        alignItems: 'center',
        justifyItems: 'center',
        marginBottom: theme.spacing(5)
    }
}));

const ModuleConfigPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { currentLayout, currentModules, selectedModuleId } = useSelector(
        (state: ApplicationState) => state.content
    );
    const { layoutId } = useParams<{ layoutId: string }>();
    const [idToDelete, setIdToDelete] = React.useState<string>();
    const isUnsavedNewModule = React.useMemo(
        () => currentModules?.some(item => item.id === NEW_MODULE_ID),
        [currentModules]
    );

    React.useEffect(() => {
        async function load() {
            if (!currentLayout && isString(layoutId)) {
                await getLayoutFromId(layoutId)(dispatch);
            }
        }
        load();
    }, [dispatch, layoutId, currentLayout]);

    // Function to handle when a 'save' button is clicked
    const handleLayoutUpdate = React.useCallback(async () => {
        // Workaround for Redux State not updating in component
        const selectedModule = { ...currentModules.find(item => item.id === selectedModuleId) };
        const isNewModule = selectedModule.id === NEW_MODULE_ID;
        delete selectedModule.id;

        if (
            (Object.values(EHeightOption) as (EHeightOption | Height)[]).includes(
                selectedModule.display?.height
            )
        ) {
            selectedModule.display = marketingHeightToDisplay(selectedModule.display);
        }
        if (!selectedModule.secondaryAction?.action) {
            selectedModule.secondaryAction = undefined;
        }
        let indexedSelectedModule;
        if (selectedModule.type === ContentModuleType.MARKETING && selectedModule.items) {
            indexedSelectedModule = {
                ...selectedModule,
                items: selectedModule.items.map((item: MarketingMessageItem, index: number) => ({
                    ...item,
                    index
                }))
            };
        }
        if (isNewModule) {
            if (currentLayout) {
                try {
                    const newModule = await contentApi.modules.create({
                        body: indexedSelectedModule || selectedModule
                    });
                    if (!newModule.ok) {
                        throw new Error(newModule.body.error.message);
                    }
                    const layoutResult = await contentApi.layouts.update(currentLayout?.id, {
                        body: {
                            anchor: {
                                ...currentLayout.anchor,
                                moduleIds: [...currentLayout.anchor.moduleIds, newModule.body.id]
                            }
                        }
                    });
                    if (!layoutResult.ok) {
                        throw new Error(layoutResult.body.message);
                    }
                    dispatch(setCurrentLayoutSuccess(layoutResult.body));
                    dispatch(updateCurrentModuleSuccess(NEW_MODULE_ID, newModule.body));
                    updateSelectedModule(newModule.body.id)(dispatch);
                    dispatch(
                        enqueueSnackbar(MESSAGE_MOBILE_CONTENT_CREATE_MODULE_SUCCESS, { variant: 'success' })
                    );
                } catch (e) {
                    dispatch(enqueueSnackbar(e.message, { variant: 'error' }));
                }
            }
        } else {
            const res = await contentApi.modules.update(selectedModuleId, {
                body: indexedSelectedModule || selectedModule
            });
            if (res.ok) {
                dispatch(updateCurrentModuleSuccess(selectedModuleId, res.body));
                dispatch(
                    enqueueSnackbar(MESSAGE_MOBILE_CONTENT_UPDATE_MODULE_SUCCESS, { variant: 'success' })
                );
            } else {
                dispatch(enqueueSnackbar(MESSAGE_MOBILE_CONTENT_UPDATE_MODULE_ERROR, { variant: 'error' }));
            }
        }
    }, [currentLayout, currentModules, dispatch, selectedModuleId]);

    const moduleToDelete = React.useMemo(() => {
        if (idToDelete) {
            return currentModules.find(item => item.id === idToDelete);
        }
        return undefined;
    }, [currentModules, idToDelete]);

    const deleteContent = React.useMemo(() => {
        if (!moduleToDelete) {
            return '';
        }
        if (
            moduleToDelete.type === ContentModuleType.MARKETING ||
            moduleToDelete.type === ContentModuleType.PRIMARY_ACTION
        ) {
            // eslint-disable-next-line max-len
            return `Be aware you will be permanently deleting ${moduleToDelete.items.length} module item(s). This action cannot be reversed. To confirm deletion, type "DELETE" in the text input field.`;
        }
        // eslint-disable-next-line max-len
        return 'Be aware you will be permanently deleting this module. This action cannot be reversed. To confirm deletion, type "DELETE" in the text input field.';
    }, [moduleToDelete]);

    const handleDelete = React.useCallback(
        (id: string) => {
            if (id === NEW_MODULE_ID) {
                dispatch(deleteSelectedModule(id));
            } else {
                setIdToDelete(id);
            }
        },
        [dispatch]
    );

    const onDeleteSubmit = React.useCallback(async () => {
        await deleteContentModule(idToDelete, currentLayout)(dispatch);
        setIdToDelete(undefined);
    }, [currentLayout, dispatch, idToDelete]);

    const onDeleteClose = React.useCallback(() => {
        setIdToDelete(undefined);
    }, []);

    const handleNextStepClick = React.useCallback(() => {
        if (currentLayout) {
            history.push(`${MOBILE_CONTENT_CONFIRMATION.replace(/:layoutId/g, currentLayout.id)}`);
        }
    }, [history, currentLayout]);

    const moduleTypes = React.useMemo(() => {
        const types = [ContentModuleType.PRIMARY_ACTION, ContentModuleType.MARKETING];
        if (!currentModules?.some(item => item.type === ContentModuleType.LOCATIONS)) {
            types.push(ContentModuleType.LOCATIONS);
        }
        if (!currentModules?.some(item => item.type === ContentModuleType.AWARDS)) {
            types.push(ContentModuleType.AWARDS);
        }
        if (!currentModules?.some(item => item.type === ContentModuleType.FAVOURITES)) {
            types.push(ContentModuleType.FAVOURITES);
        }
        return types;
    }, [currentModules]);

    const handleAddNewModule = React.useCallback(
        (type: ContentModuleType) => addNewModule(type)(dispatch),
        [dispatch]
    );

    return (
        <MainLayout
            columnProps={{ align: 'center' }}
            breadcrumbs={[{ label: 'Mobile content', url: MOBILE_CONTENT }]}
            pageName="Module configuration"
            pageDescription="Configure and manage what appears in your application"
        >
            <LayoutConfigStepper />
            <StyledRow valign="center" align="center">
                <Column valign="center" align="center">
                    <Row valign="center" align="center">
                        <ModulesConfiguration
                            className={classes.config}
                            handleSubmit={handleLayoutUpdate}
                            onDelete={handleDelete}
                        />
                    </Row>
                </Column>
                <Column valign="center" align="center" className={classes.preview}>
                    <Row valign="center" align="center">
                        <LayoutPreview isSelectable={true} />
                    </Row>
                    <Row valign="center" align="center" className={classes.nextStepButton}>
                        <AddModuleMenu
                            disabled={isUnsavedNewModule}
                            enabledTypes={moduleTypes}
                            onClick={handleAddNewModule}
                        />
                    </Row>
                    <Row valign="center" align="center" className={classes.nextStepButton}>
                        <Button variant="outlined" color="primary" onClick={handleNextStepClick}>
                            Next Step
                        </Button>
                    </Row>
                </Column>
            </StyledRow>
            <DeleteDialog
                title="Delete module"
                content={deleteContent}
                protectionWord="DELETE"
                onClose={onDeleteClose}
                onDelete={onDeleteSubmit}
                open={!!moduleToDelete}
            />
        </MainLayout>
    );
};

export default ModuleConfigPage;
