import { isDateString, isEnumOfTypeCurried, isOptional, isOptionalString, isString } from 'lib/typeguards';
export enum NotificationReceiptStatus {
    SENT = 'sent',
    SKIPPED = 'skipped',
    ERROR = 'error'
}

export interface INotificationReceipt {
    _id: string;
    status?: NotificationReceiptStatus;
    notificationMessageId: string;
    userId: string;
    userFullName?: string;
    skipMessage?: string;
    errorMessage?: string;
    createdAt?: string;
    sentAt?: string;
}

export const isNotificationReceipt = (input: any): input is INotificationReceipt =>
    isString(input._id) &&
    isOptional(isEnumOfTypeCurried(NotificationReceiptStatus), input.status) &&
    isString(input.notificationMessageId) &&
    isString(input.userId) &&
    isOptionalString(input.userFullName) &&
    isOptionalString(input.skipMessage) &&
    isOptionalString(input.errorMessage) &&
    isDateString(input.createdAt);
