import { MenuItem, Select } from '@mui/material';
import { Option } from 'lib/types';
import React from 'react';
import { NotificationReceiptStatus } from '../models/NotificationReceipt';

const ANY_STATUS = 'ANY';

interface NotificationReceiptStatusFilterProps {
    disabled?: boolean;
    onChange: (value?: string) => void;
    value?: string;
}

export const NotificationReceiptStatusLabels: Record<NotificationReceiptStatus, string> = {
    [NotificationReceiptStatus.SENT]: 'Sent',
    [NotificationReceiptStatus.SKIPPED]: 'Skipped',
    [NotificationReceiptStatus.ERROR]: 'Error'
};

const options = Object.entries(NotificationReceiptStatusLabels).map(([key, value]) => ({
    label: value,
    value: key
}));

export const NotificationReceiptStatusFilter: React.FC<NotificationReceiptStatusFilterProps> = ({
    disabled,
    value,
    onChange
}) => {
    const handleStateChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === ANY_STATUS) {
                onChange(undefined);
            } else {
                onChange(e.target.value);
            }
        },
        [onChange]
    );
    const renderOption = React.useCallback(
        (option: Option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ),
        []
    );
    return (
        <Select
            disabled={disabled}
            value={value ?? ANY_STATUS}
            onChange={handleStateChange}
            variant="outlined"
        >
            <MenuItem value={ANY_STATUS}>All Statuses</MenuItem>
            {options.map(renderOption)}
        </Select>
    );
};
