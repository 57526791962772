import React from 'react';
import { MainLayout } from 'layouts/MainLayout';
import { Box, Tabs } from '@mui/material';
import { MARKETING_3RD_PARTY_PERKS, MARKETING_PEPPER_PERKS } from 'config/routes';
import { TabContext } from '@mui/lab';
import { PepperPerksView } from './PepperPerksView';
import { LinkTab } from 'ui/LinkTab';
import { useParams } from 'react-router-dom';
import { ExternalPerksView } from './ExternalPerksView';
import { contentApi } from 'components/content/contentApi';
import { ContentLayout } from 'components/content/models/ContentLayout';
import { setCurrentLayoutSuccess } from 'store/content/contentActions';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLocationTitles } from 'store/locations/locationsActions';
import { ApplicationState } from 'store/store';

const PerksPage = () => {
    const dispatch = useDispatch();
    const { type } = useParams<{ type: 'pepper' | 'external' | undefined }>();
    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const globalLoyaltyProvider = React.useMemo(() => settings?.loyaltyProvider, [settings?.loyaltyProvider]);
    React.useEffect(() => {
        getAllLocationTitles(dispatch);
    }, [dispatch]);

    React.useEffect(() => {
        async function load() {
            const activeLayoutResult = await contentApi.layouts.getActiveLayout();
            if (activeLayoutResult.ok) {
                const activeLayout = activeLayoutResult.body as ContentLayout;
                dispatch(setCurrentLayoutSuccess(activeLayout));
            }
        }
        load();
    }, [dispatch]);

    const [value, setValue] = React.useState(() => {
        if (type === 'external') {
            return MARKETING_3RD_PARTY_PERKS;
        }

        return MARKETING_PEPPER_PERKS;
    });

    const handleChange = React.useCallback((_: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    }, []);
    const hasExternalPerkSetting = React.useMemo(
        () =>
            !!globalLoyaltyProvider &&
            globalLoyaltyProvider !== 'PEPPER' &&
            globalLoyaltyProvider !== 'COMTREX',
        [globalLoyaltyProvider]
    );

    return (
        <MainLayout
            pageName="Perks"
            pageDescription="Incentivize your customers with loyalty schemes."
            noScroll
        >
            <TabContext value={value}>
                {hasExternalPerkSetting && (
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            variant="fullWidth"
                            value={value}
                            indicatorColor="primary"
                            onChange={handleChange}
                        >
                            {hasExternalPerkSetting && (
                                <LinkTab
                                    value={MARKETING_3RD_PARTY_PERKS}
                                    label={`${globalLoyaltyProvider}`}
                                    href={MARKETING_3RD_PARTY_PERKS}
                                />
                            )}
                            <LinkTab
                                value={MARKETING_PEPPER_PERKS}
                                label="Pepper"
                                href={MARKETING_PEPPER_PERKS}
                            />
                        </Tabs>
                    </Box>
                )}
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', pb: 6 }}>
                    {value === MARKETING_PEPPER_PERKS && <PepperPerksView />}
                    {!!globalLoyaltyProvider &&
                        globalLoyaltyProvider !== 'PEPPER' &&
                        value === MARKETING_3RD_PARTY_PERKS && (
                            <ExternalPerksView loyaltyProvider={globalLoyaltyProvider} />
                        )}
                </Box>
            </TabContext>
        </MainLayout>
    );
};

export default PerksPage;
