/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography } from '@mui/material';
import { Form } from 'formik';
import * as Yup from 'yup';
import { MuiForm } from 'lib/Form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';
import { IPerkBasketQualification, PerkDiscount } from 'components/loyalty/models/PerkModel';
import { LoyaltyScope } from 'components/loyalty/enums';
import { IAwardEnrichmentDateTimeRestriction } from 'components/loyalty/models/AwardEnrichment';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { Option } from 'lib/types';
import { DatePicker, DatePickerSlotProps } from '@mui/x-date-pickers-pro';
import { useLocalDateTimeFormat } from 'lib/hooks/useLocalDateTimeFormat';
import { isValid } from 'date-fns';
import { AwardFormStep, StepUpdateMetadata } from '../AwardEnrichmentEditStepper';
import { weekdays } from '../consts';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { isDefined, isEmptyString } from 'lib/typeguards';
import { SchemaLike } from 'yup/lib/types';
import { ConditionOptions } from 'yup/lib/Condition';

export interface AwardRedemptionFormData {
    token?: string;
    discount?: Partial<Omit<PerkDiscount, 'maxValue'>>;
    basketQualification?: Omit<IPerkBasketQualification, 'scope'> & {
        scope?: LoyaltyScope;
    };
    dateTimeRestriction: Omit<IAwardEnrichmentDateTimeRestriction, 'startTime' | 'daysOfWeek' | 'endTime'> & {
        startTime: string | undefined;
        endTime: string | undefined;
        daysOfWeek: string[];
    };
}

interface AwardRedemptionFormProps {
    onSubmit: (data: AwardRedemptionFormData) => void;
    initialValues?: AwardRedemptionFormData;
    open: boolean;
    isEdit?: boolean;
    isLoading?: boolean;
    isFormValid?: boolean;
    onPrevious?: (isValid?: boolean, values?: AwardRedemptionFormData) => void;
    clickPrevious: () => void;
    onUpdate?: (values: StepUpdateMetadata) => void;
    auto?: boolean;
}

const scopeOptions: Option[] = [
    {
        value: 'ITEM',
        label: 'Item'
    },
    {
        value: 'CATEGORY',
        label: 'Category'
    },
    {
        value: 'BASKET',
        label: 'Basket'
    },
    {
        value: 'ITEM_OR_CATEGORY',
        label: 'Item or Category'
    }
];

const childItemRules: Option[] = [
    {
        value: 'EXCLUDE',
        label: 'Exclude'
    },
    {
        value: 'INCLUDE',
        label: 'Include'
    }
];

const types: Option[] = [
    {
        value: 'VALUE',
        label: 'Value'
    },
    {
        value: 'PERCENTAGE',
        label: 'Percentage'
    }
];

const rules: Option[] = [
    {
        value: 'ALL',
        label: 'All'
    },
    {
        value: 'CHEAPEST',
        label: 'Cheapest'
    },
    {
        value: 'COSTLIEST',
        label: 'Costliest'
    },
    {
        value: 'CHEAPEST_FIRST',
        label: 'Cheapest First'
    },
    {
        value: 'COSTLIEST_FIRST',
        label: 'Costliest First'
    },
    {
        value: 'ALL_ALLOCATE',
        label: 'Allocate All'
    }
];

export const awardRedemptionDefaultFormValues: AwardRedemptionFormData = {
    token: '',
    discount: {
        scope: undefined,
        scopeMatchCode: undefined,
        value: undefined,
        rule: undefined,
        childItemRule: undefined,
        type: undefined
    },
    basketQualification: {
        scope: undefined,
        matchCode: undefined,
        minimumItems: undefined,
        minimumValue: undefined
    },
    dateTimeRestriction: {
        startDate: undefined,
        startTime: undefined,
        endDate: undefined,
        endTime: undefined,
        daysOfWeek: []
    }
};

const getKeysToValidate = <T,>(key: keyof T, object: T, exclude?: string[]) => {
    const allKeys = Object.keys(object);
    return allKeys.filter(field => field !== key && !exclude?.includes(field));
};

const createConditionalAllRequiredScheme = <T,>(
    key: keyof T,
    object: T,
    validationScheme: SchemaLike,
    exclude: string[] = []
): [string[], ConditionOptions<any>] => [
    getKeysToValidate<T>(key, object, exclude),
    {
        is: (...args: any[]) => args.some(isDefined),
        then: validationScheme
    }
];

const validationSchema = Yup.object().shape({
    token: Yup.string().required('This field is required'),
    discount: Yup.object().shape(
        {
            value: Yup.string().when(
                ...createConditionalAllRequiredScheme<AwardRedemptionFormData['discount']>(
                    'value',
                    awardRedemptionDefaultFormValues.discount,
                    Yup.string().required('This field is required'),
                    ['scopeMatchCode']
                )
            ),
            type: Yup.mixed().when(
                ...createConditionalAllRequiredScheme<AwardRedemptionFormData['discount']>(
                    'type',
                    awardRedemptionDefaultFormValues.discount,
                    Yup.mixed().oneOf(['VALUE', 'PERCENTAGE', 'OVERRIDE']).required('This field is required'),
                    ['scopeMatchCode']
                )
            ),
            scope: Yup.mixed().when(
                ...createConditionalAllRequiredScheme<AwardRedemptionFormData['discount']>(
                    'scope',
                    awardRedemptionDefaultFormValues.discount,
                    Yup.mixed()
                        .oneOf(['ITEM', 'CATEGORY', 'BASKET', 'ITEM_OR_CATEGORY'])
                        .required('This field is required'),
                    ['scopeMatchCode']
                )
            ),
            rule: Yup.mixed().when(
                ...createConditionalAllRequiredScheme<AwardRedemptionFormData['discount']>(
                    'rule',
                    awardRedemptionDefaultFormValues.discount,
                    Yup.mixed()
                        .oneOf([
                            'CHEAPEST',
                            'COSTLIEST',
                            'ALL_ALLOCATE',
                            'ALL',
                            'CHEAPEST_FIRST',
                            'COSTLIEST_FIRST'
                        ])
                        .required('This field is required'),
                    ['scopeMatchCode']
                )
            ),
            childItemRule: Yup.mixed().when(
                ...createConditionalAllRequiredScheme<AwardRedemptionFormData['discount']>(
                    'childItemRule',
                    awardRedemptionDefaultFormValues.discount,
                    Yup.mixed().oneOf(['INCLUDE', 'EXCLUDE']).required('This field is required'),
                    ['scopeMatchCode']
                )
            )
        },
        [
            ['value', 'type'],
            ['type', 'scope'],
            ['value', 'scope'],
            ['value', 'rule'],
            ['type', 'rule'],
            ['scope', 'rule'],
            ['childItemRule', 'value'],
            ['childItemRule', 'rule'],
            ['childItemRule', 'type'],
            ['childItemRule', 'scope']
        ]
    ),
    basketQualification: Yup.object().shape(
        {
            scope: Yup.mixed().oneOf(Object.values(LoyaltyScope)),
            minimumItems: Yup.number().min(0, 'Please enter the value more than or equal to 0'),
            minimumValue: Yup.number().min(0, 'Please enter the value more than or equal to 0')
        },
        [
            ['scope', 'matchCode'],
            ['minimumValue', 'minimumItems'],
            ['matchCode', 'minimumItems'],
            ['scope', 'minimumItems'],
            ['scope', 'minimumValue'],
            ['matchCode', 'minimumValue']
        ]
    ),
    dateTimeRestriction: Yup.object().shape(
        {
            startTime: Yup.string().when('endTime', {
                is: (value: string) => !!value,
                then: Yup.string().required('This field is required'),
                otherwise: Yup.string().notRequired()
            }),
            endTime: Yup.string().when('startTime', {
                is: (value: string) => !!value,
                then: Yup.string().required('This field is required'),
                otherwise: Yup.string().notRequired()
            }),
            startDate: Yup.string()
                .nullable()
                .when('endDate', {
                    is: (value: string) => !!value,
                    then: Yup.string()
                        .required('This field is required.')
                        .test('valid-date', 'Please enter valid date', value => {
                            if (value && !isValid(new Date(value))) {
                                return false;
                            }

                            return true;
                        }),
                    otherwise: Yup.string().notRequired()
                }),
            endDate: Yup.string()
                .nullable()
                .when('startDate', {
                    is: (value: string) => !!value,
                    then: Yup.string()
                        .required('This field is required.')
                        .test('valid-date', 'Please enter valid date', value => {
                            if (value && !isValid(new Date(value))) {
                                return false;
                            }

                            return true;
                        }),
                    otherwise: Yup.string().notRequired()
                })
        },
        [
            ['startTime', 'endTime'],
            ['startDate', 'endDate']
        ]
    )
});

export const AwardRedemptionForm: React.FC<AwardRedemptionFormProps> = ({
    onSubmit,
    initialValues,
    open,
    isEdit,
    clickPrevious,
    onPrevious,
    isLoading,
    onUpdate,
    auto
}) => {
    const { getUserDateFormat } = useLocalDateTimeFormat();
    const [expandedSections, setExpandedSections] = React.useState<string[]>(['discount']);
    const inputFormat = React.useMemo(getUserDateFormat, [getUserDateFormat]);
    const getDatepickerSlots = React.useCallback(
        (error: string): DatePickerSlotProps<Date, false> => ({
            textField: { variant: 'standard', helperText: error, error: !!error }
        }),
        []
    );
    const handleSubmit = React.useCallback(
        (data: AwardRedemptionFormData) => {
            onSubmit({
                ...data,
                dateTimeRestriction: {
                    ...data.dateTimeRestriction,
                    daysOfWeek: data.dateTimeRestriction.daysOfWeek ? data.dateTimeRestriction.daysOfWeek : []
                }
            });
        },
        [onSubmit]
    );
    const handleOpenAccordion = React.useCallback(
        (section: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedSections(prevExpandedSections => {
                if (!isExpanded) {
                    return prevExpandedSections.filter(val => val !== section);
                }
                return [...prevExpandedSections, section];
            });
        },
        []
    );

    if (!open) {
        return null;
    }
    return (
        <MuiForm
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            initialValues={initialValues || awardRedemptionDefaultFormValues}
        >
            {({ submitForm, setFieldValue, values, isValid, errors }) => {
                const isDiscountFieldsRequired = Object.entries(values.discount).some(
                    ([key, value]) => key !== 'scopeMatchCode' && isDefined(value) && !isEmptyString(value)
                );
                const handleUpdate = () => {
                    onUpdate({ values, type: AwardFormStep.REDEMPTION });
                };
                const handleDateFieldChange = (fieldName: string) => (date: Date) => {
                    setFieldValue(fieldName, date || '');
                };

                const handlePreviousStep = () => {
                    if (isEdit) {
                        onPrevious(isValid, values);
                    }
                    clickPrevious();
                };
                return (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box padding={3} maxWidth={866}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h4">3. Redemption</Typography>
                                            <Typography variant="body2">
                                                Set up how the award will behave for your users when redeeming
                                                through ordering in your app.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Accordion
                                                expanded={
                                                    expandedSections.includes('discount') || !!errors.discount
                                                }
                                                onChange={handleOpenAccordion('discount')}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="discount-content"
                                                    id="discount-header"
                                                >
                                                    <Box display="flex" flexDirection="column">
                                                        <Typography>Discount</Typography>
                                                        <Typography variant="caption">
                                                            The fields below control the discountable items.
                                                            It determines based on the rules that are supplied
                                                            to it, what items should be discounted and by how
                                                            much.
                                                        </Typography>
                                                    </Box>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={4}>
                                                            <TextFormField
                                                                name="token"
                                                                label={
                                                                    <MuiFormLabel required>Code</MuiFormLabel>
                                                                }
                                                                description="Enter a code that will be visible to the cashier at the POS to let them know what discount to apply to the order"
                                                            />
                                                        </Grid>
                                                        {!auto && (
                                                            <Grid item xs={4}>
                                                                <SelectFormField
                                                                    options={types}
                                                                    name="discount.type"
                                                                    label={
                                                                        <MuiFormLabel
                                                                            required={
                                                                                isDiscountFieldsRequired
                                                                            }
                                                                        >
                                                                            Type
                                                                        </MuiFormLabel>
                                                                    }
                                                                />
                                                            </Grid>
                                                        )}
                                                        {!auto && (
                                                            <Grid item xs={4}>
                                                                <TextFormField
                                                                    name="discount.value"
                                                                    label={
                                                                        <MuiFormLabel
                                                                            required={
                                                                                isDiscountFieldsRequired
                                                                            }
                                                                        >
                                                                            Value
                                                                        </MuiFormLabel>
                                                                    }
                                                                    type="number"
                                                                    description="The hard value of the discount. For example, a Percentage set up as 1 means 100% discount and a Value set up as 2 means £2 / $2"
                                                                />
                                                            </Grid>
                                                        )}
                                                        {!auto && (
                                                            <Grid item xs={4}>
                                                                <SelectFormField
                                                                    options={scopeOptions}
                                                                    required
                                                                    label={
                                                                        <MuiFormLabel
                                                                            required={
                                                                                isDiscountFieldsRequired
                                                                            }
                                                                        >
                                                                            Scope
                                                                        </MuiFormLabel>
                                                                    }
                                                                    name="discount.scope"
                                                                />
                                                            </Grid>
                                                        )}

                                                        {!auto && (
                                                            <Grid xs={4} item>
                                                                <TextFormField
                                                                    name="discount.scopeMatchCode"
                                                                    label={
                                                                        <MuiFormLabel>
                                                                            Match Code
                                                                        </MuiFormLabel>
                                                                    }
                                                                />
                                                            </Grid>
                                                        )}
                                                        {!auto && (
                                                            <Grid item xs={4}>
                                                                <SelectFormField
                                                                    options={rules}
                                                                    name="discount.rule"
                                                                    label={
                                                                        <MuiFormLabel
                                                                            required={
                                                                                isDiscountFieldsRequired
                                                                            }
                                                                        >
                                                                            Rule
                                                                        </MuiFormLabel>
                                                                    }
                                                                    description="Determines how to discount the qualifying products based on price"
                                                                />
                                                            </Grid>
                                                        )}
                                                        {!auto && (
                                                            <Grid item xs={4}>
                                                                <SelectFormField
                                                                    options={childItemRules}
                                                                    required
                                                                    name="discount.childItemRule"
                                                                    label={
                                                                        <MuiFormLabel
                                                                            required={
                                                                                isDiscountFieldsRequired
                                                                            }
                                                                        >
                                                                            Modifier Products
                                                                        </MuiFormLabel>
                                                                    }
                                                                    description="Include or exclude modifier products when calculating the discount"
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                        {!auto && (
                                            <Grid item xs={12}>
                                                <Accordion
                                                    expanded={
                                                        expandedSections.includes('basketQualification') ||
                                                        !!errors.basketQualification
                                                    }
                                                    onChange={handleOpenAccordion('basketQualification')}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="basketQualification-content"
                                                        id="basketQualification-header"
                                                    >
                                                        <Box display="flex" flexDirection="column">
                                                            <Typography>Basket Qualification</Typography>
                                                            <Typography variant="caption">
                                                                The fields below control what items you must
                                                                have in your basket, alongside the
                                                                discountable items to be able to qualify for
                                                                the award.
                                                            </Typography>
                                                        </Box>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={4}>
                                                                <SelectFormField
                                                                    options={scopeOptions}
                                                                    name="basketQualification.scope"
                                                                    label={<MuiFormLabel>Scope</MuiFormLabel>}
                                                                />
                                                            </Grid>
                                                            <Grid xs={4} item>
                                                                <TextFormField
                                                                    name="basketQualification.matchCode"
                                                                    label={
                                                                        <MuiFormLabel>
                                                                            Match Code
                                                                        </MuiFormLabel>
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid xs={4} item>
                                                                <TextFormField
                                                                    name="basketQualification.minimumItems"
                                                                    type="number"
                                                                    label={
                                                                        <MuiFormLabel>
                                                                            Minimum Items
                                                                        </MuiFormLabel>
                                                                    }
                                                                    description="The minimum number of items required to qualify for the discount"
                                                                />
                                                            </Grid>
                                                            <Grid xs={4} item>
                                                                <TextFormField
                                                                    name="basketQualification.minimumValue"
                                                                    type="number"
                                                                    label={
                                                                        <MuiFormLabel>
                                                                            Minimum Value
                                                                        </MuiFormLabel>
                                                                    }
                                                                    description="The minimum amount required to qualify for the discount"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Accordion
                                                expanded={
                                                    expandedSections.includes('dateTimeRestriction') ||
                                                    !!errors.dateTimeRestriction
                                                }
                                                onChange={handleOpenAccordion('dateTimeRestriction')}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="dateTimeRestriction-content"
                                                    id="dateTimeRestriction-header"
                                                >
                                                    <Box display="flex" flexDirection="column">
                                                        <Typography>Time Conditions</Typography>
                                                        <Typography variant="caption">
                                                            The fields below control when an award will be
                                                            available to the user to redeem in app.
                                                        </Typography>
                                                    </Box>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={4}>
                                                            <TextFormField
                                                                name="dateTimeRestriction.startTime"
                                                                type="time"
                                                                label="Start Time"
                                                                shrink
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextFormField
                                                                name="dateTimeRestriction.endTime"
                                                                type="time"
                                                                label="End Time"
                                                                shrink
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <SelectFormField
                                                                options={weekdays}
                                                                label="Days of Week"
                                                                multiple
                                                                name="dateTimeRestriction.daysOfWeek"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <DatePicker
                                                                label="Start Date"
                                                                value={
                                                                    values.dateTimeRestriction.startDate
                                                                        ? new Date(
                                                                              values.dateTimeRestriction.startDate
                                                                          )
                                                                        : null
                                                                }
                                                                onChange={handleDateFieldChange(
                                                                    'dateTimeRestriction.startDate'
                                                                )}
                                                                slotProps={getDatepickerSlots(
                                                                    errors?.dateTimeRestriction?.startDate
                                                                )}
                                                                format={inputFormat}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <DatePicker
                                                                label="End Date"
                                                                value={
                                                                    values.dateTimeRestriction.endDate
                                                                        ? new Date(
                                                                              values.dateTimeRestriction.endDate
                                                                          )
                                                                        : null
                                                                }
                                                                onChange={handleDateFieldChange(
                                                                    'dateTimeRestriction.endDate'
                                                                )}
                                                                slotProps={getDatepickerSlots(
                                                                    errors?.dateTimeRestriction?.endDate
                                                                )}
                                                                format={inputFormat}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Row flex={1} gutter align="flex-end">
                                    {isEdit && (
                                        <LoadingButton
                                            loading={isLoading}
                                            disabled={isLoading || !isValid}
                                            onClick={handleUpdate}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            Update & Close
                                        </LoadingButton>
                                    )}
                                    <Button onClick={handlePreviousStep} color="primary" variant="outlined">
                                        Previous
                                    </Button>
                                    <Button onClick={submitForm} color="primary" variant="contained">
                                        Next
                                    </Button>
                                </Row>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </MuiForm>
    );
};
