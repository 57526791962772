import React from 'react';
import { Box, Button, styled } from '@mui/material';
import { SystemRole } from '@pepperhq/auth-client';
import { Form } from 'formik';
import * as Yup from 'yup';
import { Location } from 'components/location/models/LocationModel';
import { MuiForm } from 'lib/Form';
import { MultiSelectFormField } from 'lib/form/fields/MultiSelectFormField';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { Option } from 'lib/types';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Role } from '../models/Role';
import clsx from 'clsx';

export interface IInviteUserFormValues {
    email: string;
    roleId: SystemRole;
    locationIds: Option[];
}

interface IInviteUserFormProps {
    onClose: () => void;
    isLoading: boolean;
    onSubmit: (values: IInviteUserFormValues) => void;
    roles: Role[];
    locations: Location[];
    isResend: boolean;
    initialValues?: IInviteUserFormValues;
}

const PREFIX = 'InviteUserForm';

const classes = {
    marginBottom: `${PREFIX}-marginBottom`,
    marginTop: `${PREFIX}-marginTop`
};

const StyledForm = styled(Form)(({ theme }) => ({
    [`& .${classes.marginBottom}`]: {
        marginBottom: theme.spacing(1.5)
    },
    [`& .${classes.marginTop}`]: {
        marginTop: theme.spacing(1.5)
    }
}));

export const InviteUserForm: React.FC<IInviteUserFormProps> = props => {
    const { onClose, isLoading, onSubmit, roles, locations, isResend, initialValues } = props;

    const initial: IInviteUserFormValues = React.useMemo(
        () =>
            initialValues ?? {
                email: '',
                roleId: roles[0].id,
                locationIds: []
            },
        [initialValues, roles]
    );

    const validationSchema = React.useMemo(
        () =>
            Yup.object().shape({
                email: Yup.string().required('This field is required.'),
                roleId: Yup.string().required('This field is required.')
            }),
        []
    );

    const roleOptions = React.useMemo(
        () =>
            roles.map((role: Role) => ({
                value: role.id,
                label: role.name
            })),
        [roles]
    );
    const locationOptions = React.useMemo(
        () =>
            locations.map((location: Location) => ({
                value: location._id,
                label: location.title
            })),
        [locations]
    );

    return (
        <MuiForm onSubmit={onSubmit} initialValues={initial} validationSchema={validationSchema}>
            {({ submitForm, isSubmitting }) => (
                <StyledForm>
                    <TextFormField name="email" label="Email" className={classes.marginBottom} />
                    <SelectFormField
                        name="roleId"
                        options={roleOptions}
                        label="Role"
                        className={clsx(classes.marginBottom, classes.marginTop)}
                    />
                    <MultiSelectFormField
                        name="locationIds"
                        options={locationOptions}
                        label="Locations"
                        description="The user will only be limited to some locations if any are provided"
                    />
                    <Box display="flex" flexDirection="row" justifyContent="flex-end" marginTop={1.5}>
                        <Button onClick={onClose} variant="outlined" sx={{ mr: 1 }}>
                            Cancel
                        </Button>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting || isLoading}
                            loading={isSubmitting || isLoading}
                            onClick={submitForm}
                        >
                            {isResend ? 'Resend Invite' : 'Invite User'}
                        </LoadingButton>
                    </Box>
                </StyledForm>
            )}
        </MuiForm>
    );
};
