import { Box, Button } from '@mui/material';
import React from 'react';
import { VouchersFilter, VouchersFilterChange } from './hooks/useVouchersFilter';
import { Row } from 'ui/Flex';
import { GridFilterInput } from 'lib/grid/GridFilterInput';

interface VoucherFiltersProps {
    filter: VouchersFilter;
    onChange: VouchersFilterChange;
    onCreateClick: () => void;
    onOpenClick: () => void;
    onDeleteClick: () => void;
    loading?: boolean;
    selected?: boolean;
}

export const VoucherFilters: React.FC<VoucherFiltersProps> = ({
    onCreateClick,
    filter,
    onChange,
    loading,
    onOpenClick,
    onDeleteClick,
    selected
}) => {
    const handleTextChange = React.useCallback(
        (value: string, name: keyof VouchersFilter) => {
            onChange(name, value);
        },
        [onChange]
    );
    return (
        <Row flex={1} align="space-between" valign="flex-end">
            <Box>
                <GridFilterInput
                    value={filter.voucherSchemeId}
                    onChange={handleTextChange}
                    label="Scheme ID"
                    name="voucherSchemeId"
                    disabled={loading}
                    size="small"
                />
            </Box>
            <Row gutter>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onOpenClick}
                    size="small"
                    disabled={loading || !selected}
                >
                    View
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCreateClick}
                    size="small"
                    disabled={loading || selected}
                >
                    Create
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={onDeleteClick}
                    size="small"
                    disabled={loading || !selected}
                >
                    Delete
                </Button>
            </Row>
        </Row>
    );
};
