/* eslint-disable prefer-destructuring */
import {
    MenuChange,
    MenuChangeUpdate,
    MenuChannel,
    ProductChange,
    ProductGroupChange,
    fromScopedKey,
    productDefaults,
    toScopedKey
} from '@pepperhq/menu-sdk';
import { Optional, isDefined, isEmptyString, isNotNull, isString } from 'lib/typeguards';
import { Option } from 'lib/types';
import {
    CategoryType,
    MenuType,
    ProductType,
    menuChannelsArrayToFlags,
    menuChannelsFlagsToArray
} from './menu';
import { difference, differenceBy, union, unionBy } from 'lodash';
import { MuiGridChanges } from 'lib/MuiGrid/MuiGrid';
import { GridCellParams } from '@mui/x-data-grid-pro';

export type EnrichedProduct =
    // the fields we use from the product type
    Omit<Partial<ProductType>, 'complements' | 'perks' | 'available' | 'id'> &
        // any extra fields from the productChange
        Omit<ProductChange, keyof ProductType> & {
            // fields for the UX
            id: string; // internal row id - we set it to the product change / product id, so it MAY be scoped
            productId: string; // same as id - is it needed?
            categoryId: string;
            categoryTitle?: string;
            channels?: MenuChannel[];
            categoryDescription?: string;
            productGroupShortDescription?: string;
            _overriden?: Set<string>;
        };

function toEnrichedProduct(
    categoryId: string,
    product: Optional<ProductType, 'title' | 'price'>
): EnrichedProduct {
    const { complements: _, perks, ...rest } = product;
    return {
        ...rest,
        categoryId,
        perkMatchCodes: perks,
        productId: product.id
    };
}

// array fields contain the union of the underlying product value and the product change -
// this means you can't clear an array field using a change.  If this is incorrect, then the menu-service
// needs changing so that it no longer unions, or we need a special array value to indicate 'don't union'
const unionedFields = [
    'taxIds',
    'allergens',
    'perkMatchCodes',
    'suggestions',
    'tags',
    'nutrition',
    'zones',
    'modifiers'
] as const;

function applyToProduct(
    productChange: ProductChange,
    product: EnrichedProduct,
    productGroupMap: Map<string, ProductGroupEntry>
) {
    if (isDefined(productChange.title)) {
        product.title = productChange.title;
    }
    if (isDefined(productChange.shortDescription)) {
        product.shortDescription = productChange.shortDescription;
    }
    if (isDefined(productChange.sort)) {
        product.sort = productChange.sort;
    }
    if (isDefined(productChange.imageUrl)) {
        product.imageUrl = productChange.imageUrl;
    }
    if (isDefined(productChange.listingImageUrl)) {
        product.listingImageUrl = productChange.listingImageUrl;
    }
    if (isDefined(productChange.prepTimeMins)) {
        product.prepTimeMins = productChange.prepTimeMins;
    }
    if (isDefined(productChange.dynamicImagery)) {
        product.dynamicImagery = productChange.dynamicImagery;
    }
    if (isDefined(productChange.taxIds)) {
        product.taxIds = union(product.taxIds, productChange.taxIds);
    }
    if (isDefined(productChange.perkMatchCodes) && productChange.perkMatchCodes.length > 0) {
        product.perkMatchCodes = product.perkMatchCodes
            ? union(product.perkMatchCodes, productChange.perkMatchCodes)
            : productChange.perkMatchCodes;
    }
    if (isDefined(productChange.allergens)) {
        product.allergens = product.allergens
            ? union(product.allergens, productChange.allergens)
            : productChange.allergens;
    }
    if (isDefined(productChange.suggestions)) {
        product.suggestions = unionBy(product.suggestions, productChange.suggestions, 'title');
    }
    if (isDefined(productChange.tags)) {
        product.tags = union(product.tags, productChange.tags);
    }
    if (isDefined(productChange.nutrition)) {
        product.nutrition = unionBy(productChange.nutrition, product.nutrition, 'title');
    }

    if (isDefined(productChange.availableDays)) {
        product.availableDays = union(product.availableDays, productChange.availableDays);
    }
    if (isDefined(productChange.availableScenarios)) {
        product.availableScenarios = union(product.availableScenarios, productChange.availableScenarios);
    }
    if (isDefined(productChange.listingTextColour)) {
        product.listingTextColour = productChange.listingTextColour;
    }
    if (isDefined(productChange.listingBackgroundColour)) {
        product.listingBackgroundColour = productChange.listingBackgroundColour;
    }
    if (isDefined(productChange.listingBackgroundImageUrl)) {
        product.listingBackgroundImageUrl = productChange.listingBackgroundImageUrl;
    }
    if (isDefined(productChange.terminalListingTextColour)) {
        product.terminalListingTextColour = productChange.terminalListingTextColour;
    }
    if (isDefined(productChange.terminalListingBackgroundColour)) {
        product.terminalListingBackgroundColour = productChange.terminalListingBackgroundColour;
    }

    if (isDefined(productChange.productGroupId) && productChange.productGroupId !== product.productGroupId) {
        // we are adding to or changing productGroup - all the group fields are changed
        product.productGroupId = productChange.productGroupId;
        const groupEntry = productGroupMap.get(product.productGroupId);
        if (groupEntry) {
            product._overriden = addToSet(product._overriden, ['productGroupName']);
            product.productGroupName = groupEntry.change.productGroupName ?? groupEntry.base.productGroupName;
            product.productGroupShortDescription =
                groupEntry.change.shortDescription ?? groupEntry.base.shortDescription;
            if (groupEntry.change.shortDescription) {
                product._overriden.add('productGroupShortDescription');
            }
        }
    } else if (isDefined(product.productGroupId)) {
        // override the product group fields if they have been changed
        const groupEntry = productGroupMap.get(product.productGroupId);
        if (groupEntry) {
            product._overriden = addToSet(product._overriden, Object.keys(groupEntry.change));
            if (groupEntry.change.productGroupName) {
                product.productGroupName = groupEntry.change.productGroupName;
            }
            if (groupEntry.change.shortDescription) {
                product.productGroupShortDescription = groupEntry.change.shortDescription;
                product._overriden = addToSet(product._overriden, ['productGroupShortDescription']);
            }
        }
    }

    if (isDefined(productChange.productGroupDefault)) {
        product.productGroupDefault = productChange.productGroupDefault;
    }
    if (isDefined(productChange.price)) {
        product.price = productChange.price;
    }
    if (isDefined(productChange.zones)) {
        product.zones = union(product.zones, productChange.zones);
    }
    if (isDefined(productChange.modifiers)) {
        product.modifiers = union(product.modifiers, productChange.modifiers);
    }
    if (isDefined(productChange.priceLevel)) {
        product.priceLevel = productChange.priceLevel;
    }
    if (isDefined(productChange.channels)) {
        product.channels = menuChannelsFlagsToArray(productChange.channels);
    }
}

function excludeOriginalValues(
    modelRow: Record<string | number, any>,
    originalProductMap: Map<string, ProductType>,
    productId: string,
    categoryId: string
) {
    for (const field of unionedFields) {
        const newValue = modelRow[field];
        if (Array.isArray(newValue)) {
            const originalFieldName = field === 'perkMatchCodes' ? 'perks' : field;
            const originalValue = originalProductMap.get(
                toScopedKey(categoryId, fromScopedKey(productId).id)
            )?.[originalFieldName];
            if (Array.isArray(originalValue)) {
                modelRow[field] =
                    field === 'nutrition' || field === 'suggestions'
                        ? differenceBy(newValue, originalValue, 'title')
                        : difference(newValue, originalValue);
            }
        }
    }
}

interface ProductGroupEntry {
    base: { productGroupName: string; shortDescription?: string };
    change: { productGroupName?: string; shortDescription?: string };
}

// in the products tab, always handle scoped and unscoped productchanges even when showByCategory is turned off
// because we don't want the UX 'hiding' what is in the data and that would be in an App menu.
// Other tabs that use this should use allowScoped false
export function getProductsData(
    menu: MenuType,
    menuChange: MenuChange,
    allowScoped: boolean
): [EnrichedProduct[], Option[]] {
    const products: Record<string, EnrichedProduct> = {};
    const productOptions: Map<string, Option> = new Map();
    const keyProcessed = new Set<string>();
    const categoryMap = new Map<string, Pick<CategoryType, 'title' | 'shortDescription'>>();
    const productGroupMap = defineProductGroups(menu, menuChange);

    for (const category of menu.categories) {
        const categoryChange = menuChange.categories?.[category.id];
        const categoryEntry = {
            title: categoryChange?.title || category.title,
            shortDescription: categoryChange?.shortDescription || category.shortDescription
        };
        categoryMap.set(category.id, categoryEntry);

        for (const product of category.products) {
            const modelRow = toEnrichedProduct(category.id, product);
            modelRow.categoryTitle = categoryEntry.title;
            modelRow.categoryDescription = categoryEntry.shortDescription;

            // unscoped change, or no change - show 1 row for all categories
            const unscopedKey = toScopedKey(undefined, product.id);
            if (!keyProcessed.has(unscopedKey)) {
                keyProcessed.add(unscopedKey);
                const unscopedChange = menuChange.products?.[unscopedKey];
                if (unscopedChange) {
                    // accumulate changes in modelRow in case there is a scoped change too
                    applyToProduct(unscopedChange, modelRow, productGroupMap);
                    products[unscopedKey] = {
                        ...modelRow,
                        productId: unscopedKey,
                        _overriden: addToSet(modelRow._overriden, Object.keys(unscopedChange))
                    };
                } else {
                    products[unscopedKey] = {
                        ...modelRow,
                        productId: unscopedKey
                    };
                }
            } else {
                const existingRow = products[unscopedKey];
                existingRow.categoryTitle = existingRow.categoryTitle
                    ? `${existingRow.categoryTitle}, ${categoryEntry.title}`
                    : categoryEntry.title;
                existingRow.categoryDescription = existingRow.categoryDescription
                    ? `${existingRow.categoryDescription}, ${categoryEntry.shortDescription}`
                    : categoryEntry.shortDescription;
            }

            // TODO: figure out what to do with taxes, suggestions and dynamic images
            productOptions.set(product.id, {
                value: String(product.id),
                label: modelRow?.productGroupName
                    ? `${modelRow.productGroupName} - ${modelRow.title}`
                    : `${modelRow.title}`
            });

            // at this point modelRow has unscoped changes applied (if any);
            // now if there are scoped changes just for this category, use it to make an extra 'scoped' row

            if (allowScoped) {
                const scopedKey = toScopedKey(category.id, product.id);
                keyProcessed.add(scopedKey);
                const scopedChange = menuChange.products?.[scopedKey];
                if (scopedChange) {
                    applyToProduct(scopedChange, modelRow, productGroupMap);
                    products[scopedKey] = {
                        ...modelRow,
                        id: scopedKey,
                        productId: scopedKey,
                        _overriden: addToSet(modelRow._overriden, Object.keys(scopedChange))
                    };
                }
            }
        }
    }

    // finally, append all scoped key records not already found - they represent 'new' products

    if (allowScoped && menuChange.products) {
        for (const key of Object.keys(menuChange.products)) {
            if (!keyProcessed.has(key)) {
                const id = fromScopedKey(key);
                // FIXME I want to be able to inject errors into the model for this row:
                // - error if new product is missing title or price
                // - error if the new product has an invalid category
                if (id.parentId) {
                    const scopedChange = menuChange.products[key];
                    if (scopedChange) {
                        const categoryEntry = categoryMap.get(id.parentId) || {
                            title: '?',
                            shortDescription: '?'
                        };
                        const modelRow = toEnrichedProduct(id.parentId, {
                            ...productDefaults(),
                            id: key
                        });
                        modelRow.categoryTitle = categoryEntry.title;
                        modelRow.categoryDescription = categoryEntry.shortDescription;
                        applyToProduct(scopedChange, modelRow, productGroupMap);
                        modelRow._overriden = addToSet(modelRow._overriden, [
                            'productId',
                            ...Object.keys(scopedChange)
                        ]);
                        products[key] = modelRow;
                    }
                }
            }
        }
    }

    const resultProducts = Object.values(products);
    return [resultProducts, Array.from(productOptions.values())];
}

function defineProductGroups(menu: MenuType, menuChange: MenuChange): Map<string, ProductGroupEntry> {
    const productGroupMap = new Map<string, ProductGroupEntry>();
    for (const category of menu.categories) {
        for (const product of category.products) {
            if (isString(product.productGroupId)) {
                if (!productGroupMap.has(product.productGroupId)) {
                    productGroupMap.set(product.productGroupId, {
                        base: {
                            productGroupName: product.productGroupName || product.title
                        },
                        change: {}
                    });
                }
            }
        }
    }
    if (menuChange.productGroups) {
        for (const [id, productGroup] of Object.entries(menuChange.productGroups)) {
            const entry = productGroupMap.get(id);
            if (!entry) {
                productGroupMap.set(id, {
                    base: {
                        productGroupName: productGroup.name ?? 'GROUP',
                        shortDescription: productGroup.shortDescription ?? ''
                    },
                    change: {
                        productGroupName: productGroup.name ?? 'GROUP',
                        shortDescription: productGroup.shortDescription ?? ''
                    }
                });
            } else {
                if (productGroup.name) {
                    entry.change.productGroupName = productGroup.name;
                }
                if (productGroup.shortDescription) {
                    entry.change.shortDescription = productGroup.shortDescription;
                }
            }
        }
    }
    // new products - their product groups will be already defined.  they can't 'add' a group by themselves
    return productGroupMap;
}

export function getProductsMenuChangeUpdate(
    changes: MuiGridChanges,
    originalMenu: MenuType,
    menuChange: MenuChange,
    products: EnrichedProduct[],
    deleted?: Set<string | number>
): Pick<MenuChangeUpdate, 'products' | 'productGroups'> {
    const update: Pick<MenuChangeUpdate, 'products' | 'productGroups'> = { products: {}, productGroups: {} };
    const originalProductMap: Map<string, ProductType> = new Map();
    for (const category of originalMenu.categories) {
        for (const product of category.products) {
            originalProductMap.set(product.id, product);
        }
    }

    for (const [id, changeRow] of Object.entries(changes)) {
        const product = products.find(item => item.id === id);
        // any fields that are any empty string have been 'cleared'.  set them to null
        setEmptyFieldsToNull(changeRow);
        buildMenuChangeUpdate(update, id, changeRow, product, originalProductMap, menuChange.productGroups);
    }

    // drop any unreferenced productGroupIds
    const productGroupIds = new Set(
        products.map(product => {
            const change = changes[product.id];
            return change && change.productGroupId !== undefined // want null
                ? change.productGroupId
                : product.productGroupId;
        })
    );
    if (menuChange.productGroups) {
        for (const productGroupId of Object.keys(menuChange.productGroups)) {
            if (!productGroupIds.has(productGroupId)) {
                update.productGroups[productGroupId] = null;
            }
        }
    }
    if (deleted && deleted.size) {
        if (!update.products) {
            update.products = {};
        }
        deleted.forEach(deletedProduct => {
            update.products[deletedProduct] = null;
        });
    }
    return update;
}

export function getProductsAndProductGroupsToCopy(
    allProducts: EnrichedProduct[],
    selectedIds: Array<EnrichedProduct['id']>,
    originalMenu: MenuType
): Pick<MenuChangeUpdate, 'products' | 'productGroups'> {
    const update: Pick<MenuChangeUpdate, 'products' | 'productGroups'> = { products: {}, productGroups: {} };

    const selectedIdsMap = new Set(selectedIds);
    const originalProductMap: Map<string, ProductType> = new Map();
    for (const category of originalMenu.categories) {
        for (const product of category.products) {
            originalProductMap.set(product.id, product);
        }
    }

    // FIXME how do I bulk copy a 'clear' of a field?  Cannot atm
    for (const product of allProducts) {
        if (selectedIdsMap.has(product.id)) {
            // only copy the overridden fields
            const change = Object.fromEntries(
                Object.entries(product).filter(([field]) => product._overriden?.has(field))
            );
            buildMenuChangeUpdate(update, product.id, change, product, originalProductMap);
        }
    }
    return update;
}

export function getProductAndProductGroupCellToCopy(
    params: GridCellParams,
    originalMenu: MenuType
): Pick<MenuChangeUpdate, 'products' | 'productGroups'> {
    const update: Pick<MenuChangeUpdate, 'products' | 'productGroups'> = { products: {}, productGroups: {} };
    const originalProductMap: Map<string, ProductType> = new Map();
    for (const category of originalMenu.categories) {
        for (const product of category.products) {
            originalProductMap.set(toScopedKey(category.id, product.id), product);
        }
    }

    const change = { [params.field]: params.value };
    buildMenuChangeUpdate(update, params.row.id, change, params.row, originalProductMap);

    return update;
}

function buildMenuChangeUpdate(
    update: Pick<MenuChangeUpdate, 'products' | 'productGroups'>,
    rowId: string,
    changed: Record<string | number, any>,
    product: EnrichedProduct, // the model row
    originalProductMap: Map<string, ProductType>,
    productGroupChange?: Record<string, ProductGroupChange>
) {
    const categoryId = product.categoryId;
    const {
        productGroupName,
        productGroupShortDescription,
        id: _a,
        productId: _b,
        _overriden,
        categoryTitle: _c,
        categoryDescription: _d,
        ...modelChanges
    } = changed;
    const productId = rowId; // row id IS the product change id
    const { parentId, id } = fromScopedKey(productId);

    // remove the original values from any unioned array values
    excludeOriginalValues(modelChanges, originalProductMap, id, categoryId);

    // if it is NOT scoped, and it is a productGroup product, then write productGroupName and
    // shortDescription to the product group. nb productGroupChanges are not scoped
    const productGroupId = changed.productGroupId ?? product.productGroupId;
    if (!isDefined(parentId) && isDefined(productGroupId)) {
        const productGroupUpdate: ProductGroupChange = {};

        if (isDefined(productGroupChange)) {
            const original = originalProductMap.get(id);
            if (original && productGroupId !== original.productGroupId) {
                // a new or changed product group
                productGroupUpdate.name = productGroupName ?? product.productGroupName ?? product.title;
                productGroupUpdate.shortDescription = productGroupShortDescription ?? null;
            } else {
                if (isDefined(productGroupName)) {
                    productGroupUpdate.name = productGroupName;
                }
                if (isDefined(productGroupShortDescription)) {
                    productGroupUpdate.shortDescription = productGroupShortDescription ?? null;
                }
            }
        }
        const keyLength = Object.keys(productGroupUpdate).length;
        if (keyLength > 0) {
            // update if not exists, or if it has more keys
            if (
                !update.productGroups[productGroupId] ||
                Object.keys(update.productGroups[productGroupId]).length < keyLength
            ) {
                update.productGroups[productGroupId] = productGroupUpdate;
            }
        }
    }
    if (isNotNull(modelChanges.channels)) {
        modelChanges.channels = menuChannelsArrayToFlags(modelChanges.channels);
    }
    // nb we are no longer sending back existing values in update - shouldn't be needed.
    if (Object.keys(modelChanges).length > 0) {
        update.products[productId] = modelChanges;
    }
}

function setEmptyFieldsToNull(modelRow: Record<string | number, unknown>) {
    for (const key of Object.keys(modelRow)) {
        const value = modelRow[key];
        // any field that is a blank string is an 'empty' field
        if (isEmptyString(value)) {
            modelRow[key] = null;
        }
    }
}

export function getProductPrice(
    productId: string,
    menu: MenuType,
    menuChange: MenuChange,
    categoryId?: string
) {
    if (isDefined(categoryId) && !isEmptyString(categoryId)) {
        const category = menu.categories.find(item => item.id === categoryId);
        if (!category) {
            return undefined;
        }
        const unscopedKey = toScopedKey(undefined, productId);
        const unscopedChange = menuChange?.products?.[unscopedKey];
        const scopedKey = toScopedKey(categoryId, productId);
        const scopedChange = menuChange?.products?.[scopedKey];
        return (
            scopedChange?.price ??
            unscopedChange?.price ??
            category.products.find(item => item.id === productId)?.price
        );
    }
    if (isDefined(menuChange?.products?.[productId]?.price)) {
        return menuChange?.products?.[productId]?.price;
    }
    for (const category of menu.categories) {
        for (const product of category.products) {
            if (product.id === productId) {
                return product.price;
            }
        }
    }
    return undefined;
}

function addToSet<T>(set: Set<T> | undefined, items: T[]): Set<T> {
    if (!set) {
        return new Set<T>(items);
    }
    for (let i = items.length - 1; i >= 0; --i) {
        set.add(items[i]);
    }
    return set;
}
