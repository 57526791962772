import { Box, Button, Grid, IconButton, Paper, Typography, Tooltip } from '@mui/material';
import { PepperTransaction } from 'components/pepper-pay/model/PepperPay';
import { pepperPayApi } from 'components/pepper-pay/pepperPayApi';
import { PEPPER_PAY_DISPUTES_VIEW, PEPPER_PAY_TRANSACTIONS } from 'config/routes';
import { MainLayout } from 'layouts/MainLayout';
import { roundToDecimal } from 'lib/helpers';
import logger from 'lib/logger';
import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Throbber } from 'ui/Throbber';
import { Row } from 'ui/Flex';
import { TransactionStateChip } from 'components/pepper-pay/transactions/TransactionStateChip';

import { customerApi } from 'components/customers/customerApi';
import { PepperPayTimelineComponent } from 'components/pepper-pay/timeline/PepperPayTimeline';
import { PepperPayChargeBlock } from 'components/pepper-pay/PepperPayChargeBlock';
import { PepperPayPaymentDetailsBlock } from 'components/pepper-pay/PepperPayPaymentDetailsBlock';
import { MoreVert } from '@mui/icons-material';
import { MenuOn } from 'ui/MenuOn';
import { RadarListItemsResponse } from 'components/pepper-pay/block-list/models';
import { EBlockListAlias } from 'components/pepper-pay/block-list/enums';
import { ConfirmDialog } from 'ui/dialogs/ConfirmDialog';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_ADD_BLOCK_LIST_ITEM_VALUE_ERROR,
    MESSAGE_ADD_BLOCK_LIST_ITEM_TRANSACTION_SUCCESS,
    MESSAGE_VOID_TRANSACTION_ERROR,
    MESSAGE_VOID_TRANSACTION_SUCCESS,
    MESSAGE_CUSTOMER_ORDER_REFUNDING_PENDING,
    MESSAGE_CUSTOMER_ORDER_REFUNDING_ERROR,
    MESSAGE_CUSTOMER_ORDER_REFUNDING_SUCCESS,
    MESSAGE_CAPTURE_PAYMENT_SUCCESS,
    MESSAGE_CAPTURE_PAYMENT_ERROR
} from 'config/messages';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { OrderRefundResultOC2, orderApi } from 'components/order/orderApi';
import { IOrder, IOrderPayment } from 'components/order/model/Order';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { CustomRefundDialog } from 'components/order/refund/CustomRefundDialog';
import { HttpClientResponseContent } from 'lib/HttpClient';
import { isDefined } from 'lib/typeguards';
import { IClerk } from 'components/clerks-access/Clerk';
import { clerksApi } from 'components/clerks-access/clerksApi';
import { StandalonePaymentRefundDialog } from 'components/pepper-pay/refund/StandalonePaymentRefundDialog';
import { TransactionOverview } from 'components/pepper-pay/TransactionOverview';
import Dinero from 'dinero.js';
import { isCurrencyCode } from '@pepperhq/regions';
import { formatDateTime } from 'lib/timeHelpers';

export const ViewTransactionPage = () => {
    const dispatch = useDispatch();
    const { transactionId } = useParams<{ transactionId?: string }>();
    const history = useHistory();
    const [transaction, setTransaction] = React.useState<PepperTransaction>();
    const [loading, setLoading] = React.useState(true);
    const [voidPaymentOpen, setVoidPaymentOpen] = React.useState(false);
    const [capturePaymentOpen, setCapturePaymentOpen] = React.useState(false);
    const [refundPaymentConfirmationOpen, setRefundPaymentConfirmationOpen] = React.useState(false);
    const [capturePaymentLoading, setCapturePaymentLoading] = React.useState(false);
    const [voidPaymentLoading, setVoidPaymentLoading] = React.useState(false);
    const [refundPaymentLoading, setRefundPaymentLoading] = React.useState(false);
    const [radarLists, setRadarLists] = React.useState<RadarListItemsResponse[] | null>(null);
    const [isProcessingTransactionAction, setIsProcessingTransactionAction] = React.useState(false);
    const [order, setOrder] = React.useState<IOrder | undefined>();
    const [clerk, setClerk] = React.useState<IClerk>();
    const [payments, setPayments] = React.useState<{
        mainPayment?: IOrderPayment;
        relatedPayments?: IOrderPayment[];
    }>({ mainPayment: null, relatedPayments: [] });

    const [isSelectedToBlock, setIsSelectedToBlock] = React.useState(false);
    const [isBlockUserLoading, setIsBlockUserLoading] = React.useState(false);

    const totalRefundedAmount = React.useMemo(
        () =>
            roundToDecimal(
                roundToDecimal(
                    payments.relatedPayments.reduce((acc, payment) => (acc += payment.amount), 0),
                    2
                ) * 100
            ),
        [payments.relatedPayments]
    );
    const amountLeftToRefund = React.useMemo(() => {
        if (transaction?.standalone) {
            return (
                (transaction?.paymentIntent?.amountReceived ?? 0) - (transaction?.charge?.amountRefunded ?? 0)
            );
        }
        if (!payments.mainPayment || !transaction) {
            return 0;
        }

        return transaction.total + totalRefundedAmount;
    }, [payments.mainPayment, totalRefundedAmount, transaction]);
    React.useEffect(() => {
        if (transaction) {
            pepperPayApi.radarList.getRadarLists(transaction.locationId).then(res => {
                if (res.ok && res.body?.data) {
                    const lists = res.body.data;
                    setRadarLists(lists);
                }
            });
        }
    }, [transaction]);
    React.useEffect(() => {
        if (transactionId) {
            pepperPayApi.transactions
                .get(transactionId)
                .then(result => {
                    setTransaction(result.body);
                })
                .catch(e => {
                    logger.error(e);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [transactionId]);

    const handleManageDispute = React.useCallback(() => {
        history.push(PEPPER_PAY_DISPUTES_VIEW.replace(':transactionId', transactionId));
    }, [history, transactionId]);
    const menuItems = [
        {
            label: 'Add to block list...',
            onClick: () => {
                setIsSelectedToBlock(true);
            }
        }
    ];
    const renderOn = React.useCallback(
        (toggle: (event: React.MouseEvent<HTMLButtonElement>) => void) => (
            <IconButton onClick={toggle}>
                <MoreVert />
            </IconButton>
        ),
        []
    );

    const handleConfirmBlock = React.useCallback(() => {
        setIsBlockUserLoading(true);
        const promises = [];
        promises.push(
            customerApi.getUser(transaction.userId, [{ key: 'include', value: 'credentials' }]).then(res => {
                if (res.ok) {
                    const emailCredential = res.body.credentials.find(
                        (credential: any) => credential.provider === 'EMAIL'
                    );
                    if (emailCredential) {
                        const emailRadarListId = radarLists.find(
                            val => val.alias === EBlockListAlias.EMAIL
                        ).id;

                        if (emailRadarListId && transaction) {
                            return pepperPayApi.radarListItems
                                .createNewListItem(
                                    emailRadarListId,
                                    {
                                        value: emailCredential.id
                                    },
                                    transaction.locationId
                                )
                                .then(res => {
                                    if (res.ok) {
                                        dispatch(
                                            enqueueSnackbar(
                                                MESSAGE_ADD_BLOCK_LIST_ITEM_TRANSACTION_SUCCESS(
                                                    emailCredential.id
                                                ),
                                                { variant: 'success' }
                                            )
                                        );
                                    } else if (res.body.code === 'E-PAY-0057') {
                                        dispatch(
                                            enqueueSnackbar(
                                                MESSAGE_ADD_BLOCK_LIST_ITEM_VALUE_ERROR(emailCredential.id),
                                                { variant: 'error' }
                                            )
                                        );
                                    }

                                    return res;
                                });
                        }

                        throw Error("Email block list id couldn't be found.");
                    }
                }

                throw Error("User details couldn't be fetched.");
            })
        );

        if (transaction && transaction.paymentIntent?.paymentMethodFingerprint) {
            const fingerPrintListId = radarLists.find(val => val.alias === EBlockListAlias.FINGERPRINT).id;

            if (fingerPrintListId) {
                const fingerPrint = transaction.paymentIntent.paymentMethodFingerprint;
                promises.push(
                    pepperPayApi.radarListItems
                        .createNewListItem(
                            fingerPrintListId,
                            {
                                value: fingerPrint
                            },
                            transaction.locationId
                        )
                        .then(res => {
                            if (res.ok) {
                                dispatch(
                                    enqueueSnackbar(
                                        MESSAGE_ADD_BLOCK_LIST_ITEM_TRANSACTION_SUCCESS(fingerPrint),
                                        { variant: 'success' }
                                    )
                                );
                            } else if (res.body.code === 'E-PAY-0057') {
                                dispatch(
                                    enqueueSnackbar(MESSAGE_ADD_BLOCK_LIST_ITEM_VALUE_ERROR(fingerPrint), {
                                        variant: 'error'
                                    })
                                );
                            }
                            return res;
                        })
                );
            }
        }

        Promise.all(promises).finally(() => {
            setIsBlockUserLoading(false);
            setIsSelectedToBlock(false);
        });
    }, [dispatch, radarLists, transaction]);
    const handleCancelBlock = React.useCallback(() => {
        setIsSelectedToBlock(false);
    }, []);

    const toggleRefundConfirmationDialog = React.useCallback(() => {
        setRefundPaymentConfirmationOpen(val => !val);
    }, []);

    const handleRefundConfirmationClosed = React.useCallback(() => {
        setRefundPaymentConfirmationOpen(false);
    }, []);

    React.useEffect(() => {
        if (isProcessingTransactionAction) {
            setTimeout(() => {
                (async () => {
                    try {
                        const result = await pepperPayApi.transactions.get(transactionId);

                        if (result.ok) {
                            setTransaction(result.body);
                            setIsProcessingTransactionAction(false);
                        } else {
                            throw Error(result.body);
                        }
                    } catch (err) {
                        logger.error(err);
                    }
                })();
            }, 5 * 1000);
        }
    }, [isProcessingTransactionAction, transactionId]);

    const handleConfirmVoidPayment = React.useCallback(() => {
        if (transaction) {
            setVoidPaymentLoading(true);
            pepperPayApi.paymentIntents
                .cancel(transaction.paymentIntent.paymentIntentId, transaction.locationId)
                .then(res => {
                    if (res.ok) {
                        dispatch(enqueueSnackbar(MESSAGE_VOID_TRANSACTION_SUCCESS, { variant: 'info' }));
                        setIsProcessingTransactionAction(true);
                    } else {
                        dispatch(enqueueSnackbar(MESSAGE_VOID_TRANSACTION_ERROR, { variant: 'error' }));
                    }
                    setVoidPaymentLoading(false);
                    setVoidPaymentOpen(false);
                });
        }
    }, [dispatch, transaction]);
    const handleToggleVoidPayment = React.useCallback(() => {
        setVoidPaymentOpen(val => !val);
    }, []);

    const handleToggleCapturePayment = React.useCallback(() => {
        setCapturePaymentOpen(val => !val);
    }, []);

    const handleRefundSuccess = React.useCallback(() => {
        setIsProcessingTransactionAction(true);
    }, []);

    const handlePrepareForRefund = React.useCallback(
        async (orderId: string, shouldReloadOrder = true) => {
            if (transaction) {
                try {
                    const orderRes = await orderApi.getOrder(orderId);

                    if (orderRes.ok) {
                        const mainPayment: IOrderPayment = orderRes.body.payments.find(
                            (payment: IOrderPayment) =>
                                payment.receiptId === transaction.paymentIntent.paymentIntentId
                        );

                        if (!mainPayment && shouldReloadOrder) {
                            orderApi.onCharged(transaction.paymentIntent.paymentIntentId);
                            handlePrepareForRefund(orderId, false);
                        } else {
                            const relatedPayments = orderRes.body.payments.filter(
                                (payment: IOrderPayment) =>
                                    !payment.deleted && payment.parentId === mainPayment.id
                            );
                            setPayments({ mainPayment, relatedPayments });
                            setOrder(orderRes.body);
                        }
                        if (!transaction.clerkId && mainPayment && mainPayment.clerkId) {
                            const clerkRes = await clerksApi.get(mainPayment.clerkId);
                            if (clerkRes.ok) {
                                setClerk(clerkRes.body);
                            } else {
                                setClerk(undefined);
                            }
                        }
                    }
                } catch (err) {
                    logger.error(err);
                }
            }
        },
        [transaction]
    );

    const handleGetClerk = React.useCallback(async (clerkId: string) => {
        if (!clerkId) {
            return;
        }

        try {
            const clerkRes = await clerksApi.get(clerkId);
            if (clerkRes.ok) {
                setClerk(clerkRes.body);
            } else {
                setClerk(undefined);
            }
        } catch (err) {
            logger.error(err);
        }
    }, []);

    const processRefund = React.useCallback(
        async (amount?: number, reason?: string, note?: string) => {
            setRefundPaymentLoading(true);
            let result: HttpClientResponseContent<OrderRefundResultOC2> | undefined;
            if (amount) {
                result = await orderApi.refundOC2ById(String(order?.id), {
                    paymentId: payments?.mainPayment?.id,
                    amount,
                    reason,
                    note
                });
            } else {
                result = await orderApi.refundOC2ById(String(order?.id), {
                    paymentId: payments?.mainPayment?.id
                });
            }
            if (!result.ok) {
                return dispatch(
                    enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_REFUNDING_ERROR, { variant: 'error' })
                );
            }
            dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_REFUNDING_PENDING, { variant: 'info' }));
            const operationResult = await orderApi.pollOC2Operation(result.body.id, result.body.etaMs);
            if (operationResult.status === OperationStatus.ERROR) {
                return dispatch(
                    enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_REFUNDING_ERROR, { variant: 'error' })
                );
            }
            dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_REFUNDING_SUCCESS, { variant: 'success' }));
            handleRefundSuccess();
            setRefundPaymentLoading(false);
            handleRefundConfirmationClosed();
        },
        [dispatch, handleRefundConfirmationClosed, handleRefundSuccess, order?.id, payments?.mainPayment?.id]
    );

    const processStandaloneRefund = React.useCallback(
        async (amount?: number, reason?: string, note?: string) => {
            setRefundPaymentLoading(true);
            const result = await pepperPayApi.paymentIntents.refund(
                transaction?.paymentIntent?.paymentIntentId,
                {
                    amount,
                    reason,
                    note
                }
            );
            if (!result.ok) {
                return dispatch(
                    enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_REFUNDING_ERROR, { variant: 'error' })
                );
            }
            dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_REFUNDING_SUCCESS, { variant: 'success' }));
            handleRefundSuccess();
            setRefundPaymentLoading(false);
            handleRefundConfirmationClosed();
        },
        [dispatch, handleRefundConfirmationClosed, handleRefundSuccess, transaction]
    );

    React.useEffect(() => {
        if (transaction) {
            if (isDefined(transaction.order?.orderId)) {
                handlePrepareForRefund(transaction.order.orderId);
            }
            if (isDefined(transaction.clerkId)) {
                handleGetClerk(transaction.clerkId);
            }
        }
    }, [transaction, handlePrepareForRefund, handleGetClerk]);

    const handleConfirmCapture = React.useCallback(async () => {
        setCapturePaymentLoading(true);
        const result = await pepperPayApi.paymentIntents.capture(
            transaction?.paymentIntent?.paymentIntentId,
            transaction?.paymentIntent?.amountCapturable
        );
        if (!result.ok) {
            dispatch(enqueueSnackbar(MESSAGE_CAPTURE_PAYMENT_ERROR, { variant: 'error' }));
        } else {
            dispatch(enqueueSnackbar(MESSAGE_CAPTURE_PAYMENT_SUCCESS, { variant: 'info' }));
            setIsProcessingTransactionAction(true);
        }
        setCapturePaymentLoading(false);
        setCapturePaymentOpen(false);
    }, [dispatch, transaction?.paymentIntent?.amountCapturable, transaction?.paymentIntent?.paymentIntentId]);

    const currency = isCurrencyCode(transaction?.currency) ? transaction?.currency : 'GBP';

    const capturableAmount = React.useMemo(
        () =>
            Dinero({
                amount: transaction?.paymentIntent?.amountCapturable ?? 0,
                currency
            }).toFormat(),
        [currency, transaction?.paymentIntent?.amountCapturable]
    );

    if (!transactionId || (!loading && !transaction)) {
        return <Redirect to={PEPPER_PAY_TRANSACTIONS} />;
    }
    if (loading) {
        return <Throbber />;
    }

    return (
        <MainLayout
            breadcrumbs={[{ label: 'Transactions', url: PEPPER_PAY_TRANSACTIONS }]}
            pageName={transactionId}
            pageDescription="View and interact with selected transaction."
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <Box padding={2}>
                            <Row align="space-between" valign="flex-start">
                                <Row valign="center" gutter>
                                    <Box>
                                        <Typography variant="h6">Overview</Typography>
                                        <Typography gutterBottom variant="caption">
                                            {formatDateTime(new Date(transaction.created))}
                                        </Typography>
                                    </Box>
                                    <TransactionStateChip value={transaction.state} />
                                </Row>
                                <Row gutter>
                                    {transaction.dispute?.disputeId && transaction.state === 'DISPUTED' && (
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            onClick={handleManageDispute}
                                        >
                                            Manage Dispute
                                        </Button>
                                    )}
                                    {(transaction.order?.orderId || transaction.standalone) &&
                                        !order?.isOpen &&
                                        (transaction.state === 'COMPLETED' ||
                                            transaction.state === 'PARTIALLY_REFUNDED') && (
                                            <Tooltip
                                                title={
                                                    !!isProcessingTransactionAction
                                                        ? 'This transaction is currently in the process of being refunded.'
                                                        : ''
                                                }
                                            >
                                                <span>
                                                    <LoadingButton
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={toggleRefundConfirmationDialog}
                                                        disabled={
                                                            isProcessingTransactionAction ||
                                                            amountLeftToRefund === 0
                                                        }
                                                        loading={isProcessingTransactionAction}
                                                    >
                                                        Refund
                                                    </LoadingButton>
                                                </span>
                                            </Tooltip>
                                        )}
                                    {transaction.paymentIntent.paymentIntentId &&
                                        transaction.state === 'PENDING' && (
                                            <Tooltip
                                                title={
                                                    !!isProcessingTransactionAction
                                                        ? 'This transaction is currently in the process of being voided.'
                                                        : ''
                                                }
                                            >
                                                <span>
                                                    <Button
                                                        color="primary"
                                                        variant="outlined"
                                                        onClick={handleToggleVoidPayment}
                                                        disabled={isProcessingTransactionAction}
                                                    >
                                                        Void
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        )}
                                    {transaction.paymentIntent.status === 'requires_capture' &&
                                        transaction.state === 'PENDING' && (
                                            <span>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    onClick={handleToggleCapturePayment}
                                                    disabled={isProcessingTransactionAction}
                                                >
                                                    Capture
                                                </Button>
                                            </span>
                                        )}
                                    <MenuOn items={menuItems} renderOn={renderOn} />
                                </Row>
                            </Row>
                            {!!transaction && (
                                <TransactionOverview
                                    transaction={transaction}
                                    clerk={clerk}
                                    refundedAmount={totalRefundedAmount}
                                />
                            )}
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PepperPayPaymentDetailsBlock transaction={transaction} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PepperPayChargeBlock transaction={transaction} />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Paper>
                        <Box padding={2}>
                            <Typography variant="h6" gutterBottom>
                                Timeline
                            </Typography>
                            <PepperPayTimelineComponent timeline={transaction.timeline} />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            <ConfirmDialog
                confirmLabel="Add"
                cancelLabel="Cancel"
                title="Add to block list"
                content={`Adding a payment to a block list blocks all future charges that have
                the same user's email or card fingerprint. You should only add them to
                the block list if you believe that this is not a legitimate customer.`}
                open={!!isSelectedToBlock}
                onConfirm={handleConfirmBlock}
                onCancel={handleCancelBlock}
                loading={isBlockUserLoading}
            />
            <ConfirmDialog
                confirmLabel="Confirm"
                cancelLabel="Cancel"
                title="Void Transaction"
                content="Please confirm you want to void this transaction."
                open={!!voidPaymentOpen}
                onConfirm={handleConfirmVoidPayment}
                onCancel={handleToggleVoidPayment}
                loading={voidPaymentLoading}
            />
            <ConfirmDialog
                confirmLabel="Confirm"
                cancelLabel="Cancel"
                title={`Capture Payment of ${capturableAmount}`}
                content={
                    // eslint-disable-next-line max-len
                    'This payment was authorised, but has not been captured/completed. This may be because we couldn’t declare the order on the POS. Please check on POS that the payment has been declared. If payment has been declared on the POS then it may be that another payment was taken and this uncaptured payment should not be captured.\n\nWould you like to continue and capture this payment?'
                }
                open={!!capturePaymentOpen}
                onConfirm={handleConfirmCapture}
                onCancel={handleToggleCapturePayment}
                loading={capturePaymentLoading}
            />
            <CustomRefundDialog
                loading={refundPaymentLoading}
                payment={refundPaymentConfirmationOpen ? payments.mainPayment : undefined}
                order={order}
                onClose={handleRefundConfirmationClosed}
                onRefund={processRefund}
            />
            <StandalonePaymentRefundDialog
                loading={refundPaymentLoading}
                open={refundPaymentConfirmationOpen && transaction?.standalone}
                onClose={handleRefundConfirmationClosed}
                onRefund={processStandaloneRefund}
                amount={transaction?.paymentIntent?.amountReceived}
                preauthAmount={transaction?.paymentIntent?.amount}
                paymentMethodBrand={transaction?.paymentIntent?.paymentMethodBrand}
                last4={transaction?.paymentIntent?.lastFour}
                currency={transaction.currency}
                refundedAmount={transaction?.charge?.amountRefunded}
            />
        </MainLayout>
    );
};
