import * as React from 'react';
import { LocationInAppLinksCardComponent } from 'components/location/cards/LocationInAppLinksCardComponent';
import { LocationInAppLinksEditCardComponent } from 'components/location/cards/LocationInAppLinksEditCardComponent';
import { createLocationInAppLinksForm } from 'components/location/forms/createLocationInAppLinksForm';
import { Location } from 'components/location/models/LocationModel';
import { LegacyForm } from 'lib/LegacyForm';
import { Panel } from 'ui/Panel';
import { locationApi } from '../LocationApi';

export interface LocationInAppLinksCardProps {
    currentLocation: Location;
    setCurrentLocation: (location: Location) => void;
}

export class LocationInAppLinksCard extends React.Component<
    LocationInAppLinksCardProps,
    { isEditing: boolean }
> {
    state = {
        isEditing: false
    };
    handleSubmit = async (data: any) => {
        const locationId = this.props.currentLocation._id;
        const { citymapper, facebook, uber, twitter, instagram } = data;

        const response = await locationApi.update(locationId, {
            body: {
                links: {
                    facebook: { enabled: !!facebook, parameters: facebook },
                    twitter: { enabled: !!twitter, parameters: twitter },
                    instagram: { enabled: !!instagram, parameters: instagram },
                    // No parameters for these
                    citymapper: { enabled: citymapper },
                    uber: { enabled: uber },
                    hailo: { enabled: false },
                    foursquare: { enabled: false }
                }
            }
        });
        if (response.ok) {
            this.updateForm(response.body);
            this.props.setCurrentLocation(response.body);
        }
        this.setState({ isEditing: false });
    };
    // eslint-disable-next-line @typescript-eslint/member-ordering
    form = new LegacyForm(createLocationInAppLinksForm(), this.handleSubmit);
    componentDidMount() {
        this.updateForm(this.props.currentLocation);
    }
    updateForm = (data: Location) => {
        const { citymapper, hailo, uber, facebook, twitter, instagram, ...rest } = data.links;
        this.form.update({
            ...rest,
            facebook: facebook ? facebook.parameters : '',
            twitter: twitter ? twitter.parameters : '',
            instagram: instagram ? instagram.parameters : '',
            // No parameters for these
            citymapper: citymapper && citymapper.enabled,
            hailo: hailo && hailo.enabled,
            uber: uber && uber.enabled
        });
    };
    toggleState = () => {
        this.setState(({ isEditing }) => ({ isEditing: !isEditing }));
        this.updateForm(this.props.currentLocation);
    };
    render() {
        const { isEditing } = this.state;
        return (
            <Panel title="Links" showAction={!isEditing} onClick={this.toggleState} divider>
                {isEditing ? (
                    <LocationInAppLinksEditCardComponent
                        onClose={this.toggleState}
                        form={this.form}
                        links={this.props.currentLocation.links}
                    />
                ) : (
                    <LocationInAppLinksCardComponent location={this.props.currentLocation} />
                )}
            </Panel>
        );
    }
}
