import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Box,
    FilterOptionsState,
    TextField,
    Typography,
    createFilterOptions
} from '@mui/material';
import { AudienceCreateModal } from 'components/audience/modals/AudienceCreateModal';
import { Audience } from 'components/audience/models/segmentModel';
import { ReadOnlyOverlay } from 'lib/form/ReadOnlyOverlay';
import { isString } from 'lib/typeguards';
import { Option } from 'lib/types';
import React from 'react';

interface CreatableOption extends Option {
    inputValue?: string;
}

const filter = createFilterOptions<CreatableOption>();

interface PremiumLoyaltyAudienceSelectProps {
    value: string;
    loading?: boolean;
    onChange: (value: string) => void;
    readOnly?: boolean;
    excludeAudiences?: string[];
    options: Option[];
}

export const PremiumLoyaltyAudienceSelect: React.FC<PremiumLoyaltyAudienceSelectProps> = ({
    value,
    loading,
    onChange,
    readOnly,
    excludeAudiences,
    options
}) => {
    const [createAudienceOpen, setCreateAudienceOpen] = React.useState(false);
    const [audienceTitle, setAudienceTitle] = React.useState<string>();
    const handleAudienceCreateOpen = React.useCallback((newAudienceTitle: string) => {
        setAudienceTitle(newAudienceTitle);
        setCreateAudienceOpen(true);
    }, []);
    const handleAudienceCreateSuccess = React.useCallback(
        (audience: Audience) => {
            onChange(audience._id);
            setAudienceTitle(undefined);
            setCreateAudienceOpen(false);
        },
        [onChange]
    );
    const handleAudienceCreateClose = React.useCallback(() => {
        setAudienceTitle(undefined);
        setCreateAudienceOpen(false);
    }, []);
    const availableAudiences = React.useMemo(
        () => options.filter(item => value === item.value || !excludeAudiences?.includes(item.value)),
        [options, value, excludeAudiences]
    );
    const renderAutocompleteInput = React.useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                {...params}
                InputProps={{
                    readOnly,
                    sx: { bgcolor: readOnly ? 'divider' : undefined },
                    ...params.InputProps
                }}
                label="Audience"
                variant="outlined"
            />
        ),
        [readOnly]
    );
    const getOptionKey = React.useCallback((option: Option) => option.value, []);
    const handleAudienceChange = React.useCallback(
        (_: never, newValue: CreatableOption) => {
            if (newValue?.value === null && isString(newValue.inputValue)) {
                handleAudienceCreateOpen(newValue.inputValue);
            } else {
                onChange(newValue.value);
            }
        },
        [handleAudienceCreateOpen, onChange]
    );
    const selectedAudienceOption = React.useMemo(
        () => options?.find(item => item.value === value),
        [options, value]
    );
    const filterOptions = React.useCallback(
        (options: CreatableOption[], params: FilterOptionsState<Option>) => {
            const filtered = filter(options, params);

            if (params.inputValue !== '') {
                filtered.push({
                    inputValue: params.inputValue,
                    label: `+ Create a new "${params.inputValue}" audience`,
                    value: null,
                    color: 'primary'
                });
            }

            return filtered;
        },
        []
    );
    const renderOption = React.useCallback(
        (props: any, option: CreatableOption) => (
            <Box {...props}>
                <Typography color={option.color}>{option.label}</Typography>
            </Box>
        ),
        []
    );
    return (
        <React.Fragment>
            <Autocomplete
                options={availableAudiences}
                renderInput={renderAutocompleteInput}
                autoComplete
                getOptionKey={getOptionKey}
                disableClearable
                value={selectedAudienceOption ?? null}
                disabled={loading}
                onChange={handleAudienceChange}
                readOnly={!!readOnly}
                sx={{ opacity: readOnly ? 0.8 : undefined }}
                filterOptions={filterOptions}
                renderOption={renderOption}
            />
            {!!readOnly && <ReadOnlyOverlay />}
            <AudienceCreateModal
                initialData={{ title: audienceTitle }}
                open={createAudienceOpen}
                onClose={handleAudienceCreateClose}
                onSuccess={handleAudienceCreateSuccess}
            />
        </React.Fragment>
    );
};
