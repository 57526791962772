import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

import { ApplicationState } from 'store/store';
import { getAllLocationTitles } from 'store/locations/locationsActions';
import { MainLayout } from 'layouts/MainLayout';
import { AdjustmentSchemesCreationModal } from 'components/adjustment-schemes/modals/AdjustmentSchemesCreationModal';
import { AdjustmentSchemeEditModal } from 'components/adjustment-schemes/modals/AdjustmentSchemeEditModal';
import {
    adjustmentSchemesReducer,
    initialState,
    AdjustmentSchemesActionType
} from 'components/adjustment-schemes/reducers/adjustmentSchemesReducer';
import { AdjustmentSchemesTable } from 'components/adjustment-schemes/tables/AdjustmentSchemesTable';
import { useAdjustmentSchemesMutation } from 'components/adjustment-schemes/hooks/useAdjustmentSchemesMutation';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { useAdjustmentSchemesData } from 'components/adjustment-schemes/hooks/useAdjustmentSchemesData';
import { AdjustmentScheme } from 'components/adjustment-schemes/adjustmentSchemeModel';

export const AdjustmentSchemesPage = () => {
    const globalDispatch = useDispatch();
    const [state, dispatch] = React.useReducer(adjustmentSchemesReducer, initialState);
    const currencySymbol = useSelector(
        (state: ApplicationState) => state.settings.settings.region?.currencySymbol
    );
    const {
        createModalOpen,
        updateModalOpen,
        deleteModalOpen,
        closeCreateModalOpen,
        closeUpdateModalOpen,
        selectedRow
    } = state;

    const {
        schemes,
        loading: listLoading,
        count,
        noData,
        onCreateSuccess,
        onUpdateSuccess,
        onDeleteSuccess
    } = useAdjustmentSchemesData();

    const selectedScheme = React.useMemo(
        () => schemes.find(scheme => selectedRow[0] === scheme._id),
        [schemes, selectedRow]
    );

    const existingCodes = React.useMemo(() => schemes.map(scheme => scheme.schemeId), [schemes]);

    const handleDeleteSuccess = React.useCallback(
        (scheme: AdjustmentScheme) => {
            onDeleteSuccess(scheme);
            dispatch({ type: AdjustmentSchemesActionType.DELETE_SUCCESS });
        },
        [onDeleteSuccess]
    );

    const { isProcessing, handleConfirmDelete } = useAdjustmentSchemesMutation({
        selectedScheme,
        handleDeleteSuccess
    });

    const handleCreateClick = React.useCallback(() => {
        dispatch({ type: AdjustmentSchemesActionType.MODAL_ACTION_CREATE });
    }, []);

    React.useEffect(() => {
        if (noData) {
            handleCreateClick();
        }
    }, [noData, handleCreateClick]);

    React.useEffect(() => {
        getAllLocationTitles(globalDispatch);
    }, [globalDispatch]);

    const handleCloseCreateModal = React.useCallback(() => {
        dispatch({ type: AdjustmentSchemesActionType.TOGGLE_CLOSE_CREATE_MODAL });
    }, []);

    const handleCreateSuccess = React.useCallback(
        (scheme: AdjustmentScheme) => {
            onCreateSuccess(scheme);
            dispatch({ type: AdjustmentSchemesActionType.CREATE_SUCCESS });
        },
        [onCreateSuccess]
    );

    const handleUpdateClick = React.useCallback(() => {
        dispatch({
            type: AdjustmentSchemesActionType.MODAL_ACTION_UPDATE
        });
    }, []);

    const handleUpdateSuccess = React.useCallback(
        (scheme: AdjustmentScheme) => {
            onUpdateSuccess(scheme);
            dispatch({ type: AdjustmentSchemesActionType.UPDATE_SUCCESS });
        },
        [onUpdateSuccess]
    );

    const handleCloseUpdateModal = React.useCallback(() => {
        dispatch({ type: AdjustmentSchemesActionType.TOGGLE_CLOSE_UPDATE_MODAL });
    }, []);

    const handleDeleteClick = React.useCallback(() => {
        dispatch({
            type: AdjustmentSchemesActionType.MODAL_ACTION_DELETE,
            selectedScheme
        });
    }, [selectedScheme]);

    const handleCloseAllModals = React.useCallback(() => {
        dispatch({ type: AdjustmentSchemesActionType.CLOSE_ALL_MODALS });
    }, []);

    const handleSelectRow = React.useCallback((newSelectedRow: GridRowSelectionModel) => {
        if (newSelectedRow.length > 1) {
            dispatch({
                selectedRow: [newSelectedRow[newSelectedRow.length - 1]],
                type: AdjustmentSchemesActionType.SET_SELECTED_ROW
            });
        } else {
            dispatch({ selectedRow: newSelectedRow, type: AdjustmentSchemesActionType.SET_SELECTED_ROW });
        }
    }, []);

    return (
        <MainLayout
            pageName="Adjustment Schemes"
            pageDescription="Incentivize your customers with adjustment schemes."
            noScroll
        >
            <AdjustmentSchemesTable
                loading={listLoading}
                schemes={schemes}
                selectedScheme={selectedScheme}
                count={count}
                selectedRow={selectedRow}
                handleSelectRow={handleSelectRow}
                onCreateClick={handleCreateClick}
                onUpdateClick={handleUpdateClick}
                onDeleteClick={handleDeleteClick}
            />
            <AdjustmentSchemesCreationModal
                open={createModalOpen}
                existingCodes={existingCodes}
                currencySymbol={currencySymbol}
                onClose={handleCloseCreateModal}
                onSuccess={handleCreateSuccess}
            />
            <DeleteDialog
                submitLabel="Yes, Cancel"
                discardLabel="No, don't cancel"
                title="Hold On!"
                content="Are you sure you want to cancel this adjustment creation process? All changes you made will be lost."
                open={closeCreateModalOpen}
                onDelete={handleCloseAllModals}
                onClose={handleCloseCreateModal}
            />
            <AdjustmentSchemeEditModal
                open={updateModalOpen}
                existingCodes={existingCodes}
                onClose={handleCloseUpdateModal}
                scheme={selectedScheme}
                currencySymbol={currencySymbol}
                onSuccess={handleUpdateSuccess}
            />
            <DeleteDialog
                submitLabel="Yes, Cancel"
                discardLabel="No, don't cancel"
                title="Hold On!"
                content="Are you sure you want to cancel this adjustment update process? All changes you made will be lost."
                open={closeUpdateModalOpen}
                onDelete={handleCloseAllModals}
                onClose={handleCloseUpdateModal}
            />
            <DeleteDialog
                title="Delete Adjustment Scheme"
                // eslint-disable-next-line max-len
                content="If you delete this adjustment scheme, you will not be able to recover when deleted. To confirm deletion, type 'DELETE' in the text input field."
                open={deleteModalOpen}
                onDelete={handleConfirmDelete}
                onClose={handleCloseAllModals}
                protectionWord="DELETE"
                loading={isProcessing}
            />
        </MainLayout>
    );
};
