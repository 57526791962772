import { styled } from '@mui/material';
import { VerticalAlignment, TextAlignment } from 'components/loyalty/models/PerkModel';

type ExpiryLabelPreviewProps = {
    vertical: VerticalAlignment.TOP | VerticalAlignment.BOTTOM;
    horizontal: TextAlignment;
    primaryActionBackgroundColour?: string | null;
};

const expiryLabelPreviewProps: PropertyKey[] = ['vertical', 'horizontal', 'primaryActionBackgroundColour'];

export const ExpiryLabelPreview = styled('div', {
    shouldForwardProp: propName => !expiryLabelPreviewProps.includes(propName)
})<ExpiryLabelPreviewProps>(({ theme, vertical, horizontal, primaryActionBackgroundColour }) => ({
    height: theme.spacing(4),
    width: horizontal === TextAlignment.JUSTIFY ? '100%' : '35%',
    boxSizing: 'border-box',
    backgroundColor: primaryActionBackgroundColour || theme.palette.primary.main,
    ...(vertical === VerticalAlignment.BOTTOM
        ? {
              borderTopLeftRadius: horizontal === TextAlignment.LEFT ? 0 : theme.shape.borderRadius,
              borderTopRightRadius: horizontal === TextAlignment.RIGHT ? 0 : theme.shape.borderRadius
          }
        : {
              borderBottomLeftRadius: horizontal === TextAlignment.LEFT ? 0 : theme.shape.borderRadius,
              borderBottomRightRadius: horizontal === TextAlignment.RIGHT ? 0 : theme.shape.borderRadius
          }),

    position: 'absolute',
    top: vertical === VerticalAlignment.TOP ? 0 : 'auto',
    bottom: 0,
    ...(horizontal !== TextAlignment.JUSTIFY
        ? {
              right: horizontal === TextAlignment.RIGHT ? 0 : 'auto',
              left: horizontal === TextAlignment.LEFT ? 0 : 'auto'
          }
        : {
              right: 0,
              left: 0
          }),
    marginInline: horizontal === TextAlignment.CENTER ? theme.spacing(18) : 0
}));
