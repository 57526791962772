import React from 'react';
import { Box, Step, StepButton, StepLabel, Stepper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from 'ui/buttons/IconButton';
import { Column, Row } from 'ui/Flex';
import { IAwardEnrichment, IAwardEnrichmentCreate } from 'components/loyalty/models/AwardEnrichment';
import {
    awardGeneralDefaultFormValues,
    AwardGeneralForm,
    AwardGeneralFormData
} from './forms/AwardGeneralForm';
import { AwardConfigureForm, AwardConfigureFormData } from './forms/AwardConfigureForm';
import { AwardRedemptionForm, AwardRedemptionFormData } from './forms/AwardRedemptionForm';
import { AwardPreviewForm } from './forms/AwardPreviewForm';
import { awardEnrichmentApi } from 'components/loyalty/AwardEnrichmentsApi';
import { LoyaltyProvider } from 'components/loyalty/enums';
import {
    generateAwardConfigureFormData,
    generateAwardRedemptionFormData,
    getAwardEnrichmentCreateData
} from './utils';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { useDispatch, useSelector } from 'react-redux';
import {
    MESSAGE_MARKETING_AWARD_UPDATE_ERROR,
    MESSAGE_MARKETING_AWARD_UPDATE_SUCCESS
} from 'config/messages';
import { ApplicationState } from 'store/store';

export enum AwardFormStep {
    GENERAL = 'GENERAL',
    CONFIGURE = 'CONFIGURE',
    REDEMPTION = 'REDEMPTION'
}

export type StepUpdateMetadata =
    | {
          type: AwardFormStep.GENERAL;
          values: AwardGeneralFormData;
      }
    | {
          type: AwardFormStep.CONFIGURE;
          values: AwardConfigureFormData;
      }
    | {
          type: AwardFormStep.REDEMPTION;
          values: AwardRedemptionFormData;
      };

export interface AwardEditStepperProps {
    onClose: () => void;
    onSubmit: (award: IAwardEnrichment) => void;
    award: IAwardEnrichment;
    currencySymbol: string;
    primaryActionBackgroundColour: string;
    initialGeneralData?: Partial<AwardGeneralFormData>;
    loyaltyProvider: LoyaltyProvider;
}

export const AwardEditStepper: React.FC<AwardEditStepperProps> = ({
    onClose,
    onSubmit,
    currencySymbol,
    primaryActionBackgroundColour,
    award,
    loyaltyProvider
}) => {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);
    const [steps] = React.useState(['Define', 'Configure', 'Redemption', 'Review']);
    const [activeStep, setActiveStep] = React.useState(0);
    const [generalData, setGeneralData] = React.useState<AwardGeneralFormData>({
        ...awardGeneralDefaultFormValues,
        title: award.title,
        description: award.visualisation.description,
        loyaltyRewardId: award.loyaltyRewardId,
        order: award.visualisation.priority?.toString() || ''
    });
    const [configureData, setConfigureData] = React.useState<AwardConfigureFormData>(
        generateAwardConfigureFormData(award)
    );
    const [redemptionData, setRedemptionData] = React.useState<AwardRedemptionFormData>(
        generateAwardRedemptionFormData(award)
    );
    const handleStepClick = React.useCallback(
        (index: number) => () => {
            setActiveStep(index);
        },
        []
    );

    const handleGeneralSubmit = React.useCallback((values: AwardGeneralFormData) => {
        setGeneralData(values);
        setActiveStep(1);
    }, []);
    const handleConfigureSubmit = React.useCallback((values: AwardConfigureFormData) => {
        setConfigureData(values);
        setActiveStep(2);
    }, []);
    const handleRedemptionSubmit = React.useCallback((values: AwardRedemptionFormData) => {
        setRedemptionData(values);
        setActiveStep(3);
    }, []);
    const handlePrevious = React.useCallback(() => {
        setActiveStep(activeStep - 1);
    }, [activeStep]);

    const handleUpdateAward = React.useCallback(
        async (updateData: IAwardEnrichmentCreate) => {
            setIsLoading(true);
            try {
                const result = await awardEnrichmentApi.update(award._id, {
                    body: updateData
                });

                if (result.ok) {
                    dispatch(enqueueSnackbar(MESSAGE_MARKETING_AWARD_UPDATE_SUCCESS, { variant: 'success' }));
                    return onSubmit(result.body);
                }
                throw Error(result.body);
            } catch (e) {
                dispatch(enqueueSnackbar(MESSAGE_MARKETING_AWARD_UPDATE_ERROR, { variant: 'error' }));
            } finally {
                setIsLoading(false);
            }
        },
        [award._id, dispatch, onSubmit]
    );
    const handleStepUpdate = React.useCallback(
        (updateData: StepUpdateMetadata) => {
            switch (updateData.type) {
                case AwardFormStep.GENERAL:
                    setGeneralData(updateData.values);
                    break;
                case AwardFormStep.CONFIGURE:
                    setConfigureData(updateData.values);
                    break;
                case AwardFormStep.REDEMPTION:
                    setRedemptionData(updateData.values);
                    break;
                default:
                    break;
            }
            const dataToUpdate: IAwardEnrichmentCreate = getAwardEnrichmentCreateData(
                {
                    ...generalData,
                    ...configureData,
                    ...redemptionData,
                    ...updateData.values
                },
                loyaltyProvider,
                settings?.region?.timezone
            );

            handleUpdateAward(dataToUpdate);
        },
        [configureData, generalData, handleUpdateAward, loyaltyProvider, redemptionData, settings]
    );

    const handleReviewUpdateAward = React.useCallback(() => {
        const dataToUpdate: IAwardEnrichmentCreate = getAwardEnrichmentCreateData(
            {
                ...generalData,
                ...configureData,
                ...redemptionData
            },
            loyaltyProvider,
            settings?.region?.timezone
        );

        handleUpdateAward(dataToUpdate);
    }, [configureData, generalData, handleUpdateAward, loyaltyProvider, redemptionData, settings]);

    return (
        <Box width="100%" maxWidth={1600}>
            <Column>
                <Row valign="flex-start" align="center">
                    <Box flex={1} maxWidth={500}>
                        <Stepper nonLinear alternativeLabel activeStep={activeStep}>
                            {steps.map((item, index) => (
                                <Step key={`step-button-${item}`} completed={activeStep > index}>
                                    <StepButton
                                        disableRipple
                                        disabled={activeStep < index}
                                        onClick={handleStepClick(index)}
                                        color="inherit"
                                    >
                                        <StepLabel>{item}</StepLabel>
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <Box position="absolute" top={0} right={0}>
                        <IconButton size="large">
                            <CloseIcon onClick={onClose} />
                        </IconButton>
                    </Box>
                </Row>
                <AwardGeneralForm
                    open={activeStep === 0}
                    initialValues={generalData}
                    onSubmit={handleGeneralSubmit}
                    onUpdate={handleStepUpdate}
                    isLoading={isLoading}
                    isEdit
                />
                <AwardConfigureForm
                    open={activeStep === 1}
                    initialValues={configureData}
                    onSubmit={handleConfigureSubmit}
                    clickPrevious={handlePrevious}
                    onPrevious={handlePrevious}
                    currencySymbol={currencySymbol}
                    primaryActionBackgroundColour={primaryActionBackgroundColour}
                    title={generalData && generalData.title}
                    onUpdate={handleStepUpdate}
                    isLoading={isLoading}
                    isEdit
                />
                <AwardRedemptionForm
                    open={activeStep === 2}
                    initialValues={redemptionData}
                    onSubmit={handleRedemptionSubmit}
                    clickPrevious={handlePrevious}
                    onPrevious={handlePrevious}
                    onUpdate={handleStepUpdate}
                    isLoading={isLoading}
                    isEdit
                    auto={configureData.auto}
                />
                <AwardPreviewForm
                    open={activeStep === 3}
                    data={{ ...generalData, ...configureData }}
                    clickPrevious={handlePrevious}
                    submit={handleReviewUpdateAward}
                    isLoading={isLoading}
                    label="Update linked Award"
                    primaryActionBackgroundColour={primaryActionBackgroundColour}
                />
            </Column>
        </Box>
    );
};
