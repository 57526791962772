import { Crud, HttpClient, httpClient } from 'lib/HttpClient';
import { PremiumLoyaltyConditionBody, PremiumLoyaltyListenerBody, UserMetric } from './model';
import { v4 } from 'uuid';

class MetricsApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.API_SERVER_URL, 'metrics/user');
    }
    addMetric(definitionId: string, metricId: string, data: UserMetric) {
        return this.httpClient.post(`${this.api}/metrics/user/${definitionId}/metrics/${metricId}`, {
            body: {
                ...data
            }
        });
    }
    deleteMetric(definitionId: string, metricId: string) {
        return this.httpClient.delete(`${this.api}/metrics/user/${definitionId}/metrics/${metricId}`, {});
    }
    addEffect(definitionId: string, body: PremiumLoyaltyListenerBody) {
        return this.httpClient.post(`${this.api}/metrics/user/${definitionId}/effects`, { body });
    }
    deleteEffect(definitionId: string, listenerId: string) {
        return this.httpClient.delete(`${this.api}/metrics/user/${definitionId}/effects/${listenerId}`, {});
    }
    addEffectCondition(definitionId: string, listenerId: string, body: PremiumLoyaltyConditionBody) {
        return this.httpClient.post(
            `${this.api}/metrics/user/${definitionId}/effects/${listenerId}/conditions`,
            { body }
        );
    }
    deleteEffectCondition(definitionId: string, listenerId: string, conditionId: string) {
        return this.httpClient.delete(
            `${this.api}/metrics/user/${definitionId}/effects/${listenerId}/conditions/${conditionId}`,
            {}
        );
    }
    compute(definitionId: string) {
        return this.httpClient.post(`${this.api}/metrics/user/${definitionId}/compute`, {
            headers: {
                'x-pepper-req-nonce': v4()
            }
        });
    }
}

export const metricsApi = new MetricsApi(httpClient);
