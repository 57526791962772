import React, { CSSProperties } from 'react';
import { InputBase, TextField, TextFieldVariants } from '@mui/material';
import { Field, FieldAttributes, FieldProps, useFormikContext } from 'formik';

interface TextFieldProps {
    label?: React.ReactNode;
    placeholder?: string;
    multiline?: boolean;
    maxRows?: number;
    minRows?: number;
    disabled?: boolean;
    textColor?: string;
    inputRef?: React.Ref<HTMLInputElement>;
    description?: React.ReactNode;
    type?: string;
    fontSize?: string;
    textAlign?: CSSProperties['textAlign'];
    naked?: boolean;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    max?: number;
    min?: number;
    className?: string;
    error?: string;
    step?: number;
    readOnly?: boolean;
    shrink?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    variant?: TextFieldVariants;
    size?: 'small' | 'medium';
}

export const TextFormField: React.FC<FieldAttributes<TextFieldProps>> = ({
    label,
    placeholder,
    description,
    multiline,
    textColor,
    disabled,
    naked,
    textAlign,
    fontSize,
    step,
    maxRows,
    minRows,
    inputRef,
    readOnly,
    onKeyDown,
    onClick,
    error,
    max,
    min,
    className,
    shrink,
    variant,
    size,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <TextFormFieldComponent
                label={label}
                placeholder={placeholder}
                multiline={multiline}
                type={props.type}
                naked={naked}
                textColor={textColor}
                textAlign={textAlign}
                disabled={disabled}
                fontSize={fontSize}
                inputRef={inputRef}
                description={description}
                maxRows={maxRows}
                minRows={minRows}
                min={min}
                onClick={onClick}
                step={step}
                max={max}
                error={error}
                shrink={shrink}
                onKeyDown={onKeyDown}
                className={className}
                readOnly={readOnly}
                variant={variant}
                size={size}
                {...childProps}
            />
        )}
    </Field>
);

const TextFormFieldComponent: React.FC<FieldProps & TextFieldProps> = ({
    field,
    meta,
    label,
    type,
    fontSize,
    placeholder,
    description,
    multiline,
    textColor,
    min,
    max,
    step,
    disabled,
    inputRef,
    textAlign,
    naked,
    error,
    onKeyDown,
    onClick,
    className,
    readOnly,
    shrink,
    maxRows,
    minRows,
    variant,
    size
}) => {
    const { isSubmitting } = useFormikContext();
    const isError = React.useMemo(
        () => meta.touched && (!!meta.error || !!error),
        [error, meta.error, meta.touched]
    );
    const helperText = React.useMemo(
        () => (isError ? error || meta.error : description),
        [description, error, isError, meta.error]
    );

    if (naked) {
        return (
            <InputBase
                fullWidth
                placeholder={placeholder}
                type={type}
                multiline={multiline}
                maxRows={maxRows || (multiline && 4)}
                minRows={minRows || (multiline && 1)}
                name={field.name}
                disabled={disabled || isSubmitting}
                style={fontSize ? { fontSize } : {}}
                inputProps={{ min, max, style: { textAlign: textAlign || 'start', color: textColor } }}
                inputRef={inputRef}
                value={field.value ?? ''}
                onClick={onClick}
                onChange={field.onChange}
                onBlur={field.onBlur}
                onKeyDown={onKeyDown}
                className={className}
                readOnly={readOnly}
            />
        );
    }

    return (
        <TextField
            fullWidth
            placeholder={placeholder}
            type={type}
            multiline={multiline}
            maxRows={maxRows || (multiline && 4)}
            minRows={minRows || (multiline && 1)}
            name={field.name}
            label={label}
            disabled={disabled || isSubmitting}
            inputRef={inputRef}
            value={field.value ?? ''}
            onChange={field.onChange}
            inputProps={{ min, max, step }}
            InputLabelProps={{ shrink }}
            onBlur={field.onBlur}
            onKeyDown={onKeyDown}
            onClick={onClick}
            error={isError}
            helperText={helperText}
            className={className}
            variant={variant}
            size={size}
            InputProps={{
                readOnly
            }}
        />
    );
};
