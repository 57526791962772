import { MESSAGE_DOWNLOAD_ASSET_ERROR } from 'config/messages';
import React from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';

interface IConfig {
    src: string;
    fileName?: string;
}

export function useDownloadAsset(config: IConfig) {
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    const handleDownload = React.useCallback(async () => {
        if (!config.src) {
            return;
        }
        setLoading(true);

        try {
            const url = new URL(config.src);
            if (!url.searchParams.has('date')) {
                url.searchParams.set('date', new Date().toLocaleTimeString());
            }
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`Failed to download asset: ${response.statusText}`);
            }

            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = config.fileName || 'download';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            dispatch(enqueueSnackbar(MESSAGE_DOWNLOAD_ASSET_ERROR, { variant: 'error' }));
        } finally {
            setLoading(false);
        }
    }, [config, dispatch]);

    return { loading, handleDownload };
}
