import { Grid } from '@mui/material';
import { usePremiumLoyaltyStateModal } from 'components/metrics/hooks/usePremiumLoyaltyStateModal';
import {
    EPremiumLoyaltyScheme,
    useUserMetricDefinition
} from 'components/metrics/hooks/useUserMetricDefinition';
import { MainLayout } from 'layouts/MainLayout';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getAllAudiences } from 'store/audiences/audiencesActions';
import { getAllLocations } from 'store/locations/locationsActions';
import { getAllPerks } from 'store/perks/perksActions';
import { EditPremiumLoyaltyModal } from 'components/metrics/modals/EditPremiumLoyaltyModal';
import { BirthdayLoyaltyOverview } from 'components/metrics/birthday/BirthdayLoyaltyOverview';
import { useBirthdayLoyaltyEditModal } from 'components/metrics/birthday/hooks/useBirthdayLoyaltyEditModal';
import { BirthdayAudiences } from 'components/metrics/birthday/BirthdayAudiences';
import { ConfirmDialog } from 'ui/dialogs/ConfirmDialog';
import { EPremiumLoyaltyState, UserMetricDefinition } from 'components/metrics/model';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_BIRTHDAY_LOYALTY_ACTIVATE_ERROR,
    MESSAGE_BIRTHDAY_LOYALTY_ACTIVATE_SUCCESS,
    MESSAGE_BIRTHDAY_LOYALTY_COMPUTE_ERROR,
    MESSAGE_BIRTHDAY_LOYALTY_DISABLE_ERROR,
    MESSAGE_BIRTHDAY_LOYALTY_DISABLE_SUCCESS
} from 'config/messages';
import { metricsApi } from 'components/metrics/metricsApi';
import logger from 'lib/logger';

export const BirthdayLoyalty = () => {
    const dispatch = useDispatch();
    const {
        metricDefinition,
        isSetup,
        state,
        isValidMetricDefinition,
        setMetricDefinition,
        finishSetup,
        loading: metricDefinitionLoading
    } = useUserMetricDefinition(EPremiumLoyaltyScheme.Birthday);
    const handleStateUpdateSuccess = React.useCallback(
        async (metric: UserMetricDefinition) => {
            setMetricDefinition(metric);
            if (state === EPremiumLoyaltyState.INACTIVE) {
                const computeResult = await metricsApi.compute(metric._id);
                if (!computeResult.ok) {
                    dispatch(enqueueSnackbar(MESSAGE_BIRTHDAY_LOYALTY_COMPUTE_ERROR, { variant: 'warning' }));
                    logger.error(computeResult.body.message, computeResult.body);
                }
            }
            dispatch(
                enqueueSnackbar(
                    state === EPremiumLoyaltyState.INACTIVE
                        ? MESSAGE_BIRTHDAY_LOYALTY_ACTIVATE_SUCCESS
                        : MESSAGE_BIRTHDAY_LOYALTY_DISABLE_SUCCESS,
                    {
                        variant: 'success'
                    }
                )
            );
        },
        [dispatch, setMetricDefinition, state]
    );
    const handleStateUpdateError = React.useCallback(() => {
        dispatch(
            enqueueSnackbar(
                state === EPremiumLoyaltyState.INACTIVE
                    ? MESSAGE_BIRTHDAY_LOYALTY_ACTIVATE_ERROR
                    : MESSAGE_BIRTHDAY_LOYALTY_DISABLE_ERROR,
                {
                    variant: 'error'
                }
            )
        );
    }, [dispatch, state]);
    const {
        stateModalOpen,
        handleStateChangeOpen,
        handleStateChangeClose,
        handleStateChangeSubmit,
        loading: stateChangeLoading
    } = usePremiumLoyaltyStateModal(metricDefinition, handleStateUpdateError, handleStateUpdateSuccess);
    const handleStateChangeConfirm = React.useCallback(() => {
        handleStateChangeSubmit(
            state === EPremiumLoyaltyState.INACTIVE
                ? EPremiumLoyaltyState.ACTIVE
                : EPremiumLoyaltyState.INACTIVE
        );
    }, [handleStateChangeSubmit, state]);
    const changeStateTitle = React.useMemo(() => {
        if (state === EPremiumLoyaltyState.INACTIVE) {
            return 'Activate a birthday scheme?';
        }
        return 'Disable a birthday scheme?';
    }, [state]);
    const changeStateContent = React.useMemo(() => {
        if (state === EPremiumLoyaltyState.INACTIVE) {
            return `Are you sure you want to activate ${metricDefinition?.name}?`;
        }
        return `Are you sure you want to disable ${metricDefinition?.name}?`;
    }, [metricDefinition?.name, state]);
    const {
        editModalOpen,
        handleDefinitionEditOpen,
        handleDefinitionEditClose,
        handleDefinitionEditSubmit,
        loading: editLoading
    } = useBirthdayLoyaltyEditModal(metricDefinition, setMetricDefinition, finishSetup);
    React.useEffect(() => {
        getAllAudiences()(dispatch);
        getAllPerks()(dispatch);
        getAllLocations()(dispatch);
    }, [dispatch]);
    return (
        <MainLayout
            title="Birthday Schemes"
            pageName="Birthday Schemes"
            pageDescription="Audience based birthday loyalty"
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <BirthdayLoyaltyOverview
                        metricDefinition={metricDefinition}
                        loading={metricDefinitionLoading}
                        onEdit={handleDefinitionEditOpen}
                        onStateChange={handleStateChangeOpen}
                        readyToActivate={isValidMetricDefinition}
                        state={state}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BirthdayAudiences
                        metricDefinition={metricDefinition}
                        loading={metricDefinitionLoading}
                        updateMetricDefinition={setMetricDefinition}
                    />
                </Grid>
            </Grid>
            <EditPremiumLoyaltyModal
                open={editModalOpen || isSetup}
                onClose={handleDefinitionEditClose}
                onSubmit={handleDefinitionEditSubmit}
                loading={editLoading}
                metricDefinition={metricDefinition}
                scheme={EPremiumLoyaltyScheme.Birthday}
            />
            <ConfirmDialog
                open={stateModalOpen}
                onCancel={handleStateChangeClose}
                onConfirm={handleStateChangeConfirm}
                loading={stateChangeLoading}
                title={changeStateTitle}
                content={changeStateContent}
            />
        </MainLayout>
    );
};
