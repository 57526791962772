import { SystemRole } from '@pepperhq/auth-client';
import { Form } from 'formik';
import { MuiForm } from 'lib/Form';
import React from 'react';
import { MuiModal } from 'ui/MuiModal';
import { IClerk, IClerkResource } from './Clerk';
import * as Yup from 'yup';
import { Button, Grid, Typography } from '@mui/material';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { Row } from 'ui/Flex';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { Option } from 'lib/types';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { ClerksFormError } from './ClerksFormError';

interface ClerksModalForm {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: IClerkResource) => void;
    loading?: boolean;
    clerk?: IClerk;
    error?: string;
    onErrorChange: (message?: string) => void;
    onUpdate: (data: IClerkResource) => void;
}

const initialData: IClerkResource = {
    roleId: SystemRole.TerminalClerk,
    pin: '',
    locationId: '',
    name: '',
    sessionMs: 60
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    pin: Yup.number()
        .typeError('Must be a number')
        .min(0, 'Must be a positive number')
        .required('This field is required'),
    roleId: Yup.string().required('This field is required'),
    locationId: Yup.string().required('This field is required'),
    sessionMs: Yup.number()
        .typeError('Must be a positive number')
        .min(0, 'Must be a positive number')
        .required('This field is required'),
    tipGroupId: Yup.string()
});

const roles = [
    { value: SystemRole.TerminalManager, label: 'Manager' },
    { value: SystemRole.TerminalClerk, label: 'Clerk' }
];

export const ClerksModalForm: React.FC<ClerksModalForm> = ({
    open,
    onClose,
    onSubmit,
    clerk,
    error,
    onErrorChange,
    onUpdate
}) => {
    const title = React.useMemo(() => (clerk ? 'Update a clerk' : 'Create a clerk'), [clerk]);
    const { locationIdToLocationTitle } = useSelector((state: ApplicationState) => state.locations);

    const locations = React.useMemo(() => {
        const options: Option[] = [];
        if (!locationIdToLocationTitle) {
            return [];
        }
        locationIdToLocationTitle.forEach((label, value) => {
            options.push({ label, value });
        });
        return options;
    }, [locationIdToLocationTitle]);
    const initialValues = React.useMemo(() => clerk ?? initialData, [clerk]);
    const handleSubmit = React.useCallback(
        (data: IClerkResource) => {
            if (!!clerk) {
                onUpdate(data);
            } else {
                onSubmit(data);
            }
        },
        [clerk, onSubmit, onUpdate]
    );

    return (
        <MuiModal open={open} onClose={onClose}>
            <MuiForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({ submitForm, isSubmitting, isValid }) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography color="primary" variant="h6">
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextFormField name="name" label="Staff Name" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextFormField name="pin" label="PIN" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectFormField
                                    name="locationId"
                                    label="Location"
                                    options={locations}
                                    disabled={!!clerk}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextFormField name="tipGroupId" label="Tip Group (optional)" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectFormField name="roleId" label="Role" options={roles} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextFormField name="sessionMs" label="Session Duration (seconds)" />
                            </Grid>
                            <Grid item xs={6}>
                                <ClerksFormError error={error} onErrorChange={onErrorChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <Row gutter align="flex-end" flex={1}>
                                    <Button variant="outlined" onClick={onClose} disabled={isSubmitting}>
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        disabled={!isValid || isSubmitting || !!error}
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                        loading={isSubmitting}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Row>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </MuiForm>
        </MuiModal>
    );
};
