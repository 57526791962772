import React from 'react';
import { Box, Button, styled } from '@mui/material';
import { Location } from 'components/location/models/LocationModel';
import { MuiModal } from 'ui/MuiModal';
import { CheckboxPlainPicker } from 'lib/checkbox-picker/CheckboxPlainPicker';

const ModalContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: theme.spacing(430 / 8),
    minHeight: theme.spacing(410 / 8)
}));

interface LocationsPickerModalProps {
    open: boolean;
    onClose: () => void;
    locations: Location[];
    defaultLocationIds?: string[];
    selectedLocationIds?: string[];
    onSubmit: (locationIds: string[]) => void;
    loading?: boolean;
    allowEmpty?: boolean;
}

const LocationsPickerModalPropsComponent: React.FC<LocationsPickerModalProps> = props => {
    const {
        onClose,
        onSubmit,
        selectedLocationIds = [],
        defaultLocationIds = [],
        locations,
        loading,
        allowEmpty
    } = props;
    const options = React.useMemo(() => {
        if (!Array.isArray(locations)) {
            return [];
        }
        return locations.map(item => ({ value: item._id, label: item.title }));
    }, [locations]);
    const defaultSelectedLocations = React.useMemo(
        () => new Set([...defaultLocationIds, ...selectedLocationIds]),
        [defaultLocationIds, selectedLocationIds]
    );

    const [selectedLocations, setSelectedLocations] = React.useState<Set<string>>(
        new Set(defaultSelectedLocations)
    );
    const value = React.useMemo(() => [...selectedLocations], [selectedLocations]);
    const handleChange = React.useCallback((value: string[]) => {
        setSelectedLocations(new Set(value));
    }, []);

    const handleSubmit = React.useCallback(() => {
        onSubmit([...selectedLocations]);
        onClose();
    }, [onClose, onSubmit, selectedLocations]);

    return (
        <ModalContainer>
            <CheckboxPlainPicker
                options={options}
                defaultItems={defaultLocationIds}
                value={value}
                loading={loading}
                searchPlaceholder="Search Location"
                onChange={handleChange}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    color="primary"
                    disabled={allowEmpty ? false : !selectedLocations.size}
                    onClick={handleSubmit}
                >
                    Confirm
                </Button>
            </Box>
        </ModalContainer>
    );
};

export const LocationsPickerModal: React.FC<LocationsPickerModalProps> = props => {
    const { open, onClose } = props;
    return (
        <MuiModal open={open} onClose={onClose}>
            <LocationsPickerModalPropsComponent {...props} />
        </MuiModal>
    );
};
