/* eslint-disable prefer-spread */
import React, { CSSProperties } from 'react';
import { Box, InputBase, Typography } from '@mui/material';
import { AwardTemplate } from 'components/customers/models/Award';
import { RGBAValue } from 'lib/form/fields/ColorFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { rgbaToHex } from 'lib/helpers';
import { Row } from 'ui/Flex';
import { perkPreviewFontSizesByType } from './PerkPreviewEditable';
import { StampCircle } from './StampCircle';

interface StampCardPerkPreviewEditable {
    cardAvailable?: boolean;
    stampImageUrl?: string;
    randomizeStampCard?: boolean;
    availableCardCopy?: string;
    textColor: RGBAValue;
    nextCardProgressCopy?: string;
    perkType: AwardTemplate;
    stampColor: RGBAValue;
    subtitle?: string;
    titleText?: string;
    stampsPerCard: number;
    editable?: boolean;
    textAlign: CSSProperties['textAlign'];
    onTitleClick: () => void;
}

export const StampCardPerkPreviewEditable: React.FC<StampCardPerkPreviewEditable> = ({
    textColor,
    perkType,
    cardAvailable,
    stampImageUrl,
    nextCardProgressCopy,
    randomizeStampCard,
    stampColor,
    subtitle,
    titleText,
    availableCardCopy,
    stampsPerCard,
    editable,
    textAlign,
    onTitleClick
}) => {
    const count = stampsPerCard > 12 ? 12 : stampsPerCard;
    const firstCount = Math.ceil(count / 2);
    const filled = Math.ceil(count / 1.5);
    const firstFilled = Math.ceil(firstCount / 1.5);
    const oneLine = Array.apply(null, { length: count });
    const firstLine = Array.apply(null, { length: firstCount });
    const secondLine = Array.apply(null, { length: count - firstCount });
    const fontSizes = perkPreviewFontSizesByType[perkType];
    const reduced = stampsPerCard > 10;
    if (cardAvailable) {
        return (
            <React.Fragment>
                <Box marginBottom="auto" color={rgbaToHex(textColor)}>
                    <Row gutter>
                        <Typography variant="h1" color="inherit">
                            1
                        </Typography>
                        {editable ? (
                            <TextFormField
                                name="availableCardCopy"
                                naked
                                fontSize={`${fontSizes.subtitle}px`}
                                textColor={rgbaToHex(textColor)}
                                multiline
                                maxRows={2}
                                placeholder="Click to add text"
                            />
                        ) : (
                            <InputBase
                                fullWidth
                                disabled
                                multiline
                                maxRows={2}
                                style={{ fontSize: fontSizes.subtitle }}
                                inputProps={{
                                    sx: { color: rgbaToHex(textColor) },
                                    style: { WebkitTextFillColor: rgbaToHex(textColor) }
                                }}
                                value={availableCardCopy}
                            />
                        )}
                    </Row>
                </Box>
                {editable ? (
                    <TextFormField
                        name="nextCardProgressCopy"
                        naked
                        fontSize={`${fontSizes.subtitleTwo}px`}
                        textColor={rgbaToHex(textColor)}
                        multiline
                        maxRows={2}
                        placeholder="Click to add text or leave blank"
                    />
                ) : (
                    <InputBase
                        fullWidth
                        disabled
                        multiline
                        maxRows={2}
                        style={{ fontSize: fontSizes.subtitleTwo }}
                        inputProps={{
                            sx: { color: rgbaToHex(textColor) },
                            style: { WebkitTextFillColor: rgbaToHex(textColor) }
                        }}
                        value={nextCardProgressCopy}
                    />
                )}
                <Row>
                    {oneLine.map((_: never, index: number) => (
                        <StampCircle
                            key={`available-stamp-circle-${index}`}
                            filled={index < count / 1.5}
                            randomizeStampCard={randomizeStampCard}
                            stampImage={stampImageUrl}
                            stampColor={stampColor}
                            reduced={reduced}
                            small
                        />
                    ))}
                </Row>
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            {editable ? (
                <TextFormField
                    naked
                    maxRows={1}
                    name="titleText"
                    fontSize={`${fontSizes.title}px`}
                    textAlign={textAlign}
                    textColor={rgbaToHex(textColor)}
                    placeholder="Click to add text"
                    onClick={onTitleClick}
                />
            ) : (
                <InputBase
                    fullWidth
                    disabled
                    maxRows={1}
                    style={{ fontSize: fontSizes.title }}
                    inputProps={{
                        sx: { color: rgbaToHex(textColor) },
                        style: { WebkitTextFillColor: rgbaToHex(textColor) }
                    }}
                    value={titleText}
                />
            )}
            <Box marginBottom="auto">
                {editable ? (
                    <TextFormField
                        name="subtitle"
                        naked
                        fontSize={`${fontSizes.subtitle}px`}
                        textColor={rgbaToHex(textColor)}
                        placeholder="Click to add text or leave blank"
                    />
                ) : (
                    <InputBase
                        fullWidth
                        disabled
                        multiline
                        maxRows={2}
                        style={{ fontSize: fontSizes.subtitle }}
                        inputProps={{
                            sx: { color: rgbaToHex(textColor) },
                            style: { WebkitTextFillColor: rgbaToHex(textColor) }
                        }}
                        value={subtitle}
                    />
                )}
            </Box>
            <Row>
                {firstLine.map((_: never, index: number) => (
                    <StampCircle
                        key={`stamp-circle-1-${index}`}
                        filled={index < firstFilled}
                        randomizeStampCard={randomizeStampCard}
                        stampImage={stampImageUrl}
                        reduced={reduced}
                        stampColor={stampColor}
                    />
                ))}
            </Row>
            <Box marginLeft={2.75} marginTop={-0.5}>
                <Row>
                    {secondLine.map((_: never, index: number) => (
                        <StampCircle
                            key={`stamp-circle-2-${index}`}
                            filled={index < filled - firstFilled}
                            randomizeStampCard={randomizeStampCard}
                            stampImage={stampImageUrl}
                            reduced={reduced}
                            stampColor={stampColor}
                        />
                    ))}
                </Row>
            </Box>
        </React.Fragment>
    );
};
