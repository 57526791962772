import { GridColTypeDef } from '@mui/x-data-grid-pro';
import {
    isNutritionInfo,
    isTagValue,
    NutrientValue,
    TagValue,
    isSuggestion,
    NutritionInfo,
    TagGroup,
    Suggestion
} from '@pepperhq/menu-sdk';
import { isArrayOf, isString } from 'lib/typeguards';
import { TagsEditCell } from './tags/TagsEditCell';
import { TagsViewCell } from './tags/TagsViewCell';
import { NutritionEditCell } from './nutrition/NutritionEditCell';
import { NutritionViewCell } from './nutrition/NutritionViewCell';
import { SuggestionsViewCell } from './suggestions/SuggestionsViewCell';
import { SuggestionsEditCell } from './suggestions/SuggestionsEditCell';
import { Option } from 'lib/types';
import { NutritionCaloriesEditCell } from './nutrition/NutritionCaloriesEditCell';

export function NutritionGridColumn(caloriesOnly?: boolean): GridColTypeDef {
    const getValue = (value: NutritionInfo[]) => {
        const getValuesString = (values: NutrientValue[]) =>
            values.map(item => `${item.nutrient} - ${item.value}`).join(', ');
        if (isArrayOf(isNutritionInfo, value)) {
            return value.map(item => `${item.title}: ${getValuesString(item.values)}`).join('; ');
        }
        return '';
    };
    const sort = (v1: NutritionInfo[], v2: NutritionInfo[]) => getValue(v1).localeCompare(getValue(v2));
    return {
        sortComparator: sort,
        renderCell: NutritionViewCell,
        renderEditCell: caloriesOnly ? NutritionCaloriesEditCell : NutritionEditCell
    };
}
export function TagsGridColumn(): GridColTypeDef {
    const getValue = (value: TagGroup) => {
        const getValuesString = (values: TagValue[]) =>
            values.map(item => `${item.id} - ${item.title}`).join(', ');
        if (isArrayOf(isTagValue, value)) {
            return getValuesString(value);
        }
        return '';
    };
    const sort = (v1: TagGroup, v2: TagGroup) => getValue(v1).localeCompare(getValue(v2));
    return {
        sortComparator: sort,
        renderCell: TagsViewCell,
        renderEditCell: TagsEditCell
    };
}

export function SuggestionsGridColumn(products: Option[]): GridColTypeDef {
    const getValue = (value: Suggestion) => {
        if (isArrayOf(isSuggestion, value) && value.length) {
            if (value[0].type === 'add') {
                const found = products.find(item => item.value === value[0].productId);
                if (found && isString(found?.label)) {
                    return found.label;
                }
                return '';
            }
            if (value[0].type === 'bogof') {
                return 'OFFER';
            }
            return '';
        }
        return '';
    };
    const sort = (v1: Suggestion, v2: Suggestion) => getValue(v1).localeCompare(getValue(v2));
    return {
        sortComparator: sort,
        renderCell: props => SuggestionsViewCell(props, products),
        renderEditCell: props => SuggestionsEditCell(props, products)
    };
}
