import { getMinutesFromDays } from 'lib/timeHelpers';
import { EMetricType, PremiumLoyaltyAudience, UserMetricResource } from '.';

export interface LoyaltyTier extends PremiumLoyaltyAudience {
    metric: string;
    metricAddConditions: UserMetricListenerMetricCondition[];
    metricRemoveConditions: UserMetricListenerMetricCondition[];
}

export interface EditTieredLoyaltyForm {
    name: string;
}

export interface MetricTierLoyaltyForm {
    name: string;
    type: EMetricType | string;
    action?: string;
    perkId?: string;
    includeTips?: boolean;
    timeWindowStart?: number;
    timeWindowEnd?: number;
    gt?: Date;
    lt?: Date;
}

export function generateUserMetricData(values: MetricTierLoyaltyForm): UserMetricResource {
    const baseFields: UserMetricResource = {
        name: values.name,
        displayName: values.name,
        type: values.type,
        timeRange: {
            gt: values.gt ?? undefined,
            lt: values.lt ?? undefined
        }
    };
    if (values.timeWindowStart) {
        baseFields.timeRange.windowStartOffsetMins = getMinutesFromDays(values.timeWindowStart);
        baseFields.timeRange.windowEndOffsetMins = getMinutesFromDays(values.timeWindowEnd);
    }
    switch (values.type) {
        case EMetricType.Spend: {
            return {
                ...baseFields,
                includeTips: values.includeTips
            };
        }
        case EMetricType.PointsBurned:
        case EMetricType.PointsEarned:
            return {
                ...baseFields,
                perkId: values.perkId
            };
        case EMetricType.ActionType:
            return {
                ...baseFields,
                type: values.action
            };
        case EMetricType.TipsGiven:
        default:
            return {
                ...baseFields
            };
    }
}

export function getMetricConditionString(
    operator:
        | 'EQUAL'
        | 'NOT_EQUAL'
        | 'GREATER_THAN'
        | 'LESS_THAN'
        | 'IS_WITHIN_TOP_PERCENTAGE'
        | 'IS_NOT_WITHIN_TOP_PERCENTAGE',
    value: number
) {
    switch (operator) {
        case 'EQUAL':
            return `Equal to ${value}`;
        case 'NOT_EQUAL':
            return `Not equal to ${value}`;
        case 'LESS_THAN':
            return `Less than ${value}`;
        case 'GREATER_THAN':
            return `Greater than ${value}`;
        default:
            return `${operator} + ${value}`;
    }
}

export const METRICS_HEIGHT = '240px';

export type UserMetricListenerMetricCondition = {
    _id: string;
    metricId: string;
    operator:
        | 'EQUAL'
        | 'NOT_EQUAL'
        | 'GREATER_THAN'
        | 'LESS_THAN'
        | 'IS_WITHIN_TOP_PERCENTAGE'
        | 'IS_NOT_WITHIN_TOP_PERCENTAGE';
    value: number;
};
