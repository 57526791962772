import { Box, Button, Paper, Tooltip, Typography } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { MARKETING_AUDIENCES_VIEW } from 'config/routes';
import { LinkIdCell, LinkIdCellRowModel } from 'lib/MuiGrid/LinkIdCell';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React from 'react';
import { LoyaltyTier } from '../model/loyalty-tier';
import { Row } from 'ui/Flex';
import { UserMetricDefinition } from '../model';
import { isDefined } from 'lib/typeguards';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { TieredLoyaltyTierModal } from '../modals/TieredLoyaltyTierModal';
import { MuiModal } from 'ui/MuiModal';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useSimpleModalState } from 'lib/hooks/useSimpleModalState';
import { usePremiumLoyaltyAudienceDeleteModal } from '../hooks/usePremiumLoyaltyAudienceDeleteModal';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { useGridSingleSelection } from 'lib/MuiGrid/useGridSingleSelection';

interface TieredLoyaltyAudiencesProps {
    metricDefinition?: UserMetricDefinition;
    tiers: LoyaltyTier[];
    loading?: boolean;
    loadPremiumLoyalty: () => void;
    onDefinitionUpdate: (value: UserMetricDefinition) => void;
}

export const TieredLoyaltyTiers: React.FC<TieredLoyaltyAudiencesProps> = ({
    tiers,
    loading,
    loadPremiumLoyalty,
    metricDefinition,
    onDefinitionUpdate
}) => {
    const [selectedTier, tierSelectionModel, handleSelectRow] = useGridSingleSelection(tiers);
    const handleTierConditionAdd = React.useCallback(
        (definitionUpdate: UserMetricDefinition, newAudienceId?: string) => {
            onDefinitionUpdate(definitionUpdate);
            if (isDefined(newAudienceId)) {
                handleSelectRow([newAudienceId]);
            }
        },
        [handleSelectRow, onDefinitionUpdate]
    );
    const renderViewCell = React.useCallback(
        (params: GridCellParams<LinkIdCellRowModel, string>) => (
            <LinkIdCell id={params.value} param="audienceId" path={MARKETING_AUDIENCES_VIEW} />
        ),
        []
    );
    const renderMissingConditionsCell = React.useCallback((params: GridCellParams) => {
        if (params.value) {
            return (
                <Tooltip
                    title="Conditions to add or remove a customer are missing. This tier might not work as expected."
                    arrow
                >
                    <ErrorOutlineIcon color="warning" sx={{ fontSize: '22px' }} />
                </Tooltip>
            );
        }
        return '';
    }, []);
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'Tier',
                field: 'name',
                width: 200
            },
            {
                headerName: 'Audience ID',
                field: 'id',
                width: 300,
                renderCell: renderViewCell
            },
            {
                headerName: 'Metric',
                field: 'metric',
                width: 300
            },
            {
                headerName: 'Add',
                field: 'addConditionsString',
                width: 200
            },
            {
                headerName: 'Remove',
                field: 'removeConditionsString',
                width: 200
            },
            {
                headerName: '',
                resizable: false,
                field: 'missingConditions',
                width: 60,
                disableColumnMenu: true,
                disableExport: true,
                disableReorder: true,
                sortable: false,
                align: 'center',
                justify: 'center',
                display: 'flex',
                renderCell: renderMissingConditionsCell
            }
        ],
        [renderMissingConditionsCell, renderViewCell]
    );
    const [tiersModalOpen, handleTierEditOpen, handleTierEditClose] = useSimpleModalState();
    const {
        handleTierDeleteClose,
        handleTierDeleteOpen,
        handleTierDeleteSubmit,
        tiersDeleteModalOpen,
        title,
        content,
        loading: tierDeleteLoading
    } = usePremiumLoyaltyAudienceDeleteModal(
        metricDefinition,
        selectedTier,
        loadPremiumLoyalty,
        'loyalty tier'
    );
    return (
        <Paper sx={{ p: 1, pb: 2 }}>
            <Row align="space-between" flex={1} valign="center">
                <Typography variant="h6" sx={{ ml: 1 }}>
                    Tiers
                </Typography>
                <Row gutter>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!!selectedTier}
                        size="small"
                        onClick={handleTierEditOpen}
                    >
                        Add
                    </Button>
                    <Button
                        variant="contained"
                        disabled={!selectedTier}
                        size="small"
                        onClick={handleTierEditOpen}
                    >
                        View
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        disabled={!selectedTier}
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={handleTierDeleteOpen}
                    >
                        Delete
                    </Button>
                </Row>
            </Row>
            <Box height="300px" mt={1}>
                <MuiGrid
                    loading={loading}
                    columns={columns}
                    rows={tiers}
                    disableMultipleRowSelection
                    disableRowSelectionOnClick={false}
                    onRowSelectionModelChange={handleSelectRow}
                    rowSelectionModel={tierSelectionModel}
                    storageName={GridStorageName.Tiers}
                />
            </Box>
            <DeleteDialog
                open={tiersDeleteModalOpen}
                onClose={handleTierDeleteClose}
                title={title}
                content={content}
                loading={tierDeleteLoading}
                onDelete={handleTierDeleteSubmit}
            />
            <MuiModal open={tiersModalOpen} onClose={handleTierEditClose} noMaxWidth>
                <TieredLoyaltyTierModal
                    open={tiersModalOpen}
                    onClose={handleTierEditClose}
                    tier={selectedTier}
                    tiers={tiers}
                    metricDefinition={metricDefinition}
                    onConditionAdd={handleTierConditionAdd}
                />
            </MuiModal>
        </Paper>
    );
};
