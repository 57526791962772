import { Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { parseIntOrUndefined } from 'lib/helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { MuiModal } from 'ui/MuiModal';
import { PremiumLoyaltyAudienceSelect } from '../PremiumLoyaltyAudienceSelect';
import { Row } from 'ui/Flex';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { BirthdayAudience } from '../model';

const selectOptions = [
    { value: 'before', label: 'before birthday' },
    { value: 'after', label: 'after birthday' }
];
const dayOptions = Array.from({ length: 31 }, (_: never, i: number) => ({
    value: i,
    label: `${i} day${i !== 1 ? 's' : ''}`
}));

const DEFAULT_FROM_VALUE = 7;
const DEFAULT_TO_VALUE = 1;
const DEFAULT_FROM_OPERATOR = 'before';
const DEFAULT_TO_OPERATOR = 'after';

const getOperatorFromValue = (value: number): 'before' | 'after' => (value < 0 ? 'before' : 'after');

const AUDIENCE_SELECT_MESSAGE = `Choose an audience to proceed with creating the birthday scheme. Alternatively,
                            you can create a new audience by entering the desired title and clicking '+ Create
                            new'`;

interface BirthdayAudienceModalProps {
    open: boolean;
    onClose: () => void;
    loading?: boolean;
    birthdayAudience?: BirthdayAudience;
    onSubmit: (data: { audienceId: string; fromDays: number; toDays: number }) => void;
}

export const BirthdayAudienceModal: React.FC<BirthdayAudienceModalProps> = ({
    open,
    onClose,
    loading,
    birthdayAudience,
    onSubmit
}) => {
    const { audiences } = useSelector((state: ApplicationState) => state.audiences);
    const isCreate = React.useMemo(() => !!birthdayAudience, [birthdayAudience]);
    // ==========Audience input==============
    const [audienceId, setAudienceId] = React.useState<string>(birthdayAudience?.id);
    const audienceOptions = React.useMemo(
        () => audiences?.map(item => ({ label: `${item.title} [${item._id}]`, value: item._id })) ?? [],
        [audiences]
    );
    const handleAudienceChange = React.useCallback((value: string) => {
        setAudienceId(value);
    }, []);

    // =========== Inputs ===========
    const [from, setFrom] = React.useState(
        birthdayAudience ? Math.abs(birthdayAudience.fromDays) : DEFAULT_FROM_VALUE
    );
    const [fromOperator, setFromOperator] = React.useState<'before' | 'after'>(
        birthdayAudience ? getOperatorFromValue(birthdayAudience.fromDays) : DEFAULT_FROM_OPERATOR
    );

    const [to, setTo] = React.useState(
        birthdayAudience ? Math.abs(birthdayAudience.toDays) : DEFAULT_TO_VALUE
    );
    const [toOperator, setToOperator] = React.useState<'before' | 'after'>(
        birthdayAudience ? getOperatorFromValue(birthdayAudience.toDays) : DEFAULT_TO_OPERATOR
    );

    const makeFormChange = React.useCallback(
        (_from: number, _to: number, _fromOperator: 'before' | 'after', _toOperator: 'before' | 'after') => {
            let fromValue = _from;
            if (fromValue !== 0) {
                fromValue = fromValue * (_fromOperator === 'before' ? -1 : 1);
            }
            let toValue = _to;
            if (toValue !== 0) {
                toValue = toValue * (_toOperator === 'before' ? -1 : 1);
            }
            if (fromValue > toValue) {
                setFrom(Math.abs(toValue));
                setTo(Math.abs(fromValue));
                setToOperator(_fromOperator);
                setFromOperator(_toOperator);
            } else {
                setFrom(Math.abs(fromValue));
                setTo(Math.abs(toValue));
                setFromOperator(_fromOperator);
                setToOperator(_toOperator);
            }
        },
        []
    );

    const handleChangeFrom = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            makeFormChange(parseIntOrUndefined(e.target.value), to, fromOperator, toOperator);
        },
        [fromOperator, makeFormChange, to, toOperator]
    );

    const handleChangeTo = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            makeFormChange(from, parseIntOrUndefined(e.target.value), fromOperator, toOperator);
        },
        [from, fromOperator, makeFormChange, toOperator]
    );

    const handleChangeFromOperator = React.useCallback(
        (e: any) => {
            makeFormChange(from, to, e.target.value, toOperator);
        },
        [from, makeFormChange, to, toOperator]
    );

    const handleChangeToOperator = React.useCallback(
        (e: any) => {
            makeFormChange(from, to, fromOperator, e.target.value);
        },
        [from, fromOperator, makeFormChange, to]
    );

    const mapSelectOptions = React.useCallback(
        (option: { label: string; value: string | number }) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ),
        []
    );
    const handleSubmit = React.useCallback(() => {
        onSubmit({
            audienceId,
            fromDays: from * (fromOperator === 'before' ? -1 : 1),
            toDays: to * (toOperator === 'before' ? -1 : 1)
        });
    }, [audienceId, from, fromOperator, onSubmit, to, toOperator]);
    return (
        <MuiModal open={open} onClose={onClose} noMaxWidth>
            <Box sx={{ width: 800 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                            {isCreate ? 'New audience conditions' : 'Edit audience conditions'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ position: 'relative' }}>
                        <PremiumLoyaltyAudienceSelect
                            value={audienceId}
                            onChange={handleAudienceChange}
                            loading={loading}
                            options={audienceOptions}
                        />
                    </Grid>
                    {!audienceId && (
                        <Grid item xs={12}>
                            <Typography>{AUDIENCE_SELECT_MESSAGE}</Typography>
                        </Grid>
                    )}
                    {!!audienceId && (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Typography sx={{ mb: 1 }}>Time Window</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    name="from"
                                    label="Added to the audience"
                                    value={from}
                                    onChange={handleChangeFrom}
                                    fullWidth
                                    select
                                >
                                    {dayOptions.map(mapSelectOptions)}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    name="fromOperator"
                                    value={fromOperator}
                                    onChange={handleChangeFromOperator}
                                    label=" "
                                    select
                                    fullWidth
                                >
                                    {selectOptions.map(mapSelectOptions)}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    name="to"
                                    label="Removed from the audience"
                                    value={to}
                                    onChange={handleChangeTo}
                                    fullWidth
                                    select
                                >
                                    {dayOptions.map(mapSelectOptions)}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    name="toOperator"
                                    value={toOperator}
                                    onChange={handleChangeToOperator}
                                    label=" "
                                    select
                                    fullWidth
                                >
                                    {selectOptions.map(mapSelectOptions)}
                                </TextField>
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid item xs={12}>
                        <Row gutter align="flex-end">
                            <Button variant="outlined" onClick={onClose}>
                                Cancel
                            </Button>
                            <LoadingButton
                                variant="contained"
                                loading={loading}
                                disabled={loading || !audienceId}
                                onClick={handleSubmit}
                            >
                                Confirm
                            </LoadingButton>
                        </Row>
                    </Grid>
                </Grid>
            </Box>
        </MuiModal>
    );
};
