import { AdjustmentScheme } from '../adjustmentSchemeModel';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

enum AdjustmentSchemesActionType {
    MODAL_ACTION_CREATE = 'ADJUSTMENT_SCHEMES_MODAL_ACTION_CREATE',
    TOGGLE_CLOSE_CREATE_MODAL = 'TOGGLE_CLOSE_CREATE_MODAL',
    CREATE_SUCCESS = 'CREATE_ADJUSTMENT_SCHEME_SUCCESS',
    TOGGLE_CLOSE_UPDATE_MODAL = 'TOGGLE_CLOSE_UPDATE_MODAL',
    MODAL_ACTION_UPDATE = 'ADJUSTMENT_SCHEMES_MODAL_ACTION_UPDATE',
    UPDATE_SUCCESS = 'UPDATE_ADJUSTMENT_SCHEME_SUCCESS',
    CLOSE_ALL_MODALS = 'CLOSE_ALL_ADJUSTMENT_SCHEMES_MODALS',
    MODAL_ACTION_DELETE = 'ADJUSTMENT_SCHEMES_MODAL_ACTION_DELETE',
    DELETE_SUCCESS = 'DELETE_ADJUSTMENT_SCHEME_SUCCESS',
    SET_SELECTED_ROW = 'SET_SELECTED_ROW',
    UNKNOWN = 'UNKNOWN'
}

interface IBaseReducerAction {
    type: AdjustmentSchemesActionType;
}

interface IUnknownAction extends IBaseReducerAction {
    type: AdjustmentSchemesActionType.UNKNOWN;
}

interface IModalAction extends IBaseReducerAction {
    type:
        | AdjustmentSchemesActionType.MODAL_ACTION_CREATE
        | AdjustmentSchemesActionType.TOGGLE_CLOSE_CREATE_MODAL
        | AdjustmentSchemesActionType.MODAL_ACTION_UPDATE
        | AdjustmentSchemesActionType.TOGGLE_CLOSE_UPDATE_MODAL
        | AdjustmentSchemesActionType.MODAL_ACTION_DELETE
        | AdjustmentSchemesActionType.CLOSE_ALL_MODALS;
    selectedScheme?: AdjustmentScheme;
}

interface IModalSuccessAction extends IBaseReducerAction {
    type:
        | AdjustmentSchemesActionType.CREATE_SUCCESS
        | AdjustmentSchemesActionType.UPDATE_SUCCESS
        | AdjustmentSchemesActionType.DELETE_SUCCESS;
}

interface ISetRowAction extends IBaseReducerAction {
    type: AdjustmentSchemesActionType.SET_SELECTED_ROW;
    selectedRow: GridRowSelectionModel;
}

export type IAdjustmentSchemeAction = IModalAction | IModalSuccessAction | ISetRowAction | IUnknownAction;

interface AdjustmentSchemesState {
    loading: boolean;
    createModalOpen: boolean;
    closeCreateModalOpen: boolean;
    updateModalOpen: boolean;
    closeUpdateModalOpen: boolean;
    deleteModalOpen: boolean;
    selectedRow: GridRowSelectionModel;
}

const initialState: AdjustmentSchemesState = {
    loading: false,
    createModalOpen: false,
    closeCreateModalOpen: false,
    updateModalOpen: false,
    closeUpdateModalOpen: false,
    deleteModalOpen: false,
    selectedRow: []
};

const adjustmentSchemesReducer = (
    state: AdjustmentSchemesState = initialState,
    action: IAdjustmentSchemeAction
): AdjustmentSchemesState => {
    switch (action.type) {
        case AdjustmentSchemesActionType.MODAL_ACTION_CREATE:
            return {
                ...state,
                createModalOpen: true
            };
        case AdjustmentSchemesActionType.CREATE_SUCCESS:
            return {
                ...state,
                createModalOpen: false
            };
        case AdjustmentSchemesActionType.TOGGLE_CLOSE_CREATE_MODAL:
            return {
                ...state,
                closeCreateModalOpen: !state.closeCreateModalOpen
            };
        case AdjustmentSchemesActionType.MODAL_ACTION_UPDATE:
            return {
                ...state,
                updateModalOpen: true
            };
        case AdjustmentSchemesActionType.UPDATE_SUCCESS: {
            return {
                ...state,
                updateModalOpen: false,
                selectedRow: []
            };
        }
        case AdjustmentSchemesActionType.TOGGLE_CLOSE_UPDATE_MODAL:
            return {
                ...state,
                closeUpdateModalOpen: !state.closeUpdateModalOpen
            };
        case AdjustmentSchemesActionType.MODAL_ACTION_DELETE:
            return {
                ...state,
                deleteModalOpen: true
            };
        case AdjustmentSchemesActionType.DELETE_SUCCESS: {
            return {
                ...state,
                deleteModalOpen: false,
                selectedRow: []
            };
        }
        case AdjustmentSchemesActionType.CLOSE_ALL_MODALS:
            return {
                ...state,
                closeCreateModalOpen: false,
                createModalOpen: false,
                closeUpdateModalOpen: false,
                updateModalOpen: false,
                deleteModalOpen: false
            };
        case AdjustmentSchemesActionType.SET_SELECTED_ROW:
            return {
                ...state,
                selectedRow: action.selectedRow.at(-1) === state.selectedRow.at(-1) ? [] : action.selectedRow
            };
        default:
            return state;
    }
};

export { AdjustmentSchemesActionType, adjustmentSchemesReducer, initialState };
