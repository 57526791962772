import React from 'react';
import { Paper, styled, Typography } from '@mui/material';
import { Row } from 'ui/Flex';
import { IAction } from './models/Action';
import { mapActionTypeToTimelineTitle } from './utils/actionTypeToTitle';
import { getActionTime } from './utils/formatDate';

interface ActionTimelineElementProps {
    action: IAction;
    className?: string;
    onClick: (action: IAction) => void;
}

const PREFIX = 'ActionTimelineElement';

const classes = {
    date: `${PREFIX}-date`,
    body: `${PREFIX}-body`
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`& .${classes.date}`]: {
        marginLeft: 'auto'
    },
    [`&.${classes.body}`]: {
        width: '100%',
        cursor: 'pointer',
        margin: theme.spacing(3, 0),
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
        transition: 'all 0.15s ease-out',
        '&:hover': {
            // border: `1px solid ${theme.palette.background.paper}`,
            boxShadow: theme.shadows[1]
            // backgroundColor: theme.palette.orange.tertiary
        },
        '&:first-child': {
            marginTop: 0
        }
    }
}));

function arePropsEqual() {
    return true;
}

export const ActionTimelineElement = React.memo((props: ActionTimelineElementProps) => {
    const { action, onClick } = props;
    const validDate = getActionTime(action);
    const handleClick = React.useCallback(() => {
        onClick(action);
    }, [onClick, action]);

    return (
        <StyledPaper className={classes.body} elevation={0} onClick={handleClick}>
            <Row flex={1} valign="center" wrap="wrap">
                <Typography>{mapActionTypeToTimelineTitle(action)}</Typography>
                <Typography variant="caption" color="textSecondary" align="justify" className={classes.date}>
                    {validDate}
                </Typography>
            </Row>
        </StyledPaper>
    );
}, arePropsEqual);
