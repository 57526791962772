import * as React from 'react';
import logger from 'lib/logger';
import { removeArrayItem, updateArrayItem } from 'lib/helpers';
import { DEFAULT_PAGE_LIMIT } from 'lib/hooks/usePagedData';

import { AdjustmentSchemeType, AdjustmentScheme } from '../adjustmentSchemeModel';
import { adjustmentSchemesApi } from '../adjustmentSchemesApi';
import { QueryParameter } from 'lib/HttpClient';

const DEFAULT_QUERY_PARAMS: QueryParameter[] = [
    { key: 'schemeType', value: AdjustmentSchemeType.COUPON },
    { key: 'limit', value: DEFAULT_PAGE_LIMIT.toString() }
];

export function useAdjustmentSchemesData() {
    const [loading, setLoading] = React.useState(false);
    const [noData, setNoData] = React.useState<boolean>(false);
    const [schemes, setSchemes] = React.useState<AdjustmentScheme[]>([]);

    const count = React.useMemo(() => schemes.length, [schemes]);

    React.useEffect(() => {
        const getSchemes = async () => {
            let nextKey: string;
            let endOfData = false;
            let loadedSchemes: AdjustmentScheme[] = [];

            setLoading(true);

            while (!endOfData) {
                try {
                    const queryParams: QueryParameter[] = nextKey
                        ? [...DEFAULT_QUERY_PARAMS, { key: 'startKey', value: nextKey }]
                        : DEFAULT_QUERY_PARAMS;

                    const { body } = await adjustmentSchemesApi.getList({
                        queryParameters: queryParams
                    });

                    loadedSchemes = [
                        ...loadedSchemes,
                        ...body.items.map((item: AdjustmentScheme) => ({ ...item, id: item._id }))
                    ];

                    nextKey = body.page.nextKey;

                    if (!body.page.nextKey) {
                        endOfData = true;
                    }
                } catch (err) {
                    endOfData = true;
                    logger.error(err.message);
                }
            }

            setSchemes(loadedSchemes);
            setNoData(loadedSchemes.length === 0);
            setLoading(false);
        };

        getSchemes();
    }, [setNoData]);

    const onCreateSuccess = React.useCallback(
        (scheme: AdjustmentScheme) => {
            setSchemes(schemes => [...schemes, { ...scheme, id: scheme._id }]);
        },
        [setSchemes]
    );

    const onUpdateSuccess = React.useCallback(
        (scheme: AdjustmentScheme) => {
            setSchemes(schemes =>
                updateArrayItem(
                    schemes,
                    schemes.findIndex(s => s._id === scheme._id),
                    { ...scheme, id: scheme._id }
                )
            );
        },
        [setSchemes]
    );

    const onDeleteSuccess = React.useCallback(
        (scheme: AdjustmentScheme) => {
            setSchemes(schemes =>
                removeArrayItem(
                    schemes,
                    schemes.findIndex(s => s.id === scheme.id)
                )
            );
        },
        [setSchemes]
    );

    return {
        schemes,
        loading,
        count,
        noData,
        onCreateSuccess,
        onUpdateSuccess,
        onDeleteSuccess
    };
}
