// TODO: refactor this component
import React from 'react';
import { connect } from 'react-redux';
import {
    AppBar,
    ButtonBase,
    ClickAwayListener,
    Divider,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    styled,
    Toolbar,
    Typography
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExitToApp from '@mui/icons-material/ExitToApp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Store from '@mui/icons-material/Store';
import StoreMallDirectory from '@mui/icons-material/StoreMallDirectory';
import { ActionCreator } from 'redux';
import { USER_MERCHANTS } from 'config/routes';
import { mobileToolbarHeight, toolbarHeight } from 'config/theme';
import Logo from 'static/pepper-logo-white.png';
import { signOut, SignOutAction } from 'store/auth/authActions';
import { FirebaseIdentifiers } from 'store/auth/authReducer';
import { ApplicationState } from 'store/store';
import { Row } from 'ui/Flex';
import { Link } from 'ui/Link';
import { NotificationsCentre } from './NotificationsCentre';

interface ReferenceObject {
    clientHeight: number;
    clientWidth: number;

    getBoundingClientRect(): ClientRect;
}

const LOGO_UNITS = 12.5;

const StyledImage = styled('img')(({ theme }) => ({
    width: theme.spacing(1 * LOGO_UNITS)
}));

const PREFIX = 'CustomAppBar';

const appBarClasses = {
    userMenuIcon: `${PREFIX}-userMenuIcon`,
    userName: `${PREFIX}-userName`
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    top: 0,
    height: toolbarHeight,
    [theme.breakpoints.down('md')]: {
        height: mobileToolbarHeight
    },
    [`& .${appBarClasses.userMenuIcon}`]: {
        color: theme.palette.primary.contrastText,
        margin: theme.spacing(0, 0.5),
        fontSize: 20
    },
    [`& .${appBarClasses.userName}`]: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    height: toolbarHeight,
    minHeight: `${toolbarHeight} !important`,
    zIndex: 100,
    paddingLeft: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
        height: mobileToolbarHeight,
        paddingLeft: theme.spacing(1.5)
    }
}));

const classes = {
    userMenuIcon: `${PREFIX}-userMenuIcon`,
    link: `${PREFIX}-link`,
    userMenuButton: `${PREFIX}-userMenuButton`,
    divider: `${PREFIX}-divider`
};

const StyledMenuItem = styled(MenuItem)({
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)'
    }
});

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`& .${classes.userMenuIcon}`]: {
        color: theme.palette.primary.contrastText,
        margin: theme.spacing(0, 0.5),
        fontSize: 20
    },
    [`& .${classes.link}`]: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        '&: focused': {
            textDecoration: 'none'
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        }
    },
    [`& .${classes.userMenuButton}`]: {
        color: theme.palette.primary.contrastText,
        fontSize: '1rem',
        width: '100%'
    },
    [`& .${classes.divider}`]: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)'
    },
    backgroundColor: theme.palette.primary.main,
    borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`
}));

function extractIdentifier(identifiers: FirebaseIdentifiers) {
    if (!identifiers) {
        return null;
    }
    return identifiers.name || identifiers.email;
}

const StyledLink = styled(Link)(({ theme }) => ({
    height: '100%',
    color: theme.palette.primary.contrastText
}));

interface MuiAppBarProps {
    identifiers?: FirebaseIdentifiers;
    title?: string;
    signOut?: () => void;
}
const mapStateToProps = ({ auth }: ApplicationState) => ({
    identifiers: auth.identifiers
});

const mapDispatchToProps = (dispatch: ActionCreator<SignOutAction>) => ({
    signOut: () => dispatch(signOut())
});

export const MuiAppBar = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    class extends React.Component<MuiAppBarProps> {
        anchorEl: HTMLElement | ReferenceObject | ((element?: HTMLElement) => HTMLElement);
        iconAnchorEl: HTMLElement | ReferenceObject | ((element?: HTMLElement) => HTMLElement);
        state = {
            menu: false
        };
        handleUserButtonClick = () => {
            this.setState({ menu: true });
        };
        handleUserMenuClose = () => {
            this.setState({ menu: false });
        };
        handleSignOut = () => {
            this.props.signOut();
        };
        buttonRef = (node: HTMLElement) => {
            this.anchorEl = node;
        };
        iconRef = (node: HTMLElement) => {
            this.iconAnchorEl = node;
        };
        render() {
            const { identifiers, title } = this.props;
            return (
                <React.Fragment>
                    <StyledAppBar position="fixed" color="primary" elevation={1}>
                        <StyledToolbar>
                            <Row valign="center">
                                <StyledImage src={Logo} alt="" />
                            </Row>
                            <Row align="flex-end" valign="center" flex={1} style={{ height: '100%' }}>
                                <NotificationsCentre />
                            </Row>
                            <Row align="flex-end" valign="center" style={{ height: '100%' }}>
                                {title && (
                                    <StyledLink href={USER_MERCHANTS} underline="none">
                                        <ButtonBase sx={{ px: 2, height: '100%' }}>
                                            <Store className={appBarClasses.userMenuIcon} />
                                            <Typography variant="body2" color="inherit">
                                                {title}
                                            </Typography>
                                        </ButtonBase>
                                    </StyledLink>
                                )}
                                <ButtonBase
                                    onClick={this.handleUserButtonClick}
                                    sx={{ pl: 2, pr: 1, height: '100%' }}
                                    ref={this.buttonRef}
                                >
                                    <AccountCircle className={appBarClasses.userMenuIcon} />
                                    <Typography
                                        color="inherit"
                                        variant="body2"
                                        className={appBarClasses.userName}
                                    >
                                        {extractIdentifier(identifiers)}
                                    </Typography>
                                    <KeyboardArrowDown className={appBarClasses.userMenuIcon} />
                                </ButtonBase>
                            </Row>
                        </StyledToolbar>
                    </StyledAppBar>
                    <Popper
                        style={{ zIndex: 1 }}
                        open={this.state.menu}
                        anchorEl={this.anchorEl}
                        transition
                        placement="bottom-end"
                    >
                        {({ TransitionProps }) => (
                            <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
                                <StyledPaper square>
                                    <ClickAwayListener onClickAway={this.handleUserMenuClose}>
                                        <MenuList onClick={this.handleUserMenuClose}>
                                            <StyledLink
                                                className={classes.link}
                                                underline="none"
                                                href={USER_MERCHANTS}
                                            >
                                                <StyledMenuItem className={classes.userMenuButton}>
                                                    <StoreMallDirectory
                                                        className={classes.userMenuIcon}
                                                        strokeWidth="1.5"
                                                    />
                                                    <Typography color="inherit">Merchants</Typography>
                                                </StyledMenuItem>
                                            </StyledLink>

                                            <Divider className={classes.divider} />
                                            <StyledMenuItem
                                                onClick={this.handleSignOut}
                                                className={classes.link}
                                            >
                                                <Row valign="center" className={classes.userMenuButton}>
                                                    <ExitToApp
                                                        className={classes.userMenuIcon}
                                                        strokeWidth="1.5"
                                                    />
                                                    <Typography>Log out</Typography>
                                                </Row>
                                            </StyledMenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </StyledPaper>
                            </Grow>
                        )}
                    </Popper>
                </React.Fragment>
            );
        }
    }
);
