import React from 'react';
import { ContentModule } from 'components/content/models/ContentModule';
import { contentApi } from 'components/content/contentApi';
import logger from 'lib/logger';
import { MainLayout } from 'layouts/MainLayout';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_CREATE_ERROR,
    MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_CREATE_IN_PROGRESS,
    MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_DELETE_ERROR,
    MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_DELETE_SUCCESS,
    MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_PAUSE,
    MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_RESUME,
    MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_UPDATE_ERROR,
    MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_UPDATE_IN_PROGRESS
} from 'config/messages';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import rulesReducer from 'components/rules/reducers/rulesReducer';
import { RuleResource } from 'components/rules/rules.types';
import { rulesApi } from 'components/rules/rulesApi';
import { RulesTable } from 'components/rules/tables/RulesTable';
import { CreateRuleScheduledTaskNotificationModal } from 'components/rules/scheduled-notification/modals/CreateRuleScheduledTaskNotificationModal';
import { UpdateRuleScheduledTaskNotificationModal } from 'components/rules/scheduled-notification/modals/UpdateRuleScheduledTaskNotificationModal';

const { reducer, initialState, ActionType } = rulesReducer;

async function getModules(): Promise<ContentModule[]> {
    const { ok, body: modules } = await contentApi.modules.getList();
    if (!ok) {
        return [];
    }
    return modules;
}

export const TriggeredCampaignsPage = () => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const reduxDispatch = useDispatch();

    const {
        isLoading,
        modules,
        selectedRule,
        actionedRule,
        createRuleModalOpen,
        deleteRuleModalOpen,
        updateRuleModalOpen
    } = state;

    React.useEffect(() => {
        async function load() {
            try {
                dispatch({ type: ActionType.START_REQUEST });
                const modules = await getModules();
                dispatch({ type: ActionType.LOAD_SUCCESS, modules });
            } catch (error) {
                logger.error('Failed to load scheduled tasks', error);
                dispatch({ type: ActionType.LOAD_ERROR });
            }
        }
        load();
    }, []);

    const handleCreateClose = React.useCallback(() => {
        dispatch({ isOpen: false, type: ActionType.MODAL_ACTION_CREATE });
    }, [dispatch]);
    const handleCreateError = React.useCallback(
        (errorMessage: string) => {
            dispatch({ isOpen: false, type: ActionType.MODAL_ACTION_CREATE });
            reduxDispatch(
                enqueueSnackbar(
                    MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_CREATE_ERROR.replace(
                        '{{errorMessage}}',
                        errorMessage
                    ),
                    {
                        variant: 'error'
                    }
                )
            );
        },
        [dispatch, reduxDispatch]
    );
    const handleCreateSuccess = React.useCallback(
        (rule: RuleResource) => {
            dispatch({ type: ActionType.CREATE_SUCCESS, rule });
            reduxDispatch(
                enqueueSnackbar(MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_CREATE_IN_PROGRESS, {
                    variant: 'info'
                })
            );
        },
        [reduxDispatch]
    );
    const handleCreateClick = React.useCallback(
        (rule: RuleResource) =>
            dispatch({
                isOpen: true,
                type: ActionType.MODAL_ACTION_CREATE,
                selectedRule: rule
            }),
        [dispatch]
    );
    const handleUpdateClose = React.useCallback(() => {
        dispatch({ isOpen: false, type: ActionType.MODAL_ACTION_UPDATE });
    }, [dispatch]);
    const handleUpdateError = React.useCallback(
        (errorMessage: string) => {
            dispatch({ isOpen: false, type: ActionType.MODAL_ACTION_UPDATE });
            reduxDispatch(
                enqueueSnackbar(
                    MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_UPDATE_ERROR.replace(
                        '{{errorMessage}}',
                        errorMessage
                    ),
                    {
                        variant: 'error'
                    }
                )
            );
        },
        [dispatch, reduxDispatch]
    );
    const handleUpdateSuccess = React.useCallback(
        (rule: RuleResource) => {
            dispatch({ type: ActionType.UPDATE_SUCCESS, rule });
            reduxDispatch(
                enqueueSnackbar(MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_UPDATE_IN_PROGRESS, {
                    variant: 'info'
                })
            );
        },
        [reduxDispatch]
    );
    const handleUpdateClick = React.useCallback(
        (rule: RuleResource) =>
            dispatch({
                isOpen: true,
                type: ActionType.MODAL_ACTION_UPDATE,
                selectedRule: rule
            }),
        [dispatch]
    );
    const handleDeleteClose = React.useCallback(() => {
        dispatch({ isOpen: false, type: ActionType.MODAL_ACTION_DELETE });
    }, [dispatch]);
    const handleDeleteConfirm = React.useCallback(async () => {
        try {
            dispatch({ type: ActionType.START_REQUEST });
            const { ok } = await rulesApi.delete(selectedRule._id, {
                skipResponseBody: true
            });
            if (!ok) {
                throw new Error('Failed to delete scheduled task');
            }
            dispatch({ type: ActionType.DELETE_SUCCESS, rule: selectedRule });
            reduxDispatch(
                enqueueSnackbar(MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_DELETE_SUCCESS, {
                    variant: 'success'
                })
            );
        } catch (error) {
            logger.error('Failed to load scheduled tasks', error);
            dispatch({ isOpen: false, type: ActionType.MODAL_ACTION_DELETE });
            reduxDispatch(
                enqueueSnackbar(
                    MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_DELETE_ERROR.replace(
                        '{{errorMessage}}',
                        error.message
                    ),
                    {
                        variant: 'error'
                    }
                )
            );
        }
    }, [reduxDispatch, selectedRule]);
    const handleDeleteClick = React.useCallback(
        (rule: RuleResource) =>
            dispatch({
                isOpen: true,
                type: ActionType.MODAL_ACTION_DELETE,
                selectedRule: rule
            }),
        [dispatch]
    );
    const handlePauseUnpauseClick = React.useCallback(
        async (rule: RuleResource, active: boolean) => {
            try {
                dispatch({ type: ActionType.START_REQUEST });
                const { ok } = await rulesApi.update(rule._id, {
                    body: {
                        active
                    }
                });
                if (!ok) {
                    throw new Error('Failed to pause/unpause scheduled task');
                }
                dispatch({ type: ActionType.UPDATE_SUCCESS, rule });
                reduxDispatch(
                    enqueueSnackbar(
                        active
                            ? MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_RESUME
                            : MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_PAUSE,
                        {
                            variant: 'success'
                        }
                    )
                );
            } catch (error) {
                logger.error('Failed to pause/unpause scheduled task', error);
                dispatch({ isOpen: false, type: ActionType.MODAL_ACTION_DELETE });
                reduxDispatch(
                    enqueueSnackbar(
                        MESSAGE_CAMPAIGNS_TRIGGERED_NOTIFICATION_UPDATE_ERROR.replace(
                            '{{errorMessage}}',
                            error.message
                        ),
                        {
                            variant: 'error'
                        }
                    )
                );
            }
        },
        [reduxDispatch]
    );

    return (
        <MainLayout
            pageName="Triggered Campaigns"
            pageDescription="View and manage your triggered campaigns"
            noScroll
        >
            <RulesTable
                isLoading={isLoading}
                onCreateClick={handleCreateClick}
                onPauseUnpauseClick={handlePauseUnpauseClick}
                onUpdateClick={handleUpdateClick}
                onDeleteClick={handleDeleteClick}
                actionedRule={actionedRule}
            />
            <CreateRuleScheduledTaskNotificationModal
                open={createRuleModalOpen}
                onClose={handleCreateClose}
                onSuccess={handleCreateSuccess}
                onError={handleCreateError}
                rule={selectedRule}
                modules={modules}
            />
            <UpdateRuleScheduledTaskNotificationModal
                open={updateRuleModalOpen}
                onClose={handleUpdateClose}
                onSuccess={handleUpdateSuccess}
                onError={handleUpdateError}
                rule={selectedRule}
                modules={modules}
            />
            <DeleteDialog
                open={deleteRuleModalOpen}
                title="Delete push notifications"
                content="If you delete this push notification, you will not be able to recover when deleted.
                    To confirm deletion, type 'DELETE' in the text input field."
                onClose={handleDeleteClose}
                onDelete={handleDeleteConfirm}
                loading={isLoading}
                protectionWord="DELETE"
            />
        </MainLayout>
    );
};
