import React from 'react';
import { styled, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import { getFullHex } from 'lib/helpers';
import { Column, Row } from 'ui/Flex';
import {
    IBrowserMessageItem,
    IDefaultMessageItem,
    IDisplay,
    ILocationMessageItem,
    ILocationsListMessageItem,
    IMarketingModule,
    INativeMessageItem,
    IPresetMessageItem,
    isDoubleHeightDisplay,
    isLargeSquareDisplay,
    isSingleHeightDisplay,
    isSmallSquareDisplay,
    IStartOrderMessageItem,
    MarketingMessageItem,
    marketingTypeguards
} from '../models/ContentModule';
import { ContentAnchorProperties } from '../models/ContentTemplate';
import { ContentModuleRenderer, getBorderRadius, MobileLayoutTheme } from '../renderContentModule';
import { MUIStyledCommonProps } from '@mui/system';

const PREFIX = 'renderMarketingModule';

const classes = {
    root: `${PREFIX}-root`,
    title: `${PREFIX}-title`,
    secondaryAction: `${PREFIX}-secondaryAction`,
    content: `${PREFIX}-content`,
    image: `${PREFIX}-image`,
    itemBackground: `${PREFIX}-itemBackground`,
    itemBackgroundInverted: `${PREFIX}-itemBackgroundInverted`,
    itemBackgroundOff: `${PREFIX}-itemBackgroundOff`,
    item: `${PREFIX}-item`,
    itemInverted: `${PREFIX}-itemInverted`,
    row: `${PREFIX}-row`,
    column: `${PREFIX}-column`
};

const getHeightMultiplier = (display?: IDisplay) => {
    if (isSingleHeightDisplay(display) || isSmallSquareDisplay(display)) {
        return 1;
    }

    if (isLargeSquareDisplay(display)) {
        return 1.5;
    }

    if (isDoubleHeightDisplay(display)) {
        return 2;
    }

    return 1;
};

const getWidthDivider = (display?: IDisplay) => {
    if (isSingleHeightDisplay(display) || isDoubleHeightDisplay(display)) {
        return 1;
    }

    if (isLargeSquareDisplay(display)) {
        return 2;
    }

    if (isSmallSquareDisplay(display)) {
        return 3;
    }

    return 1;
};

const StyledColumn = styled(Column)(
    ({
        theme: muiTheme,
        marketing,
        layoutTheme: theme,
        properties
    }: StyledMarketingModuleProps & MUIStyledCommonProps<Theme>) => ({
        [`& .${classes.root}`]: {
            width: '100%',
            overflowX: 'auto',
            overflowY: 'hidden',
            height: muiTheme.spacing(1 + (theme.height * getHeightMultiplier(marketing.display)) / 8)
        },
        [`& .${classes.title}`]: {
            transition: 'color .15s linear',
            color: properties.titleTextColour ? properties.titleTextColour : properties.anchorTextColour,
            fontSize: theme.fontSize
        },
        [`& .${classes.secondaryAction}`]: {
            transition: 'color .15s linear',
            color: properties.tintColour,
            textAlign: 'right',
            fontSize: theme.fontSize - 2
        },
        [`& .${classes.content}`]: {
            width: `${100 / marketing.items?.length}%`,
            height: theme.height * getHeightMultiplier(marketing.display),
            borderRadius: properties.useRoundedCorners ? theme.borderRadius : 'unset',
            transition: 'border-radius .15s linear',
            marginRight: 4,
            position: 'relative'
        },
        [`& .${classes.image}`]: {
            height: '100%',
            width: '100%',
            borderRadius: properties.useRoundedCorners ? theme.borderRadius : 'unset',
            transition: 'background-color .15s linear, border-radius .15s linear',
            backgroundColor: properties.anchorBackgroundColour
        },
        [`& .${classes.itemBackground}`]: {
            backgroundColor: `${getFullHex(properties.anchorBackgroundColour)}B3`,
            position: 'absolute',
            top: '60%',
            width: '100%',
            height: '40%',
            transition: 'background-color .15s linear, border-radius .15s linear',
            borderRadius: properties.useRoundedCorners
                ? `0 0 ${theme.borderRadius}px ${theme.borderRadius}px`
                : 'unset'
        },
        [`& .${classes.itemBackgroundInverted}`]: {
            backgroundColor: `${getFullHex(properties.anchorTextColour)}B3`
        },
        [`& .${classes.itemBackgroundOff}`]: {
            backgroundColor: 'transparent'
        },
        [`& .${classes.item}`]: {
            color: properties.bodyTextColour ? properties.bodyTextColour : properties.anchorTextColour,
            width: marketing.items?.length > 1 ? '46%' : '100%',
            borderRadius: getBorderRadius(properties),
            transition: 'border-radius .15s linear, color .15s linear, background-color .15s linear',
            marginLeft: muiTheme.spacing(1),
            marginTop: marketing.display?.height === 'SINGLE' ? 0 : muiTheme.spacing(1),
            padding: theme.unit,
            overflow: 'hidden',
            fontSize: theme.fontSize
        },
        [`& .${classes.itemInverted}`]: {
            color: properties.anchorBackgroundColour
        },
        [`& .${classes.row}`]: {
            minWidth: '100%',
            width:
                marketing.items?.length > 1
                    ? `${(marketing.items?.length * 92) / getWidthDivider(marketing.display)}%`
                    : '100%',
            position: 'relative',
            padding: muiTheme.spacing(0.5, 0),
            paddingLeft: muiTheme.spacing(0.5),
            cursor: properties.isSelectable ? 'pointer' : 'inherit',
            transition: 'background-color 0.1s ease-in, transform 0.1s ease-in'
        },
        [`&.${classes.column}`]: {
            [`&.${classes.column}:hover`]: {
                backgroundColor: properties.isSelectable
                    ? `rgba(92, 92, 92, ${properties.isSelected ? '0.16' : '0.12'})`
                    : 'initial',
                transform: `${properties.isSelectable && !properties.isSelected ? 'scale(1.025)' : 'none'}`
            },
            width: '100%',
            padding: muiTheme.spacing(0.5),
            paddingTop: '0px',
            margin: '0px',
            marginTop: muiTheme.spacing(0.5),
            backgroundColor: properties.isSelected ? 'rgba(92, 92, 92, 0.16)' : 'initial',
            cursor: properties.isSelectable ? 'pointer' : 'inherit',
            transition: 'background-color 0.1s ease-in, transform 0.1s ease-in',
            border: `2px solid ${
                properties.isSelectable && properties.isSelected ? 'rgb(62, 141, 208)' : 'rgba(0,0,0,0)'
            }`
        }
    })
);

interface MarketingModuleProps {
    marketing: IMarketingModule;
    theme: MobileLayoutTheme;
    properties: ContentAnchorProperties;
}

interface StyledMarketingModuleProps {
    marketing: IMarketingModule;
    layoutTheme: MobileLayoutTheme;
    properties: ContentAnchorProperties;
}

export const renderMarketingModule: ContentModuleRenderer = (
    marketing: IMarketingModule,
    theme,
    properties,
    _
) => <MarketingModuleComponent marketing={marketing} theme={theme} properties={properties} />;

const StartOrderMessageRenderer: React.FC<{ item: IStartOrderMessageItem }> = ({ item }) => {
    const timeString = React.useMemo(() => {
        if (item) {
            return new Date().toTimeString();
        }
    }, [item]);
    return (
        <div className={classes.content} key={`marketing-item-${item.properties.imageUrl}`}>
            <img className={classes.image} src={`${item.properties.imageUrl}?t=${timeString}`} alt="" />
        </div>
    );
};

const MessageRenderer: React.FC<{
    item:
        | IPresetMessageItem
        | IBrowserMessageItem
        | ILocationMessageItem
        | ILocationsListMessageItem
        | INativeMessageItem
        | IDefaultMessageItem;
}> = ({ item }) => {
    const timeString = React.useMemo(() => {
        if (item) {
            return new Date().toTimeString();
        }
    }, [item]);
    return (
        <div className={classes.content}>
            <img className={classes.image} src={`${item.properties.imageUrl}?t=${timeString}`} alt="" />
            <div
                className={clsx({
                    [classes.itemBackground]: true,
                    [classes.itemBackgroundInverted]: item.properties.invertTextColour,
                    [classes.itemBackgroundOff]: !item.properties.showTextBackgroundBar
                })}
            >
                <Typography
                    className={clsx({
                        [classes.item]: true,
                        [classes.itemInverted]: item.properties.invertTextColour
                    })}
                >
                    {item.properties.title}
                </Typography>
            </div>
        </div>
    );
};

function MarketingModuleComponent({ theme: layoutTheme, ...props }: MarketingModuleProps) {
    const { marketing, properties } = props;

    const handleClick = React.useCallback(() => {
        if (properties.handleClick && !properties.isSelected) {
            properties.handleClick();
        }
    }, [properties]);
    const renderMarketingItem = React.useCallback((item: MarketingMessageItem, index: number) => {
        if (marketingTypeguards.isCustomMessage(item)) {
            return (
                <div className={classes.content} key={`marketing-item-${item.properties.url}`}>
                    <div className={classes.image} />
                    <div className={classes.itemBackground}>
                        <Typography className={classes.item}>{item.properties.url}</Typography>
                    </div>
                </div>
            );
        }

        if (marketingTypeguards.isStartOrderMessage(item)) {
            return (
                <StartOrderMessageRenderer key={`marketing-item-${item.properties.imageUrl}`} item={item} />
            );
        }

        return (
            <MessageRenderer
                key={`marketing-item-${index}-${item.properties.title || item.properties.imageUrl}`}
                item={item}
            />
        );
    }, []);

    return (
        <StyledColumn className={classes.column} onClick={handleClick} {...props} layoutTheme={layoutTheme}>
            <Row flex={1} align="space-between" valign="flex-end">
                <Typography className={classes.title}>{marketing.title}</Typography>
                {marketing.secondaryAction && (
                    <Typography className={classes.secondaryAction}>
                        {marketing.secondaryAction?.title}
                    </Typography>
                )}
            </Row>
            <div className={clsx('hidden-scroll', classes.root)} onClick={handleClick}>
                <Row className={classes.row}>{marketing.items?.map(renderMarketingItem)}</Row>
            </div>
        </StyledColumn>
    );
}
