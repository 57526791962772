import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { Option } from 'lib/types';
import React from 'react';
import { Column } from 'ui/Flex';
import { ContentModuleType } from '../models/ContentModule';

interface AddModuleMenuProps {
    onClick: (type: ContentModuleType) => void;
    enabledTypes: ContentModuleType[];
    disabled?: boolean;
}

const typesToMenuItem: Record<ContentModuleType, Omit<Option, 'value'>> = {
    [ContentModuleType.PRIMARY_ACTION]: {
        label: 'Add a primary call-to-action module',
        description:
            'Use this module to start ordering journeys and other marketing features, such as referrals and vouchers.'
    },
    [ContentModuleType.MARKETING]: {
        label: 'Add a marketing module',
        description:
            'Use this module to visually promote new products, news, and ordering journeys, such as Preorder and Order to Table.'
    },
    [ContentModuleType.LOCATIONS]: {
        label: 'Add a location map module',
        description: 'This module provides information on each location, such as opening hours and events.'
    },
    [ContentModuleType.AWARDS]: {
        label: 'Add an awards module',
        description:
            "This module lists the user's available awards and displays information about each award."
    },
    [ContentModuleType.FAVOURITES]: {
        label: 'Add a favorite location module',
        description: "This module provides quick access to the user's favourite location."
    },
    [ContentModuleType.LOYALTY_BALANCE]: {
        label: '',
        description: ''
    },
    [ContentModuleType.SECRET_DJ]: {
        label: '',
        description: ''
    }
};

export const AddModuleMenu: React.FC<AddModuleMenuProps> = ({ onClick, enabledTypes, disabled }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);
    const handleSelect = React.useCallback(
        (value: ContentModuleType) => () => {
            onClick(value);
            setAnchorEl(null);
        },
        [onClick]
    );
    const renderTypes = React.useCallback(
        (value: ContentModuleType, index: number) => {
            const { label, description } = typesToMenuItem[value];
            return (
                <MenuItem value={value} onClick={handleSelect(value)} key={`${value}-${index}`}>
                    {description ? (
                        <Column>
                            {label}
                            {React.isValidElement(description) ? (
                                description
                            ) : (
                                <Typography variant="caption">{description}</Typography>
                            )}
                        </Column>
                    ) : (
                        label
                    )}
                </MenuItem>
            );
        },
        [handleSelect]
    );
    return (
        <React.Fragment>
            <Button variant="outlined" color="primary" onClick={handleClick} disabled={disabled}>
                + Add Module
            </Button>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                {enabledTypes.map(renderTypes)}
            </Menu>
        </React.Fragment>
    );
};
