import { Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { EPremiumLoyaltyState, UserMetricDefinition } from '../model';
import { LoadingTypography } from 'lib/LoadingTypoghraphy';
import { titleize } from 'lib/helpers';
import { Row } from 'ui/Flex';

interface BirthdayLoyaltyOverviewProps {
    metricDefinition?: UserMetricDefinition;
    loading?: boolean;
    readyToActivate?: boolean;
    onEdit: () => void;
    onStateChange: () => void;
    state?: EPremiumLoyaltyState;
    timeWindow?: number;
}

export const BirthdayLoyaltyOverview: React.FC<BirthdayLoyaltyOverviewProps> = ({
    metricDefinition,
    loading,
    readyToActivate,
    onEdit,
    onStateChange,
    state
}) => {
    const disableStateButton = React.useMemo(
        () => !readyToActivate && state !== EPremiumLoyaltyState.ACTIVE,
        [readyToActivate, state]
    );
    return (
        <Paper sx={{ p: 2, pt: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Row align="space-between">
                        <Typography variant="h6">Overview</Typography>
                        {!!metricDefinition && (
                            <Row gutter>
                                <Box sx={{ position: 'relative' }}>
                                    <Button
                                        variant={
                                            state === EPremiumLoyaltyState.INACTIVE ? 'contained' : 'outlined'
                                        }
                                        size="small"
                                        disabled={disableStateButton || loading}
                                        onClick={onStateChange}
                                    >
                                        {state === EPremiumLoyaltyState.INACTIVE ? 'Activate' : 'Disable'}
                                    </Button>
                                    {disableStateButton && (
                                        <Tooltip title="In order to activate, please make sure you have a valid Time Window.">
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%'
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </Box>
                                <Button size="small" variant="outlined" disabled={loading} onClick={onEdit}>
                                    Edit
                                </Button>
                            </Row>
                        )}
                        {!metricDefinition && (
                            <Button size="small" variant="contained" disabled={loading} onClick={onEdit}>
                                Create
                            </Button>
                        )}
                    </Row>
                </Grid>
                {!loading && !metricDefinition && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="body2">Name</Typography>
                            <Typography>N/A</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">State</Typography>
                            <Typography>N/A</Typography>
                        </Grid>
                    </React.Fragment>
                )}
                {(loading || metricDefinition) && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="body2">Name</Typography>
                            <LoadingTypography loading={loading}>{metricDefinition?.name}</LoadingTypography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">State</Typography>
                            <LoadingTypography loading={loading}>{titleize(state)}</LoadingTypography>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </Paper>
    );
};
