/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, styled, Typography } from '@mui/material';
import { IPublicAndPrivateSettings } from '@pepperhq/location-sdk';
import ct from 'countries-and-timezones';
import { uploadFile } from 'components/content/uploadFile';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { ChipInputFormField } from 'lib/form/fields/ChipInputFormField';
import { FileInputFormField } from 'lib/form/fields/FileInputFormField';
import { KeyFileInputFormField } from 'lib/form/fields/KeyFileInputFormField';
import { NumberFormField } from 'lib/form/fields/NumberFormField';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { MuiCardForm } from 'lib/form/MuiCardForm';
import { parseIntOrUndefined } from 'lib/helpers';
import { useMediaCheck } from 'lib/hooks/useMediaCheck';
import logger from 'lib/logger';
import { SettingDisplay } from 'lib/SettingDisplay';
import { Option } from 'lib/types';
import { ApplicationState } from 'store/store';
import { ILocationScopedSettingsComponentProps } from './LocationScopedSettings';
import { SettingDisplaySwitch } from './SettingDisplaySwitch';
import { SettingDisplayText } from './SettingDisplayText';
import { isDefined } from 'lib/typeguards';
import { RegionCode, regionSetting, availableLocales, LocaleCode } from '@pepperhq/regions';
import * as lookup from 'country-code-lookup';
import { tenantApi } from 'components/merchant/tenantApi';
import { Tenant } from 'components/merchant/models/Tenant';
import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { Row } from 'ui/Flex';
import { SettingDisplayFile } from './SettingDisplayFile';
import { KeyValueSettings } from './KeyValueSettings';
import { useTwilioNumbers } from './hooks/useTwilioNumbers';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';

const APP_VERSION_REGEX = new RegExp(/^\d+(?:\.\d+){0,3}$/);
const appVersionValidation = Yup.object().shape({
    ios: Yup.object().shape({
        must: Yup.string()
            .trim()
            .matches(APP_VERSION_REGEX, 'Please enter valid version format, e.g. 0.0.0.1130'),
        should: Yup.string()
            .trim()
            .matches(APP_VERSION_REGEX, 'Please enter valid version format, e.g. 0.0.0.1130')
    }),
    android: Yup.object().shape({
        must: Yup.string()
            .trim()
            .matches(APP_VERSION_REGEX, 'Please enter valid version format, e.g. 0.0.0.1130'),
        should: Yup.string()
            .trim()
            .matches(APP_VERSION_REGEX, 'Please enter valid version format, e.g. 0.0.0.1130')
    })
});

const PREFIX = 'GlobalSettings';

const classes = {
    settingsCard: `${PREFIX}-settingsCard`,
    displayClassName: `${PREFIX}-displayClassName`,
    identityChip: `${PREFIX}-identityChip`
};

const StyledMuiCardForm = styled(MuiCardForm)(({ theme }) => ({
    [`&.${classes.settingsCard}`]: {
        marginBottom: theme.spacing(2)
    },
    [`& .${classes.displayClassName}`]: {
        padding: theme.spacing(2)
    },
    [`& .${classes.identityChip}`]: {
        marginRight: theme.spacing(0.5)
    }
}));

enum EAppEnvironment {
    BETA = 'BETA',
    PRODUCTION = 'PRODUCTION'
}

enum EAppUserAuth {
    LEGACY = 'None (legacy)',
    PASSWORD = 'Password Only',
    VERIFY_PASSWORD = 'Two-Factor Authentication'
}

enum EMenuSupplementProvider {
    TENKITES = 'TENKITES',
    NONE = 'NONE'
}

enum ETenKitesNutritionUnit {
    PER_SERVING = 'perServ',
    PER_HUNDRED = 'perHund',
    GDA_PERCENT = 'gdaPerc'
}

const identityProvidersOptions: Option[] = [
    { label: 'Phone', value: 'phone' },
    { label: 'Email', value: 'email' }
];

const supportedCountryCallingCodeOptions: Option[] = Object.entries(regionSetting).map(([code, setting]) => ({
    label: lookup.byIso(code)?.country ?? 'United Kingdom',
    value: setting.phoneNumberCode
}));

const filePickerAttributes = { type: 'file', accept: '.md' };
const filePickerAndroidAttributes = { type: 'file', accept: '.json' };
const filePickeriOSAttributes = { type: 'file', accept: '.plist' };
const filePickerFirebaseAttributes = { type: 'file', accept: '.json' };

const appEnvironmentOptions: Option[] = [
    {
        value: EAppEnvironment.BETA,
        label: 'Beta'
    },
    {
        value: EAppEnvironment.PRODUCTION,
        label: 'Production'
    }
];

enum EUserBarcodeFormat {
    PDF417 = 'PDF417',
    QR = 'QR'
}

const userBarcodeFormatLabels: Record<EUserBarcodeFormat, string> = {
    [EUserBarcodeFormat.PDF417]: 'PDF417 Barcode',
    [EUserBarcodeFormat.QR]: 'QR Code'
};

const getUserBarcodeFormatLabel = (provider: string | undefined): string => {
    if (provider === undefined) {
        return userBarcodeFormatLabels[EUserBarcodeFormat.PDF417];
    }

    return userBarcodeFormatLabels[provider as EUserBarcodeFormat];
};

// Standard Regional Settings per country, these should be unchangable
const regionSettings: { [regionCode: string]: IPublicAndPrivateSettings['region'] } = regionSetting;
const regionsToDisplay = (() => {
    const result: { label: string; value: string }[] = [];
    for (const region in RegionCode) {
        result.push({
            label: lookup.byIso(region)?.country ?? 'United Kingdom',
            value: region
        });
    }
    return result;
})();

const localeOptions = (() => {
    const result: { label: string; value: LocaleCode }[] = [];
    availableLocales.forEach(locale => result.push({ label: locale, value: locale }));
    return result;
})();

const timezoneOptions = Object.entries(ct.getAllTimezones()).map(tz => ({ label: tz[0], value: tz[0] }));

export const GlobalSettings: React.FC<ILocationScopedSettingsComponentProps> = ({
    settings,
    onSettingsSaved,
    locationId
}) => {
    const dispatch = useDispatch();
    const [tenant, setTenant] = React.useState<Tenant | null>(null);
    const { tenantId } = useSelector((state: ApplicationState) => state.auth.tenant);
    const [faqMDSrc, faqMdLoading, checkFaq, getFaqUrl] = useMediaCheck(
        `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/faq`,
        'faq'
    );
    const [aboutMDSrc, aboutMdLoading, checkAbout, getAboutUrl] = useMediaCheck(
        `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/about`,
        'about'
    );
    const [privacyMDSrc, privacyMdLoading, checkPrivacy, getPrivacyUrl] = useMediaCheck(
        `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/privacy`,
        'privacy'
    );
    const [termsMDSrc, termsMdLoading, checkTerms, getTermsUrl] = useMediaCheck(
        `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/terms`,
        'terms'
    );
    const [androidFirebase, androidFirebaseLoading, checkAndroidFirebase] = useMediaCheck(
        `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_config/google-services.json`,
        'google-services.json'
    );
    const [iOSFirebase, iOSFirebaseLoading, checkiOSFirebase] = useMediaCheck(
        `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_config/GoogleService-Info.plist`,
        'GoogleService-Info.plist '
    );
    const {
        values: twilioNumbers,
        onChange: onTwilioNumbersChange,
        validate: validateTwilioNumbers
    } = useTwilioNumbers(settings?.twilio?.numbers);
    const handleFetchTenant = React.useCallback(async () => {
        const tenantResult = await tenantApi.get(tenantId);

        if (tenantResult.ok === true) {
            setTenant(tenantResult.body);
        }

        return tenantResult;
    }, [tenantId]);

    React.useEffect(() => {
        handleFetchTenant();
    }, [handleFetchTenant]);

    const handleSubmitGeneral = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                const phoneAuthEnabled = !!values.identityProviders.find(
                    (provider: Option) => provider.value === 'phone'
                );
                const facebookAuthEnabled = !!values.identityProviders.find(
                    (provider: Option) => provider.value === 'facebook'
                );
                const emailAuthEnabled = !!values.identityProviders.find(
                    (provider: Option) => provider.value === 'email'
                );
                if (values.faqMD) {
                    const { ok } = await uploadFile(
                        new File([await values.faqMD.text()], values.faqMD.name, {
                            type: 'text/markdown'
                        }),
                        'faq',
                        'app_assets'
                    );
                    if (ok) {
                        checkFaq();
                    }
                }
                if (values.termsMD) {
                    const { ok } = await uploadFile(
                        new File([await values.termsMD.text()], values.termsMD.name, {
                            type: 'text/markdown'
                        }),
                        'terms',
                        'app_assets'
                    );
                    if (ok) {
                        checkTerms();
                    }
                }
                if (values.privacyMD) {
                    const { ok } = await uploadFile(
                        new File([await values.privacyMD.text()], values.privacyMD.name, {
                            type: 'text/markdown'
                        }),
                        'privacy',
                        'app_assets'
                    );
                    if (ok) {
                        checkPrivacy();
                    }
                }
                if (values.aboutMD) {
                    const { ok } = await uploadFile(
                        new File([await values.aboutMD.text()], values.aboutMD.name, {
                            type: 'text/markdown'
                        }),
                        'about',
                        'app_assets'
                    );
                    if (ok) {
                        checkAbout();
                    }
                }
                let settingsToUpdate: IPublicAndPrivateSettings = {};

                // Common Settings
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        phoneAuthEnabled,
                        facebookAuthEnabled,
                        emailAuthEnabled,
                        app: {
                            ageRequestEnabled: values.ageRequestEnabled,
                            registrationMinimumAge: parseIntOrUndefined(values.registrationMinimumAge),
                            registrationShowMarketingOptIn: values.registrationShowMarketingOptIn,
                            appId: values.appId,
                            showCopyright: values.showCopyright,
                            userBarcodeFormat: values.userBarcodeFormat,
                            userBarcodePrefix: values.userBarcodePrefix
                        },
                        region: {
                            code: values.region,
                            timezone: values.timezone,
                            taxLabel: values.taxLabel,
                            locale: values.locale
                        },
                        iam: {
                            appUserAuth: values.appUserAuth === 'LEGACY' ? null : values.appUserAuth
                        },
                        uniqueDeviceVerificationEnabled: values.uniqueDeviceVerificationEnabled,
                        phoneAuth: {
                            supportedCountryCallingCodes: values.supportedCountryCallingCodes?.map(
                                (pair: any) => pair.value
                            )
                        },
                        offlineCheckinEnabled: values.offlineCheckinEnabled,
                        secondaryCredentialsEnabled: values.secondaryCredentialsEnabled,
                        inAppReviewPromptsEnabled: values.inAppReviewPromptsEnabled
                    };

                    if (settingsToUpdate.region.code) {
                        settingsToUpdate.region = {
                            ...settingsToUpdate.region,
                            ...regionSettings[settingsToUpdate.region.code]
                        };
                    }
                }
                if (!Object.keys(settingsToUpdate)) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);
                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [checkAbout, checkFaq, checkPrivacy, checkTerms, locationId, onSettingsSaved]
    );

    const handleSubmitAndroidIos = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};
                if (values.androidFirebase) {
                    const { ok } = await uploadFile(
                        new File([await values.androidFirebase.text()], values.androidFirebase.name, {
                            type: 'application/json'
                        }),
                        'google-services',
                        'app_config'
                    );
                    if (ok) {
                        checkAndroidFirebase();
                    }
                }
                if (values.iOSFirebase) {
                    const { ok } = await uploadFile(
                        new File([await values.iOSFirebase.text()], values.iOSFirebase.name, {
                            type: 'application/x-plist'
                        }),
                        'GoogleService-Info',
                        'app_config'
                    );
                    if (ok) {
                        checkiOSFirebase();
                    }
                }

                // Common Settings
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        app: {
                            displayName: values.displayName,
                            bundleIdentifier: values.bundleIdentifier,
                            bundleDisplayName: values.bundleDisplayName,
                            bundleName: values.bundleName,
                            appURLScheme: values.appURLScheme,
                            appStoreUrl: values.appStoreUrl,
                            googleMapsApiKey: values.googleMapsApiKey,
                            playStoreUrl: values.playStoreUrl,
                            environment: values.environment
                        },
                        appleDeviceCheck: {
                            keyId: values.appleKeyId,
                            teamId: values.appleTeamId,
                            keyAsBase64: values.appleKeyFile
                        },
                        web: {
                            domainName: values.domainName
                        },
                        firebase: {
                            credentialsAsBase64: values.firebaseCredentialsFile
                        },
                        smartlookEnabled: values.smartlookEnabled
                    };
                }
                if (!Object.keys(settingsToUpdate).length) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);
                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [checkAndroidFirebase, checkiOSFirebase, locationId, onSettingsSaved]
    );
    const handleSubmitTwilio = React.useCallback(
        async (values: Record<string, any>) => {
            let numbers: Record<string, string[]> = {};
            try {
                numbers = validateTwilioNumbers();
            } catch (e) {
                dispatch(enqueueSnackbar(e.message, { variant: 'error' }));
                return false;
            }
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        twilio: {
                            accountSID: values.accountSID,
                            number: values.number,
                            authToken: values.authToken,
                            numbers: Object.keys(numbers).length ? numbers : null
                        }
                    };
                }
                if (!Object.keys(settingsToUpdate).length) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);
                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [dispatch, locationId, onSettingsSaved, validateTwilioNumbers]
    );
    const handleSSOSubmit = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};
                if (locationId === undefined) {
                    settingsToUpdate = {
                        appleSSOEnabled: values.appleSSOEnabled,
                        googleSSOEnabled: values.googleSSOEnabled,
                        appleSSO: {
                            webClientId: values.appleSSO_webClientId
                        },
                        googleSSO: {
                            iosClientId: values.googleSSO_iosClientId,
                            webClientId: values.googleSSO_webClientId,
                            webAppClientId: values.googleSSO_webAppClientId
                        }
                    };
                }
                if (!Object.keys(settingsToUpdate).length) {
                    // No need to update anything
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);
                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [locationId, onSettingsSaved]
    );

    const handleSubmitAppVersions = React.useCallback(
        (appVersions: Tenant['appVersions']) => {
            const defaultedVersions = {
                ios: {
                    must: appVersions.ios.must || '0.0.0.0',
                    should: appVersions.ios.should || '0.0.0.0'
                },
                android: {
                    must: appVersions.android.must || '0.0.0.0',
                    should: appVersions.android.should || '0.0.0.0'
                }
            };
            return tenantApi.update(tenantId, { body: { appVersions: defaultedVersions } }).then(res => {
                if (res.ok) {
                    handleFetchTenant();

                    return true;
                }

                return false;
            });
        },
        [handleFetchTenant, tenantId]
    );

    const handleSubmitTenKites = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        tenKites: {
                            tenKitesOrgId: values.tenKitesOrgId,
                            menuId: values.menuId,
                            apiKey: values.apiKey,
                            nutritionUnit: values.nutritionUnit
                        },
                        menuSupplementProvider:
                            values.menuSupplementProvider === EMenuSupplementProvider.NONE
                                ? null
                                : values.menuSupplementProvider
                    };
                }
                if (!Object.keys(settingsToUpdate).length) {
                    // No need to update anything
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);
                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [locationId, onSettingsSaved]
    );

    const getAppUserAuthDisplayText = (setting: string | undefined): string => {
        switch (setting) {
            case 'PASSWORD':
                return EAppUserAuth.PASSWORD;
            case 'VERIFY_PASSWORD':
                return EAppUserAuth.VERIFY_PASSWORD;
            default:
                return EAppUserAuth.LEGACY;
        }
    };

    const getTenKitesNutritionLabel = (setting: string | undefined): string | undefined => {
        switch (setting) {
            case ETenKitesNutritionUnit.PER_HUNDRED:
                return 'Per 100g';
            case ETenKitesNutritionUnit.PER_SERVING:
                return 'Per Serving';
            case ETenKitesNutritionUnit.GDA_PERCENT:
                return '% of GDA';
            default:
                return setting;
        }
    };

    const getMenuSupplementProviderLabel = (setting?: string): string => {
        switch (setting) {
            case EMenuSupplementProvider.TENKITES:
                return 'Ten Kites';
            case EMenuSupplementProvider.NONE:
            default:
                return 'None';
        }
    };

    const userBarcodeFormatOptions = React.useMemo(
        () => [
            {
                label: userBarcodeFormatLabels[EUserBarcodeFormat.PDF417],
                value: EUserBarcodeFormat.PDF417
            },
            {
                label: userBarcodeFormatLabels[EUserBarcodeFormat.QR],
                value: EUserBarcodeFormat.QR
            }
        ],
        []
    );

    const renderGlobalEdit = React.useCallback(
        (values: FormikValues) => (
            <Box display="flex" flexDirection="column">
                <SettingDisplay label="Region" buildtimeIOS>
                    <SelectFormField name="region" options={regionsToDisplay} />
                </SettingDisplay>
                <SettingDisplay label="User Authentication">
                    <SelectFormField
                        name="appUserAuth"
                        options={[
                            { label: EAppUserAuth.LEGACY, value: 'LEGACY' },
                            { label: EAppUserAuth.PASSWORD, value: 'PASSWORD' },
                            { label: EAppUserAuth.VERIFY_PASSWORD, value: 'VERIFY_PASSWORD' }
                        ]}
                    />
                </SettingDisplay>
                <SettingDisplay label="Timezone">
                    <SelectFormField name="timezone" options={timezoneOptions} />
                </SettingDisplay>
                <SettingDisplay label="Locale">
                    <SelectFormField name="locale" options={localeOptions} />
                </SettingDisplay>
                <SettingDisplay label="Tax Label" buildtimeIOS>
                    <TextFormField name="taxLabel" />
                </SettingDisplay>
                <SettingDisplay label="Identity Providers">
                    <ChipInputFormField
                        name="identityProviders"
                        options={identityProvidersOptions}
                        multiple
                        isCreatable={false}
                    />
                </SettingDisplay>
                <SettingDisplay
                    label="Unique Device Verification"
                    description={'Should users only be able to sign up for a single account per device'}
                >
                    <SwitchFormField name="uniqueDeviceVerificationEnabled" />
                </SettingDisplay>
                {values.identityProviders.some((provider: Option) => provider.value === 'phone') && (
                    <SettingDisplay label="Supported Country Calling Codes on Registration">
                        <ChipInputFormField
                            name="supportedCountryCallingCodes"
                            options={supportedCountryCallingCodeOptions}
                            multiple
                            isCreatable={false}
                        />
                    </SettingDisplay>
                )}
                <SettingDisplay
                    label="Age Request Enabled"
                    description="Should the App ask the user for their age on sign up?"
                >
                    <SwitchFormField name="ageRequestEnabled" />
                </SettingDisplay>
                <SettingDisplay
                    label="Signup Minimum Age"
                    description="What is the minimum age, if enabled, to sign up to the App?"
                >
                    <NumberFormField name="registrationMinimumAge" />
                </SettingDisplay>
                <SettingDisplay
                    label="Ask for Marketing on Registration"
                    description="Should the App ask the user for marketing preferences on sign up?"
                >
                    <SwitchFormField name="registrationShowMarketingOptIn" />
                </SettingDisplay>
                <SettingDisplay label="Offline Checkin">
                    <SwitchFormField name="offlineCheckinEnabled" />
                </SettingDisplay>
                <SettingDisplay label="User Barcode Format">
                    <SelectFormField name="userBarcodeFormat" options={userBarcodeFormatOptions} />
                </SettingDisplay>
                <SettingDisplay label="Barcode Prefix">
                    <TextFormField name="userBarcodePrefix" />
                </SettingDisplay>
                <SettingDisplay label="Additional Credentials">
                    <SwitchFormField name="secondaryCredentialsEnabled" />
                </SettingDisplay>
                <SettingDisplay label="App Store Ratings & Reviews Enabled">
                    <SwitchFormField name="inAppReviewPromptsEnabled" />
                </SettingDisplay>
                <SettingDisplay label="Show Pepper Copyright">
                    <SwitchFormField name="showCopyright" />
                </SettingDisplay>
                <SettingDisplay label="About Markdown">
                    <FileInputFormField
                        name="aboutMD"
                        attributes={filePickerAttributes}
                        path={aboutMDSrc}
                        isLoading={aboutMdLoading}
                    />
                </SettingDisplay>
                <SettingDisplay label="FAQ Markdown">
                    <FileInputFormField
                        name="faqMD"
                        attributes={filePickerAttributes}
                        path={faqMDSrc}
                        isLoading={faqMdLoading}
                    />
                </SettingDisplay>
                <SettingDisplay label="Privacy Markdown">
                    <FileInputFormField
                        name="privacyMD"
                        attributes={filePickerAttributes}
                        path={privacyMDSrc}
                        isLoading={privacyMdLoading}
                    />
                </SettingDisplay>
                <SettingDisplay label="Terms Markdown">
                    <FileInputFormField
                        name="termsMD"
                        attributes={filePickerAttributes}
                        path={termsMDSrc}
                        isLoading={termsMdLoading}
                    />
                </SettingDisplay>
            </Box>
        ),
        [
            aboutMDSrc,
            aboutMdLoading,
            faqMDSrc,
            faqMdLoading,
            privacyMDSrc,
            privacyMdLoading,
            termsMDSrc,
            termsMdLoading,
            userBarcodeFormatOptions
        ]
    );

    const renderSupportedCountryCodes = React.useCallback(() => {
        if (!settings?.phoneAuthEnabled) {
            return null;
        }
        const supportedCodesToShow = (settings?.phoneAuth?.supportedCountryCallingCodes ?? []).slice(0, 7);
        const hiddenCodesCount =
            settings?.phoneAuth?.supportedCountryCallingCodes?.length - supportedCodesToShow.length;
        return (
            <SettingDisplay label="Supported Country Calling Codes on Registration">
                <Row valign="center" wrap="wrap" flex={1}>
                    {supportedCodesToShow.map(code => (
                        <Chip key={code} label={code} color="primary" className={classes.identityChip} />
                    ))}
                    {!!hiddenCodesCount && (
                        <Typography variant="body2" key="helper-text">
                            + {hiddenCodesCount} more
                        </Typography>
                    )}
                </Row>
            </SettingDisplay>
        );
    }, [settings?.phoneAuth?.supportedCountryCallingCodes, settings?.phoneAuthEnabled]);

    return (
        <Box minWidth="500px">
            {/* Cards for each settings block */}
            <StyledMuiCardForm
                title="General Settings"
                initialValues={{
                    region: settings?.region?.code,
                    appUserAuth: !isDefined(settings?.iam?.appUserAuth)
                        ? 'LEGACY'
                        : settings?.iam?.appUserAuth,
                    identityProviders: identityProvidersOptions.filter(identityProviderOption => {
                        if (identityProviderOption.value === 'phone' && settings?.phoneAuthEnabled) {
                            return true;
                        }
                        if (identityProviderOption.value === 'facebook' && settings?.facebookAuthEnabled) {
                            return true;
                        }
                        if (identityProviderOption.value === 'email' && settings?.emailAuthEnabled) {
                            return true;
                        }
                        return false;
                    }),
                    uniqueDeviceVerificationEnabled: settings?.uniqueDeviceVerificationEnabled ?? false,
                    supportedCountryCallingCodes: supportedCountryCallingCodeOptions.filter(code => {
                        const supported = settings?.phoneAuth?.supportedCountryCallingCodes ?? [
                            settings?.region?.phoneNumberCode
                        ];
                        return supported.includes(code.value);
                    }),
                    ageRequestEnabled: settings?.app?.ageRequestEnabled ?? false,
                    registrationMinimumAge: settings?.app?.registrationMinimumAge ?? '',
                    registrationShowMarketingOptIn: settings?.app?.registrationShowMarketingOptIn ?? true,
                    offlineCheckinEnabled: settings?.offlineCheckinEnabled ?? false,
                    userBarcodeFormat: settings?.app?.userBarcodeFormat ?? EUserBarcodeFormat.PDF417,
                    userBarcodePrefix: settings?.app?.userBarcodePrefix,
                    secondaryCredentialsEnabled: settings?.secondaryCredentialsEnabled ?? false,
                    aboutMD: undefined,
                    termsMD: undefined,
                    faqMD: undefined,
                    privacyMD: undefined,
                    timezone: settings?.region?.timezone,
                    locale: !isDefined(settings?.region?.locale) ? 'en-GB' : settings?.region?.locale,
                    taxLabel: settings?.region?.taxLabel,
                    inAppReviewPromptsEnabled: !!settings?.inAppReviewPromptsEnabled,
                    showCopyright: settings?.app?.showCopyright ?? true
                }}
                onSubmit={handleSubmitGeneral}
                childrenWhileDisplaying={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplayText buildtimeIOS title="Region" text={settings?.region?.code} />
                        <SettingDisplayText
                            title="User Authentication"
                            text={`${getAppUserAuthDisplayText(settings?.iam?.appUserAuth)}`}
                        />
                        <SettingDisplayText title="Timezone" text={settings?.region?.timezone} />
                        <SettingDisplayText
                            title="Locale"
                            text={!isDefined(settings?.region?.locale) ? 'en-GB' : settings.region.locale}
                        />
                        <SettingDisplayText
                            buildtimeIOS
                            title="Tax Label"
                            text={settings?.region?.taxLabel}
                        />
                        <SettingDisplay label="Identity Providers">
                            {settings?.emailAuthEnabled && (
                                <Chip label="Email" color="primary" className={classes.identityChip} />
                            )}
                            {settings?.phoneAuthEnabled && (
                                <Chip label="Phone" color="primary" className={classes.identityChip} />
                            )}
                            {settings?.facebookAuthEnabled && (
                                <Chip label="Facebook" color="primary" className={classes.identityChip} />
                            )}
                        </SettingDisplay>
                        <SettingDisplaySwitch
                            title="Unique Device Verification"
                            description="Should users only be able to sign up for a single account per device"
                            checked={settings?.uniqueDeviceVerificationEnabled}
                        />
                        {renderSupportedCountryCodes()}
                        <SettingDisplaySwitch
                            title="Age Request Enabled"
                            description="Should the App ask the user for their age on sign up?"
                            checked={settings?.app?.ageRequestEnabled}
                        />
                        <SettingDisplayText
                            title="Signup Minimum Age"
                            description="What is the minimum age, if enabled, to sign up to the App?"
                            text={settings?.app?.registrationMinimumAge ?? 'None'}
                        />
                        <SettingDisplaySwitch
                            title="Ask for Marketing on Registration"
                            description="Should the App ask the user for marketing preferences on sign up?"
                            checked={settings?.app?.registrationShowMarketingOptIn ?? true}
                        />
                        <SettingDisplaySwitch
                            title="Offline Checkin"
                            checked={settings?.offlineCheckinEnabled}
                        />
                        <SettingDisplay label="User Barcode Format">
                            <Chip
                                label={getUserBarcodeFormatLabel(settings?.app?.userBarcodeFormat)}
                                color="primary"
                            />
                        </SettingDisplay>
                        <SettingDisplayText title="Barcode Prefix" text={settings?.app?.userBarcodePrefix} />
                        <SettingDisplaySwitch
                            title="Additional Credentials"
                            checked={settings?.secondaryCredentialsEnabled}
                        />
                        <SettingDisplaySwitch
                            title="App Store Ratings & Reviews Enabled"
                            checked={!!settings?.inAppReviewPromptsEnabled}
                        />
                        <SettingDisplaySwitch
                            title="Show Pepper Copyright"
                            checked={settings?.app?.showCopyright ?? true}
                        />
                        <SettingDisplayFile
                            title="About Markdown"
                            noFileText="Click Edit to upload About Markdown"
                            url={getAboutUrl()}
                            fileName={aboutMDSrc}
                        />
                        <SettingDisplayFile
                            title="FAQ Markdown"
                            noFileText="Click Edit to change FAQ Markdown"
                            url={getFaqUrl()}
                            fileName={faqMDSrc}
                        />
                        <SettingDisplayFile
                            title="Privacy Markdown"
                            noFileText="Click Edit to change Privacy Markdown"
                            url={getPrivacyUrl()}
                            fileName={privacyMDSrc}
                        />
                        <SettingDisplayFile
                            title="Terms Markdown"
                            noFileText="Click Edit to change Terms Markdown"
                            url={getTermsUrl()}
                            fileName={termsMDSrc}
                        />
                    </Box>
                }
                childrenWhileEditing={renderGlobalEdit}
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />
            <StyledMuiCardForm
                title="Web, iOS & Android"
                initialValues={{
                    displayName: settings?.app?.displayName,
                    bundleIdentifier: settings?.app?.bundleIdentifier,
                    bundleDisplayName: settings?.app?.bundleDisplayName,
                    bundleName: settings?.app?.bundleName,
                    appURLScheme: settings?.app?.appURLScheme,
                    appStoreUrl: settings?.app?.appStoreUrl,
                    appleKeyId: settings?.appleDeviceCheck?.keyId,
                    appleTeamId: settings?.appleDeviceCheck?.teamId,
                    appleKeyFile: settings?.appleDeviceCheck?.keyAsBase64,
                    googleMapsApiKey: settings?.app?.googleMapsApiKey,
                    playStoreUrl: settings?.app?.playStoreUrl,
                    domainName: settings?.web?.domainName,
                    environment: settings?.app?.environment,
                    androidFirebase: undefined,
                    iOSFirebase: undefined,
                    firebaseCredentialsFile: settings?.firebase?.credentialsAsBase64,
                    smartlookEnabled: settings?.smartlookEnabled ?? false
                }}
                onSubmit={handleSubmitAndroidIos}
                childrenWhileDisplaying={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplayText
                            title="App Environment"
                            buildtimeAndroid
                            buildtimeIOS
                            text={settings?.app?.environment ?? <>&mdash;</>}
                        />
                        <SettingDisplayText title="Web App Domain" text={settings?.web?.domainName} />
                        <SettingDisplayText
                            title="App Display Name"
                            buildtimeAndroid
                            buildtimeIOS
                            text={settings?.app?.displayName}
                        />
                        <SettingDisplayText
                            title="Bundle Identifier"
                            buildtimeAndroid
                            buildtimeIOS
                            text={settings?.app?.bundleIdentifier}
                        />
                        <SettingDisplayText
                            title="Bundle Display Name"
                            buildtimeAndroid
                            buildtimeIOS
                            text={settings?.app?.bundleDisplayName}
                        />
                        <SettingDisplayText
                            title="Bundle Name"
                            buildtimeAndroid
                            buildtimeIOS
                            text={settings?.app?.bundleName}
                        />
                        <SettingDisplayText
                            title="App URL Scheme"
                            buildtimeAndroid
                            buildtimeIOS
                            text={settings?.app?.appURLScheme}
                        />
                        <SettingDisplayText title="App Store URL" text={settings?.app?.appStoreUrl} />
                        <SettingDisplayText
                            title="Apple API Auth Key ID"
                            text={settings?.appleDeviceCheck?.keyId}
                        />
                        <SettingDisplayText
                            title="Apple Team ID"
                            buildtimeIOS
                            text={settings?.appleDeviceCheck?.teamId}
                        />

                        <SettingDisplayText
                            title="Apple Key File"
                            text={settings?.appleDeviceCheck?.keyAsBase64}
                            crop
                        />
                        <SettingDisplayText
                            title="Google Maps API Key"
                            buildtimeAndroid
                            text={settings?.app?.googleMapsApiKey}
                        />
                        <SettingDisplayText title="Play Store URL" text={settings?.app?.playStoreUrl} />
                        <SettingDisplayText
                            title="Android Firebase Config File"
                            buildtimeAndroid
                            text={
                                androidFirebase
                                    ? androidFirebase
                                    : 'Click Edit to upload Android Firebase Config File'
                            }
                        />
                        <SettingDisplayText
                            title="IOS Firebase Config File"
                            buildtimeIOS
                            text={iOSFirebase ? iOSFirebase : 'Click Edit to upload IOS Firebase Config File'}
                        />
                        <SettingDisplayText
                            title="Firebase Credentials File"
                            text={settings?.firebase?.credentialsAsBase64}
                            crop
                        />
                        <SettingDisplaySwitch
                            title="Smartlook Enabled"
                            checked={settings?.smartlookEnabled}
                        />
                    </Box>
                }
                childrenWhileEditing={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="App Environment" buildtimeAndroid buildtimeIOS>
                            <SelectFormField options={appEnvironmentOptions} name="environment" />
                        </SettingDisplay>
                        <SettingDisplay label="Web App Domain">
                            <TextFormField name="domainName" />
                        </SettingDisplay>
                        <SettingDisplay label="App Display Name" buildtimeAndroid buildtimeIOS>
                            <TextFormField name="displayName" />
                        </SettingDisplay>
                        <SettingDisplay label="Bundle Identifier" buildtimeAndroid buildtimeIOS>
                            <TextFormField name="bundleIdentifier" />
                        </SettingDisplay>
                        <SettingDisplay label="Bundle Display Name" buildtimeAndroid buildtimeIOS>
                            <TextFormField name="bundleDisplayName" />
                        </SettingDisplay>
                        <SettingDisplay label="Bundle Name" buildtimeAndroid buildtimeIOS>
                            <TextFormField name="bundleName" />
                        </SettingDisplay>
                        <SettingDisplay label="App URL Scheme" buildtimeAndroid buildtimeIOS>
                            <TextFormField name="appURLScheme" />
                        </SettingDisplay>
                        <SettingDisplay label="App Store URL">
                            <TextFormField name="appStoreUrl" />
                        </SettingDisplay>
                        <SettingDisplay label="Apple API Auth Key ID">
                            <TextFormField name="appleKeyId" />
                        </SettingDisplay>
                        <SettingDisplay label="Apple Team ID" buildtimeIOS>
                            <TextFormField name="appleTeamId" />
                        </SettingDisplay>
                        <SettingDisplay label="Apple Key File">
                            <KeyFileInputFormField name="appleKeyFile" />
                        </SettingDisplay>
                        <SettingDisplay label="Google Maps API Key" buildtimeAndroid>
                            <TextFormField name="googleMapsApiKey" />
                        </SettingDisplay>
                        <SettingDisplay label="Play Store URL">
                            <TextFormField name="playStoreUrl" />
                        </SettingDisplay>
                        <SettingDisplay label="Android Firebase Config File" buildtimeAndroid>
                            <FileInputFormField
                                name="androidFirebase"
                                attributes={filePickerAndroidAttributes}
                                path={androidFirebase}
                                isLoading={androidFirebaseLoading}
                            />
                        </SettingDisplay>
                        <SettingDisplay label="IOS Firebase Config File" buildtimeIOS>
                            <FileInputFormField
                                name="iOSFirebase"
                                attributes={filePickeriOSAttributes}
                                path={iOSFirebase}
                                isLoading={iOSFirebaseLoading}
                            />
                        </SettingDisplay>
                        <SettingDisplay label="Firebase Credentials File">
                            <KeyFileInputFormField
                                name="firebaseCredentialsFile"
                                attributes={filePickerFirebaseAttributes}
                            />
                        </SettingDisplay>
                        <SettingDisplay label="Smartlook Enabled">
                            <SwitchFormField name="smartlookEnabled" />
                        </SettingDisplay>
                    </Box>
                }
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />
            <StyledMuiCardForm
                title="Twilio"
                initialValues={{
                    accountSID: settings?.twilio?.accountSID,
                    number: settings?.twilio?.number,
                    authToken: settings?.twilio?.authToken
                }}
                childrenWhileDisplaying={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplayText
                            title="Account SID Identifier"
                            text={settings?.twilio?.accountSID}
                        />
                        <SettingDisplayText title="Number" text={settings?.twilio?.number} />
                        <SettingDisplayText title="Auth Token" text={settings?.twilio?.authToken} />
                        <SettingDisplayText
                            title="Localised Numbers"
                            text={`${Object.keys(settings?.twilio?.numbers ?? {}).length} numbers`}
                        />
                    </Box>
                }
                childrenWhileEditing={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="Account SID">
                            <TextFormField name="accountSID" />
                        </SettingDisplay>
                        <SettingDisplay label="Number">
                            <TextFormField name="number" />
                        </SettingDisplay>
                        <SettingDisplay label="Auth Token">
                            <TextFormField name="authToken" />
                        </SettingDisplay>
                        <SettingDisplay label="Localised Numbers">
                            <KeyValueSettings
                                value={twilioNumbers}
                                keyPlaceholder="Phone Number"
                                valuePlaceholder="Valid Countries (e.g. GB, US)"
                                onChange={onTwilioNumbersChange}
                            />
                        </SettingDisplay>
                    </Box>
                }
                onSubmit={handleSubmitTwilio}
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />

            <StyledMuiCardForm
                title="Apple & Google SSO"
                initialValues={{
                    appleSSOEnabled: !!settings?.appleSSOEnabled,
                    appleSSO_webClientId: settings?.appleSSO?.webClientId,
                    googleSSOEnabled: !!settings?.googleSSOEnabled,
                    googleSSO_iosClientId: settings?.googleSSO?.iosClientId,
                    googleSSO_webClientId: settings?.googleSSO?.webClientId,
                    googleSSO_webAppClientId: settings?.googleSSO?.webAppClientId
                }}
                childrenWhileDisplaying={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplaySwitch
                            title="Apple SSO Enabled"
                            checked={!!settings?.appleSSOEnabled}
                        />
                        <SettingDisplayText
                            title="Apple SSO Web Client ID"
                            text={settings?.appleSSO?.webClientId}
                        />
                        <SettingDisplaySwitch
                            title="Google SSO Enabled"
                            checked={!!settings?.googleSSOEnabled}
                        />
                        <SettingDisplayText
                            title="Google SSO iOS Client ID"
                            text={settings?.googleSSO?.iosClientId}
                            buildtimeIOS
                        />
                        <SettingDisplayText
                            title="Google SSO Web Client ID"
                            text={settings?.googleSSO?.webClientId}
                        />
                        <SettingDisplayText
                            title="Google SSO Web App Client ID"
                            text={settings?.googleSSO?.webAppClientId}
                        />
                    </Box>
                }
                childrenWhileEditing={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="Apple SSO Enabled">
                            <SwitchFormField name="appleSSOEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Apple SSO Web Client ID">
                            <TextFormField name="appleSSO_webClientId" />
                        </SettingDisplay>
                        <SettingDisplay label="Google SSO Enabled">
                            <SwitchFormField name="googleSSOEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Google SSO iOS Client ID">
                            <TextFormField name="googleSSO_iosClientId" />
                        </SettingDisplay>
                        <SettingDisplay label="Google SSO Web Client ID">
                            <TextFormField name="googleSSO_webClientId" />
                        </SettingDisplay>
                        <SettingDisplay label="Google SSO Web App Client ID">
                            <TextFormField name="googleSSO_webAppClientId" />
                        </SettingDisplay>
                    </Box>
                }
                onSubmit={handleSSOSubmit}
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />
            <StyledMuiCardForm
                title="App Version"
                initialValues={{
                    android: {
                        must: tenant?.appVersions.android.must,
                        should: tenant?.appVersions.android.should
                    },
                    ios: {
                        must: tenant?.appVersions.ios.must,
                        should: tenant?.appVersions.ios.should
                    }
                }}
                childrenWhileDisplaying={
                    <>
                        <Box display="flex" flexDirection="column">
                            <Typography fontWeight={700}>IOS</Typography>
                            <Box marginTop={1}>
                                <SettingDisplayText title="Must Update" text={tenant?.appVersions.ios.must} />
                                <SettingDisplayText
                                    title="Should Update"
                                    text={tenant?.appVersions.ios.should}
                                />
                            </Box>
                        </Box>
                        <Box marginTop={2} display="flex" flexDirection="column">
                            <Typography fontWeight={700}>Android</Typography>
                            <Box marginTop={1}>
                                <SettingDisplayText
                                    title="Must Update"
                                    text={tenant?.appVersions.android.must}
                                />
                                <SettingDisplayText
                                    title="Should Update"
                                    text={tenant?.appVersions.android.should}
                                />
                            </Box>
                        </Box>
                    </>
                }
                childrenWhileEditing={
                    <>
                        <Box display="flex" flexDirection="column">
                            <Typography fontWeight={700}>iOS</Typography>
                            <Box marginTop={1}>
                                <SettingDisplay
                                    label="Must Update"
                                    description="The user will be logged out and prompted to update their app to the latest version. They will not be able to use the app until they have updated."
                                >
                                    <TextFormField placeholder="e.g. 5.0.0" name="ios.must" />
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Should Update"
                                    description="The user will be prompted to update their app. They can continue to use the app without updating to the latest version."
                                >
                                    <TextFormField placeholder="e.g. 5.0.0" name="ios.should" />
                                </SettingDisplay>
                            </Box>
                        </Box>
                        <Box marginTop={2} display="flex" flexDirection="column">
                            <Typography fontWeight={700}>Android</Typography>
                            <Box marginTop={1}>
                                <SettingDisplay
                                    label="Must Update"
                                    description="The user will be logged out and prompted to update their app to the latest version. They will not be able to use the app until they have updated."
                                >
                                    <TextFormField placeholder="e.g. 0.0.0.1130" name="android.must" />
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Should Update"
                                    description="The user will be prompted to update their app. They can continue to use the app without updating to the latest version."
                                >
                                    <TextFormField placeholder="e.g. 0.0.0.1130" name="android.should" />
                                </SettingDisplay>
                            </Box>
                        </Box>
                    </>
                }
                validationSchema={appVersionValidation}
                onSubmit={handleSubmitAppVersions}
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />
            <StyledMuiCardForm
                title="Menu Supplement Provider"
                initialValues={{
                    menuSupplementProvider: settings?.menuSupplementProvider ?? 'None',
                    tenKitesOrgId: settings?.tenKites?.tenKitesOrgId,
                    apiKey: settings?.tenKites?.apiKey,
                    menuId: settings?.tenKites?.menuId,
                    nutritionUnit: settings?.tenKites?.nutritionUnit
                }}
                childrenWhileDisplaying={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplayText
                            title="Provider"
                            text={getMenuSupplementProviderLabel(settings?.menuSupplementProvider)}
                        />
                        {settings?.menuSupplementProvider === EMenuSupplementProvider.TENKITES && (
                            <>
                                <SettingDisplayText
                                    title="Ten Kites Organisation ID"
                                    text={settings?.tenKites?.tenKitesOrgId}
                                />
                                <SettingDisplayText title="Menu ID" text={settings?.tenKites?.menuId} />
                                <SettingDisplayText title="API Key" text={settings?.tenKites?.apiKey} />
                                <SettingDisplayText
                                    title="Nutrition Unit"
                                    text={getTenKitesNutritionLabel(settings?.tenKites?.nutritionUnit)}
                                />
                            </>
                        )}
                    </Box>
                }
                childrenWhileEditing={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="Provider">
                            <SelectFormField
                                name="menuSupplementProvider"
                                options={[
                                    { label: 'Ten Kites', value: EMenuSupplementProvider.TENKITES },
                                    { label: 'None', value: EMenuSupplementProvider.NONE }
                                ]}
                            />
                        </SettingDisplay>
                        {settings?.menuSupplementProvider === EMenuSupplementProvider.TENKITES && (
                            <>
                                <SettingDisplay label="Ten Kites Organisation ID">
                                    <TextFormField name="tenKitesOrgId" />
                                </SettingDisplay>
                                <SettingDisplay label="Menu ID">
                                    <TextFormField name="menuId" />
                                </SettingDisplay>
                                <SettingDisplay label="API Key">
                                    <TextFormField name="apiKey" />
                                </SettingDisplay>
                                <SettingDisplay label="Nutrition Unit">
                                    <SelectFormField
                                        name="nutritionUnit"
                                        options={[
                                            {
                                                label: 'Per Serving',
                                                value: ETenKitesNutritionUnit.PER_SERVING
                                            },
                                            { label: 'Per 100g', value: ETenKitesNutritionUnit.PER_HUNDRED },
                                            { label: '% of GDA', value: ETenKitesNutritionUnit.GDA_PERCENT }
                                        ]}
                                    />
                                </SettingDisplay>
                            </>
                        )}
                    </Box>
                }
                onSubmit={handleSubmitTenKites}
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />
        </Box>
    );
};
