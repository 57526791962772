// function(state: CampaignsState = initialState, action: ICampaignsAction): CampaignsState {

import { Perk } from 'components/loyalty/models/PerkModel';
import { removeArrayItem, updateArrayItem } from 'lib/helpers';
import { IPerksAction } from './perksActions';
import { PerksActionType } from './perksActionTypes';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

export interface PerksState {
    perks: Perk[];
    codeSpecificEntries: Record<string, string>;
    loading: boolean;
    createModalOpen: boolean;
    closeCreateModalOpen: boolean;
    closeUpdateModalOpen: boolean;
    stepClub: boolean;
    indexToReset: number;
    indexToDelete: number;
    indexOpen: number;
    indexToDuplicate: number;
    resetModalLoading: boolean;
    deleteModalLoading: boolean;
    perkIdToTitle: Map<string, string>;
    perkAwardCount: number;
    selectedRow: GridRowSelectionModel;
}

export const initialState: PerksState = {
    perks: [],
    codeSpecificEntries: {},
    loading: false,
    createModalOpen: false,
    closeUpdateModalOpen: false,
    closeCreateModalOpen: false,
    stepClub: false,
    indexToReset: -1,
    indexToDelete: -1,
    indexOpen: -1,
    indexToDuplicate: -1,
    resetModalLoading: false,
    deleteModalLoading: false,
    perkIdToTitle: new Map<string, string>(),
    perkAwardCount: -1,
    selectedRow: []
};

export default function (state: PerksState = initialState, action: IPerksAction): PerksState {
    switch (action.type) {
        case PerksActionType.START_REQUEST:
            return { ...state, loading: true };
        case PerksActionType.LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                perks: action.perks ? action.perks : []
            };
        case PerksActionType.LOAD_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                perkIdToTitle: action.perkIdToTitle,
                codeSpecificEntries: action.codeSpecificEntries,
                perks: action.perks ? action.perks : []
            };
        case PerksActionType.LOAD_ERROR:
            return { ...state, loading: false, perks: [] };
        case PerksActionType.CREATE_SUCCESS: {
            const dataToMerge: Partial<PerksState> = {
                closeCreateModalOpen: false,
                createModalOpen: false,
                perks: state.perks,
                loading: false,
                indexToDuplicate: -1
            };

            dataToMerge.perks = [...state.perks, action.perk];

            return { ...state, ...dataToMerge };
        }
        case PerksActionType.CREATE_SUCCESS_CLUB: {
            const dataToMerge: Partial<PerksState> = {
                closeCreateModalOpen: false,
                createModalOpen: true,
                perks: state.perks,
                stepClub: true,
                indexToDuplicate: -1
            };

            dataToMerge.perks = [...state.perks, action.perk];

            return { ...state, ...dataToMerge };
        }
        case PerksActionType.TOGGLE_CREATE_MODAL:
            return { ...state, createModalOpen: !state.createModalOpen };
        case PerksActionType.TOGGLE_CLOSE_CREATE_MODAL:
            return { ...state, closeCreateModalOpen: !state.closeCreateModalOpen };
        case PerksActionType.TOGGLE_CLOSE_UPDATE_MODAL:
            return { ...state, closeUpdateModalOpen: !state.closeUpdateModalOpen };
        case PerksActionType.CLOSE_MODALS:
            return {
                ...state,
                closeCreateModalOpen: false,
                createModalOpen: false,
                closeUpdateModalOpen: false,
                indexOpen: -1,
                indexToDuplicate: -1,
                stepClub: false
            };
        case PerksActionType.START_DELETE_REQUEST:
            return { ...state, deleteModalLoading: true };
        case PerksActionType.SET_ITEM_TO_RESET:
            return { ...state, indexToReset: action.index };
        case PerksActionType.SET_PERK_AWARD_COUNT:
            return { ...state, perkAwardCount: action.count };
        case PerksActionType.SET_ITEM_TO_DELETE:
            return { ...state, indexToDelete: action.index };
        case PerksActionType.RESET_SUCCESS:
            return { ...state, indexToReset: -1, resetModalLoading: false, selectedRow: [] };
        case PerksActionType.RESET_ERROR:
            return { ...state, indexToReset: -1, resetModalLoading: false };
        case PerksActionType.DELETE_ERROR:
            return { ...state, indexToDelete: -1, deleteModalLoading: false };
        case PerksActionType.DELETE_SUCCESS:
            return {
                ...state,
                indexToDelete: -1,
                deleteModalLoading: false,
                perks: removeArrayItem(state.perks, state.indexToDelete)
            };
        case PerksActionType.SET_OPEN_ITEM:
            return { ...state, indexOpen: action.index };
        case PerksActionType.SET_ITEM_TO_DUPLICATE:
            return { ...state, indexToDuplicate: action.index };
        case PerksActionType.UPDATE_SUCCESS:
            return {
                ...state,
                indexOpen: -1,
                perks: updateArrayItem(state.perks, state.indexOpen, action.perk),
                selectedRow: []
            };
        case PerksActionType.SET_SELECTED_ROW:
            return {
                ...state,
                selectedRow: action.selectedRow.at(-1) === state.selectedRow.at(-1) ? [] : action.selectedRow
            };
        default:
            return state;
    }
}
