import {
    isArrayOf,
    isOptionalString,
    isOptional,
    isString,
    isBoolean,
    isNumber,
    isEnumOfType
} from 'lib/typeguards';
import { IBasketQualification, IPerkDateTimeRestriction, PerkDiscount } from '../loyalty/models/PerkModel';

export enum AdjustmentSchemeType {
    COUPON = 'COUPON'
}

export interface AdjustmentScheme {
    id: string;
    _id: string;
    tenantId: string;
    title: string;
    internalTitle?: string;
    schemeId: string;
    schemeType: AdjustmentSchemeType;
    externalPosId?: string;
    dateTimeRestriction?: IPerkDateTimeRestriction;
    basketQualification?: IBasketQualification & {
        minimumValue?: number;
    };
    discount: PerkDiscount;
    locationIds?: string[];
    enabled?: boolean;
}

export type AdjustmentSchemeDateTimeRestriction = Partial<
    Omit<IPerkDateTimeRestriction, 'startDate' | 'endDate'>
> & {
    startDate?: string;
    endDate?: string;
};

interface AdjustmentSchemeData {
    items: AdjustmentScheme[];
    page: {
        nextKey?: string;
        startKey?: string;
        count: number;
        limit?: number;
    };
}

export type AdjustmentSchemeMutationBody = Omit<
    AdjustmentScheme,
    '_id' | 'id' | 'tenantId' | 'schemeId' | 'dateTimeRestriction'
> & {
    dateTimeRestriction?: AdjustmentSchemeDateTimeRestriction;
};

export function isAdjustmentScheme(input: any): input is AdjustmentScheme {
    return (
        isString(input._id) &&
        isString(input.tenantId) &&
        isString(input.title) &&
        isOptionalString(input.internalTitle) &&
        isString(input.schemeId) &&
        isEnumOfType(AdjustmentSchemeType, input.schemeType) &&
        isNumber(input.discount.value) &&
        (input.discount.type === 'VALUE' ||
            input.discount.type === 'PERCENTAGE' ||
            input.discount.type === 'OVERRIDE') &&
        isOptional(isNumber, input.discount.maxValue) &&
        (input.discount.scope === 'ITEM' ||
            input.discount.scope === 'CATEGORY' ||
            input.discount.scope === 'BASKET' ||
            input.discount.scope === 'ITEM_OR_CATEGORY' ||
            input.discount.scope === 'CHILD_ITEM' ||
            input.discount.scope === 'ITEM_AND_CHILD_ITEM') &&
        (input.discount.rule === 'CHEAPEST' ||
            input.discount.rule === 'COSTLIEST' ||
            input.discount.rule === 'ALL' ||
            input.discount.rule === 'CHEAPEST_FIRST' ||
            input.discount.rule === 'COSTLIEST_FIRST' ||
            input.discount.rule === 'ALL_ALLOCATE') &&
        (input.discount.type === 'OVERRIDE' ||
            input.discount.childItemRule === 'EXCLUDE' ||
            input.discount.childItemRule === 'INCLUDE_MATCHED') &&
        isOptional(isBoolean, input.enabled) &&
        isOptionalString(input.externalPosId)
    );
}

export function isAdjustmentSchemeData(input: any): input is AdjustmentSchemeData {
    return (
        isArrayOf(isAdjustmentScheme, input.items) &&
        !!input.page &&
        isOptionalString(input.page.nextKey) &&
        isOptionalString(input.page.startKey) &&
        isNumber(input.page.count) &&
        isOptional(isNumber, input.page.limit)
    );
}
