import React from 'react';
import { Box, Divider, List, ListItem, Typography } from '@mui/material';
import { gridColumns } from './PerksGrid';

interface IPerksSearchInfoTooltip {
    excludedColumns: string[];
}

export const SEARCHABLE_FIELDS = [
    '_id',
    'loyaltyRewardId',
    'title',
    'internalTitle',
    'template',
    'description',
    'terms',
    'token',
    'locations'
];

export const PerksSearchInfoTooltip: React.FC<IPerksSearchInfoTooltip> = ({ excludedColumns }) => {
    const fields = gridColumns
        .filter(({ field }) => SEARCHABLE_FIELDS.includes(field) && !excludedColumns.includes(field))
        .map(({ headerName }) => headerName);

    return (
        <Box padding={1.5}>
            <Typography>Searchable Fields</Typography>
            <Divider sx={{ my: 1 }} />
            <List sx={{ listStyleType: 'disc', py: 0, pl: 2.5 }}>
                {fields.map(field => (
                    <ListItem key={field} sx={{ display: 'list-item', p: 0.25, pr: 1 }}>
                        <Typography variant="body2">{field}</Typography>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};
