import React from 'react';
import { Location } from 'components/location/models/LocationModel';
import { locationApi } from 'components/location/LocationApi';
import { CustomerSegment } from 'components/customers/models/Customer';
import { segmentsApi } from 'components/audience/segmentsApi';
import { ContentModule } from 'components/content/models/ContentModule';
import { contentApi } from 'components/content/contentApi';
import logger from 'lib/logger';
import scheduledTasksReducer from 'components/scheduled-tasks/reducers/scheduledTasksReducer';
import { MainLayout } from 'layouts/MainLayout';
import { ScheduledTasksTable } from 'components/scheduled-tasks/tables/ScheduledTasksTable';

import { ScheduledTask } from 'components/scheduled-tasks/scheduledTasks.types';
import { CreateScheduledTaskNotificationModal } from 'components/scheduled-tasks/notification/modals/CreateScheduledTaskNotificationModal';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_CAMPAIGNS_SCHEDULED_NOTIFICATION_CREATE_ERROR,
    MESSAGE_CAMPAIGNS_SCHEDULED_NOTIFICATION_CREATE_IN_PROGRESS,
    MESSAGE_CAMPAIGNS_SCHEDULED_NOTIFICATION_DELETE_ERROR,
    MESSAGE_CAMPAIGNS_SCHEDULED_NOTIFICATION_DELETE_SUCCESS,
    MESSAGE_CAMPAIGNS_SCHEDULED_NOTIFICATION_UPDATE_ERROR,
    MESSAGE_CAMPAIGNS_SCHEDULED_NOTIFICATION_UPDATE_IN_PROGRESS
} from 'config/messages';
import { UpdateScheduledTaskNotificationModal } from 'components/scheduled-tasks/notification/modals/UpdateScheduledTaskNotificationModal';
import { scheduledTasksApi } from 'components/scheduled-tasks/scheduledTasksApi';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';

const { reducer, initialState, ActionType } = scheduledTasksReducer;

async function getLocations(): Promise<Location[]> {
    const result = await locationApi.getList();
    if (!result.ok) {
        return [];
    }
    return result.body.locations;
}

async function getSegments(): Promise<CustomerSegment[]> {
    try {
        return segmentsApi.getAll();
    } catch (err) {
        return [];
    }
}

async function getModules(): Promise<ContentModule[]> {
    const { ok, body: modules } = await contentApi.modules.getList();
    if (!ok) {
        return [];
    }
    return modules;
}

export const ScheduledCampaignsPage = () => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const reduxDispatch = useDispatch();

    const {
        isLoading,
        locations,
        segments,
        modules,
        selectedScheduledTask,
        actionedScheduledTask,
        createScheduledTaskModalOpen,
        deleteScheduledTaskModalOpen,
        updateScheduledTaskModalOpen
    } = state;

    React.useEffect(() => {
        async function load() {
            try {
                dispatch({ type: ActionType.START_REQUEST });
                const [locations, segments, modules] = await Promise.all([
                    getLocations(),
                    getSegments(),
                    getModules()
                ]);
                dispatch({ type: ActionType.LOAD_SUCCESS, locations, segments, modules });
            } catch (error) {
                logger.error('Failed to load scheduled tasks', error);
                dispatch({ type: ActionType.LOAD_ERROR });
            }
        }
        load();
    }, []);

    const handleCreateClose = React.useCallback(() => {
        dispatch({ isOpen: false, type: ActionType.MODAL_ACTION_CREATE });
    }, [dispatch]);
    const handleCreateError = React.useCallback(
        (errorMessage: string) => {
            dispatch({ isOpen: false, type: ActionType.MODAL_ACTION_CREATE });
            reduxDispatch(
                enqueueSnackbar(
                    MESSAGE_CAMPAIGNS_SCHEDULED_NOTIFICATION_CREATE_ERROR.replace(
                        '{{errorMessage}}',
                        errorMessage
                    ),
                    {
                        variant: 'error'
                    }
                )
            );
        },
        [dispatch, reduxDispatch]
    );
    const handleCreateSuccess = React.useCallback(
        (scheduledTask: ScheduledTask<any>) => {
            dispatch({ type: ActionType.CREATE_SUCCESS, scheduledTask });
            reduxDispatch(
                enqueueSnackbar(MESSAGE_CAMPAIGNS_SCHEDULED_NOTIFICATION_CREATE_IN_PROGRESS, {
                    variant: 'info'
                })
            );
        },
        [reduxDispatch]
    );
    const handleCreateClick = React.useCallback(
        (scheduledTask?: ScheduledTask<any>) =>
            dispatch({
                isOpen: true,
                type: ActionType.MODAL_ACTION_CREATE,
                selectedScheduledTask: scheduledTask
            }),
        [dispatch]
    );
    const handleUpdateClose = React.useCallback(() => {
        dispatch({ isOpen: false, type: ActionType.MODAL_ACTION_UPDATE });
    }, [dispatch]);
    const handleUpdateError = React.useCallback(
        (errorMessage: string) => {
            dispatch({ isOpen: false, type: ActionType.MODAL_ACTION_UPDATE });
            reduxDispatch(
                enqueueSnackbar(
                    MESSAGE_CAMPAIGNS_SCHEDULED_NOTIFICATION_UPDATE_ERROR.replace(
                        '{{errorMessage}}',
                        errorMessage
                    ),
                    {
                        variant: 'error'
                    }
                )
            );
        },
        [dispatch, reduxDispatch]
    );
    const handleUpdateSuccess = React.useCallback(
        (scheduledTask: ScheduledTask<any>) => {
            dispatch({ type: ActionType.UPDATE_SUCCESS, scheduledTask });
            reduxDispatch(
                enqueueSnackbar(MESSAGE_CAMPAIGNS_SCHEDULED_NOTIFICATION_UPDATE_IN_PROGRESS, {
                    variant: 'info'
                })
            );
        },
        [reduxDispatch]
    );
    const handleUpdateClick = React.useCallback(
        (scheduledTask?: ScheduledTask<any>) =>
            dispatch({
                isOpen: true,
                type: ActionType.MODAL_ACTION_UPDATE,
                selectedScheduledTask: scheduledTask
            }),
        [dispatch]
    );
    const handleDeleteClose = React.useCallback(() => {
        dispatch({ isOpen: false, type: ActionType.MODAL_ACTION_DELETE });
    }, [dispatch]);
    const handleDeleteConfirm = React.useCallback(async () => {
        try {
            const { ok } = await scheduledTasksApi.delete(selectedScheduledTask._id, {
                skipResponseBody: true
            });
            if (!ok) {
                throw new Error('Failed to delete scheduled task');
            }
            dispatch({ type: ActionType.DELETE_SUCCESS, scheduledTask: selectedScheduledTask });
            reduxDispatch(
                enqueueSnackbar(MESSAGE_CAMPAIGNS_SCHEDULED_NOTIFICATION_DELETE_SUCCESS, {
                    variant: 'success'
                })
            );
        } catch (error) {
            logger.error('Failed to load scheduled tasks', error);
            dispatch({ isOpen: false, type: ActionType.MODAL_ACTION_DELETE });
            reduxDispatch(
                enqueueSnackbar(
                    MESSAGE_CAMPAIGNS_SCHEDULED_NOTIFICATION_DELETE_ERROR.replace(
                        '{{errorMessage}}',
                        error.message
                    ),
                    {
                        variant: 'error'
                    }
                )
            );
        }
    }, [reduxDispatch, selectedScheduledTask]);
    const handleDeleteClick = React.useCallback(
        (scheduledTask?: ScheduledTask<any>) =>
            dispatch({
                isOpen: true,
                type: ActionType.MODAL_ACTION_DELETE,
                selectedScheduledTask: scheduledTask
            }),
        [dispatch]
    );
    const segmentOptions = React.useMemo(
        () =>
            segments.map((segment: CustomerSegment) => ({
                label: segment.title,
                value: segment._id
            })),
        [segments]
    );
    const locationOptions = React.useMemo(
        () =>
            locations.map((segment: Location) => ({
                label: segment.title,
                value: segment._id
            })),
        [locations]
    );

    return (
        <MainLayout
            pageName="Scheduled Campaigns"
            pageDescription="View and manage your scheduled campaigns"
            noScroll
        >
            <ScheduledTasksTable
                isLoading={isLoading}
                onCreateClick={handleCreateClick}
                onUpdateClick={handleUpdateClick}
                onDeleteClick={handleDeleteClick}
                actionedScheduledTask={actionedScheduledTask}
                segments={segments}
                locations={locations}
            />
            <CreateScheduledTaskNotificationModal
                open={createScheduledTaskModalOpen}
                onClose={handleCreateClose}
                onSuccess={handleCreateSuccess}
                onError={handleCreateError}
                scheduledTask={selectedScheduledTask}
                segments={segmentOptions}
                locations={locationOptions}
                modules={modules}
            />
            <UpdateScheduledTaskNotificationModal
                open={updateScheduledTaskModalOpen}
                onClose={handleUpdateClose}
                onSuccess={handleUpdateSuccess}
                onError={handleUpdateError}
                scheduledTask={selectedScheduledTask}
                segments={segmentOptions}
                locations={locationOptions}
                modules={modules}
            />
            <DeleteDialog
                open={deleteScheduledTaskModalOpen}
                title="Delete push notification"
                content="If you delete this push notification, you will not be able to recover when deleted.
                    To confirm deletion, type 'DELETE' in the text input field."
                onClose={handleDeleteClose}
                onDelete={handleDeleteConfirm}
                loading={isLoading}
                protectionWord="DELETE"
            />
        </MainLayout>
    );
};
