const channel = new BroadcastChannel('tab');

const listenToOtherTabs = (msg: any) => {
    if (msg.data === 'merchant-access') {
        // message received from 2nd tab
        // eslint-disable-next-line no-alert
        alert('The session was changed in another tab.');
        window.location.reload();
    }
};

export function removeTabMerchantChangeListener() {
    channel.removeEventListener('message', listenToOtherTabs);
}
export function addTabMerchantChangeListener() {
    channel.addEventListener('message', listenToOtherTabs);
}
export function notifyOtherTabsMerchantChange() {
    removeTabMerchantChangeListener();
    channel.postMessage('merchant-access');
    addTabMerchantChangeListener();
}
