import React from 'react';
import { useLocalDateTimeFormat } from 'lib/hooks/useLocalDateTimeFormat';
import { DatePicker } from '@mui/x-date-pickers-pro';

interface IProps {
    onChange: (value?: string) => void;
    value?: string;
    error?: string;
}

export const AvailabilityDateFilter: React.FC<IProps> = ({ value, error, onChange }) => {
    const { getUserDateFormat } = useLocalDateTimeFormat();
    const handleDateChange = React.useCallback(
        (selectedDate?: Date) => {
            if (!selectedDate) {
                onChange(null);
            } else {
                onChange(selectedDate.toString());
            }
        },
        [onChange]
    );
    const inputFormat = React.useMemo(getUserDateFormat, [getUserDateFormat]);

    return (
        <DatePicker
            label="Select date"
            value={value ? new Date(value) : null}
            onChange={handleDateChange}
            slotProps={{ textField: { variant: 'outlined', helperText: error, error: !!error } }}
            format={inputFormat}
        />
    );
};
