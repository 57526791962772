import {
    isNotificationDeeplink,
    NotificationCreate,
    NotificationStatus,
    NotificationType
} from 'components/notification/models/Notification';
import {
    isBoolean,
    isDateString,
    isEnumOfType,
    isNumber,
    isOptional,
    isOptionalArrayOf,
    isString,
    TypeGuard
} from 'lib/typeguards';

export enum ScheduledTaskStatus {
    DRAFT = 'DRAFT', // Just saved, not scheduled
    SCHEDULED = 'SCHEDULED', // waiting to be queued
    QUEUED = 'QUEUED', // waiting to be processed
    PROCESSING = 'PROCESSING', // Pulled from queue, actively being processed
    PROCESSED = 'PROCESSED',
    ERROR = 'ERROR' // Failed to process, may be up for retry
}

export enum ScheduledTaskType {
    // Debug types
    NOOP = 'NOOP',
    FORCE_ERROR = 'FORCE_ERROR',
    // Real types
    CREATE_PUSH_NOTIFICATION = 'CREATE_PUSH_NOTIFICATION',
    RESET_AWARD_POINTS = 'RESET_AWARD_POINTS'
}

export type IScheduledTaskSource =
    | {
          source: 'RULE';
          ruleId: string;
      }
    | {
          source: 'API';
      };

export function isScheduledTaskSource(source: any): source is IScheduledTaskSource {
    return (
        source &&
        isString(source.source) &&
        ((source.source === 'RULE' && isString(source.ruleId)) || source.source === 'API')
    );
}

export interface ScheduledTaskParameterTypeMap {
    [ScheduledTaskType.NOOP]: NonNullable<any>;
    [ScheduledTaskType.FORCE_ERROR]: NonNullable<any>;
    [ScheduledTaskType.CREATE_PUSH_NOTIFICATION]: NotificationCreate;
    [ScheduledTaskType.RESET_AWARD_POINTS]: { userId: string; awardId: string };
}

const scheduledTaskTypeToParameterTypeGuardMap: Record<
    ScheduledTaskType,
    TypeGuard<ScheduledTaskParameterTypeMap[ScheduledTaskType]>
> = {
    [ScheduledTaskType.NOOP]: (_: any): _ is any => true,
    [ScheduledTaskType.FORCE_ERROR]: (_: any): _ is any => true,
    [ScheduledTaskType.CREATE_PUSH_NOTIFICATION]: (
        params: any
    ): params is ScheduledTaskParameterTypeMap[ScheduledTaskType.CREATE_PUSH_NOTIFICATION] =>
        params &&
        isOptional(isString, params.title) &&
        isString(params.message) &&
        isOptional(isString, params.imageUrl) &&
        isOptional(isNotificationDeeplink, params.deepLink) &&
        isOptional(isString, params.buttonText) &&
        isEnumOfType(NotificationType, params.type) &&
        isString(params.value) &&
        isBoolean(params.isProcessingMessage) &&
        isOptionalArrayOf(isString, params.userIds) &&
        isOptionalArrayOf(isString, params.segmentIds) &&
        isOptionalArrayOf(isString, params.locationIds) &&
        isOptional(isBoolean, params.global) &&
        (!params.status ||
            (isEnumOfType(NotificationStatus, params.status) &&
                (params.status === NotificationStatus.DRAFT ||
                    params.status === NotificationStatus.PENDING))) &&
        isOptional(isString, params.tag),
    [ScheduledTaskType.RESET_AWARD_POINTS]: (params: any): params is { userId: string; awardId: string } =>
        isString(params?.userId) && isString(params?.awardId)
};

export function isScheduledTaskParameters<Type extends ScheduledTaskType>(
    type: Type,
    parameters: any
): parameters is ScheduledTaskParameterTypeMap[Type] {
    return scheduledTaskTypeToParameterTypeGuardMap[type](parameters);
}

export interface ScheduledTask<Type extends ScheduledTaskType> {
    id: string;
    _id: string;
    tenantId: string;
    locationId?: string;
    status: ScheduledTaskStatus;
    type: Type;
    parameters: ScheduledTaskParameterTypeMap[Type];
    triggerAt: string;
    queuedAt?: string;
    attempts: number;
    error?: string;
    source: IScheduledTaskSource;
    idempotencyKey?: string;
    createdAt: string;
    updatedAt: string;
}

export type CreateScheduledTask<Type extends ScheduledTaskType> = Pick<
    ScheduledTask<Type>,
    'type' | 'parameters' | 'triggerAt'
> & {
    status: ScheduledTaskStatus.SCHEDULED | ScheduledTaskStatus.DRAFT;
};

export function isScheduledTask<Type extends ScheduledTaskType>(task: any): task is ScheduledTask<Type> {
    return (
        task &&
        isString(task._id) &&
        isString(task.tenantId) &&
        isOptional(isString, task.locationId) &&
        isEnumOfType(ScheduledTaskStatus, task.status) &&
        isEnumOfType(ScheduledTaskType, task.type) &&
        isScheduledTaskParameters(task.type, task.parameters) &&
        isDateString(task.triggerAt) &&
        isOptional(isDateString, task.queuedAt) &&
        isNumber(task.attempts) &&
        isOptional(isString, task.error) &&
        isScheduledTaskSource(task.source) &&
        isOptional(isString, task.idempotencyKey)
    );
}

export function isScheduledTaskOfType<Type extends ScheduledTaskType>(
    type: Type,
    task: any
): task is ScheduledTask<Type> {
    return isScheduledTask(task) && task.type === type;
}

// Shortcut for specific types

export type IScheduledTaskNoop = ScheduledTask<ScheduledTaskType.NOOP>;
export type IScheduledTaskForceError = ScheduledTask<ScheduledTaskType.FORCE_ERROR>;
export type IScheduledTaskCreatePushNotification = ScheduledTask<ScheduledTaskType.CREATE_PUSH_NOTIFICATION>;
export type ICreateScheduledTaskCreatePushNotification =
    CreateScheduledTask<ScheduledTaskType.CREATE_PUSH_NOTIFICATION>;
export type IScheduledTaskResetAwardPoints = ScheduledTask<ScheduledTaskType.RESET_AWARD_POINTS>;
