import { StandardTextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { styled, TextField } from '@mui/material';
import { isEmptyString } from 'lib/typeguards';
import { debounce } from 'lodash';

const StyledTextField = styled(TextField)(({ theme }) => ({
    minWidth: '360px',
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down('md')]: {
        width: '100%',
        minWidth: 0
    }
}));

interface DebouncedGridFilterInputProps {
    debounceTime?: number;
    onChange: (value: string | undefined, name: string) => void;
    value?: string;
    placeholder?: string;
    name: string;
    disabled?: boolean;
}

export const DebouncedGridFilterInput: React.FC<
    DebouncedGridFilterInputProps & Omit<StandardTextFieldProps, 'onChange'>
> = ({ debounceTime = 1000, value = '', onChange, placeholder, name, disabled, ...rest }) => {
    const [field, setField] = React.useState<string>(value);
    const handleChange = React.useCallback(
        (newValue: string) => {
            if (newValue !== value) {
                onChange(isEmptyString(newValue) ? undefined : newValue, name);
            }
        },
        [name, onChange, value]
    );
    const debounceFn = React.useMemo(
        () => debounce(handleChange, debounceTime),
        [debounceTime, handleChange]
    );
    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
                debounceFn.cancel();
                handleChange(field);
            }
        },
        [debounceFn, field, handleChange]
    );
    const handleBlur = React.useCallback(() => {
        debounceFn.cancel();
        handleChange(field);
    }, [debounceFn, field, handleChange]);
    const handleFieldChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setField(e.target.value);
            debounceFn(e.target.value);
        },
        [debounceFn]
    );

    return (
        <StyledTextField
            value={field}
            onKeyDown={handleKeyDown}
            onChange={handleFieldChange}
            onBlur={handleBlur}
            placeholder={placeholder}
            name={name}
            disabled={disabled}
            {...rest}
        />
    );
};
