import { Grid, Typography, Button } from '@mui/material';
import { Suggestion } from '@pepperhq/menu-sdk';
import { Form } from 'formik';
import { ProductSuggestionType } from '../model/menu';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { ImageFormField } from 'lib/form/fields/ImageFormField';
import { AutocompleteFormField } from 'lib/form/fields/AutocompleteFormField';
import { MuiForm } from 'lib/Form';
import { Option } from 'lib/types';
import { Row } from 'ui/Flex';
import React from 'react';
import * as yup from 'yup';

interface SuggestionFormProps {
    options: Option[];
    suggestion?: Suggestion;
    onClose: () => void;
    onSave: (suggestion: Suggestion) => void;
    onClear: () => void;
}

const typeOptions: Array<{ value: ProductSuggestionType; label: string }> = [
    { value: 'add', label: 'ADD' },
    { value: 'bogof', label: 'OFFER' },
    { value: 'replace', label: 'REPLACE' }
];

const schema = yup.object().shape({
    title: yup.string().required('This field is required'),
    description: yup.mixed().when('type', {
        is: (target: string) => target === 'bogof',
        then: yup.mixed().required('This field is required.'),
        otherwise: undefined
    }),
    type: yup
        .string()
        .oneOf(typeOptions.map(option => option.value))
        .required('This field is required.'),
    productId: yup.mixed().when('type', {
        is: (target: string) => target === 'add' || target === 'replace',
        then: yup.mixed().required('This field is required.'),
        otherwise: undefined
    })
});

export const SuggestionsForm: React.FC<SuggestionFormProps> = ({
    suggestion,
    options,
    onClose,
    onSave,
    onClear
}) => {
    const handleSubmit = React.useCallback(
        (values: Suggestion) => {
            if (values.type === 'bogof') {
                const value: Suggestion = { ...values, productId: undefined };
                onSave(value);
                onClose();
                return;
            }
            onSave(values);
            onClose();
        },
        [onClose, onSave]
    );

    const handleRemove = React.useCallback(() => {
        onClear();
        onClose();
    }, [onClear, onClose]);

    const initialValues = React.useMemo(
        () =>
            suggestion ?? {
                title: '',
                description: '',
                type: '',
                productId: '',
                imageUrl: ''
            },
        [suggestion]
    );

    return (
        <MuiForm onSubmit={handleSubmit} initialValues={initialValues} validationSchema={schema}>
            {({ submitForm, values }) => (
                <Form>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item sm={12}>
                            <Typography variant="h6" color="primary">
                                Edit Product Suggestion
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} sm={6} spacing={1}>
                            <Grid item xs={12}>
                                <SelectFormField name="type" options={typeOptions} label="Type" />
                            </Grid>

                            {(values?.type === 'add' || values?.type === 'replace') && (
                                <Grid item xs={12}>
                                    <AutocompleteFormField
                                        name="productId"
                                        label="Product"
                                        options={options}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <TextFormField label="Title" name="title" />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={6} spacing={1}>
                            <Grid item xs={12}>
                                <TextFormField name="description" label="Description" multiline />
                            </Grid>
                            <Grid item xs={12}>
                                <ImageFormField
                                    aspect={3.5}
                                    name="imageUrl"
                                    description="600px by 200px. Max. file size is 1MB."
                                    smallText
                                />
                            </Grid>
                        </Grid>
                        <Grid item sm={12}>
                            <Row align="space-between" flex={1}>
                                <Row flex={1}>
                                    {values && (
                                        <Button onClick={handleRemove} color="primary">
                                            Remove Suggestion
                                        </Button>
                                    )}
                                </Row>
                                <Row gutter>
                                    <Button color="primary" onClick={onClose}>
                                        Cancel
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={submitForm}>
                                        Save
                                    </Button>
                                </Row>
                            </Row>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </MuiForm>
    );
};
