import { MenuItem, Select } from '@mui/material';
import { Option } from 'lib/types';
import React from 'react';
import { Notification } from 'components/notification/models/Notification';

const ALL = 'ALL';

interface NotificationReceiptNotificationFilterProps {
    disabled?: boolean;
    notifications: Notification<string>[];
    onChange: (value?: string) => void;
    value?: string;
}

export const NotificationReceiptNotificationFilter: React.FC<NotificationReceiptNotificationFilterProps> = ({
    disabled,
    notifications,
    value,
    onChange
}) => {
    const options: Option[] = React.useMemo(
        () =>
            notifications.map(notification => {
                const date = new Date(notification.createdAt).toLocaleString();
                return {
                    label: `${date} (${notification._id})`,
                    value: notification._id
                };
            }),
        [notifications]
    );
    const handleStateChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === ALL) {
                onChange(undefined);
            } else {
                onChange(e.target.value);
            }
        },
        [onChange]
    );
    const renderOption = React.useCallback(
        (option: Option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ),
        []
    );
    return (
        <Select disabled={disabled} value={value ?? ALL} onChange={handleStateChange} variant="outlined">
            <MenuItem value={ALL}>All Notifications</MenuItem>
            {options.map(renderOption)}
        </Select>
    );
};
