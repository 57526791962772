import { Box, Paper } from '@mui/material';
import { DataGridProProps, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { OrderScenario, IMenuOption } from '@pepperhq/menu-sdk';
import { OrderScenarioLabels } from 'components/order/model/Order';
import { MENU_MANAGER_ENRICHMENTS_EMPTY } from 'config/routes';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { isArray, isUndefined } from 'lib/typeguards';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { MenuOptionFilter } from '../MenuManagerModel';
import { MenuSchedulingFilter } from './filter/MenuSchedulingFilter';
import { formatDate } from 'lib/timeHelpers';

interface IProps {
    list: IMenuOption[];
    isLoading: boolean;
}

interface IRowEntry {
    menuId: string;
    description: string;
    locationId: string;
}

enum GridFields {
    DESCRIPTION = 'description',
    PRIORITY_ORDER = 'priorityOrder',
    ZONES = 'zones',
    SCENARIOS = 'scenarios',
    TIMETABLE = 'timetable',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
    SUNDAY = 'sunday'
}

export const filterKeyToFilterChecker: Record<
    keyof MenuOptionFilter,
    (summary: IMenuOption, value: any) => boolean
> = {
    menu: (summary, menuName) => summary.description.toLowerCase().includes(menuName.toLowerCase()),
    scenario: (summary, scenario: OrderScenario) => {
        if (!summary.scenarios || isUndefined(scenario)) {
            return true;
        }

        return summary.scenarios.includes(scenario);
    },
    zone: (summary, zone) => {
        if (!summary.zones || isUndefined(zone)) {
            return true;
        }

        return summary.zones.includes(zone);
    }
};

export const MenuSchedulingTable: React.FC<IProps> = ({ list, isLoading }) => {
    const { locationId } = useParams<{ locationId: string }>();
    const [filter, setFilter] = React.useState<MenuOptionFilter>({
        menu: undefined,
        scenario: undefined,
        zone: undefined
    });

    // TODO: fix the type
    const valueFormatter = React.useCallback(
        (cellValue: string, _, __, apiRef: any) => {
            const allRows = Array.from(apiRef.current?.getRowModels());
            const entries = allRows.find(([, value]) => value.menuId === cellValue);
            if (!entries) {
                return '';
            }
            // eslint-disable-next-line prefer-destructuring
            const { menuId, locationId, description }: IRowEntry = (entries as any)[1];

            const { menuChangeId } = list.find(
                ({ menuId: _menuId, description: _description }) =>
                    menuId === _menuId && description === _description
            );

            return (
                <Link
                    to={{
                        pathname: MENU_MANAGER_ENRICHMENTS_EMPTY.replace(':menuId', menuId)
                            .replace(':locationId', locationId)
                            .replace(':menuChangeId', menuChangeId)
                    }}
                >
                    {description}
                </Link>
            );
        },
        [list]
    );

    const groupingColDef = React.useMemo<DataGridProProps['groupingColDef']>(
        () => ({
            headerName: 'Title',
            hideDescendantCount: true,
            valueFormatter,
            width: 300
        }),
        [valueFormatter]
    );

    const handleFilterChange = React.useCallback((filter: MenuOptionFilter) => {
        setFilter(filter);
    }, []);
    const renderGridColumnMap = React.useMemo<
        Partial<Record<string, (params: GridRenderCellParams) => JSX.Element | string>>
    >(
        () => ({
            ZONES: params => {
                if (params.value === null) {
                    return 'All Zones';
                }

                return params.value;
            },
            SCENARIOS: params => {
                const value = params.value as IMenuOption['scenarios'];
                if (value === null) {
                    return 'All Scenarios';
                }
                if (value === undefined) {
                    return '';
                }

                return value.map(scenario => OrderScenarioLabels[scenario]).join(', ');
            },
            DATE: params => {
                const startDate = params.value;

                return formatDate(startDate) || '';
            },
            TIME: params => {
                const timeArray = params.value;

                return isArray(timeArray) ? timeArray.join(', ') : timeArray;
            }
        }),
        []
    );
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'Priority',
                field: GridFields.PRIORITY_ORDER,
                disableReorder: true,
                disableExport: true,
                width: 100,
                sortable: false
            },
            {
                headerName: 'Zones',
                field: GridFields.ZONES,
                disableReorder: true,
                disableExport: true,
                width: 100,
                sortable: false,
                renderCell: renderGridColumnMap['ZONES']
            },
            {
                headerName: 'Scenario',
                field: GridFields.SCENARIOS,
                disableReorder: true,
                disableExport: true,
                width: 240,
                sortable: false,
                renderCell: renderGridColumnMap['SCENARIOS']
            },
            {
                headerName: 'Start Date',
                field: GridFields.START_DATE,
                disableReorder: true,
                disableExport: true,
                width: 100,
                sortable: false,
                renderCell: renderGridColumnMap['DATE']
            },
            {
                headerName: 'End Date',
                field: GridFields.END_DATE,
                disableReorder: true,
                disableExport: true,
                width: 100,
                sortable: false,
                renderCell: renderGridColumnMap['DATE']
            },
            {
                headerName: 'Monday',
                field: GridFields.MONDAY,
                disableReorder: true,
                disableExport: true,
                width: 100,
                sortable: false,
                renderCell: renderGridColumnMap['TIME']
            },
            {
                headerName: 'Tuesday',
                field: GridFields.TUESDAY,
                disableReorder: true,
                disableExport: true,
                width: 100,
                sortable: false,
                renderCell: renderGridColumnMap['TIME']
            },
            {
                headerName: 'Wednesday',
                field: GridFields.WEDNESDAY,
                disableReorder: true,
                disableExport: true,
                width: 100,
                sortable: false,
                renderCell: renderGridColumnMap['TIME']
            },
            {
                headerName: 'Thursday',
                field: GridFields.THURSDAY,
                disableReorder: true,
                disableExport: true,
                width: 100,
                sortable: false,
                renderCell: renderGridColumnMap['TIME']
            },
            {
                headerName: 'Friday',
                field: GridFields.FRIDAY,
                disableReorder: true,
                disableExport: true,
                width: 100,
                sortable: false,
                renderCell: renderGridColumnMap['TIME']
            },
            {
                headerName: 'Saturday',
                field: GridFields.SATURDAY,
                disableReorder: true,
                disableExport: true,
                width: 100,
                sortable: false,
                renderCell: renderGridColumnMap['TIME']
            },
            {
                headerName: 'Sunday',
                field: GridFields.SUNDAY,
                disableReorder: true,
                disableExport: true,
                width: 100,
                sortable: false,
                renderCell: renderGridColumnMap['TIME']
            }
        ],
        [renderGridColumnMap]
    );
    const filteredMenuOptions = React.useMemo(
        () =>
            list.filter(option =>
                Object.entries(filter)
                    .filter(([, value]) => !!value)
                    .every(([key, value]: [keyof MenuOptionFilter, string]) =>
                        filterKeyToFilterChecker[key](option, value)
                    )
            ),
        [filter, list]
    );
    const groupedMenuOptions = React.useMemo(
        () =>
            filteredMenuOptions.reduce((acc, menuOption) => {
                const menuOptionUniqueId = v4();
                const childGroups = menuOption.timetable.map(timeTable => {
                    const timeTableUniqueId = v4();

                    return {
                        startDate: timeTable.startDate,
                        endDate: timeTable.endDate,
                        monday: timeTable.timesByDay.MON,
                        tuesday: timeTable.timesByDay.TUE,
                        wednesday: timeTable.timesByDay.WED,
                        thursday: timeTable.timesByDay.THU,
                        friday: timeTable.timesByDay.FRI,
                        saturday: timeTable.timesByDay.SAT,
                        sunday: timeTable.timesByDay.SUN,
                        path: [menuOption.menuId, menuOptionUniqueId, timeTableUniqueId],
                        id: timeTableUniqueId
                    };
                });
                const menuOptionItem = {
                    title: menuOption.description,
                    priorityOrder: menuOption.priorityOrder,
                    zones: menuOption.zones || null,
                    scenarios: menuOption.scenarios || null,
                    menuId: menuOption.menuId,
                    description: menuOption.description,
                    locationId,
                    id: menuOptionUniqueId,
                    path: [menuOption.menuId, menuOptionUniqueId]
                };

                return [...acc, menuOptionItem, ...childGroups];
            }, []),
        [filteredMenuOptions, locationId]
    );
    const getTreeDataPath = React.useCallback((item: any) => item.path, []);
    const getRowId = React.useCallback((item: any) => item.id, []);

    return (
        <React.Fragment>
            <Box paddingBottom={1}>
                <MenuSchedulingFilter filter={filter} onFilterChange={handleFilterChange} />
            </Box>
            <Paper sx={{ flex: 1, display: 'flex', minHeight: 0 }}>
                <MuiGrid
                    rows={groupedMenuOptions}
                    loading={isLoading}
                    columns={columns}
                    hideFooter={false}
                    hideFooterRowCount
                    getRowId={getRowId}
                    treeData
                    getTreeDataPath={getTreeDataPath}
                    groupingColDef={groupingColDef}
                />
            </Paper>
        </React.Fragment>
    );
};
