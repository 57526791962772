import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { isNutritionInfo, NutrientValue, NutritionInfo } from '@pepperhq/menu-sdk';
import { isArrayOf } from 'lib/typeguards';
import React from 'react';

const NutritionValue: React.FC<{ value: NutritionInfo[] }> = ({ value }) => {
    const renderString = React.useMemo(() => {
        const getValuesString = (values: NutrientValue[]) =>
            values.map(item => `${item.nutrient} - ${item.value}`).join(', ');
        if (isArrayOf(isNutritionInfo, value)) {
            return value
                .map(
                    item =>
                        `${item.title && item.title.trim() !== '' ? item.title + ': ' : ''}${getValuesString(
                            item.values
                        )}`
                )
                .join('; ');
        }
        return '';
    }, [value]);
    return <span>{renderString}</span>;
};

export const NutritionViewCell = (props: GridRenderCellParams) => <NutritionValue value={props.value} />;
