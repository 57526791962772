import { IMenuOption } from '@pepperhq/menu-sdk';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { MenuSchedulingTable } from 'components/menu-manager/menu-scheduling/MenuSchedulingTable';
import { menuOptionsApi } from 'components/menu-manager/MenuOptionsApi';
import { LOCATIONS, LOCATION_VIEW } from 'config/routes';
import { MainLayout } from 'layouts/MainLayout';
import React from 'react';
import { useParams } from 'react-router-dom';

export const MenuScheduling = () => {
    const [menuOptions, setMenuOptions] = React.useState<IMenuOption[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const { locationId } = useParams<{ locationId: string }>();
    const { locationIdToLocationName } = useLocationHelpers();

    React.useEffect(() => {
        if (locationId) {
            setIsLoading(true);
            menuOptionsApi
                .getMenuOptionsByLocation(locationId)
                .then(res => {
                    if (res.ok && res.body) {
                        setMenuOptions(res.body);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [locationId]);

    return (
        <MainLayout
            pageName="Menu Scheduling"
            pageDescription="Schedule your menus for a specific location"
            breadcrumbs={[
                {
                    label: 'Locations',
                    url: LOCATIONS
                },
                {
                    label: locationIdToLocationName.get(locationId),
                    url: LOCATION_VIEW.replace(':locationId', locationId)
                }
            ]}
            noScroll
        >
            <MenuSchedulingTable isLoading={isLoading} list={menuOptions} />
        </MainLayout>
    );
};
