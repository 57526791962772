import { Box, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { Row } from 'ui/Flex';
import {
    PepperOrdersFilter,
    PepperOrdersFilterChange,
    isPepperOrderFilterKey
} from './hooks/useOrdersFilter';
import { OrderScenarioFilter } from './order-filters/OrderScenarioSelect';
import { OrderStatusFilter } from './order-filters/OrderStatusSelect';
import { GridLocationFilter } from 'lib/grid/GridLocationFilter';
import { GridDateFilter } from 'lib/grid/GridDateFilter';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';
import { GridFilterInput } from 'lib/grid/GridFilterInput';
import { GridFilterNumberInput } from 'lib/grid/GridFilterNumberInput';
import { defaultRangeShortcutsItems } from 'lib/date-picker/helpers';

interface OrdersFiltersProps {
    filter: PepperOrdersFilter;
    onChange: PepperOrdersFilterChange;
}

export const OrdersFilters: React.FC<OrdersFiltersProps> = ({ filter, onChange }) => {
    const handleDateChange = React.useCallback(
        (value?: DateRangePickerValue) => {
            onChange('date', value);
        },
        [onChange]
    );
    const handleScenarioChange = React.useCallback(
        (value?: string) => {
            onChange('scenario', value);
        },
        [onChange]
    );
    const handleSelectChange = React.useCallback(
        (e: SelectChangeEvent) => {
            if (isPepperOrderFilterKey(e.target.name)) {
                onChange(e.target.name, e.target.value);
            }
        },
        [onChange]
    );
    const handleLocationChange = React.useCallback(
        (value?: string) => {
            onChange('locationId', value);
        },
        [onChange]
    );
    const handleTextChange = React.useCallback(
        (value: string, name: keyof PepperOrdersFilter) => {
            onChange(name, value);
        },
        [onChange]
    );

    return (
        <Row flex={1} wrap="wrap" valign="center">
            <Box marginTop={2} marginRight={1}>
                <GridDateFilter
                    value={filter.date}
                    onChange={handleDateChange}
                    shortcuts={defaultRangeShortcutsItems}
                />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <OrderScenarioFilter value={filter.scenario} onChange={handleScenarioChange} />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <OrderStatusFilter onChange={handleSelectChange} value={filter.status} />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <GridLocationFilter value={filter.locationId} onChange={handleLocationChange} />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <GridFilterInput
                    value={filter.customerId}
                    onChange={handleTextChange}
                    label="Customer ID"
                    name="customerId"
                />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <GridFilterInput
                    value={filter.customerCredential}
                    onChange={handleTextChange}
                    label="Customer Contact"
                    name="customerCredential"
                />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <GridFilterNumberInput
                    value={filter.deliveryLocation}
                    onChange={handleTextChange}
                    label="Table"
                    name="deliveryLocation"
                />
            </Box>
            <Box marginTop={2} marginRight={1.5}>
                <GridFilterNumberInput
                    value={filter.orderId}
                    onChange={handleTextChange}
                    label="Order Number"
                    name="orderId"
                />
            </Box>
        </Row>
    );
};
