import React, { useMemo } from 'react';
import { Button, Grid } from '@mui/material';
import { Form } from 'formik';
import * as Yup from 'yup';
import { MuiForm } from 'lib/Form';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { Option } from 'lib/types';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { getActionTypeOptions } from 'components/actions/utils';
import {
    EActionTypeGroup,
    EChannel,
    ETimeOfDay,
    getActionTypeGroup
} from 'components/audience/models/addTriggersModel';
import {
    renderAmountField,
    renderAudiencesSelect,
    renderChannelSelect,
    renderDaysOfWeekSelect,
    renderLocationsSelect,
    renderMonthOfYearSelect,
    renderPrimaryPlatformSelect,
    renderScenarioSelect,
    renderTimeOfDaySelect,
    renderWeekOfYearInput
} from 'components/audience/utils/addTriggerUtils';
import { ActionType } from 'components/actions/models/Action';
import { EPrimaryPlatform } from 'components/customers/models/Customer';
import { ConditionPredicate } from '@pepperhq/sdk';
import { OrderScenario } from 'components/order/model/Order';
import { ScheduledTaskType } from 'components/scheduled-tasks/scheduledTasks.types';
import { parseRelativeTimeString, validateRelativeTimeString } from 'lib/relativeTimeString';
import { NumberFormField } from 'lib/form/fields/NumberFormField';

export interface RuleScheduledTaskNotificationTargetFormData {
    type: ScheduledTaskType.CREATE_PUSH_NOTIFICATION;
    triggerAt: string;
    tag?: string;
    actionType: ActionType;
    locationId?: string;
    dayPart?: ETimeOfDay;
    dayOfWeek?: string;
    weekOfYear?: string;
    monthOfYear?: string;
    primaryPlatform?: EPrimaryPlatform;
    amountPredicate?: ConditionPredicate;
    amount?: string;
    scenario?: OrderScenario;
    channel?: EChannel;
    audienceId?: string;
}

export interface RuleScheduledTaskNotificationTargetInternalFormData {
    triggerAtOffset: number;
    triggerAtUnit: string;
}

interface RuleScheduledTaskNotificationTargetFormProps {
    open: boolean;
    onNext: (data: RuleScheduledTaskNotificationTargetFormData) => void;
    onCancel: () => void;
    initialData?: Partial<RuleScheduledTaskNotificationTargetFormData>;
    locationOptions: Option[];
    audienceOptions: Option[];
}

const validationSchema = Yup.object().shape({
    actionType: Yup.string().typeError(' ').required(' '),
    audienceId: Yup.string().when('actionType', {
        is: (actionType: ActionType) =>
            !!actionType && getActionTypeGroup(actionType) === EActionTypeGroup.SIXTH,
        then: Yup.string().required(' ')
    }),
    triggerAtOffset: Yup.number().required(' ').min(1, 'Delay must be at least 1'),
    triggerAtUnit: Yup.string().required(' ').oneOf(['minutes', 'hours', 'days', 'weeks'])
});

const relativeTimeOptions: Option[] = [
    { value: 'minutes', label: 'Minutes' },
    { value: 'hours', label: 'Hours' },
    { value: 'days', label: 'Days' },
    { value: 'weeks', label: 'Weeks' }
];

const typeOptions: Option[] = [
    {
        value: ScheduledTaskType.CREATE_PUSH_NOTIFICATION,
        label: 'Push Notification'
    }
];

export const RuleScheduledTaskNotificationTargetForm: React.FC<
    RuleScheduledTaskNotificationTargetFormProps
> = ({ open, onNext, onCancel, initialData, locationOptions, audienceOptions }) => {
    const initialValues = useMemo<
        RuleScheduledTaskNotificationTargetFormData & RuleScheduledTaskNotificationTargetInternalFormData
    >(() => {
        let offset, unit;
        if (initialData?.triggerAt && validateRelativeTimeString(initialData.triggerAt)) {
            const parsed = parseRelativeTimeString(initialData.triggerAt);
            offset = parseInt(parsed.amountString);
            unit = parsed.unit;
        }
        return {
            ...initialData,
            type: ScheduledTaskType.CREATE_PUSH_NOTIFICATION,
            triggerAt: initialData?.triggerAt || null,
            triggerAtUnit: unit || 'minutes',
            triggerAtOffset: offset,
            actionType: initialData?.actionType || null
        };
    }, [initialData]);

    const handleSubmit = React.useCallback(
        (
            values: RuleScheduledTaskNotificationTargetFormData &
                RuleScheduledTaskNotificationTargetInternalFormData
        ) => {
            onNext({
                ...values,
                triggerAt: `+${values.triggerAtOffset}${values.triggerAtUnit}`
            } as RuleScheduledTaskNotificationTargetFormData);
        },
        [onNext]
    );
    if (!open) {
        return null;
    }
    return (
        <MuiForm
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount
        >
            {({ submitForm, isSubmitting, values: { actionType }, isValid }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SelectFormField
                                name="type"
                                options={typeOptions}
                                label={<MuiFormLabel required>Type</MuiFormLabel>}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container alignItems="center">
                                <SelectFormField
                                    label={<MuiFormLabel required>Action Type</MuiFormLabel>}
                                    options={getActionTypeOptions(false)}
                                    name="actionType"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container alignItems="center">
                                <Grid item xs={4}>
                                    <MuiFormLabel required>Delay By</MuiFormLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container spacing={2} alignItems="flex-start">
                                        <Grid item xs={6}>
                                            <NumberFormField min={1} required name="triggerAtOffset" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectFormField
                                                native={false}
                                                options={relativeTimeOptions}
                                                name="triggerAtUnit"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {getActionTypeGroup(actionType) === EActionTypeGroup.FIRST && (
                            <>
                                {renderLocationsSelect(locationOptions)}
                                {renderTimeOfDaySelect()}
                                {renderDaysOfWeekSelect()}
                                {renderWeekOfYearInput()}
                                {renderMonthOfYearSelect()}
                                {renderPrimaryPlatformSelect()}
                            </>
                        )}
                        {getActionTypeGroup(actionType) === EActionTypeGroup.SECOND && (
                            <>
                                {renderTimeOfDaySelect()}
                                {renderDaysOfWeekSelect()}
                                {renderWeekOfYearInput()}
                                {renderMonthOfYearSelect()}
                            </>
                        )}
                        {getActionTypeGroup(actionType) === EActionTypeGroup.THIRD && (
                            <>
                                {renderAmountField()}
                                {renderLocationsSelect(locationOptions)}
                                {renderTimeOfDaySelect()}
                                {renderDaysOfWeekSelect()}
                                {renderWeekOfYearInput()}
                                {renderMonthOfYearSelect()}
                                {renderPrimaryPlatformSelect()}
                                {renderScenarioSelect()}
                            </>
                        )}
                        {getActionTypeGroup(actionType) === EActionTypeGroup.FOURTH && (
                            <>
                                {renderAmountField()}
                                {renderTimeOfDaySelect()}
                                {renderDaysOfWeekSelect()}
                                {renderWeekOfYearInput()}
                                {renderMonthOfYearSelect()}
                                {renderPrimaryPlatformSelect()}
                                {renderChannelSelect()}
                            </>
                        )}
                        {getActionTypeGroup(actionType) === EActionTypeGroup.FIFTH && (
                            <>
                                {renderTimeOfDaySelect()}
                                {renderDaysOfWeekSelect()}
                                {renderWeekOfYearInput()}
                                {renderMonthOfYearSelect()}
                                {renderPrimaryPlatformSelect()}
                            </>
                        )}
                        {getActionTypeGroup(actionType) === EActionTypeGroup.SIXTH &&
                            renderAudiencesSelect(audienceOptions, true)}
                        <Grid item xs={12}>
                            <TextFormField
                                name="tag"
                                description="Add a label to this notification to group it with similar ones."
                                label={<MuiFormLabel>Tag</MuiFormLabel>}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Row gutter align="flex-end" valign="center">
                                <Button variant="outlined" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting || !isValid}
                                    loading={isSubmitting}
                                    onClick={submitForm}
                                >
                                    Next
                                </LoadingButton>
                            </Row>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </MuiForm>
    );
};
