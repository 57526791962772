import React from 'react';
import logger from 'lib/logger';
import { EPremiumLoyaltyState, UserMetricDefinition } from 'components/metrics/model';
import { metricsApi } from 'components/metrics/metricsApi';

export function usePremiumLoyaltyStateModal(
    metricDefinition: UserMetricDefinition | undefined,
    onError: () => void,
    onSuccess: (value: UserMetricDefinition) => void | Promise<void>
) {
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleStateChangeOpen = React.useCallback(() => {
        setOpen(true);
    }, []);
    const handleStateChangeClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    const handleStateChangeSubmit = React.useCallback(
        async (state: string) => {
            const getFlagsFromState = (tierState: string) => {
                switch (tierState) {
                    case EPremiumLoyaltyState.ACTIVE:
                    case EPremiumLoyaltyState.SCHEDULED_AND_REALTIME:
                        return { realtime: true, scheduled: true };
                    case EPremiumLoyaltyState.REALTIME:
                        return { realtime: true, scheduled: false };
                    case EPremiumLoyaltyState.SCHEDULED:
                        return { realtime: false, scheduled: true };
                    case EPremiumLoyaltyState.INACTIVE:
                    default:
                        return { realtime: false, scheduled: false };
                }
            };
            setLoading(true);
            const result = await metricsApi.update(metricDefinition?._id, {
                body: { ...getFlagsFromState(state) }
            });
            setLoading(false);
            if (!result.ok) {
                logger.error(result.body.message, result.body);
                onError();
                return;
            }
            onSuccess(result.body);
            setOpen(false);
        },
        [metricDefinition?._id, onError, onSuccess]
    );
    return {
        stateModalOpen: open,
        handleStateChangeOpen,
        handleStateChangeClose,
        handleStateChangeSubmit,
        loading
    };
}
