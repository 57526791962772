import React, { CSSProperties } from 'react';
import { Box, Button, Grid, InputBase, Typography, styled } from '@mui/material';
import { AwardsScreenShapes, AwardTemplate } from 'components/customers/models/Award';
import { valignStyle } from 'components/loyalty/models/PerkModel';
import { rgbaToHex } from 'lib/helpers';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Column, Row } from 'ui/Flex';
import { PerkPreviewEditable, perkPreviewWidthByShape } from './visualisation/PerkPreviewEditable';
import { PerkGeneralFormData } from './PerkGeneralForm';
import { PerkSettingsFormData } from './PerkSettingsForm';
import { ExpiryLabelPreview } from '../ExpiryLabelPreview';
import { PerkVisualisationFormData } from 'components/perks/forms/PerkVisualisationForm';
import { ApplicationState } from 'store/store';
import { useSelector } from 'react-redux';

interface PerkReviewFormProps {
    data: PerkGeneralFormData & PerkSettingsFormData & PerkVisualisationFormData;
    open: boolean;
    label?: string;
    clickPrevious: () => void;
    submit: () => void;
    submitContinueAddTrigger?: () => void;
    isLoading: boolean;
    isLoadingContinueAddTrigger?: boolean;
    showButtonStepFive?: boolean;
    primaryActionBackgroundColour?: string;
}

const PREFIX = 'PerkReviewForm';

const classes = {
    background: `${PREFIX}-background`
};

const StyledBox = styled(Box)(() => ({
    [`& .${classes.background}`]: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        left: 0,
        right: 0,
        bottom: 0
    }
}));

const StyledColumn = styled(Column)<{ awardScreenShape: AwardsScreenShapes; perkType: AwardTemplate }>(
    ({ theme, awardScreenShape, perkType }) => ({
        padding: theme.spacing(1.5),
        border: `1px solid ${theme.palette.divider}`,
        position: 'relative',
        ...(awardScreenShape && (perkType === AwardTemplate.AD_HOC || perkType === AwardTemplate.CLUB)
            ? {
                  height: perkPreviewWidthByShape[awardScreenShape].height,
                  width: perkPreviewWidthByShape[awardScreenShape].width
              }
            : {
                  height: 320,
                  width: 480
              }),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    })
);

const getReviewData = (data: PerkGeneralFormData & PerkSettingsFormData & PerkVisualisationFormData) => {
    switch (data.perkType) {
        case AwardTemplate.PSEUDO_CURRENCY:
            return { subtitle: '123 points', title: data.titleText, subtitleTwo: '£1.23 worth' };
        default:
            return { subtitle: data.subtitle, title: data.titleText, subtitleTwo: data.subtitle2 };
    }
};

export const PerkReviewForm: React.FC<PerkReviewFormProps> = props => {
    const {
        data,
        open,
        isLoading,
        submit,
        clickPrevious,
        label,
        showButtonStepFive,
        submitContinueAddTrigger,
        isLoadingContinueAddTrigger,
        primaryActionBackgroundColour
    } = props;

    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const awardsScreenShapeEnabled = React.useMemo(
        () => settings?.app?.awardsScreenBarcodePosition && settings.app.awardsScreenBarcodePosition !== '',
        [settings]
    );

    if (!open) {
        return null;
    }
    const textAlign: CSSProperties['textAlign'] = data.align.toLowerCase() as CSSProperties['textAlign'];
    const { title, subtitle, subtitleTwo } = getReviewData(data);
    const stampsPerCard: number = !!data.stampsPerCard ? Number(data.stampsPerCard) : 6;
    const showExpiryLabelPreview =
        (data.perkType === AwardTemplate.AD_HOC || data.perkType === AwardTemplate.PSEUDO_CURRENCY) &&
        data.showExpiry;

    const showPerkImageFields =
        awardsScreenShapeEnabled &&
        (data.perkType === AwardTemplate.AD_HOC || data.perkType === AwardTemplate.CLUB)
            ? data.awardsScreenShape === AwardsScreenShapes.DOUBLE_RECTANGLE
            : true;

    const renderBox = () => (
        <StyledColumn
            perkType={data.perkType}
            awardScreenShape={awardsScreenShapeEnabled ? data.awardsScreenShape : undefined}
            valign={valignStyle[data.valign]}
        >
            {data.image && <img className={classes.background} src={data.image} alt="" />}
            {data.backgroundColor && (
                <div
                    className={classes.background}
                    style={{ backgroundColor: rgbaToHex(data.backgroundColor) }}
                />
            )}
            {showPerkImageFields && (
                <InputBase
                    fullWidth
                    disabled
                    multiline
                    maxRows={2}
                    style={{ fontSize: 45 }}
                    inputProps={{
                        style: {
                            textAlign: textAlign || 'start',
                            color: rgbaToHex(data.textColor),
                            WebkitTextFillColor: rgbaToHex(data.textColor)
                        }
                    }}
                    value={title}
                />
            )}
            {subtitle && showPerkImageFields && (
                <InputBase
                    fullWidth
                    disabled
                    multiline
                    maxRows={2}
                    style={{ fontSize: 29 }}
                    inputProps={{
                        style: {
                            textAlign: textAlign || 'start',
                            color: rgbaToHex(data.textColor),
                            WebkitTextFillColor: rgbaToHex(data.textColor)
                        }
                    }}
                    value={subtitle}
                />
            )}
            {subtitleTwo && showPerkImageFields && (
                <InputBase
                    fullWidth
                    multiline
                    maxRows={2}
                    disabled
                    style={{ fontSize: 21 }}
                    inputProps={{
                        style: {
                            textAlign: textAlign || 'start',
                            color: rgbaToHex(data.textColor),
                            WebkitTextFillColor: rgbaToHex(data.textColor)
                        }
                    }}
                    value={subtitleTwo}
                />
            )}
            {showExpiryLabelPreview && showPerkImageFields && (
                <ExpiryLabelPreview
                    vertical={data.expiryLabelAnchorStyleVAlign}
                    horizontal={data.expiryLabelAnchorStyleAlign}
                    primaryActionBackgroundColour={primaryActionBackgroundColour}
                />
            )}
        </StyledColumn>
    );

    return (
        <StyledBox>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box paddingX={2}>
                        <Typography variant="h4">5. Review</Typography>
                        <Typography variant="body2">
                            Review your Perk, you can come back and change how it looks if you need to later.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Row flex={1} align="center">
                        {data.perkType === AwardTemplate.STAMP_CARD ? (
                            <Row gutter valign="flex-end">
                                <PerkPreviewEditable
                                    label={
                                        data.perkType === AwardTemplate.STAMP_CARD
                                            ? 'No purchase available'
                                            : undefined
                                    }
                                    {...data}
                                    title={title}
                                    stampsPerCard={stampsPerCard}
                                    textAlign={textAlign}
                                />
                                {data.perkType === AwardTemplate.STAMP_CARD && (
                                    <PerkPreviewEditable
                                        label="Purchase available"
                                        cardAvailable
                                        {...data}
                                        title={title}
                                        stampsPerCard={stampsPerCard}
                                        textAlign={textAlign}
                                    />
                                )}
                            </Row>
                        ) : (
                            renderBox()
                        )}
                    </Row>
                </Grid>
                <Grid item xs={12}>
                    <Row flex={1} gutter align="flex-end">
                        <Button onClick={clickPrevious} color="primary" variant="outlined">
                            Previous
                        </Button>
                        {!!label && (
                            <LoadingButton
                                onClick={submit}
                                color="primary"
                                variant="contained"
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                {label || 'Create'}
                            </LoadingButton>
                        )}
                        {showButtonStepFive && (
                            <LoadingButton
                                onClick={submitContinueAddTrigger}
                                color="primary"
                                variant="contained"
                                loading={isLoadingContinueAddTrigger}
                                disabled={isLoadingContinueAddTrigger}
                            >
                                Next
                            </LoadingButton>
                        )}
                    </Row>
                </Grid>
            </Grid>
        </StyledBox>
    );
};
