import React from 'react';
import { ValueType } from 'react-select';
import { FormControl, FormHelperText } from '@mui/material';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { ChipInput } from 'lib/ChipInput';
import { Option } from 'lib/types';

interface ChipInputFormFieldProps {
    label?: React.ReactNode;
    placeholder?: string;
    options?: Option[];
    description?: React.ReactNode;
    isCreatable?: boolean;
    menuIsOpen?: boolean;
    showNoOptionsMessage?: boolean;
}

export const ChipInputFormField: React.SFC<FieldAttributes<ChipInputFormFieldProps>> = ({
    label,
    description,
    options,
    isCreatable,
    menuIsOpen,
    placeholder,
    showNoOptionsMessage,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <ChipInputFormFieldComponent
                label={label}
                description={description}
                options={options}
                isCreatable={isCreatable}
                menuIsOpen={menuIsOpen}
                placeholder={placeholder}
                showNoOptionsMessage={showNoOptionsMessage}
                {...childProps}
            />
        )}
    </Field>
);

const ChipInputFormFieldComponent: React.SFC<FieldProps & ChipInputFormFieldProps> = ({
    description,
    placeholder,
    field,
    meta,
    label,
    options,
    isCreatable,
    menuIsOpen,
    showNoOptionsMessage
}) => {
    const isError = meta.touched && !!meta.error;
    const handleChange = React.useCallback(
        (value: ValueType<Option, true> | ValueType<Option, false>) => {
            field.onChange({ target: { value, name: field.name } });
        },
        [field]
    );
    return (
        <FormControl fullWidth error={meta.touched && !!meta.error}>
            <ChipInput
                label={label}
                value={field.value}
                placeholder={placeholder}
                onBlur={field.onBlur}
                onChange={handleChange}
                options={options}
                isCreatable={isCreatable}
                menuIsOpen={menuIsOpen}
                showNoOptionsMessage={showNoOptionsMessage}
            />
            {(isError || description) && (
                <FormHelperText sx={{ marginX: 0 }} error={isError}>
                    {isError ? meta.error : description}
                </FormHelperText>
            )}
        </FormControl>
    );
};
