import { Box, Button, Paper, TablePagination } from '@mui/material';
import { GridCellParams, GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { getClerkResource, IClerkResource, terminalRoleToTitile } from 'components/clerks-access/Clerk';
import { clerksApi } from 'components/clerks-access/clerksApi';
import { ClerksDataFilters } from 'components/clerks-access/ClerksDataFilters';
import { ClerksModalForm } from 'components/clerks-access/ClerksModalForm';
import { CLERKS_PAGE_LIMIT, useClerksData } from 'components/clerks-access/hooks/useClerksData';
import { useClerksDataFilter } from 'components/clerks-access/hooks/useClerksFilter';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import {
    MESSAGE_CREATE_CLERK_SUCCESS,
    MESSAGE_DELETE_CLERK_ERROR,
    MESSAGE_DELETE_CLERK_SUCCESS,
    MESSAGE_UPDATE_CLERK_SUCCESS
} from 'config/messages';
import { MuiTheme } from 'config/theme';
import { MainLayout } from 'layouts/MainLayout';
import { SelectTitleColumn } from 'lib/MuiGrid/Columns';
import { EditCell } from 'lib/MuiGrid/EditCell';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { RemoveCell } from 'lib/MuiGrid/RemoveCell';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import React from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { Row } from 'ui/Flex';

const initialState = {
    columns: {
        columnVisibilityModel: { id: false }
    }
};

export const ClerksPage: React.FC = () => {
    const dispatch = useDispatch();
    const [filter, onFilterChange] = useClerksDataFilter();
    const {
        formattedClerks,
        count,
        page,
        onPageChange,
        loading: clerksLoading,
        addClerk,
        error,
        updateError,
        updateClerk
    } = useClerksData(filter);
    const { locationIdToLocationName, isLoading } = useLocationHelpers();
    const [createOpen, setCreateOpen] = React.useState(false);
    const [selectedClerkId, setSelectedClerkId] = React.useState<string>();
    const [toDeleteClerkId, setToDeleteClerkId] = React.useState<string>();
    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const handleEdit = React.useCallback((id: string) => {
        setSelectedClerkId(id);
    }, []);
    const handleDelete = React.useCallback((id: string) => {
        setToDeleteClerkId(id);
    }, []);
    const renderEditCell = React.useCallback(
        (params: GridRenderEditCellParams) => <EditCell params={params} onClick={handleEdit} />,
        [handleEdit]
    );
    const renderRemoveCell = React.useCallback(
        (params: GridCellParams) => <RemoveCell params={params} onDelete={handleDelete} />,
        [handleDelete]
    );
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'ID',
                field: 'id',
                width: 130,
                sortable: false,
                headerAlign: 'left'
            },
            {
                headerName: 'PIN',
                field: 'pin',
                width: 130,
                sortable: false,
                headerAlign: 'left'
            },
            {
                headerName: 'Staff Name',
                field: 'name',
                width: 250,
                sortable: false,
                headerAlign: 'left'
            },
            {
                headerName: 'Location',
                field: 'locationId',
                width: 250,
                sortable: false,
                headerAlign: 'left',
                ...SelectTitleColumn(locationIdToLocationName)
            },
            {
                headerName: 'Role',
                field: 'roleId',
                width: 120,
                sortable: false,
                headerAlign: 'left',
                ...SelectTitleColumn(terminalRoleToTitile)
            },
            {
                headerName: 'Session Duration (seconds)',
                field: 'sessionMs',
                type: 'number',
                width: 230,
                sortable: false,
                headerAlign: 'left'
            },
            {
                field: 'edit',
                headerName: ' ',
                width: 84,
                renderCell: renderEditCell
            },
            {
                field: 'remove',
                headerName: ' ',
                width: 84,
                renderCell: renderRemoveCell
            }
        ],
        [locationIdToLocationName, renderEditCell, renderRemoveCell]
    );
    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            onPageChange(page);
        },
        [onPageChange]
    );

    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={count}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={CLERKS_PAGE_LIMIT}
                rowsPerPageOptions={[CLERKS_PAGE_LIMIT]}
                component="div"
                sx={{
                    '&:last-child': {
                        paddingRight: MuiTheme.spacing(6)
                    }
                }}
            />
        ),
        [count, handlePageChange, page]
    );
    const handleCreateOpen = React.useCallback(() => {
        setCreateOpen(true);
    }, []);
    const handleClose = React.useCallback(() => {
        setCreateOpen(false);
        setSelectedClerkId(undefined);
        updateError();
    }, [updateError]);
    const handleDeleteClose = React.useCallback(() => {
        setToDeleteClerkId(undefined);
    }, []);
    const handleCreateSubmit = React.useCallback(
        async (data: IClerkResource) => {
            const result = await clerksApi.create({ body: getClerkResource(data) });
            if (result.ok) {
                addClerk(result.body);
                handleClose();
                dispatch(enqueueSnackbar(MESSAGE_CREATE_CLERK_SUCCESS, { variant: 'success' }));
            } else {
                updateError(result.body.message);
            }
        },
        [addClerk, dispatch, handleClose, updateError]
    );
    const selectedClerk = React.useMemo(
        () => formattedClerks.find(item => item.id === selectedClerkId) || null,
        [formattedClerks, selectedClerkId]
    );
    const clerkToDelete = React.useMemo(
        () => formattedClerks.find(item => item.id === toDeleteClerkId) || null,
        [formattedClerks, toDeleteClerkId]
    );
    const handleUpdateSubmit = React.useCallback(
        async (data: IClerkResource) => {
            const result = await clerksApi.update(selectedClerk?.id, { body: getClerkResource(data) });
            if (result.ok) {
                updateClerk(selectedClerk?.id, result.body);
                handleClose();
                dispatch(enqueueSnackbar(MESSAGE_UPDATE_CLERK_SUCCESS, { variant: 'success' }));
            } else {
                updateError(result.body.message);
            }
        },
        [dispatch, handleClose, selectedClerk?.id, updateClerk, updateError]
    );
    const handleDeleteSubmit = React.useCallback(async () => {
        setDeleteLoading(true);
        const result = await clerksApi.delete(toDeleteClerkId);
        if (result.ok) {
            updateClerk(toDeleteClerkId, undefined);
            handleDeleteClose();
            dispatch(enqueueSnackbar(MESSAGE_DELETE_CLERK_SUCCESS, { variant: 'success' }));
        } else {
            dispatch(enqueueSnackbar(MESSAGE_DELETE_CLERK_ERROR, { variant: 'error' }));
        }
        setDeleteLoading(false);
    }, [dispatch, handleDeleteClose, toDeleteClerkId, updateClerk]);
    const loading = React.useMemo(() => !!isLoading || !!clerksLoading, [clerksLoading, isLoading]);
    return (
        <MainLayout
            pageName="Clerks access"
            pageDescription="View and manage your clerks access for Quickpad"
            noScroll
        >
            <Box pb={1}>
                <Row flex={1} align="space-between" valign="center">
                    <ClerksDataFilters filter={filter} onChange={onFilterChange} />
                    <Button color="primary" variant="contained" onClick={handleCreateOpen}>
                        Add a clerk
                    </Button>
                </Row>
            </Box>
            <Paper sx={{ flex: 1, display: 'flex', minHeight: 0 }}>
                <MuiGrid
                    columns={columns}
                    rows={formattedClerks}
                    initialState={initialState}
                    disableMultipleRowSelection
                    hideFooter={false}
                    hideFooterRowCount
                    pagination
                    disableColumnFilter
                    paginationMode="server"
                    loading={loading}
                    slots={{
                        pagination: Pagination
                    }}
                    storageName={GridStorageName.Clerks}
                />
            </Paper>
            <ClerksModalForm
                open={createOpen || !!selectedClerk}
                onClose={handleClose}
                onSubmit={handleCreateSubmit}
                error={error}
                clerk={selectedClerk}
                onUpdate={handleUpdateSubmit}
                onErrorChange={updateError}
            />
            <DeleteDialog
                open={!!toDeleteClerkId}
                title={`Delete "${clerkToDelete?.name}" access`}
                content={
                    'If you delete this clerk access, you won’t be able to recover it. To confirm deletion, type "DELETE" in the text input field'
                }
                protectionWord="DELETE"
                onClose={handleDeleteClose}
                onDelete={handleDeleteSubmit}
                loading={deleteLoading}
            />
        </MainLayout>
    );
};
