import React from 'react';
import {
    ContentModule,
    ContentModuleType,
    IMarketingModule,
    IPrimaryAction,
    MarketingMessageItem,
    MessageAction,
    PrimaryActionItem
} from 'components/content/models/ContentModule';
import { Option } from 'lib/types';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { MultiSelectSuggest } from 'lib/MultiSelectSuggest';
import { titleize } from 'lib/helpers';
import { getSelectedOption } from 'components/utils/getSelectedOptions';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';

const NONE = 'NONE';

const moduleTypeOptions: Option[] = [
    { label: 'None', value: NONE },
    { label: 'Native Marketing Details', value: `${MessageAction.NATIVE}` },
    { label: 'Voucher', value: `${MessageAction.VOUCHER}` },
    { label: 'Ordering', value: 'ORDERING' },
    { label: 'Browser', value: 'BROWSER' }
];
const moduleItemFilters: Record<string, string[]> = {
    [MessageAction.NATIVE]: [MessageAction.NATIVE],
    [MessageAction.VOUCHER]: [MessageAction.VOUCHER],
    ORDERING: ['PREORDER', 'START_ORDER', 'TABLE_ORDER', 'PAY_AT_TABLE', 'TABS'],
    BROWSER: ['BROWSER']
};

interface NotificationDeepLinkFieldProps {
    modules: ContentModule[];
}

export const NotificationDeepLinkField: React.FC<FieldAttributes<NotificationDeepLinkFieldProps>> = ({
    modules,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => <NotificationDeepLinkFieldComponent modules={modules} {...childProps} />}
    </Field>
);

const NotificationDeepLinkFieldComponent: React.FC<FieldProps & NotificationDeepLinkFieldProps> = ({
    meta,
    field,
    form,
    modules: initialModules
}) => {
    const modules = React.useMemo(
        () =>
            initialModules.filter(
                entry =>
                    entry.type === ContentModuleType.MARKETING ||
                    entry.type === ContentModuleType.PRIMARY_ACTION
            ) as (IMarketingModule | IPrimaryAction)[],
        [initialModules]
    );
    const [moduleType, setModuleType] = React.useState<Option>(() => {
        const foundModule = modules.find(entry => entry.id === meta.initialValue?.moduleId);
        if (!foundModule) {
            return moduleTypeOptions[0];
        }
        const foundItem: PrimaryActionItem | MarketingMessageItem | null =
            'items' in foundModule
                ? (foundModule.items as any[]).find(item => item.id === meta.initialValue?.moduleItemId)
                : null;
        if (!foundItem) {
            return moduleTypeOptions[0];
        }
        const filterBy: string =
            foundModule.type === ContentModuleType.MARKETING
                ? (foundItem as MarketingMessageItem).action
                : (foundItem as PrimaryActionItem).type;
        let moduleTypeString;
        switch (filterBy) {
            case 'VOUCHER':
                moduleTypeString = MessageAction.VOUCHER;
                break;
            case 'PREORDER':
            case 'START_ORDER':
            case 'TABLE_ORDER':
            case 'PAY_AT_TABLE':
            case 'TABS':
                moduleTypeString = 'ORDERING';
                break;
            case 'BROWSER':
                moduleTypeString = 'BROWSER';
                break;
            case 'NATIVE':
            default:
                moduleTypeString = MessageAction.NATIVE;
                break;
        }
        return getSelectedOption(moduleTypeOptions, moduleTypeString) || moduleTypeOptions[0];
    });
    const filteredModules: Option[] = React.useMemo(
        () =>
            modules.map(entry => ({
                label: `${entry.title || titleize(entry.type)} (${entry.id})`,
                value: entry.id
            })) as Option[],
        [modules]
    );
    const [module, setModule] = React.useState<{
        type?: ContentModuleType.MARKETING | ContentModuleType.PRIMARY_ACTION;
        option: Option;
    }>(() => {
        const foundModule = modules.find(entry => entry.id === meta.initialValue?.moduleId);
        return {
            type: foundModule?.type,
            option: getSelectedOption(filteredModules, meta.initialValue?.moduleId)
        };
    });
    const filteredModuleItems: Option[] = React.useMemo(() => {
        const filters = moduleItemFilters[moduleType?.value];
        if (!filters) {
            return [];
        }
        const foundModule = modules.find(entry => module && entry.id === module.option?.value);
        const moduleItems: any[] = foundModule?.items || [];

        const options: any[] = moduleItems.filter(entry =>
            filters.includes(module.type === ContentModuleType.MARKETING ? entry.action : entry.type)
        );
        return options
            .filter(item => !!item.id) // Filter out items without an ID, those will need to be updated first
            .map(entry => ({
                label: `${entry.name || titleize(entry.action || entry.type)} (${entry.id})`,
                value: entry.id
            })) as Option[];
    }, [moduleType, module, modules]);
    const [moduleItem, setModuleItem] = React.useState<Option>(() =>
        getSelectedOption(filteredModuleItems, meta.initialValue?.moduleItemId)
    );
    const [voucher, setVoucher] = React.useState<string>(meta.initialValue?.voucher);
    const handleOnChange = React.useCallback(
        (
            newModuleType?: Option,
            newModule?: {
                type?: ContentModuleType.MARKETING | ContentModuleType.PRIMARY_ACTION;
                option: Option;
            },
            newModuleItem?: Option,
            newVoucher?: string
        ) => {
            setModuleType(newModuleType);
            setModule(newModule);
            setModuleItem(newModuleItem);
            setVoucher(newVoucher);
            field.onChange({
                target: {
                    value: !!newModule?.option?.value
                        ? {
                              moduleId: newModule?.option?.value,
                              moduleItemId: newModuleItem?.value,
                              ...(newVoucher && { voucher: newVoucher })
                          }
                        : undefined,
                    name: field.name
                }
            });
        },
        [field]
    );

    const onChangeModuleType = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const option = getSelectedOption(moduleTypeOptions, e.target.value);
            handleOnChange(option);
        },
        [handleOnChange]
    );
    const onChangeModule = React.useCallback(
        value => {
            const selected = modules.find(entry => entry.id === value?.value);
            handleOnChange(moduleType, { type: selected?.type, option: value });
        },
        [handleOnChange, moduleType, modules]
    );
    const onChangeModuleItem = React.useCallback(
        value => {
            handleOnChange(moduleType, module, value);
        },
        [handleOnChange, module, moduleType]
    );
    const onChangeVoucher = React.useCallback(
        ({ target }) => {
            handleOnChange(moduleType, module, moduleItem, target.value);
        },
        [handleOnChange, module, moduleItem, moduleType]
    );

    const options = React.useMemo(
        () =>
            moduleTypeOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            )),
        []
    );

    return (
        <FormControl fullWidth error={meta.touched && !!meta.error}>
            <Grid item xs={12}>
                <InputLabel shrink style={{ left: -12, top: 8 }}>
                    Deep Link
                </InputLabel>
                <Select
                    value={moduleType?.value}
                    onChange={onChangeModuleType}
                    disabled={form.isSubmitting}
                    variant="standard"
                    fullWidth
                >
                    {options}
                </Select>
            </Grid>
            {moduleType?.value !== NONE && (
                <>
                    <Grid item xs={12}>
                        <MultiSelectSuggest
                            value={module?.option}
                            onChange={onChangeModule}
                            placeholder={<MuiFormLabel required>Module</MuiFormLabel>}
                            label={undefined}
                            isMulti={false}
                            isCreatable={false}
                            options={filteredModules}
                            error={!!meta.error && !module?.option?.value}
                            disabled={form.isSubmitting}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MultiSelectSuggest
                            value={moduleItem}
                            onChange={onChangeModuleItem}
                            placeholder={<MuiFormLabel required>Module Item</MuiFormLabel>}
                            label={undefined}
                            isMulti={false}
                            isCreatable={false}
                            options={filteredModuleItems}
                            error={!!meta.error && !moduleItem?.value}
                            disabled={form.isSubmitting || !module}
                        />
                    </Grid>
                </>
            )}
            {moduleType?.value === MessageAction.VOUCHER && (
                <Grid item xs={12} style={{ paddingTop: 16 }}>
                    <TextField
                        fullWidth
                        value={voucher}
                        placeholder="Voucher Code"
                        onChange={onChangeVoucher}
                        label="Voucher Code"
                        disabled={form.isSubmitting}
                        helperText="The voucher code will be redeemed automatically. Please ensure the voucher code is already set up."
                    />
                </Grid>
            )}
        </FormControl>
    );
};
