import { NotificationType } from 'components/notification/models/Notification';
import React from 'react';
import { Column } from 'ui/Flex';
import { Box, Step, StepButton, StepLabel, Stepper } from '@mui/material';
import { IconButton } from 'ui/buttons/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Option } from 'lib/types';
import { ContentModule } from 'components/content/models/ContentModule';
import { ICreateScheduledTaskCreatePushNotification } from 'components/scheduled-tasks/scheduledTasks.types';
import { CreateRuleResource, RuleResource } from 'components/rules/rules.types';
import {
    RuleScheduledTaskNotificationTargetForm,
    RuleScheduledTaskNotificationTargetFormData
} from 'components/rules/scheduled-notification/forms/RuleScheduledTaskNotificationTargetForm';
import {
    NotificationGeneralForm,
    NotificationGeneralFormData
} from 'components/notification/forms/NotificationGeneralForm';
import { flattenRuleConditions } from 'components/rules/helpers';
import { makeAudienceConditions } from 'components/audience/utils/addTriggerUtils';
import { EvaluatorType, MatchType } from '@pepperhq/sdk/dist/rule/resources/rule';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { getAllLocations } from 'store/locations/locationsActions';
import { getAllAudiences } from 'store/audiences/audiencesActions';

export interface ScheduledTaskNotificationStepperProps {
    onClose: () => void;
    onSubmit: (rule: CreateRuleResource) => void;
    initialData?: Partial<Omit<RuleResource, 'active'>>;
    modules: ContentModule[];
}

const notificationDefaultData: ICreateScheduledTaskCreatePushNotification['parameters'] = {
    global: false,
    isProcessingMessage: false,
    type: NotificationType.Alert,
    value: '',
    message: '',
    locationIds: [],
    segmentIds: [],
    userIds: []
};

export const RuleScheduledTaskNotificationStepper: React.FC<ScheduledTaskNotificationStepperProps> = ({
    onClose,
    onSubmit,
    initialData,
    modules
}) => {
    const dispatch = useDispatch();
    const { locations } = useSelector((state: ApplicationState) => state.locations);
    const { audiences } = useSelector((state: ApplicationState) => state.audiences);

    React.useEffect(() => {
        if (!locations?.length) {
            dispatch(getAllLocations());
        }
    }, [dispatch, locations]);
    React.useEffect(() => {
        dispatch(getAllAudiences());
    }, [dispatch]);

    const locationOptions = React.useMemo(
        () =>
            locations.map<Option>(location => ({
                value: location._id,
                label: location.title
            })),
        [locations]
    );
    const audienceOptions = React.useMemo(
        () =>
            audiences.map<Option>(audience => ({
                value: audience._id,
                label: audience.title
            })),
        [audiences]
    );

    const [steps, _setSteps] = React.useState(['Audience', 'Set Up']);
    const [activeStep, setActiveStep] = React.useState(0);
    const [targetData, setTargetData] = React.useState<RuleScheduledTaskNotificationTargetFormData>({
        ...flattenRuleConditions(initialData),
        ...initialData?.effect?.impact?.parameters,
        tag: initialData?.effect?.impact?.parameters?.parameters?.tag
    });
    const [generalData, setGeneralData] = React.useState<Partial<NotificationGeneralFormData>>(
        initialData?.effect?.impact?.parameters?.parameters
    );

    const handleStepClick = React.useCallback(
        (index: number) => () => {
            setActiveStep(index);
        },
        []
    );
    const handlePrevious = React.useCallback(
        (data: Partial<NotificationGeneralFormData>) => {
            setGeneralData(data);
            setActiveStep(activeStep - 1);
        },
        [activeStep]
    );
    const handleTargetSubmit = React.useCallback((data: RuleScheduledTaskNotificationTargetFormData) => {
        setTargetData(data);
        setActiveStep(1);
    }, []);
    const handleGeneralSubmit = React.useCallback(
        (data: NotificationGeneralFormData) => {
            setGeneralData(data);
            const scheduledTaskParameters: ICreateScheduledTaskCreatePushNotification['parameters'] = {
                ...notificationDefaultData,
                tag: targetData.tag,
                // General
                title: data.title,
                message: data.message,
                ...(data.imageUrl && { imageUrl: data.imageUrl }),
                deepLink: data.deepLink,
                buttonText: data.buttonText
            };
            const body: CreateRuleResource = {
                matchType: MatchType.TYPE,
                conditions: makeAudienceConditions(targetData),
                effect: {
                    controller: 'scheduledTask',
                    method: 'createScheduledTask',
                    impact: {
                        evaluatorType: EvaluatorType.BUILTIN_FUNCTION,
                        evaluatorName: 'passThru',
                        parameters: {
                            parameters: scheduledTaskParameters,
                            type: targetData.type,
                            triggerAt: targetData.triggerAt
                        } as ICreateScheduledTaskCreatePushNotification
                    }
                },
                active: !data.draft
            };
            onSubmit(body);
        },
        [onSubmit, targetData]
    );

    return (
        <Box width="100%">
            <Column>
                <Box flex={1} pb={2}>
                    <Stepper nonLinear alternativeLabel activeStep={activeStep}>
                        {steps.map((item, index) => (
                            <Step key={`step-button-${item}`} completed={activeStep > index}>
                                <StepButton
                                    disableRipple
                                    disabled={activeStep < index}
                                    onClick={handleStepClick(index)}
                                    color="inherit"
                                >
                                    <StepLabel>{item}</StepLabel>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                <Box position="absolute" top={0} right={0}>
                    <IconButton size="large">
                        <CloseIcon onClick={onClose} />
                    </IconButton>
                </Box>
                <RuleScheduledTaskNotificationTargetForm
                    open={activeStep === 0}
                    onNext={handleTargetSubmit}
                    onCancel={onClose}
                    initialData={targetData}
                    locationOptions={locationOptions}
                    audienceOptions={audienceOptions}
                />
                <NotificationGeneralForm
                    open={activeStep === 1}
                    isRule
                    onSubmit={handleGeneralSubmit}
                    onPrevious={handlePrevious}
                    initialData={generalData}
                    modules={modules}
                />
            </Column>
        </Box>
    );
};
