import React from 'react';
import { useDispatch } from 'react-redux';
import { MainLayout } from 'layouts/MainLayout';
import { EmptyState } from 'components/utils/emptyState';
import { CreateVoucherModal } from 'components/vouchers/modals/CreateVoucherModal';
import EmptyImage from 'static/vouchers-image-placeholder.svg';
import { AddVoucherEffectsModal } from 'components/vouchers/modals/AddVoucherEffectsModal';
import { getAllAudiences } from 'store/audiences/audiencesActions';
import { getAllPerks } from 'store/perks/perksActions';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { VOUCHERS_PAGE_LIMIT, useVouchersData } from 'components/vouchers/hooks/useVouchersData';
import { useVouchersFilter } from 'components/vouchers/hooks/useVouchersFilter';
import { Box, Paper, TablePagination } from '@mui/material';
import { MARKETING_VOUCHERS_VIEW } from 'config/routes';
import { VoucherFilters } from 'components/vouchers/VouchersFilters';
import { useVouchersActions } from 'components/vouchers/hooks/useVouchersActions';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { useGridSingleSelection } from 'lib/MuiGrid/useGridSingleSelection';
import { useHistory } from 'react-router-dom';

function VouchersPage() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [filter, onFilterChange, sortModel, onSortModelChange] = useVouchersFilter();
    const { pagedVouchers, loading, page, onPageChange, count, resetVouchers, vouchers } =
        useVouchersData(filter);

    const {
        createdVoucher,
        createEffect,
        createVoucher,
        closeModals,
        handleCreateButtonClick,
        createModalOpen,
        actionsLoading,
        voucherToDelete,
        deleteVoucher,
        deleteVoucherConfirm
    } = useVouchersActions(resetVouchers);

    React.useEffect(() => {
        getAllAudiences()(dispatch);
        getAllPerks()(dispatch);
    }, [dispatch]);

    const [selectedVoucher, rowSelectionModel, handleSelectRow] = useGridSingleSelection(vouchers);

    const handleOpen = React.useCallback(() => {
        history.push(MARKETING_VOUCHERS_VIEW.replace(':voucherId', selectedVoucher?._id));
    }, [history, selectedVoucher?._id]);

    const handleDeleteClick = React.useCallback(() => {
        deleteVoucher(selectedVoucher);
    }, [deleteVoucher, selectedVoucher]);

    const dateValueFormatter = React.useCallback<GridColDef['valueGetter']>(
        (value: string) => new Date(value),
        []
    );

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'id',
                headerName: 'ID',
                disableReorder: true,
                disableExport: true,
                width: 270,
                sortable: false
            },
            {
                field: 'title',
                headerName: 'Title',
                disableExport: true,
                width: 230,
                sortable: false
            },
            {
                field: 'code',
                headerName: 'Claim Code',
                disableExport: true,
                width: 150,
                sortable: true
            },
            {
                field: 'startDate',
                headerName: 'Start Date',
                disableExport: true,
                width: 196,
                sortable: true,
                type: 'dateTime',
                valueGetter: dateValueFormatter
            },
            {
                field: 'endDate',
                headerName: 'End Date',
                disableExport: true,
                width: 196,
                sortable: true,
                type: 'dateTime',
                valueGetter: dateValueFormatter
            },
            {
                field: 'voucherSchemeId',
                headerName: 'Scheme ID',
                disableExport: true,
                width: 270,
                sortable: false
            },
            {
                field: 'numGlobalRedemptions',
                headerName: 'Max Redemptions',
                disableExport: true,
                width: 180,
                sortable: false,
                align: 'right'
            },
            {
                field: 'numRedemptionsPerUser',
                headerName: 'Redemptions per Customer',
                disableExport: true,
                width: 230,
                sortable: false,
                align: 'right'
            }
        ],
        [dateValueFormatter]
    );

    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            onPageChange(page);
        },
        [onPageChange]
    );

    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={count}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={VOUCHERS_PAGE_LIMIT}
                rowsPerPageOptions={[VOUCHERS_PAGE_LIMIT]}
                component="div"
                sx={{ paddingRight: 1 }}
            />
        ),
        [count, handlePageChange, page]
    );

    return (
        <MainLayout
            pageName="Vouchers"
            pageDescription="Vouchers can be exchanged for perks to redeem within the app."
            noScroll
        >
            <Box pb={7} height="100%">
                <Box pb={1}>
                    <VoucherFilters
                        filter={filter}
                        onChange={onFilterChange}
                        onCreateClick={handleCreateButtonClick}
                        loading={loading || actionsLoading}
                        selected={!!selectedVoucher}
                        onDeleteClick={handleDeleteClick}
                        onOpenClick={handleOpen}
                    />
                </Box>
                {loading || (Array.isArray(pagedVouchers) && pagedVouchers.length > 0) ? (
                    <Paper sx={{ height: '100%' }}>
                        <MuiGrid
                            columns={columns}
                            rows={pagedVouchers}
                            loading={loading}
                            disableMultipleRowSelection
                            sortModel={sortModel}
                            onSortModelChange={onSortModelChange}
                            hideFooter={false}
                            rowCount={count}
                            pagination
                            disableColumnFilter
                            rowSelectionModel={rowSelectionModel}
                            onRowSelectionModelChange={handleSelectRow}
                            disableRowSelectionOnClick={false}
                            sortingMode="server"
                            paginationMode="server"
                            storageName={GridStorageName.Vouchers}
                            slots={{
                                pagination: Pagination
                            }}
                        />
                    </Paper>
                ) : (
                    <EmptyState
                        headerText="Create vouchers to give to your customers"
                        paragraphText="Vouchers can be used to award perks to your customers or group them into audiences"
                        buttonText="Create voucher"
                        imageUrl={EmptyImage}
                        onClick={handleCreateButtonClick}
                    />
                )}
            </Box>
            <CreateVoucherModal
                isLoading={actionsLoading || loading}
                open={createModalOpen}
                onClose={closeModals}
                onSubmit={createVoucher}
            />
            <AddVoucherEffectsModal
                open={!!createdVoucher}
                voucher={createdVoucher}
                onClose={closeModals}
                onSubmit={createEffect}
            />
            <DeleteDialog
                open={!!voucherToDelete}
                onClose={closeModals}
                loading={actionsLoading}
                onDelete={deleteVoucherConfirm}
                title={`Delete "${voucherToDelete?.title}"?`}
                protectionWord="DELETE"
                content="If you delete the voucher, you won’t be able to recover it. To confirm deletion, type 'DELETE' in the text input field."
            />
        </MainLayout>
    );
}

export default VouchersPage;
