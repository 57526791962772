import {
    confirmPasswordReset,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithCustomToken,
    updatePassword
} from 'firebase/auth';
import { auth } from './firebase';

// Sign Up
export const doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    createUserWithEmailAndPassword(auth(), email, password);

// We have to somehow update auth state
// Sign In
export const doSignInWithCustomToken = async (token: string) => {
    const user = await signInWithCustomToken(auth(), token);
    return user.user.getIdToken();
};

// Sign Out
export const doSignOut = () => auth().signOut();

// Password Reset
export const doPasswordReset = (email: string) => sendPasswordResetEmail(auth(), email);

// Password Reset Confirmation
export const doConfirmPasswordReset = (code: string, password: string) =>
    confirmPasswordReset(auth(), code, password);

// Password Change
export const doPasswordUpdate = (password: string) => updatePassword(auth().currentUser, password);
