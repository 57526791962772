import React from 'react';
import { Link as NextLink, useLocation } from 'react-router-dom';
import MuiLink, { LinkProps } from '@mui/material/Link';
import clsx from 'clsx';
import { styled } from '@mui/material';

interface NextComposedProps {
    href?: string;
    className?: string;
}

// had to put any for a meanshile
// TODO: remove type casting to any
const NextComposed = React.forwardRef<NextLink, NextComposedProps>(({ href, ...other }, ref) => (
    <NextLink to={href} ref={ref as any} {...other} />
));

interface MuiLinkProps extends LinkProps {
    children?: React.ReactNode;
    underline?: 'none' | 'hover' | 'always';
    href: string;
    staticContext?: never;
    activeClassName?: string;
    className?: string;
    onClick?: ((event: React.MouseEvent<HTMLAnchorElement>) => void) &
        ((event: React.MouseEvent<HTMLElement>) => void);
    innerRef?: any;
}

function LinkElement(props: MuiLinkProps) {
    const { activeClassName, className: classNameProps, innerRef, staticContext: _, ...other } = props;

    const location = useLocation();

    const className = clsx(classNameProps, {
        [activeClassName]: location.pathname === props.href && activeClassName
    });

    return (
        <MuiLink component={NextComposed} className={className} ref={innerRef} {...other} underline="none" />
    );
}

const StyledLink = styled(LinkElement)(({ theme }) => ({
    color: theme.palette.secondary.main
}));

LinkElement.defaultProps = {
    activeClassName: 'active'
};

export const Link = React.forwardRef((props: MuiLinkProps, ref) => <StyledLink {...props} innerRef={ref} />);
