import logger from 'lib/logger';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export enum EGAEventName {
    MenuSwitch = 'menu-switch',
    CalculatedRefundAttempt = 'calculated_refund_attempt',
    CustomRefundAttempt = 'custom_refund_attempt',
    RefundAllAttempt = 'refund_all_attempt',
    StandalonePaymentRefund = 'standalone_refund_attempt',
    StandaloneRefundAllAttempt = 'standalone_refund_all_attempt',
    WhatsNewClick = 'whats_new_click',
    WhatsNewLinkClick = 'whats_new_link_click',
    PerkVisualisationTitleClick = 'perk_visualisation_title_click',
    TransactionsFilterBulk = 'transactions_filter_bulk',
    NotificationsTitleInputClick = 'notifications_title_input_click'
}

export function useGAHelpers() {
    const { tenant, claims } = useSelector((state: ApplicationState) => state.auth);
    const logUserEvent = React.useCallback(
        (eventName: string, additionalData?: Record<string, any>) => {
            try {
                if (window.gtag) {
                    window.gtag('event', eventName, {
                        user: claims?.authId,
                        merchantId: tenant?.tenantId,
                        ...additionalData
                    });
                } else {
                    logger.warn('No GTAG');
                }
            } catch (e) {
                logger.error(e.message, e);
            }
        },
        [claims?.authId, tenant?.tenantId]
    );
    return { logUserEvent };
}
