import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { DateRangePickerData } from 'lib/DateRangePickerInput';
import { MuiForm } from 'lib/Form';
import { DateRangePickerFormField } from 'lib/form/fields/DateRangePickerFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';
import { VoucherCreate } from '../models/VoucherModel';

export type VoucherDetailsFormData = Pick<
    VoucherCreate,
    'title' | 'code' | 'numGlobalRedemptions' | 'numRedemptionsPerUser'
> & {
    duration: DateRangePickerData;
};

interface IVoucherDetailsFormProps {
    onSubmit: (data: VoucherDetailsFormData, formikHelpers: FormikHelpers<VoucherDetailsFormData>) => void;
    onClose: () => void;
    isLoading?: boolean;
    initialData?: Partial<VoucherDetailsFormData>;
    title: string;
    submitText?: string;
}

const initialValues: VoucherDetailsFormData = {
    title: '',
    code: '',
    numGlobalRedemptions: 0,
    numRedemptionsPerUser: 0,
    duration: {
        startDate: undefined,
        endDate: undefined
    }
};

const reg = /^\d+$/;

const validationSchema = Yup.object().shape({
    title: Yup.string().required('This field is required.'),
    code: Yup.string()
        .required('This field is required.')
        .matches(/^[A-Za-z0-9]*$/, 'Only letters and numbers are allowed.'),
    numGlobalRedemptions: Yup.mixed()
        .required('This field is required.')
        .test('', 'This field is required.', value => reg.test(value)),
    numRedemptionsPerUser: Yup.mixed()
        .required('This field is required.')
        .test('', 'This field is required.', value => reg.test(value)),
    duration: Yup.object().required('These fields are required.')
});

export const VoucherDetailsForm: React.FC<IVoucherDetailsFormProps> = ({
    onSubmit,
    onClose,
    isLoading,
    initialData = {},
    submitText = 'Submit',
    title
}) => (
    <MuiForm
        onSubmit={onSubmit}
        initialValues={{ ...initialValues, ...initialData }}
        validationSchema={validationSchema}
    >
        {({ submitForm, isSubmitting }) => (
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color="primary" variant="h6">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextFormField name="title" label={<MuiFormLabel required>Title</MuiFormLabel>} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFormField
                            name="code"
                            label={<MuiFormLabel required>Code</MuiFormLabel>}
                            description="This is the code that your customers will use to redeem the voucher."
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFormField
                            name="numGlobalRedemptions"
                            description="The total number of times the voucher can be redeemed across all of your users."
                            type="number"
                            label={<MuiFormLabel required>Maximum Redemptions</MuiFormLabel>}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFormField
                            name="numRedemptionsPerUser"
                            type="number"
                            description="The number of times a single user can redeem the voucher."
                            label={<MuiFormLabel required>Redemptions Per User</MuiFormLabel>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateRangePickerFormField
                            name="duration"
                            description="The number of times a single user can redeem the voucher."
                            labels={{
                                startDate: <MuiFormLabel required>Start Date</MuiFormLabel>,
                                endDate: <MuiFormLabel required>End Date</MuiFormLabel>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Row gutter align="flex-end" valign="center">
                            <Button variant="outlined" onClick={onClose}>
                                Cancel
                            </Button>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting || isLoading}
                                loading={isSubmitting || isLoading}
                                onClick={submitForm}
                            >
                                {submitText}
                            </LoadingButton>
                        </Row>
                    </Grid>
                </Grid>
            </Form>
        )}
    </MuiForm>
);
