import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { EditCell } from 'lib/MuiGrid/EditCell';
import { GridStateHelper } from 'lib/MuiGrid/MuiGrid';
import { isDefined } from 'lib/typeguards';
import React from 'react';

export function useEditModal<T extends { id: string | number }>(
    gridRef: React.MutableRefObject<GridStateHelper>,
    data: T[],
    deleted?: Set<string | number>
) {
    const [open, setOpen] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState<string | number>(undefined);
    const handleCreate = React.useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = React.useCallback(() => {
        setOpen(false);
        setSelectedId(undefined);
    }, []);
    const handleEditClick = React.useCallback((id: string | number) => {
        setSelectedId(id);
        setOpen(true);
    }, []);
    const deletedSet = React.useMemo(() => {
        if (!deleted) {
            return new Set();
        }
        return deleted;
    }, [deleted]);
    const renderEditCell = React.useCallback(
        (params: GridRenderEditCellParams) => (
            <EditCell deleted={deletedSet.has(params.id)} params={params} onClick={handleEditClick} />
        ),
        [deletedSet, handleEditClick]
    );
    const selectedItem = React.useMemo(() => {
        if (!!gridRef.current && isDefined(selectedId)) {
            const item = data.find(item => item.id === selectedId);
            const change = gridRef.current.getState().changes[selectedId] || {};
            return { ...item, ...change };
        }
        return undefined;
    }, [gridRef, selectedId, data]);
    return {
        renderEditCell,
        handleClose,
        handleCreate,
        open,
        selectedItem
    };
}
