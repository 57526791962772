import * as React from 'react';
import { styled } from '@mui/material';
import { MuiModal } from 'ui/MuiModal';
import { AdjustmentSchemeEditStepper } from '../forms/AdjustmentSchemeEditStepper';
import { AdjustmentScheme } from '../adjustmentSchemeModel';

interface AdjustmentSchemeEditModalProps {
    open: boolean;
    currencySymbol: string;
    scheme: AdjustmentScheme;
    existingCodes: string[];
    onClose: () => void;
    onSuccess: (scheme: AdjustmentScheme) => void;
}

const StyledMuiModal = styled(MuiModal)(({ theme }) => ({
    width: `calc(100% - ${theme.spacing(4)})`,
    maxWidth: theme.spacing(160)
}));

export const AdjustmentSchemeEditModal: React.FC<AdjustmentSchemeEditModalProps> = ({
    open,
    scheme,
    currencySymbol,
    existingCodes,
    onClose,
    onSuccess
}) => (
    <StyledMuiModal open={open} onClose={onClose}>
        <AdjustmentSchemeEditStepper
            scheme={scheme}
            currencySymbol={currencySymbol}
            existingCodes={existingCodes}
            onSuccess={onSuccess}
            onClose={onClose}
        />
    </StyledMuiModal>
);
