import { AutoFormFields } from 'lib/LegacyForm';
import { PRESET_ACTION_ITEMS } from '../ContentModuleActions';

export const createMarketingItemForm: AutoFormFields = {
    action: {
        type: 'select',
        label: 'Action',
        options: PRESET_ACTION_ITEMS,
        validation: 'required'
    }
};
