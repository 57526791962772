import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';

export function TevalisSettingsEdit({ locationId }: IPointOfSaleSettingsProps) {
    return (
        <>
            {/* Common Only */}
            {locationId === undefined && (
                <>
                    <SettingDisplay label="CompanyId">
                        <TextFormField name="tevalisCompanyId" />
                    </SettingDisplay>
                    <SettingDisplay label="DevId">
                        <TextFormField name="tevalisDevId" />
                    </SettingDisplay>
                    <SettingDisplay label="Guid">
                        <TextFormField name="tevalisGuid" />
                    </SettingDisplay>
                    <SettingDisplay label="Guid2">
                        <TextFormField name="tevalisGuid2" />
                    </SettingDisplay>
                    <SettingDisplay label="Placeholder Item PLU">
                        <TextFormField name="tevalisPlaceholderItemPLU" />
                    </SettingDisplay>
                    <SettingDisplay label="Stock Change Webhook Enabled">
                        <SwitchFormField name="tevalisStockChangeWebhookEnabled" />
                    </SettingDisplay>
                </>
            )}
            {/* Location Only */}
            {locationId !== undefined && (
                <SettingDisplay label="Site Id">
                    <TextFormField type="number" name="tevalisSiteId" />
                </SettingDisplay>
            )}
            {/* Both */}
            <SettingDisplay label="Pre-Order Price Group Id">
                <TextFormField type="number" name="tevalisPREORDER_priceGroupId" />
            </SettingDisplay>
            <SettingDisplay label="Order to Table Price Group Id">
                <TextFormField type="number" name="tevalisORDER_TO_TABLE_priceGroupId" />
            </SettingDisplay>
            <SettingDisplay label="Pay at Table Price Group Id">
                <TextFormField type="number" name="tevalisTABLE_priceGroupId" />
            </SettingDisplay>
            <SettingDisplay label="Tab Price Group Id">
                <TextFormField type="number" name="tevalisTAB_priceGroupId" />
            </SettingDisplay>
            <SettingDisplay label="Payment Tender Type">
                <TextFormField type="string" name="tevalisTenderType" />
            </SettingDisplay>
            <SettingDisplay label="Discount Tender Type">
                <TextFormField type="string" name="tevalisDiscountTenderType" />
            </SettingDisplay>
        </>
    );
}
