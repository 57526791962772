import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { Dispute, DisputeStatus, PepperTransaction } from 'components/pepper-pay/model/PepperPay';
import { pepperPayApi } from 'components/pepper-pay/pepperPayApi';
import { CUSTOMERS_VIEW, LOCATION_VIEW, PEPPER_PAY_DISPUTES } from 'config/routes';
import { MainLayout } from 'layouts/MainLayout';
import { titleize } from 'lib/helpers';
import logger from 'lib/logger';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Throbber } from 'ui/Throbber';
import { Row } from 'ui/Flex';
import Dinero from 'dinero.js';
import { isCurrencyCode } from '@pepperhq/regions';
import { DisputeStatusChip } from 'components/pepper-pay/disputes/DisputeStatusChip';
import { CardBrandIcon } from 'lib/CardBrandIcon';
import { ResourceLink } from 'lib/ResourceLink';
import { customerApi } from 'components/customers/customerApi';
import { locationApi } from 'components/location/LocationApi';
import { PepperPayTimelineComponent } from 'components/pepper-pay/timeline/PepperPayTimeline';
import { DisputeEvidenceModal } from 'components/pepper-pay/disputes/DisputeEvidenceModal';
import { ConfirmDialog } from 'ui/dialogs/ConfirmDialog';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_DSIPUTE_ACCEPT_SUCCESS, MESSAGE_DSIPUTE_EVIDENCE_SUCCESS } from 'config/messages';
import { PepperPayPaymentDetailsBlock } from 'components/pepper-pay/PepperPayPaymentDetailsBlock';
import { PepperPayChargeBlock } from 'components/pepper-pay/PepperPayChargeBlock';
import { formatDateTime } from 'lib/timeHelpers';

export const ViewDisputePage = () => {
    const { transactionId } = useParams<{ transactionId?: string }>();
    const dispatch = useDispatch();
    const [transaction, setTransaction] = React.useState<PepperTransaction>();
    const [dispute, setDispute] = React.useState<Dispute>();
    const [loading, setLoading] = React.useState(true);
    const [evidenceOpen, setEvidenceOpen] = React.useState(false);
    const [acceptOpen, setAcceptOpen] = React.useState(false);
    const [acceptLoading, setAcceptLoading] = React.useState(false);
    React.useEffect(() => {
        const loadData = async () => {
            if (transactionId) {
                try {
                    const transactionResult = await pepperPayApi.transactions.get(transactionId);
                    if (!transactionResult.ok) {
                        throw new Error(transactionResult.body.message);
                    }
                    setTransaction(transactionResult.body);
                    const disputeResult = await pepperPayApi.disputes.get(
                        transactionResult.body.dispute?.disputeId,
                        {
                            queryParameters: [
                                { key: 'locationId', value: transactionResult.body?.locationId }
                            ]
                        }
                    );
                    if (!disputeResult.ok) {
                        throw new Error(disputeResult.body.message);
                    }
                    setDispute(disputeResult.body);
                } catch (e) {
                    logger.error(e);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };
        loadData();
    }, [transactionId]);

    const handleEvidenceOpen = React.useCallback(() => {
        setEvidenceOpen(true);
    }, []);
    const handleEvidenceClose = React.useCallback(() => {
        setEvidenceOpen(false);
    }, []);
    const handleAcceptOpen = React.useCallback(() => {
        setAcceptOpen(true);
    }, []);
    const handleAcceptClose = React.useCallback(() => {
        setAcceptOpen(false);
    }, []);
    const handleEvidenceSuccess = React.useCallback(
        (data: Dispute) => {
            setDispute(data);
            setEvidenceOpen(false);
            dispatch(enqueueSnackbar(MESSAGE_DSIPUTE_EVIDENCE_SUCCESS, { variant: 'success' }));
        },
        [dispatch]
    );
    const handleAcceptSubmit = React.useCallback(async () => {
        if (dispute) {
            setAcceptLoading(true);
            try {
                const result = await pepperPayApi.disputes.close(dispute.id, transaction?.locationId);
                if (!result.ok) {
                    throw new Error(result.body.message);
                }
                setDispute(result.body);
                dispatch(enqueueSnackbar(MESSAGE_DSIPUTE_ACCEPT_SUCCESS, { variant: 'success' }));
                setAcceptOpen(false);
            } catch (e) {
                logger.error(e);
                dispatch(enqueueSnackbar(e.message, { variant: 'error' }));
            } finally {
                setAcceptLoading(false);
            }
        }
    }, [dispatch, dispute, transaction?.locationId]);

    const isAcceptEnabled = React.useMemo(
        () =>
            dispute?.status === DisputeStatus.NeedsResponse ||
            dispute?.status === DisputeStatus.WarningNeedsResponse,
        [dispute?.status]
    );

    if (!transactionId || (!loading && !dispute)) {
        return <Redirect to={PEPPER_PAY_DISPUTES} />;
    }
    if (loading) {
        return <Throbber />;
    }
    return (
        <React.Fragment>
            <MainLayout
                breadcrumbs={[{ label: 'Disputes', url: PEPPER_PAY_DISPUTES }]}
                pageName={transactionId}
                pageDescription="View and interact with selected disputes."
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper>
                            <Box padding={2}>
                                <Row align="space-between" valign="flex-start">
                                    <Row valign="center" gutter>
                                        <Box>
                                            <Typography variant="h6">Overview</Typography>
                                            <Typography gutterBottom variant="caption">
                                                {formatDateTime(new Date(dispute.created * 1000))}
                                            </Typography>
                                        </Box>
                                        <DisputeStatusChip value={dispute.status} />
                                    </Row>
                                    <Row gutter>
                                        {isAcceptEnabled && (
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={handleAcceptOpen}
                                            >
                                                Accept Dispute
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleEvidenceOpen}
                                            disabled={
                                                dispute?.status !== DisputeStatus.NeedsResponse &&
                                                dispute?.status !== DisputeStatus.WarningNeedsResponse
                                            }
                                        >
                                            Submit Evidence
                                        </Button>
                                    </Row>
                                </Row>

                                <Box paddingY={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Typography variant="body2">Amount</Typography>
                                            <Typography variant="body1">
                                                {Dinero({
                                                    amount: transaction.total,
                                                    currency: isCurrencyCode(transaction.currency)
                                                        ? transaction.currency
                                                        : 'GBP'
                                                }).toFormat()}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Typography variant="body2">Payment Method</Typography>
                                            <Row valign="center">
                                                <CardBrandIcon
                                                    brand={transaction.paymentIntent?.paymentMethodBrand}
                                                    walletType={transaction.paymentIntent?.walletType}
                                                />
                                                <Typography variant="body1">
                                                    **** **** **** {transaction.paymentIntent?.lastFour}
                                                </Typography>
                                            </Row>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Typography variant="body2">Customer</Typography>
                                            <Typography variant="body1">
                                                <ResourceLink
                                                    id={transaction.userId}
                                                    consoleLink={CUSTOMERS_VIEW}
                                                    pathParam=":customerId"
                                                    crud={customerApi}
                                                    title="fullName"
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Typography variant="body2">Reason</Typography>
                                            <Typography variant="body1">
                                                {titleize(String(dispute.reason).replaceAll('_', ' '))}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body2">Dispute ID</Typography>
                                            <Typography variant="body1">{dispute.id}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body2">Location</Typography>
                                            <Typography variant="body1">
                                                <ResourceLink
                                                    id={transaction.locationId}
                                                    consoleLink={LOCATION_VIEW}
                                                    pathParam=":locationId"
                                                    crud={locationApi}
                                                    title="title"
                                                />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Paper>
                            <Box padding={2}>
                                <Typography variant="h6" gutterBottom>
                                    Timeline
                                </Typography>
                                <PepperPayTimelineComponent timeline={transaction.timeline} />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <PepperPayChargeBlock transaction={transaction} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <PepperPayPaymentDetailsBlock transaction={transaction} />
                    </Grid>
                </Grid>
            </MainLayout>
            <DisputeEvidenceModal
                dispute={dispute}
                onSuccess={handleEvidenceSuccess}
                onClose={handleEvidenceClose}
                open={evidenceOpen}
                locationId={transaction?.locationId}
            />
            <ConfirmDialog
                open={acceptOpen}
                onCancel={handleAcceptClose}
                onConfirm={handleAcceptSubmit}
                loading={acceptLoading}
                title="Accept dispute"
                content="Are you sure you want to accept this dispute? You will automatically lose this dispute and this action cannot be undone."
            />
        </React.Fragment>
    );
};
