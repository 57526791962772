import React from 'react';
import { MENU_MANAGER_ENRICHMENTS } from 'config/routes';
import { useHistory, useParams } from 'react-router';

export const AvailabilityRedirect: React.FC = () => {
    const history = useHistory();
    const { locationId, menuChangeId, menuId } = useParams<{
        locationId?: string;
        menuChangeId?: string;
        menuId?: string;
    }>();
    history.push(
        MENU_MANAGER_ENRICHMENTS.replace(':locationId', locationId)
            .replace(':tabId', 'weekly-availability')
            .replace(':menuChangeId', menuChangeId)
            .replace(':menuId', menuId)
    );
    return null;
};
