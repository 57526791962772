import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { segmentsApi } from 'components/audience/segmentsApi';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { v4 as uuid } from 'uuid';
import {
    MESSAGE_AUDIENCE_DELETE_ERROR,
    MESSAGE_AUDIENCE_DELETE_SUCCESS,
    MESSAGE_GRANT_PERK_TO_SEGMENT_ACCEPTED,
    MESSAGE_GRANT_PERK_TO_SEGMENT_REJECTED,
    MESSAGE_RETRY_OPERATION_ACCEPTED,
    MESSAGE_RETRY_OPERATION_REJECTED
} from 'config/messages';
import { IPageOptions } from 'lib/api/paging';
import { updateArrayItem } from 'lib/helpers';
import { useInterval } from 'lib/hooks/useInterval';
import logger from 'lib/logger';
import { isDefined } from 'lib/typeguards';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MuiTableScrollItems } from 'ui/table/MuiTable';
import { SegmentGrantPerkModal, SegmentGrantPerkModalSubmitData } from './modals/SegmentGrantPerkModal';
import { SegmentRetryOperationModal } from './modals/SegmentRetryOperationModal';
import { Audience } from './models/segmentModel';
import { SegmentOperation } from './models/segmentOperationModel';
import { SegmentDetailCardComponent } from './SegmentDetailCardComponent';
import { SegmentOperationProgressBar } from './SegmentOperationProgressBar';
import { SegmentRetryOperationButtonClickData } from './SegmentOperationTable';
import { SegmentOperationTableCardComponent } from './SegmentOperationTableCardComponent';
import { SegmentUserTableCardComponent } from './SegmentUserTableCardComponent';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { useHistory } from 'react-router-dom';
import { MARKETING_AUDIENCES } from 'config/routes';
import { SegmentMetricsOverview } from './SegmentMetricsOverview';

const pagingOptions: IPageOptions = { limit: 20, sort: '-createdAt' };

interface SegmentViewProps {
    segment: Audience;
}

export const AudienceView: React.FC<SegmentViewProps> = ({ segment }) => {
    const dispatch = useDispatch();
    const segmentId = segment._id;
    const history = useHistory();
    const [polledOperation, setPolledOperation] = React.useState(undefined);
    const [operations, setOperations] = React.useState([]);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const [nextOperationKey, setOperationNextKey] = React.useState(undefined);
    const [operationsMounted, setOperationsMounted] = React.useState(false);
    const [operationsLoading, setOperationsLoading] = React.useState(true);

    const [grantPerkModalOpen, setGrantPerkModalOpen] = React.useState(false);
    const openGrantPerkModal = React.useCallback(() => setGrantPerkModalOpen(true), []);
    const closeGrantPerkModal = React.useCallback(() => setGrantPerkModalOpen(false), []);

    const [selectedRetryOperation, setSelectedRetryOperation] = React.useState<SegmentOperation>(undefined);
    const [retryOperationModalOpen, setRetryOperationModalOpen] = React.useState(false);
    const openRetryOperationModal = React.useCallback(() => setRetryOperationModalOpen(true), []);
    const closeRetryOperationModal = React.useCallback(() => setRetryOperationModalOpen(false), []);

    React.useEffect(() => {
        async function getOperationToPoll() {
            try {
                const {
                    body: { items }
                } = await segmentsApi.getSegmentOperationsPaged(
                    segmentId,
                    { status: OperationStatus.PENDING },
                    { limit: 1, sort: '-createdAt' }
                );
                setPolledOperation(items[0]);
            } catch (error) {
                logger.error('Expected operations info but got', error);
            }
        }
        getOperationToPoll();
    }, [segmentId]);

    React.useEffect(() => {
        async function getInitialOperations() {
            try {
                const {
                    body: { items, page }
                } = await segmentsApi.getSegmentOperationsPaged(segmentId, {}, pagingOptions);
                setOperations(items);
                setOperationNextKey(page.nextKey);
            } catch (error) {
                logger.error('Expected operations info but got', error);
            }
            setOperationsLoading(false);
            setOperationsMounted(true);
        }
        getInitialOperations();
    }, [segmentId]);

    useInterval(async () => {
        if (!polledOperation) {
            return;
        }
        if (polledOperation.status !== OperationStatus.PENDING) {
            const index = operations.findIndex(item => item._id === polledOperation._id);
            setOperations(updateArrayItem(operations, index, polledOperation));
            setPolledOperation(undefined);
            return;
        }
        const { body } = await segmentsApi.getSegmentOperation(segmentId, polledOperation._id);
        setPolledOperation(body);
    }, 1500);

    const handleGrantPerkModalSubmit = React.useCallback(
        async ({ segmentId, perkId, points }: SegmentGrantPerkModalSubmitData) => {
            try {
                const { body } = await segmentsApi.grantPerkToSegment(segmentId, perkId, points, uuid());
                dispatch(
                    enqueueSnackbar(MESSAGE_GRANT_PERK_TO_SEGMENT_ACCEPTED(body.metadata.perkTitle), {
                        variant: 'success'
                    })
                );
                setPolledOperation(body);
                setOperations([body, ...operations]);
            } catch (err) {
                dispatch(
                    enqueueSnackbar(MESSAGE_GRANT_PERK_TO_SEGMENT_REJECTED(err.message), {
                        variant: 'error'
                    })
                );
            }
        },
        [operations, dispatch]
    );

    const handleRetryOperationButtonClick = React.useCallback(
        (data: SegmentRetryOperationButtonClickData) => {
            setSelectedRetryOperation(data.operation);
            openRetryOperationModal();
        },
        [setSelectedRetryOperation, openRetryOperationModal]
    );

    const handleRetryOperationDialogConfirm = React.useCallback(async () => {
        try {
            const { body } = await segmentsApi.retryOperation(
                selectedRetryOperation.entityId,
                selectedRetryOperation._id,
                uuid()
            );
            dispatch(
                enqueueSnackbar(MESSAGE_RETRY_OPERATION_ACCEPTED, {
                    variant: 'success'
                })
            );
            setPolledOperation(body);
            setSelectedRetryOperation(undefined);
            const index = operations.findIndex(item => item._id === selectedRetryOperation._id);
            setOperations([
                body,
                ...updateArrayItem(operations, index, { ...selectedRetryOperation, isRetryable: false })
            ]);
            closeRetryOperationModal();
        } catch (err) {
            dispatch(
                enqueueSnackbar(MESSAGE_RETRY_OPERATION_REJECTED(err.message), {
                    variant: 'error'
                })
            );
        }
    }, [closeRetryOperationModal, dispatch, setSelectedRetryOperation, operations, selectedRetryOperation]);

    const handleOperationTableScroll = React.useCallback(async () => {
        const {
            body: { items, page }
        } = await segmentsApi.getSegmentOperationsPaged(
            segmentId,
            {},
            { startKey: nextOperationKey, ...pagingOptions }
        );
        setOperations([...operations, ...items]);
        setOperationNextKey(page.nextKey);
    }, [operations, nextOperationKey, segmentId]);

    const handelDeleteOpen = React.useCallback(() => {
        setDeleteOpen(true);
    }, []);

    const handleDeleteClose = React.useCallback(() => {
        setDeleteOpen(false);
    }, []);

    const handleDeleteSubmit = React.useCallback(async () => {
        setDeleteLoading(true);
        const result = await segmentsApi.delete(segment?._id, { skipResponseBody: true });
        if (!result.ok) {
            setDeleteLoading(false);
            setDeleteOpen(false);
            dispatch(enqueueSnackbar(MESSAGE_AUDIENCE_DELETE_ERROR, { variant: 'error' }));
        } else {
            setDeleteOpen(false);
            dispatch(enqueueSnackbar(MESSAGE_AUDIENCE_DELETE_SUCCESS, { variant: 'success' }));
            setTimeout(() => {
                history.replace(MARKETING_AUDIENCES);
            }, 5000);
        }
    }, [dispatch, history, segment?._id]);
    const deleteDialogContent = React.useMemo(
        () =>
            // eslint-disable-next-line max-len
            `There are ${segment?.count} users in this ${segment?.title}. If you delete this audience, you will not be able to recover this audience when deleted. To confirm deletion, type 'DELETE' in the text input field.`,
        [segment?.count, segment?.title]
    );

    const operationTableScrollItems: MuiTableScrollItems = {
        isMoreItems: !!nextOperationKey && operationsMounted,
        key: nextOperationKey,
        onScroll: handleOperationTableScroll
    };

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    {isDefined(polledOperation) && (
                        <SegmentOperationProgressBar operation={polledOperation} />
                    )}
                </Grid>
                <Grid item xs={12} md={12}>
                    <SegmentDetailCardComponent
                        segment={segment}
                        onGrantPerkButtonClick={openGrantPerkModal}
                        onDelete={handelDeleteOpen}
                        disabled={deleteLoading}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SegmentUserTableCardComponent segment={segment} disabled={deleteLoading} />
                </Grid>
                <Grid item xs={12} md={9}>
                    {segment?.metrics?.length > 0 && <SegmentMetricsOverview metrics={segment.metrics} />}
                    <SegmentOperationTableCardComponent
                        operations={operations}
                        scroll={operationTableScrollItems}
                        isLoading={operationsLoading}
                        onRetryButtonClick={handleRetryOperationButtonClick}
                    />
                </Grid>
            </Grid>
            <SegmentGrantPerkModal
                open={grantPerkModalOpen}
                onSubmit={handleGrantPerkModalSubmit}
                onClose={closeGrantPerkModal}
                segmentId={segmentId}
            />
            <SegmentRetryOperationModal
                operation={selectedRetryOperation}
                open={retryOperationModalOpen}
                onConfirm={handleRetryOperationDialogConfirm}
                onCancel={closeRetryOperationModal}
            />
            <DeleteDialog
                open={deleteOpen}
                onClose={handleDeleteClose}
                onDelete={handleDeleteSubmit}
                title="Delete audienece"
                content={deleteDialogContent}
                protectionWord="DELETE"
                loading={deleteLoading}
            />
        </React.Fragment>
    );
};
