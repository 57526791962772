import { Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { Link } from 'ui/Link';

interface LinkIdCellProps {
    id: string;
    param: string;
    path: string;
    variant?: TypographyProps['variant'];
    children?: React.ReactNode;
}

export interface LinkIdCellRowModel {
    id: string;
}

export const LinkIdCell: React.FC<LinkIdCellProps> = ({ id, param, path, variant, children }) => {
    const handleStopPropagation = React.useCallback(e => {
        e.stopPropagation();
    }, []);
    if (id === 'N/A') {
        return (
            <Typography fontFamily="inherit" variant={variant}>
                {id}
            </Typography>
        );
    }
    return (
        <Link href={path.replace(`:${param}`, String(id))} onClick={handleStopPropagation}>
            {children ?? (
                <Typography fontFamily="inherit" variant={variant}>
                    {id}
                </Typography>
            )}
        </Link>
    );
};
