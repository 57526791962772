import { Box, Grid, Typography } from '@mui/material';
import Dinero from 'dinero.js';
import { isDefined, isEmptyString } from 'lib/typeguards';
import React from 'react';
import { Row } from 'ui/Flex';
import { TransactionRiskCircle } from './transactions/TransactionRiskCircle';
import { titleize } from 'lib/helpers';
import { isCurrencyCode } from '@pepperhq/regions';
import { PepperTransaction, PepperTransactionType } from './model/PepperPay';
import { CardBrandIcon } from 'lib/CardBrandIcon';
import { CUSTOMERS_VIEW, LOCATION_VIEW, ORDERS_VIEW } from 'config/routes';
import { ResourceLink } from 'lib/ResourceLink';
import { locationApi } from 'components/location/LocationApi';
import { IClerk } from 'components/clerks-access/Clerk';
import { customerApi } from 'components/customers/customerApi';
import { Link } from 'ui/Link';

interface TransactionOverviewProps {
    transaction: PepperTransaction;
    clerk?: IClerk;
    refundedAmount?: number;
}

export const TransactionOverview: React.FC<TransactionOverviewProps> = ({
    transaction,
    clerk,
    refundedAmount
}) => {
    const legacyTransaction = React.useMemo(
        () => !transaction.paymentIntent?.amount && !transaction.paymentIntent?.amountReceived,
        [transaction.paymentIntent?.amount, transaction.paymentIntent?.amountReceived]
    );
    const showPreauth = React.useMemo(
        () =>
            !legacyTransaction &&
            transaction.paymentIntent?.amount !== transaction.paymentIntent?.amountReceived,
        [legacyTransaction, transaction.paymentIntent?.amount, transaction.paymentIntent?.amountReceived]
    );
    const currency = isCurrencyCode(transaction.currency) ? transaction.currency : 'GBP';
    return (
        <Box paddingY={2}>
            <Grid container spacing={2}>
                {legacyTransaction && (
                    <Grid item xs={12} sm={3}>
                        <Typography variant="body2">Amount</Typography>
                        <Typography variant="body1">
                            {Dinero({
                                amount: transaction.total,
                                currency
                            }).toFormat()}
                        </Typography>
                    </Grid>
                )}
                {showPreauth && (
                    <Grid item xs={12} sm={3}>
                        <Typography variant="body2">Pre-auth Amount</Typography>
                        <Typography variant="body1">
                            {Dinero({
                                amount: transaction.paymentIntent?.amount,
                                currency
                            }).toFormat()}
                        </Typography>
                    </Grid>
                )}
                {!legacyTransaction && (
                    <Grid item xs={12} sm={3}>
                        <Typography variant="body2">Captured Amount</Typography>
                        <Typography variant="body1">
                            {Dinero({
                                amount: transaction.paymentIntent?.amountReceived,
                                currency
                            }).toFormat()}
                        </Typography>
                    </Grid>
                )}
                {!!refundedAmount && (
                    <Grid item xs={12} sm={3}>
                        <Typography variant="body2">Refunded</Typography>
                        <Typography variant="body1">
                            {Dinero({
                                amount: refundedAmount,
                                currency
                            }).toFormat()}
                        </Typography>
                    </Grid>
                )}
                {transaction.paymentIntent?.lastFour && (
                    <Grid item xs={12} sm={3}>
                        <Typography variant="body2">Payment Method</Typography>
                        <Row valign="center">
                            <CardBrandIcon
                                brand={transaction.paymentIntent?.paymentMethodBrand}
                                walletType={transaction.paymentIntent?.walletType}
                            />
                            <Typography variant="body1">
                                **** **** **** {transaction.paymentIntent?.lastFour}
                            </Typography>
                        </Row>
                    </Grid>
                )}
                {isDefined(transaction.order?.orderId) && (
                    <Grid item xs={12} sm={3}>
                        <Typography variant="body2">Order Number</Typography>
                        <Typography variant="body1">
                            <Link href={ORDERS_VIEW.replace(':orderId', transaction.order?.orderId)}>
                                {transaction.order?.orderId}
                            </Link>
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} sm={3}>
                    <Typography variant="body2">Type</Typography>
                    <Typography variant="body1">
                        {transaction.standalone
                            ? PepperTransactionType.Standalone
                            : PepperTransactionType.Order}
                    </Typography>
                </Grid>
                {isDefined(clerk) && (
                    <Grid item xs={12} sm={6} xl={3}>
                        <Typography variant="body2">Clerk</Typography>
                        <Typography variant="body1">{clerk.name}</Typography>
                    </Grid>
                )}
                {isDefined(transaction.tableNumber) && !isEmptyString(transaction.tableNumber) && (
                    <Grid item xs={12} sm={3}>
                        <Typography variant="body2">Table Number</Typography>
                        <Typography variant="body1">{transaction.tableNumber}</Typography>
                    </Grid>
                )}
                <Grid item xs={12} sm={3}>
                    <Typography variant="body2">Risk Evaluation</Typography>
                    <Row valign="center">
                        <TransactionRiskCircle
                            score={transaction.charge?.riskScore}
                            level={transaction.charge?.riskLevel}
                        />
                        <Typography variant="body1">
                            {titleize(transaction.charge?.riskLevel?.replaceAll('_', ' '), true)}
                        </Typography>
                    </Row>
                </Grid>
                {!!transaction.userId && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2">Customer</Typography>
                        <Typography variant="body1">
                            <ResourceLink
                                id={transaction.userId}
                                consoleLink={CUSTOMERS_VIEW}
                                pathParam=":customerId"
                                crud={customerApi}
                                title="fullName"
                            />
                        </Typography>
                    </Grid>
                )}
                {transaction.locationId && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2">Location</Typography>
                        <Typography variant="body1">
                            <ResourceLink
                                id={transaction.locationId}
                                consoleLink={LOCATION_VIEW}
                                pathParam=":locationId"
                                crud={locationApi}
                                title="title"
                            />
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                    <Typography variant="body2">Payment Intent ID</Typography>
                    <Typography variant="body1">{transaction.paymentIntent?.paymentIntentId}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};
