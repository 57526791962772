import * as React from 'react';
import * as Yup from 'yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Form } from 'formik';
import { LoadingButton } from 'ui/buttons/LoadingButton';

import { Row } from 'ui/Flex';
import { MuiForm } from 'lib/Form';
import { Option } from 'lib/types';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { CheckboxFormField } from 'lib/form/fields/CheckboxFormField';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { AdjustmentSchemeRedemptionFormStep } from './AdjustmentSchemeEditStepper';

export interface ConfigureSchemeGeneralFormData {
    title: string;
    internalTitle?: string;
    schemeId: string;
    externalPosId?: string;
    locationIds: string[];
    enabled: boolean;
}

interface AdjustmentSchemeGeneralFormProps {
    open: boolean;
    existingCodes: string[];
    initialValues?: ConfigureSchemeGeneralFormData;
    isLoading?: boolean;
    isFormValid?: boolean;
    isEdit?: boolean;
    onUpdate?: (values: {
        type: AdjustmentSchemeRedemptionFormStep.GENERAL;
        values: ConfigureSchemeGeneralFormData;
    }) => void;
    onSubmit: (data: ConfigureSchemeGeneralFormData) => void;
}

export const configureSchemeGeneralFormDefaultValues: ConfigureSchemeGeneralFormData = {
    title: '',
    internalTitle: '',
    schemeId: '',
    externalPosId: '',
    locationIds: [],
    enabled: true
};

const validationSchema = (existingCodes: string[]) =>
    Yup.object().shape({
        title: Yup.string().required('Title is required.'),
        internalTitle: Yup.string(),
        schemeId: Yup.string()
            .required('Scheme ID is required.')
            .notOneOf(existingCodes, 'Scheme ID already exists.'),
        locationIds: Yup.array().of(Yup.string()).required('Location is required.'),
        enabled: Yup.boolean().required('Enabled is required.')
    });

export const AdjustmentSchemeGeneralForm = ({
    open,
    isEdit,
    initialValues,
    existingCodes,
    isFormValid,
    isLoading,
    onSubmit,
    onUpdate
}: AdjustmentSchemeGeneralFormProps) => {
    const { allLocations } = useLocationHelpers();

    const locationOptions: Option[] = React.useMemo(() => {
        if (allLocations) {
            return allLocations
                .filter(location => location.state === 'ACTIVE')
                .map(location => ({ value: location._id, label: location.title }));
        }

        return [];
    }, [allLocations]);

    if (!open) {
        return null;
    }

    return (
        <MuiForm
            initialValues={initialValues || configureSchemeGeneralFormDefaultValues}
            validationSchema={validationSchema(existingCodes)}
            onSubmit={onSubmit}
        >
            {({ submitForm, isValid, values }) => {
                const handleUpdate = () => {
                    onUpdate({ values, type: AdjustmentSchemeRedemptionFormStep.GENERAL });
                };
                return (
                    <Form>
                        <Box maxWidth={600}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box padding={2}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="h4">1. Define</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextFormField
                                                    name="title"
                                                    label={<MuiFormLabel required>Title</MuiFormLabel>}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextFormField
                                                    name="internalTitle"
                                                    placeholder="Optional"
                                                    label="Internal Title"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextFormField
                                                    name="schemeId"
                                                    label={<MuiFormLabel required>Scheme ID</MuiFormLabel>}
                                                    disabled={isEdit}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextFormField
                                                    name="externalPosId"
                                                    placeholder="Optional"
                                                    label={<MuiFormLabel>POS Adjustment Code</MuiFormLabel>}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <SelectFormField
                                                    name="locationIds"
                                                    placeholder="123abc"
                                                    multiple
                                                    hasAllOption
                                                    selectAllOptionLabel="Select All Locations"
                                                    label="Locations"
                                                    options={locationOptions}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CheckboxFormField
                                                    name="enabled"
                                                    label="Enable & Push Live"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Row flex={1} align="flex-end" gutter>
                                        {isEdit && (
                                            <LoadingButton
                                                disabled={isLoading || !isFormValid || !isValid}
                                                loading={isLoading}
                                                // eslint-disable-next-line react/jsx-no-bind
                                                onClick={handleUpdate}
                                                color="primary"
                                                variant="outlined"
                                            >
                                                Update & Close
                                            </LoadingButton>
                                        )}
                                        <Button onClick={submitForm} color="primary" variant="contained">
                                            Next
                                        </Button>
                                    </Row>
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                );
            }}
        </MuiForm>
    );
};
