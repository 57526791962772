/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Divider, Paper, Typography, styled } from '@mui/material';
import { MainLayout } from 'layouts/MainLayout';
import {
    MERCHANT_SETTINGS_BRANDING,
    MERCHANT_SETTINGS_GLOBAL,
    MERCHANT_SETTINGS_LOCATIONS_AND_FAVOURITES,
    MERCHANT_SETTINGS_MARKETING,
    MERCHANT_SETTINGS_ORDERING,
    MERCHANT_SETTINGS_PAYMENTS,
    MERCHANT_SETTINGS_POS,
    MERCHANT_SETTINGS_TERMINAL
} from 'config/routes';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { EPaymentProvider } from 'components/settings/PaymentsSettings';
import { Permission, Resource } from '@pepperhq/auth-client';
import { DisableTenantButton } from './disableTenantButton';

interface ISettingCategory {
    icon: string;
    title: string;
    subtitle: string;
    url: string;
}

const PREFIX = 'settings-index';

const classes = {
    icon: `${PREFIX}-icon`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.icon}`]: {
        marginRight: theme.spacing(2),
        width: theme.spacing(7.5),
        height: 'auto'
    }
}));

export const SettingsPage: React.FC = () => {
    const { push } = useHistory();

    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { claims } = useSelector((state: ApplicationState) => state.auth);

    const canDisableTenant = React.useMemo(
        () => claims.hasPermission(Resource.TenantControl, Permission.write),
        [claims]
    );

    const handleSettingCategoryClicked = React.useCallback(
        (url: string) => () => {
            push(url);
        },
        [push]
    );

    const isStripe = React.useMemo(
        () => settings?.paymentProvider === EPaymentProvider.Stripe,
        [settings?.paymentProvider]
    );

    const renderSettingCategory = React.useCallback(
        (settingCategory: ISettingCategory) => (
            <Paper sx={{ p: 2, mb: 1 }} key={settingCategory.url}>
                <Box display="flex" flexDirection="row" alignItems="flex-start">
                    <img src={settingCategory.icon} className={classes.icon} />
                    <Box display="flex" flexDirection="column" flexGrow={1}>
                        <Typography variant="h6">
                            <b>{settingCategory.title}</b>
                        </Typography>
                        <Typography variant="body1">{settingCategory.subtitle}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleSettingCategoryClicked(settingCategory.url)}
                        >
                            Edit
                        </Button>
                    </Box>
                </Box>
            </Paper>
        ),
        [handleSettingCategoryClicked]
    );

    const settingCategories = React.useMemo(() => {
        const baseCategories = [
            {
                icon: `${process.env.PUBLIC_URL}/images/mobile-settings/settings.png`,
                title: 'Global Setup',
                subtitle: 'Adjust your general settings and Android/iOS specific settings.',
                url: MERCHANT_SETTINGS_GLOBAL
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/mobile-settings/phone.png`,
                title: 'Branding',
                subtitle: 'Apply your brand colours and font.',
                url: MERCHANT_SETTINGS_BRANDING
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/mobile-settings/pat.png`,
                title: 'Ordering',
                subtitle: 'Edit the ordering options your users can choose from.',
                url: MERCHANT_SETTINGS_ORDERING
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/mobile-settings/payment.png`,
                title: 'Payments',
                subtitle: 'Edit payment providers and payment options.',
                url: MERCHANT_SETTINGS_PAYMENTS
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/mobile-settings/location.png`,
                title: 'Location Discovery and Favourites',
                subtitle: 'Adjust how your users can find your venues.',
                url: MERCHANT_SETTINGS_LOCATIONS_AND_FAVOURITES
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/mobile-settings/marketing.png`,
                title: 'Marketing',
                subtitle: 'Set up a loyalty or referral scheme, or create offers and marketing campaigns.',
                url: MERCHANT_SETTINGS_MARKETING
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/mobile-settings/pointofsale.png`,
                title: 'Point of Sale',
                subtitle: 'Adjust your point of sale settings.',
                url: MERCHANT_SETTINGS_POS
            }
        ];
        if (isStripe) {
            baseCategories.push({
                icon: `${process.env.PUBLIC_URL}/images/mobile-settings/quickpad.png`,
                title: 'Quickpad',
                subtitle: 'Edit quickpad settings.',
                url: MERCHANT_SETTINGS_TERMINAL
            });
        }
        return baseCategories;
    }, [isStripe]);

    return (
        <MainLayout
            pageName="Settings"
            pageDescription="Update settings to be used in Mobile Applications, or the Pepper Web Ordering Application"
        >
            <StyledBox minWidth="500px" width="45%" maxWidth="750px">
                {settingCategories.map(renderSettingCategory)}
                {canDisableTenant && (
                    <>
                        <Divider sx={{ mt: 3, mb: 3 }} />
                        <DisableTenantButton />
                    </>
                )}
            </StyledBox>
        </MainLayout>
    );
};
