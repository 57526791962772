import { Option } from 'lib/types';

export function getSelectedOptions(options: Option[], selectedValues: string[] | undefined): Option[] {
    return (
        selectedValues?.reduce((selectedOptions, value) => {
            const match = options.find(option => option.value === value);
            if (match) {
                selectedOptions.push(match);
            }
            return selectedOptions;
        }, []) || []
    );
}

export function getSelectedOption(options: Option[], selectedValue: string | undefined): Option | undefined {
    return options.find(option => option.value === selectedValue);
}
