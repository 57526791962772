import React, { MutableRefObject } from 'react';
import { styled } from '@mui/material';
import { green, lightGreen, red } from '@mui/material/colors';
import {
    GridCellParams,
    DataGridPro,
    DataGridProProps,
    GridRowModel,
    GridRowId,
    useGridApiRef,
    GridApi,
    GridValidRowModel,
    GridEventListener
} from '@mui/x-data-grid-pro';
import { isDefined } from 'lib/typeguards';
import * as Yup from 'yup';
import { ValidationError } from 'yup';
import clsx from 'clsx';
import { ObjectShape } from 'yup/lib/object';
import { GridStorageName, useGridStateStorage } from './StateController';
import { MUI_GRID_CUSTOM_CELL_CLASSES } from './Columns';
import { deepDiffMapper } from './helpers';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { GridProSlotProps } from '@mui/x-data-grid-pro/models/gridProSlotProps';
import { CellSlot } from './CellSlot';

const PREFIX = 'MuiGrid';

const classes = {
    changed: `${PREFIX}-changed`,
    error: `${PREFIX}-error`,
    removed: `${PREFIX}-removed`,
    checkboxVisibility: `${PREFIX}-checkboxVisibility`,
    overridden: `${PREFIX}-overridden`,
    borderless: `${PREFIX}-borderless`,
    row: `${PREFIX}-row`
};

const typeOptions = ['created', 'updated', 'deleted', 'unchanged'];
function findChanges([_, value]: any) {
    if ('type' in value && typeOptions.includes(value.type)) {
        return value.type !== 'unchanged';
    }
    if (Array.isArray(value)) {
        return value.some(findChanges);
    }
    if (typeof value === 'object') {
        return Object.entries(value).some(findChanges);
    }
    return false;
}

const StyledDataGridPro = styled(DataGridPro)<StyledGridProps>(
    ({ theme, checkboxVisibility, enableRowClick, disableRowSelectionOnClick }) => ({
        [`& .${classes.changed}`]: {
            // Hate material UI for thing like this one
            backgroundColor: `${lightGreen[100]} !important`,
            color: theme.palette.getContrastText(lightGreen[100])
        },
        [`& .${classes.error}`]: {
            // Hope they will bring a bit better styling
            border: `${theme.palette.error.light} !important`,
            borderStyle: 'solid !important',
            borderWidth: '2px !important',
            '&:focus': {
                borderWidth: '1px !important'
            }
        },
        [`& .${classes.checkboxVisibility}`]: {
            '&.MuiDataGrid-cellCheckbox': {
                display: checkboxVisibility ? 'flex' : 'none'
            },
            '&.MuiDataGrid-columnHeaderCheckbox': {
                display: checkboxVisibility ? 'flex' : 'none'
            }
        },
        [`& .${classes.removed}`]: {
            // Hate material UI for thing like this one
            backgroundColor: `${red[100]} !important`,
            color: theme.palette.getContrastText(red[100]),
            borderStyle: 'none !important',
            borderWidth: '2px !important',
            '&:focus': {
                borderStyle: 'none !important'
            }
        },
        [`& .${classes.overridden}`]: {
            color: green[600],
            fontWeight: 600,
            '& > .MuiDataGrid-booleanCell': {
                color: 'inherit'
            }
        },
        [`& .${MUI_GRID_CUSTOM_CELL_CLASSES.image}`]: {
            padding: 0
        },
        [`& .${classes.borderless}`]: {
            borderRadius: 0,
            border: 'none'
        },

        // TODO: return this after prod deploy
        // [`& .${MUI_GRID_CUSTOM_CELL_CLASSES.cellOnlyHoverParent}`]: {
        //     padding: 0,
        //     [`&:has(.${MUI_GRID_CUSTOM_CELL_CLASSES.cellOnlyHover}:hover)`]: {
        //         boxShadow: theme.shadows[2]
        //     }
        // },
        // [`& .${MUI_GRID_CUSTOM_CELL_CLASSES.cellOnlyHover}`]: {
        //     height: '100%',
        //     width: '100%',
        //     display: 'block',
        //     padding: theme.spacing(0, 1.25, 0, 1.25)
        // },
        // [`& .${MUI_GRID_CUSTOM_CELL_CLASSES.cellOnlyHover}:hover`]: {
        //     boxShadow: theme.shadows[2],
        //     backgroundColor: theme.palette.primary.dark,
        //     border: 'solid 1px var(--DataGrid-rowBorderColor)',
        //     marginTop: '-1px',
        //     paddingLeft: `calc(${theme.spacing(1.25)} - 1px)`,
        //     height: 'calc(100% + 2px)',
        //     color: theme.palette.text.primary
        // },
        [`& .${classes.row}`]: {
            cursor: 'pointer',
            ...(!!enableRowClick && { userSelect: 'none' }),
            '&:hover': {
                boxShadow: theme.shadows[2]
                //     backgroundColor: alpha(theme.palette.primary.dark, 0.27)
                // },
                // [`&:has(.${MUI_GRID_CUSTOM_CELL_CLASSES.cellOnlyHover}:hover)`]: {
                //     boxShadow: 'none'
            },
            '&:active': {
                boxShadow: theme.shadows[1]
            }
        },
        '& .MuiDataGrid-cell:focus': {
            ...(!!(enableRowClick || !disableRowSelectionOnClick) && { outline: 'none' })
        }
    })
);

export enum EMuiGridStatus {
    DEFAULT = 'default',
    CHANGED = 'changed'
}

export interface GridRowValidationParams {
    id: GridRowId;
    field: string;
    value: any;
}

export interface GridStateHelper {
    getState: () => MuiGridExternalState;
    reset: () => void;
    applyChanges: (id: string | number, data: Record<string | number, any>) => void;
    createItem: (id: string | number, fieldName?: string, defaultData?: any, columnToScroll?: string) => void;
    validate: (rowModel?: GridValidRowModel) => Record<string | number, Record<string, string>> | undefined;
    getApiRef: () => GridApi;
    remove: (id: string | number) => void;
}

interface StyledGridProps extends DataGridProProps {
    stateRef?: MutableRefObject<GridStateHelper>;
    onStatusChange?: (status: EMuiGridStatus) => void;
    validationScheme?: ObjectShape;
    deleted?: Set<string | number>;
    validateRow?: (
        row: GridRowModel,
        rows: GridRowModel[],
        params?: GridRowValidationParams
    ) => Record<string, string> | undefined;
    onChange?: (rowModel: GridRowModel) => void;
    storageName?: GridStorageName;
    checkboxVisibility?: boolean;
    maxHeight?: number;
    handleCellBlur?: () => void;
    enableRowClick?: boolean;
    onCellKeyDown?: GridEventListener<'cellKeyDown'>;
}

interface MuiGridProps extends StyledGridProps {
    /**
     * @deprecated If you need to access the API, use `stateRef`.
     */
    apiRef?: never;
}
export type MuiGridChanges = Record<string | number, Record<string | number, any>>;

export interface MuiGridExternalState {
    changes: MuiGridChanges;
}

const ROW_HEIGHT = 28;
const HEADER_HEIGHT = 36;

export const MuiGrid: React.FC<MuiGridProps> = function ({
    stateRef,
    onStatusChange,
    rows: gridRows,
    validationScheme,
    deleted,
    validateRow,
    onChange,
    storageName,
    handleCellBlur,
    columns,
    slots: externalSlots,
    enableRowClick,
    onCellKeyDown,
    groupingColDef,
    ...gridProps
}) {
    const dispatch = useDispatch();
    const [changes, setChanges] = React.useState<MuiGridChanges>({});
    const [errors, setErrors] = React.useState<Record<string, Record<string, string>>>(undefined);
    const apiRef = useGridApiRef();
    const isChangeDefined = React.useCallback(
        (id: string | number, field: string) => isDefined(changes[id]) && isDefined(changes[id][field]),
        [changes]
    );
    const handleEditCellChangeCommitted = React.useCallback(
        (
            newRow: GridValidRowModel,
            oldRow: GridValidRowModel,
            params: {
                rowId: GridRowId;
            }
        ) => {
            const row: GridValidRowModel = oldRow;
            if (!!row) {
                const difference = deepDiffMapper.map(oldRow, newRow);
                const newChanges = Object.entries(difference).filter(item => findChanges(item));
                if (newChanges.length) {
                    const updatedChanges: GridValidRowModel = {};
                    newChanges.forEach(([key]: [string, any]) => {
                        updatedChanges[key] = newRow[key];
                    });
                    setChanges(currentChange => ({
                        ...currentChange,
                        [params.rowId]: { ...currentChange[params.rowId], ...updatedChanges }
                    }));

                    if (onStatusChange) {
                        onStatusChange(EMuiGridStatus.CHANGED);
                    }
                }
                if (validationScheme) {
                    try {
                        Yup.object().shape(validationScheme).validateSync(newRow, { abortEarly: false });
                        setErrors(currentErrors => {
                            if (currentErrors && currentErrors[params.rowId]) {
                                const { [params.rowId]: _, ...rest } = currentErrors;
                                return { ...rest };
                            }
                            return currentErrors;
                        });
                    } catch (e) {
                        console.warn(e);
                        setErrors(currentErrors =>
                            currentErrors
                                ? { ...currentErrors, [params.rowId]: e.message }
                                : { [params.rowId]: e.message }
                        );
                    }
                }
            }
            if (onChange) {
                onChange(newRow);
            }
            return newRow;
        },
        [onChange, validationScheme, onStatusChange]
    );
    const handleEditCellUpdateError = React.useCallback(
        (error: string) => {
            dispatch(enqueueSnackbar(error, { variant: 'error' }));
        },
        [dispatch]
    );
    const handleGetCellClassName = React.useCallback(
        ({ id, field, row }: GridCellParams) =>
            clsx({
                [classes.error]: isDefined(errors) && errors[id] && errors[id][field],
                [classes.changed]: isChangeDefined(id, field),
                [classes.removed]: deleted && deleted.has(id),
                [classes.overridden]: !(deleted && deleted.has(id)) && row._overriden?.has(field)
            }),
        [errors, isChangeDefined, deleted]
    );
    const getState = React.useCallback(
        () => ({
            changes
        }),
        [changes]
    );
    const reset = React.useCallback(() => {
        setChanges({});
        setErrors(undefined);
        if (onStatusChange) {
            onStatusChange(EMuiGridStatus.DEFAULT);
        }
    }, [onStatusChange]);
    const applyChanges = React.useCallback((id: string | number, data: Record<string | number, any>) => {
        setChanges(current => ({
            ...current,
            [id]: {
                ...current[id],
                ...data
            }
        }));
    }, []);
    const handleKeyDown = React.useCallback<GridEventListener<'cellKeyDown'>>(
        (params, event, details) => {
            const gridApi = apiRef.current;
            if (params.cellMode === 'edit') {
                switch (event.code) {
                    case 'ArrowUp': {
                        gridApi.stopCellEditMode({ id: params.id, field: params.field });
                        gridApi.stopCellEditMode(params);
                        const currentIndex = gridApi.getRowIndexRelativeToVisibleRows(params.id);
                        if (currentIndex > 0) {
                            const idToGo = gridApi.getRowIdFromRowIndex(currentIndex - 1);
                            gridApi.setCellFocus(idToGo, params.field);
                        }
                        break;
                    }
                    case 'ArrowDown': {
                        gridApi.stopCellEditMode({ id: params.id, field: params.field });
                        gridApi.stopCellEditMode(params);
                        const currentIndex = gridApi.getRowIndexRelativeToVisibleRows(params.id);
                        if (currentIndex < gridApi.getRowsCount() - 1) {
                            const idToGo = gridApi.getRowIdFromRowIndex(currentIndex + 1);
                            gridApi.setCellFocus(idToGo, params.field);
                        }
                        break;
                    }
                    case 'Tab': {
                        gridApi.stopCellEditMode({ id: params.id, field: params.field });
                        break;
                    }
                }
            } else {
                switch (event.code) {
                    case 'Tab': {
                        event.preventDefault();
                        const visibleColumns = gridApi.getVisibleColumns();
                        const currentIndex = gridApi.getColumnIndex(params.field);
                        gridApi.setCellFocus(
                            params.id,
                            currentIndex < visibleColumns.length - 1
                                ? visibleColumns[currentIndex + 1].field
                                : visibleColumns[currentIndex].field
                        );
                        break;
                    }
                    case 'F2': {
                        if (gridApi.isCellEditable(params)) {
                            gridApi.startCellEditMode(params);
                        }
                        break;
                    }
                    case 'Backspace':
                    case 'Delete': {
                        if (!isDefined(params?.value) || !gridApi.isCellEditable(params)) {
                            return;
                        }

                        event.stopPropagation();

                        gridApi.updateRows([
                            {
                                id: params.id,
                                [params.field]: ''
                            }
                        ]);
                        applyChanges(params.id, { [params.field]: '' });
                        onStatusChange?.(EMuiGridStatus.CHANGED);

                        break;
                    }
                }
            }
            if (onCellKeyDown) {
                onCellKeyDown(params, event, details);
            }
        },
        [apiRef, applyChanges, onStatusChange, onCellKeyDown]
    );
    const createItem = React.useCallback(
        (id: string | number, fieldName?: string, defaultData?: any, columnToScroll?: string) => {
            setChanges(current => ({ ...current, [id]: defaultData }));
            if (apiRef.current) {
                // A timeout to edit cell only when it is in a table
                setTimeout(() => {
                    const rowIndex = apiRef.current.getRowIndexRelativeToVisibleRows(id);
                    const colIndex = apiRef.current.getColumnIndex(columnToScroll ?? 'id');
                    apiRef.current.scrollToIndexes({ rowIndex, colIndex });
                    if (isDefined(fieldName)) {
                        apiRef.current.startCellEditMode({ id, field: fieldName });
                    }
                }, 0);
            }
        },
        [apiRef]
    );
    const getApiRef = React.useCallback(() => apiRef.current, [apiRef]);

    const validate = React.useCallback(
        (params?: GridRowValidationParams) => {
            if (!!validateRow) {
                if (params) {
                    const newErrors: Record<string | number, Record<string, string>> = { ...(errors || {}) };
                    const rowsAfterEdit = gridRows?.map(row => ({ ...row, ...(changes[row.id] || {}) }));
                    const rowChange = changes[params.id];
                    const currentItem = apiRef.current?.getRow(params.id) || {};
                    const currentItemErrors = validateRow(
                        { ...currentItem, ...rowChange },
                        rowsAfterEdit,
                        params
                    );
                    if (currentItemErrors) {
                        newErrors[params.id] = { ...(newErrors[params.id] || {}), ...currentItemErrors };
                    } else {
                        delete newErrors[params.id];
                    }
                    const errorsArray = Object.entries(newErrors);
                    if (errorsArray.length) {
                        const [[rowId, rowValues]] = errorsArray;
                        const [fieldId] = Object.keys(rowValues);
                        const rowIndex = apiRef.current?.getRowIndexRelativeToVisibleRows(rowId);
                        const colIndex = apiRef.current?.getColumnIndex(fieldId);
                        apiRef.current?.scrollToIndexes({ rowIndex, colIndex });
                        setErrors(newErrors);
                        return newErrors;
                    }
                    setErrors(undefined);
                    return undefined;
                }
                const newErrors: Record<string | number, Record<string, string>> = {};
                const rowsAfterEdit = gridRows?.map(row => ({ ...row, ...(changes[row.id] || {}) }));

                Object.entries(changes)
                    .filter(([id]) => !deleted || !deleted.has(id))
                    .forEach(([id, item]) => {
                        const currentItem = apiRef.current?.getRow(id) || {};
                        const currentItemErrors = validateRow({ ...currentItem, ...item }, rowsAfterEdit);
                        if (currentItemErrors) {
                            newErrors[id] = currentItemErrors;
                        }
                    });
                const errorsArray = Object.entries(newErrors);
                if (errorsArray.length) {
                    const [[rowId, rowValues]] = errorsArray;
                    const [fieldId] = Object.keys(rowValues);
                    const rowIndex = apiRef.current?.getRowIndexRelativeToVisibleRows(rowId);
                    const colIndex = apiRef.current?.getColumnIndex(fieldId);
                    apiRef.current?.scrollToIndexes({ rowIndex, colIndex });
                    setErrors(newErrors);
                    return newErrors;
                }
                setErrors(undefined);
                return undefined;
            }
            if (!!validationScheme) {
                const errors: Record<string | number, Record<string, string>> = {};
                const yup = Yup.object().shape(validationScheme);
                Object.entries(changes).forEach(([id, item]) => {
                    if (!deleted || !deleted.has(id)) {
                        const currentItem = apiRef.current?.getRow(id) || {};
                        try {
                            yup.validateSync({ ...currentItem, ...item }, { abortEarly: false });
                        } catch (e) {
                            errors[id] = (e as ValidationError).inner.reduce<Record<string, string>>(
                                (acc, error) => {
                                    acc[error.path] = error.message;
                                    return acc;
                                },
                                {}
                            );
                        }
                    }
                });
                const errorsArray = Object.entries(errors);
                if (errorsArray.length) {
                    const [[rowId, rowValues]] = errorsArray;
                    const [fieldId] = Object.keys(rowValues);
                    const rowIndex = apiRef.current?.getRowIndexRelativeToVisibleRows(rowId);
                    const colIndex = apiRef.current?.getColumnIndex(fieldId);
                    apiRef.current?.scrollToIndexes({ rowIndex, colIndex });
                    setErrors(errors);
                    return errors;
                }
                setErrors(undefined);
                return undefined;
            }
        },
        [changes, deleted, errors, gridRows, validateRow, validationScheme, apiRef]
    );
    const remove = React.useCallback(
        (id: string | number) => {
            apiRef.current?.updateRows([{ id, _action: 'delete' }]);
        },
        [apiRef]
    );
    const {
        handleColumnOrderChange,
        handleColumnWidthChange,
        handlePinnedColumnsChange,
        handleColumnVisibilityModelChange,
        computedColumns,
        columnVisibilityModel,
        pinnedColumns,
        formattedGroupingColDef
    } = useGridStateStorage(apiRef, storageName, columns, groupingColDef);

    React.useEffect(() => {
        if (stateRef) {
            stateRef.current = {
                getState,
                reset,
                applyChanges,
                createItem,
                getApiRef,
                validate,
                remove
            };
        }
    }, [getState, stateRef, getApiRef, reset, applyChanges, createItem, validate, remove]);
    const slotProps = React.useMemo<GridProSlotProps>(
        () => ({
            cell: {
                onBlur: handleCellBlur,
                errors
            },
            loadingOverlay: {
                variant: 'linear-progress',
                noRowsVariant: 'skeleton'
            }
        }),
        [errors, handleCellBlur]
    );
    const rows = React.useMemo(() => [...gridRows], [gridRows]);
    const gridClasses = React.useMemo(
        () => ({
            cell: classes.checkboxVisibility,
            columnHeader: classes.checkboxVisibility,
            ...(enableRowClick && { row: classes.row })
        }),
        [enableRowClick]
    );

    const internalSlots = React.useMemo(
        () => ({
            cell: CellSlot
        }),
        []
    );

    const slots = React.useMemo(
        () => ({ ...externalSlots, ...internalSlots }),
        [externalSlots, internalSlots]
    );

    return (
        <StyledDataGridPro
            getCellClassName={handleGetCellClassName}
            processRowUpdate={handleEditCellChangeCommitted}
            onProcessRowUpdateError={handleEditCellUpdateError}
            hideFooter
            rowHeight={ROW_HEIGHT}
            columnHeaderHeight={HEADER_HEIGHT}
            rows={rows}
            disableRowSelectionOnClick
            onCellKeyDown={handleKeyDown}
            apiRef={apiRef}
            classes={gridClasses}
            columns={computedColumns}
            disableMultipleRowSelection={enableRowClick}
            onColumnWidthChange={handleColumnWidthChange}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
            onPinnedColumnsChange={handlePinnedColumnsChange}
            pinnedColumns={pinnedColumns}
            columnVisibilityModel={columnVisibilityModel}
            slotProps={slotProps}
            slots={slots}
            enableRowClick={enableRowClick}
            groupingColDef={formattedGroupingColDef}
            {...gridProps}
        />
    );
};
