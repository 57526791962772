/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import { styled, Theme, Typography } from '@mui/material';
import SingleImagePlaceholder from 'static/single-image-placeholder.png';
import { ApplicationState } from 'store/store';
import { Column, Row } from 'ui/Flex';
import { IAwardsModule } from '../models/ContentModule';
import { ContentAnchorProperties } from '../models/ContentTemplate';
import { ContentModuleRenderer, getBorderRadius, MobileLayoutTheme } from '../renderContentModule';
import { MUIStyledCommonProps } from '@mui/system';

const PLACEHOLDER_IMAGE = `${process.env.MEDIA_SERVICE_URL}/console/content/template_images/awards.jpg`;
function generateAwardsImage(tenantId: string) {
    if (!tenantId) {
        return undefined;
    }
    return `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/awards_placeholder`;
}

const PREFIX = 'renderAwardsModule';

const classes = {
    title: `${PREFIX}-title`,
    secondaryAction: `${PREFIX}-secondaryAction`,
    content: `${PREFIX}-content`,
    img: `${PREFIX}-img`
};

const Root = styled(Column)(
    ({
        theme: mainTheme,
        properties,
        layoutTheme,
        awards
    }: StyledAwardsModuleProps & MUIStyledCommonProps<Theme>) => ({
        '&:hover': {
            backgroundColor: properties.isSelectable
                ? properties.isSelected
                    ? 'rgba(92, 92, 92, 0.16)'
                    : 'rgba(92, 92, 92, 0.12)'
                : '',
            transform: `${properties.isSelectable && !properties.isSelected ? 'scale(1.025)' : 'none'}`
        },
        width: '100%',
        padding: mainTheme.spacing(0.5),
        paddingTop: 0,
        margin: 0,
        marginTop: mainTheme.spacing(0.5),
        backgroundColor: properties.isSelected ? 'rgba(92, 92, 92, 0.16)' : 'initial',
        cursor: properties.isSelectable ? 'pointer' : 'inherit',
        transition: 'background-color 0.1s ease-in, transform 0.1s ease-in',
        border: `2px solid ${
            properties.isSelectable && properties.isSelected ? 'rgb(62, 141, 208)' : 'rgba(0,0,0,0)'
        }`,

        [`& .${classes.title}`]: {
            transition: 'color .15s linear',
            color: properties.titleTextColour ? properties.titleTextColour : properties.anchorTextColour,
            fontSize: layoutTheme.fontSize
        },

        [`& .${classes.secondaryAction}`]: {
            transition: 'color .15s linear',
            color: properties.tintColour,
            textAlign: 'right',
            marginRight: layoutTheme.unit / 2,
            fontSize: layoutTheme.fontSize - 2
        },

        [`& .${classes.content}`]: {
            width: '100%',
            overflow: 'hidden',
            height: awards.display?.height === 'DOUBLE' ? layoutTheme.height * 2 : layoutTheme.height,
            borderRadius: getBorderRadius(properties),
            transition: 'border-radius .15s linear',
            position: 'relative'
        },

        [`& .${classes.img}`]: {
            height: '100%',
            width: '100%',
            display: 'block',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            background: `url('${SingleImagePlaceholder}')`,
            borderRadius: getBorderRadius(properties),
            transition: 'background-color .15s linear, border-radius .15s linear',
            backgroundColor: properties.primaryActionBackgroundColour
        }
    })
);

interface AwardsModuleProps {
    properties: ContentAnchorProperties;
    theme: MobileLayoutTheme;
    awards: IAwardsModule;
}

interface StyledAwardsModuleProps {
    properties: ContentAnchorProperties;
    layoutTheme: MobileLayoutTheme;
    awards: IAwardsModule;
}

export const renderAwardsModule: ContentModuleRenderer = (awards: IAwardsModule, theme, properties, _) => (
    <AwardsModuleComponent properties={properties} theme={theme} awards={awards} />
);

function AwardsModuleComponent({ properties, awards, theme }: AwardsModuleProps) {
    const tenant = useSelector((state: ApplicationState) => state.auth.tenant);

    const handleClick = React.useCallback(() => {
        if (properties.handleClick && !properties.isSelected) {
            properties.handleClick();
        }
    }, [properties]);

    return (
        <Root onClick={handleClick} properties={properties} awards={awards} layoutTheme={theme}>
            <Row flex={1} align="space-between" valign="flex-end">
                <Typography className={classes.title}>{awards.title}</Typography>
                {awards.secondaryAction && (
                    <Typography className={classes.secondaryAction}>
                        {awards.secondaryAction.title}
                    </Typography>
                )}
            </Row>
            <div className={classes.content}>
                <img
                    className={classes.img}
                    src={
                        theme.isTemplate ? PLACEHOLDER_IMAGE : generateAwardsImage(tenant && tenant.tenantId)
                    }
                    alt=""
                />
            </div>
        </Root>
    );
}
