import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';
import { Location, LocationOrderingConfig } from 'components/location/models/LocationModel';
import { MESSAGE_NOTES_UPDATE_ERROR, MESSAGE_NOTES_UPDATE_SUCCESS } from 'config/messages';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Panel } from 'ui/Panel';
import { locationApi } from '../LocationApi';
import { LocationOrderNotesView } from './LocationOrderNotesView';

interface IProps {
    currentLocation: Location;
    setCurrentLocation?: (location: Location) => void;
}

export type LocationOrderNoteMap = Record<string, LocationOrderingConfig['note']>;

const getState = (currentLocation: Location) =>
    currentLocation.ordering?.extendedFields
        ? Object.entries(currentLocation.ordering.extendedFields).reduce<LocationOrderNoteMap>(
              (acc, [scenario, config]) => {
                  acc[scenario] = [...config.note];

                  return acc;
              },
              {}
          )
        : {};

export const LocationOrderNotes: React.FC<IProps> = ({ currentLocation, setCurrentLocation }) => {
    const dispatch = useDispatch();
    const [notesState, setNotesState] = React.useState(() => getState(currentLocation));
    const [isEditing, setEditingStatus] = React.useState(false);
    const toggleState = React.useCallback(() => {
        setEditingStatus(currentStatus => !currentStatus);
    }, []);

    const handleSubmit = React.useCallback(async () => {
        const locationId = currentLocation._id;

        const ordering = { extendedFields: {}, ...currentLocation.ordering };

        Object.entries(notesState).forEach(([scenario, notes]) => {
            if (ordering.extendedFields[scenario]) {
                ordering.extendedFields[scenario].note = notes;
            } else {
                ordering.extendedFields[scenario] = {
                    ...ordering.extendedFields[scenario],
                    note: notes
                };
            }
        });

        const response = await locationApi.update(locationId, {
            body: {
                ordering
            }
        });

        if (response.ok) {
            setEditingStatus(false);
            setCurrentLocation(response.body);
            dispatch(enqueueSnackbar(MESSAGE_NOTES_UPDATE_SUCCESS, { variant: 'success' }));
        } else {
            dispatch(
                enqueueSnackbar(
                    response.body?.httpCode === 422 ? response.body.message : MESSAGE_NOTES_UPDATE_ERROR,
                    {
                        variant: 'error'
                    }
                )
            );
        }
    }, [currentLocation._id, currentLocation.ordering, dispatch, notesState, setCurrentLocation]);
    const handleCancel = React.useCallback(() => {
        setEditingStatus(false);
        setNotesState(getState(currentLocation));
    }, [currentLocation]);

    return (
        <Panel showAction={!isEditing} title="Order Notes" onClick={toggleState} divider>
            <LocationOrderNotesView setNotes={setNotesState} isEditing={isEditing} notes={notesState} />
            {isEditing && (
                <Box width="100%" marginTop={2} display="flex" justifyContent="flex-end" alignItems="center">
                    <Box marginRight={2}>
                        <Button onClick={handleCancel} variant="outlined">
                            Cancel
                        </Button>
                    </Box>

                    <LoadingButton onClick={handleSubmit} variant="contained" color="primary">
                        Submit
                    </LoadingButton>
                </Box>
            )}
        </Panel>
    );
};
