import React from 'react';
import { Perk } from 'components/loyalty/models/PerkModel';
import { IAwardEnrichment } from 'components/loyalty/models/AwardEnrichment';
import { titleize } from 'lib/helpers';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { Paper } from '@mui/material';
import { renderLocationsCell } from './PerkLocationsCell';
import { SEARCHABLE_FIELDS } from './PerksSearchInfoTooltip';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export const PERKS_ITEMS_PER_PAGE = 100;

type PerksGridProps = {
    data: (Perk | IAwardEnrichment)[];
    isLoading: boolean;
    columnsToExclude?: string[];
    selectedRow: GridRowSelectionModel;
    searchValue: string;
    handleSelectRow: (newSelectedRow: GridRowSelectionModel) => void;
    storageName?: GridStorageName;
};

export const gridColumns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 150 },
    {
        field: 'loyaltyRewardId',
        headerName: 'Loyalty Reward ID',
        width: 200,
        renderCell: params => params.value || '-'
    },
    {
        field: 'internalTitle',
        headerName: 'Internal Title',
        width: 200,
        renderCell: params => params.value || '-'
    },
    { field: 'title', headerName: 'Title', width: 200, renderCell: params => params.value || '-' },
    {
        field: 'template',
        headerName: 'Type',
        width: 150
    },
    {
        field: 'enabled',
        headerName: 'Live',
        type: 'boolean'
    },
    {
        field: 'isGlobal',
        headerName: 'Global',
        type: 'boolean'
    },
    {
        field: 'locations',
        headerName: 'Locations',
        width: 200,
        renderCell: renderLocationsCell,
        sortable: false
    },
    {
        field: 'priority',
        headerName: 'Order',
        width: 80
    },
    {
        field: 'description',
        headerName: 'Description',
        width: 300,
        renderCell: params => params.value || '-'
    },
    {
        field: 'terms',
        headerName: 'Terms and Conditions',
        width: 300,
        renderCell: params => params.value || '-'
    },
    {
        field: 'token',
        headerName: 'Discount Code',
        width: 180,
        renderCell: params => params.value || '-'
    },
    {
        field: 'auto',
        headerName: 'Auto Redemption',
        type: 'boolean',
        width: 150
    },
    {
        field: 'multi',
        headerName: 'Multi Redemption',
        type: 'boolean',
        width: 150
    }
];

export const PerksGrid: React.FC<PerksGridProps> = ({
    data,
    isLoading,
    columnsToExclude,
    selectedRow,
    searchValue,
    handleSelectRow,
    storageName
}) => {
    const { locations } = useSelector((state: ApplicationState) => state.locations);

    const rows = React.useMemo(
        () =>
            data
                .map(item => ({
                    ...item,
                    id: item._id,
                    description: item.visualisation?.description,
                    template: titleize(item.template?.replace('_', ' '), true),
                    enabled: 'enabled' in item ? item.enabled !== false : true,
                    multi: 'points' in item && item.points.redemption === 0,
                    locations: 'locationIds' in item ? item.locationIds : [],
                    terms: 'terms' in item ? item.terms : item.visualisation?.terms
                }))
                .filter(item => {
                    if (!searchValue) {
                        return true;
                    }

                    const searchValueLower = searchValue.toLowerCase().trim();
                    const filteredLocationIds = locations
                        .filter(({ title }) =>
                            title.toString().toLocaleLowerCase().includes(searchValueLower)
                        )
                        .map(({ _id }) => _id);
                    return (
                        Object.entries(item)
                            .filter(([field]) => SEARCHABLE_FIELDS.includes(field))
                            .some(([, value]) =>
                                value ? value.toString().toLowerCase().includes(searchValueLower) : false
                            ) ||
                        (filteredLocationIds.length > 0 &&
                            (!item.locations?.length ||
                                item.locations.some(locationId => filteredLocationIds.includes(locationId))))
                    );
                }),
        [data, locations, searchValue]
    );

    const columns = React.useMemo(
        () => gridColumns.filter(column => !columnsToExclude.includes(column.field)),
        [columnsToExclude]
    );

    return (
        <Paper sx={{ height: '100%', display: 'flex' }}>
            <MuiGrid
                rows={rows}
                columns={columns}
                loading={isLoading}
                rowSelectionModel={selectedRow}
                onRowSelectionModelChange={handleSelectRow}
                disableMultipleRowSelection
                hideFooter={false}
                disableColumnFilter
                storageName={storageName}
                disableRowSelectionOnClick={false}
            />
        </Paper>
    );
};
