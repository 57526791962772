/* eslint-disable */
import React from 'react';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { Form } from 'formik';
import * as Yup from 'yup';
import { MuiForm } from 'lib/Form';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';
import { PerkFormStep } from '../PerksEditStepper';

export interface PerkGeneralFormData {
    title: string;
    order: string;
    internalTitle?: string;
    description: string;
}

interface PerkGeneralFormProps {
    onSubmit: (data: PerkGeneralFormData) => void;
    initialValues?: PerkGeneralFormData;
    open: boolean;
    isEdit?: boolean;
    isLoading?: boolean;
    isFormValid?: boolean;
    onUpdate?: (values: { type: PerkFormStep.GENERAL; values: PerkGeneralFormData }) => void;
}

export const perkGeneralDefaultFormValues: PerkGeneralFormData = {
    title: '',
    order: '',
    description: ''
};

const validationSchema = Yup.object().shape({
    title: Yup.string().required('This field is required.'),
    order: Yup.string().required('This field is required.'),
    internalTitle: Yup.string(),
    description: Yup.string().required('This field is required.')
});

export const PerkGeneralForm: React.FC<PerkGeneralFormProps> = ({
    onSubmit,
    initialValues,
    open,
    isEdit,
    isFormValid,
    isLoading,
    onUpdate
}) => {
    if (!open) {
        return null;
    }
    return (
        <MuiForm
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            initialValues={initialValues || perkGeneralDefaultFormValues}
        >
            {({ submitForm, values, isValid }) => {
                const handleUpdate = () => {
                    onUpdate({ type: PerkFormStep.GENERAL, values });
                };
                return (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box padding={3} maxWidth={600}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h4">1. Define</Typography>
                                            <Typography variant="body2">
                                                These settings are only visible to yourself
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFormField
                                                name="title"
                                                description="Set a title for use on receipts and baskets (this title will not display on the award visualisation)."
                                                label={<MuiFormLabel required>Title</MuiFormLabel>}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFormField
                                                name="order"
                                                type="number"
                                                placeholder="123"
                                                label={<MuiFormLabel required>Order</MuiFormLabel>}
                                                description="Enter a number for the position you want this perk to show in your customers award carousel."
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFormField
                                                name="internalTitle"
                                                placeholder="Optional"
                                                label="Portal Title"
                                                description="Enter an internal title to help differentiate similar perks. This title is not shown on the apps."
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFormField
                                                name="description"
                                                multiline
                                                maxRows={3}
                                                label={<MuiFormLabel required>Description</MuiFormLabel>}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Row flex={1} align="flex-end" gutter>
                                    {isEdit && !isFormValid && (
                                        <Tooltip title="You should fill all required fields on the Configure step to update this Perk.">
                                            <div>
                                                <LoadingButton
                                                    disabled
                                                    loading={isLoading}
                                                    onClick={handleUpdate}
                                                    color="primary"
                                                    variant="outlined"
                                                >
                                                    Update
                                                </LoadingButton>
                                            </div>
                                        </Tooltip>
                                    )}
                                    {isEdit && !isValid && (
                                        <Tooltip title="You should fill all fields here to update this Perk.">
                                            <div>
                                                <LoadingButton
                                                    disabled
                                                    loading={isLoading}
                                                    onClick={handleUpdate}
                                                    color="primary"
                                                    variant="outlined"
                                                >
                                                    Update
                                                </LoadingButton>
                                            </div>
                                        </Tooltip>
                                    )}
                                    {isEdit && isFormValid && isValid && (
                                        <LoadingButton
                                            disabled={isLoading}
                                            loading={isLoading}
                                            onClick={handleUpdate}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            Update & Close
                                        </LoadingButton>
                                    )}
                                    <Button onClick={submitForm} color="primary" variant="contained">
                                        Next
                                    </Button>
                                </Row>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </MuiForm>
    );
};
