import React from 'react';
import { IconButton, styled, Toolbar, Typography } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import clsx from 'clsx';
import { Row } from 'ui/Flex';
import { MenuOn } from 'ui/MenuOn';
import { MuiTableMoreItem, MuiTableToolbarItems } from './MuiTable';
import { TableSearch } from './TableSearch';
import { TableSearchOptions, TableSearchOptionsProps } from './TableSearchOptions';

const PREFIX = 'MuiTableToolbar';

const classes = {
    highlight: `${PREFIX}-highlight`,
    spacer: `${PREFIX}-spacer`,
    title: `${PREFIX}-title`,
    controlsHolder: `${PREFIX}-controlsHolder`
};

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    padding: theme.spacing(1, 1, 0, 3),
    flexDirection: 'column',
    alignItems: 'flex-start',
    [`& .${classes.highlight}`]: {
        color: theme.palette.secondary.main
    },
    [`& .${classes.spacer}`]: {
        flexGrow: 10
    },
    [`& .${classes.title}`]: {
        flex: '0 0 auto'
    },
    [`& .${classes.controlsHolder}`]: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    }
}));

interface MuiTableToolbarProps {
    numSelected: number;
    items: MuiTableToolbarItems;
    selected: string[];
    selectable: boolean;
    TableSearchOptionsProps?: TableSearchOptionsProps;
}

export const MuiTableToolbar = class extends React.Component<MuiTableToolbarProps> {
    settingsButtonRenderOn = (toggle: (event: React.MouseEvent<HTMLButtonElement>) => void) => (
        <IconButton onClick={toggle} size="large">
            <SettingsIcon />
        </IconButton>
    );
    moreButtonRenderOn = (toggle: (event: React.MouseEvent<HTMLButtonElement>) => void) => (
        <IconButton disabled={this.props.numSelected < 1} onClick={toggle} size="large">
            <MoreVert />
        </IconButton>
    );
    renderSettingsButton = (): React.ReactNode => (
        <MenuOn
            variant="withCheckbox"
            items={this.props.items.settings?.items}
            renderOn={this.settingsButtonRenderOn}
        />
    );

    renderMoreButton = (): React.ReactNode => (
        <MenuOn items={this.getMoreButtonItems()} renderOn={this.moreButtonRenderOn} />
    );

    getMoreButtonItems = (): MuiTableMoreItem[] => {
        // Items differ for single and multi table selection
        const items = this.props.items.more?.items;
        // Allows provided onClick method be called with state selection parameter
        const enrichedItems = Array.isArray(items)
            ? items.map((item: MuiTableMoreItem) => ({
                  ...item,
                  onClick: () => item.onClick(this.props.selected)
              }))
            : [];
        return enrichedItems;
    };

    render() {
        const { numSelected, selectable, items } = this.props;
        if (!selectable && !items) {
            return null;
        }
        return (
            <StyledToolbar
                disableGutters
                className={clsx({
                    [classes.highlight]: selectable && numSelected > 0
                })}
            >
                {items && (
                    <div className={classes.controlsHolder}>
                        {items.TableSearchOptionsProps && (
                            <Row valign="center">
                                <TableSearchOptions {...items.TableSearchOptionsProps} />
                            </Row>
                        )}
                        {items.search && <TableSearch {...items.search} />}
                        <div className={classes.spacer} />
                        <Row>
                            <Row valign="center">
                                {items.settings && this.renderSettingsButton()}
                                {items.renderCustomElement && items.renderCustomElement()}
                                {selectable && items.more && this.renderMoreButton()}
                            </Row>
                        </Row>
                    </div>
                )}
                {selectable && (
                    <div className={classes.title}>
                        {numSelected > 0 && (
                            <Typography color="inherit" variant="subtitle1">
                                {numSelected} selected
                            </Typography>
                        )}
                    </div>
                )}
            </StyledToolbar>
        );
    }
};
