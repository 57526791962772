import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';

export const McrSettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplay label="Company GUID">
                    <TextFormField name="mcrCompanyGuid" />
                </SettingDisplay>
                <SettingDisplay label="Shared Secret">
                    <TextFormField name="mcrSharedSecret" />
                </SettingDisplay>
                <SettingDisplay label="Account ID">
                    <TextFormField name="mcrAccountId" />
                </SettingDisplay>
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <SettingDisplay label="Location ID">
                <TextFormField name="mcrLocationId" />
            </SettingDisplay>
        )}
    </>
);
