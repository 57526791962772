import {
    BirthdayAudience,
    isUserMetricBirthdayCondition,
    UserMetricDefinition,
    UserMetricListener,
    UserMetricListenerUserBirthdayCondition,
    UserMetricListenerUserPropertyCondition
} from 'components/metrics/model';
import logger from 'lib/logger';
import { parseRelativeTimeString, relativeTimeStringToDisplay } from 'lib/relativeTimeString';
import { isDefined } from 'lib/typeguards';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export function useBirthdayAudiences(definition: UserMetricDefinition) {
    const { audiences } = useSelector((state: ApplicationState) => state.audiences);
    const audienceIds = React.useMemo(() => {
        const audienceIdList = new Set<string>();
        if (!definition) {
            return [];
        }
        definition.effects.forEach(listener => {
            if (listener.type === 'ADD_TO_SEGMENT' || listener.type === 'REMOVE_FROM_SEGMENT') {
                audienceIdList.add(listener.segmentId);
            }
        });
        return [...audienceIdList];
    }, [definition]);
    const birthdayAudiences = React.useMemo<BirthdayAudience[]>(() => {
        if (!audienceIds || !definition) {
            return [];
        }
        return audienceIds.map(id => {
            const addConditions: UserMetricListenerUserPropertyCondition[] = [];
            const removeConditions: UserMetricListenerUserPropertyCondition[] = [];
            const addListeners: UserMetricListener[] = [];
            const removeListeners: UserMetricListener[] = [];
            let fromCondition: UserMetricListenerUserBirthdayCondition;
            let toCondition: UserMetricListenerUserBirthdayCondition;
            let fromDays: number;
            let toDays: number;
            const audienceListeners = definition.effects.filter(
                listener =>
                    (listener.type === 'ADD_TO_SEGMENT' || listener.type === 'REMOVE_FROM_SEGMENT') &&
                    listener.segmentId === id
            );
            audienceListeners.forEach(listener => {
                const isAddListener = listener.type === 'ADD_TO_SEGMENT';
                if (isAddListener) {
                    addListeners.push(listener);
                } else {
                    removeListeners.push(listener);
                }
                listener.conditions?.forEach(condition => {
                    if (isUserMetricBirthdayCondition(condition) && condition.datePart === 'dayOfMonth') {
                        const { symbol, amountString, unit } = parseRelativeTimeString(condition.value);
                        if (unit === 'days') {
                            const daysValue = Number(amountString) * (symbol === '-' ? 1 : -1);
                            if (isAddListener) {
                                if (!isDefined(fromDays)) {
                                    fromCondition = condition;
                                    fromDays = daysValue;
                                }
                                if (daysValue < fromDays) {
                                    fromCondition = condition;
                                    fromDays = daysValue;
                                }
                                addConditions.push(condition);
                            } else {
                                if (!isDefined(toDays)) {
                                    toCondition = condition;
                                    toDays = daysValue;
                                }
                                if (daysValue > toDays) {
                                    toCondition = condition;
                                    toDays = daysValue;
                                }
                                removeConditions.push(condition);
                            }
                        } else {
                            logger.error('Invalid birthday schemes condition', condition.value);
                        }
                    }
                });
            });
            const audience = audiences?.find(item => item._id === id);
            const fromDaysString = relativeTimeStringToDisplay(fromCondition?.value);
            const toDaysString = relativeTimeStringToDisplay(toCondition?.value);
            return {
                id,
                name: audience?.title ?? id,
                addListeners,
                removeListeners,
                fromDaysString,
                toDaysString,
                fromDays,
                toDays
            };
        });
    }, [audienceIds, audiences, definition]);

    return {
        birthdayAudiences
    };
}
