import React from 'react';
import { DEFAULT_PAGE_LIMIT } from 'lib/hooks/usePagedData';
import logger from 'lib/logger';
import { toDotNotation, updateArrayItem } from 'lib/helpers';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { rulesApi } from 'components/rules/rulesApi';
import { getRulesFilterQuery } from 'components/rules/hooks/useRulesFilter';
import { RulesFilter } from 'components/rules/filters/RuleFilters';
import { Rule, RuleResource } from 'components/rules/rules.types';

export function useRules(filter: RulesFilter, emptyFilter: boolean) {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [endOfData, setEndOfData] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(0);
    const [pages, setPages] = React.useState([undefined]);
    const [rules, setRules] = React.useState<Rule[]>([]);
    const [internalFilter, setInternalFilter] = React.useState<RulesFilter>();
    const [noData, setNoData] = React.useState<boolean>(false);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'created',
            sort: 'desc'
        }
    ]);
    const reset = React.useCallback(() => {
        setRules([]);
        setPages([undefined]);
        setPage(0);
        setLoading(true);
        setEndOfData(false);
    }, []);
    const generateSortString = React.useCallback((model: GridSortModel) => {
        if (model && model[0]) {
            const sortDirection = model[0]?.sort ?? 'desc';
            const sortField = model[0]?.field ?? 'created';
            return `${sortDirection === 'asc' ? '' : '-'}${sortField}`;
        }
        return undefined;
    }, []);
    React.useEffect(() => {
        const getRules = async () => {
            if (!internalFilter || endOfData) {
                return;
            }
            setLoading(true);
            try {
                const queryParameters = getRulesFilterQuery(internalFilter);
                queryParameters.push({ key: 'limit', value: DEFAULT_PAGE_LIMIT.toString() });
                if (pages[page]) {
                    queryParameters.push({ key: 'startKey', value: pages[page] });
                }
                if (sortModel?.length) {
                    queryParameters.push({ key: 'sort', value: generateSortString(sortModel) });
                }
                const { body, ok } = await rulesApi.getList({
                    queryParameters,
                    headers: {
                        'x-api-version': 12
                    }
                });
                if (!ok) {
                    throw new Error(body.message);
                }
                if (!body.page.nextKey) {
                    setEndOfData(true);
                }
                setRules(currentTasks => [
                    ...currentTasks,
                    ...body.items.map((rule: RuleResource) => ({ ...rule, id: rule._id }))
                ]);
                setPages(currentPages => updateArrayItem(currentPages, page + 1, body.page.nextKey));
                setNoData(emptyFilter && rules.length === 0 && body.items.length === 0);
            } catch (error) {
                logger.error(error);
                setEndOfData(true);
                setRules([]);
            } finally {
                setLoading(false);
            }
        };
        if (
            (page === 0 && rules.length === 0) ||
            (!pages[page + 1] && rules.length < pages.length * DEFAULT_PAGE_LIMIT)
        ) {
            getRules();
        }
    }, [endOfData, internalFilter, page, pages, rules.length, sortModel, generateSortString, emptyFilter]);
    React.useEffect(() => {
        setInternalFilter(filter);
        reset();
    }, [filter, reset]);
    const onPageChange = React.useCallback(
        (pageNumber: number) => {
            if (!loading) {
                setPage(pageNumber);
            }
        },
        [loading]
    );
    const onSortModelChange = React.useCallback(
        (model: GridSortModel) => {
            setSortModel(model);
            reset();
        },
        [reset]
    );
    const count = React.useMemo(() => {
        if (endOfData) {
            return rules.length;
        }
        return -1;
    }, [endOfData, rules.length]);
    const formattedRules = React.useMemo(() => {
        if (!Array.isArray(rules)) {
            return [];
        }
        return rules
            .slice(page * DEFAULT_PAGE_LIMIT, page * DEFAULT_PAGE_LIMIT + DEFAULT_PAGE_LIMIT)
            .map(rule => {
                const effect = toDotNotation(rule.effect, 'effect', 4);
                const conditions = toDotNotation(rule.conditions, 'conditions');
                return {
                    id: rule.id,
                    created: rule.created,
                    updated: rule.updated,
                    active: rule.active,
                    ...conditions,
                    ...effect
                };
            });
    }, [page, rules]);
    return {
        rules,
        page,
        onPageChange,
        loading,
        count,
        formattedRules,
        sortModel,
        onSortModelChange,
        reset,
        noData
    };
}
