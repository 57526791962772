import React from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router';
import { MenuChange, MenuChangeSchema, MenuV } from '@pepperhq/menu-sdk';
import { MainLayout } from 'layouts/MainLayout';
import { locationApi } from 'components/location/LocationApi';
import { Location } from 'components/location/models/LocationModel';
import { menuApi } from 'components/menu/MenuApi';
import { LOCATIONS } from 'config/routes';
import logger from 'lib/logger';
import { Throbber } from 'ui/Throbber';
import { CategoryGroups } from 'components/menu/tabs/CategoryGroups';
import * as routes from 'config/routes';
import { Box, Tab, Tabs, styled } from '@mui/material';
import { Column } from 'ui/Flex';
import { CategoriesTab } from 'components/menu/tabs/Categories';
import { getAllPerks } from 'store/perks/perksActions';
import { useDispatch, useSelector } from 'react-redux';
import { ModifiersTab } from 'components/menu/tabs/Modifiers';
import { ModifierProductsTab } from 'components/menu/tabs/ModifierProducts';
import { ModifierOptionsTab } from 'components/menu/tabs/ModifierOptions';
import { ProductsTab } from 'components/menu/tabs/Products';
import { ErrorLayout } from 'layouts/ErrorLayout';
import { TagsTab } from 'components/menu/tabs/Tags';
import { DynamicImagesTab } from 'components/menu/tabs/DynamicImages';
import { TaxesTab } from 'components/menu/tabs/Taxes';
import { WeeklyAvailabilityTab } from 'components/menu/tabs/WeeklyAvailability';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { ActiveOperationContext, LocationsContext } from 'components/menu/useCopyTab';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_TEN_KITES_CONFIG_ERROR } from '../../config/messages';
import { ApplicationState } from 'store/store';
import { getMenuSummaryList } from 'store/menu-manager/menuManagerAction';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { MenuLocations } from 'components/menu/MenuLocations';
import { menusApi } from 'components/menu-manager/MenusApi';
import { useMenuGridSearchState } from 'components/menu/model/useMenuGridSearchState';

const PREFIX = 'menu-index-page';

const classes = {
    root: `${PREFIX}-root`
};

const TOP_MARGIN = '16px';

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% + ${TOP_MARGIN})`,
    marginTop: `-${TOP_MARGIN}`,
    [`& .${classes.root}`]: {
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(4),
        boxShadow: theme.shadows[1],
        height: '100%'
    }
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
    zIndex: 1,
    marginBottom: theme.spacing(1),
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    minHeight: '48px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('md')]: {
        minHeight: '32px'
    }
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minHeight: '48px',
    [theme.breakpoints.down('md')]: {
        minHeight: '32px'
    }
}));

const HeaderActions = styled('div')(() => ({
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center'
}));

interface NavButton {
    label: string;
    key: string;
}

const buttons: NavButton[] = [
    {
        label: 'Products',
        key: 'products'
    },
    {
        label: 'Categories',
        key: 'categories'
    },
    {
        label: 'Category Groups',
        key: 'groups'
    },
    {
        label: 'Modifiers',
        key: 'modifiers'
    },
    {
        label: 'Modifier Products',
        key: 'modifier-products'
    },
    {
        label: 'Modifier Options',
        key: 'modifier-options'
    },
    {
        label: 'Weekly Availability',
        key: 'weekly-availability'
    },
    {
        label: 'Taxes',
        key: 'taxes'
    },
    {
        label: 'Tags',
        key: 'tags'
    },
    {
        label: 'Dynamic Images',
        key: 'dynamic-images'
    }
];

const validTabIds = [
    'groups',
    'categories',
    'products',
    'modifiers',
    'modifier-options',
    'modifier-products',
    'taxes',
    'dynamic-images',
    'perkCodes',
    'tags',
    'weekly-availability'
];

export const MenuPage = () => {
    const { locationId, tabId, menuId, menuChangeId } = useParams<{
        locationId?: string;
        tabId?: string;
        menuId?: string;
        menuChangeId?: string;
    }>();
    const { locationIdToLocationName } = useLocationHelpers();
    const { menuSummaryList } = useSelector((state: ApplicationState) => state.menuManager);
    const { settings, loading: settingsLoading } = useSelector((state: ApplicationState) => state.settings);
    const { loading: perksLoading } = useSelector((state: ApplicationState) => state.perks);
    const dispatch = useDispatch();
    const history = useHistory();
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const [locationLoading, setLocationLoading] = React.useState(true);
    const [menuLoading, setMenuLoading] = React.useState(true);
    const [changesLoading, setChangesLoading] = React.useState(true);
    const [location, setLocation] = React.useState<Location>(null);
    const [menu, setMenu] = React.useState<MenuV<6>>(null);
    const [change, setChange] = React.useState<MenuChange>(null);
    const [activeOperationId, setActiveOperationId] = React.useState<string>(null);
    const [allLocations, setAllLocations] = React.useState<Location[]>([]);
    const setOperationId = React.useCallback((id: string) => {
        setActiveOperationId(id);
    }, []);
    const menuLocationIds = React.useMemo(() => {
        if (menuSummaryList.length > 0) {
            const menuSummary = menuSummaryList.find(summary => summary.menuChangeId === menuChangeId);

            if (menuSummary) {
                return menuSummary.locationIds;
            }
        }

        return [];
    }, [menuChangeId, menuSummaryList]);
    const menuName = React.useMemo(() => {
        if (menuSummaryList.length > 0) {
            const menuSummary = menuSummaryList.find(summary => summary.menuId === menuId);
            const locationId = menuSummary.locationIds.length > 0 ? menuSummary.locationIds[0] : '';
            const locationName = locationIdToLocationName.get(locationId) || '';
            if (menuSummary) {
                return menuSummary.menuDescription.replace(':locationName', locationName);
            }
        }

        return '';
    }, [locationIdToLocationName, menuId, menuSummaryList]);
    const menuLocationOptions = React.useMemo(() => {
        if (menuLocationIds.length > 0) {
            return menuLocationIds.map(locationId => ({
                id: locationId,
                title: locationIdToLocationName.get(locationId) || ''
            }));
        }

        return [];
    }, [locationIdToLocationName, menuLocationIds]);
    React.useEffect(() => {
        if (menuSummaryList.length === 0) {
            getMenuSummaryList(dispatch);
        }
    }, [dispatch, menuSummaryList.length]);

    const loadMenu = React.useCallback(
        async (fresh?: boolean) => {
            const handleError = (e: Error) => {
                logger.error(e.message);
                setErrorMessage(e.message);
            };
            setLocationLoading(true);
            setMenuLoading(true);
            setChangesLoading(true);
            if (fresh) {
                await menusApi.refreshMenus(locationId);
            }
            locationApi
                .get(locationId)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to load a current location');
                    }
                    setLocation(response.body);
                })
                .catch(e => {
                    handleError(e);
                })
                .finally(() => {
                    setLocationLoading(false);
                });
            menusApi
                .getMenu(menuId, locationId, [{ key: 'enriched', value: 'false' }])
                .then(response => {
                    if (!response.ok) {
                        if (response.body.code === 'E-MEN-0196') {
                            dispatch(enqueueSnackbar(MESSAGE_TEN_KITES_CONFIG_ERROR, { variant: 'error' }));
                        }
                        throw new Error('Failed to load a menu for location');
                    }
                    setMenu(response.body);
                })
                .catch(e => {
                    handleError(e);
                })
                .finally(() => {
                    setMenuLoading(false);
                });
            menuApi
                .getMenuChange(menuChangeId)
                .then(result => {
                    if (!result.ok) {
                        if (result.statusCode === 404) {
                            setChange({ schema: MenuChangeSchema.v5 });
                        } else {
                            throw new Error('Failed to load a current menu change');
                        }
                    } else {
                        setChange(result.body);
                    }
                })
                .catch(e => {
                    handleError(e);
                })
                .finally(() => {
                    setChangesLoading(false);
                });
            getAllPerks()(dispatch);
        },
        [dispatch, menuId, locationId, menuChangeId]
    );

    React.useEffect(() => {
        locationApi
            .getList()
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to get merchant locations');
                }
                setAllLocations(response.body.locations);
            })
            .catch(e => {
                logger.error(e.message);
                setErrorMessage(e.message);
            });
        menuApi
            .getAllBulkUpdateOperations()
            .then(response => {
                if (!response) {
                    throw new Error('Failed to load active operation');
                }
                const [latestOperation] = response.filter((item: any) => {
                    if (item.status === OperationStatus.PENDING || item.status === OperationStatus.RUNNING) {
                        return item;
                    }
                });
                setActiveOperationId(latestOperation?.id);
            })
            .catch(e => logger.error(e.message));
    }, []);

    const loadFreshMenu = React.useCallback(() => {
        loadMenu(true);
    }, [loadMenu]);
    React.useEffect(() => {
        if (!tabId || !validTabIds.includes(tabId)) {
            history.replace({
                pathname: routes.MENU_MANAGER_ENRICHMENTS_PRODUCTS.replace(':menuId', menuId)
                    .replace(':locationId', locationId)
                    .replace(':menuChangeId', menuChangeId)
            });
        }
    }, [history, locationId, menuChangeId, menuId, tabId]);
    React.useEffect(() => {
        loadMenu();
    }, [loadMenu]);
    const handleUpdateChange = React.useCallback((internalChange: MenuChange) => {
        setChange(internalChange);
    }, []);
    const isLoading = React.useMemo(
        () => menuLoading || changesLoading || settingsLoading || locationLoading,
        [changesLoading, locationLoading, menuLoading, settingsLoading]
    );
    const handleChange = React.useCallback(
        (_: never, newValue: string) => {
            history.push({
                pathname: routes.MENU_MANAGER_ENRICHMENTS.replace(':menuId', menuId)
                    .replace(':locationId', locationId)
                    .replace(':menuChangeId', menuChangeId)
                    .replace(':tabId', newValue)
            });
        },
        [history, locationId, menuChangeId, menuId]
    );
    const renderButton = React.useCallback(
        ({ label, key }) => <StyledTab value={key} key={`tab-${key}`} label={<span>{label}</span>} />,
        []
    );
    const [searchValues, onSearchChange] = useMenuGridSearchState();
    // exclude current location as a copyTo destination
    const locationsExcludingCurrent = React.useMemo(
        () => allLocations.filter(locationItem => locationItem._id !== locationId),
        [allLocations, locationId]
    );
    const baseTabProps = {
        menu,
        settings,
        loadFreshMenu,
        menuChangeId,
        change,
        onSearchChange,
        onUpdate: handleUpdateChange
    };
    if (errorMessage) {
        return <ErrorLayout title={errorMessage} fallbackTitle="Locations" fallbackUrl={LOCATIONS} />;
    }
    if (!location || !menu || isLoading || perksLoading) {
        return <Throbber />;
    }
    return (
        <StyledBox>
            <MainLayout
                headerActions={
                    <HeaderActions>
                        {menuLocationOptions.length > 0 && (
                            <MenuLocations locationOptions={menuLocationOptions} menuName={menuName} />
                        )}
                    </HeaderActions>
                }
                breadcrumbs={[{ label: 'Menus', url: routes.MENU_MANAGER }]}
                pageName={menuName}
                noScroll
            >
                <Column flex={1} className={classes.root}>
                    <StyledTabs
                        value={tabId}
                        onChange={handleChange}
                        variant="scrollable"
                        indicatorColor="primary"
                    >
                        {buttons.map(renderButton)}
                    </StyledTabs>
                    <LocationsContext.Provider value={locationsExcludingCurrent}>
                        <ActiveOperationContext.Provider value={{ activeOperationId, setOperationId }}>
                            <Switch>
                                <Route path={routes.MENU_MANAGER_ENRICHMENTS_PRODUCTS}>
                                    <ProductsTab {...baseTabProps} search={searchValues.products} />
                                </Route>
                                <Route path={routes.MENU_MANAGER_ENRICHMENTS_CATEGORIES}>
                                    <CategoriesTab {...baseTabProps} search={searchValues.categories} />
                                </Route>
                                <Route path={routes.MENU_MANAGER_ENRICHMENTS_CATEGORY_GROUPS}>
                                    <CategoryGroups {...baseTabProps} search={searchValues.categoryGroups} />
                                </Route>
                                <Route path={routes.MENU_MANAGER_ENRICHMENTS_MODIFIERS}>
                                    <ModifiersTab {...baseTabProps} search={searchValues.modifiers} />
                                </Route>
                                <Route path={routes.MENU_MANAGER_ENRICHMENTS_MODIFIER_PRODUCTS}>
                                    <ModifierProductsTab
                                        {...baseTabProps}
                                        search={searchValues.modifierProducts}
                                    />
                                </Route>
                                <Route path={routes.MENU_MANAGER_ENRICHMENTS_MODIFIER_OPTIONS}>
                                    <ModifierOptionsTab
                                        {...baseTabProps}
                                        search={searchValues.modifierOptions}
                                    />
                                </Route>
                                <Route path={routes.MENU_MANAGER_ENRICHMENTS_TAGS}>
                                    <TagsTab {...baseTabProps} search={searchValues.tags} />
                                </Route>
                                <Route path={routes.MENU_MANAGER_ENRICHMENTS_DYNAMIC_IMAGES}>
                                    <DynamicImagesTab {...baseTabProps} search={searchValues.dynamicImages} />
                                </Route>
                                <Route path={routes.MENU_MANAGER_ENRICHMENTS_TAXES}>
                                    <TaxesTab {...baseTabProps} search={searchValues.taxes} />
                                </Route>
                                <Route path={routes.MENU_MANAGER_ENRICHMENTS_WEEKLY_AVAILABILITY}>
                                    <WeeklyAvailabilityTab
                                        {...baseTabProps}
                                        search={searchValues.weeklyAvailability}
                                    />
                                </Route>
                            </Switch>
                        </ActiveOperationContext.Provider>
                    </LocationsContext.Provider>
                </Column>
            </MainLayout>
        </StyledBox>
    );
};
