import React from 'react';
import { Box, Paper, Stack, TablePagination } from '@mui/material';
import { Notification } from './models/Notification';
import { GridColDef, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid-pro';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { renderNotificationStatusCell } from './NotificationStatusCell';
import { useNotifications } from './hooks/useNotifications';
import { DEFAULT_PAGE_LIMIT } from 'lib/hooks/usePagedData';
import { MuiTheme } from 'config/theme';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { MARKETING_NOTIFICATIONS_VIEW } from 'config/routes';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { CustomerSegment } from 'components/customers/models/Customer';
import { Location } from 'components/location/models/LocationModel';
import { useNotificationsFilter } from 'components/notification/hooks/useNotificationsFilter';
import { NotificationsFilters } from 'components/notification/filters/NotificationsFilters';
import { MultiSelectGridColumn } from 'lib/MuiGrid/Columns';
import { defaultDateTimeValueFormatter } from 'lib/MuiGrid/helpers';
import { deepLinkValueFormatter } from 'components/notification/helpers';
import { Row } from 'ui/Flex';
import { useHistory } from 'react-router-dom';

interface NotificationsTableProps {
    isLoading: boolean;
    onCreateClick: (notification?: Notification<string>) => void;
    actionedNotification: { id: string; status: 'created' } | null;
    segments: CustomerSegment[];
    locations: Location[];
}

export const NotificationsTable: React.FC<NotificationsTableProps> = ({
    isLoading,
    onCreateClick,
    actionedNotification,
    segments,
    locations
}) => {
    const history = useHistory();
    const [filter, onFilterChange, isEmptyFilter] = useNotificationsFilter();
    const {
        page,
        onPageChange,
        loading,
        count,
        formattedNotifications,
        sortModel,
        onSortModelChange,
        notifications,
        reset,
        noData
    } = useNotifications(filter, isEmptyFilter);
    const locationTitleMap = React.useMemo(
        () => locations?.reduce((acc, l) => ({ ...acc, [l._id]: l.title || l._id }), {}),
        [locations]
    );
    const segmentTitleMap = React.useMemo(
        () => segments?.reduce((acc, s) => ({ ...acc, [s._id]: s.title || s._id }), {}),
        [segments]
    );
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'ID',
                field: 'id',
                disableReorder: true,
                disableExport: true,
                sortable: false,
                width: 240
            },
            {
                headerName: 'Sent On',
                field: 'createdAt',
                sortable: true,
                type: 'dateTime',
                width: 180,
                valueGetter: defaultDateTimeValueFormatter
            },
            {
                headerName: 'Status',
                field: 'status',
                sortable: false,
                width: 90,
                renderCell: renderNotificationStatusCell
            },
            {
                headerName: 'Title',
                field: 'title',
                sortable: false,
                width: 180
            },
            {
                headerName: 'Message',
                field: 'message',
                sortable: false,
                width: 360
            },
            {
                headerName: 'Tag',
                field: 'tag',
                sortable: true,
                width: 180
            },
            {
                headerName: 'Locations',
                field: 'locationIds',
                sortable: false,
                width: 220,
                ...MultiSelectGridColumn(locationTitleMap)
            },
            {
                headerName: 'Audiences',
                field: 'segmentIds',
                sortable: false,
                width: 220,
                ...MultiSelectGridColumn(segmentTitleMap)
            },
            {
                headerName: 'Deep Link',
                field: 'deepLink',
                sortable: false,
                width: 90,
                valueGetter: deepLinkValueFormatter
            }
        ],
        [locationTitleMap, segmentTitleMap]
    );
    const [notification, setNotification] = React.useState<Notification<string> | undefined>();
    const [selection, setSelection] = React.useState<GridRowSelectionModel>([]);
    const handleSelectionChange = React.useCallback(
        (selectionModel: GridRowSelectionModel) => {
            const id =
                selectionModel.length && selectionModel.at(-1) !== notification?._id
                    ? selectionModel.at(-1)
                    : null;
            setSelection(id ? [id] : []);
            const found = id ? notifications?.find(n => n._id === id) : undefined;
            setNotification(found);
        },
        [notification?._id, notifications]
    );
    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            handleSelectionChange([]);
            onPageChange(page);
        },
        [handleSelectionChange, onPageChange]
    );
    const handleSortChange = React.useCallback(
        (model: GridSortModel) => {
            handleSelectionChange([]);
            onSortModelChange(model);
        },
        [handleSelectionChange, onSortModelChange]
    );

    React.useEffect(() => {
        setSelection([]);
        setNotification(undefined);
        reset();
    }, [actionedNotification, reset]);

    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={count}
                onPageChange={handlePageChange}
                page={page}
                disabled={isLoading || loading}
                rowsPerPage={DEFAULT_PAGE_LIMIT}
                rowsPerPageOptions={[DEFAULT_PAGE_LIMIT]}
                component="div"
                sx={{
                    '&:last-child': {
                        paddingRight: MuiTheme.spacing(1)
                    }
                }}
            />
        ),
        [count, handlePageChange, page, isLoading, loading]
    );
    const NoRowsOverlay = React.useCallback(
        () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
                {isEmptyFilter && !isLoading && !loading && !count
                    ? 'No notifications sent'
                    : 'No notifications found. Please adjust your filter'}
            </Stack>
        ),
        [count, isEmptyFilter, isLoading, loading]
    );

    const handleCreateClick = React.useCallback(() => {
        onCreateClick(notification);
    }, [notification, onCreateClick]);

    const handleViewClick = React.useCallback(() => {
        if (notification) {
            history.push(MARKETING_NOTIFICATIONS_VIEW.replace(':notificationId', notification._id));
        }
    }, [history, notification]);

    React.useEffect(() => {
        if (noData) {
            handleCreateClick();
        }
    }, [handleCreateClick, noData]);

    return (
        <React.Fragment>
            <Box paddingY={1}>
                <Row align="space-between" valign="flex-end">
                    <NotificationsFilters filter={filter} onChange={onFilterChange} />
                    <Row gutter>
                        <LoadingButton
                            variant="contained"
                            size="small"
                            onClick={handleCreateClick}
                            loading={isLoading}
                            disabled={isLoading || !!notification}
                        >
                            Create & Send
                        </LoadingButton>
                        <LoadingButton
                            variant="contained"
                            size="small"
                            onClick={handleViewClick}
                            loading={isLoading}
                            disabled={isLoading || !notification}
                        >
                            View
                        </LoadingButton>
                        <LoadingButton
                            variant="outlined"
                            size="small"
                            onClick={handleCreateClick}
                            loading={isLoading}
                            disabled={isLoading || !notification}
                        >
                            Resend
                        </LoadingButton>
                    </Row>
                </Row>
            </Box>
            <Paper sx={{ flex: 1, display: 'flex', minHeight: 0 }}>
                <MuiGrid
                    rows={formattedNotifications}
                    columns={columns}
                    hideFooter={false}
                    rowCount={count}
                    pagination
                    storageName={GridStorageName.Notifications}
                    disableColumnFilter
                    paginationMode="server"
                    loading={loading}
                    sortModel={sortModel}
                    onSortModelChange={handleSortChange}
                    sortingMode="server"
                    disableMultipleRowSelection
                    disableMultipleColumnsSorting
                    disableRowSelectionOnClick={false}
                    rowSelectionModel={selection}
                    onRowSelectionModelChange={handleSelectionChange}
                    slots={{
                        pagination: Pagination,
                        noRowsOverlay: NoRowsOverlay
                    }}
                />
            </Paper>
        </React.Fragment>
    );
};
