import { updateArrayItem } from 'lib/helpers';
import logger from 'lib/logger';
import React from 'react';
import { Voucher } from '../models/VoucherModel';
import { getVouchersFilterQuery, VouchersFilter } from './useVouchersFilter';
import { voucherApi } from '../voucherApi';

export const VOUCHERS_PAGE_LIMIT = 100;

export function useVouchersData(filter: VouchersFilter) {
    const [loading, setLoading] = React.useState(true);
    const [endOfData, setEndOfData] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [pages, setPages] = React.useState([undefined]);
    const [vouchers, setVouchers] = React.useState<Voucher[]>([]);
    const [internalFilter, setInternalFilter] = React.useState<VouchersFilter>();

    const reset = React.useCallback(() => {
        setVouchers([]);
        setPages([undefined]);
        setPage(0);
        setLoading(true);
        setEndOfData(false);
    }, []);
    React.useEffect(() => {
        const getVouchers = async () => {
            if (!internalFilter || endOfData) {
                return;
            }
            setLoading(true);
            try {
                const queryParameters = getVouchersFilterQuery(internalFilter);
                queryParameters.push({ key: 'limit', value: String(VOUCHERS_PAGE_LIMIT) });
                if (filter.s) {
                    queryParameters.push({
                        key: 'sort',
                        value: filter.s
                    });
                }
                if (pages[page]) {
                    queryParameters.push({ key: 'startKey', value: pages[page] });
                }

                const { body, ok } = await voucherApi.getList({
                    queryParameters
                });
                if (!ok) {
                    throw new Error(body.message);
                }
                if (!body.page.nextKey) {
                    setEndOfData(true);
                }
                setVouchers(current => [
                    ...current,
                    ...body.items.map((item: Voucher) => ({ ...item, id: item._id }))
                ]);
                setPages(currentPages => updateArrayItem(currentPages, page + 1, body.page.nextKey));
            } catch (e) {
                logger.error(e);
                setVouchers([]);
            }
            setLoading(false);
        };
        if (
            (page === 0 && vouchers.length === 0) ||
            (!pages[page + 1] && vouchers.length < pages.length * VOUCHERS_PAGE_LIMIT)
        ) {
            getVouchers();
        }
    }, [endOfData, filter.s, internalFilter, page, pages, vouchers.length]);
    React.useEffect(() => {
        setInternalFilter(filter);
        reset();
    }, [filter, reset]);
    const onPageChange = React.useCallback(
        (pageNumber: number) => {
            if (!loading) {
                setPage(pageNumber);
            }
        },
        [loading]
    );
    const count = React.useMemo(() => {
        if (endOfData) {
            return vouchers.length;
        }
        return -1;
    }, [endOfData, vouchers.length]);
    const pagedVouchers = React.useMemo(() => {
        if (!Array.isArray(vouchers)) {
            return [];
        }
        return vouchers.slice(page * VOUCHERS_PAGE_LIMIT, page * VOUCHERS_PAGE_LIMIT + VOUCHERS_PAGE_LIMIT);
    }, [vouchers, page]);
    return {
        vouchers,
        page,
        onPageChange,
        loading,
        count,
        pagedVouchers,
        resetVouchers: reset
    };
}
