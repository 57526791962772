import { decimalValueFormatter, numberValueFormatter } from 'lib/MuiGrid/helpers';
import { startCase } from 'lodash';

export interface AudienceMetric {
    definitionId: string;
    metricId: string;
    value: number;
    updatedAt: string;
}

const audienceMetricIdRegex =
    /(ATV|TotalSpend|OrderCount)_(Today|Yesterday|WeekToDate|LastWeek|MonthToDate|LastMonth|QuarterToDate|LastQuarter|YearToDate|LastYear)/;

export enum AudienceMetricType {
    ATV = 'ATV',
    TotalSpend = 'TotalSpend',
    OrderCount = 'OrderCount'
}

function isAudienceMetricType(value: string): value is AudienceMetricType {
    return value in AudienceMetricType;
}

export enum AudienceMetricTimeWindow {
    Today = 'Today',
    Yesterday = 'Yesterday',
    WeekToDate = 'WeekToDate',
    LastWeek = 'LastWeek',
    MonthToDate = 'MonthToDate',
    LastMonth = 'LastMonth',
    QuarterToDate = 'QuarterToDate',
    LastQuarter = 'LastQuarter',
    YearToDate = 'YearToDate',
    LastYear = 'LastYear'
}

function isAudienceMetricTimeWindow(value: string): value is AudienceMetricTimeWindow {
    return value in AudienceMetricTimeWindow;
}

interface AudienceMetricComponents {
    metricType: AudienceMetricType;
    metricTimeWindow: AudienceMetricTimeWindow;
}

export function getAudienceMetricIdComponents(metricId: string): AudienceMetricComponents {
    const result = audienceMetricIdRegex.exec(metricId);
    if (result) {
        const [_, metricType, metricTimeWindow] = result;
        if (isAudienceMetricType(metricType) && isAudienceMetricTimeWindow(metricTimeWindow)) {
            return { metricType, metricTimeWindow };
        }
    }
    return { metricType: undefined, metricTimeWindow: undefined };
}

export function getMetricFormatting(metricId: string) {
    const { metricType } = getAudienceMetricIdComponents(metricId);
    return {
        title: startCase(metricId).replace('Atv', 'ATV').replaceAll(' To', ' to'),
        valueFormatter:
            metricType === AudienceMetricType.OrderCount ? numberValueFormatter : decimalValueFormatter
    };
}

export type AudienceMetricId = `${AudienceMetricType}_${AudienceMetricTimeWindow}`;

export interface AudienceMetricProperties extends AudienceMetric, AudienceMetricComponents {}

export type AudienceMetricsRecord = {
    [k in AudienceMetricId]?: number;
};

interface ObjectWithAudienceMetrics {
    metrics?: AudienceMetric[];
}

export function getAudienceMetricProperties(audience: ObjectWithAudienceMetrics): AudienceMetricsRecord {
    const record: AudienceMetricsRecord = {};
    if (Array.isArray(audience?.metrics)) {
        audience.metrics.forEach(metric => {
            const { metricType, metricTimeWindow } = getAudienceMetricIdComponents(metric.metricId);
            if (metricType && metricTimeWindow) {
                const audienceMetricId: AudienceMetricId = `${metricType}_${metricTimeWindow}`;
                record[audienceMetricId] = metric.value;
            }
        });
    }
    return record;
}
