import { ActionType } from 'components/actions/models/Action';
import { EPrimaryPlatform } from 'components/customers/models/Customer';
import { RelativeTimeString, validateRelativeTimeString } from 'lib/relativeTimeString';
import { EPremiumLoyaltyScheme } from '../hooks/useUserMetricDefinition';
import { isString } from 'lib/typeguards';

// percentage expressed as integer e.g 10% is value: 10
export type UserMetricListenerCondition = {
    _id: string;
} & (
    | {
          metricId: string;
          operator:
              | 'EQUAL'
              | 'NOT_EQUAL'
              | 'GREATER_THAN'
              | 'LESS_THAN'
              | 'IS_WITHIN_TOP_PERCENTAGE'
              | 'IS_NOT_WITHIN_TOP_PERCENTAGE';
          value: number;
      }
    | {
          userProperty: 'hasAgreedToReceiveMarketing' | 'hasAgreedToShareData';
          operator: 'EQUAL' | 'NOT_EQUAL';
          value: boolean;
      }
    | {
          userProperty: 'primaryPlatform';
          operator: 'EQUAL' | 'NOT_EQUAL';
          value: EPrimaryPlatform;
      }
    | {
          userProperty: 'birthdate';
          operator: 'EQUAL' | 'NOT_EQUAL' | 'GREATER_THAN' | 'LESS_THAN';
          datePart: 'year' | 'month' | 'dayOfYear' | 'dayOfMonth';
          value: Date | string;
      }
    | {
          userProperty: 'gender';
          operator: 'EQUAL' | 'NOT_EQUAL';
          value: 'MALE' | 'FEMALE';
      }
    | {
          userProperty: 'favouriteLocations';
          operator: 'CONTAINS' | 'NOT_CONTAINS';
          value: string;
      }
    | {
          operator: 'IS_IN_SEGMENT' | 'IS_NOT_IN_SEGMENT';
          value: string;
      }
);

export enum EMetricType {
    Spend = 'SPEND',
    PointsEarned = 'POINTS_EARNED',
    PointsBurned = 'POINTS_BURNED',
    TipsGiven = 'TIPS_GIVEN',
    ActionType = 'ACTION_TYPE'
}

export type UserMetric = (
    | {
          type: EMetricType.TipsGiven | ActionType;
      }
    | {
          type: EMetricType.Spend;
          includeTips?: boolean;
      }
    | {
          type: EMetricType.PointsEarned | EMetricType.PointsBurned;
          perkId: string;
      }
) & {
    name: string;
    displayName: string;
    timeRange:
        | {
              windowStartOffsetMins: number;
              windowEndOffsetMins?: number;
              gt?: Date;
              lt?: Date;
          }
        | {
              gt: Date;
              lt?: Date;
          };
};

export interface UserMetricResource {
    timeRange: {
        windowStartOffsetMins?: number;
        windowEndOffsetMins?: number;
        gt?: Date;
        lt?: Date;
    };
    name: string;
    displayName: string;
    includeTips?: boolean;
    perkId?: string;
    type: string;
}

// In order for listener to work we still need at least one defined metric
export type UserMetricListener = {
    _id: string;
    conditions?: UserMetricListenerCondition[];
} & (
    | {
          type: 'ADD_TO_SEGMENT' | 'REMOVE_FROM_SEGMENT';
          segmentId: string;
      }
    | {
          type: 'TRIGGER_NOTIFICATION';
          message: string;
          isProcessingMessage: boolean;
          counter: {
              max: number;
              resetsAfterMins?: number;
          };
      }
    | {
          type: 'TRIGGER_SMS';
          message: string;
          isProcessingMessage: boolean;
          counter: {
              max: number;
              resetsAfterMins?: number;
          };
      }
    | {
          type: 'TRIGGER_EMAIL';
          subject: string;
          body: string;
          bodyMarkupLanguage?: 'HTML' | 'MJML';
          isProcessingMessage: boolean;
          counter: {
              max: number;
              resetsAfterMins?: number;
          };
      }
    | {
          type: 'GRANT_PERK' | 'REVOKE_PERK';
          perkId: string;
          points?: number;
          counter: {
              max: number;
              resetsAfterMins?: number;
          };
      }
);

export type UserMetricOrUserPropertyQueryField<T> = { or: T[] };

type UserMetricUserPropertyComparators<T> = {
    eq?: T;
    gte?: T;
    lte?: T;
    gt?: T;
    lt?: T;
};

export type UserMetricUserPropertyTimeUnitComparators = RequireAtLeastOne<{
    year: number | UserMetricUserPropertyComparators<number | RelativeTimeString>;
    month: number | UserMetricUserPropertyComparators<number | RelativeTimeString>;
    dayOfMonth: number | UserMetricUserPropertyComparators<number | RelativeTimeString>;
    dayOfYear: number | UserMetricUserPropertyComparators<number | RelativeTimeString>;
}>;

export type UserMetricArrayUserPropertyQueryField<T> = RequireAtLeastOne<{
    in: T[];
}>;

type TypeOrComparatorArray<T> =
    | T
    | {
          or: T[];
      };

export interface UserMetricUserPropertyQuery {
    birthdate?: TypeOrComparatorArray<UserMetricUserPropertyTimeUnitComparators>;
    created?: TypeOrComparatorArray<UserMetricUserPropertyTimeUnitComparators>;
    hasAgreedToReceiveMarketing?: TypeOrComparatorArray<UserMetricUserPropertyComparators<boolean>>;
    hasAgreedToShareData?: TypeOrComparatorArray<UserMetricUserPropertyComparators<boolean>>;
    primaryPlatform?: TypeOrComparatorArray<UserMetricUserPropertyComparators<string>>;
}

export interface UserMetricDefinition {
    _id: string;
    tenantId: string;
    scheduled: boolean;
    realtime: boolean;
    name: string;
    displayName: string;
    tags?: string[];
    recomputeOn?: ActionType[];
    query?: UserMetricUserPropertyQuery;
    metrics?: { [id: string]: UserMetric };
    effects: UserMetricListener[];
    updatedAt?: string;
}

export enum EPremiumLoyaltyState {
    INACTIVE = 'INACTIVE',
    SCHEDULED = 'SCHEDULED',
    REALTIME = 'REALTIME',
    SCHEDULED_AND_REALTIME = 'SCHEDULED + REALTIME',
    ACTIVE = 'ACTIVE'
}

// TODO: move to union types
export interface EditPremiumLoyaltyForm {
    name: string;
    type: EPremiumLoyaltyScheme;
}

export type UserMetricListenerUserPropertyCondition = {
    _id: string;
    userProperty: 'birthdate';
    operator: 'EQUAL' | 'NOT_EQUAL' | 'GREATER_THAN' | 'LESS_THAN';
    value: Date | number | string;
};

export interface UserMetricListenerUserBirthdayCondition {
    _id: string;
    userProperty: 'birthdate';
    operator: 'EQUAL';
    datePart: 'year' | 'month' | 'dayOfYear' | 'dayOfMonth';
    value: RelativeTimeString;
}

export function isUserMetricBirthdayCondition(
    condition: UserMetricListenerCondition
): condition is UserMetricListenerUserBirthdayCondition {
    return (
        'userProperty' in condition &&
        condition.userProperty === 'birthdate' &&
        isString(condition.value) &&
        condition.operator === 'EQUAL' &&
        validateRelativeTimeString(condition.value)
    );
}

export function getBirthdayConditionString(value: number | Date | string) {
    return String(value);
}

export interface PremiumLoyaltyAudience {
    name: string;
    id: string;
    addConditionsString: string;
    removeConditionsString: string;
    addListeners: UserMetricListener[];
    removeListeners: UserMetricListener[];
    missingConditions?: boolean;
}

export interface BirthdayAudience {
    name: string;
    id: string;
    addListeners: UserMetricListener[];
    removeListeners: UserMetricListener[];
    fromDays: number;
    toDays: number;
    fromDaysString: string;
    toDaysString: string;
}

export enum EPremiumLoyaltyConditionType {
    Metric = 'METRIC',
    UserProperty = 'USER_PROPERTY',
    Audience = 'AUDIENCE'
}

export enum EPremiumLoyaltyConditionOperator {
    Equal = 'EQUAL',
    NotEqual = 'NOT_EQUAL',
    GreaterThan = 'GREATER_THAN',
    LessThan = 'LESS_THAN',
    Contains = 'CONTAINS',
    NotContains = 'NOT_CONTAINS',
    IsInAudience = 'IS_IN_SEGMENT',
    IsNotInAudience = 'IS_NOT_IN_SEGMENT'
}

export enum EPremiumLoyaltyConditionUserProperty {
    HasAgreedToReceiveMarketing = 'hasAgreedToReceiveMarketing',
    HasAgreedToShareData = 'hasAgreedToShareData',
    Birthdate = 'birthdate',
    FavouriteLocations = 'favouriteLocations',
    PrimaryPlatform = 'primaryPlatform'
}

export enum EPremiumLoyaltyBirthdayPrefix {
    Before = 'before',
    After = 'after'
}

export interface PremiumLoyaltyCondition {
    type: EPremiumLoyaltyConditionType;
    metricId?: string;
    userProperty?: EPremiumLoyaltyConditionUserProperty;
    datePart?: 'year' | 'month' | 'dayOfYear' | 'dayOfMonth';
    operator?: EPremiumLoyaltyConditionOperator;
    value?: number | string | boolean | Date;
}

export type PremiumLoyaltyConditionBody = Omit<PremiumLoyaltyCondition, 'type'>;
export interface PremiumLoyaltyListenerBody {
    conditions?: PremiumLoyaltyConditionBody[];
    type: 'ADD_TO_SEGMENT' | 'REMOVE_FROM_SEGMENT';
    segmentId: string;
}

export type PremiumLoyaltyConditionFieldName =
    | 'type'
    | 'metricId'
    | 'metricOperator'
    | 'value'
    | 'audienceOperator'
    | 'audience'
    | 'userProperty'
    | 'primaryPlatformOperator'
    | 'primaryPlatformValue'
    | 'favouriteLocationsOperator'
    | 'favouriteLocationsValue'
    | 'marketingOperator';

export const getPremiumLoyaltyConditionType = (condition?: UserMetricListenerCondition) => {
    if (!condition) {
        return undefined;
    }
    if ('metricId' in condition) {
        return EPremiumLoyaltyConditionType.Metric;
    }
    if ('userProperty' in condition) {
        return EPremiumLoyaltyConditionType.UserProperty;
    }
    if (
        condition.operator === EPremiumLoyaltyConditionOperator.IsInAudience ||
        condition.operator === EPremiumLoyaltyConditionOperator.IsNotInAudience
    ) {
        return EPremiumLoyaltyConditionType.Audience;
    }
    return undefined;
};
