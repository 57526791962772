import { styled, Tooltip } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { titleize } from 'lib/helpers';
import React from 'react';
import {
    INotificationReceipt,
    NotificationReceiptStatus
} from 'components/notification/models/NotificationReceipt';

const PREFIX = 'NotificationReceiptStatusCell';

const classes = {
    green: `${PREFIX}-green`,
    yellow: `${PREFIX}-yellow`,
    red: `${PREFIX}-red`
};

const Root = styled('span')({
    [`&.${classes.green}`]: { color: green[500] },
    [`&.${classes.yellow}`]: { color: orange[500] },
    [`&.${classes.red}`]: { color: red[500] }
});

interface NotificationReceiptStatusCellProps {
    row: INotificationReceipt;
}

// If we have a more friendly error message, it should be added here
// These should be lower-cased keys as all messages are checked with toLowerCase
const receiptStatusText: { [key: string]: string } = {
    // SKIPS
    'marketing opt out': 'The Customer opted out of marketing messages',
    'no device token': 'The Customer did not have a valid device token', // App Uninstalled?

    // ERRORS
    notregistered: 'The Customer was previously unregistered',
    'unrecognised token': 'The Customer did not have a valid token'
};

const NotificationReceiptStatusCell: React.FC<NotificationReceiptStatusCellProps> = ({ row }) => {
    const className = React.useMemo(() => {
        if (row.status === NotificationReceiptStatus.ERROR) {
            return classes.red;
        }
        if (row.status === NotificationReceiptStatus.SKIPPED) {
            return classes.yellow;
        }
        return classes.green;
    }, [row]);
    const popover = React.useMemo(() => {
        if (row.status === NotificationReceiptStatus.ERROR) {
            return receiptStatusText[row.errorMessage.toLowerCase()] || row.errorMessage;
        }
        if (row.status === NotificationReceiptStatus.SKIPPED) {
            return receiptStatusText[row.skipMessage.toLowerCase()] || row.skipMessage;
        }
    }, [row]);

    return (
        <Root className={className}>
            <Tooltip className={className} title={popover} placement="left">
                <span>{titleize(String(row.status)).replace('_', ' ')}</span>
            </Tooltip>
        </Root>
    );
};

export const renderNotificationReceiptStatusCell = (params: GridRenderCellParams) => (
    <NotificationReceiptStatusCell row={params.row} />
);
