import {
    AwardExpiryLabelAnchorStyles,
    AwardsScreenShapes,
    AwardTemplate
} from 'components/customers/models/Award';
import {
    Perk,
    PerkDiscountScopeType,
    PerkVisualisation,
    TextAlignment,
    VerticalAlignment
} from 'components/loyalty/models/PerkModel';
import { RGBAValue } from 'lib/form/fields/ColorFormField';
import { rgbaToHex } from 'lib/helpers';
import { isDefined, isEmptyString } from 'lib/typeguards';
import { AwardRedemptionFormData } from './award-enrichments/forms/AwardRedemptionForm';
import { getMinsSinceMidnight } from './award-enrichments/utils';
import { PerkGeneralFormData } from './forms/PerkGeneralForm';
import { PerkSettingsFormData } from './forms/PerkSettingsForm';
import { EPointOfSaleProvider } from 'components/settings/PointOfSaleSettings';
import { getNormalizedDateTime } from 'lib/timeHelpers';
import { Option } from 'lib/types';
import { PerkVisualisationFormData } from 'components/perks/forms/PerkVisualisationForm';

const generateRedemptionFields = (
    { basketQualification, discount, dateTimeRestriction }: AwardRedemptionFormData,
    merchantTimezone?: string
) => {
    const weekDays = dateTimeRestriction.daysOfWeek?.length
        ? dateTimeRestriction.daysOfWeek.reduce((acc, weekday) => {
              acc += Number(weekday);

              return acc;
          }, 0)
        : undefined;
    const hasDiscountFields = !!discount && !!Object.values(discount).filter(Boolean).length;
    const hasBasketQualificationFields =
        !!basketQualification && !!Object.values(basketQualification).filter(Boolean).length;

    let { startDate, endDate } = dateTimeRestriction;
    const startTimeMins = getMinsSinceMidnight(dateTimeRestriction.startTime) ?? 0;
    const endTimeMins = getMinsSinceMidnight(dateTimeRestriction.endTime) ?? 0;
    if (startDate) {
        startDate = getNormalizedDateTime(startDate, merchantTimezone)
            .set({ hour: Math.floor(startTimeMins / 60), minute: startTimeMins % 60 })
            .toISO();
    }
    if (endDate) {
        endDate = getNormalizedDateTime(endDate, merchantTimezone)
            .set({ hour: Math.floor(endTimeMins / 60), minute: endTimeMins % 60 })
            .toISO();
    }

    return {
        basketQualification: hasBasketQualificationFields
            ? {
                  matchCode: basketQualification.matchCode || undefined,
                  minimumItems:
                      basketQualification.minimumItems ||
                      (hasBasketQualificationFields && !basketQualification.minimumValue && 1) ||
                      undefined,
                  minimumValue: basketQualification.minimumValue || undefined,
                  scope: basketQualification.scope
              }
            : undefined,
        discount: hasDiscountFields
            ? {
                  value:
                      isDefined(discount.value) && !isEmptyString(discount.value)
                          ? Number(discount.value)
                          : undefined,
                  scope: discount.scope,
                  type: discount.type,
                  scopeMatchCode: discount.scopeMatchCode,
                  rule: discount.rule,
                  childItemRule: discount.childItemRule
              }
            : undefined,
        dateTimeRestriction: {
            startDate: startDate || undefined,
            endDate: endDate || undefined,
            startTime: startTimeMins * 60 || undefined,
            endTime: endTimeMins * 60 || undefined,
            daysOfWeek: weekDays
        }
    };
};

export function showChildItemRulePerkForm(posProvider: string) {
    const POS_PROVIDERS_SUPPORT_LIST: string[] = [
        EPointOfSaleProvider.COMTREX,
        EPointOfSaleProvider.DELIVERECT,
        EPointOfSaleProvider.IBS,
        EPointOfSaleProvider.TEVALIS,
        EPointOfSaleProvider.MCR,
        EPointOfSaleProvider.OMNIVORE_MICROS_SIMPHONY
    ];
    return POS_PROVIDERS_SUPPORT_LIST.includes(posProvider);
}

export function getChildItemRuleOptions(posProvider: string, discountScope?: PerkDiscountScopeType) {
    const defaultRules = [
        { value: 'EXCLUDE', label: 'excluded' },
        { value: 'INCLUDE', label: 'included' }
    ];
    if (
        showChildItemRulePerkForm(posProvider) &&
        (discountScope === 'ITEM' || discountScope === 'ITEM_OR_CATEGORY')
    ) {
        return [...defaultRules, { value: 'INCLUDE_MATCHED', label: 'included if matched' }];
    }
    return defaultRules;
}
export function getPerkDiscountScopeOptions(posProvider: string) {
    const defaultScopeOptions: Option[] = [
        {
            value: 'ITEM',
            label: 'a specific product'
        },
        {
            value: 'CATEGORY',
            label: 'a specific category'
        },
        {
            value: 'BASKET',
            label: 'entire basket'
        },
        {
            value: 'ITEM_OR_CATEGORY',
            label: 'either a category or product'
        }
    ];
    if (showChildItemRulePerkForm(posProvider)) {
        return [
            ...defaultScopeOptions,
            {
                value: 'CHILD_ITEM',
                label: 'a specific modifier product'
            },
            {
                value: 'ITEM_AND_CHILD_ITEM',
                label: 'a specific product and modifier product'
            }
        ];
    }
    return defaultScopeOptions;
}

export const getPerkOpacity = (color: string | RGBAValue) => {
    const opacity = typeof color === 'string' ? parseInt(color.substring(7, 9), 16) / 255 : color.a;
    if (Number.isNaN(opacity)) {
        return 1;
    }
    return opacity ?? 1;
};

export const deriveExpiryLabelAnchorStyle = (
    horizontalAlignment: TextAlignment,
    verticalAlignment: VerticalAlignment.TOP | VerticalAlignment.BOTTOM
): AwardExpiryLabelAnchorStyles => {
    if (verticalAlignment === VerticalAlignment.TOP) {
        if (horizontalAlignment === TextAlignment.LEFT) {
            return AwardExpiryLabelAnchorStyles.TOP_LEFT_CHIP;
        }
        if (horizontalAlignment === TextAlignment.CENTER) {
            return AwardExpiryLabelAnchorStyles.TOP_MIDDLE_CHIP;
        }
        if (horizontalAlignment === TextAlignment.RIGHT) {
            return AwardExpiryLabelAnchorStyles.TOP_RIGHT_CHIP;
        }
        if (horizontalAlignment === TextAlignment.JUSTIFY) {
            return AwardExpiryLabelAnchorStyles.TOP_FULL_WIDTH_BAR;
        }
    } else {
        if (horizontalAlignment === TextAlignment.LEFT) {
            return AwardExpiryLabelAnchorStyles.BOTTOM_LEFT_CHIP;
        }
        if (horizontalAlignment === TextAlignment.CENTER) {
            return AwardExpiryLabelAnchorStyles.BOTTOM_MIDDLE_CHIP;
        }
        if (horizontalAlignment === TextAlignment.RIGHT) {
            return AwardExpiryLabelAnchorStyles.BOTTOM_RIGHT_CHIP;
        }
        if (horizontalAlignment === TextAlignment.JUSTIFY) {
            return AwardExpiryLabelAnchorStyles.BOTTOM_FULL_WIDTH_BAR;
        }
    }
};

export const deriveExpiryLabelAlignment = (
    anchorStyle: AwardExpiryLabelAnchorStyles
): { vertical: VerticalAlignment.TOP | VerticalAlignment.BOTTOM; horizontal: TextAlignment } => {
    switch (anchorStyle) {
        case AwardExpiryLabelAnchorStyles.TOP_LEFT_CHIP:
            return { vertical: VerticalAlignment.TOP, horizontal: TextAlignment.LEFT };
        case AwardExpiryLabelAnchorStyles.TOP_MIDDLE_CHIP:
            return { vertical: VerticalAlignment.TOP, horizontal: TextAlignment.CENTER };
        case AwardExpiryLabelAnchorStyles.TOP_RIGHT_CHIP:
            return { vertical: VerticalAlignment.TOP, horizontal: TextAlignment.RIGHT };
        case AwardExpiryLabelAnchorStyles.BOTTOM_LEFT_CHIP:
            return { vertical: VerticalAlignment.BOTTOM, horizontal: TextAlignment.LEFT };
        case AwardExpiryLabelAnchorStyles.BOTTOM_MIDDLE_CHIP:
            return { vertical: VerticalAlignment.BOTTOM, horizontal: TextAlignment.CENTER };
        case AwardExpiryLabelAnchorStyles.BOTTOM_RIGHT_CHIP:
            return { vertical: VerticalAlignment.BOTTOM, horizontal: TextAlignment.RIGHT };
        case AwardExpiryLabelAnchorStyles.TOP_FULL_WIDTH_BAR:
            return { vertical: VerticalAlignment.TOP, horizontal: TextAlignment.JUSTIFY };
        case AwardExpiryLabelAnchorStyles.BOTTOM_FULL_WIDTH_BAR:
            return { vertical: VerticalAlignment.BOTTOM, horizontal: TextAlignment.JUSTIFY };
        default:
            return { vertical: VerticalAlignment.BOTTOM, horizontal: TextAlignment.RIGHT };
    }
};

const generateCreatePseudoCurPerkBody = (
    general: PerkGeneralFormData,
    settings: PerkSettingsFormData,
    visualisation: PerkVisualisationFormData,
    awardsScreenAwardEnabled?: boolean
) => {
    const opacity = getPerkOpacity(visualisation.backgroundColor);

    const initial = Number(settings.initial ?? 0);

    return {
        terms: settings.terms,
        isGlobal: settings.global,
        enabled: settings.enabled,
        locationIds: settings.locationIds?.length > 0 ? settings.locationIds : undefined,
        priority: general.order,
        template: AwardTemplate.PSEUDO_CURRENCY,
        auto: settings.auto ?? false,
        isDonatable: false,
        maximumPointCashValue: 1,
        isTier: false,
        title: general.title,
        internalTitle: general.internalTitle,
        redemptionMethod: 'AUTO' as Perk['redemptionMethod'],
        visualisation: {
            subtitleTwoText: 'Worth {{currency}}',
            textAlignment: visualisation.align,
            description: general.description,
            subtitleText: '{{available}} point{{s}}',
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(visualisation.backgroundColor, true),
            textColour: rgbaToHex(visualisation.textColor, true),
            backgroundImageUrl: visualisation.image ?? undefined,
            verticalAlignment: visualisation.valign,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            titleText: visualisation.titleText,
            showExpiry: visualisation.showExpiry,
            ...(visualisation.showExpiry
                ? {
                      expiryLabelAnchorStyle: deriveExpiryLabelAnchorStyle(
                          visualisation.expiryLabelAnchorStyleAlign,
                          visualisation.expiryLabelAnchorStyleVAlign
                      ),
                      expiryLabelDetailStyle: visualisation.expiryLabelDetailStyle
                  }
                : {}),
            wallet: generateWalletVisualisationBody(visualisation),
            ...(awardsScreenAwardEnabled && {
                awardsScreenShape: AwardsScreenShapes.DOUBLE_RECTANGLE
            })
        } as PerkVisualisation,
        points: {
            initial,
            max: 100000,
            min: 0,
            step: Number(settings.step),
            redemption: Number(settings.impact)
        }
    };
};

const generateCreateAdHocPerkBody = (
    general: PerkGeneralFormData,
    settings: PerkSettingsFormData,
    visualisation: PerkVisualisationFormData,
    awardsScreenAwardEnabled?: boolean
) => {
    const opacity = getPerkOpacity(visualisation.backgroundColor);
    const isMultiSet = !!settings.multi;
    const initial = isMultiSet ? 1 : Number(settings.initial ?? 0);

    return {
        terms: settings.terms,
        isGlobal: settings.global,
        enabled: settings.enabled,
        priority: general.order,
        template: AwardTemplate.AD_HOC,
        auto: settings.auto ?? false,
        isDonatable: false,
        maximumPointCashValue: isMultiSet ? 0 : 1,
        isTier: false,
        title: general.title,
        internalTitle: general.internalTitle,
        redemptionMethod: 'AUTO' as Perk['redemptionMethod'],
        locationIds: settings.locationIds?.length > 0 ? settings.locationIds : undefined,
        visualisation: {
            subtitleTwoText: visualisation.subtitle2,
            textAlignment: visualisation.align,
            description: general.description,
            subtitleText: visualisation.subtitle,
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(visualisation.backgroundColor, true),
            textColour: rgbaToHex(visualisation.textColor, true),
            backgroundImageUrl: visualisation.image ?? undefined,
            verticalAlignment: visualisation.valign,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            ...(awardsScreenAwardEnabled &&
            visualisation.awardsScreenShape !== AwardsScreenShapes.DOUBLE_RECTANGLE
                ? {
                      titleText: '',
                      subtitleTwoText: '',
                      subtitleText: '',
                      awardsScreenShape: visualisation.awardsScreenShape,
                      showExpiry: false
                  }
                : {
                      titleText: visualisation.titleText,
                      subtitleTwoText: visualisation.subtitle2,
                      subtitleText: visualisation.subtitle,
                      verticalAlignment: visualisation.valign,
                      showExpiry: visualisation.showExpiry,
                      textAlignment: visualisation.align,
                      textColour: rgbaToHex(visualisation.textColor, true),
                      ...(visualisation.showExpiry && {
                          expiryLabelAnchorStyle: deriveExpiryLabelAnchorStyle(
                              visualisation.expiryLabelAnchorStyleAlign,
                              visualisation.expiryLabelAnchorStyleVAlign
                          ),
                          expiryLabelDetailStyle: visualisation.expiryLabelDetailStyle
                      }),
                      ...(awardsScreenAwardEnabled && {
                          awardsScreenShape: AwardsScreenShapes.DOUBLE_RECTANGLE
                      })
                  }),
            wallet: generateWalletVisualisationBody(visualisation)
        } as PerkVisualisation,
        points: {
            ...(isMultiSet
                ? {
                      initial,
                      min: 1,
                      max: 1,
                      step: 0,
                      redemption: 0
                  }
                : {
                      initial,
                      min: 0,
                      max: 1,
                      step: 1,
                      redemption: 1
                  })
        }
    };
};

const generateCreateClubPerkBody = (
    general: PerkGeneralFormData,
    settings: PerkSettingsFormData,
    visualisation: PerkVisualisationFormData,
    awardsScreenAwardEnabled?: boolean
) => {
    const opacity = getPerkOpacity(visualisation.backgroundColor);
    const isMultiSet = !!settings.multi;
    const initial = isMultiSet ? 1 : Number(settings.initial ?? 0);

    return {
        terms: settings.terms,
        isGlobal: settings.global,
        enabled: settings.enabled,
        locationIds: settings.locationIds?.length > 0 ? settings.locationIds : undefined,
        priority: general.order,
        template: AwardTemplate.CLUB,
        auto: settings.auto ?? false,
        isDonatable: false,
        maximumPointCashValue: isMultiSet ? 0 : 1,
        isTier: false,
        title: general.title,
        internalTitle: general.internalTitle,
        redemptionMethod: 'AUTO' as Perk['redemptionMethod'],
        visualisation: {
            subtitleTwoText: visualisation.subtitle2,
            textAlignment: visualisation.align,
            description: general.description,
            subtitleText: visualisation.subtitle,
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(visualisation.backgroundColor, true),
            textColour: rgbaToHex(visualisation.textColor, true),
            backgroundImageUrl: visualisation.image ?? undefined,
            verticalAlignment: visualisation.valign,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            ...(awardsScreenAwardEnabled &&
            visualisation.awardsScreenShape !== AwardsScreenShapes.DOUBLE_RECTANGLE
                ? {
                      titleText: '',
                      subtitleTwoText: '',
                      subtitleText: '',
                      awardsScreenShape: visualisation.awardsScreenShape
                  }
                : {
                      titleText: visualisation.titleText,
                      subtitleTwoText: visualisation.subtitle2,
                      subtitleText: visualisation.subtitle,
                      verticalAlignment: visualisation.valign,
                      textAlignment: visualisation.align,
                      textColour: rgbaToHex(visualisation.textColor, true),
                      ...(awardsScreenAwardEnabled && {
                          awardsScreenShape: AwardsScreenShapes.DOUBLE_RECTANGLE
                      })
                  }),
            ...(visualisation.awardsScreenShape && {
                awardsScreenShape: visualisation.awardsScreenShape
            }),
            wallet: generateWalletVisualisationBody(visualisation)
        } as PerkVisualisation,
        points: {
            ...(isMultiSet
                ? {
                      initial,
                      min: 1,
                      max: 1,
                      step: 0,
                      redemption: 0
                  }
                : {
                      initial,
                      min: 0,
                      max: 1,
                      step: 1,
                      redemption: 1
                  })
        }
    };
};

const generateCreateStampCardPerkBody = (
    general: PerkGeneralFormData,
    settings: PerkSettingsFormData,
    visualisation: PerkVisualisationFormData,
    awardsScreenAwardEnabled?: boolean
) => {
    const opacity = getPerkOpacity(visualisation.backgroundColor);
    const initial = Number(settings.initial ?? 0);
    return {
        terms: settings.terms,
        isGlobal: settings.global,
        enabled: settings.enabled,
        locationIds: settings.locationIds?.length > 0 ? settings.locationIds : undefined,
        priority: general.order,
        template: AwardTemplate.STAMP_CARD,
        auto: settings.auto ?? false,
        isDonatable: false,
        maximumPointCashValue: 1,
        isTier: false,
        title: general.title,
        internalTitle: general.internalTitle,
        redemptionMethod: 'AUTO' as Perk['redemptionMethod'],
        visualisation: {
            textAlignment: visualisation.align,
            randomiseStampPositions: visualisation.randomizeStampCard,
            stampImageUrl: visualisation.stampImageUrl,
            stampColour: rgbaToHex(visualisation.stampColor, true),
            description: general.description,
            subtitleText: visualisation.subtitle,
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(visualisation.backgroundColor, true),
            textColour: rgbaToHex(visualisation.textColor, true),
            backgroundImageUrl: visualisation.image ?? undefined,
            verticalAlignment: visualisation.valign,
            availableCardCopy: visualisation.availableCardCopy,
            nextCardProgressCopy: visualisation.nextCardProgressCopy,
            titleText: visualisation.titleText,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            ...(awardsScreenAwardEnabled && {
                awardsScreenShape: AwardsScreenShapes.DOUBLE_RECTANGLE
            }),
            wallet: generateWalletVisualisationBody(visualisation)
        } as PerkVisualisation,
        points: {
            initial,
            max: 100000,
            min: 0,
            step: 1,
            redemption: visualisation.stampsPerCard ? Number(visualisation.stampsPerCard) : 6
        }
    };
};

export const generateCreatePerkBody = (
    general: PerkGeneralFormData,
    settings: PerkSettingsFormData,
    redemption: AwardRedemptionFormData,
    visualisation: PerkVisualisationFormData,
    merchantTimezone?: string,
    awardsScreenAwardEnabled?: boolean
) => {
    let perkBody = {};
    switch (settings.perkType) {
        case AwardTemplate.PSEUDO_CURRENCY:
            perkBody = {
                ...perkBody,
                token: settings.redemption,
                ...generateCreatePseudoCurPerkBody(general, settings, visualisation, awardsScreenAwardEnabled)
            };
            break;
        case AwardTemplate.STAMP_CARD:
            perkBody = {
                ...perkBody,
                token: settings.redemption,
                ...generateCreateStampCardPerkBody(general, settings, visualisation, awardsScreenAwardEnabled)
            };
            break;
        case AwardTemplate.CLUB:
            perkBody = {
                ...perkBody,
                token: settings.redemption,
                ...generateCreateClubPerkBody(general, settings, visualisation, awardsScreenAwardEnabled)
            };
            break;
        default:
            perkBody = {
                ...perkBody,
                token: settings.redemption,
                ...generateCreateAdHocPerkBody(general, settings, visualisation, awardsScreenAwardEnabled)
            };

            break;
    }

    if (redemption) {
        perkBody = { ...perkBody, ...generateRedemptionFields(redemption, merchantTimezone) };
    }

    return perkBody;
};

export const generateWalletVisualisationBody = (data: PerkVisualisationFormData) => {
    const imageUrls = [];
    if (data.walletAppleImageUrl) {
        imageUrls.push({ name: 'APPLE', value: data.walletAppleImageUrl });
    }
    if (data.walletGoogleImageUrl) {
        imageUrls.push({ name: 'GOOGLE', value: data.walletGoogleImageUrl });
    }

    return {
        enabled: data.walletEnabled,
        title: data.walletTitle,
        priority: data.walletPriority,
        description: data.walletDescription,
        terms: data.walletTerms,
        link: data.walletLink,
        imageUrls,
        displayPoints: data.walletDisplayPoints,
        hidden: data.walletHidden
    };
};

export const generateUpdatePseudoCurPerkBody = (
    updateData: PerkGeneralFormData & PerkSettingsFormData & PerkVisualisationFormData,
    awardsScreenAwardEnabled?: boolean
) => {
    const opacity = getPerkOpacity(updateData.backgroundColor);
    return {
        terms: updateData.terms,
        priority: updateData.order,
        title: updateData.title,
        internalTitle: updateData.internalTitle,
        locationIds: updateData.locationIds?.length > 0 ? updateData.locationIds : null,
        isGlobal: updateData.global,
        enabled: updateData.enabled,
        visualisation: {
            subtitleTwoText: 'Worth {{currency}}',
            textAlignment: updateData.align,
            description: updateData.description,
            subtitleText: '{{available}} point{{s}}',
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(updateData.backgroundColor, true),
            textColour: rgbaToHex(updateData.textColor, true),
            backgroundImageUrl: updateData.image ?? undefined,
            verticalAlignment: updateData.valign,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            titleText: updateData.titleText,
            showExpiry: updateData.showExpiry,
            ...(updateData.showExpiry && {
                expiryLabelAnchorStyle: deriveExpiryLabelAnchorStyle(
                    updateData.expiryLabelAnchorStyleAlign,
                    updateData.expiryLabelAnchorStyleVAlign
                ),
                expiryLabelDetailStyle: updateData.expiryLabelDetailStyle
            }),
            ...(awardsScreenAwardEnabled && {
                awardsScreenShape: AwardsScreenShapes.DOUBLE_RECTANGLE
            }),
            wallet: generateWalletVisualisationBody(updateData)
        } as PerkVisualisation
    };
};

const generateUpdateStampCardPerkBody = (
    updateData: PerkGeneralFormData & PerkSettingsFormData & PerkVisualisationFormData,
    awardsScreenAwardEnabled?: boolean
) => {
    const opacity = getPerkOpacity(updateData.backgroundColor);
    return {
        terms: updateData.terms,
        priority: updateData.order,
        title: updateData.title,
        internalTitle: updateData.internalTitle,
        locationIds: updateData.locationIds?.length > 0 ? updateData.locationIds : null,
        isGlobal: updateData.global,
        enabled: updateData.enabled,
        visualisation: {
            textAlignment: updateData.align,
            randomiseStampPositions: updateData.randomizeStampCard,
            stampImageUrl: updateData.stampImageUrl ?? undefined,
            stampColour: rgbaToHex(updateData.stampColor, true),
            description: updateData.description,
            subtitleText: updateData.subtitle,
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(updateData.backgroundColor, true),
            textColour: rgbaToHex(updateData.textColor, true),
            backgroundImageUrl: updateData.image ?? undefined,
            verticalAlignment: updateData.valign,
            availableCardCopy: updateData.availableCardCopy,
            nextCardProgressCopy: updateData.nextCardProgressCopy,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            titleText: updateData.titleText,
            wallet: generateWalletVisualisationBody(updateData),
            ...(awardsScreenAwardEnabled && {
                awardsScreenShape: AwardsScreenShapes.DOUBLE_RECTANGLE
            })
        } as PerkVisualisation
    };
};

const generateUpdateAdHocPerkBody = (
    updateData: PerkGeneralFormData & PerkSettingsFormData & PerkVisualisationFormData,
    awardsScreenAwardEnabled?: boolean
) => {
    const opacity = getPerkOpacity(updateData.backgroundColor);

    return {
        terms: updateData.terms,
        priority: updateData.order,
        title: updateData.title,
        internalTitle: updateData.internalTitle,
        locationIds: updateData.locationIds?.length > 0 ? updateData.locationIds : null,
        isGlobal: updateData.global,
        enabled: updateData.enabled,
        visualisation: {
            description: updateData.description,
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(updateData.backgroundColor, true),
            textColour: rgbaToHex(updateData.textColor, true),
            backgroundImageUrl: updateData.image ?? undefined,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            showExpiry: updateData.showExpiry,
            ...(updateData.showExpiry && {
                expiryLabelAnchorStyle: deriveExpiryLabelAnchorStyle(
                    updateData.expiryLabelAnchorStyleAlign,
                    updateData.expiryLabelAnchorStyleVAlign
                ),
                expiryLabelDetailStyle: updateData.expiryLabelDetailStyle
            }),
            ...(awardsScreenAwardEnabled &&
            updateData.awardsScreenShape !== AwardsScreenShapes.DOUBLE_RECTANGLE
                ? {
                      titleText: '',
                      subtitleTwoText: '',
                      subtitleText: '',
                      awardsScreenShape: updateData.awardsScreenShape,
                      showExpiry: false
                  }
                : {
                      titleText: updateData.titleText,
                      subtitleTwoText: updateData.subtitle2,
                      subtitleText: updateData.subtitle,
                      verticalAlignment: updateData.valign,
                      showExpiry: updateData.showExpiry,
                      textAlignment: updateData.align,
                      textColour: rgbaToHex(updateData.textColor, true),
                      ...(updateData.showExpiry && {
                          expiryLabelAnchorStyle: deriveExpiryLabelAnchorStyle(
                              updateData.expiryLabelAnchorStyleAlign,
                              updateData.expiryLabelAnchorStyleVAlign
                          ),
                          expiryLabelDetailStyle: updateData.expiryLabelDetailStyle
                      }),
                      ...(awardsScreenAwardEnabled && {
                          awardsScreenShape: AwardsScreenShapes.DOUBLE_RECTANGLE
                      })
                  }),
            wallet: generateWalletVisualisationBody(updateData)
        } as PerkVisualisation
    };
};

export const generateEditPerkBody = (
    updateData: PerkGeneralFormData &
        PerkSettingsFormData &
        PerkVisualisationFormData &
        AwardRedemptionFormData,
    merchantTimezone?: string,
    awardsScreenAwardEnabled?: boolean
) => {
    let perkBody: Partial<Perk> = {};
    switch (updateData.perkType) {
        case AwardTemplate.PSEUDO_CURRENCY:
            perkBody = {
                ...perkBody,
                ...generateUpdatePseudoCurPerkBody(updateData, awardsScreenAwardEnabled)
            };
            break;
        case AwardTemplate.STAMP_CARD:
            perkBody = {
                ...perkBody,
                ...generateUpdateStampCardPerkBody(updateData, awardsScreenAwardEnabled)
            };
            break;
        default:
            perkBody = { ...perkBody, ...generateUpdateAdHocPerkBody(updateData, awardsScreenAwardEnabled) };
            break;
    }

    perkBody = { ...perkBody, ...generateRedemptionFields(updateData, merchantTimezone) };
    if (isDefined(updateData.redemption)) {
        perkBody.token = updateData.redemption;
    }

    return perkBody;
};

export const generatePerkGrantImpactBody = (audienceId: string, perkId: string) => {
    const grantPerkFromImpactBody = {
        matchType: 'TYPE',
        conditions: [
            {
                subject: 'type',
                object: 'MEMBER_ADDED',
                predicate: 'EQUAL'
            },
            {
                subject: 'metadata.audienceId',
                object: [audienceId],
                predicate: 'IN'
            }
        ],
        effect: {
            controller: 'pointPerk',
            method: 'grantPerkFromImpact',
            impact: {
                evaluatorType: 'BUILTIN_FUNCTION',
                evaluatorName: 'passThru',
                parameters: {
                    perkId
                }
            }
        }
    };

    return grantPerkFromImpactBody;
};

export const generatePerkGrantRevokeBody = (audienceId: string, perkId: string) => {
    const revokeFromImpactBody = {
        matchType: 'TYPE',
        conditions: [
            {
                subject: 'type',
                object: 'MEMBER_REMOVED',
                predicate: 'EQUAL'
            },
            {
                subject: 'metadata.audienceId',
                object: [audienceId],
                predicate: 'IN'
            }
        ],
        effect: {
            controller: 'pointAward',
            method: 'revokeFromImpact',
            impact: {
                evaluatorType: 'BUILTIN_FUNCTION',
                evaluatorName: 'passThru',
                parameters: {
                    perkId
                }
            }
        }
    };

    return revokeFromImpactBody;
};
