import React from 'react';
import { FormControl, FormHelperText, TextField, Autocomplete } from '@mui/material';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { Option } from 'lib/types';

interface AutocompleteFormFieldProps {
    label?: React.ReactNode;
    placeholder?: string;
    options: Option[];
    description?: React.ReactNode;
    isCreatable?: boolean;
}

export const AutocompleteFormField: React.FC<FieldAttributes<AutocompleteFormFieldProps>> = ({
    label,
    description,
    options,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <AutocompleteFormFieldComponent
                label={label}
                description={description}
                options={options}
                {...childProps}
            />
        )}
    </Field>
);

const AutocompleteFormFieldComponent: React.FC<FieldProps & AutocompleteFormFieldProps> = ({
    label,
    description,
    meta,
    options,
    field
}) => {
    const isError = meta.touched && !!meta.error;

    const autocompleteValue = React.useMemo(
        () => options.find(item => item.value === field.value) ?? '',
        [field.value, options]
    );

    const handleChange = React.useCallback(
        (_: never, option: Option) => {
            const value: string = option?.value ? option.value : '';
            field.onChange({ target: { value, name: field.name } });
        },
        [field]
    );
    const getOptionsLabel = React.useCallback(item => (typeof item.label === 'string' ? item.label : ''), []);

    const renderSelectInput = React.useCallback(
        params => <TextField {...params} error={isError} label={label} fullWidth />,
        [isError, label]
    );

    return (
        <FormControl fullWidth error={meta.touched && !!meta.error}>
            <Autocomplete
                value={autocompleteValue}
                options={options}
                onChange={handleChange}
                getOptionLabel={getOptionsLabel}
                renderInput={renderSelectInput}
            />
            {(isError || description) && (
                <FormHelperText sx={{ marginX: 0 }} error={isError}>
                    {isError ? meta.error : description}
                </FormHelperText>
            )}
        </FormControl>
    );
};
