import React from 'react';
import {
    EPointOfSaleProvider,
    IPointOfSaleSettingsProps,
    itemMappingSchemeOptions
} from 'components/settings/PointOfSaleSettings';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { ChipInputFormField } from '../../../../lib/form/fields/ChipInputFormField';

export const AlohaSettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({
    locationId,
    settings,
    globalSettings
}) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplay label="Omnivore NCR Aloha API Key">
                    <TextFormField name="omnivoreAlohaApiKey" />
                </SettingDisplay>
                <SettingDisplay label="Webhook Activation Code">
                    <TextFormField name="omnivoreAlohaWebhookActivationCode" />
                </SettingDisplay>
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <>
                <SettingDisplay label="Location ID">
                    <TextFormField name="omnivoreAlohaLocationId" />
                </SettingDisplay>
                <SettingDisplay label="Employee ID">
                    <TextFormField name="omnivoreAlohaEmployeeId" />
                </SettingDisplay>
                <SettingDisplay label="Tender Type ID">
                    <TextFormField name="omnivoreAlohaTenderTypeId" />
                </SettingDisplay>
                <SettingDisplay label="Pre-Order Order Type ID">
                    <TextFormField name="omnivoreAlohaPreOrderOrderTypeId" />
                </SettingDisplay>
                <SettingDisplay label="Pre-Order Revenue Center ID">
                    <TextFormField name="omnivoreAlohaPreOrderCenterId" />
                </SettingDisplay>
                <SettingDisplay label="Order to Table Order Type ID">
                    <TextFormField name="omnivoreAlohaOrderToTableOrderTypeId" />
                </SettingDisplay>
                <SettingDisplay label="Order to Table Revenue Center ID">
                    <TextFormField name="omnivoreAlohaOrderToTableRevenueCenterId" />
                </SettingDisplay>
                <SettingDisplay label="Pay at Table Order Type ID">
                    <TextFormField name="omnivoreAlohaPayAtTableOrderTypeId" />
                </SettingDisplay>
                <SettingDisplay label="Pay at Table Revenue Center ID">
                    <TextFormField name="omnivoreAlohaPayAtTableRevenueCenterId" />
                </SettingDisplay>
                <SettingDisplay
                    label="Menu Category Filter"
                    description="Comma delimited list of categories to include in the menu. If blank, all are returned."
                >
                    <TextFormField name="omnivoreAlohaMenuCategoryFilter" />
                </SettingDisplay>
                <SettingDisplay
                    label="Item Level Price"
                    // eslint-disable-next-line max-len
                    description="Will only use price level for item pricing otherwise will fall back to base price, if blank all price levels are returned."
                >
                    <TextFormField name="omnivoreAlohaItemLevelPrice" />
                </SettingDisplay>
                <SettingDisplay label="Item Mapping Scheme">
                    <SelectFormField
                        name="omnivoreAlohaItemMappingScheme"
                        options={itemMappingSchemeOptions}
                    />
                </SettingDisplay>
                {{ ...globalSettings, ...settings }.terminalAccessProvider ===
                    EPointOfSaleProvider.OMNIVORE_NCR_ALOHA && (
                    <>
                        <SettingDisplay
                            label="Manager Job IDs"
                            // eslint-disable-next-line max-len
                            description="The ID of the jobs representing managers on the point of sale."
                        >
                            <ChipInputFormField
                                name="omnivoreAlohaManagerJobIds"
                                multiple
                                isCreatable={true}
                            />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Clerk Job IDs"
                            // eslint-disable-next-line max-len
                            description="The ID of the jobs representing clerks on the point of sale."
                        >
                            <ChipInputFormField name="omnivoreAlohaClerkJobIds" multiple isCreatable={true} />
                        </SettingDisplay>
                    </>
                )}
            </>
        )}
    </>
);
