import {
    ICallContext,
    IPublicAndPrivateSettings,
    IPublicSettings,
    OAuthProvider,
    SettingId,
    settingsService as SettingsService
} from '@pepperhq/location-sdk';
import { TypeGuard } from '@pepperhq/location-sdk/dist/lib/typeInference';
import { getAuthToken } from 'components/auth/getAuthToken';
import logger from 'lib/logger';
import { Omit } from 'lib/types';

const buildCallContext = async (): Promise<Omit<ICallContext, 'requestId'>> => ({
    logger,
    session: {
        bearerToken: await getAuthToken(),
        authId: process.env.CONSOLE_FIREBASE_PROJECT_ID
    }
});

export class LocationSettingsApi {
    private readonly service: SettingsService;
    constructor() {
        this.service = new SettingsService(process.env.TIER as 'prod' | 'beta');
    }
    async getPublicSettings(
        _?: null,
        locationId?: string,
        dontMerge?: boolean,
        ...onlySettingIds: SettingId[]
    ): Promise<IPublicSettings> {
        return this.service.getPublicSettings(
            await buildCallContext(),
            locationId,
            dontMerge,
            ...onlySettingIds
        );
    }
    async getAllPublicSettings(
        _?: null,
        locationIds?: string[],
        dontMerge?: boolean,
        ...onlySettingIds: SettingId[]
    ) {
        return this.service.getAllPublicSettings(
            await buildCallContext(),
            locationIds,
            dontMerge,
            ...onlySettingIds
        );
    }
    async getPublicAndPrivateSettings(
        _?: null,
        locationId?: string | undefined,
        dontMerge?: boolean | undefined,
        ...onlySettingIds: SettingId[]
    ): Promise<IPublicAndPrivateSettings> {
        return this.service.getPublicAndPrivateSettings(
            await buildCallContext(),
            locationId,
            dontMerge,
            ...onlySettingIds
        );
    }

    async setPublicAndPrivateSetting(setting: IPublicAndPrivateSettings, locationId?: string): Promise<void> {
        return this.service.setPublicAndPrivateSetting(await buildCallContext(), setting, locationId);
    }

    async updatePublicAndPrivateSettings(
        settings: IPublicAndPrivateSettings,
        locationId?: string
    ): Promise<void> {
        return this.service.updatePublicAndPrivateSettings(await buildCallContext(), settings, locationId);
    }

    async revokeOAuthAccess(oauthProvider: OAuthProvider, locationId?: string): Promise<void> {
        await this.service.revokeOAuthAccess(await buildCallContext(), oauthProvider, locationId);
    }
    async getAllSettingValuesForTenant(
        settingId: string,
        settingDataPath: string,
        isT: TypeGuard<{
            accountId: string;
        }>
    ) {
        return this.service.getAllSettingValuesForTenant(
            await buildCallContext(),
            settingId,
            settingDataPath,
            isT
        );
    }
}
