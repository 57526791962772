import { DateTime } from 'luxon';
import { isEmptyString, isNumber, isString } from './typeguards';
import { UserMetricDefinition } from 'components/metrics/model';
import { isObject } from '@pepperhq/menu-sdk/dist/libs/typeUtils';

export type RelativeTimeStringUnit = 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years';
export function isRelativeTimeStringUnit(unit: any): unit is RelativeTimeStringUnit {
    return ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years'].includes(unit);
}

export type RelativeTimeString =
    | `${'-' | '+'}${number}${RelativeTimeStringUnit}`
    | `${'-' | '+'}${number}${RelativeTimeStringUnit}@${string}:${string}`;

const regex =
    /^([+-])([0-9]+)(seconds|minutes|hours|days|weeks|months|years)(?:@(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]))?$/;
export function validateRelativeTimeString(str: string): str is RelativeTimeString {
    return regex.test(str);
}

export function parseRelativeTimeString(str: RelativeTimeString) {
    const parts = regex.exec(str);
    if (parts === null) {
        return;
    }

    const [_, symbol, amountString, unit, snapToHoursString, snapToMinutesString] = parts;
    const amount = parseInt(amountString);
    if (!isNumber(amount)) {
        return;
    }

    if (!isRelativeTimeStringUnit(unit)) {
        return;
    }

    if (snapToHoursString && snapToMinutesString) {
        const snapToHours = parseInt(snapToHoursString);
        if (!isNumber(snapToHours)) {
            return;
        }
        const snapToMinutes = parseInt(snapToMinutesString);
        if (!isNumber(snapToMinutes)) {
            return;
        }
        return { symbol, amountString, unit, snapToHoursString, snapToMinutesString };
    }
    return { symbol, amountString, unit };
}

export function relativeTimeStringToDate(
    str: RelativeTimeString,
    currentDate = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
): DateTime | undefined {
    const parts = regex.exec(str);
    if (parts === null) {
        return;
    }

    const [_, symbol, amountString, unit, snapToHoursString, snapToMinutesString] = parts;
    let today = currentDate;
    if (snapToHoursString && snapToMinutesString) {
        const snapToHours = parseInt(snapToHoursString);
        if (isNaN(snapToHours)) {
            return;
        }
        const snapToMinutes = parseInt(snapToMinutesString);
        if (isNaN(snapToMinutes)) {
            return;
        }
        today = today.set({ hour: snapToHours, minute: snapToMinutes });
    }

    const amount = parseInt(amountString);
    if (isNaN(amount)) {
        return;
    }

    if (!isRelativeTimeStringUnit(unit)) {
        return;
    }

    switch (symbol) {
        case '+':
            return today.plus({ [unit]: amount });
        case '-':
            return today.minus({ [unit]: amount });
        default:
    }
}

export function relativeTimeStringToDisplay(
    str: string,
    options: { symbols: { '+': string; '-': string } | false } = { symbols: { '+': 'before', '-': 'after' } }
) {
    if (isEmptyString(str)) {
        return '';
    }
    if (!validateRelativeTimeString(str)) {
        return 'Incorrect value';
    }
    let formattedString = '';
    const { symbol, amountString, unit } = parseRelativeTimeString(str);
    formattedString += amountString + ' ' + unit;
    if (options.symbols) {
        switch (symbol) {
            case '+':
                formattedString += ` ${options.symbols['+']}`;
                break;
            case '-':
                formattedString += ` ${options.symbols['-']}`;
                break;
            default:
                return undefined;
        }
    }

    return formattedString;
}

export function getBirthdaySchemeTimeWindow(metricDefinition?: UserMetricDefinition) {
    if (metricDefinition?.query?.birthdate && 'or' in metricDefinition.query.birthdate) {
        let maxDay = 0;
        metricDefinition.query.birthdate.or.forEach(comparator => {
            if (isObject(comparator.dayOfMonth) && isString(comparator.dayOfMonth.eq)) {
                if (validateRelativeTimeString(comparator.dayOfMonth.eq)) {
                    const { amountString, unit } = parseRelativeTimeString(comparator.dayOfMonth.eq);
                    if (unit === 'days' && Number(amountString) > maxDay) {
                        maxDay = Number(amountString);
                    }
                }
            }
        });
        return maxDay;
    }
    return undefined;
}

export function birthdayDaysToRelativeTimeString(days: number): RelativeTimeString {
    return `${days <= 0 ? '+' : '-'}${Math.abs(days)}days@00:00`;
}
