import React from 'react';
import { Typography } from '@mui/material';
import { SettingDisplay } from 'lib/SettingDisplay';
import { isString } from 'lib/typeguards';

interface SettingDisplayTextProps {
    title?: string;
    text?: React.ReactNode;
    description?: string;
    crop?: boolean;
    buildtimeIOS?: boolean;
    buildtimeAndroid?: boolean;
    variant?: 'body2' | 'body1';
}

export const SettingDisplayText: React.FC<SettingDisplayTextProps> = ({
    text,
    title,
    description,
    crop,
    buildtimeIOS,
    buildtimeAndroid,
    variant = 'body2'
}) => {
    const displayValue = React.useMemo(() => {
        if (crop && isString(text) && text.length > 50) {
            return `${text.substring(0, 50)}...`;
        }
        return text;
    }, [crop, text]);
    return (
        <SettingDisplay
            label={title}
            description={description}
            buildtimeIOS={buildtimeIOS}
            buildtimeAndroid={buildtimeAndroid}
        >
            <Typography variant={variant} sx={{ wordBreak: 'break-word' }}>
                {displayValue}
            </Typography>
        </SettingDisplay>
    );
};
