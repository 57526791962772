import React from 'react';
import { Card } from '@mui/material';
import { TableSkeletonComponent } from 'ui/skeleton/TableSkeleton';
import { NotificationReceiptsTable, NotificationReceiptsTableProps } from '../NotificationReceiptsTable';

interface NotificationReceiptsCardProps {
    isLoading?: boolean;
}

export const NotificationReceiptsCard: React.FC<
    NotificationReceiptsCardProps & NotificationReceiptsTableProps
> = props => {
    const { isLoading, notificationId, notifications, showNotificationIdFilter, extraFilters, isUnsent } =
        props;

    return isLoading ? (
        <Card>
            <TableSkeletonComponent tableBodyRows={5} tableHeadColumns={2} />
        </Card>
    ) : (
        <NotificationReceiptsTable
            isUnsent={isUnsent}
            notificationId={notificationId}
            notifications={notifications}
            showNotificationIdFilter={showNotificationIdFilter}
            extraFilters={extraFilters}
        />
    );
};
