import React from 'react';
import { Grid } from '@mui/material';
import { CardViewTextRow } from 'ui/viewComponents';
import { getActionDate } from '../utils/formatDate';
import { ActionCardBase } from './ActionCardBase';
import { ActionCardModalComponentProps } from './ActionCardModalComponent';
import { isAwardPointsResetAction } from '../models/Action';
import { AwardTemplate } from '../../customers/models/Award';

export const ActionCardAwardPointsReset: React.FC<ActionCardModalComponentProps> = ({ action, perks }) => {
    if (!isAwardPointsResetAction(action)) {
        return <ActionCardBase action={action} broken />;
    }
    const perk =
        action.metadata.perkId &&
        Array.isArray(perks) &&
        perks.find(item => item._id === action.metadata.perkId);
    const { pointsAvailableBefore, pointsAvailableAfter } = action.metadata;
    return (
        <ActionCardBase action={action}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CardViewTextRow title="Date" value={getActionDate(action)} />
                </Grid>

                {perk && (
                    <Grid item xs={12}>
                        <CardViewTextRow title="Name" value={perk.title} />
                    </Grid>
                )}
                {perk && perk.template === AwardTemplate.STAMP_CARD && (
                    <Grid item xs={12}>
                        <CardViewTextRow
                            title="Points available before"
                            value={`${pointsAvailableBefore} Stamp${pointsAvailableBefore > 1 ? 's' : ''}`}
                        />
                    </Grid>
                )}
                {(!perk || perk.template === AwardTemplate.PSEUDO_CURRENCY) && (
                    <Grid item xs={12}>
                        <CardViewTextRow
                            title="Points available before"
                            value={`${pointsAvailableBefore} Point${pointsAvailableBefore > 1 ? 's' : ''}`}
                        />
                    </Grid>
                )}
                {perk && perk.template === AwardTemplate.STAMP_CARD && (
                    <Grid item xs={12}>
                        <CardViewTextRow
                            title="Points available after"
                            value={`${pointsAvailableAfter} Stamp${pointsAvailableAfter > 1 ? 's' : ''}`}
                        />
                    </Grid>
                )}
                {(!perk || perk.template === AwardTemplate.PSEUDO_CURRENCY) && (
                    <Grid item xs={12}>
                        <CardViewTextRow
                            title="Points available after"
                            value={`${pointsAvailableAfter} Point${pointsAvailableAfter > 1 ? 's' : ''}`}
                        />
                    </Grid>
                )}
            </Grid>
        </ActionCardBase>
    );
};
