import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import {
    fromModifierProductKey,
    MenuChange,
    MenuChangeSchema,
    ModifierOptionChangeUpdate
} from '@pepperhq/menu-sdk';
import { menuApi } from 'components/menu/MenuApi';
import { ImagePickerColumn } from 'lib/MuiGrid/Columns';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React, { useContext } from 'react';
import { BaseMenuTab, getMenuChangeDataToCopy, getModifierOptionsData, useMenuChange } from '../model/menu';
import * as Yup from 'yup';
import { useLeavePageBlock } from 'lib/hooks/useLeavePageBlock';
import { useDispatch } from 'react-redux';
import logger from 'lib/logger';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_MODIFIER_OPTIONS_UPDATE_ERROR,
    MESSAGE_MODIFIER_OPTIONS_UPDATE_SUCCESS
} from 'config/messages';
import { isEmptyString } from 'lib/typeguards';
import { useMenuGridSearch } from '../model/useMenuGridSearch';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { LocationsContext, useTabCopy } from '../useCopyTab';
import { ActionsHeader } from '../ActionsHeader';
import { NutritionGridColumn } from '../Columns';
import { renderMuiGridTextView } from 'lib/MuiGrid/text/MuiGridTextView';
import { useMenuSelection } from '../useMenuSelection';
import { useMenuDeleteControls } from '../model/useMenuDeleteControls';
import { renderEditPriceCell } from 'lib/MuiGrid/text/GridEditPriceCell';
import { MuiGridPriceView } from 'lib/MuiGrid/text/MuiGridPriceView';

type ModifierOptionsTabProps = BaseMenuTab;

const validationSchema = {
    sort: Yup.number().integer('Whole number only')
};

const renderIdCell = (params: GridRenderEditCellParams) => {
    const { id } = fromModifierProductKey(params.value);
    return renderMuiGridTextView(params, id);
};

export const ModifierOptionsTab: React.FC<ModifierOptionsTabProps> = ({
    menu,
    settings,
    menuChangeId,
    onUpdate,
    loadFreshMenu,
    change: externalChange,
    search,
    onSearchChange
}) => {
    const locations = useContext(LocationsContext);
    const dispatch = useDispatch();
    const {
        change: [change, setChange],
        readyToSave,
        handleCancel,
        handleStatusChange,
        gridRef
    } = useMenuChange(externalChange);

    const {
        selectedIds,
        resetSelection,
        selectedCell,
        handleSelectChange,
        handleCellSelection,
        copyEnabled,
        handleCellBlur
    } = useMenuSelection(!!locations.length);

    const { toggleIsCopyModalOpen, setMenuChangeToCopy, activeOperationId, renderLocationPickerModal } =
        useTabCopy(resetSelection, selectedCell, ['selected', 'imageUrl', 'sort', 'hide', 'nutrition']);

    const handleRefreshMenu = React.useCallback(() => {
        loadFreshMenu();
        gridRef.current.reset();
    }, [gridRef, loadFreshMenu]);

    const {
        editedOrDeleted,
        handleCancelEditing,
        handleDelete,
        deleted,
        deleteEnabled,
        resetDeleted,
        isCellEditable
    } = useMenuDeleteControls(selectedIds, readyToSave, handleCancel, resetSelection);

    const modifierOptions = React.useMemo(() => {
        if (!menu || !change) {
            return [];
        }
        return getModifierOptionsData(menu, change);
    }, [change, menu]);

    useLeavePageBlock(editedOrDeleted);
    const gridSearchOptions = React.useMemo(
        () => [{ column: 'id' }, { column: 'title' }, { column: 'modifierTitle' }],
        []
    );
    const { filterModel, renderInput } = useMenuGridSearch(
        gridSearchOptions,
        search,
        onSearchChange,
        'modifierOptions',
        'Search by ID, title or modifier'
    );

    const renderPriceCell = React.useCallback(
        (params: GridRenderCellParams) => (
            <MuiGridPriceView
                value={params.value ?? ''}
                currencyCode={settings?.region?.currencyCode || 'GBP'}
            />
        ),
        [settings?.region?.currencyCode]
    );

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'id',
                headerName: 'ID',
                width: 140,
                renderCell: renderIdCell
            },
            {
                field: 'title',
                headerName: 'Title',
                width: 250,
                editable: true
            },
            {
                field: 'modifierTitle',
                headerName: 'Modifier',
                width: 280
            },
            {
                field: 'hide',
                headerName: 'Exclude from Menu',
                width: 230,
                editable: true,
                headerAlign: 'left',
                type: 'boolean'
            },
            {
                field: 'nutrition',
                headerName: 'Nutrition Information',
                width: 250,
                editable: true,
                ...NutritionGridColumn(true)
            },
            {
                field: 'sort',
                headerName: 'Sort',
                editable: true,
                type: 'number',
                width: 120,
                headerAlign: 'left'
            },
            {
                field: 'price',
                headerName: 'Price',
                type: 'number',
                width: 130,
                headerAlign: 'left',
                editable: settings.menu?.productByCategoryIdEditingEnabled,
                renderCell: renderPriceCell,
                renderEditCell: props => renderEditPriceCell(props)
            },
            {
                field: 'imageUrl',
                headerName: 'Image',
                width: 300,
                ...ImagePickerColumn('modifier-option-', true, 200 / 200)
            }
        ],
        [renderPriceCell, settings.menu?.productByCategoryIdEditingEnabled]
    );
    const handleSubmit = React.useCallback(async () => {
        try {
            if (!gridRef.current) {
                throw new Error(MESSAGE_MODIFIER_OPTIONS_UPDATE_ERROR);
            }
            const errors = gridRef.current.validate();
            if (errors) {
                return;
            }
            const { changes } = gridRef.current.getState();
            const modifierOptionsChanges = Object.entries(changes).reduce<MenuChange['modifierOptions']>(
                (acc, [id, { _overriden, ...item }]) => {
                    const { id: modifierOptionId } = fromModifierProductKey(id);
                    if (!change?.modifierOptions || !change?.modifierOptions[id]) {
                        acc[modifierOptionId] = item;
                    } else {
                        acc[modifierOptionId] = { ...change.modifierOptions[id], ...item };
                    }
                    if (isEmptyString(acc[modifierOptionId].hide)) {
                        acc[modifierOptionId].hide = null;
                    }
                    if (isEmptyString(acc[modifierOptionId].sort)) {
                        acc[modifierOptionId].sort = null;
                    }
                    if (isEmptyString(acc[modifierOptionId].nutrition)) {
                        acc[modifierOptionId].nutrition = null;
                    }
                    if (isEmptyString(acc[modifierOptionId].title)) {
                        acc[modifierOptionId].title = null;
                    }
                    if (isEmptyString(acc[modifierOptionId].imageUrl)) {
                        acc[modifierOptionId].imageUrl = null;
                    }
                    return acc;
                },
                {}
            );
            if (deleted) {
                deleted.forEach(deletedOption => {
                    modifierOptionsChanges[deletedOption] = null;
                });
            }
            const result = await menuApi.updateMenuChange(menuChangeId, {
                modifierOptions: modifierOptionsChanges,
                schema: MenuChangeSchema.v5
            });
            if (!result.ok) {
                throw new Error(result.body.message);
            }
            setChange(result.body);
            onUpdate(result.body);
            resetDeleted();
            dispatch(enqueueSnackbar(MESSAGE_MODIFIER_OPTIONS_UPDATE_SUCCESS, { variant: 'success' }));
            gridRef.current.reset();
        } catch (e) {
            logger.error(e);
            dispatch(enqueueSnackbar(e.message, { variant: 'error' }));
        }
    }, [gridRef, deleted, menuChangeId, setChange, onUpdate, resetDeleted, dispatch, change.modifierOptions]);

    const handleCopyTo = React.useCallback(() => {
        toggleIsCopyModalOpen();
        const menuChange = getMenuChangeDataToCopy<ModifierOptionChangeUpdate>(
            change,
            selectedIds.map(compoundId => {
                const { id } = fromModifierProductKey(compoundId);
                return id;
            }),
            'modifierOptions',
            selectedCell,
            row => {
                const { id } = fromModifierProductKey(row.id);
                return id;
            }
        );
        setMenuChangeToCopy(menuChange);
    }, [toggleIsCopyModalOpen, change, selectedIds, selectedCell, setMenuChangeToCopy]);

    return (
        <React.Fragment>
            <Box pb={1}>
                <ActionsHeader
                    menu={menu}
                    onCancelEditing={handleCancelEditing}
                    onSubmit={handleSubmit}
                    activeOperationId={activeOperationId}
                    onCopy={handleCopyTo}
                    onRefreshMenu={handleRefreshMenu}
                    isEditedOrDeleted={editedOrDeleted}
                    copyEnabled={copyEnabled}
                    onDelete={handleDelete}
                    deleteEnabled={deleteEnabled}
                />
                {renderInput()}
            </Box>
            <MuiGrid
                rows={modifierOptions}
                columns={columns}
                stateRef={gridRef}
                onStatusChange={handleStatusChange}
                validationScheme={validationSchema}
                storageName={GridStorageName.ModifierOptions}
                filterModel={filterModel}
                disableColumnFilter
                checkboxSelection
                checkboxVisibility
                onRowSelectionModelChange={handleSelectChange}
                rowSelectionModel={selectedIds}
                onCellClick={handleCellSelection}
                deleted={deleted}
                isCellEditable={isCellEditable}
                handleCellBlur={handleCellBlur}
            />
            {renderLocationPickerModal()}
        </React.Fragment>
    );
};
