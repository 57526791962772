/* eslint-disable prefer-destructuring */
import { IPublicAndPrivateSettings, IPublicSettings } from '@pepperhq/location-sdk';
import {
    CategoryChangeUpdate,
    CategoryGroup,
    CategoryGroupChange,
    CategoryV,
    DayCode,
    DefaultVariantStrategy,
    DynamicImagery,
    fromScopedKey,
    isDynamicImagery,
    isMenuChangeUpdate,
    MenuChange,
    MenuChangeSchema,
    MenuChangeUpdate,
    MenuChannel,
    MenuChannelFlags,
    MenuV,
    Modifier,
    ModifierOption,
    OrderScenario,
    Product,
    Tax,
    TaxChange,
    TimePeriod,
    TimesByDay,
    TimesByDayByZone,
    toModifierProductKey,
    toScopedKey,
    Suggestion
} from '@pepperhq/menu-sdk';
import { EMuiGridStatus, GridStateHelper } from 'lib/MuiGrid/MuiGrid';
import React from 'react';
import merge from 'deepmerge';
import { isDefined, isEmptyString, isString } from 'lib/typeguards';
import { roundToDecimal } from 'lib/helpers';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { MenuSearchKey } from './useMenuGridSearchState';
import { EPointOfSaleProvider } from 'components/settings/PointOfSaleSettings';

export type MenuType = MenuV<6>;
export type CategoryType = CategoryV;
export type ProductType = Product;

export interface BaseMenuTab {
    menu: MenuV<6>;
    settings: IPublicAndPrivateSettings;
    menuChangeId: string;
    locationId?: string;
    loadFreshMenu: () => void;
    change: MenuChange;
    onUpdate: (change: MenuChange) => void;
    search: string;
    onSearchChange: (value: string, name: MenuSearchKey) => void;
}

export interface TableRowWithOverrides {
    _overriden?: Set<string>;
}

export interface EnrichedCategoryGroup extends TableRowWithOverrides, CategoryGroupChange {
    id?: string;
    availableZones?: string[];
    availableTimesByDay?: TimesByDay;
    availableTimesByDayByZone?: TimesByDayByZone;
}

export interface EnrichedCategory extends TableRowWithOverrides, CategoryType {
    imageUrl?: string;
    perkMatchCodes?: string[];
    taxIds?: string[];
    defaultVariantStrategy?: DefaultVariantStrategy;
    hide?: boolean;
    availableScenarios?: OrderScenario[];
    availableTimes?: TimePeriod[];
    availableZones?: string[];
    availableDays?: DayCode[];
    availableTimesByDay?: TimesByDay;
    availableTimesByDayByZone?: TimesByDayByZone;
    categoryGroups?: string[];
}

export interface EnrichedModifier extends TableRowWithOverrides, Modifier {
    hide?: boolean;
    category?: string;
    selectedOptions?: string[];
    displayOptions: string[];
}

export interface EnrichedModifierOption extends TableRowWithOverrides, ModifierOption {
    hide?: boolean;
    modifierId: string;
    modifierTitle: string;
}

export interface EnrichedTagValue {
    id: string;
    title: string;
    sort?: number;
    imageUrl?: string;
}

export interface EnrichedTag extends TableRowWithOverrides {
    id?: string;
    sort: number;
    title: string;
    selected?: boolean;
    isFilter: boolean;
    values: EnrichedTagValue[];
}

export interface EnrichedDynamicImagery extends TableRowWithOverrides, DynamicImagery {
    id: string;
    productId: string;
}

export interface EnrichedTax extends TableRowWithOverrides, Tax {
    productIds: string[];
    categoryIds: string[];
}

export interface TaxesData {
    taxes: EnrichedTax[];
    categoryIdToTitle: Map<string, string>;
    productIdToTitle: Map<string, string>;
    categoryProducts: Record<string, string[]>;
}

const weeklyAvailabilityOverridenFields = new Set([
    'zone',
    DayCode.MON,
    DayCode.TUE,
    DayCode.WED,
    DayCode.THU,
    DayCode.FRI,
    DayCode.SAT,
    DayCode.SUN
]);
export interface WeeklyAvailability extends TableRowWithOverrides {
    id: string;
    categoryGroupId?: string;
    categoryId?: string;
    zone?: string;
    [DayCode.MON]?: TimePeriod[];
    [DayCode.TUE]?: TimePeriod[];
    [DayCode.WED]?: TimePeriod[];
    [DayCode.THU]?: TimePeriod[];
    [DayCode.FRI]?: TimePeriod[];
    [DayCode.SAT]?: TimePeriod[];
    [DayCode.SUN]?: TimePeriod[];
}

export type ProductSuggestionType = Suggestion['type'];

function enrichCategoryGroup(categoryGroup: CategoryGroup, menuChange: MenuChange): EnrichedCategoryGroup {
    if (!menuChange.categoryGroups || !menuChange.categoryGroups[categoryGroup.id]) {
        return categoryGroup;
    }
    const categoryGroupChange = menuChange.categoryGroups[categoryGroup.id];
    return {
        ...categoryGroup,
        ...categoryGroupChange,
        _overriden: new Set(Object.keys(categoryGroupChange))
    };
}

function enrichTax(tax: Tax, menuChange: MenuChange): Partial<EnrichedTax> {
    if (!menuChange.options?.taxes || !menuChange.options.taxes[tax.id]) {
        return tax;
    }
    return {
        ...tax,
        ...menuChange.options.taxes[tax.id],
        _overriden: new Set(Object.keys(menuChange.options.taxes[tax.id]))
    };
}

export function getCategoryGroupsData(menu: MenuV<6>, menuChange: MenuChange): EnrichedCategoryGroup[] {
    const menuGroups = Object.values(menu.categoryGroups || {}).map(item =>
        enrichCategoryGroup(item, menuChange)
    );
    const changeGroups = Object.entries(menuChange.categoryGroups || {}).reduce((acc, [id, change]) => {
        if (!menu.categoryGroups[id]) {
            const _overriden = new Set(Object.keys(change));
            _overriden.add('id');
            acc.push({ ...change, id, _overriden });
        }
        return acc;
    }, []);
    return [...menuGroups, ...changeGroups];
}

export function getCategoryGroupIdToTitle(menu: MenuV<6>, menuChange: MenuChange): Record<string, string> {
    const categoryGroupIdToTitle: Record<string, string> = {};
    if (menu.categoryGroups) {
        Object.entries(menu.categoryGroups).forEach(([id, item]) => {
            categoryGroupIdToTitle[id] = item.title;
        });
    }
    Object.entries(menuChange.categoryGroups || {}).forEach(([id, change]) => {
        if (isString(change.title)) {
            categoryGroupIdToTitle[id] = change.title;
        }
    }, []);
    return categoryGroupIdToTitle;
}

const WEEKLY_AVAILABILITY_SPLITTER = '*/';
export function getWeeklyAvailabilityId(categoryGroupId?: string, categoryId?: string, zone?: string) {
    return `${categoryGroupId ?? ''}${WEEKLY_AVAILABILITY_SPLITTER}${
        categoryId ?? ''
    }${WEEKLY_AVAILABILITY_SPLITTER}${zone ?? ''}`;
}

export function getWeeklyAvailabilityParts(id: string) {
    const parts = isString(id) && id.split(WEEKLY_AVAILABILITY_SPLITTER);
    if (!Array.isArray(parts) || parts.length !== 3) {
        return undefined;
    }
    const [categoryGroupId, categoryId, zone] = parts;
    return {
        categoryGroupId: categoryGroupId === '' ? undefined : categoryGroupId,
        categoryId: categoryId === '' ? undefined : categoryId,
        zone: zone === '' ? undefined : zone
    };
}

export function getWeeklyAvailabilityData(
    menu: MenuV<6>,
    menuChange: MenuChange,
    temporaryItems?: Record<number, WeeklyAvailability>
): WeeklyAvailability[] {
    const weeklyAvailability: WeeklyAvailability[] = [];
    const categoryGroups = merge<Record<string, EnrichedCategoryGroup>>(
        menu.categoryGroups || {},
        menuChange?.categoryGroups || {}
    );
    Object.entries(categoryGroups).forEach(([id, group]) => {
        if (group.availableTimesByDayByZone) {
            Object.entries(group.availableTimesByDayByZone).forEach(([zone, days]) => {
                weeklyAvailability.push({
                    id: getWeeklyAvailabilityId(id, undefined, zone),
                    categoryGroupId: id,
                    ...days,
                    zone
                });
            });
        }
        if (group.availableTimesByDay) {
            weeklyAvailability.push({
                id: getWeeklyAvailabilityId(id),
                categoryGroupId: id,
                ...group.availableTimesByDay
            });
        }
    });
    Object.entries(menuChange?.categories || {}).forEach(([id, category]) => {
        if (category.availableTimesByDayByZone) {
            Object.entries(category.availableTimesByDayByZone).forEach(([zone, days]) => {
                weeklyAvailability.push({
                    id: getWeeklyAvailabilityId(undefined, id, zone),
                    categoryId: id,
                    ...days,
                    zone
                });
            });
        }
        if (category.availableTimesByDay) {
            weeklyAvailability.push({
                id: getWeeklyAvailabilityId(undefined, id),
                categoryId: id,
                ...category.availableTimesByDay
            });
        }
    });
    if (temporaryItems) {
        Object.entries(temporaryItems).forEach(([id, item]) => {
            weeklyAvailability.push({
                id,
                ...item
            });
        });
    }
    return weeklyAvailability.map(item => ({
        ...item,
        _overriden: new Set(weeklyAvailabilityOverridenFields)
    }));
}

export function getTaxesData(menu: MenuV<6>, menuChange: MenuChange): TaxesData {
    const categoryIdToTitle = new Map<string, string>();
    const productIdToTitle = new Map<string, string>();
    const taxCategories: Record<string, string[]> = {};
    const taxProducts: Record<string, string[]> = {};
    const categoryProducts: Record<string, string[]> = {};
    menu.categories.forEach(category => {
        categoryIdToTitle.set(
            category.id,
            (menuChange?.categories && menuChange.categories[category.id]?.title) ?? category.title
        );
        if (category) {
            categoryProducts[category.id] = [];
            category.products.forEach(product => {
                categoryProducts[category.id].push(product.id);
                const changes = menuChange?.products && menuChange?.products[product.id];
                const productGroupChange =
                    isDefined(product.productGroupId) &&
                    menuChange?.productGroups &&
                    menuChange.productGroups[product.productGroupId];
                const productGroupName = productGroupChange?.name ?? product.productGroupName;
                const productName = changes?.title ?? product.title;
                const productTaxes =
                    (menuChange?.products && menuChange.products[product.id]?.taxIds) || product.taxIds;
                if (productTaxes?.length) {
                    productTaxes.forEach(tax => {
                        taxProducts[tax] = [...(taxProducts[tax] || []), product.id];
                    });
                }
                productIdToTitle.set(
                    product.id,
                    productGroupName ? `${productGroupName} - ${productName}` : productName
                );
            });
        }
    });
    Object.entries(menuChange?.categories || {}).forEach(([categoryId, change]) => {
        const currentTitle = categoryIdToTitle.get(categoryId);
        if (isDefined(change?.title)) {
            categoryIdToTitle.set(categoryId, change.title);
        } else if (!isDefined(currentTitle)) {
            categoryIdToTitle.set(categoryId, categoryId);
        }
        const categoryTaxes = change?.taxIds;
        if (categoryTaxes?.length) {
            categoryTaxes.forEach(tax => {
                taxCategories[tax] = [...(taxCategories[tax] || []), categoryId];
            });
        }
    });
    Object.entries(menuChange?.products || {}).forEach(([productId, change]) => {
        const currentTitle = productIdToTitle.get(productId);
        if (!isDefined(currentTitle)) {
            if (isDefined(change.title)) {
                productIdToTitle.set(productId, `${change.title} [${productId}]`);
            } else {
                productIdToTitle.set(productId, productId);
            }
            (change?.taxIds || []).forEach(tax => {
                taxProducts[tax] = [...(taxProducts[tax] || []), productId];
            });
        }
    });

    const taxes = Object.values(menu.options?.taxes || {}).map(item => enrichTax(item, menuChange));
    const changeTaxes = Object.entries(menuChange.options?.taxes || {}).reduce((acc, [id, change]) => {
        if (!menu?.options?.taxes || !menu?.options?.taxes.find(tax => tax.id === id)) {
            const _overriden = new Set(Object.keys(change));
            _overriden.add('id');
            if (taxProducts[id]) {
                _overriden.add('productIds');
            }
            if (taxCategories[id]) {
                _overriden.add('categoryIds');
            }
            acc.push({ ...change, id, _overriden });
        }
        return acc;
    }, []);
    const resultTaxes = [...taxes, ...changeTaxes].map((item: Tax) => ({
        ...item,
        rate: roundToDecimal(item.rate * 100, 3),
        productIds: taxProducts[item.id],
        categoryIds: taxCategories[item.id]
    }));
    return { taxes: resultTaxes, productIdToTitle, categoryIdToTitle, categoryProducts };
}

export const menuChannelsFlagsToArray = (value: MenuChannelFlags) => {
    const channels: MenuChannel[] = [];
    Object.values(MenuChannel).forEach(key => {
        if (!isDefined(value[key]) || value[key]) {
            channels.push(key);
        }
    });
    return channels;
};

export const menuChannelsArrayToFlags = (value?: MenuChannel[]) => {
    if (!value || !value.length) {
        return null;
    }
    const flags: MenuChannelFlags = {};
    Object.values(MenuChannel).forEach(key => {
        flags[key] = value.includes(key);
    });
    return flags;
};

export function getCategoriesData(menu: MenuV<6>, menuChange: MenuChange): EnrichedCategory[] {
    const categoryGroups = merge(menu.categoryGroups || {}, menuChange.categoryGroups || {});
    return menu.categories.map(item => {
        const categoryChange = menuChange.categories?.[item.id];
        return {
            ...item,
            ...categoryChange,
            channels: categoryChange?.channels
                ? menuChannelsFlagsToArray(categoryChange.channels)
                : undefined,
            _overriden: categoryChange ? new Set(Object.keys(categoryChange)) : undefined,
            categoryGroups: Object.entries(categoryGroups)
                .filter(([_, group]) => group.categoryIds?.includes(item.id))
                .map(([_, group]) => group.title)
        };
    });
}

const mapModifierToData =
    (menuChange: MenuChange, modifiers: Record<string, EnrichedModifier>, category?: CategoryType) =>
    (modifier: Modifier) => {
        const categoryChangeTitle =
            category && menuChange?.categories && menuChange.categories[category.id]?.title;
        const selectedOptions: string[] = [];
        const displayOptions: string[] = [];
        let isSelectedOptionsOverriden = false;
        if (Array.isArray(modifier.options)) {
            modifier.options.forEach(option => {
                displayOptions.push(option.id);
                if (menuChange?.modifierOptions?.[option.id]?.selected ?? option.selected) {
                    if (menuChange?.modifierOptions?.[option.id]?.selected) {
                        isSelectedOptionsOverriden = true;
                    }
                    selectedOptions.push(option.id);
                }
            });
        }
        if (Array.isArray(modifier.products)) {
            modifier.products.forEach(option => {
                displayOptions.push(option.id);
                const isSelectedOnMenuChange =
                    menuChange.modifierProducts &&
                    ((menuChange.modifierProducts[
                        toModifierProductKey(option.id, modifier.id, category?.id)
                    ] &&
                        menuChange.modifierProducts[
                            toModifierProductKey(option.id, modifier.id, category?.id)
                        ].selected) ||
                        (menuChange.modifierProducts[`${option.id}`] &&
                            menuChange.modifierProducts[`${option.id}`].selected));
                if (isSelectedOnMenuChange ?? option.selected) {
                    if (isSelectedOnMenuChange) {
                        isSelectedOptionsOverriden = true;
                    }
                    selectedOptions.push(option.id);
                }
            });
        }
        const modifierChange = (menuChange?.modifiers && menuChange?.modifiers[modifier.id]) || {};
        const _overriden = new Set(Object.keys(modifierChange));
        if (isSelectedOptionsOverriden) {
            _overriden.add('selectedOptions');
        }
        const modifierId = category ? toScopedKey(category?.id, modifier.id) : modifier.id;
        modifiers[modifierId] = {
            ...modifier,
            id: modifierId,
            ...modifierChange,
            category: categoryChangeTitle ?? category?.title,
            selectedOptions,
            displayOptions,
            _overriden
        };
    };

export function getModifiersData(menu: MenuV<6>, menuChange: MenuChange): EnrichedModifier[] {
    const modifiers: Record<string, EnrichedModifier> = {};
    if (!menu || !Array.isArray(menu.categories)) {
        return [];
    }
    menu.modifiers.forEach(mapModifierToData(menuChange, modifiers));
    menu.categories.forEach(category => {
        if (category) {
            category.modifiers?.forEach(mapModifierToData(menuChange, modifiers, category));
        }
    });
    // find category specific overrides and add them as separate entries to the table
    Object.entries(menuChange.modifiers ?? {}).forEach(([modifierChangeId, modifierChange]) => {
        const { parentId, id } = fromScopedKey(modifierChangeId);
        const category = menu.categories.find(item => parentId === item.id);
        const globalModifierChange = modifiers[id];
        if (isDefined(parentId) && !modifiers[modifierChangeId] && globalModifierChange && category) {
            const enrichedModifier = { ...globalModifierChange, ...modifierChange, id: modifierChangeId };
            enrichedModifier.category =
                (menuChange?.categories && menuChange.categories[category.id]?.title) ?? category.title;
            const _overriden = new Set(Object.keys(enrichedModifier));
            enrichedModifier._overriden = _overriden;
            modifiers[modifierChangeId] = enrichedModifier;
        }
    });
    return Object.values(modifiers);
}

export function getModifierOptionsData(menu: MenuV<6>, menuChange: MenuChange): EnrichedModifierOption[] {
    const modifierOptions: EnrichedModifierOption[] = [];
    if (!menu || !Array.isArray(menu.categories)) {
        return modifierOptions;
    }
    menu.modifiers.forEach(modifier => {
        if (Array.isArray(modifier.options)) {
            modifier.options.forEach(option => {
                const optionChange = menuChange.modifierOptions && menuChange.modifierOptions?.[option.id];
                modifierOptions.push({
                    ...option,
                    ...optionChange,
                    _overriden: new Set(Object.keys(optionChange ?? {})),
                    id: toModifierProductKey(option.id, modifier.id, undefined),
                    modifierId: modifier.id,
                    modifierTitle:
                        (menuChange?.modifiers && menuChange.modifiers[modifier.id]?.title) ?? modifier.title
                });
            });
        }
    });
    menu.categories.forEach(category => {
        if (category) {
            category.modifiers?.forEach(modifier => {
                const categoryChangeTitle =
                    menuChange?.categories && menuChange?.categories[category.id]?.title;
                if (Array.isArray(modifier.options)) {
                    modifier.options.forEach(option => {
                        const optionChange =
                            menuChange.modifierOptions && menuChange.modifierOptions?.[option.id];
                        modifierOptions.push({
                            ...option,
                            ...optionChange,
                            _overriden: new Set(Object.keys(optionChange ?? {})),
                            id: toModifierProductKey(option.id, modifier.id, category.id),
                            modifierId: modifier.id,
                            modifierTitle: `${categoryChangeTitle ?? category.title} - ${
                                (menuChange?.modifiers &&
                                    (menuChange.modifiers[toScopedKey(category.id, modifier.id)]?.title ??
                                        menuChange.modifiers[modifier.id]?.title)) ??
                                modifier.title
                            }`
                        });
                    });
                }
            });
        }
    });
    return modifierOptions;
}

export function getTagsData(menu: MenuV<6>, menuChange: MenuChange): EnrichedTag[] {
    const tags: EnrichedTag[] = [];
    if (!menu || !menu.options || !Array.isArray(menu.options.tags)) {
        return tags;
    }
    if (menuChange?.options?.tags) {
        Object.entries(menuChange.options.tags).forEach(([tagId, tag]) => {
            const _overriden = new Set(Object.keys(tag));
            _overriden.add('id');
            tags.push({ id: tagId, ...tag, _overriden });
        });
    }
    return tags;
}

const dynamicImageryOverridenFields = new Set(['baseImageUrl', 'type']);
export function getDynamicImagesData(menuChange: MenuChange): EnrichedDynamicImagery[] {
    const dynamicImages: (EnrichedDynamicImagery & { type?: 'Layer' | 'Box' })[] = [];
    if (!menuChange || !menuChange.products) {
        return dynamicImages;
    }
    Object.entries(menuChange.products).forEach(([id, { dynamicImagery }]) => {
        if (dynamicImagery) {
            dynamicImages.push({
                ...dynamicImagery,
                id,
                type: !dynamicImagery?.containers.some(container => container.division) ? 'Layer' : 'Box',
                productId: id,
                _overriden: dynamicImageryOverridenFields
            });
        }
    });
    return dynamicImages;
}

export type ModifierOptionsMapItem = Record<string, { title: string; price?: number }>;
export type ModifierOptionsMap = Map<string, ModifierOptionsMapItem>;

const mapModifierToModifierOptions =
    (
        menuChange: MenuChange,
        menu: MenuType,
        modifierMap: Map<string, ModifierOptionsMapItem>,
        category?: CategoryType
    ) =>
    (modifier: Modifier) => {
        const modifierOptions: ModifierOptionsMapItem = {};
        if (Array.isArray(modifier.products)) {
            modifier.products.forEach(modifierProduct => {
                let productName: string;
                let groupName: string | undefined;
                let price: number;
                if (
                    menuChange.products &&
                    menuChange.products[modifierProduct.id] &&
                    menuChange.products[modifierProduct.id].title
                ) {
                    productName = menuChange.products[modifierProduct.id].title;
                }
                let currentProduct;
                if (isDefined(modifierProduct.categoryId)) {
                    const productCategory = menu.categories.find(
                        item => item.id === modifierProduct.categoryId
                    );
                    if (productCategory) {
                        currentProduct = productCategory.products.find(
                            item => item.id === modifierProduct.id
                        );
                    }
                }
                if (!currentProduct) {
                    let i = 0;
                    while (!currentProduct && i < menu.categories.length) {
                        currentProduct = menu.categories[i].products.find(
                            product => product.id === modifierProduct.id
                        );
                        i++;
                    }
                }
                if (
                    menuChange.productGroups &&
                    menuChange.productGroups[currentProduct?.productGroupId] &&
                    isDefined(menuChange.productGroups[currentProduct?.productGroupId].name)
                ) {
                    groupName = menuChange.productGroups[currentProduct?.productGroupId].name;
                }
                if (currentProduct) {
                    if (!isDefined(productName)) {
                        productName = currentProduct.title;
                    }
                    if (!isDefined(groupName)) {
                        groupName = currentProduct.productGroupName;
                    }
                    price = currentProduct.price;
                }
                modifierOptions[modifierProduct.id] = {
                    title: groupName ? `${groupName} - ${productName}` : productName,
                    price
                };
            });
        }
        if (Array.isArray(modifier.options)) {
            modifier.options.forEach(modifierOption => {
                if (menuChange?.modifierOptions?.[modifierOption.id]?.title) {
                    modifierOptions[modifierOption.id] = {
                        title: menuChange.modifierOptions[modifierOption.id].title
                    };
                } else {
                    modifierOptions[modifierOption.id] = { title: modifierOption.title };
                }
                modifierOptions[modifierOption.id].price = modifierOption.price;
            });
        }
        modifierMap.set(category ? toScopedKey(category.id, modifier.id) : modifier.id, modifierOptions);
    };

export function getModifierIdModifierOptions(menu: MenuV<6>, menuChange: MenuChange): ModifierOptionsMap {
    const modifierMap: ModifierOptionsMap = new Map();
    if (!menu || !Array.isArray(menu.categories)) {
        return modifierMap;
    }
    menu.modifiers.forEach(mapModifierToModifierOptions(menuChange, menu, modifierMap));
    menu.categories.forEach(category => {
        category.modifiers?.forEach(mapModifierToModifierOptions(menuChange, menu, modifierMap, category));
    });
    return modifierMap;
}

export function getTaxIdToTitle(menu: MenuV<6>, menuChange: MenuChange): Record<string, string> {
    if (!menu || !menu.options) {
        return {};
    }
    const menuTaxes = menu.options.taxes.reduce<Record<string, string>>((acc, item) => {
        acc[item.id] = item.title;
        return acc;
    }, {});
    const changeTaxes = Object.entries(menuChange?.options?.taxes || {}).reduce<Record<string, string>>(
        (acc, [id, item]) => {
            acc[id] = item.title;
            return acc;
        },
        {}
    );
    return { ...menuTaxes, ...changeTaxes };
}

export function getModifierIdToTitle(menu: MenuV<6>, menuChange: MenuChange): Record<string, string> {
    if (!menu || !Array.isArray(menu.categories)) {
        return {};
    }
    const menuModifiers = menu.modifiers.reduce<Record<string, string>>((acc, modifier) => {
        acc[modifier.id] = modifier.title;
        return acc;
    }, {});
    const categoryModifiers = menu.categories.reduce<Record<string, string>>((acc, item) => {
        item.modifiers?.forEach(modifier => {
            acc[toScopedKey(item.id, modifier.id)] = modifier.title;
        });
        return acc;
    }, {});
    const changeModifiers = Object.entries(menuChange?.modifiers || {}).reduce<Record<string, string>>(
        (acc, [id, item]) => {
            acc[id] = item.title;
            return acc;
        },
        {}
    );
    return { ...menuModifiers, ...categoryModifiers, ...changeModifiers };
}

export function getTagIdToTitle(menu: MenuV<6>, menuChange: MenuChange): Record<string, string> {
    if (!menu || !menu.options) {
        return {};
    }
    const menuTags = menu.options.tags.reduce<Record<string, string>>((acc, item) => {
        item.values.forEach(value => {
            const valueId = `${item.id}/${value.id}`;
            acc[valueId] = `${item.title} - ${value.title}`;
        });
        return acc;
    }, {});
    const changeTags = Object.values(menuChange?.options?.tags || {}).reduce<Record<string, string>>(
        (acc, item) => {
            if (Array.isArray(item?.values)) {
                item.values.forEach(value => {
                    const valueId = `${item.id}/${value.id}`;
                    acc[valueId] = `${item.title} - ${value.title}`;
                });
            }
            return acc;
        },
        {}
    );
    return { ...menuTags, ...changeTags };
}

interface UseMenuChange {
    change: [MenuChange, React.Dispatch<React.SetStateAction<MenuChange>>];
    gridRef: React.MutableRefObject<GridStateHelper>;
    readyToSave: boolean;
    handleCancel: () => void;
    handleStatusChange: (status: EMuiGridStatus) => void;
}

interface UseMenuOverrideChange {
    gridRef: React.MutableRefObject<GridStateHelper>;
    readyToSave: boolean;
    handleCancel: () => void;
    handleStatusChange: (status: EMuiGridStatus) => void;
}

export function useMenuChange(externalChange: MenuChange): UseMenuChange {
    const [change, setChange] = React.useState<MenuChange>(externalChange);
    const gridRef = React.useRef<GridStateHelper>(null);
    const [readyToSave, setReadyToSave] = React.useState(false);
    React.useEffect(() => {
        setChange(externalChange);
    }, [externalChange]);
    const handleCancel = React.useCallback(() => {
        if (gridRef.current) {
            gridRef.current.reset();
            setChange({ ...externalChange });
        }
    }, [externalChange]);
    const handleStatusChange = React.useCallback(
        (status: EMuiGridStatus) => {
            if (readyToSave && status === EMuiGridStatus.DEFAULT) {
                setReadyToSave(false);
            }
            if (!readyToSave && status === EMuiGridStatus.CHANGED) {
                setReadyToSave(true);
            }
        },
        [readyToSave]
    );
    return { change: [change, setChange], gridRef, readyToSave, handleCancel, handleStatusChange };
}

export function useMenuOverrideChange(resetOverrides: () => void): UseMenuOverrideChange {
    const gridRef = React.useRef<GridStateHelper>(null);
    const [readyToSave, setReadyToSave] = React.useState(false);

    const handleCancel = React.useCallback(() => {
        if (gridRef.current) {
            gridRef.current.reset();
            resetOverrides();
        }
    }, [resetOverrides]);
    const handleStatusChange = React.useCallback(
        (status: EMuiGridStatus) => {
            if (readyToSave && status === EMuiGridStatus.DEFAULT) {
                setReadyToSave(false);
            }
            if (!readyToSave && status === EMuiGridStatus.CHANGED) {
                setReadyToSave(true);
            }
        },
        [readyToSave]
    );
    return { gridRef, readyToSave, handleCancel, handleStatusChange };
}

export const scenarioLabel: Record<OrderScenario, string> = {
    [OrderScenario.ORDER_TO_TABLE]: 'Order To Table',
    [OrderScenario.PAYATPOS]: 'Pay At POS',
    [OrderScenario.PREORDER]: 'Pre-Order',
    [OrderScenario.TAB]: 'TAB',
    [OrderScenario.TABLE]: 'Pay at Table'
};

export const menuChannelLabel: Record<MenuChannel, string> = {
    [MenuChannel.APP]: 'Mobile App',
    [MenuChannel.WEB]: 'Web App',
    [MenuChannel.TERMINAL]: 'Quickpad'
};

export const dayLabel: Record<DayCode, string> = {
    [DayCode.MON]: 'Monday',
    [DayCode.TUE]: 'Tuesday',
    [DayCode.WED]: 'Wednesday',
    [DayCode.THU]: 'Thursday',
    [DayCode.FRI]: 'Friday',
    [DayCode.SAT]: 'Saturday',
    [DayCode.SUN]: 'Sunday'
};

export const getTagsToCopy = (
    menuChange: MenuChange,
    selectedIds: string[],
    selectedCell?: GridCellParams<EnrichedTag>,
    getChangeId?: (row: GridCellParams['row']) => string
): Record<string, EnrichedTag> => {
    if (selectedIds.length > 0) {
        const selectedIdsSet = new Set(selectedIds);
        if (!menuChange || !isMenuChangeUpdate(menuChange)) {
            return {};
        }
        const {
            options: { tags: allTags }
        } = menuChange;
        const tagChanges: Record<string, EnrichedTag> = {};
        Object.entries(allTags).forEach(([id, value]) => {
            if (selectedIdsSet.has(id)) {
                tagChanges[id] = value;
            }
        });
        return tagChanges;
    }
    if (selectedCell) {
        const changes: Record<string, EnrichedTag> = {};
        const rowChanges: any = {
            [selectedCell.field]: selectedCell.value
        };
        changes[getChangeId(selectedCell.row)] = rowChanges;

        return changes;
    }
    return {};
};

export const getTaxesToCopy = (
    menuChange: MenuChange,
    selectedIds: string[],
    selectedCell?: GridCellParams,
    getChangeId?: (row: GridCellParams['row']) => string
): Record<string, TaxChange> => {
    if (selectedIds.length > 0) {
        const selectedIdsSet = new Set(selectedIds);
        if (!menuChange || !isMenuChangeUpdate(menuChange)) {
            return {};
        }
        const {
            options: { taxes: allTaxes }
        } = menuChange;
        if (!allTaxes) {
            return {};
        }
        const taxChanges: Record<string, TaxChange> = {};
        Object.entries(allTaxes).forEach(([id, value]) => {
            if (selectedIdsSet.has(id)) {
                taxChanges[id] = value;
            }
        });
        return taxChanges;
    }
    if (selectedCell) {
        const changes: Record<string, Partial<TaxChange>> = {};
        changes[getChangeId(selectedCell.row)] = {
            [selectedCell.field as keyof TaxChange]: selectedCell.value
        } as Partial<TaxChange>;

        return changes;
    }
};

type MenuChangeUpdateField = (typeof allowableFields)[number];
const allowableFields = [
    'categoryGroups',
    'categories',
    'products',
    'modifiers',
    'modifierOptions',
    'modifierProducts'
] as const;

const getMenuChangeToCopyByField = (cell: GridCellParams<unknown, MenuChannel[]>) => {
    switch (cell.field) {
        case 'channels':
            if (isEmptyString(cell.value)) {
                return null;
            }
            return menuChannelsArrayToFlags(cell.value);

        default:
            return cell.value;
    }
};

export const getMenuChangeDataToCopy = <Type extends Record<string, any>>(
    menuChange: MenuChange,
    selectedIds: string[],
    field: MenuChangeUpdateField,
    selectedCell?: GridCellParams<unknown, MenuChannel[]>,
    getChangeId?: (row: GridCellParams['row']) => string
): MenuChangeUpdate => {
    const update: MenuChangeUpdate = {
        schema: MenuChangeSchema.v5
    };

    if (menuChange && selectedIds.length > 0 && menuChange[field] && isMenuChangeUpdate(menuChange)) {
        const selectedIdsSet = new Set(selectedIds);
        const all = menuChange[field];
        const changes: Record<string, Type> = {};
        Object.entries(all).forEach(([id, { _overriden, ...value }]) => {
            if (selectedIdsSet.has(id)) {
                changes[id] = value;
                if (field === 'categories' || field === 'categoryGroups') {
                    const withoutTimes = changes[id] as CategoryChangeUpdate;
                    delete withoutTimes.availableTimesByDay;
                    delete withoutTimes.availableTimesByDayByZone;
                }
            }
        });
        update[field] = changes;
    } else if (selectedCell) {
        const changes: Record<string, Partial<Type>> = {};
        changes[getChangeId(selectedCell.row)] = {
            [selectedCell.field as keyof Type]: getMenuChangeToCopyByField(selectedCell)
        } as Partial<Type>;
        update[field] = changes;
    }
    return update;
};

export const getDynamicImageryToCopy = (
    allRows: EnrichedDynamicImagery[],
    selectedRows: Array<EnrichedDynamicImagery['id']>
) => {
    const dynamicImageryChanges: MenuChangeUpdate['products'] = {};
    const selectedIds = new Set(selectedRows);
    for (const row of allRows) {
        if (selectedIds.has(row.id)) {
            const { id: _a, productId: _b, _overriden, ...model } = row;
            if (isDynamicImagery(model)) {
                dynamicImageryChanges[row.id] = { dynamicImagery: model };
            }
        }
    }
    return dynamicImageryChanges;
};

export const getWeeklyAvailabilityToCopy = (menuChange: MenuChange, selectedRows: string[]) => {
    const update: MenuChangeUpdate = {
        schema: MenuChangeSchema.v5,
        categoryGroups: {},
        categories: {}
    };
    const { categoryGroups: allCategoryGroups, categories: allCategories } = menuChange;
    selectedRows.forEach(row => {
        const {
            categoryGroupId: selectedCategoryGroup,
            categoryId: selectedCategory,
            zone: selectedZone
        } = getWeeklyAvailabilityParts(row);
        if (selectedZone) {
            // availableTimesByDayByZone
            if (selectedCategoryGroup) {
                update.categoryGroups[selectedCategoryGroup] = {
                    availableTimesByDayByZone: {
                        [selectedZone]:
                            allCategoryGroups[selectedCategoryGroup].availableTimesByDayByZone[selectedZone]
                    }
                };
            }
            if (selectedCategory) {
                update.categories[selectedCategory] = {
                    availableTimesByDayByZone: {
                        [selectedZone]:
                            allCategories[selectedCategory].availableTimesByDayByZone[selectedZone]
                    }
                };
            }
        } else {
            // availableTimesByDay only
            if (selectedCategoryGroup) {
                update.categoryGroups[selectedCategoryGroup] = {
                    availableTimesByDay: allCategoryGroups[selectedCategoryGroup].availableTimesByDay
                };
            }
            if (selectedCategory) {
                update.categories[selectedCategory] = {
                    availableTimesByDay: allCategories[selectedCategory].availableTimesByDay
                };
            }
        }
    });
    return update;
};

export const isModifiersMinMaxSelectSupported = (settings: IPublicSettings) => {
    const supportedProviders: string[] = [
        EPointOfSaleProvider.SQUARE,
        EPointOfSaleProvider.IBS,
        EPointOfSaleProvider.DELIVERECT,
        EPointOfSaleProvider.COMTREX
    ];
    return supportedProviders.includes(settings.posProvider);
};
