import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Step, StepButton, StepLabel, Stepper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Perk } from 'components/loyalty/models/PerkModel';
import { perkApi } from 'components/loyalty/PerkApi';
import { MESSAGE_MARKETING_PERK_UPDATE_ERROR, MESSAGE_MARKETING_PERK_UPDATE_SUCCESS } from 'config/messages';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { IconButton } from 'ui/buttons/IconButton';
import { Column, Row } from 'ui/Flex';
import { PerkGeneralForm, PerkGeneralFormData } from './forms/PerkGeneralForm';
import { PerkReviewForm } from './forms/PerkReviewForm';
import { PerkSettingsForm, PerkSettingsFormData } from './forms/PerkSettingsForm';
import { generateEditPerkBody } from './helpers';
import {
    generateAwardRedemptionFormData,
    generateSettingsFormData,
    generateVisualisationFormData
} from './award-enrichments/utils';
import { AwardFormStep } from './award-enrichments/AwardEnrichmentEditStepper';
import { ApplicationState } from 'store/store';
import { AwardRedemptionFormData, PerkRedemptionForm } from './forms/RedemptionForm/PerkRedemptionForm';
import {
    PerkVisualisationForm,
    PerkVisualisationFormData
} from 'components/perks/forms/PerkVisualisationForm';

export enum PerkFormStep {
    GENERAL = 'GENERAL',
    CONFIGURE = 'CONFIGURE',
    VISUALISATION = 'VISUALISATION',
    REDEMPTION = 'REDEMPTION'
}

export type StepUpdateMetadata =
    | {
          type: PerkFormStep.GENERAL;
          values: PerkGeneralFormData;
      }
    | {
          type: PerkFormStep.CONFIGURE;
          values: PerkSettingsFormData;
      }
    | {
          type: PerkFormStep.VISUALISATION;
          values: PerkVisualisationFormData;
      }
    | {
          type: PerkFormStep.REDEMPTION | AwardFormStep.REDEMPTION;
          values: AwardRedemptionFormData;
      };

export interface PerksEditStepperProps {
    onClose: () => void;
    onSubmit: (perk: Perk) => void;
    perk: Perk;
    currencySymbol: string;
    primaryActionBackgroundColour: string;
}

const steps = ['Define', 'Configure', 'Visualisation', 'Redemption', 'Review'];

const updatePerk = async (id: string, body: Partial<Perk>) => {
    const perkResult = await perkApi.update(id, { body });
    if (!perkResult.ok) {
        throw new Error(perkResult.body.message);
    }
    return perkResult;
};

export const PerksEditStepper: React.FC<PerksEditStepperProps> = ({
    onClose,
    onSubmit,
    perk,
    currencySymbol,
    primaryActionBackgroundColour
}) => {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const [activeStep, setActiveStep] = React.useState(0);
    const [generalData, setGeneralData] = React.useState<PerkGeneralFormData>({
        title: perk.title,
        order: perk.priority,
        internalTitle: perk.internalTitle,
        description: perk.visualisation.description
    });
    const [settingsData, setSettingsData] = React.useState<PerkSettingsFormData>(
        generateSettingsFormData(perk)
    );
    const [visualisationData, setVisualisationData] = React.useState<PerkVisualisationFormData>(
        generateVisualisationFormData(perk)
    );
    const [redemptionData, setRedemptionData] = React.useState<AwardRedemptionFormData>(
        generateAwardRedemptionFormData(perk)
    );
    const [isLoading, setIsLoading] = React.useState(false);
    const [isValid, setIsValid] = React.useState(true);
    const dispatch = useDispatch();

    const handleGeneralSubmit = React.useCallback((data: PerkGeneralFormData) => {
        setGeneralData(data);
        setActiveStep(1);
    }, []);
    const handleSettingsSubmit = React.useCallback(
        (data: PerkSettingsFormData) => {
            setSettingsData(data);
            setVisualisationData({ ...visualisationData, perkType: data.perkType });
            setActiveStep(2);
        },
        [visualisationData]
    );
    const handleVisualisationSubmit = React.useCallback((data: PerkVisualisationFormData) => {
        setVisualisationData(data);
        setActiveStep(3);
    }, []);
    const handleRedemptionSubmit = React.useCallback((values: AwardRedemptionFormData) => {
        setRedemptionData(values);
        setActiveStep(4);
    }, []);
    const handlePrevious = React.useCallback(() => {
        setActiveStep(activeStep - 1);
    }, [activeStep]);
    const handleVisualisationPrevious = React.useCallback(
        (valid: boolean, values: PerkVisualisationFormData) => {
            setIsValid(valid);
            setVisualisationData(values);
            setActiveStep(activeStep - 1);
        },
        [activeStep]
    );
    const handleSettingsPrevious = React.useCallback(
        (valid: boolean, values: PerkSettingsFormData) => {
            setIsValid(valid);
            setSettingsData(values);
            setActiveStep(activeStep - 1);
        },
        [activeStep]
    );
    const handleUpdatePerk = React.useCallback(
        async (
            updateData: PerkSettingsFormData &
                PerkGeneralFormData &
                PerkVisualisationFormData &
                AwardRedemptionFormData
        ) => {
            setIsLoading(true);
            const body = generateEditPerkBody(
                updateData,
                settings?.region?.timezone,
                !!settings?.app?.awardsScreenBarcodePosition
            );
            try {
                const result = await updatePerk(perk._id, body);
                setIsLoading(false);
                dispatch(enqueueSnackbar(MESSAGE_MARKETING_PERK_UPDATE_SUCCESS, { variant: 'success' }));
                return onSubmit(result.body);
            } catch (e) {
                setIsLoading(false);
                return dispatch(enqueueSnackbar(MESSAGE_MARKETING_PERK_UPDATE_ERROR, { variant: 'error' }));
            }
        },
        [dispatch, onSubmit, perk._id, settings]
    );
    const handleReviewUpdatePerk = React.useCallback(() => {
        handleUpdatePerk({
            ...generalData,
            ...redemptionData,
            ...settingsData,
            ...visualisationData
        });
    }, [handleUpdatePerk, generalData, redemptionData, settingsData, visualisationData]);
    const handleStepUpdate = React.useCallback(
        (updateData: StepUpdateMetadata) => {
            switch (updateData.type) {
                case PerkFormStep.GENERAL:
                    setGeneralData(updateData.values);
                    break;
                case PerkFormStep.CONFIGURE:
                    setSettingsData(updateData.values);
                    break;
                case PerkFormStep.VISUALISATION:
                    setVisualisationData(updateData.values);
                    break;
                case PerkFormStep.REDEMPTION:
                    setRedemptionData(updateData.values);
                    break;
                default:
                    break;
            }

            handleUpdatePerk({
                ...generalData,
                ...redemptionData,
                ...settingsData,
                ...visualisationData,
                ...updateData.values
            });
        },
        [generalData, handleUpdatePerk, redemptionData, settingsData, visualisationData]
    );

    return (
        <Box width="100%" maxWidth={1600}>
            <Column>
                <Row valign="flex-start" align="center">
                    <Box flex={1} maxWidth={500}>
                        <Stepper nonLinear alternativeLabel activeStep={activeStep}>
                            {steps.map((item, index) => (
                                <Step key={`step-button-${item}`} completed={activeStep > index}>
                                    <StepButton disableRipple disabled={activeStep < index} color="inherit">
                                        <StepLabel>{item}</StepLabel>
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <Box position="absolute" top={0} right={0}>
                        <IconButton size="large" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Row>
                <PerkGeneralForm
                    open={activeStep === 0}
                    onSubmit={handleGeneralSubmit}
                    isEdit
                    isLoading={isLoading}
                    initialValues={generalData}
                    isFormValid={isValid}
                    onUpdate={handleStepUpdate}
                />
                <PerkSettingsForm
                    open={activeStep === 1}
                    onSubmit={handleSettingsSubmit}
                    title={generalData && generalData.title}
                    isEdit
                    currencySymbol={currencySymbol}
                    primaryActionBackgroundColour={primaryActionBackgroundColour}
                    isLoading={isLoading}
                    clickPrevious={handlePrevious}
                    onPrevious={handleSettingsPrevious}
                    initialValues={settingsData}
                    onUpdate={handleStepUpdate}
                />
                <PerkVisualisationForm
                    open={activeStep === 2}
                    onSubmit={handleVisualisationSubmit}
                    title={generalData && generalData.title}
                    isEdit
                    isLoading={isLoading}
                    primaryActionBackgroundColour={primaryActionBackgroundColour}
                    initialValues={visualisationData}
                    onPrevious={handleVisualisationPrevious}
                    onUpdate={handleStepUpdate}
                />
                {activeStep === 3 && (
                    <PerkRedemptionForm
                        initialData={redemptionData}
                        onSubmit={handleRedemptionSubmit}
                        clickPrevious={handlePrevious}
                        onPrevious={handlePrevious}
                        onUpdate={handleStepUpdate}
                        currencySymbol={currencySymbol}
                        perkType={settingsData?.perkType}
                        isLoading={isLoading}
                        isEdit
                        auto={settingsData?.auto}
                    />
                )}
                <PerkReviewForm
                    clickPrevious={handlePrevious}
                    submit={handleReviewUpdatePerk}
                    isLoading={isLoading}
                    label="Update & Close"
                    open={activeStep === 4}
                    data={{ ...generalData, ...settingsData, ...visualisationData }}
                    primaryActionBackgroundColour={primaryActionBackgroundColour}
                />
            </Column>
        </Box>
    );
};
