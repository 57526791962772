import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { AutoFormFields, FormItem, LegacyForm } from 'lib/LegacyForm';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';
import { SkeletonComponent } from 'ui/skeleton/SkeletonComponent';
import { AutoFormField } from './AutoFormField';
import { Throbber } from 'ui/Throbber';

type FieldData = any;
export type RenderedFields = Record<string, React.ReactElement>;
export type FormChildrenFunction = (
    fields: RenderedFields,
    defaultRenderer: (renderedFields: RenderedFields) => React.ReactElement[]
) => React.ReactNode;

type ValidationMap<Fields> = { [key in keyof Fields]?: string };
type RulesMap<Fields> = { [key in keyof Fields]?: string | string[] };
export type AutoFormData = { [key: string]: FieldData };

export interface AutoFormProps {
    title?: string;
    subtitle?: string;
    form: LegacyForm;
    submitLabel?: string;
    submitOnEnter?: boolean;
    isLoading?: boolean;
    onKeyDown?: (e: React.KeyboardEvent, data: AutoFormData) => void;
    onClose?: () => void;
    onBlur?: (name: string, value: any) => void;
    children?: FormChildrenFunction;
    disabled?: boolean;
    buttonDisabled?: boolean;
    renderButtons?: (submit: JSX.Element, cancel: JSX.Element) => JSX.Element;
}

export interface AutoFormState<Fields> {
    data: AutoFormData;
    validationMap: ValidationMap<Fields>;
    loading: boolean;
}

const getPrefilledFormData = function (fields: AutoFormFields) {
    return fields
        ? Object.entries(fields).reduce((acc: AutoFormFields, [key, item]) => {
              acc[key] = item.value || '';
              if (item.type === 'number') {
                  acc[key] = item.value ?? '';
              }
              if (item.type === 'switch' || item.type === 'checkbox') {
                  acc[key] = item.value || false;
              }
              return acc;
          }, {})
        : {};
};

const getErrorRules = function (fields: AutoFormFields) {
    return fields
        ? Object.entries(fields).reduce((acc: { [key: string]: string }, [key, item]) => {
              if (item.errors) {
                  Object.entries(item.errors).forEach(([rule, errorMessage]) => {
                      acc[`${rule}.${key}`] = errorMessage;
                  });
              }
              return acc;
          }, {})
        : {};
};

const getPrefilledValidationMap = function (fields: AutoFormFields) {
    return fields
        ? Object.keys(fields).reduce((acc: ValidationMap<AutoFormFields>, item) => {
              acc[item] = '';
              return acc;
          }, {})
        : {};
};

const getValidationRules = function (fields: AutoFormFields) {
    return fields
        ? Object.keys(fields).reduce((acc: RulesMap<AutoFormFields>, item) => {
              if (fields[item].validation) {
                  acc[item] = fields[item].validation;
              }
              return acc;
          }, {})
        : {};
};

// FIXME: Just remove it, please

export const AutoForm = <Fields extends { [key: string]: any }>(props: AutoFormProps) => {
    const { onBlur } = props;
    const [initialised, setInitialised] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<AutoFormFields>(
        getPrefilledFormData(props.form && (props.form.fields as AutoFormFields))
    );
    const [validationMap, setValidationMap] = useState<ValidationMap<AutoFormFields>>(
        getPrefilledValidationMap(props.form && (props.form.fields as AutoFormFields))
    );
    const [form, setForm] = useState(props.form);
    const Validator = React.useRef<Validator.ValidatorStatic>();
    const {
        title,
        children,
        onClose,
        submitLabel,
        subtitle,
        isLoading,
        onKeyDown,
        submitOnEnter,
        disabled,
        buttonDisabled,
        renderButtons
    } = props;

    React.useEffect(() => {
        import('validatorjs').then(validator => {
            Validator.current = validator.default;
            setInitialised(true);
        });
    }, []);

    // Handle LegacyForm change
    // Forces state update if form input property changes
    // Which forces the internal values to update
    useEffect(() => {
        setLoading(true);
        // Get data to prefill of new form property's fields
        setData(getPrefilledFormData(props.form && (props.form.fields as AutoFormFields)));
        setValidationMap(getPrefilledValidationMap(props.form && (props.form.fields as AutoFormFields)));
        setForm(props.form);
        setLoading(false);
    }, [props.form]);

    const isContainErrors = React.useCallback(
        (map: ValidationMap<Fields>) => Object.values(map).some(item => !!item),
        []
    );

    const validate = React.useCallback(
        (submit?: () => void) => {
            const { fields } = form;
            const validation = new Validator.current(
                data,
                getValidationRules(fields as AutoFormFields),
                getErrorRules(fields as AutoFormFields)
            );
            validation.passes();
            const errors: Validator.ValidationErrors = validation.errors.all();
            const errorMessages = Object.keys(validationMap).reduce(
                (acc: ValidationMap<Fields>, item: Extract<keyof Fields, string>) => {
                    acc[item] = errors[item] ? validation.errors.first(item).toString() : '';
                    return acc;
                },
                {}
            );
            setValidationMap(errorMessages);
            if (!isContainErrors(errorMessages)) {
                submit();
            } else {
                setLoading(false);
            }
        },
        [data, form, validationMap, isContainErrors]
    );
    const handleChange = React.useCallback(
        (name: string, value: any) => {
            form.update({
                ...data
            });
            setData({ ...data, [name]: value });
        },
        [form, data]
    );
    const handleBlur = React.useCallback(
        (name: string, value: any) => {
            if (onBlur) {
                onBlur(name, value);
            }
        },
        [onBlur]
    );
    const handleSubmit = React.useCallback(() => {
        setLoading(true);
        validate(() => {
            form.handleSubmit(data);
            setLoading(false);
        });
    }, [validate, form, data]);
    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent) => {
            if (onKeyDown) {
                onKeyDown(e, data);
            } else if (submitOnEnter) {
                if (e.keyCode === 13) {
                    handleSubmit();
                }
            }
        },
        [data, handleSubmit, onKeyDown, submitOnEnter]
    );
    const renderField = (fieldName: string, field: FormItem, fieldData: FieldData, error?: string) => (
        <AutoFormField
            key={fieldName}
            onKeyDown={handleKeyDown}
            {...field}
            error={error}
            name={fieldName}
            value={fieldData}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={disabled}
        />
    );
    const getRenderedFields = () => {
        const { fields } = form;
        return Object.entries(fields).reduce(
            (renderedFields, [fieldName, field]) => ({
                ...renderedFields,
                [fieldName]: renderField(fieldName, field, data[fieldName], validationMap[fieldName])
            }),
            {}
        );
    };
    const renderFieldsAsRows = (renderedFields: RenderedFields) =>
        Object.values(renderedFields).map((element, index) =>
            React.cloneElement(element, { key: `form-item-${index}` })
        );

    // On Mount
    useEffect(() => {
        if (form) {
            const update = (updatedData: Record<string, any>) => {
                setData(localData => ({ ...localData, ...updatedData }));
                setLoading(false);
            };
            form.setUpdateForParent(update);
        }
    }, [form]);

    const cancelButton = React.useMemo(() => {
        if (onClose) {
            return (
                <Button variant="outlined" disabled={loading || isLoading} onClick={onClose}>
                    Cancel
                </Button>
            );
        }
        return null;
    }, [isLoading, loading, onClose]);

    const submitButton = React.useMemo(
        () => (
            <LoadingButton
                loading={loading || isLoading}
                disabled={loading || isLoading || buttonDisabled}
                onClick={handleSubmit}
                variant="contained"
                color="primary"
            >
                {submitLabel || 'Submit'}
            </LoadingButton>
        ),
        [buttonDisabled, handleSubmit, isLoading, loading, submitLabel]
    );

    const actionButtons = React.useMemo(() => {
        if (renderButtons) {
            return renderButtons(submitButton, cancelButton);
        }
        return (
            <Row gutter align="flex-end" valign="center">
                {cancelButton}
                {submitButton}
            </Row>
        );
    }, [cancelButton, renderButtons, submitButton]);

    if (!initialised) {
        return <Throbber />;
    }

    return (
        <Grid container spacing={2}>
            {title && (
                <Grid item xs={12}>
                    <Typography color="primary" variant="h6">
                        {title}
                    </Typography>
                    {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
                </Grid>
            )}
            {!form && <SkeletonComponent count={3} />}
            {!!form &&
                (children
                    ? children(getRenderedFields(), renderFieldsAsRows)
                    : renderFieldsAsRows(getRenderedFields()))}
            <Grid item xs={12}>
                {actionButtons}
            </Grid>
        </Grid>
    );
};
