import * as React from 'react';
import { styled } from '@mui/material';
import { MuiModal } from 'ui/MuiModal';
import { AdjustmentSchemeCreateStepper } from '../forms/AdjustmentSchemeCreateStepper';
import { AdjustmentScheme } from '../adjustmentSchemeModel';

interface AdjustmentSchemesCreationModalProps {
    open: boolean;
    currencySymbol: string;
    existingCodes: string[];
    onClose: () => void;
    onSuccess: (scheme: AdjustmentScheme) => void;
}

const StyledMuiModal = styled(MuiModal)(({ theme }) => ({
    width: `calc(100% - ${theme.spacing(4)})`,
    maxWidth: theme.spacing(160)
}));

export const AdjustmentSchemesCreationModal: React.FC<AdjustmentSchemesCreationModalProps> = ({
    open,
    currencySymbol,
    existingCodes,
    onSuccess,
    onClose
}) => (
    <StyledMuiModal open={open} onClose={onClose}>
        <AdjustmentSchemeCreateStepper
            existingCodes={existingCodes}
            currencySymbol={currencySymbol}
            onClose={onClose}
            onSuccess={onSuccess}
        />
    </StyledMuiModal>
);
