import { updateArrayItem } from 'lib/helpers';
import { HttpClientResponse, PageObject, QueryParameter, RequestOptions } from 'lib/HttpClient';
import logger from 'lib/logger';
import { isDefined, isEmptyString } from 'lib/typeguards';
import React from 'react';

export const DEFAULT_PAGE_LIMIT = 100;

export function usePagedData<T, FilterType, FormattedType = T>(
    filter: FilterType,
    loadFunction: (options: RequestOptions) => HttpClientResponse<{ items: T[]; page: PageObject }>,
    getFilterBody: (config: FilterType) => Record<string, any>,
    itemFormatter: (item: T) => FormattedType
) {
    const [loading, setLoading] = React.useState(true);
    const [endOfData, setEndOfData] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [pages, setPages] = React.useState([undefined]);
    const [items, setItems] = React.useState<T[]>([]);
    const [internalFilter, setInternalFilter] = React.useState<FilterType>();
    const reset = React.useCallback(() => {
        setItems([]);
        setPages([undefined]);
        setPage(0);
        setLoading(true);
        setEndOfData(false);
    }, []);
    React.useEffect(() => {
        const getItems = async () => {
            if (!internalFilter || endOfData) {
                return;
            }
            setLoading(true);
            try {
                const filterBody = getFilterBody(internalFilter);
                const queryParameters: QueryParameter[] = [{ value: DEFAULT_PAGE_LIMIT, key: 'limit' }];
                if (pages[page]) {
                    queryParameters.push({ key: 'startKey', value: pages[page] });
                }
                Object.entries(filterBody).forEach(([key, value]) => {
                    if (isDefined(value) && !isEmptyString(value)) {
                        queryParameters.push({ key, value });
                    }
                });
                const { body, ok } = await loadFunction({
                    queryParameters
                });
                if (!ok) {
                    throw new Error(body.message);
                }
                if (!body.page.nextKey) {
                    setEndOfData(true);
                }
                setItems(currentItems => [...currentItems, ...body.items]);
                setPages(currentPages => updateArrayItem(currentPages, page + 1, body.page.nextKey));
            } catch (e) {
                logger.error(e);
                setItems([]);
            }
            setLoading(false);
        };
        if (
            (page === 0 && items.length === 0) ||
            (!pages[page + 1] && items.length < pages.length * DEFAULT_PAGE_LIMIT)
        ) {
            getItems();
        }
    }, [endOfData, getFilterBody, internalFilter, items.length, loadFunction, page, pages]);
    React.useEffect(() => {
        setInternalFilter(filter);
        reset();
    }, [filter, reset]);
    const formattedItems = React.useMemo(() => {
        if (!Array.isArray(items)) {
            return [];
        }
        return items
            .slice(page * DEFAULT_PAGE_LIMIT, page * DEFAULT_PAGE_LIMIT + DEFAULT_PAGE_LIMIT)
            .map(itemFormatter);
    }, [page, items, itemFormatter]);
    const onPageChange = React.useCallback(
        (pageNumber: number) => {
            if (!loading) {
                setPage(pageNumber);
            }
        },
        [loading]
    );
    const count = React.useMemo(() => {
        if (endOfData) {
            return items.length;
        }
        return -1;
    }, [endOfData, items.length]);
    return { items, formattedItems, page, onPageChange, loading, count };
}
