import { Option } from 'lib/types';
import {
    CHANNEL_OPTIONS,
    DAYS_OF_WEEK_OPTIONS,
    EActionTypeGroup,
    getActionTypeGroup,
    MONTH_OF_YEAR_OPTIONS,
    NUMBER_PERIOD_OPTIONS,
    PRIMARY_PLATFORM_OPTIONS,
    SCENARIO_OPTIONS,
    TIME_OF_DAY_OPTIONS
} from 'components/audience/models/addTriggersModel';
import { Grid, InputLabel } from '@mui/material';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { AutocompleteFormField } from 'lib/form/fields/AutocompleteFormField';
import { NumberFormField } from 'lib/form/fields/NumberFormField';
import React from 'react';
import { IAudienceAddTriggersFormData } from 'components/audience/forms/AudienceAddTriggersForm';
import { Condition, ConditionPredicate } from '@pepperhq/sdk';

export const renderTimeOfDaySelect = () => (
    <Grid item xs={12}>
        <Grid container alignItems="center">
            <Grid item xs={4}>
                <InputLabel>Time of the day</InputLabel>
            </Grid>
            <Grid item xs={8}>
                <SelectFormField
                    displayEmpty
                    native={false}
                    emptyLabel="None"
                    options={TIME_OF_DAY_OPTIONS}
                    name="dayPart"
                />
            </Grid>
        </Grid>
    </Grid>
);

export const renderLocationsSelect = (options: Option[]) => (
    <Grid item xs={12}>
        <Grid container alignItems="center">
            <Grid item xs={4}>
                <InputLabel>Locations</InputLabel>
            </Grid>
            <Grid item xs={8}>
                <AutocompleteFormField options={options} name="locationId" />
            </Grid>
        </Grid>
    </Grid>
);

export const renderDaysOfWeekSelect = () => (
    <Grid item xs={12}>
        <Grid container alignItems="center">
            <Grid item xs={4}>
                <InputLabel>Day of the week</InputLabel>
            </Grid>
            <Grid item xs={8}>
                <SelectFormField
                    displayEmpty
                    native={false}
                    emptyLabel="None"
                    options={DAYS_OF_WEEK_OPTIONS}
                    name="dayOfWeek"
                />
            </Grid>
        </Grid>
    </Grid>
);

export const renderWeekOfYearInput = () => (
    <Grid item xs={12}>
        <Grid container alignItems="center">
            <Grid item xs={4}>
                <InputLabel>Week of the year</InputLabel>
            </Grid>
            <Grid item xs={8}>
                <NumberFormField name="weekOfYear" />
            </Grid>
        </Grid>
    </Grid>
);

export const renderMonthOfYearSelect = () => (
    <Grid item xs={12}>
        <Grid container alignItems="center">
            <Grid item xs={4}>
                <InputLabel>Month of the year</InputLabel>
            </Grid>
            <Grid item xs={8}>
                <SelectFormField
                    displayEmpty
                    native={false}
                    emptyLabel="None"
                    options={MONTH_OF_YEAR_OPTIONS}
                    name="monthOfYear"
                />
            </Grid>
        </Grid>
    </Grid>
);

export const renderPrimaryPlatformSelect = () => (
    <Grid item xs={12}>
        <Grid container alignItems="center">
            <Grid item xs={4}>
                <InputLabel>Primary platform</InputLabel>
            </Grid>
            <Grid item xs={8}>
                <SelectFormField
                    displayEmpty
                    native={false}
                    emptyLabel="None"
                    options={PRIMARY_PLATFORM_OPTIONS}
                    name="primaryPlatform"
                />
            </Grid>
        </Grid>
    </Grid>
);

export const renderAmountField = () => (
    <Grid item xs={12}>
        <Grid container alignItems="center">
            <Grid item xs={4}>
                <InputLabel>Amount</InputLabel>
            </Grid>
            <Grid item xs={8}>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={6}>
                        <SelectFormField
                            displayEmpty
                            native={false}
                            emptyLabel="None"
                            options={NUMBER_PERIOD_OPTIONS}
                            name="amountPredicate"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberFormField name="amount" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);

export const renderScenarioSelect = () => (
    <Grid item xs={12}>
        <Grid container alignItems="center">
            <Grid item xs={4}>
                <InputLabel>Scenario</InputLabel>
            </Grid>
            <Grid item xs={8}>
                <SelectFormField
                    displayEmpty
                    native={false}
                    emptyLabel="None"
                    options={SCENARIO_OPTIONS}
                    name="scenario"
                />
            </Grid>
        </Grid>
    </Grid>
);

export const renderChannelSelect = () => (
    <Grid item xs={12}>
        <Grid container alignItems="center">
            <Grid item xs={4}>
                <InputLabel>Channel</InputLabel>
            </Grid>
            <Grid item xs={8}>
                <SelectFormField
                    displayEmpty
                    native={false}
                    emptyLabel="None"
                    options={CHANNEL_OPTIONS}
                    name="channel"
                />
            </Grid>
        </Grid>
    </Grid>
);

export const renderAudiencesSelect = (options: Option[], isRequired?: boolean) => (
    <Grid item xs={12}>
        <Grid container alignItems="center">
            <Grid item xs={4}>
                <InputLabel required={isRequired}>Audiences</InputLabel>
            </Grid>
            <Grid item xs={8}>
                <AutocompleteFormField options={options} name="audienceId" />
            </Grid>
        </Grid>
    </Grid>
);

export const makeAudienceConditions = ({
    actionType,
    locationId,
    dayPart,
    dayOfWeek,
    weekOfYear,
    monthOfYear,
    primaryPlatform,
    amount,
    amountPredicate,
    scenario,
    channel,
    audienceId
}: IAudienceAddTriggersFormData) => {
    const conditions: Condition[] = [
        {
            subject: 'type',
            predicate: ConditionPredicate.EQUAL,
            object: actionType
        }
    ];

    const finalData: Record<string, Condition | undefined> = {
        locationId: locationId
            ? {
                  subject: 'context.location._id',
                  predicate: ConditionPredicate.EQUAL,
                  object: locationId
              }
            : undefined,
        dayPart: dayPart
            ? {
                  subject: 'when.daypart',
                  predicate: ConditionPredicate.IN,
                  object: [dayPart]
              }
            : undefined,
        dayOfWeek: dayOfWeek
            ? {
                  subject: 'when.dayofweek',
                  predicate: ConditionPredicate.EQUAL,
                  object: Number(dayOfWeek)
              }
            : undefined,
        weekOfYear: weekOfYear
            ? {
                  subject: 'when.weekofyear',
                  predicate: ConditionPredicate.EQUAL,
                  object: Number(weekOfYear)
              }
            : undefined,
        monthOfYear: monthOfYear
            ? {
                  subject: 'when.monthofyear',
                  predicate: ConditionPredicate.EQUAL,
                  object: Number(monthOfYear)
              }
            : undefined,
        primaryPlatform: primaryPlatform
            ? {
                  subject: 'context.user.primaryPlatform',
                  predicate: ConditionPredicate.EQUAL,
                  object: primaryPlatform
              }
            : undefined,
        amount:
            amount && amountPredicate
                ? {
                      subject: 'metadata.value.amount',
                      predicate: amountPredicate,
                      object: Number(amount)
                  }
                : undefined,
        scenario: scenario
            ? {
                  subject: 'metadata.order.scenario',
                  predicate: ConditionPredicate.EQUAL,
                  object: scenario
              }
            : undefined,
        channel: channel
            ? {
                  subject: 'metadata.channel',
                  predicate: ConditionPredicate.EQUAL,
                  object: channel
              }
            : undefined,
        audienceId: audienceId
            ? {
                  subject: 'metadata.audienceId',
                  predicate: ConditionPredicate.EQUAL,
                  object: audienceId
              }
            : undefined
    };

    const pushConditions = (keys: string[]) => {
        keys.forEach(key => {
            if (!!finalData[key]) {
                conditions.push(finalData[key]);
            }
        });
    };

    if (getActionTypeGroup(actionType) === EActionTypeGroup.FIRST) {
        pushConditions([
            'locationId',
            'dayPart',
            'dayOfWeek',
            'weekOfYear',
            'monthOfYear',
            'primaryPlatform'
        ]);
    }

    if (getActionTypeGroup(actionType) === EActionTypeGroup.SECOND) {
        pushConditions(['dayPart', 'dayOfWeek', 'weekOfYear', 'monthOfYear']);
    }

    if (getActionTypeGroup(actionType) === EActionTypeGroup.THIRD) {
        pushConditions([
            'amount',
            'locationId',
            'dayPart',
            'dayOfWeek',
            'weekOfYear',
            'monthOfYear',
            'primaryPlatform',
            'scenario'
        ]);
    }

    if (getActionTypeGroup(actionType) === EActionTypeGroup.FOURTH) {
        pushConditions([
            'amount',
            'dayPart',
            'dayOfWeek',
            'weekOfYear',
            'monthOfYear',
            'primaryPlatform',
            'channel'
        ]);
    }

    if (getActionTypeGroup(actionType) === EActionTypeGroup.FIFTH) {
        pushConditions(['dayPart', 'dayOfWeek', 'weekOfYear', 'monthOfYear', 'primaryPlatform']);
    }

    if (getActionTypeGroup(actionType) === EActionTypeGroup.SIXTH) {
        pushConditions(['audienceId']);
    }

    return conditions;
};
