export async function preloadImage(link: string) {
    try {
        const result = await fetch(link, { method: 'head' });
        if (!result.ok) {
            throw new Error('Failed to load an image');
        }
        return link;
    } catch (e) {
        return undefined;
    }
}
