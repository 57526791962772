import React, { useRef } from 'react';
import { MainLayout } from 'layouts/MainLayout';
import { locationApi } from 'components/location/LocationApi';
import { ITag, ITagGroup } from 'components/location/models/LocationModel';
import { Box, Button, Paper } from '@mui/material';
import { EmptyState } from 'components/utils/emptyState';
import EmptyStateImage from 'static/locations-empty-state.svg';
import { LocationTagDetailsModal } from 'components/location-tags/modals/LocationTagDetailsModal';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_DELETE_TAG_GROUP_ERROR, MESSAGE_DELETE_TAG_GROUP_SUCCESS } from 'config/messages';
import { useDispatch } from 'react-redux';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import AddIcon from '@mui/icons-material/Add';
import { useLocation } from 'react-router-dom';
import { LocationTagsApplicationPopover } from 'components/location-tags/LocationTagsApplicationPopover';
import { Row } from 'ui/Flex';
import { useGridSingleSelection } from 'lib/MuiGrid/useGridSingleSelection';

function LocationTagsPage() {
    const dispatch = useDispatch();
    const { state } = useLocation<{ openCreateModal?: boolean }>();
    const boxRef = useRef<HTMLDivElement>();

    const [loading, setLoading] = React.useState(false);
    const [tagGroups, setTagGroups] = React.useState<ITagGroup[]>([]);
    const [isTagDetailsModalOpen, setTagDetailsModalOpen] = React.useState(!!state?.openCreateModal);
    const [isTagApplicationPopoverOpen, setTagApplicationPopoverOpen] = React.useState(false);
    const [tagGroupToEdit, setTagGroupToEdit] = React.useState<ITagGroup>();
    const [tagGroupToDelete, setTagGroupToDelete] = React.useState<ITagGroup>();
    const [deleteLoading, setDeleteLoading] = React.useState(false);

    const getTagGroups = React.useCallback(() => {
        setLoading(true);
        locationApi
            .getTagGroups()
            .then(res => {
                if (res.ok) {
                    setTagGroups(res.body.items);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    React.useEffect(() => {
        getTagGroups();
    }, [getTagGroups]);

    const handleCreateClick = React.useCallback(() => {
        setTagDetailsModalOpen(true);
    }, []);

    const handleDetailsModalClose = React.useCallback(() => {
        setTagDetailsModalOpen(false);
        setTagGroupToEdit(undefined);
    }, []);
    const maxId = React.useMemo(() => {
        const ids = tagGroups.map(group => {
            const idAsNumber = Number(group.id);
            return isNaN(idAsNumber) || idAsNumber === Number.NEGATIVE_INFINITY ? 0 : idAsNumber;
        });

        if (ids.length) {
            return Math.max(...ids);
        }

        return 0;
    }, [tagGroups]);

    const [selectedItem, rowSelectionModel, handleSelectRow] = useGridSingleSelection(tagGroups);

    const handleItemEditClick = React.useCallback(() => {
        setTagGroupToEdit(selectedItem);
        setTagDetailsModalOpen(true);
    }, [selectedItem]);
    const handleItemDeleteClick = React.useCallback(() => {
        setTagGroupToDelete(selectedItem);
    }, [selectedItem]);
    const handleDeleteDialogClose = React.useCallback(() => {
        setTagGroupToDelete(undefined);
    }, []);
    const handleDeleteTagGroup = React.useCallback(() => {
        if (tagGroupToDelete) {
            setDeleteLoading(true);
            locationApi
                .deleteTagGroup(tagGroupToDelete.id)
                .then(res => {
                    if (res.ok) {
                        dispatch(enqueueSnackbar(MESSAGE_DELETE_TAG_GROUP_SUCCESS, { variant: 'success' }));
                        setTagGroupToDelete(undefined);
                        getTagGroups();
                    } else {
                        dispatch(enqueueSnackbar(MESSAGE_DELETE_TAG_GROUP_ERROR, { variant: 'error' }));
                    }
                })
                .finally(() => {
                    setDeleteLoading(false);
                });
        }
    }, [dispatch, getTagGroups, tagGroupToDelete]);

    const tagsValueGetter = React.useCallback(
        (value: Record<string, ITag>) =>
            Object.values(value)
                .map(item => item.title)
                .join(', '),
        []
    );

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'Title',
                field: 'title',
                display: 'flex',
                flex: 0.2,
                sortable: false
            },
            {
                headerName: 'Tags',
                field: 'tags',
                sortable: false,
                filterable: false,
                flex: 0.8,
                valueGetter: tagsValueGetter
            }
        ],
        [tagsValueGetter]
    );

    const handleApplicationPopoverClose = React.useCallback(() => {
        setTagApplicationPopoverOpen(false);
    }, []);

    const handleTagsCreation = React.useCallback(() => {
        if (!tagGroups.length) {
            setTagApplicationPopoverOpen(true);
        }
    }, [tagGroups]);

    return (
        <MainLayout
            breadcrumbs={[{ label: 'Locations', url: '/locations' }]}
            pageName="Tags"
            pageDescription="Manage your location tags"
            noScroll
        >
            {(tagGroups && tagGroups.length > 0) || loading ? (
                <>
                    <Box height={0} flex={0} ref={boxRef} />
                    <LocationTagsApplicationPopover
                        anchorEl={boxRef.current}
                        onClose={handleApplicationPopoverClose}
                        open={isTagApplicationPopoverOpen}
                    />
                    <Box display="flex" justifyContent="flex-end" mb={1.5}>
                        <Row gutter>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={handleCreateClick}
                                size="small"
                            >
                                Add Tag
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={handleItemEditClick}
                                disabled={!selectedItem}
                                size="small"
                            >
                                Edit
                            </Button>
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={handleItemDeleteClick}
                                disabled={!selectedItem}
                                size="small"
                            >
                                Delete
                            </Button>
                        </Row>
                    </Box>
                    <Paper sx={{ flex: 1, flexDirection: 'column' }}>
                        <MuiGrid
                            loading={loading}
                            columns={columns}
                            rows={tagGroups}
                            rowSelectionModel={rowSelectionModel}
                            onRowSelectionModelChange={handleSelectRow}
                            disableRowSelectionOnClick={false}
                            disableMultipleRowSelection
                        />
                    </Paper>
                </>
            ) : (
                <EmptyState
                    headerText="You haven't created any tags yet"
                    paragraphText="Tags allow users to filter the location list to find the most suited location to order from."
                    buttonText="Create tags"
                    imageUrl={EmptyStateImage}
                    onClick={handleCreateClick}
                />
            )}
            <LocationTagDetailsModal
                maxId={maxId}
                open={isTagDetailsModalOpen}
                onClose={handleDetailsModalClose}
                getTagGroups={getTagGroups}
                tagGroup={tagGroupToEdit}
                onCreate={handleTagsCreation}
            />
            <DeleteDialog
                open={!!tagGroupToDelete}
                title={`Delete ${tagGroupToDelete?.title}?`}
                content="If you delete this tag group, you won't be able to recover it. To confirm deletion, type 'DELETE' in the text input field."
                onClose={handleDeleteDialogClose}
                onDelete={handleDeleteTagGroup}
                protectionWord="DELETE"
                loading={deleteLoading}
            />
        </MainLayout>
    );
}

export default LocationTagsPage;
