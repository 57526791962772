import React from 'react';
import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import { SettingDisplay } from 'lib/SettingDisplay';
import { isDefined } from 'lib/typeguards';

interface SettingDisplaySwitchProps {
    title?: string;
    checked?: boolean;
    description?: string;
    buildtimeIOS?: boolean;
    buildtimeAndroid?: boolean;
    isLocation?: boolean;
}

export const SettingDisplaySwitch: React.FC<SettingDisplaySwitchProps> = ({
    checked,
    title,
    buildtimeAndroid,
    buildtimeIOS,
    description,
    isLocation
}) => {
    const isSettingDefined = React.useMemo(() => !isLocation || isDefined(checked), [checked, isLocation]);
    return (
        <SettingDisplay
            label={title}
            description={description}
            buildtimeIOS={buildtimeIOS}
            buildtimeAndroid={buildtimeAndroid}
        >
            {isSettingDefined && (checked ? <Done /> : <Close />)}
            {!isSettingDefined && 'Unset (currently uses GLOBAL setting)'}
        </SettingDisplay>
    );
};
