import { Box, Paper, TablePagination } from '@mui/material';
import { MainLayout } from 'layouts/MainLayout';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React from 'react';
import { GridColDef, GridRowParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { CONTENT_AVAILABILITY_VIEW } from 'config/routes';
import { useHistory } from 'react-router-dom';
import { MuiTheme } from 'config/theme';
import { useLocationsData } from 'components/location/useLocationsData';
import { GridFilterInput } from 'lib/grid/GridFilterInput';
import { DEFAULT_PAGE_LIMIT } from 'lib/hooks/usePagedData';
import { GridStorageName } from 'lib/MuiGrid/StateController';

export const OrderingAvailabilityPage: React.FC = () => {
    const [search, setSearch] = React.useState<string>();
    const history = useHistory();
    const { count, page, onPageChange, items, loading } = useLocationsData(search);
    const handleSearchChange = React.useCallback((value: string) => {
        setSearch(value);
    }, []);
    const handleRowClick = React.useCallback(
        (params: GridRowParams) => {
            history.push(CONTENT_AVAILABILITY_VIEW.replace(':locationId', String(params?.id)));
        },
        [history]
    );
    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            onPageChange(page);
        },
        [onPageChange]
    );
    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={count}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={DEFAULT_PAGE_LIMIT}
                rowsPerPageOptions={[DEFAULT_PAGE_LIMIT]}
                component="div"
                sx={{
                    '&:last-child': {
                        paddingRight: MuiTheme.spacing(6)
                    }
                }}
            />
        ),
        [count, handlePageChange, page]
    );
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'Location ID',
                field: '_id',
                disableReorder: true,
                disableExport: true,
                width: 300
            },
            {
                headerName: 'Name',
                field: 'title',
                disableReorder: true,
                disableExport: true,
                width: 400
            }
        ],
        []
    );
    const getUniqueKey = React.useCallback((row: GridValidRowModel) => row._id, []);
    React.useEffect(() => {
        if (items && items.length === 1) {
            history.replace(CONTENT_AVAILABILITY_VIEW.replace(':locationId', items[0]._id));
        }
    }, [history, items]);
    return (
        <MainLayout
            pageName="Ordering Availability"
            pageDescription="Manage product availability per location"
            noScroll
        >
            <Box paddingBottom={1}>
                <GridFilterInput
                    value={search}
                    onChange={handleSearchChange}
                    label="Location title"
                    name="search"
                    size="small"
                />
            </Box>
            <Paper sx={{ flex: 1, display: 'flex', minHeight: 0 }}>
                <MuiGrid
                    rows={items}
                    columns={columns}
                    hideFooter={false}
                    hideFooterRowCount
                    pagination
                    disableColumnFilter
                    paginationMode="server"
                    loading={loading}
                    slots={{
                        pagination: Pagination
                    }}
                    getRowId={getUniqueKey}
                    storageName={GridStorageName.OrderingAvailability}
                    enableRowClick
                    onRowClick={handleRowClick}
                />
            </Paper>
        </MainLayout>
    );
};
