import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplayText } from '../../SettingDisplayText';

export const McrSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({ settings, locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplayText title="Company Guid" text={settings?.mcr?.companyGuid} />
                <SettingDisplayText title="Shared Secret" text={settings?.mcr?.sharedSecret} />
                <SettingDisplayText title="Account ID" text={settings?.mcr?.accountId} />
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <SettingDisplayText title="Location Id" text={settings?.mcr?.locationId} />
        )}
    </>
);
