import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Card, Chip, Divider, IconButton, Typography, styled } from '@mui/material';
import Done from '@mui/icons-material/Done';
import Close from '@mui/icons-material/Close';
import { IPublicAndPrivateSettings } from '@pepperhq/location-sdk';
import { FormikValues } from 'formik';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { SettingDisplaySwitch } from 'components/settings/SettingDisplaySwitch';
import { ImageFormField } from 'lib/form/fields/ImageFormField';
import { NumberFormField } from 'lib/form/fields/NumberFormField';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { MuiCardForm } from 'lib/form/MuiCardForm';
import { parseIntOrUndefined } from 'lib/helpers';
import logger from 'lib/logger';
import { SettingDisplay } from 'lib/SettingDisplay';
import { Option } from 'lib/types';
import { ApplicationState } from 'store/store';
import { ILocationScopedSettingsComponentProps } from './LocationScopedSettings';
import { SettingDisplayText } from './SettingDisplayText';
import { MuiForm } from 'lib/Form';
import { Download } from '@mui/icons-material';
import { useDownloadAsset } from 'lib/hooks/useDownloadAsset';

const PREFIX = 'OrderingSettings';

const classes = {
    settingsCard: `${PREFIX}-settingsCard`,
    displayClassName: `${PREFIX}-displayClassName`,
    image: `${PREFIX}-image`,
    titleWrapper: `${PREFIX}-titleWrapper`
};

const StyledMuiCardForm = styled(MuiCardForm)(({ theme }) => ({
    [`&.${classes.settingsCard}`]: {
        marginBottom: theme.spacing(2)
    },
    [`& .${classes.displayClassName}`]: {
        padding: theme.spacing(2)
    },
    [`& .${classes.image}`]: {
        width: 'auto',
        height: 'auto',
        maxHeight: '400px',
        maxWidth: '100%'
    }
}));

const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    [`& .${classes.titleWrapper}`]: {
        marginLeft: theme.spacing(1),
        minHeight: theme.spacing(5.25)
    },
    [`& .${classes.displayClassName}`]: {
        padding: theme.spacing(2)
    }
}));

const retrievalByOptions: Option[] = [
    { label: 'Table Number', value: 'TABLE_NUMBER' },
    { label: 'Check Number', value: 'CHECK_NUMBER' },
    { label: 'Both', value: 'BOTH' }
];

const timeslotsProviderOptions: Option[] = [
    { label: 'Pepper', value: 'PEPPER' },
    { label: 'Zonal', value: 'ZONAL' }
];

const timeslotsBusynessOptions: Option[] = [
    { label: 'Standard', value: 'STANDARD' },
    { label: 'Busy', value: 'BUSY' },
    { label: 'Quiet', value: 'QUIET' }
];

export const OrderingSettings: React.FC<ILocationScopedSettingsComponentProps> = ({
    settings,
    onSettingsSaved,
    locationId
}) => {
    const { tenantId } = useSelector((state: ApplicationState) => state.auth.tenant);

    const updateSettings = React.useCallback(
        async (
            commonSettings: IPublicAndPrivateSettings,
            locationSettings?: IPublicAndPrivateSettings,
            allSettings?: IPublicAndPrivateSettings
        ) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = allSettings ?? {};

                // Common Settings
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        ...commonSettings
                    };
                } else if (locationSettings) {
                    settingsToUpdate = { ...settingsToUpdate, ...locationSettings };
                }

                if (Object.keys(settingsToUpdate).length === 0) {
                    // No need to update anything
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);
                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [locationId, onSettingsSaved]
    );
    const handleMediaSubmit = React.useCallback(() => {}, []);

    const handleSubmitOrdering = React.useCallback(
        async (values: Record<string, any>) =>
            updateSettings(
                {
                    menu: {
                        calorieNutrientName: values.calorieNutrientName,
                        buyNowEnabled: values.buyNowEnabled,
                        useProductDetailsImageInMenuListing: values.useProductDetailsImageInMenuListing,
                        allergenUrl: values.allergenUrl
                    }
                },
                {
                    menu: {
                        allergenUrl: values.allergenUrl
                    }
                },
                {
                    app: {
                        showRecentOrders: values.showRecentOrders,
                        showCategoryGroups: values.showCategoryGroups
                    },
                    orderCardCheck: {
                        TABLE: values.cardCheckEnabledTable,
                        TAB: values.cardCheckEnabledTab,
                        ORDER_TO_TABLE: values.cardCheckEnabledOrderToTable,
                        PREORDER: values.cardCheckEnabledPreorder,
                        PAY_AT_POS: values.cardCheckEnabledPayAtPos
                    }
                }
            ),
        [updateSettings]
    );

    const handleSubmitPayAtTAble = React.useCallback(
        async (values: Record<string, any>) =>
            updateSettings(
                {},
                {
                    payAtTable: {
                        retrievalBy: values.retrievalBy,
                        noTableCheckFoundUrl: values.noTableCheckFoundUrl
                    }
                }
            ),
        [updateSettings]
    );

    const handleSubmitPreorder = React.useCallback(
        async (values: Record<string, any>) =>
            updateSettings(
                {
                    preOrder: {
                        readyPeriodStart: parseIntOrUndefined(values.readyPeriodStart),
                        readyPeriodEnd: parseIntOrUndefined(values.readyPeriodEnd),
                        readyPeriodPadding: parseIntOrUndefined(values.readyPeriodPadding)
                    }
                },
                { orderingEnabled: values.orderingEnabled },
                {
                    preOrderEnabled: values.preOrderEnabled,
                    asapOrderingEnabled: values.asapOrderingEnabled,
                    capturePhoneNumberOnOrder: {
                        PREORDER: values.capturePhoneNumberOnOrder_PREORDER
                    }
                }
            ),
        [updateSettings]
    );

    const handleSubmitOrderToTable = React.useCallback(
        async (values: Record<string, any>) =>
            updateSettings({
                orderToTableEnabled: values.orderToTableEnabled,
                orderToTable: {
                    limitLocationRange: values.limitLocationRange,
                    nfcEnabled: values.nfcEnabled,
                    multiPartEnabled: values.multiPartEnabled,
                    canChangeTableNumber: values.canChangeTableNumber
                }
            }),
        [updateSettings]
    );

    const handleSubmitPayAtTable = React.useCallback(
        async (values: Record<string, any>) =>
            updateSettings({
                payAtTableEnabled: values.payAtTableEnabled,
                payAtTable: {
                    nfcEnabled: values.nfcEnabled,
                    limitLocationRange: values.limitLocationRange,
                    canChangeTableNumber: values.canChangeTableNumber
                }
            }),
        [updateSettings]
    );

    const handleSubmitPayAtPos = React.useCallback(
        async (values: Record<string, any>) => updateSettings({ payAtPosEnabled: values.payAtPosEnabled }),
        [updateSettings]
    );

    const handleSubmitTimeslots = React.useCallback(
        async (values: Record<string, any>) =>
            updateSettings(
                {},
                {},
                {
                    timeSlotsEnabled: values.timeslotsEnabled,
                    timeSlotsProvider: values.timeslotsProvider,
                    ...(values.timeslotsProvider === 'PEPPER'
                        ? {
                              busyness: values.timeslotsBusyness,
                              timeSlots: {
                                  standard: {
                                      ordersPerTimeslot: parseIntOrUndefined(
                                          values.standardOrdersPerTimeslot
                                      ),
                                      timeslotFrequencyInMins: parseIntOrUndefined(
                                          values.standardFrequencyInMins
                                      ),
                                      timeslotLengthInMins: parseIntOrUndefined(values.standardLengthInMins),
                                      windowLengthInMins: parseIntOrUndefined(
                                          values.standardWindowLengthInMins
                                      ),
                                      windowOffsetInMins: parseIntOrUndefined(
                                          values.standardWindowOffsetInMins
                                      )
                                  },
                                  busy: {
                                      ordersPerTimeslot: parseIntOrUndefined(values.busyOrdersPerTimeslot),
                                      timeslotFrequencyInMins: parseIntOrUndefined(
                                          values.busyFrequencyInMins
                                      ),
                                      timeslotLengthInMins: parseIntOrUndefined(values.busyLengthInMins),
                                      windowLengthInMins: parseIntOrUndefined(values.busyWindowLengthInMins),
                                      windowOffsetInMins: parseIntOrUndefined(values.busyWindowOffsetInMins)
                                  },
                                  quiet: {
                                      ordersPerTimeslot: parseIntOrUndefined(values.quietOrdersPerTimeslot),
                                      timeslotFrequencyInMins: parseIntOrUndefined(
                                          values.quietFrequencyInMins
                                      ),
                                      timeslotLengthInMins: parseIntOrUndefined(values.quietLengthInMins),
                                      windowLengthInMins: parseIntOrUndefined(values.quietWindowLengthInMins),
                                      windowOffsetInMins: parseIntOrUndefined(values.quietWindowOffsetInMins)
                                  }
                              }
                          }
                        : {})
                }
            ),
        [updateSettings]
    );

    const retrievalByLabel = React.useMemo(
        () =>
            settings
                ? retrievalByOptions.find(option => option.value === settings.payAtTable?.retrievalBy)
                      ?.label ?? 'None'
                : 'None',
        [settings]
    );

    const timeslotsProviderLabel = React.useMemo(
        () =>
            settings
                ? timeslotsProviderOptions.find(option => option.value === settings.timeSlotsProvider)
                      ?.label ?? 'None'
                : 'None',
        [settings]
    );

    const timeslotsBusynessLabel = React.useMemo(
        () =>
            settings
                ? timeslotsBusynessOptions.find(option => option.value === settings.busyness)?.label ?? 'None'
                : 'None',
        [settings]
    );

    const renderTimeslotsEdit = React.useCallback(
        (values: FormikValues) => (
            <Box display="flex" flexDirection="column">
                <SettingDisplay label="Timeslots Enabled">
                    <SwitchFormField name="timeslotsEnabled" />
                </SettingDisplay>
                <SettingDisplay label="Timeslots Provider">
                    <SelectFormField name="timeslotsProvider" options={timeslotsProviderOptions} />
                </SettingDisplay>
                {values?.timeslotsProvider === 'PEPPER' && (
                    <>
                        <SettingDisplay label="Busyness">
                            <SelectFormField name="timeslotsBusyness" options={timeslotsBusynessOptions} />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Standard Orders Per Timeslot"
                            description="The number of orders that can be made within a selected timeslot"
                        >
                            <NumberFormField
                                shouldSeparateThousands={false}
                                name="standardOrdersPerTimeslot"
                            />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Standard Timeslot Frequency Mins"
                            description="How many minutes after a new timeslot can start"
                        >
                            <NumberFormField shouldSeparateThousands={false} name="standardFrequencyInMins" />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Standard Timeslot Length Mins"
                            description="The duration of each timeslot"
                        >
                            <NumberFormField shouldSeparateThousands={false} name="standardLengthInMins" />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Standard Window Length Mins"
                            description="How far ahead a user can make an order for"
                        >
                            <NumberFormField
                                shouldSeparateThousands={false}
                                name="standardWindowLengthInMins"
                            />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Standard Window Offset Mins"
                            description="An offset from the current time of when a user is able to request an order"
                        >
                            <NumberFormField
                                shouldSeparateThousands={false}
                                name="standardWindowOffsetInMins"
                            />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Busy Orders Per Timeslot"
                            description="The number of orders that can be made within a selected timeslot"
                        >
                            <NumberFormField shouldSeparateThousands={false} name="busyOrdersPerTimeslot" />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Busy Timeslot Frequency Mins"
                            description="How many minutes after a new timeslot can start"
                        >
                            <NumberFormField shouldSeparateThousands={false} name="busyFrequencyInMins" />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Busy Timeslot Length Mins"
                            description="The duration of each timeslot"
                        >
                            <NumberFormField shouldSeparateThousands={false} name="busyLengthInMins" />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Busy Window Length Mins"
                            description="How far ahead a user can make an order for"
                        >
                            <NumberFormField shouldSeparateThousands={false} name="busyWindowLengthInMins" />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Busy Window Offset Mins"
                            description="An offset from the current time of when a user is able to request an order"
                        >
                            <NumberFormField shouldSeparateThousands={false} name="busyWindowOffsetInMins" />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Quiet Orders Per Timeslot"
                            description="The number of orders that can be made within a selected timeslot"
                        >
                            <NumberFormField shouldSeparateThousands={false} name="quietOrdersPerTimeslot" />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Quiet Timeslot Frequency Mins"
                            description="How many minutes after a new timeslot can start"
                        >
                            <NumberFormField shouldSeparateThousands={false} name="quietFrequencyInMins" />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Quiet Timeslot Length Mins"
                            description="The duration of each timeslot"
                        >
                            <NumberFormField shouldSeparateThousands={false} name="quietLengthInMins" />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Quiet Window Length Mins"
                            description="How far ahead a user can make an order for"
                        >
                            <NumberFormField shouldSeparateThousands={false} name="quietWindowLengthInMins" />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Quiet Window Offset Mins"
                            description="An offset from the current time of when a user is able to request an order"
                        >
                            <NumberFormField shouldSeparateThousands={false} name="quietWindowOffsetInMins" />
                        </SettingDisplay>
                    </>
                )}
            </Box>
        ),
        []
    );

    const { loading: ottSelectionImageLoading, handleDownload: handleDownloadOttSelectionImage } =
        useDownloadAsset({
            src: `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/web_ott_selection`,
            fileName: 'web_ott_selection'
        });

    const { loading: patSelectionImageLoading, handleDownload: handleDownloadPatSelectionImage } =
        useDownloadAsset({
            src: `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/web_pat_selection`,
            fileName: 'web_pat_selection'
        });

    const { loading: webMenuSelectionImageLoading, handleDownload: handleDownloadWebMenuSelectionImage } =
        useDownloadAsset({
            src: `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/web_menu_selection`,
            fileName: 'web_menu_selection'
        });

    return (
        <Box minWidth="500px">
            {/* Cards for each settings block */}
            <StyledMuiCardForm
                title="Ordering"
                initialValues={{
                    menuCardImage: `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/web_menu_selection`,
                    showRecentOrders: settings?.app?.showRecentOrders ?? true,
                    showCategoryGroups: !!settings?.app?.showCategoryGroups,
                    cardCheckEnabledTable: settings?.orderCardCheck?.TABLE ?? true,
                    cardCheckEnabledTab: settings?.orderCardCheck?.TAB ?? true,
                    cardCheckEnabledOrderToTable: !!settings?.orderCardCheck?.ORDER_TO_TABLE,
                    cardCheckEnabledPreorder: !!settings?.orderCardCheck?.PREORDER,
                    cardCheckEnabledPayAtPos: !!settings?.orderCardCheck?.PAY_AT_POS,
                    calorieNutrientName: settings?.menu?.calorieNutrientName,
                    buyNowEnabled: settings?.menu?.buyNowEnabled ?? false,
                    useProductDetailsImageInMenuListing:
                        settings?.menu?.useProductDetailsImageInMenuListing ?? false,
                    allergenUrl: settings?.menu?.allergenUrl
                }}
                onSubmit={handleSubmitOrdering}
                childrenWhileDisplaying={
                    <Box display="flex" flexDirection="column">
                        {locationId === undefined && (
                            <>
                                <SettingDisplaySwitch
                                    title="Show Recent Orders"
                                    checked={settings?.app?.showRecentOrders ?? true}
                                />
                                <SettingDisplaySwitch
                                    title="Show Parent Categories"
                                    checked={!!settings?.app?.showCategoryGroups}
                                />
                                <SettingDisplaySwitch
                                    title="Card check enabled - Table"
                                    description="Check the first user to join a table order has a stored card"
                                    checked={settings?.orderCardCheck?.TABLE ?? true}
                                />
                                <SettingDisplaySwitch
                                    title="Card check enabled - Tab"
                                    description="Check the first user to join a tab order has a stored card"
                                    checked={settings?.orderCardCheck?.TAB ?? true}
                                />
                                <SettingDisplaySwitch
                                    title="Card check enabled - Order to Table"
                                    description="Check the first user to join an order to table order has a stored card"
                                    checked={!!settings?.orderCardCheck?.ORDER_TO_TABLE}
                                />
                                <SettingDisplaySwitch
                                    title="Card check enabled - Preorder"
                                    description="Check the first user to join a preorder has a stored card"
                                    checked={!!settings?.orderCardCheck?.PREORDER}
                                />
                                <SettingDisplaySwitch
                                    title="Card check enabled - Pay at POS"
                                    description="Check the first user to join a pay at pos order has a stored card"
                                    checked={!!settings?.orderCardCheck?.PAY_AT_POS}
                                />
                                <SettingDisplayText
                                    title="Calorie Nutrient Name"
                                    text={settings?.menu?.calorieNutrientName}
                                />
                                <SettingDisplaySwitch
                                    title="Buy Now Button on All Products"
                                    checked={settings?.menu?.buyNowEnabled ?? false}
                                />
                                <SettingDisplaySwitch
                                    title="Use Product Details Image in Menu Listing"
                                    checked={settings?.menu?.useProductDetailsImageInMenuListing ?? true}
                                />
                            </>
                        )}
                        {locationId !== undefined && (
                            <SettingDisplaySwitch
                                title="Show Parent Categories"
                                checked={!!settings?.app?.showCategoryGroups}
                            />
                        )}
                        <SettingDisplayText title="Allergen Link" text={settings?.menu?.allergenUrl} />
                    </Box>
                }
                childrenWhileEditing={
                    <Box display="flex" flexDirection="column">
                        {locationId === undefined && (
                            <>
                                <SettingDisplay label="Show Recent Orders">
                                    <SwitchFormField name="showRecentOrders" />
                                </SettingDisplay>
                                <SettingDisplay label="Show Parent Categories">
                                    <SwitchFormField name="showCategoryGroups" />
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Card check enabled - Table"
                                    description="Check the first user to join a table order has a stored card"
                                >
                                    <SwitchFormField name="cardCheckEnabledTable" />
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Card check enabled - Tab"
                                    description="Check the first user to join a tab order has a stored card"
                                >
                                    <SwitchFormField name="cardCheckEnabledTab" />
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Card check enabled - Order to Table"
                                    description="Check the first user to join an order to table order has a stored card"
                                >
                                    <SwitchFormField name="cardCheckEnabledOrderToTable" />
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Card check enabled - Preorder"
                                    description="Check the first user to join a preorder has a stored card"
                                >
                                    <SwitchFormField name="cardCheckEnabledPreorder" />
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Card check enabled - Pay at POS"
                                    description="Check the first user to join a pay at pos order has a stored card"
                                >
                                    <SwitchFormField name="cardCheckEnabledPayAtPos" />
                                </SettingDisplay>
                                <SettingDisplay label="Calorie Nutrient Name">
                                    <TextFormField name="calorieNutrientName" />
                                </SettingDisplay>
                                <SettingDisplay label="Buy Now Button on All Products">
                                    <SwitchFormField name="buyNowEnabled" />
                                </SettingDisplay>
                                <SettingDisplay label="Use Product Details Image in Menu Listing">
                                    <SwitchFormField name="useProductDetailsImageInMenuListing" />
                                </SettingDisplay>
                            </>
                        )}
                        {locationId !== undefined && (
                            <SettingDisplay label="Show Parent Categories">
                                <SwitchFormField name="showCategoryGroups" />
                            </SettingDisplay>
                        )}
                        <SettingDisplay label="Allergen Link">
                            <TextFormField name="allergenUrl" />
                        </SettingDisplay>
                    </Box>
                }
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />
            {locationId !== undefined && (
                <StyledMuiCardForm
                    title="Pay at Table"
                    initialValues={{
                        noTableCheckFoundUrl: settings.payAtTable?.noTableCheckFoundUrl,
                        retrievalBy: settings.payAtTable?.retrievalBy
                    }}
                    onSubmit={handleSubmitPayAtTAble}
                    childrenWhileDisplaying={
                        <Box display="flex" flexDirection="column">
                            <SettingDisplay
                                label="No Check Found URL"
                                description="The URL shown to the user, when no check is found at their table"
                            >
                                <Typography>{settings.payAtTable?.noTableCheckFoundUrl ?? 'None'}</Typography>
                            </SettingDisplay>
                            <SettingDisplay
                                label="Order Retrieval By"
                                description="Retrieve the order using which of the following"
                            >
                                <Chip color="primary" label={retrievalByLabel} />
                            </SettingDisplay>
                            <SettingDisplay
                                label="Web Menu Image"
                                description="Min Ratio 657px X 220px, Max Ratio 800px x 268px PNG or JPEG"
                            >
                                {/* TODO: replace with SettingImageDisplay or something like that */}
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    flexWrap="wrap"
                                    alignItems="center"
                                    gap={1}
                                >
                                    <img
                                        src={`${
                                            process.env.MEDIA_SERVICE_URL
                                        }/tenants/${tenantId}/app_assets/web_menu_selection?t=${new Date().toTimeString()}`}
                                        className={classes.image}
                                    />
                                    <IconButton
                                        disabled={webMenuSelectionImageLoading}
                                        onClick={handleDownloadWebMenuSelectionImage}
                                    >
                                        <Download />
                                    </IconButton>
                                </Box>
                            </SettingDisplay>
                        </Box>
                    }
                    childrenWhileEditing={
                        <Box display="flex" flexDirection="column">
                            <SettingDisplay
                                label="No Check Found URL"
                                description="The URL shown to the user, when no check is found at their table"
                            >
                                <TextFormField name="noTableCheckFoundUrl" />
                            </SettingDisplay>
                            <SettingDisplay
                                label="Order Retrieval By"
                                description="Retrieve the order using which of the following"
                            >
                                <SelectFormField options={retrievalByOptions} name="retrievalBy" />
                            </SettingDisplay>
                            <SettingDisplay
                                label="Web Menu Image"
                                description="Min Ratio 657px X 220px, Max Ratio 800px x 268px PNG or JPEG"
                            >
                                <ImageFormField
                                    name="menuCardImage"
                                    aspect={657 / 220}
                                    fileName="web_menu_selection"
                                    uploadFolder="app_assets"
                                />
                            </SettingDisplay>
                        </Box>
                    }
                    className={classes.settingsCard}
                    boxClassName={classes.displayClassName}
                />
            )}
            <StyledMuiCardForm
                title="Pre-Order"
                initialValues={{
                    preOrderEnabled: settings?.preOrderEnabled ?? false,
                    orderingEnabled: settings?.orderingEnabled ?? false,
                    asapOrderingEnabled: settings?.asapOrderingEnabled ?? false,
                    readyPeriodStart: settings?.preOrder?.readyPeriodStart,
                    readyPeriodEnd: settings?.preOrder?.readyPeriodEnd,
                    readyPeriodPadding: settings?.preOrder?.readyPeriodPadding,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    capturePhoneNumberOnOrder_PREORDER: settings?.capturePhoneNumberOnOrder?.PREORDER ?? false
                }}
                onSubmit={handleSubmitPreorder}
                childrenWhileDisplaying={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplaySwitch
                            checked={settings?.preOrderEnabled}
                            isLocation={!!locationId}
                            title="Pre-Order Enabled"
                        />
                        {locationId !== undefined && (
                            <SettingDisplaySwitch
                                checked={settings?.orderingEnabled}
                                title="Ordering Enabled"
                            />
                        )}
                        <SettingDisplaySwitch
                            checked={settings?.asapOrderingEnabled}
                            isLocation={!!locationId}
                            title="ASAP Ordering Enabled"
                        />
                        {locationId === undefined && (
                            <>
                                <SettingDisplay label="Window Start">
                                    <Typography variant="body2">
                                        {settings?.preOrder?.readyPeriodStart}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Window End">
                                    <Typography variant="body2">
                                        {settings?.preOrder?.readyPeriodEnd}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Window Padding">
                                    <Typography variant="body2">
                                        {settings?.preOrder?.readyPeriodPadding}
                                    </Typography>
                                </SettingDisplay>
                            </>
                        )}
                        <SettingDisplaySwitch
                            checked={settings?.capturePhoneNumberOnOrder?.PREORDER}
                            isLocation={!!locationId}
                            title="Capture Phone Number on Pre-Order"
                        />
                    </Box>
                }
                childrenWhileEditing={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="Pre-Order Enabled">
                            <SwitchFormField name="preOrderEnabled" />
                        </SettingDisplay>
                        {locationId !== undefined && (
                            <SettingDisplay label="Ordering Enabled">
                                <SwitchFormField name="orderingEnabled" />
                            </SettingDisplay>
                        )}
                        <SettingDisplay label="ASAP Ordering Enabled">
                            <SwitchFormField name="asapOrderingEnabled" />
                        </SettingDisplay>
                        {locationId === undefined && (
                            <>
                                <SettingDisplay label="Window Start">
                                    <NumberFormField name="readyPeriodStart" />
                                </SettingDisplay>
                                <SettingDisplay label="Window End">
                                    <NumberFormField name="readyPeriodEnd" />
                                </SettingDisplay>
                                <SettingDisplay label="Window Padding">
                                    <NumberFormField name="readyPeriodPadding" />
                                </SettingDisplay>
                            </>
                        )}
                        <SettingDisplay label="Capture Phone Number on Pre-Order">
                            <SwitchFormField name="capturePhoneNumberOnOrder_PREORDER" />
                        </SettingDisplay>
                    </Box>
                }
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />
            {locationId !== undefined && (
                <StyledMuiCardForm
                    title="Timeslots"
                    initialValues={{
                        timeslotsEnabled: settings?.timeSlotsEnabled ?? false,
                        timeslotsProvider: settings?.timeSlotsProvider,
                        timeslotsBusyness: settings?.busyness,
                        standardOrdersPerTimeslot: settings?.timeSlots?.standard?.ordersPerTimeslot,
                        standardFrequencyInMins: settings?.timeSlots?.standard?.timeslotFrequencyInMins,
                        standardLengthInMins: settings?.timeSlots?.standard?.timeslotLengthInMins,
                        standardWindowLengthInMins: settings?.timeSlots?.standard?.windowLengthInMins,
                        standardWindowOffsetInMins: settings?.timeSlots?.standard?.windowOffsetInMins,
                        busyOrdersPerTimeslot: settings?.timeSlots?.busy?.ordersPerTimeslot,
                        busyFrequencyInMins: settings?.timeSlots?.busy?.timeslotFrequencyInMins,
                        busyLengthInMins: settings?.timeSlots?.busy?.timeslotLengthInMins,
                        busyWindowLengthInMins: settings?.timeSlots?.busy?.windowLengthInMins,
                        busyWindowOffsetInMins: settings?.timeSlots?.busy?.windowOffsetInMins,
                        quietOrdersPerTimeslot: settings?.timeSlots?.quiet?.ordersPerTimeslot,
                        quietFrequencyInMins: settings?.timeSlots?.quiet?.timeslotFrequencyInMins,
                        quietLengthInMins: settings?.timeSlots?.quiet?.timeslotLengthInMins,
                        quietWindowLengthInMins: settings?.timeSlots?.quiet?.windowLengthInMins,
                        quietWindowOffsetInMins: settings?.timeSlots?.quiet?.windowOffsetInMins
                    }}
                    onSubmit={handleSubmitTimeslots}
                    childrenWhileDisplaying={
                        <Box display="flex" flexDirection="column">
                            <SettingDisplay label="Timeslots Enabled">
                                {settings?.timeSlotsEnabled && <Done />}
                                {!settings?.timeSlotsEnabled && <Close />}
                            </SettingDisplay>
                            <SettingDisplay label="Timeslots Provider">
                                <Chip color="primary" label={timeslotsProviderLabel} />
                            </SettingDisplay>
                            {settings?.timeSlotsProvider === 'PEPPER' && (
                                <>
                                    <SettingDisplay label="Busyness">
                                        <Chip color="primary" label={timeslotsBusynessLabel} />
                                    </SettingDisplay>
                                    <SettingDisplay label="Standard Orders Per Timeslot">
                                        <Typography variant="body2">
                                            {settings?.timeSlots?.standard?.ordersPerTimeslot}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Standard Timeslot Frequency Mins">
                                        <Typography variant="body2">
                                            {settings?.timeSlots?.standard?.timeslotFrequencyInMins}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Standard Timeslot Length Mins">
                                        <Typography variant="body2">
                                            {settings?.timeSlots?.standard?.timeslotLengthInMins}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Standard Window Length Mins">
                                        <Typography variant="body2">
                                            {settings?.timeSlots?.standard?.windowLengthInMins}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Standard Window Offset Mins">
                                        <Typography variant="body2">
                                            {settings?.timeSlots?.standard?.windowOffsetInMins}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Busy Orders Per Timeslot">
                                        <Typography variant="body2">
                                            {settings?.timeSlots?.busy?.ordersPerTimeslot}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Busy Timeslot Frequency Mins">
                                        <Typography variant="body2">
                                            {settings?.timeSlots?.busy?.timeslotFrequencyInMins}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Busy Timeslot Length Mins">
                                        <Typography variant="body2">
                                            {settings?.timeSlots?.busy?.timeslotLengthInMins}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Busy Window Length Mins">
                                        <Typography variant="body2">
                                            {settings?.timeSlots?.busy?.windowLengthInMins}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Busy Window Offset Mins">
                                        <Typography variant="body2">
                                            {settings?.timeSlots?.busy?.windowOffsetInMins}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Quiet Orders Per Timeslot">
                                        <Typography variant="body2">
                                            {settings?.timeSlots?.quiet?.ordersPerTimeslot}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Quiet Timeslot Frequency Mins">
                                        <Typography variant="body2">
                                            {settings?.timeSlots?.quiet?.timeslotFrequencyInMins}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Quiet Timeslot Length Mins">
                                        <Typography variant="body2">
                                            {settings?.timeSlots?.quiet?.timeslotLengthInMins}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Quiet Window Length Mins">
                                        <Typography variant="body2">
                                            {settings?.timeSlots?.quiet?.windowLengthInMins}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Quiet Window Offset Mins">
                                        <Typography variant="body2">
                                            {settings?.timeSlots?.quiet?.windowOffsetInMins}
                                        </Typography>
                                    </SettingDisplay>
                                </>
                            )}
                        </Box>
                    }
                    childrenWhileEditing={renderTimeslotsEdit}
                    className={classes.settingsCard}
                    boxClassName={classes.displayClassName}
                />
            )}
            {locationId === undefined && (
                <>
                    <StyledMuiCardForm
                        title="Order to Table"
                        initialValues={{
                            orderToTableEnabled: settings?.orderToTableEnabled ?? false,
                            limitLocationRange: settings?.orderToTable?.limitLocationRange ?? false,
                            nfcEnabled: settings?.orderToTable?.nfcEnabled ?? false,
                            multiPartEnabled: settings?.orderToTable?.multiPartEnabled ?? false,
                            canChangeTableNumber: settings?.orderToTable?.canChangeTableNumber ?? true,
                            ottCardImage: `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/web_ott_selection`
                        }}
                        onSubmit={handleSubmitOrderToTable}
                        childrenWhileDisplaying={
                            <Box display="flex" flexDirection="column">
                                <SettingDisplay label="Order To Table Enabled">
                                    {settings?.orderToTableEnabled && <Done />}
                                    {!settings?.orderToTableEnabled && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Multi-part Ordering">
                                    {settings?.orderToTable?.multiPartEnabled && <Done />}
                                    {!settings?.orderToTable?.multiPartEnabled && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Limit Location Range">
                                    {settings?.orderToTable?.limitLocationRange && <Done />}
                                    {!settings?.orderToTable?.limitLocationRange && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Order To Table NFC Enabled">
                                    {settings?.orderToTable?.nfcEnabled && <Done />}
                                    {!settings?.orderToTable?.nfcEnabled && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Change Table Number Enabled">
                                    {settings?.orderToTable?.canChangeTableNumber && <Done />}
                                    {!settings?.orderToTable?.canChangeTableNumber && <Close />}
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Web Journey Selection Image"
                                    description="Min Ratio 657px X 220px, Max Ratio 800px x 268px PNG or JPEG"
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        flexWrap="wrap"
                                        alignItems="center"
                                        gap={1}
                                    >
                                        <img
                                            src={`${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/web_ott_selection`}
                                            className={classes.image}
                                        />
                                        <IconButton
                                            disabled={ottSelectionImageLoading}
                                            onClick={handleDownloadOttSelectionImage}
                                        >
                                            <Download />
                                        </IconButton>
                                    </Box>
                                </SettingDisplay>
                            </Box>
                        }
                        childrenWhileEditing={
                            <Box display="flex" flexDirection="column">
                                <SettingDisplay label="Order To Table Enabled">
                                    <SwitchFormField name="orderToTableEnabled" />
                                </SettingDisplay>
                                <SettingDisplay label="Multi-part Ordering">
                                    <SwitchFormField name="multiPartEnabled" />
                                </SettingDisplay>
                                <SettingDisplay label="Limit Location Range">
                                    <SwitchFormField name="limitLocationRange" />
                                </SettingDisplay>
                                <SettingDisplay label="Order To Table NFC Enabled">
                                    <SwitchFormField name="nfcEnabled" />
                                </SettingDisplay>
                                <SettingDisplay label="Change Table Number Enabled">
                                    <SwitchFormField name="canChangeTableNumber" />
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Web Journey Selection Image"
                                    description="Min Ratio 657px X 220px, Max Ratio 800px x 268px PNG or JPEG"
                                >
                                    <ImageFormField
                                        name="ottCardImage"
                                        aspect={657 / 220}
                                        fileName="web_ott_selection"
                                        uploadFolder="app_assets"
                                    />
                                </SettingDisplay>
                            </Box>
                        }
                        className={classes.settingsCard}
                        boxClassName={classes.displayClassName}
                    />
                    <StyledCard className={classes.settingsCard}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            padding={1}
                            justifyContent="space-between"
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                className={classes.titleWrapper}
                            >
                                <Typography variant="h6">Bartab</Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <Box className={classes.displayClassName}>
                            <MuiForm
                                onSubmit={handleMediaSubmit}
                                initialValues={{
                                    openTabImage: `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/open_tab`,
                                    joinTabImage: `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/join_tab`
                                }}
                            >
                                <Box display="flex" flexDirection="column">
                                    <SettingDisplay
                                        label="Open Tab Image"
                                        description="600px by 260px PNG or JPEG"
                                        buildtimeAndroid
                                        buildtimeIOS
                                    >
                                        <ImageFormField
                                            name="openTabImage"
                                            aspect={600 / 260}
                                            fileName="open_tab"
                                            uploadFolder="app_assets"
                                            unremovable
                                            downloadable
                                        />
                                    </SettingDisplay>
                                    <SettingDisplay
                                        label="Join Tab Image"
                                        description="600px by 260px PNG or JPEG"
                                        buildtimeAndroid
                                        buildtimeIOS
                                    >
                                        <ImageFormField
                                            name="joinTabImage"
                                            aspect={600 / 260}
                                            fileName="join_tab"
                                            uploadFolder="app_assets"
                                            unremovable
                                            downloadable
                                        />
                                    </SettingDisplay>
                                </Box>
                            </MuiForm>
                        </Box>
                    </StyledCard>
                    <StyledMuiCardForm
                        title="Pay At Table"
                        initialValues={{
                            payAtTableEnabled: settings?.payAtTableEnabled ?? false,
                            nfcEnabled: settings?.payAtTable?.nfcEnabled ?? false,
                            limitLocationRange: settings?.payAtTable?.limitLocationRange ?? false,
                            canChangeTableNumber: settings?.payAtTable?.canChangeTableNumber ?? true,
                            patCardImage: `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/web_pat_selection`
                        }}
                        onSubmit={handleSubmitPayAtTable}
                        childrenWhileDisplaying={
                            <Box display="flex" flexDirection="column">
                                <SettingDisplay label="Pay At Table Enabled">
                                    {settings?.payAtTableEnabled && <Done />}
                                    {!settings?.payAtTableEnabled && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Limit Location Range">
                                    {settings?.payAtTable?.limitLocationRange && <Done />}
                                    {!settings?.payAtTable?.limitLocationRange && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Pay At Table NFC Enabled">
                                    {settings?.payAtTable?.nfcEnabled && <Done />}
                                    {!settings?.payAtTable?.nfcEnabled && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Change Table Number Enabled">
                                    {settings?.payAtTable?.canChangeTableNumber && <Done />}
                                    {!settings?.payAtTable?.canChangeTableNumber && <Close />}
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Web Journey Selection Image"
                                    description="Min Ratio 657px X 220px, Max Ratio 800px x 268px PNG or JPEG"
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        flexWrap="wrap"
                                        alignItems="center"
                                        gap={1}
                                    >
                                        <img
                                            src={`${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/web_pat_selection`}
                                            className={classes.image}
                                        />
                                        <IconButton
                                            disabled={patSelectionImageLoading}
                                            onClick={handleDownloadPatSelectionImage}
                                        >
                                            <Download />
                                        </IconButton>
                                    </Box>
                                </SettingDisplay>
                            </Box>
                        }
                        childrenWhileEditing={
                            <Box display="flex" flexDirection="column">
                                <SettingDisplay label="Pay At Table Enabled">
                                    <SwitchFormField name="payAtTableEnabled" />
                                </SettingDisplay>
                                <SettingDisplay label="Limit Location Range">
                                    <SwitchFormField name="limitLocationRange" />
                                </SettingDisplay>
                                <SettingDisplay label="Pay At Table NFC Enabled">
                                    <SwitchFormField name="nfcEnabled" />
                                </SettingDisplay>
                                <SettingDisplay label="Change Table Number Enabled">
                                    <SwitchFormField name="canChangeTableNumber" />
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Web Journey Selection Image"
                                    description="Min Ratio 657px X 220px, Max Ratio 800px x 268px PNG or JPEG"
                                >
                                    <ImageFormField
                                        name="patCardImage"
                                        aspect={657 / 220}
                                        fileName="web_pat_selection"
                                        uploadFolder="app_assets"
                                    />
                                </SettingDisplay>
                            </Box>
                        }
                        className={classes.settingsCard}
                        boxClassName={classes.displayClassName}
                    />
                    <StyledMuiCardForm
                        title="Pay At POS"
                        initialValues={{
                            payAtPosEnabled: settings?.payAtPosEnabled ?? false
                        }}
                        onSubmit={handleSubmitPayAtPos}
                        childrenWhileDisplaying={
                            <Box display="flex" flexDirection="column">
                                <SettingDisplay label="Pay At POS Enabled">
                                    {settings?.payAtPosEnabled && <Done />}
                                    {!settings?.payAtPosEnabled && <Close />}
                                </SettingDisplay>
                            </Box>
                        }
                        childrenWhileEditing={
                            <Box display="flex" flexDirection="column">
                                <SettingDisplay label="Pay At POS Enabled">
                                    <SwitchFormField name="payAtPosEnabled" />
                                </SettingDisplay>
                            </Box>
                        }
                        className={classes.settingsCard}
                        boxClassName={classes.displayClassName}
                    />
                </>
            )}
        </Box>
    );
};
