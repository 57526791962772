import { createTheme } from '@mui/material';
import '@mui/x-data-grid/themeAugmentation';

// Converted rgba to rgb on white background
const palette = {
    primary: {
        dark: '#c9d2f9', // purple.tertiary
        main: '#2e3a47', // black.tertiary
        light: '#fdccbb', // orange.tertiary
        contrastText: '#eeeced' // white.tertiary
    },
    secondary: {
        light: '#fdccbb', // #fdccbb
        main: '#ff6f41', // #ff6f41
        dark: '#fc8469', // #fc8469
        contrastText: '#1c2837' // #1c2837
    },
    black: {
        core: '#031121', // #031121
        secondary: '#1c2837', // #1c2837
        tertiary: '#2e3a47' // #2e3a47
    },
    purple: {
        core: '#6a48f2', // #6a48f2
        secondary: '#93a8ff', // #93a8ff
        tertiary: '#c9d2f9', // #c9d2f9
        off: '#aab4d5' // #aab4d5
    },
    orange: {
        core: '#ff6f41',
        secondary: '#fc8469',
        tertiary: '#fdccbb',
        off: '#d99889'
    },
    white: {
        core: '#f9f7f8',
        secondary: '#f4f2f4',
        tertiary: '#eeeced',
        off: '#ccccd0'
    },
    background: {
        default: '#fefcfd'
    },
    text: {
        primary: '#031121',
        secondary: '#1c2837'
    },
    action: {
        disabled: '#DDDD'
    },
    common: {
        white: '#FFF'
    },
    divider: '#552e3a30'
};

// A theme with custom primary and secondary color.
// It's optional.
export const MuiTheme = createTheme({
    palette,
    typography: {
        fontSize: 14,
        htmlFontSize: 16,
        fontFamily: 'Manrope, serif',
        body1: {
            fontWeight: '500',
            fontOpticalSizing: 'auto',
            fontStyle: 'normal'
        },
        button: {
            fontWeight: '500',
            fontOpticalSizing: 'auto'
        },
        h6: {
            fontWeight: 'normal',
            fontSize: 18
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard'
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                containedPrimary: {
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: palette.primary.main,
                    '&.Mui-disabled': {
                        backgroundColor: 'unset',
                        borderColor: 'unset'
                    },
                    '&:hover': {
                        backgroundColor: palette.primary.dark,
                        color: palette.text.primary,
                        borderColor: palette.primary.dark
                    }
                },
                outlinedPrimary: {
                    '&:hover': {
                        backgroundColor: palette.primary.dark,
                        color: palette.text.primary
                    }
                },
                sizeSmall: {
                    lineHeight: 1.5,
                    padding: '4px 12px 4px 12px'
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: 20
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                paddingCheckbox: {
                    padding: '0 12px'
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: palette.text.primary
                }
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontFamily: 'Lato, serif',
                    fontWeight: 400,
                    fontStyle: 'normal'
                },
                row: {
                    '&:hover': {
                        backgroundColor: '#c9d2f944' // primary.dark + opacity 27%
                    },
                    '&:last-child': {
                        marginBottom: '12px' // offset for scrollbar
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#fdccbb99', // primary.light + opacity 60%
                        '&:hover': {
                            backgroundColor: palette.purple.tertiary
                        }
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: palette.secondary.main,
                    textDecoration: 'none',
                    '&:hover': {
                        color: palette.purple.secondary
                    }
                }
            }
        }
    }
});

export const toolbarHeight = MuiTheme.spacing(7);
export const mobileToolbarHeight = MuiTheme.spacing(6);

interface PepperColor {
    core: string;
    secondary: string;
    tertiary: string;
    off: string;
}

declare module '@mui/material/styles' {
    interface Palette {
        purple: PepperColor;
        orange: PepperColor;
    }

    interface PaletteOptions {
        purple?: PepperColor;
        orange?: PepperColor;
    }
}
