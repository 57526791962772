import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { NumberFormField } from 'lib/form/fields/NumberFormField';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';
import { ibsCondimentListOptions, ibsOrderNotesTextJustifyOptions } from './IBSSettingsDisplay';

export const IBSSettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplay label="API Username">
                    <TextFormField name="ibsApiUsername" />
                </SettingDisplay>
                <SettingDisplay label="API Password">
                    <TextFormField name="ibsApiPassword" />
                </SettingDisplay>
                <SettingDisplay label="Web Orders Hostname">
                    <TextFormField name="ibsWebOrdersHostname" />
                </SettingDisplay>
                <SettingDisplay label="App GUID">
                    <TextFormField name="ibsAppGuid" />
                </SettingDisplay>
                <SettingDisplay label="Company Code">
                    <TextFormField name="ibsCompanyCode" />
                </SettingDisplay>
                <SettingDisplay label="Enable Kiosk Orders Mode">
                    <SwitchFormField name="ibsEnableKioskOrdersMode" />
                </SettingDisplay>
                <SettingDisplay label="Enable Availability Web Notifications">
                    <SwitchFormField name="ibsWebNotificationsEnabled" />
                </SettingDisplay>
                <SettingDisplay label="Filter Products by Zone">
                    <SwitchFormField name="filterProductsByZone" />
                </SettingDisplay>
                <SettingDisplay label="Order Notes Bold">
                    <SwitchFormField name="ibsOrderNotesBold" />
                </SettingDisplay>
                <SettingDisplay label="Order Notes Double Width">
                    <SwitchFormField name="ibsOrderNotesDoubleWidth" />
                </SettingDisplay>
                <SettingDisplay label="Order Notes Double Height">
                    <SwitchFormField name="ibsOrderNotesDoubleHeight" />
                </SettingDisplay>
                <SettingDisplay label="Order Notes Align">
                    <SelectFormField options={ibsOrderNotesTextJustifyOptions} name="ibsOrderNotesJustify" />
                </SettingDisplay>
                <SettingDisplay label="Quickpad Item Notes Enabled">
                    <SwitchFormField name="itemOrderNotesEnabled" />
                </SettingDisplay>
                <SettingDisplay label="Keyboard ID for Quickpad Item Notes">
                    <TextFormField name="ibsItemNoteModifierNo" />
                </SettingDisplay>
                <SettingDisplay label="PLU for Quickpad Item Notes">
                    <TextFormField name="ibsItemNoteOffset" />
                </SettingDisplay>
                <SettingDisplay label="PLU Sales Group for Quickpad Item Notes">
                    <TextFormField name="ibsItemNoteSalesGroupNo" />
                </SettingDisplay>
                <SettingDisplay label="Pre-Order Price Level">
                    <TextFormField type="number" name="PREORDER_priceLevel" />
                </SettingDisplay>
                <SettingDisplay label="Order to Table Price Level">
                    <TextFormField type="number" name="ORDER_TO_TABLE_priceLevel" />
                </SettingDisplay>
                <SettingDisplay label="Pay at Table Price Level">
                    <TextFormField type="number" name="TABLE_priceLevel" />
                </SettingDisplay>
                <SettingDisplay label="Tab Price Level">
                    <TextFormField type="number" name="TAB_priceLevel" />
                </SettingDisplay>
                <SettingDisplay label="Condiment List Type">
                    <SelectFormField options={ibsCondimentListOptions} name="condimentListOption" />
                </SettingDisplay>
                <SettingDisplay label="Access Menu Manager (AMM) Enabled">
                    <SwitchFormField name="accessMenuManagerEnabled" />
                </SettingDisplay>
                <SettingDisplay label="AMM Host">
                    <TextFormField type="string" name="accessMenuManagerHost" />
                </SettingDisplay>
                <SettingDisplay label="AMM API Key">
                    <TextFormField type="string" name="accessMenuManagerApiKey" />
                </SettingDisplay>
                <SettingDisplay label="AMM Password">
                    <TextFormField type="string" name="accessMenuManagerPassword" />
                </SettingDisplay>
                <SettingDisplay label="Preorder Channel Id">
                    <TextFormField type="number" name="preorderChannelId" />
                </SettingDisplay>
                <SettingDisplay label="Order to Table Channel Id">
                    <TextFormField type="number" name="orderToTableChannelId" />
                </SettingDisplay>
                <SettingDisplay label="Tab Channel Id">
                    <TextFormField type="number" name="tabChannelId" />
                </SettingDisplay>
                <SettingDisplay label="Table Channel Id">
                    <TextFormField type="number" name="tableChannelId" />
                </SettingDisplay>
                <SettingDisplay label="Payment Media Type">
                    <TextFormField name="ibsPaymentMediaType" />
                </SettingDisplay>
                <SettingDisplay label="Terminal Payment Media Type Override">
                    <TextFormField type="number" name="ibsTerminalPaymentMediaType" />
                </SettingDisplay>
                <SettingDisplay label="Timezone Name">
                    <TextFormField name="ibsTimezoneName" />
                </SettingDisplay>
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <>
                <SettingDisplay label="Location ID">
                    <TextFormField name="ibsLocationId" />
                </SettingDisplay>
                <SettingDisplay label="Location ID for Menu">
                    <TextFormField name="menuIbsLocationId" />
                </SettingDisplay>
                <SettingDisplay label="Payment Media Type">
                    <TextFormField name="ibsPaymentMediaType" />
                </SettingDisplay>
                <SettingDisplay label="Terminal Payment Media Type Override">
                    <TextFormField type="number" name="ibsTerminalPaymentMediaType" />
                </SettingDisplay>
                <SettingDisplay label="Timezone Name">
                    <TextFormField name="ibsTimezoneName" />
                </SettingDisplay>
                <SettingDisplay label="Pre-Order Seating Area">
                    <NumberFormField name="ibsPreOrderSeatingArea" shouldSeparateThousands={false} />
                </SettingDisplay>
                <SettingDisplay label="Order to Table Seating Area">
                    <NumberFormField name="ibsOrderToTableSeatingArea" shouldSeparateThousands={false} />
                </SettingDisplay>
                <SettingDisplay label="Pay at Table Seating Area">
                    <NumberFormField name="ibsPayAtTableSeatingArea" shouldSeparateThousands={false} />
                </SettingDisplay>
                <SettingDisplay label="Tab Seating Area">
                    <NumberFormField name="ibsTabSeatingArea" shouldSeparateThousands={false} />
                </SettingDisplay>
                <SettingDisplay label="Filter Products by Zone">
                    <SwitchFormField name="filterProductsByZone" />
                </SettingDisplay>
                <SettingDisplay label="Quickpad Item Notes Enabled">
                    <SwitchFormField name="itemOrderNotesEnabled" />
                </SettingDisplay>
                <SettingDisplay label="Pre-Order Price Level">
                    <TextFormField type="number" name="PREORDER_priceLevel" />
                </SettingDisplay>
                <SettingDisplay label="Order to Table Price Level">
                    <TextFormField type="number" name="ORDER_TO_TABLE_priceLevel" />
                </SettingDisplay>
                <SettingDisplay label="Pay at Table Price Level">
                    <TextFormField type="number" name="TABLE_priceLevel" />
                </SettingDisplay>
                <SettingDisplay label="Tab Price Level">
                    <TextFormField type="number" name="TAB_priceLevel" />
                </SettingDisplay>
            </>
        )}
    </>
);
