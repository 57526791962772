import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Allergens, MenuChange, MenuV } from '@pepperhq/menu-sdk';
import { titleize } from 'lib/helpers';
import { ColorPickerColumn, ImagePickerColumn, MultiSelectGridColumn } from 'lib/MuiGrid/Columns';
import { MuiGridPriceView } from 'lib/MuiGrid/text/MuiGridPriceView';
import { Option } from 'lib/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { NutritionGridColumn, SuggestionsGridColumn } from '../Columns';
import {
    dayLabel,
    getModifierIdToTitle,
    getTagIdToTitle,
    getTaxIdToTitle,
    menuChannelLabel,
    scenarioLabel
} from '../model/menu';
import { renderEditPriceCell } from 'lib/MuiGrid/text/GridEditPriceCell';
import { EPaymentProvider } from '../../settings/PaymentsSettings';
import { EPointOfSaleProvider } from 'components/settings/PointOfSaleSettings';

const allergensObject = Allergens.reduce<Record<string, string>>((acc, item) => {
    acc[item] = titleize(item);
    return acc;
}, {});

export function useProductsColumns(
    menu: MenuV<6>,
    change: MenuChange,
    productOptions: Option[]
): GridColDef[] {
    const taxIdToTitle = React.useMemo(() => getTaxIdToTitle(menu, change), [change, menu]);
    const modifierIdToTitle = React.useMemo(() => getModifierIdToTitle(menu, change), [change, menu]);
    const tagIdToTitle = React.useMemo(() => getTagIdToTitle(menu, change), [change, menu]);
    const { codeSpecificEntries } = useSelector((state: ApplicationState) => state.perks);
    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const renderPriceCell = React.useCallback(
        (params: GridRenderCellParams) => (
            <MuiGridPriceView
                value={params.value ?? ''}
                currencyCode={settings?.region?.currencyCode || 'GBP'}
            />
        ),
        [settings?.region?.currencyCode]
    );

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'productId',
                headerName: 'ID',
                width: 110
            },
            {
                field: 'title',
                headerName: 'Title',
                editable: true,
                width: 200
            },
            {
                field: 'price',
                headerName: 'Price',
                type: 'number',
                width: 130,
                headerAlign: 'left',
                editable: settings.menu?.productByCategoryIdEditingEnabled,
                renderCell: renderPriceCell,
                renderEditCell: props => renderEditPriceCell(props)
            },
            {
                field: 'priceLevel',
                headerName: 'Price Level',
                width: 130,
                headerAlign: 'left',
                editable: settings.menu?.productByCategoryIdEditingEnabled
            },
            {
                field: 'shortDescription',
                headerName: 'Description',
                editable: true,
                width: 200
            },
            {
                field: 'categoryTitle',
                headerName: 'Category Titles',
                editable: false,
                width: 220
            },
            {
                field: 'categoryDescription',
                headerName: 'Category Descriptions',
                editable: false,
                width: 220
            },
            {
                field: 'productGroupId',
                headerName: 'Product Group ID',
                editable: true,
                width: 190
            },
            {
                field: 'productGroupName',
                headerName: 'Product Group Name',
                editable: true,
                width: 220
            },
            {
                field: 'productGroupShortDescription',
                headerName: 'Product Group Description',
                editable: true,
                width: 220
            },
            {
                field: 'sort',
                headerName: 'Sort',
                editable: true,
                type: 'number',
                width: 120,
                headerAlign: 'left'
            },
            {
                field: 'channels',
                headerName: 'Available Channels',
                width: 230,
                editable: true,
                ...MultiSelectGridColumn(menuChannelLabel, 'All')
            },
            {
                field: 'availableScenarios',
                headerName: 'Available Scenarios',
                width: 230,
                editable: true,
                ...MultiSelectGridColumn(scenarioLabel, 'All')
            },
            {
                field: 'availableDays',
                headerName: 'Available Days',
                width: 230,
                editable: true,
                ...MultiSelectGridColumn(dayLabel, 'Every Day')
            },
            {
                field: 'perkMatchCodes',
                headerName: 'Perks',
                width: 300,
                editable: true,
                ...MultiSelectGridColumn(codeSpecificEntries, undefined, true)
            },
            {
                field: 'prepTimeMins',
                headerName: 'Preparation Time',
                editable: true,
                type: 'number',
                width: 220,
                headerAlign: 'left'
            },
            {
                field: 'imageUrl',
                headerName: 'Product Details Image',
                width: 300,
                ...ImagePickerColumn('product-', true, 600 / 260)
            },
            {
                field: 'suggestions',
                headerName: 'Suggested Product',
                width: 210,
                editable: true,
                ...SuggestionsGridColumn(productOptions)
            },
            ...(settings?.paymentProvider === EPaymentProvider.Stripe && !!settings.stripe?.connectEnabled
                ? [
                      {
                          field: 'terminalListingTextColour',
                          headerName: 'QuickPad Menu Listing Text Colour',
                          width: 240,
                          ...ColorPickerColumn()
                      },
                      {
                          field: 'terminalListingBackgroundColour',
                          headerName: 'QuickPad Menu Listing Background Colour',
                          width: 240,
                          ...ColorPickerColumn()
                      }
                  ]
                : []),
            {
                field: 'listingTextColour',
                headerName: 'Menu Listing Text Colour',
                width: 240,
                ...ColorPickerColumn()
            },
            {
                field: 'listingBackgroundColour',
                headerName: 'Menu Listing Background Colour',
                width: 240,
                ...ColorPickerColumn()
            },
            {
                field: 'listingImageUrl',
                headerName: 'Menu Listing Image',
                width: 300,
                ...ImagePickerColumn('product-listing-', true, 200 / 200)
            },
            {
                field: 'listingBackgroundImageUrl',
                headerName: 'Menu Listing Background Image',
                width: 300,
                ...ImagePickerColumn('product-listing-background-', true, 4 / 1)
            },
            {
                field: 'allergens',
                headerName: 'Allergens',
                width: 250,
                editable: true,
                ...MultiSelectGridColumn(allergensObject)
            },
            {
                field: 'tags',
                headerName: 'Tags',
                width: 250,
                editable: true,
                ...MultiSelectGridColumn(tagIdToTitle)
            },
            {
                field: 'nutrition',
                headerName: 'Nutrition Information',
                width: 250,
                editable: true,
                ...NutritionGridColumn()
            },
            {
                field: 'taxIds',
                headerName: 'Taxes',
                width: 200,
                ...MultiSelectGridColumn(taxIdToTitle),
                editable: false
            },
            {
                field: 'modifiers',
                headerName: 'Modifiers',
                width: 300,
                ...MultiSelectGridColumn(modifierIdToTitle),
                editable: settings.posProvider === EPointOfSaleProvider.COMTREX
            }
        ],
        [
            settings.menu?.productByCategoryIdEditingEnabled,
            settings?.paymentProvider,
            settings.stripe?.connectEnabled,
            settings.posProvider,
            renderPriceCell,
            codeSpecificEntries,
            productOptions,
            tagIdToTitle,
            taxIdToTitle,
            modifierIdToTitle
        ]
    );

    return columns;
}
