import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';
import { ChipInputFormField } from '../../../../lib/form/fields/ChipInputFormField';

export const SimphonySettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplay label="API Host URL">
                    <TextFormField name="simphonyHost" />
                </SettingDisplay>
                <SettingDisplay label="Enterprise Short Name">
                    <TextFormField name="simphonyEnterpriseShortName" />
                </SettingDisplay>
                <SettingDisplay label="Client ID">
                    <TextFormField name="simphonyClientId" />
                </SettingDisplay>
                <SettingDisplay label="Username">
                    <TextFormField name="simphonyUsername" />
                </SettingDisplay>
                <SettingDisplay label="Password">
                    <TextFormField name="simphonyPassword" />
                </SettingDisplay>
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <>
                <SettingDisplay label="Location Reference">
                    <TextFormField name="simphonyLocRef" />
                </SettingDisplay>
                <SettingDisplay label="Revenue Center Reference">
                    <TextFormField name="simphonyRvcRef" />
                </SettingDisplay>
                <SettingDisplay label="Employee Reference">
                    <TextFormField name="simphonyCheckEmployeeRef" />
                </SettingDisplay>
                <SettingDisplay label="Default Price Sequence">
                    <TextFormField name="simphonyDefaultPriceSequence" />
                </SettingDisplay>
                <SettingDisplay label="Price Sequences">
                    <ChipInputFormField
                        name="simphonyPriceSequences"
                        multiple
                        isCreatable={true}
                    ></ChipInputFormField>
                </SettingDisplay>
                <SettingDisplay label="Order Type Reference - Default">
                    <TextFormField name="simphonyOrderTypeRef_DEFAULT" />
                </SettingDisplay>
                <SettingDisplay label="Order Type Reference - Pre-Order">
                    <TextFormField name="simphonyOrderTypeRef_PREORDER" />
                </SettingDisplay>
                <SettingDisplay label="Order Type Reference - Order To Table">
                    <TextFormField name="simphonyOrderTypeRef_OTT" />
                </SettingDisplay>
                <SettingDisplay label="Tender ID - Default">
                    <TextFormField name="simphonyTenderId_DEFAULT" />
                </SettingDisplay>
                <SettingDisplay label="Tender ID - Card">
                    <TextFormField name="simphonyTenderId_CARD" />
                </SettingDisplay>
                <SettingDisplay label="Tender ID - Cash">
                    <TextFormField name="simphonyTenderId_CASH" />
                </SettingDisplay>
                <SettingDisplay label="Tender ID - Applepay">
                    <TextFormField name="simphonyTenderId_APPLEPAY" />
                </SettingDisplay>
                <SettingDisplay label="Tender ID - Googlepay">
                    <TextFormField name="simphonyTenderId_GOOGLEPAY" />
                </SettingDisplay>
                <SettingDisplay label="Tender ID - Terminal">
                    <TextFormField name="simphonyTenderId_TERMINAL" />
                </SettingDisplay>
                <SettingDisplay label="Tender ID - Giftcard">
                    <TextFormField name="simphonyTenderId_GIFTCARD" />
                </SettingDisplay>
            </>
        )}
    </>
);
