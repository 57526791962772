import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { MultiSelectFormField } from 'lib/form/fields/MultiSelectFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { Option } from 'lib/types';
import { Row } from 'ui/Flex';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { Container, ContainerDivision } from '@pepperhq/menu-sdk';

export interface BoxContainerFormData {
    x: string;
    y: string;
    width: string;
    height: string;
    division?: ContainerDivision;
    count: string;
    suitableFor: Option[];
}

interface IProps {
    onCancel: () => void;
    container?: Container;
    onSubmit: (data: Container) => void;
    options: Option[];
}

const validationSchema = yup.object().shape({
    x: yup
        .number()
        .required('This field is required.')
        .positive('Must be a value between 0 and 1.')
        .max(1, 'Must be a value between 0 and 1.'),
    y: yup
        .number()
        .required('This field is required.')
        .positive('Must be a value between 0 and 1.')
        .max(1, 'Must be a value between 0 and 1.'),
    width: yup
        .number()
        .required('This field is required.')
        .positive('Must be a value between 0 and 1.')
        .max(1, 'Must be a value between 0 and 1.'),
    height: yup
        .number()
        .required('This field is required.')
        .positive('Must be a value between 0 and 1.')
        .max(1, 'Must be a value between 0 and 1.'),
    division: yup.string().required('This field is required.'),
    count: yup
        .number()
        .integer('Must be a whole number')
        .positive('The value must be greater than 0.')
        .required('This field is required and must be a whole number only.'),
    suitableFor: yup.array().required('This field is required.').min(1, 'Please select a modifier product.')
});

const divisionOptions = [
    { value: ContainerDivision.VERTICAL, label: 'Vertical' },
    { value: ContainerDivision.HORIZONTAL, label: 'Horizontal' },
    { value: ContainerDivision.LAYERED, label: 'Layered' }
];

export const DynamicImageryBoxContainerDialog: React.FC<IProps> = ({
    container,
    onCancel,
    onSubmit,
    options
}) => {
    const handleSubmit = React.useCallback(
        (data: BoxContainerFormData) => {
            onSubmit({
                x: Number(data.x),
                y: Number(data.y),
                width: Number(data.width),
                height: Number(data.height),
                division: data.division,
                count: Number(data.count),
                suitableFor: data.suitableFor.map(item => item.value)
            });
        },
        [onSubmit]
    );
    const initialData = React.useMemo(() => {
        if (!container) {
            return {
                x: '',
                y: '',
                width: '',
                height: '',
                division: ContainerDivision.VERTICAL,
                count: '0',
                suitableFor: []
            };
        }
        return {
            x: String(container.x),
            y: String(container.y),
            width: String(container.width),
            height: String(container.height),
            division: container?.division || ContainerDivision.VERTICAL,
            count: String(container.count),
            suitableFor: container.suitableFor.map(item => options.find(option => option.value === item))
        };
    }, [container, options]);
    return (
        <Formik onSubmit={handleSubmit} initialValues={initialData} validationSchema={validationSchema}>
            {({ submitForm, isValid }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" color="primary">
                                {!!container ? 'Update ' : 'Create '}a container
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFormField
                                name="x"
                                label="X position"
                                type="number"
                                description="Horizontal position as a decimal where 0 is left, and 1 is right."
                                min={0}
                                max={1}
                                step={0.05}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFormField
                                name="y"
                                label="Y position"
                                type="number"
                                description="Vertical position as a decimal where 0 is top, and 1 is bottom."
                                min={0}
                                max={1}
                                step={0.05}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFormField
                                name="width"
                                label="Width"
                                type="number"
                                description="Width size of the container as a decimal where 1 is the full width of the background image."
                                min={0}
                                max={1}
                                step={0.05}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFormField
                                name="height"
                                label="Height"
                                type="number"
                                description="Height size of the container as a decimal where 1 is the full height of the background image."
                                min={0}
                                max={1}
                                step={0.05}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectFormField name="division" options={divisionOptions} label="Division" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFormField
                                name="count"
                                label="Count"
                                type="number"
                                description="The number of products which will stack in the column. Think of these as rows."
                                min={0}
                                max={99}
                                step={1}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MultiSelectFormField
                                options={options}
                                name="suitableFor"
                                label="Suitable For"
                                description="Select the modifier products that should use the dynamic imagery"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Row flex={1} align="flex-end" gutter>
                                <Button onClick={onCancel}>Cancel</Button>
                                <Button
                                    disabled={!isValid}
                                    onClick={submitForm}
                                    variant="contained"
                                    color="primary"
                                >
                                    Submit
                                </Button>
                            </Row>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
