import { Option } from 'lib/types';
import { ActionType } from 'components/actions/models/Action';

const NONE_OPTION: Option = {
    value: '',
    label: 'None'
};

export const getActionTypeOptions = (includeNoneOption = true): Option[] => {
    const initialOptions = includeNoneOption ? [{ ...NONE_OPTION }] : [];

    return [
        ...initialOptions,
        {
            value: ActionType.CHECKIN,
            label: 'Checkin',
            description: ActionType.CHECKIN
        },
        {
            value: ActionType.CARD_CREATED,
            label: 'Card Created',
            description: ActionType.CARD_CREATED
        },
        {
            value: ActionType.CARD_DELETED,
            label: 'Card Deleted',
            description: ActionType.CARD_DELETED
        },
        {
            value: ActionType.MEMBER_ADDED,
            label: 'Member Added',
            description: ActionType.MEMBER_ADDED
        },
        {
            value: ActionType.MEMBER_REMOVED,
            label: 'Member Removed',
            description: ActionType.MEMBER_REMOVED
        },
        {
            value: ActionType.ORDER_CREATED,
            label: 'Order Created',
            description: ActionType.ORDER_CREATED
        },
        {
            value: ActionType.ORDER_COMPLETED,
            label: 'Order Completed',
            description: ActionType.ORDER_COMPLETED
        },
        {
            value: ActionType.ORDER_USER_BILLED,
            label: 'Order User Billed',
            description: ActionType.ORDER_USER_BILLED
        },
        {
            value: ActionType.ORDER_ITEMS_ADDED,
            label: 'Items Added to Order',
            description: ActionType.ORDER_ITEMS_ADDED
        },
        {
            value: ActionType.ORDER_USER_JOINED,
            label: 'Order User Joined',
            description: ActionType.ORDER_USER_JOINED
        },
        {
            value: ActionType.ORDER_PAYMENT_RECEIVED,
            label: 'Order Payment Received',
            description: ActionType.ORDER_PAYMENT_RECEIVED
        },
        {
            value: ActionType.ORDER_PAYMENT_REFUNDED,
            label: 'Order Payment Refunded',
            description: ActionType.ORDER_PAYMENT_REFUNDED
        },
        {
            value: ActionType.REDEEM_PERK,
            label: 'Perk Redeemed',
            description: ActionType.REDEEM_PERK
        },
        {
            value: ActionType.REFERAL_CLAIMED_REFEREE,
            label: 'Referral Claimed (referee)',
            description: ActionType.REFERAL_CLAIMED_REFEREE
        },
        {
            value: ActionType.REFERAL_CLAIMED_REFERER,
            label: 'Referral Claimed (referrer)',
            description: ActionType.REFERAL_CLAIMED_REFERER
        },
        {
            value: ActionType.REFUND,
            label: 'Refund (V1)',
            description: ActionType.REFUND
        },
        {
            value: ActionType.REWARD_EARNED,
            label: 'Reward Earned',
            description: ActionType.REWARD_EARNED
        },
        {
            value: ActionType.AWARD_POINTS_REFUNDED,
            label: 'Reward Refunded',
            description: ActionType.AWARD_POINTS_REFUNDED
        },
        {
            value: ActionType.AWARD_POINTS_RESET,
            label: 'Reward Reset',
            description: ActionType.AWARD_POINTS_RESET
        },
        {
            value: ActionType.USER_ACTIVATED,
            label: 'User Activated',
            description: ActionType.USER_ACTIVATED
        },
        {
            value: ActionType.USER_ACTIVATION_RESENT,
            label: 'User Activation Resent',
            description: ActionType.USER_ACTIVATION_RESENT
        },
        {
            value: ActionType.USER_CHANGED,
            label: 'User Changed',
            description: ActionType.USER_CHANGED
        },
        {
            value: ActionType.USER_CREATED,
            label: 'User Created',
            description: ActionType.USER_CREATED
        },
        {
            value: ActionType.USER_CREDITED,
            label: 'User Credited',
            description: ActionType.USER_CREDITED
        },
        {
            value: ActionType.USER_DELETED,
            label: 'User Deleted',
            description: ActionType.USER_DELETED
        },
        {
            value: ActionType.VOUCHER_REDEEMED,
            label: 'Voucher Redeemed',
            description: ActionType.VOUCHER_REDEEMED
        },
        {
            value: ActionType.GIFT_CARD_ACTIVATED,
            label: 'Gift Card Activated',
            description: ActionType.GIFT_CARD_ACTIVATED
        },
        {
            value: ActionType.GIFT_CARD_TOPUP,
            label: 'Gift Card Topup',
            description: ActionType.GIFT_CARD_TOPUP
        },
        {
            value: ActionType.COUPON_REDEEMED,
            label: 'Coupon Redeemed',
            description: ActionType.COUPON_REDEEMED
        }
    ];
};

export const ACTION_TYPES_WITHOUT_LOCATION: ActionType[] = [
    ActionType.USER_CREATED,
    ActionType.USER_ACTIVATED,
    ActionType.USER_CHANGED,
    ActionType.USER_ACTIVATION_RESENT,
    ActionType.USER_CREDITED,
    ActionType.VOUCHER_REDEEMED,
    ActionType.CARD_CREATED,
    ActionType.CARD_DELETED,
    ActionType.REFERAL_CLAIMED_REFEREE,
    ActionType.REFERAL_CLAIMED_REFERER,
    ActionType.USER_DELETED,
    ActionType.MEMBER_REMOVED,
    ActionType.MEMBER_ADDED,
    ActionType.GIFT_CARD_ACTIVATED,
    ActionType.GIFT_CARD_TOPUP,
    ActionType.AWARD_POINTS_RESET
];

export const LOCATION_INDEPENDENT_ACTION_TYPES: ActionType[] = [ActionType.REWARD_EARNED];
