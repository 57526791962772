import { styled } from '@mui/material';
import { green, grey, orange, red } from '@mui/material/colors';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { titleize } from 'lib/helpers';
import React from 'react';
import { ScheduledTaskStatus } from './scheduledTasks.types';

const PREFIX = 'ScheduledTaskStatusCell';

const classes = {
    green: `${PREFIX}-green`,
    orange: `${PREFIX}-yellow`,
    red: `${PREFIX}-red`,
    grey: `${PREFIX}-gray`
};

const Root = styled('span')({
    [`&.${classes.green}`]: { color: green[500] },
    [`&.${classes.orange}`]: { color: orange[500] },
    [`&.${classes.red}`]: { color: red[500] },
    [`&.${classes.grey}`]: { color: grey[500] }
});

interface ScheduledTaskStatusCellProps {
    status: ScheduledTaskStatus;
}

const ScheduledTaskStatusCell: React.FC<ScheduledTaskStatusCellProps> = ({ status }) => {
    const className = React.useMemo(() => {
        switch (status) {
            case ScheduledTaskStatus.ERROR:
                return classes.red;
            case ScheduledTaskStatus.SCHEDULED:
            case ScheduledTaskStatus.QUEUED:
                return classes.orange;
            case ScheduledTaskStatus.DRAFT:
            case ScheduledTaskStatus.PROCESSING:
                return classes.grey;
            case ScheduledTaskStatus.PROCESSED:
            default:
                return classes.green;
        }
    }, [status]);

    return <Root className={className}>{titleize(status).replace('_', ' ')}</Root>;
};

export const renderScheduledTaskStatusCell = (params: GridRenderCellParams) => (
    <ScheduledTaskStatusCell status={params.value} />
);
