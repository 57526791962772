import { NotificationReceiptsFilter } from 'components/notification/filters/NotificationReceiptFilters';
import { updateArrayItem } from 'lib/helpers';
import { DEFAULT_PAGE_LIMIT } from 'lib/hooks/usePagedData';
import logger from 'lib/logger';
import { notificationApi } from '../notificationApi';
import React from 'react';
import { getNotificationReceiptsFilterQuery } from 'components/notification/hooks/useNotificationReceiptsFilter';
import {
    INotificationReceipt,
    NotificationReceiptStatus
} from 'components/notification/models/NotificationReceipt';
import { QueryParameter } from 'lib/HttpClient';
import { GridSortModel } from '@mui/x-data-grid-pro';

export function useNotificationReceipts(
    filter: NotificationReceiptsFilter,
    extraFilter?: QueryParameter[],
    emptyFilter?: boolean
) {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [endOfData, setEndOfData] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(0);
    const [pages, setPages] = React.useState([undefined]);
    const [receipts, setReceipts] = React.useState<INotificationReceipt[]>([]);
    const [internalFilter, setInternalFilter] = React.useState<NotificationReceiptsFilter>();
    const [noData, setNoData] = React.useState<boolean>(false);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'sentAt',
            sort: 'desc'
        }
    ]);
    const reset = React.useCallback(() => {
        setReceipts([]);
        setPages([undefined]);
        setPage(0);
        setLoading(true);
        setEndOfData(false);
    }, []);
    const generateSortString = React.useCallback((model: GridSortModel) => {
        if (model && model[0]) {
            const sortDirection = model[0]?.sort ?? 'desc';
            const sortField = model[0]?.field ?? 'sentAt';
            return `${sortDirection === 'asc' ? '' : '-'}${sortField}`;
        }
        return undefined;
    }, []);
    React.useEffect(() => {
        const getReceipts = async () => {
            if (!internalFilter || endOfData) {
                return;
            }
            setLoading(true);
            try {
                const queryParameters = getNotificationReceiptsFilterQuery(internalFilter);
                queryParameters.push({ key: 'limit', value: DEFAULT_PAGE_LIMIT.toString() });
                queryParameters.push({ key: 'include', value: 'userFullName' });
                if (pages[page]) {
                    queryParameters.push({ key: 'startKey', value: pages[page] });
                }
                if (sortModel?.length) {
                    queryParameters.push({ key: 'sort', value: generateSortString(sortModel) });
                }
                if (extraFilter) {
                    queryParameters.push(...extraFilter);
                }
                const { body, ok } = await (!internalFilter?.notificationId
                    ? notificationApi.getAggregatedReceipts({
                          queryParameters
                      })
                    : notificationApi.getNotificationReceipts(internalFilter.notificationId, {
                          queryParameters
                      }));
                if (!ok) {
                    throw new Error(body.message);
                }
                if (!body.page.nextKey) {
                    setEndOfData(true);
                }
                setReceipts(currentReceipts => [...currentReceipts, ...body.items]);
                setPages(currentPages => updateArrayItem(currentPages, page + 1, body.page.nextKey));
                setNoData(emptyFilter && !receipts.length && body.items.length === 0);
            } catch (e) {
                logger.error(e);
                setEndOfData(true);
                setReceipts([]);
                setNoData(true);
            } finally {
                setLoading(false);
            }
        };
        if (
            (page === 0 && receipts.length === 0) ||
            (!pages[page + 1] && receipts.length < pages.length * DEFAULT_PAGE_LIMIT)
        ) {
            getReceipts();
        }
    }, [
        endOfData,
        page,
        pages,
        receipts.length,
        sortModel,
        generateSortString,
        internalFilter,
        extraFilter,
        emptyFilter
    ]);
    React.useEffect(() => {
        setInternalFilter(filter);
        reset();
    }, [filter, reset]);
    const onPageChange = React.useCallback(
        (pageNumber: number) => {
            if (!loading) {
                setPage(pageNumber);
            }
        },
        [loading]
    );
    const onSortModelChange = React.useCallback(
        (model: GridSortModel) => {
            setSortModel(model);
            reset();
        },
        [reset]
    );
    const count = React.useMemo(() => {
        if (endOfData) {
            return receipts.length;
        }
        return -1;
    }, [endOfData, receipts.length]);
    const formattedReceipts = React.useMemo(() => {
        if (!Array.isArray(receipts)) {
            return [];
        }
        return receipts
            .slice(page * DEFAULT_PAGE_LIMIT, page * DEFAULT_PAGE_LIMIT + DEFAULT_PAGE_LIMIT)
            .map(receipt => {
                const status =
                    receipt.status ||
                    (receipt.errorMessage && NotificationReceiptStatus.ERROR) ||
                    (receipt.skipMessage && NotificationReceiptStatus.SKIPPED) ||
                    NotificationReceiptStatus.SENT;

                return {
                    id: receipt._id,
                    userFullName: receipt.userFullName || 'Unknown',
                    userId: receipt.userId,
                    status,
                    errorMessage: receipt.errorMessage,
                    skipMessage: receipt.skipMessage,
                    notificationId: receipt.notificationMessageId,
                    createdAt: receipt.createdAt,
                    sentAt: receipt.sentAt
                };
            });
    }, [receipts, page]);
    return {
        receipts,
        page,
        onPageChange,
        loading,
        count,
        formattedReceipts,
        sortModel,
        onSortModelChange,
        noData
    };
}
