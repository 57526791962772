/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Grid, Tab, Tabs, styled } from '@mui/material';
import { EBlockListAlias } from 'components/pepper-pay/block-list/enums';
import { pepperPayApi } from 'components/pepper-pay/pepperPayApi';
import { MainLayout } from 'layouts/MainLayout';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as routes from 'config/routes';
import { TableActionButton } from 'ui/buttons/TableActionButton';
import Add from '@mui/icons-material/Add';
import { RadarListItem, RadarListItemsResponse } from 'components/pepper-pay/block-list/models';
import { BlockListTable } from 'components/pepper-pay/block-list/BlockListTable';
import { AddBlockListItemModal } from 'components/pepper-pay/block-list/AddBlockListItemModal';
import { ConfirmDialog } from 'ui/dialogs/ConfirmDialog';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_ADD_BLOCK_LIST_ITEM_ERROR,
    MESSAGE_ADD_BLOCK_LIST_ITEM_SUCCESS,
    MESSAGE_ADD_BLOCK_LIST_ITEM_VALUE_ERROR,
    MESSAGE_REMOVE_BLOCK_LIST_ITEM_ERROR,
    MESSAGE_REMOVE_BLOCK_LIST_ITEM_SUCCESS
} from 'config/messages';
import { useDispatch } from 'react-redux';
import { LocationSelectGroupedByAccountId } from 'components/pepper-pay/LocationSelectGroupedByAccountId';
import { LocationSelectByAppIdContext } from '../LocationSelectByAppIdContext';
import { usePepperBlockList } from './hooks/usePepperBlockList';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { IPublicAndPrivateSettings } from '@pepperhq/location-sdk';

const PREFIX = 'block-list';

const classes = {
    tabs: `${PREFIX}-tabs`,
    tabPanelRoot: `${PREFIX}-tabPanelRoot`,
    label: `${PREFIX}-label`,
    switch: `${PREFIX}-switch`
};

const StyledMainLayout = styled(MainLayout)(({ theme }) => ({
    [`& .${classes.tabs}`]: {
        zIndex: 1,
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[2],
        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`
    },
    [`& .${classes.tabPanelRoot}`]: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0
    },
    [`& .${classes.label}`]: {
        color: theme.palette.common.white
    },
    [`& .${classes.switch}`]: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(3)
    }
}));

export const BlockListView: React.FC = () => {
    const dispatch = useDispatch();
    const [globalSettings, setGlobalSettings] = React.useState<IPublicAndPrivateSettings>({});
    const { selectedLocation } = useContext(LocationSelectByAppIdContext);
    const history = useHistory();
    const [radarLists, setRadarLists] = React.useState<{
        locationId: string;
        list: RadarListItemsResponse[];
    } | null>(null);
    const location = useLocation();
    const queryParams = React.useMemo(() => new URLSearchParams(location.search), [location]);
    const activeListAlias: EBlockListAlias = React.useMemo(() => {
        if (!globalSettings) {
            return null;
        }
        return (
            (queryParams.get('tabId') as EBlockListAlias) ||
            (globalSettings.emailAuthEnabled ? EBlockListAlias.EMAIL : EBlockListAlias.FINGERPRINT)
        );
    }, [globalSettings, queryParams]);
    const activeListId = React.useMemo(() => {
        if (radarLists && radarLists.locationId === selectedLocation && activeListAlias) {
            return radarLists.list.find(radarList => radarList.alias === activeListAlias)?.id || null;
        }

        return null;
    }, [activeListAlias, radarLists, selectedLocation]);
    const buttons: { key: EBlockListAlias; label: string }[] = React.useMemo(() => {
        const tabs = [
            {
                label: 'Card Fingerprint',
                key: EBlockListAlias.FINGERPRINT
            }
        ];

        if (globalSettings && globalSettings.emailAuthEnabled) {
            tabs.unshift({
                label: 'Email',
                key: EBlockListAlias.EMAIL
            });
        }

        return tabs;
    }, [globalSettings]);

    const [isLoading, setIsLoading] = React.useState(true);
    const [isNewModalOpen, setIsNewModalOpen] = React.useState(false);

    const [selectedItem, setSelectedItem] = React.useState<RadarListItem>(null);
    const [isRemoveLoading, setIsRemoveLoading] = React.useState(false);

    const {
        count,
        page,
        data,
        reset,
        onPageChange,
        loading: isRadarListItemsLoading
    } = usePepperBlockList({ selectedLocation, activeListId });

    const loadGlobalSettings = React.useCallback(async () => {
        setIsLoading(true);
        const settings = await new LocationSettingsApi().getPublicAndPrivateSettings(null);
        setGlobalSettings(settings);
    }, []);

    React.useEffect(() => {
        loadGlobalSettings();
    }, [loadGlobalSettings]);

    React.useEffect(() => {
        setIsLoading(true);
        if (selectedLocation) {
            pepperPayApi.radarList
                .getRadarLists(selectedLocation)
                .then(res => {
                    if (res.ok && res.body?.data) {
                        const lists = res.body.data;
                        setRadarLists({ locationId: selectedLocation, list: lists });
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [selectedLocation]);

    const renderButton = React.useCallback(
        ({ label, key }) => (
            <Tab
                disabled={isLoading}
                value={key}
                key={`tab-${key}`}
                label={<span className={classes.label}>{label}</span>}
            />
        ),
        [isLoading]
    );
    const handleChange = React.useCallback(
        (_: never, newValue: string) => {
            history.push({
                pathname: routes.PEPPER_PAY_BLOCK_LIST,
                search: `?tabId=${newValue}`
            });

            reset();
        },
        [history, reset]
    );
    const handleToggleAddModal = React.useCallback(() => {
        setIsNewModalOpen(val => !val);
    }, []);
    const toolbarItems = React.useMemo(
        () => [
            <TableActionButton key="add-new-block-item" onClick={handleToggleAddModal} Icon={Add}>
                Add
            </TableActionButton>
        ],
        [handleToggleAddModal]
    );
    const handleClickDelete = React.useCallback((item: RadarListItem) => {
        setSelectedItem(item);
    }, []);

    const handleAddNewValue = React.useCallback(
        (value: string) =>
            pepperPayApi.radarListItems
                .createNewListItem(activeListId, { value }, selectedLocation)
                .then(res => {
                    handleToggleAddModal();

                    if (res.ok) {
                        dispatch(
                            enqueueSnackbar(MESSAGE_ADD_BLOCK_LIST_ITEM_SUCCESS, { variant: 'success' })
                        );
                        reset();
                    } else if (res.body.code === 'E-PAY-0057') {
                        dispatch(
                            enqueueSnackbar(MESSAGE_ADD_BLOCK_LIST_ITEM_VALUE_ERROR(value), {
                                variant: 'error'
                            })
                        );
                    } else {
                        dispatch(enqueueSnackbar(MESSAGE_ADD_BLOCK_LIST_ITEM_ERROR, { variant: 'error' }));
                    }
                }),
        [activeListId, dispatch, reset, selectedLocation, handleToggleAddModal]
    );

    const handleConfirmDelete = React.useCallback(() => {
        if (selectedItem) {
            setIsRemoveLoading(true);
            pepperPayApi.radarListItems
                .deleteListItem(activeListId, selectedItem.id, selectedLocation)
                .then(res => {
                    setSelectedItem(null);
                    setIsRemoveLoading(false);

                    if (res.ok) {
                        dispatch(
                            enqueueSnackbar(MESSAGE_REMOVE_BLOCK_LIST_ITEM_SUCCESS, { variant: 'success' })
                        );
                        reset();
                    } else {
                        dispatch(enqueueSnackbar(MESSAGE_REMOVE_BLOCK_LIST_ITEM_ERROR, { variant: 'error' }));
                    }

                    return res;
                });
        }
    }, [activeListId, dispatch, reset, selectedLocation, selectedItem]);
    const handleConfirmCancel = React.useCallback(() => {
        setSelectedItem(null);
    }, []);
    const handleLocationChange = React.useCallback(
        (_: string) => {
            reset();
        },
        [reset]
    );

    return (
        <StyledMainLayout pageName="Blocked Lists" pageDescription="View and add blocked lists" noScroll>
            <Box marginBottom={1}>
                <Grid container justifyContent="flex-end">
                    <Grid xs={4}>
                        <LocationSelectGroupedByAccountId onLocationChange={handleLocationChange} />
                    </Grid>
                </Grid>
            </Box>
            <Tabs
                value={activeListAlias}
                onChange={handleChange}
                variant="scrollable"
                indicatorColor="primary"
                className={classes.tabs}
            >
                {buttons.map(renderButton)}
            </Tabs>
            <BlockListTable
                data={data}
                isLoading={isLoading || isRadarListItemsLoading}
                toolbarItems={toolbarItems}
                page={page}
                count={count}
                onDelete={handleClickDelete}
                onPageChange={onPageChange}
            />
            <AddBlockListItemModal
                open={isNewModalOpen}
                alias={activeListAlias}
                onClose={handleToggleAddModal}
                onCreate={handleAddNewValue}
            />
            <ConfirmDialog
                confirmLabel="Remove"
                cancelLabel="Cancel"
                title="Remove item?"
                content={`Are you sure you want to remove item "${selectedItem?.value}"?`}
                open={!!selectedItem}
                onConfirm={handleConfirmDelete}
                onCancel={handleConfirmCancel}
                loading={isRemoveLoading}
            />
        </StyledMainLayout>
    );
};
