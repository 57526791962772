import React from 'react';
import { ValueType } from 'react-select';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    TextFieldProps,
    Typography,
    Autocomplete,
    SelectChangeEvent
} from '@mui/material';
import { useFormik } from 'formik';
import randomColor from 'randomcolor';
import * as yup from 'yup';

import { MuiImagePicker } from 'lib/image-picker';
import { MultiSelectSuggest } from 'lib/MultiSelectSuggest';
import { isArrayOf, isDefined } from 'lib/typeguards';
import { Option } from 'lib/types';
import { Row } from 'ui/Flex';
import { Container, fromScopedKey } from '@pepperhq/menu-sdk';
import { EnrichedDynamicImagery, MenuType } from '../model/menu';
import {
    DYNAMIC_IMAGE_ASPECT_RATIO,
    isBoxContainer,
    isLayerContainer
} from 'components/menu/model/dynamicImagery';
import { DynamicImageryImageOverlay } from './DynamicImageryImageOverlay';
import { DynamicImageryContainersEdit } from './DynamicImageryContainersEdit';
import { DynamicImageryImagesList } from './DynamicImageryImagesList';
import { EnrichedProduct } from '../model/product';

interface DataModel {
    imageUrl: string;
    productId: string;
    imageryType: 'BOX' | 'LAYER';
    images: Record<string, { imageUrl: string; sort: number }>;
    boxContainers: Container[];
    count: string;
    suitableFor: Option[];
}

interface DynamicImageryEditProps {
    onClose: () => void;
    onSubmit: (dynamicImagery: EnrichedDynamicImagery) => void;
    onTouch?: () => void;
    dynamicImagery?: EnrichedDynamicImagery;
    products: EnrichedProduct[];
    menu: MenuType;
    productIdToTitle: Map<string, string>;
}

const validationSchema = yup.object().shape({
    imageUrl: yup.string().required('This field is required.'),
    productId: yup.string().required('This field is required.'),
    imageryType: yup.string().required('This field is required.'),
    images: yup.mixed().test(
        'are-images-valid',
        'Please upload an image and enter a sort number for each product.',
        (
            images: Record<
                string,
                {
                    imageUrl?: string;
                    sort?: number;
                }
            >
        ) =>
            Object.values(images).every(
                item => isDefined(item.imageUrl) && item.imageUrl.length && item.sort >= 0
            )
    ),
    count: yup.number().when('imageryType', {
        is: 'LAYER',
        then: yup
            .number()
            .integer('Must be a whole number')
            .min(1, 'The value must be greater than 0.')
            .required('This field is required and must be a whole number only.'),
        otherwise: undefined
    }),
    suitableFor: yup.array().when('imageryType', {
        is: 'LAYER',
        then: yup.array().min(1, 'Please select a modifier product.').required('This field is required.'),
        otherwise: undefined
    }),
    boxContainers: yup.array().when('imageryType', {
        is: 'BOX',
        then: yup.array().min(1, 'Please add a container.').required('This field is required.'),
        otherwise: undefined
    })
});

const getInitialValues = (
    dynamicImagery?: EnrichedDynamicImagery,
    productIdToTitle?: Map<string, string>
): DataModel => ({
    imageUrl: dynamicImagery?.baseImageUrl,
    productId: dynamicImagery?.id || '',
    imageryType: !dynamicImagery?.containers.some(container => container.division) ? 'LAYER' : 'BOX',
    images: dynamicImagery?.images || {},
    boxContainers: isArrayOf(isBoxContainer, dynamicImagery?.containers) ? dynamicImagery?.containers : [],
    suitableFor:
        isArrayOf(isLayerContainer, dynamicImagery?.containers) && !!productIdToTitle
            ? dynamicImagery?.containers[0]?.suitableFor.map(item => ({
                  value: item,
                  label: productIdToTitle.get(item)
              }))
            : [],
    count: String(dynamicImagery?.containers[0]?.count ?? 0)
});

export const DynamicImageryEditForm: React.FC<DynamicImageryEditProps> = ({
    onClose,
    onTouch,
    dynamicImagery,
    menu,
    onSubmit,
    productIdToTitle,
    products
}) => {
    const handleSubmit = React.useCallback(
        (data: DataModel) => {
            const containers =
                data.imageryType === 'BOX'
                    ? data.boxContainers
                    : [{ suitableFor: data.suitableFor.map(item => item.value), count: Number(data.count) }];
            onSubmit({
                containers,
                baseImageUrl: data.imageUrl,
                images: data.images,
                id: data.productId,
                productId: data.productId
            });
        },
        [onSubmit]
    );
    const initial = React.useMemo(
        () => getInitialValues(dynamicImagery, productIdToTitle),
        [dynamicImagery, productIdToTitle]
    );
    const formik = useFormik<DataModel>({
        initialValues: initial,
        onSubmit: handleSubmit,
        validationSchema
    });
    const handleCancel = React.useCallback(() => {
        onClose();
    }, [onClose]);
    const handleTouched = React.useCallback(
        (name: string) => {
            if (onTouch) {
                onTouch();
            }
            formik.setFieldTouched(name, true, false);
        },
        [formik, onTouch]
    );
    const handleImageSelect = React.useCallback(
        async (resultUrl: string) => {
            await formik.setFieldValue('imageUrl', resultUrl);
            handleTouched('imageUrl');
        },
        [formik, handleTouched]
    );
    const handleChange = React.useCallback(
        (e: React.ChangeEvent<{ value: string; name: string }> | SelectChangeEvent) => {
            formik.setFieldValue(e.target.name, e.target.value);
            handleTouched(e.target.name);
        },
        [formik, handleTouched]
    );
    const handleProductChange = React.useCallback(
        async (_: never, value: EnrichedProduct) => {
            await formik.setFieldValue('productId', value?.productId ?? undefined);
            await formik.setFieldValue('images', []);
            await formik.setFieldValue('suitableFor', []);
            await formik.setFieldValue('boxContainers', []);
            handleTouched('productId');
        },
        [formik, handleTouched]
    );
    const handleImagesChange = React.useCallback(
        async (images: Record<string, { imageUrl: string; sort: number }>) => {
            await formik.setFieldValue('images', images);
            handleTouched('images');
        },
        [formik, handleTouched]
    );
    const handleBoxContainersChange = React.useCallback(
        async (containers: Container[]) => {
            await formik.setFieldValue('boxContainers', containers);
            handleTouched('boxContainers');
            const newImages: Record<string, { imageUrl: string; sort: number }> = {};
            containers.forEach(container => {
                container.suitableFor.forEach(item => {
                    newImages[item] = formik.values.images[item] || {
                        imageUrl: undefined,
                        sort: 0
                    };
                });
            });
            await formik.setFieldValue('images', newImages);
            handleTouched('images');
        },
        [formik, handleTouched]
    );
    const handleSuitableForChange = React.useCallback(
        async (value: ValueType<Option, true> | ValueType<Option, false>) => {
            if (value === null || typeof value === 'undefined') {
                await formik.setFieldValue('suitableFor', []);
            } else if (Array.isArray(value)) {
                await formik.setFieldValue('suitableFor', value);
            } else {
                await formik.setFieldValue('suitableFor', [value]);
            }
            handleTouched('suitableFor');
            const newImages: Record<string, { imageUrl: string; sort: number }> = {};
            if (Array.isArray(value)) {
                value.forEach(item => {
                    newImages[item.value] = formik.values.images[item.value] || {
                        imageUrl: undefined,
                        sort: 0
                    };
                });
            }
            await formik.setFieldValue('images', newImages);
            handleTouched('images');
        },
        [formik, handleTouched]
    );
    const getProductOptionsLabel = React.useCallback(
        (item: EnrichedProduct) =>
            item.productGroupName ? `${item.productGroupName} - ${item.title}` : item.title,
        []
    );
    const renderProductSelectInput = React.useCallback(
        (params: TextFieldProps) => (
            <TextField
                {...params}
                label="Product"
                error={!!formik.errors.productId && formik.touched.productId}
                helperText={
                    formik.errors.productId ||
                    'Select the product you would like to apply the dynamic imagery to.'
                }
                fullWidth
            />
        ),
        [formik.errors.productId, formik.touched.productId]
    );
    const colors = React.useMemo(
        () => randomColor({ alpha: 0.5, count: formik.values.boxContainers.length, format: 'rgba' }),
        [formik.values.boxContainers.length]
    );
    const productValue = React.useMemo(
        () => products.find(item => item.productId === formik.values.productId),
        [formik.values.productId, products]
    );
    const currentCategory = React.useMemo(() => {
        if (!productValue) {
            return undefined;
        }
        // not expecting any scoped keys, but just in case
        const { parentId: _, id } = fromScopedKey(productValue.productId);
        return menu?.categories.find(category => category.products.some(product => product.id === id));
    }, [menu?.categories, productValue]);

    const selectedProducts = React.useMemo(() => {
        const newSelectedProducts: Option[] = [];
        if (!!productIdToTitle) {
            if (formik.values.imageryType === 'BOX') {
                formik.values.boxContainers.forEach(item => {
                    item.suitableFor.forEach(id => {
                        newSelectedProducts.push({ value: id, label: productIdToTitle.get(id) });
                    });
                });
            } else if (Array.isArray(formik.values.suitableFor)) {
                formik.values.suitableFor.forEach(option => {
                    newSelectedProducts.push(option);
                });
            }
        }
        return newSelectedProducts;
    }, [formik.values.boxContainers, formik.values.imageryType, formik.values.suitableFor, productIdToTitle]);
    const availableProducts = React.useMemo(() => {
        if (!productIdToTitle || !currentCategory) {
            return [];
        }
        const allModifiers = productValue?.modifiers?.map(
            modifierId =>
                currentCategory.modifiers?.find(modifier => modifier.id === modifierId) ??
                menu.modifiers.find(modifier => modifier.id === modifierId)
        );
        const available: { label: string; value: string }[] = [];
        allModifiers
            .filter(item => !!item?.products?.length)
            .forEach(modifier => {
                modifier.products.forEach(product => {
                    available.push({
                        value: product.id,
                        label: productIdToTitle.get(product.id) ?? product.id
                    });
                });
            });
        return available;
    }, [currentCategory, menu.modifiers, productIdToTitle, productValue?.modifiers]);
    const overlay = React.useMemo(
        () => <DynamicImageryImageOverlay boxContainers={formik.values.boxContainers} colors={colors} />,
        [formik.values.boxContainers, colors]
    );
    return (
        <Box padding={3} paddingTop={2} minWidth={700}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} container spacing={2}>
                    <Grid item xs={12}>
                        <MuiImagePicker
                            unremovable
                            fullWidth
                            onSelect={handleImageSelect}
                            url={formik.values.imageUrl}
                            overlay={formik.values.imageryType === 'BOX' ? overlay : undefined}
                            aspect={1 / DYNAMIC_IMAGE_ASPECT_RATIO}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            value={productValue}
                            options={products}
                            onChange={handleProductChange}
                            getOptionLabel={getProductOptionsLabel}
                            renderInput={renderProductSelectInput}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            error={!!formik.errors.imageryType && !!formik.touched.imageryType}
                        >
                            <InputLabel
                                sx={{ backgroundColor: 'common.white', px: 0.5 }}
                                htmlFor="imagery-type-input"
                            >
                                Type
                            </InputLabel>
                            <Select
                                labelId="imagery-type-input"
                                value={formik.values.imageryType || ''}
                                onChange={handleChange}
                                name="imageryType"
                                fullWidth
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="BOX">
                                    <em>Box</em>
                                </MenuItem>
                                <MenuItem value="LAYER">
                                    <em>Layer</em>
                                </MenuItem>
                            </Select>
                            <FormHelperText sx={{ mx: 0 }}>
                                {!!formik.errors.imageryType && formik.touched.imageryType
                                    ? formik.errors.imageryType
                                    : 'Set the type of the dynamic imagery.'}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    {formik.values.imageryType === 'BOX' && (
                        <Grid item xs={12}>
                            <Typography>Containers</Typography>
                            <DynamicImageryContainersEdit
                                colors={colors}
                                containers={formik.values.boxContainers}
                                onChange={handleBoxContainersChange}
                                options={availableProducts}
                            />
                            {!!formik.errors.boxContainers && formik.touched.boxContainers && (
                                <Typography variant="caption" color="error">
                                    {formik.errors.boxContainers}
                                </Typography>
                            )}
                        </Grid>
                    )}
                    {formik.values.imageryType === 'LAYER' && (
                        <Grid item xs={12}>
                            <MultiSelectSuggest
                                label="Suitable For"
                                value={formik.values.suitableFor}
                                isMulti
                                isCreatable={false}
                                name="suitableFor"
                                error={!!formik.errors.suitableFor && !!formik.touched.suitableFor}
                                helperText={
                                    !!formik.errors.suitableFor
                                        ? formik.errors.suitableFor
                                        : 'Select the modifier products that should use the dynamic imagery'
                                }
                                onChange={handleSuitableForChange}
                                options={availableProducts}
                            />
                        </Grid>
                    )}
                    {formik.values.imageryType === 'LAYER' && (
                        <Grid item xs={12}>
                            <TextField
                                name="count"
                                value={formik.values.count}
                                error={!!formik.errors.count && formik.touched.count}
                                type="number"
                                label="Count"
                                fullWidth
                                helperText={
                                    !!formik.errors.count
                                        ? formik.errors.count
                                        : 'The number of products which will stack on top of the background image. Think of these as layers.'
                                }
                                onChange={handleChange}
                            />
                        </Grid>
                    )}
                </Grid>
                {!!selectedProducts?.length && (
                    <Grid item xs={12} sm={6} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>Images</Typography>
                            <DynamicImageryImagesList
                                products={selectedProducts}
                                images={formik.values.images}
                                onChange={handleImagesChange}
                            />
                            {!!formik.errors.images && formik.touched.images && (
                                <Typography variant="caption" color="error">
                                    {formik.errors.images}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                )}
                {!selectedProducts?.length && (
                    <Grid item xs={12} sm={6}>
                        <Box
                            height="100%"
                            width="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography>Selected modifier products will be listed here</Typography>
                        </Box>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {!formik.isValid && (
                        <Typography variant="caption" color="textSecondary">
                            Fill all the fields to submit this form
                        </Typography>
                    )}
                    <Row flex={1} align="flex-end" valign="center" gutter>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button
                            onClick={formik.submitForm}
                            variant="contained"
                            color="primary"
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            Submit
                        </Button>
                    </Row>
                </Grid>
            </Grid>
        </Box>
    );
};
