import React from 'react';
import { styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { MuiModal } from 'ui/MuiModal';
import { AddVoucherEffectFormData, VoucherAddEffectsForm } from '../forms/VoucherAddEffectsForm';
import { Voucher } from '../models/VoucherModel';

const StyledMuiModal = styled(MuiModal)(({ theme }) => ({
    width: theme.spacing(60),
    padding: theme.spacing(2)
}));

interface CreateVoucherEffectsModalProps {
    open: boolean;
    onClose?: () => void;
    onSubmit: (values: AddVoucherEffectFormData) => void;
    voucher: Voucher;
}

export const AddVoucherEffectsModal: React.FunctionComponent<CreateVoucherEffectsModalProps> = props => {
    const { open, onClose, onSubmit, voucher } = props;
    const { perks } = useSelector((state: ApplicationState) => state.perks);
    const { audiences } = useSelector((state: ApplicationState) => state.audiences);

    const perksOptions = React.useMemo(
        () => perks.map(item => ({ value: item._id, label: item.title })),
        [perks]
    );
    const audiencesOptions = React.useMemo(
        () => audiences.map(item => ({ value: item._id, label: item.title })),
        [audiences]
    );

    return (
        <StyledMuiModal open={open} onClose={onClose}>
            <VoucherAddEffectsForm
                onSubmit={onSubmit}
                onClose={onClose}
                perksOptions={perksOptions}
                audiencesOptions={audiencesOptions}
                voucher={voucher}
            />
        </StyledMuiModal>
    );
};
