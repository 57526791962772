import { Box, Button, TextField, Typography } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { ListPicker, ListPickerOption } from 'lib/ListPicker';
import React from 'react';
import { Row } from 'ui/Flex';
import { MuiModal } from 'ui/MuiModal';
import { MuiGridMultiselectView } from './MuiGridMultiselectView';

interface MuiGridCreatableSelectProps extends GridRenderEditCellParams {
    title: string;
    description?: string;
    inputLabel?: string;
    inputHelper?: string;
    selectLabel?: string;
    disabledItems?: string[];
    labels: Map<string, string>;
}

export const MuiGridCreatableSelect: React.FC<MuiGridCreatableSelectProps> = ({
    value,
    title,
    description,
    inputLabel,
    inputHelper,
    selectLabel,
    disabledItems,
    labels,
    id,
    field,
    api
}) => {
    const options = React.useMemo(() => {
        const newOptions: ListPickerOption[] = [];
        if (labels instanceof Map) {
            for (const [value, label] of labels.entries()) {
                newOptions.push({ label, value });
            }
        }
        return newOptions;
    }, [labels]);
    const [open, setOpen] = React.useState(true);
    const [selectedString, setSelectedString] = React.useState<string>(
        Array.isArray(value) ? value.join(',') : ''
    );
    const [internalValue, setInternalValue] = React.useState<string[]>(Array.isArray(value) ? value : []);
    const handleCustomChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedString(e.target.value);
        setInternalValue(e.target.value?.split(',').map(item => item.trim()));
    }, []);
    const handleSelect = React.useCallback((data: string[]) => {
        setInternalValue(data);
        setSelectedString(data.join(','));
    }, []);
    const handleClose = React.useCallback(() => {
        setOpen(false);
        api.stopCellEditMode({ id, field });
    }, [api, field, id]);
    const handleSubmit = React.useCallback(
        (event: React.MouseEvent) => {
            api.setEditCellValue({ id, field, value: internalValue }, event);
            api.stopCellEditMode({ id, field });
            setOpen(false);
        },
        [api, field, id, internalValue]
    );
    return (
        <React.Fragment>
            <MuiGridMultiselectView value={value} labels={labels} />
            <MuiModal open={open} onClose={handleClose}>
                <div>
                    <Typography variant="h6">{title}</Typography>
                    {!!description && (
                        <Typography variant="caption" component="p" color="textSecondary">
                            {description}
                        </Typography>
                    )}
                    <Box width="100%" paddingTop={1} paddingBottom={1}>
                        <TextField
                            label={inputLabel}
                            helperText={inputHelper}
                            value={selectedString}
                            onChange={handleCustomChange}
                            fullWidth
                        />
                    </Box>
                    {!!selectLabel && (
                        <Box width="100%">
                            <Typography variant="body2">{selectLabel}</Typography>
                        </Box>
                    )}
                    <Box width="100%">
                        <ListPicker
                            items={options}
                            selectedItems={internalValue}
                            onChange={handleSelect}
                            disabledItems={disabledItems}
                        />
                    </Box>
                    <Row flex={1} align="flex-end" gutter>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Row>
                </div>
            </MuiModal>
        </React.Fragment>
    );
};
