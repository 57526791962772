import React from 'react';
import { Box, Grid, Skeleton, styled } from '@mui/material';
import { segmentsApi } from 'components/audience/segmentsApi';
import { locationApi } from 'components/location/LocationApi';
import { Panel } from 'ui/Panel';
import { CardViewChipArrayRow, CardViewChipRow, CardViewTextRow, CardViewTitle } from 'ui/viewComponents';
import { mapNotificationStatusToStyles, renderDeepLinkTitle } from '../helpers';
import { Notification } from '../models/Notification';
import { formatDateTime } from 'lib/timeHelpers';
import { contentApi } from 'components/content/contentApi';

import { ResourceText } from 'lib/ResourceText';

const PREFIX = 'NotificationDetailsCard';

const classes = {
    stateChip: `${PREFIX}-stateChip`
};

const StyledBox = styled(Box)<{ status: Notification<string>['status'] }>(({ theme, status }) => ({
    [`& .${classes.stateChip}`]: {
        ...mapNotificationStatusToStyles(status, theme),
        fontSize: '0.8rem'
    }
}));

interface NotificationDetailsCardProps {
    notification: Notification<string>;
}

export const NotificationDetailsCard: React.FC<NotificationDetailsCardProps> = props => {
    const { notification } = props;
    const [locations, setLocations] = React.useState(null);
    const [audiences, setAudiences] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        async function getData() {
            if (Array.isArray(notification.locationIds) && notification.locationIds.length > 0) {
                const result = await Promise.all(notification.locationIds.map(item => locationApi.get(item)));
                const locationNames: string[] = [];
                result.forEach(item => {
                    if (item.ok) {
                        locationNames.push(item.body.title);
                    }
                });
                if (locationNames.length) {
                    setLocations(locationNames);
                }
            }
            // test segments
            if (Array.isArray(notification.segmentIds) && notification.segmentIds.length > 0) {
                const result = await Promise.all(notification.segmentIds.map(item => segmentsApi.get(item)));
                const audienceNames: string[] = [];
                result.forEach(item => {
                    if (item.ok) {
                        audienceNames.push(item.body.title);
                    }
                });
                if (audienceNames.length) {
                    setAudiences(audienceNames);
                }
            }
            setIsLoading(false);
        }
        getData();
    }, [notification.locationIds, notification.segmentIds]);
    return (
        <Panel title="Details" divider>
            <Grid container spacing={2}>
                {notification.title && (
                    <Grid item xs={12}>
                        <CardViewTextRow title="Title" value={notification.title} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <CardViewTextRow title="Message" value={notification.message} />
                </Grid>
                {notification.imageUrl && (
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <CardViewTitle title="Image" />
                            <img
                                src={notification.imageUrl}
                                alt="Image"
                                style={{
                                    aspectRatio: 'initial',
                                    maxWidth: '100%',
                                    maxHeight: '200px'
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
                {notification.deepLink && (
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <CardViewTitle title="Deep Link" noTransform />
                            <ResourceText
                                id={notification.deepLink?.moduleId}
                                crud={contentApi.modules}
                                title={renderDeepLinkTitle(notification.deepLink)}
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <StyledBox status={notification.status}>
                        <CardViewChipRow
                            {...props}
                            title="State"
                            value={notification.status}
                            chipClasses={{ root: classes.stateChip }}
                        />
                    </StyledBox>
                </Grid>
                {notification.tag && (
                    <Grid item xs={12}>
                        <CardViewTextRow title="Tag" value={notification.tag} />
                    </Grid>
                )}
                {notification.errorMessage && (
                    <Grid item xs={12}>
                        <CardViewTextRow title="Error" value={notification.errorMessage} />
                    </Grid>
                )}
                {notification.status !== 'DRAFT' && (
                    <Grid item xs={12}>
                        <CardViewTextRow
                            title="Sent On"
                            noTransform
                            value={formatDateTime(notification.completedAt)}
                            fallback="Processing"
                        />
                    </Grid>
                )}
                {notification.global && (
                    <Grid item xs={12}>
                        <CardViewTextRow title="Sent To" value="All Users" />
                    </Grid>
                )}
                {isLoading && (
                    <Grid item xs={12}>
                        <Box marginBottom={1} flex={1}>
                            <Skeleton height={10} width="40%" />
                            <Skeleton variant="rectangular" height={24} width="80%" />
                        </Box>
                    </Grid>
                )}
                {Array.isArray(locations) && (
                    <Grid item xs={12}>
                        <CardViewChipArrayRow title="Locations" value={locations} />
                    </Grid>
                )}
                {Array.isArray(audiences) && (
                    <Grid item xs={12}>
                        <CardViewChipArrayRow title="Audiences" value={audiences} />
                    </Grid>
                )}
            </Grid>
        </Panel>
    );
};
