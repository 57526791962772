import {
    isArrayOf,
    isArrayOfCurried,
    isBoolean,
    isEnumOfType,
    isNumber,
    isOptional,
    isOptionalString,
    isString
} from 'lib/typeguards';

export enum NotificationType {
    Alert = 'alert',
    OrderFailed = 'order_failed',
    OrderCompleted = 'order_completed',
    Topup = 'topup',
    Survey = 'survey',
    Credit = 'credit',
    Referral = 'referral',
    Unknown = 'unknown'
}

export interface NotificationResult<T> {
    body: Notification<T>;
    ok: boolean;
}

export enum NotificationStatus {
    DRAFT = 'DRAFT',
    PENDING = 'PENDING',
    DONE = 'DONE',
    ERROR = 'ERROR'
}

// Should this be Generic?
// Aren't keys always returned as hex strings?
export interface Notification<T> {
    completedAt?: string;
    createdAt: string;
    updatedAt: string;
    failures: 0 | 1;
    global: boolean;
    isProcessingMessage: boolean;
    locationIds?: string[];
    segmentIds?: string[];
    title?: string;
    message: string;
    imageUrl?: string;
    deepLink?: NotificationDeeplink;
    buttonText?: string;
    sent: 0 | 1;
    skipped: 0 | 1;
    status: NotificationStatus;
    tenantId: string;
    type: NotificationType;
    userIds?: string[];
    value: string;
    errorMessage?: string;
    tag?: string;
    _id: T;
}

export interface NotificationDeeplinkBase {
    moduleId: string;
    moduleItemId: string;
}

export interface NotificationDeeplinkVoucher extends NotificationDeeplinkBase {
    voucher?: string;
}

export type NotificationDeeplink = NotificationDeeplinkBase | NotificationDeeplinkVoucher;

export interface NotificationCreate {
    status?: 'DRAFT' | 'PENDING';
    title?: string;
    message: string;
    imageUrl?: string;
    deepLink?: NotificationDeeplink;
    buttonText?: string;
    type: NotificationType;
    value: string;
    isProcessingMessage: boolean;
    userIds?: string[];
    locationIds?: string[];
    segmentIds?: string[];
    global: boolean;
    tag?: string;
}

interface Page {
    nextKey?: string;
    startKey: string;
}

interface NotificationData {
    items: Notification<string>[];
    page: Page;
}

export function isNotificationDeeplink(obj: any): obj is NotificationDeeplink {
    return isNotificationDeeplinkVoucher(obj) || isNotificationDeeplinkBase(obj);
}

export function isNotificationDeeplinkBase(obj: any): obj is NotificationDeeplinkBase {
    return obj && isString(obj.moduleId) && isString(obj.moduleItemId);
}

export function isNotificationDeeplinkVoucher(obj: any): obj is NotificationDeeplinkVoucher {
    return isOptional(isString, obj.voucher) && isNotificationDeeplinkBase(obj);
}

export function isNotification(input: any): input is Notification<string> {
    return (
        isString(input._id) &&
        isOptionalString(input.completedAt) &&
        isString(input.createdAt) &&
        isNumber(input.failures) &&
        isBoolean(input.global) &&
        isBoolean(input.isProcessingMessage) &&
        isOptional(isArrayOfCurried(isString), input.locationIds) &&
        isOptionalString(input.title) &&
        isString(input.message) &&
        isOptionalString(input.imageUrl) &&
        isOptional(isNotificationDeeplink, input.deepLink) &&
        isOptional(isArrayOfCurried(isString), input.segmentIds) &&
        isNumber(input.sent) &&
        isNumber(input.skipped) &&
        isEnumOfType(NotificationStatus, input.status) &&
        isString(input.tenantId) &&
        isString(input.type) &&
        isOptional(isArrayOfCurried(isString), input.userIds) &&
        isString(input.value) &&
        (!input.errorMessage || isString(input.errorMessage))
    );
}

export function isNotificationData(input: any): input is NotificationData {
    return (
        isArrayOf(isNotification, input.items) &&
        !!input.page &&
        isOptionalString(input.page.nextKey) &&
        isOptionalString(input.page.startKey)
    );
}
