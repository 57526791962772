import { Button, Grid, Typography } from '@mui/material';
import { FieldArray, Form } from 'formik';
import { MuiForm } from 'lib/Form';
import React from 'react';
import { Row } from 'ui/Flex';
import * as Yup from 'yup';
import { TagValuesForm } from './TagValuesForm';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import logger from 'lib/logger';
import { isString } from 'lib/typeguards';
import { uploadFile } from 'components/content/uploadFile';
import { LoadingButton } from 'ui/buttons/LoadingButton';

export interface TagFormValues {
    id: string;
    title: string;
    sort?: number;
    imageUrl?: string | File[];
}

export interface TagForm {
    id: string;
    sort: number;
    title: string;
    selected?: boolean;
    isFilter: boolean;
    values: TagFormValues[];
}

export interface TagsFormProps {
    tags?: TagFormValues[];
    onClose: () => void;
    onSave: (tags: TagFormValues[]) => void;
    onClear: () => void;
}

const schema = Yup.object().shape({
    values: Yup.array()
        .of(
            Yup.object().shape({
                id: Yup.number()
                    .typeError('Must be a number')
                    .integer('Must be a whole number')
                    .required('This field is required and must be a whole number only.'),
                title: Yup.string().required('This field is required')
            })
        )
        .required('This field is required')
});

export const TagsForm: React.FC<TagsFormProps> = ({ tags, onClose, onClear, onSave }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);

    const handleSubmit = React.useCallback(
        async ({ values }: { values: TagFormValues[] }) => {
            const copyValues = [...values];

            async function postImages(values: TagFormValues[]) {
                setLoading(true);
                return Promise.all(
                    values.map(async tag => {
                        if (tag?.imageUrl && !isString(tag?.imageUrl)) {
                            try {
                                const [file] = tag.imageUrl;

                                const result = await uploadFile(file, `tag-image-${uuid()}`, 'menu');
                                if (!result.ok) {
                                    throw new Error(result.body.message);
                                }

                                const [{ url }] = result.body.items;
                                return {
                                    ...tag,
                                    imageUrl: url
                                };
                            } catch (e) {
                                logger.error(e.message);
                                dispatch(
                                    enqueueSnackbar(
                                        'Failed to upload an image. Please make sure the image size is less than 1MB.',
                                        { variant: 'warning' }
                                    )
                                );
                            } finally {
                                setLoading(false);
                            }
                        }
                        return tag;
                    })
                );
            }

            const updatedValues = await postImages(copyValues);

            onSave(updatedValues);
            onClose();
        },
        [dispatch, onClose, onSave]
    );

    const handleRemove = React.useCallback(() => {
        onClear();
        onClose();
    }, [onClear, onClose]);

    const initialValues = React.useMemo<{ values: TagFormValues[] }>(
        () =>
            Array.isArray(tags) && tags.length
                ? { values: tags }
                : { values: [{ id: '', title: '', sort: 0 }] },
        [tags]
    );

    return (
        <MuiForm initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
            {({ submitForm }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" color="primary">
                                Edit Tags
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FieldArray name="values" component={TagValuesForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <Row align="space-between" flex={1}>
                                <Row flex={1}>
                                    <Button onClick={handleRemove} color="primary">
                                        Remove Tag
                                    </Button>
                                </Row>
                                <Row gutter>
                                    <Button onClick={onClose}>Cancel</Button>
                                    <LoadingButton
                                        color="primary"
                                        variant="contained"
                                        disabled={loading}
                                        loading={loading}
                                        onClick={submitForm}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Row>
                            </Row>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </MuiForm>
    );
};
