import { GridCellParams } from '@mui/x-data-grid-pro';
import { MenuChangeSchema, MenuChangeUpdate } from '@pepperhq/menu-sdk';
import { MenuPickerModal } from 'components/menu-manager/modals/MenuPickerModal';
import { menuApi } from 'components/menu/MenuApi';
import { useToggle } from 'lib/hooks/useToggle';
import logger from 'lib/logger';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMenuSummaryList } from 'store/menu-manager/menuManagerAction';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { ApplicationState } from 'store/store';

export const LocationsContext = React.createContext([]);
export const ActiveOperationContext = React.createContext({
    activeOperationId: null,
    setOperationId: (_id: string) => {}
});

export function useTabCopy(
    resetSelection: () => void,
    selectedCell?: GridCellParams,
    includeFieldsToCopy: string[] = []
) {
    const dispatch = useDispatch();
    const { activeOperationId, setOperationId } = React.useContext(ActiveOperationContext);
    const [isCopyModalOpen, toggleIsCopyModalOpen] = useToggle(false);
    const [menuChangeToCopy, setMenuChangeToCopy] = React.useState<MenuChangeUpdate>({
        schema: MenuChangeSchema.v5
    });
    const { menuSummaryList = [], hasLoaded } = useSelector((state: ApplicationState) => state.menuManager);

    const selectedCellToCopy = React.useMemo(() => {
        if (includeFieldsToCopy.includes(selectedCell?.field)) {
            return selectedCell;
        }
        return null;
    }, [includeFieldsToCopy, selectedCell]);
    React.useEffect(() => {
        if (!hasLoaded) {
            getMenuSummaryList(dispatch);
        }
    }, [hasLoaded, dispatch]);

    const handleModalOpen = React.useCallback(() => {
        toggleIsCopyModalOpen();
    }, [toggleIsCopyModalOpen]);

    const handleBulkUpdate = React.useCallback(
        async (selectedMenus: string[]) => {
            try {
                const result = await menuApi.bulkUpdateMenuChange(selectedMenus, menuChangeToCopy);
                if (!result.ok) {
                    throw new Error(result.body.message);
                }
                setOperationId(result.body.id);
                resetSelection();
            } catch (e) {
                logger.error(e);
                dispatch(enqueueSnackbar(e.message, { variant: 'error' }));
            }
        },
        [dispatch, menuChangeToCopy, resetSelection, setOperationId]
    );

    const renderLocationPickerModal = React.useCallback(
        () => (
            <MenuPickerModal
                open={isCopyModalOpen}
                onClose={toggleIsCopyModalOpen}
                menus={menuSummaryList}
                onSubmit={handleBulkUpdate}
            />
        ),
        [handleBulkUpdate, isCopyModalOpen, menuSummaryList, toggleIsCopyModalOpen]
    );

    return {
        selectedCellToCopy,
        toggleIsCopyModalOpen: handleModalOpen,
        setMenuChangeToCopy,
        activeOperationId,
        renderLocationPickerModal
    };
}
