import React from 'react';

export function useSomethingNewPopupState(sessionKey: string, localStorageKey: string, maxCount = 3) {
    const getIsConfirmed = React.useCallback(
        () => 'true' === sessionStorage.getItem(sessionKey),
        [sessionKey]
    );
    const setIsConfirmed = (confirmed?: boolean) => {
        sessionStorage.setItem(sessionKey, `${confirmed ?? 'true'}`);
    };

    const getCountShown = React.useCallback(() => {
        const parsedNumber = parseInt(localStorage.getItem(localStorageKey));
        if (Number.isNaN(parsedNumber)) {
            return 0;
        }
        return parsedNumber;
    }, [localStorageKey]);
    const incrementCountShown = (doNotShowAgain?: boolean) => {
        const count = getCountShown();
        if (count < maxCount) {
            if (doNotShowAgain) {
                localStorage.setItem(localStorageKey, String(maxCount));
            } else {
                localStorage.setItem(localStorageKey, String(count + 1));
            }
        }
    };
    const shouldShow = React.useCallback(
        () => !getIsConfirmed() && getCountShown() < maxCount,
        [getCountShown, getIsConfirmed, maxCount]
    );
    return {
        shouldShow,
        setIsConfirmed,
        incrementCountShown
    };
}
