import * as React from 'react';
import {
    Box,
    Button,
    Grid,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    styled
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import clsx from 'clsx';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { getZonesArrayFromString, getZonesString } from '../helpers';
import { CreateTableZoneModal, EditTableZoneModal, ITableZoneForm } from '../modal/TableZoneModal';
import { ILocationZones } from '../models/LocationModel';

interface ILocationTablesAndZonesCardEditComponentProps {
    zones: ILocationZones;
    onClose: () => void;
    isLoading: boolean;
    onSubmit: (zones: ILocationZones) => void;
}

const PREFIX = 'LocationTablesAndZonesCardEditComponent';

const classes = {
    cell: `${PREFIX}-cell`,
    actionCell: `${PREFIX}-actionCell`
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`& .${classes.cell}`]: {
        border: 'none',
        padding: theme.spacing(1)
    },
    [`& .${classes.actionCell}`]: {
        width: '64px'
    }
}));

export const LocationTablesAndZonesCardEditComponent: React.FC<
    ILocationTablesAndZonesCardEditComponentProps
> = ({ zones, onClose, isLoading, onSubmit }) => {
    const [stateZones, setStateZones] = React.useState(zones);
    const [isAddModalOpen, setAddModalVisibility] = React.useState(false);
    const [editingZoneModalDetails, setEditingZoneModalDetails] = React.useState<ITableZoneForm>();
    const [menuAnchorElements, setMenuAnchorElements] = React.useState<Record<string, HTMLButtonElement>>({});

    const handleZoneAddition = React.useCallback(
        ({ zoneId, tables }: ITableZoneForm) => {
            setStateZones(oldValues => ({
                ...oldValues,
                [zoneId]: getZonesArrayFromString(tables)
            }));
            setAddModalVisibility(false);
        },
        [setAddModalVisibility, setStateZones]
    );

    const handleAddZoneClick = React.useCallback(() => {
        setAddModalVisibility(true);
    }, [setAddModalVisibility]);

    const handleAddTableZoneModalClose = React.useCallback(() => {
        setAddModalVisibility(false);
    }, [setAddModalVisibility]);

    const handleEditTableZoneModalClose = React.useCallback(() => {
        setEditingZoneModalDetails(undefined);
    }, [setEditingZoneModalDetails]);

    const handleZoneEdit = React.useCallback(
        ({ zoneId, tables }: ITableZoneForm) => {
            setStateZones(oldZones => {
                const newState = { ...oldZones };
                delete newState[editingZoneModalDetails.zoneId];
                newState[zoneId] = getZonesArrayFromString(tables);

                return newState;
            });
            setEditingZoneModalDetails(undefined);
        },
        [editingZoneModalDetails]
    );

    const makeEditMenuOpenHandler = React.useCallback(
        (zoneId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
            setMenuAnchorElements(oldElements => ({
                ...oldElements,
                [zoneId]: event.currentTarget
            }));
        },
        [setMenuAnchorElements]
    );

    const removeMenuAnchorElement = React.useCallback(
        (zoneId: string) => {
            setMenuAnchorElements(oldElements => ({ ...oldElements, [zoneId]: null }));
        },
        [setMenuAnchorElements]
    );

    const makeEditZoneClickHandler = React.useCallback(
        (zoneId: string) => () => {
            removeMenuAnchorElement(zoneId);
            setEditingZoneModalDetails({
                zoneId,
                tables: getZonesString(stateZones[zoneId], false)
            });
        },
        [stateZones, setEditingZoneModalDetails, removeMenuAnchorElement]
    );

    const makeEditMenuCloseHandler = React.useCallback(
        (zoneId: string) => () => {
            removeMenuAnchorElement(zoneId);
        },
        [removeMenuAnchorElement]
    );

    const makeZoneDeleteHandler = React.useCallback(
        (key: string) => () => {
            removeMenuAnchorElement(key);
            setStateZones(oldZones => {
                const newState = { ...oldZones };
                delete newState[key];

                return newState;
            });
        },
        [setStateZones, removeMenuAnchorElement]
    );

    const handleSubmit = React.useCallback(() => {
        onSubmit(stateZones);
    }, [onSubmit, stateZones]);

    const renderZoneRow = React.useCallback(
        (zoneKey: string) => {
            const editClickHandler = makeEditZoneClickHandler(zoneKey);
            const deleteClickHandler = makeZoneDeleteHandler(zoneKey);
            const editMenuOpenHandler = makeEditMenuOpenHandler(zoneKey);
            const editMenuCloseHandler = makeEditMenuCloseHandler(zoneKey);

            return (
                <StyledTableRow key={zoneKey}>
                    <TableCell className={classes.cell}>
                        <TableCell className={classes.cell}>{zoneKey}</TableCell>
                        {getZonesString(stateZones[zoneKey])}
                    </TableCell>
                    <TableCell className={clsx(classes.cell, classes.actionCell)}>
                        <Button
                            aria-haspopup="true"
                            aria-controls={`edit-menu-${zoneKey}`}
                            onClick={editMenuOpenHandler}
                        >
                            <MoreVertIcon />
                        </Button>
                        <Menu
                            open={!!menuAnchorElements[zoneKey]}
                            id={`edit-menu-${zoneKey}`}
                            keepMounted={false}
                            anchorEl={menuAnchorElements[zoneKey]}
                            onClose={editMenuCloseHandler}
                        >
                            <MenuItem onClick={editClickHandler}>Edit</MenuItem>
                            <MenuItem onClick={deleteClickHandler}>Delete</MenuItem>
                        </Menu>
                    </TableCell>
                </StyledTableRow>
            );
        },
        [
            makeEditZoneClickHandler,
            makeZoneDeleteHandler,
            makeEditMenuOpenHandler,
            makeEditMenuCloseHandler,
            stateZones,
            menuAnchorElements
        ]
    );

    const zoneKeys = React.useMemo(() => Object.keys(stateZones ?? {}), [stateZones]);

    return (
        <>
            <Grid container>
                {!!zoneKeys.length && (
                    <Grid xs={12}>
                        <Table>
                            <TableHead>
                                <StyledTableRow>
                                    <TableCell className={classes.cell}>
                                        <b>Zone</b>
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        <b>Table Number(s)</b>
                                    </TableCell>
                                    <TableCell className={classes.cell} />
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>{zoneKeys.map(renderZoneRow)}</TableBody>
                        </Table>
                    </Grid>
                )}
                <Box width="100%" display="flex" marginTop={2}>
                    <Button variant="outlined" onClick={handleAddZoneClick}>
                        <AddIcon />
                    </Button>
                </Box>
                <Box width="100%" marginTop={2} display="flex" justifyContent="flex-end" alignItems="center">
                    <Box marginRight={2}>
                        <Button variant="outlined" onClick={onClose}>
                            Cancel
                        </Button>
                    </Box>

                    <LoadingButton
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                    >
                        Submit
                    </LoadingButton>
                </Box>
            </Grid>
            {isAddModalOpen && (
                <CreateTableZoneModal
                    onClose={handleAddTableZoneModalClose}
                    open={isAddModalOpen}
                    onAddition={handleZoneAddition}
                    title="Create a new zone"
                />
            )}
            {!!editingZoneModalDetails && (
                <EditTableZoneModal
                    initialValues={editingZoneModalDetails}
                    onEdit={handleZoneEdit}
                    open={!!editingZoneModalDetails}
                    onClose={handleEditTableZoneModalClose}
                />
            )}
        </>
    );
};
