import React from 'react';
import { Chip, Divider, Grid, styled, Typography } from '@mui/material';
import { getCurrencyString } from 'lib/helpers';
import { useToggle } from 'lib/hooks/useToggle';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { MuiModal } from 'ui/MuiModal';
import { ActionComponentProps, Panel } from 'ui/Panel';
import { CardViewSwitchRow, CardViewTextRow } from 'ui/viewComponents';
import { Customer } from '../models/Customer';
import { CustomerCreditForm } from './CustomerCreditForm';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { Resource, Permission } from '@pepperhq/auth-client';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { cardsApi } from '../cardsApi';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_CUSTOMER_CARD_DELETE_SUCCESS, MESSAGE_CUSTOMER_CARD_DELETE_ERROR } from 'config/messages';

const StyledMuiModal = styled(MuiModal)(({ theme }) => ({
    width: theme.spacing(75),
    minWidth: theme.spacing(56),
    maxWidth: theme.spacing(85)
}));

interface CustomersFinancialCardProps {
    customer: Customer;
    currency: string;
    currencySymbol: string;
    paymentMode: string;
    onChange: (data: Partial<Customer>) => void;
}

const actionRender = (props: ActionComponentProps) => (
    <LoadingButton variant="outlined" color="primary" {...props}>
        Credit Balance
    </LoadingButton>
);

export const CustomersFinancialCard: React.FC<CustomersFinancialCardProps> = props => {
    const { claims } = useSelector((state: ApplicationState) => state.auth);
    const { customer, currency, paymentMode, currencySymbol, onChange } = props;
    const dispatch = useDispatch();
    const [balance, setBalance] = React.useState(customer.balance || 0);
    const [isCreditOpen, toggleCredit] = useToggle(false);
    const [isDeleteCardOpen, toggleDeleteCard] = useToggle(false);
    const onCreditSuccess = React.useCallback((value: number) => {
        setBalance(currentBalance => currentBalance + value);
    }, []);
    const handleDeleteCard = React.useCallback(() => {
        if (customer.card) {
            cardsApi
                .deleteCard(customer.card._id)
                .then(() => {
                    toggleDeleteCard();
                    onChange({ card: null });
                    dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_CARD_DELETE_SUCCESS, { variant: 'success' }));
                })
                .catch(() => {
                    dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_CARD_DELETE_ERROR, { variant: 'error' }));
                });
        }
    }, [customer.card, dispatch, onChange, toggleDeleteCard]);

    return (
        <React.Fragment>
            <Panel
                title="Financial"
                actionComponentRender={actionRender}
                divider
                onClick={paymentMode !== 'PAYG' && toggleCredit}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center">
                            {customer.card ? (
                                <React.Fragment>
                                    <Grid item xs={6}>
                                        <CardViewTextRow
                                            title="Type"
                                            value={customer.card && customer.card.type}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CardViewSwitchRow
                                            title="Auto Top-Up"
                                            value={
                                                customer.card &&
                                                customer.card.autoTopUp &&
                                                customer.card.autoTopUp.enabled
                                            }
                                            vertical
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CardViewTextRow
                                            title="Card Number"
                                            value={
                                                customer.card ? `**** **** **** ${customer.card.last4}` : ''
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CardViewTextRow
                                            title="Card Expiry"
                                            value={customer.card && customer.card.expires}
                                        />
                                    </Grid>
                                    {claims.hasPermission(Resource.Customer, Permission.delete) && (
                                        <Grid item xs={12}>
                                            <LoadingButton
                                                sx={{ mb: 1 }}
                                                fullWidth
                                                color="primary"
                                                variant="contained"
                                                onClick={toggleDeleteCard}
                                            >
                                                Delete card
                                            </LoadingButton>
                                        </Grid>
                                    )}
                                </React.Fragment>
                            ) : (
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="textSecondary">
                                        This customer doesn&apos;t have any payment information
                                    </Typography>
                                </Grid>
                            )}
                            {paymentMode !== 'PAYG' && (
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            <b>Users Balance</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Chip
                                            color="primary"
                                            label={`${getCurrencyString(balance, currency)}`}
                                        />
                                    </Grid>
                                </React.Fragment>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Panel>
            <StyledMuiModal open={isCreditOpen} onClose={toggleCredit}>
                <CustomerCreditForm
                    customer={customer}
                    onClose={toggleCredit}
                    onCreditSuccess={onCreditSuccess}
                    currencySymbol={currencySymbol}
                />
            </StyledMuiModal>
            {claims.hasPermission(Resource.Customer, Permission.delete) && (
                <DeleteDialog
                    open={isDeleteCardOpen}
                    title={`Delete ${customer.card ? `**** **** **** ${customer.card.last4}` : ''}`}
                    content="If you delete the customer card, you won't be able to recover it."
                    onClose={toggleDeleteCard}
                    onDelete={handleDeleteCard}
                />
            )}
        </React.Fragment>
    );
};
