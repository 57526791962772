export type TypeGuard<T> = (v: any) => v is T;

export function isString(s: any): s is string {
    return typeof s === 'string';
}

export function isNumber(n: any): n is number {
    return typeof n === 'number';
}

export function isValidNumber(v: any): v is number {
    return isNumber(v) && !Number.isNaN(v);
}

export function isBoolean(b: any): b is boolean {
    return typeof b === 'boolean';
}

export function isBooleanString(b: unknown): b is 'true' | 'false' {
    return b === 'true' || b === 'false';
}

export function isOptional<T>(typeGuard: TypeGuard<T>, x: any): x is T | undefined {
    return typeof x === 'undefined' || typeGuard(x);
}

export function isOptionalCurried<T>(typeGuard: TypeGuard<T>): (x: any) => x is T | undefined {
    return (x: any): x is T | undefined => isOptional(typeGuard, x);
}

export function isOptionalString(s: any): s is string | undefined {
    return isOptional(isString, s);
}

export function isOptionalOrNull<T>(typeGuard: TypeGuard<T>, x: any): x is T | undefined {
    return x === null || typeof x === 'undefined' || typeGuard(x);
}

export function isDefined<T>(x: T | undefined): x is T {
    return x !== undefined;
}

export function isNotNull<T>(x: T | undefined): x is T {
    // eslint-disable-next-line eqeqeq
    return x != null;
}

export function isUndefined<T>(x: T | undefined): x is T {
    return x === undefined;
}

export function isEmptyString(x: any): x is '' {
    return x === '';
}

export function isNonEmptyString(s: any): s is string {
    return isString(s) && s.length !== 0;
}

export function isArray(a: any): a is any[] {
    return Array.isArray(a);
}

export function isArrayOfCurried<T>(typeGuard: TypeGuard<T>): (a: any) => a is T[] {
    return (a: any): a is T[] => a && a instanceof Array && a.every(typeGuard);
}

export function isArrayOf<T>(typeGuard: TypeGuard<T>, a: any): a is T[] {
    return a && a instanceof Array && a.every(typeGuard);
}

export function isOptionalArrayOf<T>(typeguard: TypeGuard<T>, a: any): a is T[] | undefined {
    return typeof a === 'undefined' || isArrayOf<T>(typeguard, a);
}

export function isEnumOfType<T>(enumType: T, obj: any): obj is T {
    if (obj === undefined) {
        return false;
    }
    for (const key in enumType) {
        // an enum with integer values will have the number values as keys as well - so omit anything castable to a number
        if (isNaN(+key)) {
            if (obj === enumType[key]) {
                return true;
            }
        }
    }
    return false;
}

export function isEnumOfTypeCurried<T>(enumType: T): TypeGuard<T> {
    return (obj: any): obj is T => isEnumOfType(enumType, obj);
}

export function isDate(s: any): s is Date {
    return Object.prototype.toString.call(s) === '[object Date]';
}

export function isValidDate(s: any): s is Date {
    return isDate(s) && !Number.isNaN(s.valueOf());
}

export function isDateString(s: any): s is string {
    return isString(s) && !isNaN(Date.parse(s));
}

export function Or<T1, T2>(
    isT1: (t1: any) => t1 is T1,
    isT2: (t2: any) => t2 is T2
): (x: any) => x is T1 | T2 {
    return (x: any): x is T1 | T2 => isT1(x) || isT2(x);
}

export type HasRequiredProperty<T, P extends keyof T> = T & Required<Pick<T, P>>;

// eslint-disable-next-line @typescript-eslint/ban-types
export function HasRequiredProperty<T extends object, P extends keyof T>(
    obj: T,
    propertyName: P
): obj is HasRequiredProperty<T, P> {
    return isDefined(obj[propertyName]);
}

export type Optional<T, K extends keyof T> = Partial<T> & Omit<T, K>;
