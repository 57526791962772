import { Popper, styled, Typography } from '@mui/material';
import { GridCell, GridCellProps } from '@mui/x-data-grid-pro';
import React from 'react';

const StyledOverlay = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 0.5),
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    border: `${theme.palette.error.light}`,
    borderStyle: 'solid',
    borderWidth: '2px',
    backgroundColor: theme.palette.error.light,
    color: theme.palette.background.paper
}));

export const CellSlot: React.FC<GridCellProps & { errors?: Record<string, Record<string, string>> }> = ({
    errors,
    ...props
}) => {
    const anchorEl = React.useRef<HTMLDivElement>(null);
    return (
        <>
            <GridCell ref={anchorEl} {...props} />
            {errors && !!errors[props.rowId] && errors[props.rowId][props.column.field] && (
                <Popper open anchorEl={anchorEl?.current} placement="bottom-end">
                    <StyledOverlay>
                        <Typography variant="caption">{errors[props.rowId][props.column.field]}</Typography>
                    </StyledOverlay>
                </Popper>
            )}
        </>
    );
};
