/* eslint-disable max-len */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { Option } from 'lib/types';
import { MuiModal } from 'ui/MuiModal';
import { getAllReportColumns } from './model';
import { getAllLocations } from 'store/locations/locationsActions';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import logger from 'lib/logger';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { pepperPayApi } from '../pepperPayApi';
import { PepperPayScheduleOperation } from '../model/PepperPay';
import { IAllPublicSettings } from '@pepperhq/location-sdk';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { GeneralReportForm } from './GeneralReportForm';
import { createTriggerOptions, getOperationTypeAndFormat } from './utils';
import { EReportFormat, EReportType, EScheduleReportInterval } from './enums';
import { intervalOption, reportCreationValues, weeklyOptions } from './consts';
import { MESSAGE_SCHEDULE_ERROR, MESSAGE_SCHEDULE_SUCCESS } from 'config/messages';
import { IStripeAccount } from './NewReportModal';

interface NewReportModalProps {
    open: boolean;
    lastOperation?: PepperPayScheduleOperation | null;
    onClose: () => void;
    onCreate: (operation: PepperPayScheduleOperation) => void;
}

export interface CreateScheduleReportForm {
    appId: string;
    type: EReportType;
    format: EReportFormat;
    columns: Map<string, boolean>;
    name: string;
    notificationEmail: string;
    interval: string;
    intervalTrigger: string;
}

export const NewScheduleModal: React.FC<NewReportModalProps> = props => {
    const { open, onClose, onCreate } = props;
    const dispatch = useDispatch();
    const { locations } = useSelector((state: ApplicationState) => state.locations);
    const [lastOperation, setLastOperation] = React.useState<PepperPayScheduleOperation | null>(null);
    const [appIds, setAppIds] = React.useState<Map<string, string>>(new Map());
    const [stripeAccounts, setStripeAccounts] = React.useState<Map<string, IStripeAccount>>(new Map());

    const onSubmit = React.useCallback(
        async (values: CreateScheduleReportForm) => {
            const { type, format, columns, appId, name, notificationEmail, interval, intervalTrigger } =
                values;
            const reportType = reportCreationValues[type][format].name;
            const locationId = appIds.get(appId);
            if (reportType) {
                const { body, ok } = await pepperPayApi.schedules.createSchedule({
                    reportType,
                    columns: Array.from(columns.keys()),
                    appId,
                    locationId,
                    name,
                    notificationEmail,
                    interval,
                    intervalTrigger: Number(intervalTrigger)
                });
                if (ok) {
                    const report = body;
                    dispatch(enqueueSnackbar(MESSAGE_SCHEDULE_SUCCESS, { variant: 'success' }));
                    onCreate(report);
                    onClose();
                } else {
                    dispatch(enqueueSnackbar(MESSAGE_SCHEDULE_ERROR, { variant: 'error' }));
                }

                return body;
            }
        },
        [appIds, dispatch, onClose, onCreate]
    );

    const formik = useFormik<CreateScheduleReportForm>({
        onSubmit,
        validateOnMount: false,
        validationSchema: yup.object().shape({
            appId: yup.string().required('You must select connected account id.'),
            type: yup.string().oneOf(Object.values(EReportType)).required('Please, select report type.'),
            format: yup
                .string()
                .oneOf(Object.values(EReportFormat))
                .required('Please, select report format.'),
            name: yup.string().required('This filed is required'),
            notificationEmail: yup.string().required('This filed is required'),
            interval: yup.string().required('This filed is required'),
            intervalTrigger: yup.string().when('interval', {
                is: (val: string) => val === 'MONTHLY' || val === 'WEEKLY',
                then: yup.string().required('This filed is required')
            })
        }),
        initialValues: {
            appId: '',
            type: EReportType.ACTIVITY,
            format: EReportFormat.SUMMARY,
            columns: new Map(),
            name: '',
            notificationEmail: '',
            interval: '',
            intervalTrigger: ''
        }
    });

    const {
        values: { type, format, interval },
        handleSubmit,
        setFieldValue,
        setValues,
        validateForm,
        isSubmitting
    } = formik;

    const appIdOptions: Option[] = React.useMemo(
        () =>
            Array.from(appIds.keys()).map(appId => {
                const account = stripeAccounts.get(appId);

                return {
                    label: account?.name ?? appId,
                    value: appId
                };
            }),
        [appIds, stripeAccounts]
    );

    React.useEffect(() => {
        if (props.lastOperation && lastOperation?._id !== props.lastOperation?._id) {
            setLastOperation(props.lastOperation);
        }
    }, [lastOperation, props.lastOperation]);

    React.useEffect(() => {
        (async () => {
            if (locations.length === 0) {
                getAllLocations()(dispatch);
                return;
            }
            const locIds = locations.map(loc => loc._id);

            try {
                // We split all the locationIds into chunks due to db limitations. IN operator cannot process more than 100 items
                const numberOfChunks = Math.ceil(locIds.length / 100);
                const locationIdChunks: string[][] = [];
                for (let i = 0; i < numberOfChunks; i++) {
                    locationIdChunks.push(locIds.slice(i * 100, (i + 1) * 100));
                }
                let appIds: {
                    locationId: string;
                    settings: DeepPartial<IAllPublicSettings>;
                }[] = [];
                const requests = locationIdChunks.map(locIdChunk =>
                    new LocationSettingsApi().getAllPublicSettings(null, locIdChunk, true, 'stripe')
                );
                const allLocationsSettingsResults = await Promise.all(requests);
                allLocationsSettingsResults.forEach(item => {
                    appIds = [...appIds, ...item];
                });
                // And we need to add the accountId for the common/global stripe account
                const commonId = await new LocationSettingsApi().getPublicSettings(
                    null,
                    undefined,
                    true,
                    'stripe'
                );
                appIds.push({ locationId: 'common', settings: commonId });
                const uniqueConnectIds = new Map<string, string>();
                appIds.forEach(({ settings: { stripe }, locationId }) => {
                    if (stripe?.accountId) {
                        uniqueConnectIds.set(stripe?.accountId, locationId);
                    }
                });
                const accountIds = Array.from(uniqueConnectIds.keys());
                const { body: accounts } = await pepperPayApi.accounts.getAccounts(accountIds);

                setAppIds(uniqueConnectIds);
                setStripeAccounts(new Map(Object.entries(accounts)));
            } catch (err) {
                logger.error(err);
            }
        })();
    }, [dispatch, locations]);

    React.useEffect(() => {
        const { type: lastOperationType, format: lastOperationFormat } = getOperationTypeAndFormat(
            lastOperation?.reportData.report_type
        );
        if (type && format && lastOperation) {
            if (type === lastOperationType && format === lastOperationFormat) {
                setFieldValue(
                    'columns',
                    new Map(lastOperation?.reportData.parameters.columns.map(key => [key, true])),
                    true
                );
            } else {
                const values: [string, boolean][] = getAllReportColumns(type, format).map(key => [key, true]);
                const columnValues = new Map<string, boolean>(values);
                setFieldValue('columns', columnValues, true);
            }
        }
    }, [format, lastOperation, setFieldValue, type, validateForm]);

    React.useEffect(() => {
        if (lastOperation) {
            const { interval, intervalTrigger } = lastOperation;
            const { type: lastOperationType, format: lastOperationFormat } = getOperationTypeAndFormat(
                lastOperation?.reportData.report_type
            );
            const appId = lastOperation?.reportData.parameters.connected_account;
            setValues(
                {
                    type: lastOperationType,
                    format: lastOperationFormat,
                    appId,
                    columns: new Map(lastOperation?.reportData.parameters.columns.map(key => [key, true])),
                    name: '',
                    notificationEmail: '',
                    interval,
                    intervalTrigger: String(intervalTrigger)
                },
                true
            );
        }
    }, [lastOperation, setValues]);

    const getOption = React.useCallback(value => {
        if (value === EScheduleReportInterval.WEEKLY) {
            return weeklyOptions;
        }
        if (value === EScheduleReportInterval.MONTHLY) {
            return createTriggerOptions(31);
        }
        return [{ label: '', value: '' }];
    }, []);

    const isIntervalNotDaily = React.useMemo(
        () => interval && interval !== EScheduleReportInterval.DAILY,
        [interval]
    );

    const getIntervalTriggerTooltipInfo = React.useCallback((interval: string) => {
        if (interval === EScheduleReportInterval.MONTHLY) {
            return 'Select the day the report should run on. If the day number selected for the report to run on, exceeds the number of days in the month. The report will run on the last day of the month. For example, if the report was selected to run on day 31 of the month. In February, where there are 28 days it would run on the last day of the month as there is no day 31.';
        }
        if (interval === EScheduleReportInterval.WEEKLY) {
            return 'Select the day the report should run on.';
        }
    }, []);

    return (
        <MuiModal open={open} onClose={onClose}>
            <Box>
                <Typography variant="h6" color="primary">
                    Create a Report
                </Typography>
                {appIdOptions.length > 0 && (
                    <FormikProvider value={formik}>
                        <form onSubmit={handleSubmit}>
                            <Box sx={{ width: 250 }}>
                                <SelectFormField
                                    emptyLabel="Connect Account"
                                    name="appId"
                                    options={appIdOptions}
                                    displayEmpty
                                />
                            </Box>
                            <Box marginBottom={2}>
                                <GeneralReportForm formik={formik} />
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={6} display="flex">
                                    <TextFormField name="name" label="Report Name" />
                                    <Tooltip title="Provide a name for the scheduled report.">
                                        <InfoOutlinedIcon />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6} display="flex">
                                    <TextFormField name="notificationEmail" label="Email" />
                                    <Tooltip title="Provide an email to be notified for the creation of the report.">
                                        <InfoOutlinedIcon />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6} display="flex">
                                    <SelectFormField
                                        label="Frequency"
                                        displayEmpty
                                        name="interval"
                                        options={intervalOption}
                                    />
                                    <Tooltip title="Select how regularly the report should run. The reports will be generated at 00:05 UTC.">
                                        <InfoOutlinedIcon />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6} display="flex">
                                    {isIntervalNotDaily && (
                                        <>
                                            <SelectFormField
                                                label="Interval"
                                                displayEmpty
                                                name="intervalTrigger"
                                                options={getOption(interval)}
                                            />
                                            <Tooltip title={getIntervalTriggerTooltipInfo(interval)}>
                                                <InfoOutlinedIcon />
                                            </Tooltip>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Box display="flex" justifyContent="flex-end" paddingTop={2}>
                                <Box paddingRight={1}>
                                    <Button onClick={onClose}>Cancel</Button>
                                </Box>
                                <LoadingButton
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    Create
                                </LoadingButton>
                            </Box>
                        </form>
                    </FormikProvider>
                )}
            </Box>
        </MuiModal>
    );
};
