import { metricsApi } from 'components/metrics/metricsApi';
import {
    PremiumLoyaltyAudience,
    PremiumLoyaltyConditionBody,
    UserMetricDefinition
} from 'components/metrics/model';
import {
    MESSAGE_PREMIUM_LOYALTY_AUDIENCE_CONDITION_CREATE_ERROR,
    MESSAGE_PREMIUM_LOYALTY_AUDIENCE_CONDITION_CREATE_SUCCESS,
    MESSAGE_PREMIUM_LOYALTY_AUDIENCE_CONDITION_DELETE_ERROR,
    MESSAGE_PREMIUM_LOYALTY_AUDIENCE_CONDITION_DELETE_SUCCESS,
    MESSAGE_PREMIUM_LOYALTY_AUDIENCE_LISTENER_CREATE_ERROR,
    MESSAGE_PREMIUM_LOYALTY_AUDIENCE_LISTENER_CREATE_SUCCESS
} from 'config/messages';
import logger from 'lib/logger';
import React from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';

export function usePremiumLoyaltyAudienceRequests(
    onConditionAdd: (data: UserMetricDefinition, audienceId?: string) => void,
    metricDefinition?: UserMetricDefinition,
    tier?: PremiumLoyaltyAudience,
    audienceId?: string
) {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const [requestParams, setRequestParams] = React.useState<{ listenerId: string; conditionId: string }>();
    const createNewCondition = React.useCallback(
        async (body: PremiumLoyaltyConditionBody, type: 'ADD_TO_SEGMENT' | 'REMOVE_FROM_SEGMENT') => {
            const listenersList = type === 'ADD_TO_SEGMENT' ? tier?.addListeners : tier?.removeListeners;
            if (!audienceId) {
                dispatch(
                    enqueueSnackbar(MESSAGE_PREMIUM_LOYALTY_AUDIENCE_LISTENER_CREATE_ERROR, {
                        variant: 'error'
                    })
                );
                return;
            }
            setLoading(true);
            if (listenersList?.length) {
                const result = await metricsApi.addEffectCondition(
                    metricDefinition?._id,
                    listenersList[0]?._id,
                    body
                );
                setLoading(false);
                if (!result.ok) {
                    dispatch(
                        enqueueSnackbar(MESSAGE_PREMIUM_LOYALTY_AUDIENCE_CONDITION_CREATE_ERROR, {
                            variant: 'error'
                        })
                    );
                    return logger.error(result.body.message, result.body);
                }
                dispatch(
                    enqueueSnackbar(MESSAGE_PREMIUM_LOYALTY_AUDIENCE_CONDITION_CREATE_SUCCESS, {
                        variant: 'success'
                    })
                );
                onConditionAdd(result.body);
            } else {
                const result = await metricsApi.addEffect(metricDefinition?._id, {
                    type,
                    conditions: [body],
                    segmentId: audienceId
                });
                setLoading(false);
                if (!result.ok) {
                    dispatch(
                        enqueueSnackbar(MESSAGE_PREMIUM_LOYALTY_AUDIENCE_LISTENER_CREATE_ERROR, {
                            variant: 'error'
                        })
                    );
                    return logger.error(result.body.message, result.body);
                }
                dispatch(
                    enqueueSnackbar(MESSAGE_PREMIUM_LOYALTY_AUDIENCE_LISTENER_CREATE_SUCCESS, {
                        variant: 'success'
                    })
                );
                onConditionAdd(result.body, audienceId);
            }
        },
        [
            audienceId,
            dispatch,
            metricDefinition?._id,
            onConditionAdd,
            tier?.addListeners,
            tier?.removeListeners
        ]
    );
    const handleSubmitToAdd = React.useCallback(
        (body: PremiumLoyaltyConditionBody) => {
            createNewCondition(body, 'ADD_TO_SEGMENT');
        },
        [createNewCondition]
    );
    const handleSubmitToRemove = React.useCallback(
        (body: PremiumLoyaltyConditionBody) => {
            createNewCondition(body, 'REMOVE_FROM_SEGMENT');
        },
        [createNewCondition]
    );
    const deleteCondition = React.useCallback(
        async (listenerId: string, conditionId: string) => {
            setLoading(true);
            const result = await metricsApi.deleteEffectCondition(
                metricDefinition?._id,
                listenerId,
                conditionId
            );
            setLoading(false);
            if (!result.ok) {
                dispatch(
                    enqueueSnackbar(MESSAGE_PREMIUM_LOYALTY_AUDIENCE_CONDITION_DELETE_ERROR, {
                        variant: 'error'
                    })
                );
                return logger.error(result.body.message, result.body);
            }
            onConditionAdd(result.body);
            dispatch(
                enqueueSnackbar(MESSAGE_PREMIUM_LOYALTY_AUDIENCE_CONDITION_DELETE_SUCCESS, {
                    variant: 'success'
                })
            );
        },
        [dispatch, metricDefinition?._id, onConditionAdd]
    );
    const handleDeleteModalClose = React.useCallback(() => {
        setDeleteModalOpen(false);
        setRequestParams(undefined);
    }, []);
    const handleDelete = React.useCallback(
        (listenerId: string) => (conditionId: string) => {
            if (!metricDefinition?.realtime && !metricDefinition?.scheduled) {
                return deleteCondition(listenerId, conditionId);
            }
            setRequestParams({ listenerId, conditionId });
            setDeleteModalOpen(true);
        },
        [deleteCondition, metricDefinition?.realtime, metricDefinition?.scheduled]
    );
    const handleDeleteModalConfirm = React.useCallback(() => {
        if (requestParams && requestParams.conditionId && requestParams.listenerId) {
            deleteCondition(requestParams.listenerId, requestParams.conditionId);
        } else {
            dispatch(
                enqueueSnackbar(MESSAGE_PREMIUM_LOYALTY_AUDIENCE_CONDITION_DELETE_ERROR, { variant: 'error' })
            );
        }
        return handleDeleteModalClose();
    }, [deleteCondition, dispatch, handleDeleteModalClose, requestParams]);
    return {
        loading,
        handleDelete,
        handleSubmitToAdd,
        handleSubmitToRemove,
        deleteModalOpen,
        handleDeleteModalClose,
        handleDeleteModalConfirm
    };
}
