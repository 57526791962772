import { Box } from '@mui/material';
import { GridColDef, GridRowId, GridValidRowModel } from '@mui/x-data-grid-pro';
import {
    DayCode,
    isDayCode,
    MenuChangeSchema,
    TimePeriod,
    TimesByDay,
    TimesByDayByZone
} from '@pepperhq/menu-sdk';
import { menuApi } from 'components/menu/MenuApi';
import { SelectTitleColumn, TimeRangeGridColumn } from 'lib/MuiGrid/Columns';
import { EMuiGridStatus, GridRowValidationParams, MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React from 'react';
import {
    BaseMenuTab,
    getCategoryGroupIdToTitle,
    getWeeklyAvailabilityToCopy,
    getWeeklyAvailabilityData,
    getWeeklyAvailabilityId,
    getWeeklyAvailabilityParts,
    useMenuChange,
    WeeklyAvailability
} from '../model/menu';
import { useLeavePageBlock } from 'lib/hooks/useLeavePageBlock';
import { useDispatch } from 'react-redux';
import logger from 'lib/logger';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_WEEKLY_AVAILABILITY_UPDATE_ERROR,
    MESSAGE_WEEKLY_AVAILABILITY_UPDATE_SUCCESS
} from 'config/messages';
import { isString } from '@pepperhq/menu-sdk/dist/libs/typeUtils';
import { isDefined, isUndefined } from 'lib/typeguards';
import merge from 'deepmerge';
import { useMenuGridSearch } from '../model/useMenuGridSearch';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { useTabCopy } from '../useCopyTab';
import { ActionsHeader } from '../ActionsHeader';
import { useMenuSelection } from '../useMenuSelection';
import { useMenuDeleteControls } from '../model/useMenuDeleteControls';
const overwriteMerge = (_destinationArray: never, sourceArray: Array<any>, _: never) => sourceArray;

type WeeklyAvailabilityProps = BaseMenuTab;

const groupDescription =
    'You must keep the category field blank if a category group is selected. It is not possible to select a category group and a category together.';
const categoryDescription =
    'You must keep the category group field blank if a category is selected. It is not possible to select a category group and a category together.';
const zoneDescription =
    // eslint-disable-next-line max-len
    'Set this to a zone that this category group or category should appear within the zone menu. If it is blank, it will be visible in all zone menus.';
const timeDescription =
    // eslint-disable-next-line max-len
    "Set the time periods when this category group or category will be visible on this day. If it is blank on this day and not on others, it won't be visible on this day.";

interface WeeklyAvailabilityUpdate {
    availableTimesByDay?: TimesByDay;
    availableTimesByDayByZone?: TimesByDayByZone;
}

type UndformatedDays = Record<string, [TimePeriod]>;

const isEqualIds = (a: WeeklyAvailability, b: WeeklyAvailability) =>
    getWeeklyAvailabilityId(a.categoryGroupId, a.categoryId, a.zone) ===
    getWeeklyAvailabilityId(b.categoryGroupId, b.categoryId, b.zone);

const generateFieldsToValidate = (id?: GridRowId, field?: string, isCurrentId?: boolean) => {
    const fieldsArray = [
        DayCode.MON,
        DayCode.TUE,
        DayCode.WED,
        DayCode.THU,
        DayCode.FRI,
        DayCode.SAT,
        DayCode.SUN,
        'categoryId',
        'categoryGroupId',
        'zone'
    ];
    if (isUndefined(id)) {
        return undefined;
    }
    if (!isCurrentId) {
        if (isUndefined(id)) {
            return fieldsArray.reduce<Record<string, boolean>>((acc, item) => {
                acc[item] = false;
                return acc;
            }, {});
        }
    }
    return fieldsArray.reduce<Record<string, boolean>>((acc, item) => {
        acc[item] = item === field;
        return acc;
    }, {});
};

const validateItem = (
    item: WeeklyAvailability,
    rows: WeeklyAvailability[],
    params?: GridRowValidationParams
) => {
    const errors: Record<string, string> = {};
    const isCurrentId = params?.id === item.id;
    const propsToValidate = generateFieldsToValidate(params?.id, params?.field, isCurrentId);
    if (
        isDefined(item.categoryId) &&
        item.categoryId !== '' &&
        isDefined(item.categoryGroupId) &&
        item.categoryGroupId !== ''
    ) {
        errors.categoryId = 'Only a category group or a category can be selected';
    }
    if (
        (isUndefined(item.categoryId) || item.categoryId === '') &&
        (isUndefined(item.categoryGroupId) || item.categoryGroupId === '')
    ) {
        errors.categoryId = 'You must select a category or a category group';
    }
    const daysOfWeek = Object.values(DayCode);
    if (daysOfWeek.every(day => !item[day])) {
        daysOfWeek.forEach(day => {
            errors[day] = 'Set at least one day';
        });
    }
    if (propsToValidate) {
        Object.entries(propsToValidate).forEach(([field, toValidate]) => {
            if (!toValidate) {
                delete errors[field];
            }
        });
    }
    if (Object.values(errors).length) {
        return errors;
    }
    if (rows.some(row => row.id !== item.id && isEqualIds(item, row))) {
        if (!propsToValidate) {
            return { zone: 'Duplicate', categoryId: 'Duplicate', categoryGroupId: 'Duplicate' };
        }
        if (propsToValidate?.zone) {
            errors.zone = 'Duplicate';
        }
        if (propsToValidate?.categoryId) {
            errors.categoryId = 'Duplicate';
        }
        if (propsToValidate?.categoryGroupId) {
            errors.categoryGroupId = 'Duplicate';
        }
        return Object.values(errors).length ? errors : undefined;
    }
    return undefined;
};

function prepareDays(days: UndformatedDays) {
    const preparedDays = Object.entries(days).reduce<TimesByDay>((acc, [dayCode, dayPeriod]) => {
        if (isDayCode(dayCode)) {
            if (!!dayPeriod?.length) {
                acc[dayCode] = dayPeriod;
            } else {
                acc[dayCode] = undefined;
            }
        }
        return acc;
    }, {});
    return Object.keys(preparedDays).length ? preparedDays : null;
}

export const WeeklyAvailabilityTab: React.FC<WeeklyAvailabilityProps> = ({
    menu,
    menuChangeId,
    loadFreshMenu,
    onUpdate,
    change: externalChange,
    search,
    onSearchChange
}) => {
    const dispatch = useDispatch();
    const {
        change: [change, setChange],
        readyToSave,
        handleCancel,
        handleStatusChange,
        gridRef
    } = useMenuChange(externalChange);
    const [temporaryChange, setTemporaryChange] = React.useState<Record<string, WeeklyAvailability>>({});

    const categoryIdToTitle = React.useMemo(() => {
        if (!menu || !menu.categories) {
            return {};
        }
        const isCategoryChagnesExist = change && change.categories;
        const data = menu.categories.reduce<Record<string, string>>((acc, item) => {
            acc[item.id] =
                isCategoryChagnesExist && isString(change.categories[item.id]?.title)
                    ? change.categories[item.id].title
                    : item.title;
            return acc;
        }, {});
        return data;
    }, [change, menu]);

    const categoryGroupIdToTitle = React.useMemo(() => {
        if (!menu) {
            return {};
        }
        return getCategoryGroupIdToTitle(menu, change);
    }, [change, menu]);

    const handleRefreshMenu = React.useCallback(() => {
        loadFreshMenu();
        gridRef.current.reset();
    }, [gridRef, loadFreshMenu]);

    const { selectedIds, resetSelection, selectedCell, handleSelectChange, copyEnabled, handleCellBlur } =
        useMenuSelection();

    const { toggleIsCopyModalOpen, setMenuChangeToCopy, activeOperationId, renderLocationPickerModal } =
        useTabCopy(resetSelection, selectedCell);

    const weeklyAvailability = React.useMemo(() => {
        if (!menu || !change) {
            return [];
        }
        return getWeeklyAvailabilityData(menu, change, temporaryChange);
    }, [change, menu, temporaryChange]);

    const {
        editedOrDeleted,
        handleCancelEditing: _handleCancelEditing,
        handleDelete,
        deleted,
        deleteEnabled,
        resetDeleted,
        isCellEditable
    } = useMenuDeleteControls(selectedIds, readyToSave, handleCancel, resetSelection);

    const handleCancelEditing = React.useCallback(() => {
        _handleCancelEditing();
        setTemporaryChange({});
    }, [_handleCancelEditing]);

    useLeavePageBlock(editedOrDeleted);
    const gridSearchOptions = React.useMemo(
        () => [
            { column: 'categoryId', labels: categoryIdToTitle },
            { column: 'categoryGroupId', labels: categoryGroupIdToTitle },
            { column: 'zone' }
        ],
        [categoryGroupIdToTitle, categoryIdToTitle]
    );
    const { filterModel, filterOperators, renderInput } = useMenuGridSearch(
        gridSearchOptions,
        search,
        onSearchChange,
        'weeklyAvailability',
        'Search by category group, category or zone'
    );

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'categoryGroupId',
                headerName: 'Category Group',
                editable: true,
                description: groupDescription,
                width: 200,
                filterOperators: [filterOperators.categoryGroupId],
                ...SelectTitleColumn(categoryGroupIdToTitle, true)
            },
            {
                field: 'categoryId',
                headerName: 'Category',
                editable: true,
                description: categoryDescription,
                width: 180,
                filterOperators: [filterOperators.categoryId],
                ...SelectTitleColumn(categoryIdToTitle, true)
            },
            {
                field: 'zone',
                headerName: 'Zone',
                editable: true,
                width: 180,
                description: zoneDescription
            },
            {
                field: DayCode.MON,
                headerName: 'Monday',
                editable: true,
                width: 160,
                description: timeDescription,
                ...TimeRangeGridColumn()
            },
            {
                field: DayCode.TUE,
                headerName: 'Tuesday',
                editable: true,
                width: 160,
                description: timeDescription,
                ...TimeRangeGridColumn()
            },
            {
                field: DayCode.WED,
                headerName: 'Wednesday',
                editable: true,
                width: 160,
                description: timeDescription,
                ...TimeRangeGridColumn()
            },
            {
                field: DayCode.THU,
                headerName: 'Thursday',
                editable: true,
                width: 160,
                description: timeDescription,
                ...TimeRangeGridColumn()
            },
            {
                field: DayCode.FRI,
                headerName: 'Friday',
                editable: true,
                width: 160,
                description: timeDescription,
                ...TimeRangeGridColumn()
            },
            {
                field: DayCode.SAT,
                headerName: 'Saturday',
                editable: true,
                width: 160,
                description: timeDescription,
                ...TimeRangeGridColumn()
            },
            {
                field: DayCode.SUN,
                headerName: 'Sunday',
                editable: true,
                width: 160,
                description: timeDescription,
                ...TimeRangeGridColumn()
            }
        ],
        [
            categoryGroupIdToTitle,
            categoryIdToTitle,
            filterOperators.categoryGroupId,
            filterOperators.categoryId
        ]
    );

    const handleCreate = React.useCallback(() => {
        const { changes } = gridRef.current?.getState() ?? {};
        let newId: string;
        let i = 1;
        while (!newId) {
            if (!temporaryChange[`${i}`]) {
                newId = `${i}`;
            } else {
                i++;
            }
        }
        setTemporaryChange(tempChange => {
            const updatedTemporaryChange = Object.entries(tempChange).reduce<
                Record<string, WeeklyAvailability>
            >((acc, [id, item]) => {
                acc[id] = { ...item, ...(changes[id] || {}) };
                return acc;
            }, {});
            return {
                ...updatedTemporaryChange,
                [newId]: {
                    id: newId,
                    categoryId: '',
                    categoryGroupId: '',
                    zone: '',
                    [DayCode.MON]: [],
                    [DayCode.TUE]: [],
                    [DayCode.WED]: [],
                    [DayCode.THU]: [],
                    [DayCode.FRI]: [],
                    [DayCode.SAT]: [],
                    [DayCode.SUN]: []
                }
            };
        });
        gridRef.current?.createItem(
            newId,
            undefined,
            {
                categoryId: '',
                categoryGroupId: '',
                zone: '',
                [DayCode.MON]: [],
                [DayCode.TUE]: [],
                [DayCode.WED]: [],
                [DayCode.THU]: [],
                [DayCode.FRI]: [],
                [DayCode.SAT]: [],
                [DayCode.SUN]: []
            },
            'categoryGroupId'
        );
        handleStatusChange(EMuiGridStatus.CHANGED);
    }, [gridRef, handleStatusChange, temporaryChange]);

    const handleSubmit = React.useCallback(async () => {
        try {
            if (!gridRef.current) {
                throw new Error(MESSAGE_WEEKLY_AVAILABILITY_UPDATE_ERROR);
            }
            const { changes } = gridRef.current.getState();

            const errors = gridRef.current.validate();
            if (errors) {
                return;
            }

            const updateChanges: Record<string, TimesByDay | null> = {};
            const addChanges: Record<string, TimesByDay | null> = {};

            const categories: Record<string, WeeklyAvailabilityUpdate> = {};
            const categoryGroups: Record<string, WeeklyAvailabilityUpdate> = {};

            const deletedSet = new Set([...deleted]);

            Object.entries(changes).forEach(([id, { _overriden, ...currentChange }]) => {
                const {
                    categoryGroupId,
                    categoryId,
                    zone,
                    id: _id,
                    ...days
                } = merge(weeklyAvailability.find(item => String(item.id) === id) || {}, currentChange, {
                    arrayMerge: overwriteMerge
                });
                const parts = getWeeklyAvailabilityParts(id);
                if (!!parts) {
                    if (deletedSet.has(id)) {
                        updateChanges[id] = null;
                        deletedSet.delete(id);
                    } else {
                        const newId = getWeeklyAvailabilityId(categoryGroupId, categoryId, zone);
                        if (id !== newId) {
                            if (isUndefined(updateChanges[id])) {
                                updateChanges[id] = null;
                            }
                        }
                        updateChanges[newId] = prepareDays(days);
                    }
                } else if (!deletedSet.has(id)) {
                    const newId = getWeeklyAvailabilityId(categoryGroupId, categoryId, zone);
                    addChanges[newId] = prepareDays(days);
                } else {
                    deletedSet.delete(id);
                }
            });
            deletedSet.forEach(id => {
                if (getWeeklyAvailabilityParts(String(id))) {
                    updateChanges[id] = null;
                }
            });
            const changesToMap = { ...updateChanges, ...addChanges };
            Object.entries(changesToMap).forEach(([id, days]) => {
                const parts = getWeeklyAvailabilityParts(id);
                if (!parts) {
                    return;
                }
                const { categoryId, categoryGroupId, zone } = parts;
                if (isDefined(categoryGroupId)) {
                    if (isDefined(zone)) {
                        categoryGroups[categoryGroupId] = {
                            ...(categoryGroups[categoryGroupId] || {}),
                            availableTimesByDayByZone: {
                                ...(categoryGroups[categoryGroupId]?.availableTimesByDayByZone || {}),
                                [zone]: days
                            }
                        };
                    } else {
                        categoryGroups[categoryGroupId] = {
                            ...(categoryGroups[categoryGroupId] || {}),
                            availableTimesByDay: days
                        };
                    }
                } else if (isDefined(categoryId)) {
                    if (isDefined(zone)) {
                        categories[categoryId] = {
                            ...(categories[categoryId] || {}),
                            availableTimesByDayByZone: {
                                ...(categories[categoryId]?.availableTimesByDayByZone || {}),
                                [zone]: days
                            }
                        };
                    } else {
                        categories[categoryId] = {
                            ...(categories[categoryId] || {}),
                            availableTimesByDay: days
                        };
                    }
                }
            });
            Object.entries(categoryGroups).forEach(([id, group]) => {
                categoryGroups[id] = merge(
                    {
                        availableTimesByDay:
                            (externalChange?.categoryGroups &&
                                externalChange.categoryGroups[id]?.availableTimesByDay) ||
                            null,
                        availableTimesByDayByZone:
                            (externalChange?.categoryGroups &&
                                externalChange.categoryGroups[id]?.availableTimesByDayByZone) ||
                            null
                    },
                    group,
                    { arrayMerge: overwriteMerge }
                );
                const zones = Object.entries(
                    categoryGroups[id]?.availableTimesByDayByZone || {}
                ).reduce<TimesByDayByZone>((acc, [zoneName, zoneDays]) => {
                    if (zoneDays) {
                        acc[zoneName] = zoneDays;
                    }
                    return acc;
                }, {});
                categoryGroups[id].availableTimesByDayByZone = Object.values(zones).length ? zones : null;
            });
            Object.entries(categories).forEach(([id, group]) => {
                categories[id] = merge(
                    {
                        availableTimesByDay:
                            (externalChange?.categories &&
                                externalChange.categories[id]?.availableTimesByDay) ||
                            null,
                        availableTimesByDayByZone:
                            (externalChange?.categories &&
                                externalChange.categories[id]?.availableTimesByDayByZone) ||
                            null
                    },
                    group,
                    { arrayMerge: overwriteMerge }
                );
                const zones = Object.entries(
                    categories[id]?.availableTimesByDayByZone || {}
                ).reduce<TimesByDayByZone>((acc, [zoneName, zoneDays]) => {
                    if (zoneDays) {
                        acc[zoneName] = zoneDays;
                    }
                    return acc;
                }, {});
                categories[id].availableTimesByDayByZone = Object.values(zones).length ? zones : null;
            });

            const result = await menuApi.updateMenuChange(menuChangeId, {
                categoryGroups,
                categories,
                schema: MenuChangeSchema.v5
            });
            if (!result.ok) {
                throw new Error(result.body.message);
            }
            setChange(result.body);
            setTemporaryChange({});
            onUpdate(result.body);
            resetDeleted();
            dispatch(enqueueSnackbar(MESSAGE_WEEKLY_AVAILABILITY_UPDATE_SUCCESS, { variant: 'success' }));
            gridRef.current.reset();
        } catch (e) {
            logger.error(e);
            dispatch(enqueueSnackbar(e.message, { variant: 'error' }));
        }
    }, [
        gridRef,
        deleted,
        menuChangeId,
        setChange,
        onUpdate,
        resetDeleted,
        dispatch,
        weeklyAvailability,
        externalChange
    ]);

    const handleChange = React.useCallback(
        (newRow: GridValidRowModel) => {
            setTimeout(() => {
                gridRef.current.validate(newRow);
            }, 100);
        },
        [gridRef]
    );

    const handleCopyTo = React.useCallback(() => {
        toggleIsCopyModalOpen();
        const update = getWeeklyAvailabilityToCopy(change, selectedIds);
        setMenuChangeToCopy(update);
    }, [selectedIds, toggleIsCopyModalOpen, setMenuChangeToCopy, change]);

    return (
        <React.Fragment>
            <Box pb={1}>
                <ActionsHeader
                    onCreate={handleCreate}
                    menu={menu}
                    onCancelEditing={handleCancelEditing}
                    onSubmit={handleSubmit}
                    activeOperationId={activeOperationId}
                    onCopy={handleCopyTo}
                    onRefreshMenu={handleRefreshMenu}
                    isEditedOrDeleted={editedOrDeleted}
                    copyEnabled={copyEnabled}
                    onDelete={handleDelete}
                    deleteEnabled={deleteEnabled}
                />
                {renderInput()}
            </Box>
            <MuiGrid
                rows={weeklyAvailability}
                columns={columns}
                stateRef={gridRef}
                validateRow={validateItem}
                onStatusChange={handleStatusChange}
                deleted={deleted}
                isCellEditable={isCellEditable}
                onChange={handleChange}
                filterModel={filterModel}
                storageName={GridStorageName.WeeklyAvailability}
                disableColumnFilter
                checkboxSelection
                onRowSelectionModelChange={handleSelectChange}
                rowSelectionModel={selectedIds}
                checkboxVisibility
                handleCellBlur={handleCellBlur}
            />
            {renderLocationPickerModal()}
        </React.Fragment>
    );
};
