import React from 'react';
import {
    IScheduledTaskCreatePushNotification,
    ScheduledTaskStatus
} from 'components/scheduled-tasks/scheduledTasks.types';
import { Grid, Hidden } from '@mui/material';
import { FallbackSkeleton } from 'ui/skeleton/FallbackSkeleton';
import { SkeletonProps } from 'ui/skeleton/SkeletonComponent';
import { ScheduledTaskNotificationDetailsCard } from 'components/scheduled-tasks/notification/cards/ScheduledTaskNotificationDetailsCard';
import { Notification } from 'components/notification/models/Notification';
import { NotificationReceiptsCard } from 'components/notification/cards/NotificationReceiptsCard';
import { NotificationResultsCard } from 'components/notification/cards/NotificationResultsCard';

export interface ScheduledTaskNotificationViewProps {
    scheduledTask: IScheduledTaskCreatePushNotification;
    notifications: Notification<string>[];
    metrics: { sent: number; skipped: number; failures: number };
    loading: boolean;
}

const fallbackProps: Partial<SkeletonProps> = {
    height: '320px'
};

export const ScheduledTaskNotificationView: React.FC<ScheduledTaskNotificationViewProps> = ({
    scheduledTask,
    notifications,
    metrics,
    loading
}) => {
    const isLoading = React.useMemo(() => loading || !scheduledTask?._id, [loading, scheduledTask]);
    const isUnsent = React.useMemo(
        () =>
            scheduledTask?.status === ScheduledTaskStatus.DRAFT ||
            scheduledTask?.status === ScheduledTaskStatus.SCHEDULED ||
            scheduledTask?.status === ScheduledTaskStatus.QUEUED,
        [scheduledTask]
    );
    return (
        <>
            <Hidden lgDown>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps}>
                                    <ScheduledTaskNotificationDetailsCard scheduledTask={scheduledTask} />
                                </FallbackSkeleton>
                            </Grid>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps}>
                                    <NotificationResultsCard isLoading={isLoading} metrics={metrics} />
                                </FallbackSkeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps}>
                                    <NotificationReceiptsCard
                                        notifications={notifications}
                                        showNotificationIdFilter
                                        isLoading={isLoading}
                                        isUnsent={isUnsent}
                                        extraFilters={[{ key: 'scheduledTaskId', value: scheduledTask?._id }]}
                                    />
                                </FallbackSkeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdDown lgUp>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps}>
                                    <ScheduledTaskNotificationDetailsCard scheduledTask={scheduledTask} />
                                </FallbackSkeleton>
                            </Grid>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps}>
                                    <NotificationResultsCard isLoading={isLoading} metrics={metrics} />
                                </FallbackSkeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps}>
                                    <NotificationReceiptsCard
                                        notifications={notifications}
                                        showNotificationIdFilter
                                        isLoading={isLoading}
                                        isUnsent={isUnsent}
                                        extraFilters={[{ key: 'scheduledTaskId', value: scheduledTask?._id }]}
                                    />
                                </FallbackSkeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FallbackSkeleton loading={loading} {...fallbackProps}>
                            <ScheduledTaskNotificationDetailsCard scheduledTask={scheduledTask} />
                        </FallbackSkeleton>
                    </Grid>
                    <Grid item xs={12}>
                        <FallbackSkeleton loading={loading} {...fallbackProps}>
                            <NotificationResultsCard isLoading={isLoading} metrics={metrics} />
                        </FallbackSkeleton>
                    </Grid>
                    <Grid item xs={12}>
                        <FallbackSkeleton loading={loading} {...fallbackProps}>
                            <NotificationReceiptsCard
                                notifications={notifications}
                                showNotificationIdFilter
                                isLoading={isLoading}
                                isUnsent={isUnsent}
                                extraFilters={[{ key: 'scheduledTaskId', value: scheduledTask?._id }]}
                            />
                        </FallbackSkeleton>
                    </Grid>
                </Grid>
            </Hidden>
        </>
    );
};
