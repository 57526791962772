import { isArrayOf, isString } from 'lib/typeguards';
import React from 'react';

export interface KeyValuePair {
    key: string;
    value: string;
}

export const toKeyValuePairs = (initialValue: Record<string, any>): KeyValuePair[] =>
    Object.entries(initialValue).map(([key, value]) => {
        if (isString(value)) {
            return { key, value };
        }
        if (isArrayOf(isString, value)) {
            return { key, value: value.join(', ') };
        }
    });

export const useRecordSetting = (value: KeyValuePair[], onChange: (update: KeyValuePair[]) => void) => {
    const handleAdd = React.useCallback(() => {
        onChange([...value, { key: '', value: '' }]);
    }, [onChange, value]);

    const handleRemove = React.useCallback(
        (index: number) => () => {
            const updatedPairs = value.filter((_, i) => i !== index);
            onChange(updatedPairs);
        },
        [onChange, value]
    );

    const handleChange = React.useCallback(
        (index: number, field: 'key' | 'value') => (e: React.ChangeEvent<HTMLInputElement>) => {
            const updatedPairs = value.map((pair, i) =>
                i === index ? { ...pair, [field]: e.target.value } : pair
            );
            onChange(updatedPairs);
        },
        [onChange, value]
    );

    return {
        handleAdd,
        handleRemove,
        handleChange
    };
};
