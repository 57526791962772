import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { isSuggestion } from '@pepperhq/menu-sdk';
import { isArrayOf } from '../../../lib/typeguards';
import { Option } from '../../../lib/types';
import React from 'react';
import { ProductSuggestionType } from '../model/menu';

interface SuggestionsValueProps extends GridRenderCellParams {
    options: Option[];
}

const SuggestionsValue: React.FC<SuggestionsValueProps> = ({ value, options }) => {
    const renderString = React.useMemo(() => {
        if (isArrayOf(isSuggestion, value)) {
            const suggestionType = value[0].type as ProductSuggestionType;
            const productTitle = options.find(item => item.value === value[0].productId)?.label;
            switch (suggestionType) {
                case 'add':
                    if (productTitle) {
                        return `Add - ${productTitle}`;
                    }
                    return '';
                case 'replace':
                    if (productTitle) {
                        return `Replace - ${productTitle}`;
                    }
                    return '';
                case 'bogof':
                    return 'Offer';
                default:
                    return '';
            }
        }
        return '';
    }, [value, options]);

    return <span>{renderString}</span>;
};

export const SuggestionsViewCell = (props: GridRenderCellParams, options: Option[]) => (
    <SuggestionsValue {...props} options={options} />
);
