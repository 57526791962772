import React from 'react';

export function useRemainingHeight(defaultHeight = 600): [string, React.RefObject<HTMLDivElement>] {
    const [contentHeight, setContentHeight] = React.useState(defaultHeight);
    const elementRef = React.useRef<HTMLDivElement>();
    React.useLayoutEffect(() => {
        const rects = elementRef.current?.getBoundingClientRect();
        setContentHeight(rects?.height);
    }, []);
    const height = React.useMemo(() => `calc(100% - ${contentHeight}px)`, [contentHeight]);
    return [height, elementRef];
}
