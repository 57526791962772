import React from 'react';
import { Perk } from 'components/loyalty/models/PerkModel';
import { ActionType, IAction } from '../models/Action';
import { ActionCardCredentialVerified } from './ActionCardCredentialVerified';
import { ActionCardLoyaltyEarned } from './ActionCardLoyaltyEarned';
import { ActionCardOrderBilled } from './ActionCardOrderBilled';
import { ActionCardOrderCompleted } from './ActionCardOrderCompleted';
import { ActionCardOrderPaymentRefunded } from './ActionCardOrderPaymentRefunded';
import { ActionCardRefund } from './ActionCardRefund';
import { ActionCardUserAddedToAudience } from './ActionCardUserAddedToAudience';
import { ActionCardUserChanged } from './ActionCardUserChanged';
import { ActionCardUserCheckin } from './ActionCardUserCheckin';
import { ActionCardUserCreated } from './ActionCardUserCreated';
import { ActionCardUserCredited } from './ActionCardUserCredited';
import { ActionCardUserRemovedFromAudience } from './ActionCardUserRemovedFromAudience';
import { ActionCardGiftCardActivated } from './ActionCardGiftCardActivated';
import { ActionCardGiftCardTopup } from './ActionCardGiftCardTopup';
import { ActionCardAwardPointsReset } from './ActionCardAwardPointsReset';

export interface ActionCardModalComponentProps {
    action: IAction;
    perks?: Perk[];
}

export const ActionCardModalComponent: Record<string, React.FC<ActionCardModalComponentProps>> = {
    [ActionType.ORDER_COMPLETED]: ActionCardOrderCompleted,
    [ActionType.CREDENTIAL_VERIFIED]: ActionCardCredentialVerified,
    [ActionType.USER_CREATED]: ActionCardUserCreated,
    [ActionType.USER_CREDITED]: ActionCardUserCredited,
    [ActionType.REWARD_EARNED]: ActionCardLoyaltyEarned,
    [ActionType.AWARD_POINTS_RESET]: ActionCardAwardPointsReset,
    [ActionType.MEMBER_REMOVED]: ActionCardUserRemovedFromAudience,
    [ActionType.USER_CHANGED]: ActionCardUserChanged,
    [ActionType.CHECKIN]: ActionCardUserCheckin,
    [ActionType.MEMBER_ADDED]: ActionCardUserAddedToAudience,
    [ActionType.ORDER_USER_BILLED]: ActionCardOrderBilled,
    [ActionType.REFUND]: ActionCardRefund,
    [ActionType.ORDER_PAYMENT_REFUNDED]: ActionCardOrderPaymentRefunded,
    [ActionType.GIFT_CARD_ACTIVATED]: ActionCardGiftCardActivated,
    [ActionType.GIFT_CARD_TOPUP]: ActionCardGiftCardTopup
};
