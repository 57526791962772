/* eslint-disable @typescript-eslint/no-misused-promises */
import { v4 as uuid } from 'uuid';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { Crud, HttpClient, httpClient, HttpClientResponse } from 'lib/HttpClient';
import { IOrderRefundItem } from './model/Order';

export interface OrderRefundResultOC2 {
    createdAt: string;
    etaMs: number;
    href: string;
    id: number;
    status: OperationStatus;
    errorCode?: string;
    errorMessage?: string;
    resultHref?: number;
    resultId?: number;
}

interface IRefundOC2Body {
    userId?: string;
    items?: IOrderRefundItem[];
    refundTips?: boolean;
    paymentId?: number;
    amount?: number;
    reason?: string;
    note?: string;
}

class OrderApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.ORDER_SERVICE_URL, 'orders', { 'x-api-version': '19' });
    }
    refundOC2(orderId: string, body?: IRefundOC2Body): HttpClientResponse<OrderRefundResultOC2> {
        return this.httpClient.post(`${this.api}/orders/${orderId}/refunds`, {
            headers: {
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
                'x-api-version': '19',
                'x-pepper-req-nonce': uuid()
            },
            body
        });
    }
    refundOC2ById(orderId: string, body: IRefundOC2Body): HttpClientResponse<OrderRefundResultOC2> {
        return this.httpClient.post(`${this.api}/orders/${orderId}/refunds`, {
            headers: {
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
                'x-api-version': '15',
                'x-pepper-req-nonce': uuid()
            },
            body
        });
    }
    pollOC2Operation(operationId: number, etaMs: number): Promise<OrderRefundResultOC2> {
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                const result = await this.httpClient.get(`${this.api}/operations/${operationId}`, {
                    headers: {
                        'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
                        'x-api-version': '19'
                    }
                });
                if (!result || !result.ok) {
                    reject(new Error(`Could not get operation ${operationId}`));
                }

                if (result.body && result.body.status === 'PENDING') {
                    resolve(this.pollOC2Operation(result.body.id, result.body.etaMs));
                }

                resolve(result.body);
            }, etaMs);
        });
    }
    getOrder(orderId: string) {
        return this.httpClient.get(`${process.env.ORDER_SERVICE_URL}/orders/${orderId}`, {
            headers: {
                'x-api-version': '19',
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
            }
        });
    }
    onCharged(paymentIntentId: string) {
        return this.httpClient.post(`${process.env.ORDER_SERVICE_URL}/stripe/${paymentIntentId}/oncharged`, {
            headers: {
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
            }
        });
    }
    closeOpenOrders(cutoff: Date) {
        return this.httpClient.post(`${process.env.ORDER_SERVICE_URL}/orders/close`, {
            headers: {
                'x-api-version': '21',
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
                'x-pepper-req-nonce': uuid()
            },
            body: { cutoff }
        });
    }
}

export const orderApi = new OrderApi(httpClient);
