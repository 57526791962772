import get from 'get-value';
import React from 'react';
import { Crud } from './HttpClient';
import logger from './logger';
import { isString } from './typeguards';
import { Link } from 'ui/Link';

interface ResourceLinkProps {
    crud: Crud;
    consoleLink: string;
    id: string;
    pathParam: string;
    // Literally any resource could be here
    title: string | ((resource: any) => void);
}

export const ResourceLink: React.FC<ResourceLinkProps> = ({ pathParam, consoleLink, id, crud, title }) => {
    const [resource, setResource] = React.useState();
    const renderTitle = React.useMemo(() => {
        if (!resource) {
            return undefined;
        }
        if (typeof title === 'function') {
            return title(resource);
        }
        if (isString(title)) {
            return get(resource, title);
        }
        return undefined;
    }, [resource, title]);
    const to = React.useMemo(() => consoleLink.replace(pathParam, id), [consoleLink, id, pathParam]);
    React.useEffect(() => {
        crud.get(id, { headers: { 'Cache-Control': 'max-age=600' } })
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.body.message);
                }
                setResource(response.body);
            })
            .catch(e => {
                logger.error(e);
            });
    }, [crud, id]);
    return <Link href={to}>{renderTitle ? `${renderTitle} (${id})` : id}</Link>;
};
