import { CustomerSegment } from 'components/customers/models/Customer';
import { Location } from 'components/location/models/LocationModel';
import logger from 'lib/logger';
import { ContentModule } from 'components/content/models/ContentModule';
import { ScheduledTask } from 'components/scheduled-tasks/scheduledTasks.types';

enum ScheduledTasksReducerActionType {
    START_REQUEST = 'START_REQUEST',
    LOAD_SUCCESS = 'LOAD_SUCCESS',
    LOAD_ERROR = 'LOAD_ERROR',
    MODAL_ACTION_CREATE = 'MODAL_ACTION_CREATE',
    CREATE_SUCCESS = 'CREATE_SUCCESS',
    MODAL_ACTION_UPDATE = 'MODAL_ACTION_UPDATE',
    UPDATE_SUCCESS = 'UPDATE_SUCCESS',
    MODAL_ACTION_DELETE = 'MODAL_ACTION_DELETE',
    DELETE_SUCCESS = 'DELETE_SUCCESS',
    UNKNOWN = 'UNKNOWN'
}

interface IState {
    isLoading: boolean;
    segments: CustomerSegment[];
    locations: Location[];
    modules: ContentModule[];
    selectedScheduledTask: ScheduledTask<any>;
    actionedScheduledTask: { id: string; status: 'created' | 'updated' | 'deleted' } | null;
    createScheduledTaskModalOpen: boolean;
    updateScheduledTaskModalOpen: boolean;
    deleteScheduledTaskModalOpen: boolean;
}
type IReducerAction = IUnknownAction | IProgressAction | ILoadingAction | IModalAction | IModalSuccessAction;
interface IBaseReducerAction {
    type: ScheduledTasksReducerActionType;
}
interface IUnknownAction extends IBaseReducerAction {
    type: ScheduledTasksReducerActionType.UNKNOWN;
}
interface IProgressAction extends IBaseReducerAction {
    type: ScheduledTasksReducerActionType.START_REQUEST;
}
interface ILoadingAction extends IBaseReducerAction {
    type: ScheduledTasksReducerActionType.LOAD_SUCCESS | ScheduledTasksReducerActionType.LOAD_ERROR;
    locations?: Location[];
    segments?: CustomerSegment[];
    modules?: ContentModule[];
}
interface IModalAction extends IBaseReducerAction {
    type:
        | ScheduledTasksReducerActionType.MODAL_ACTION_CREATE
        | ScheduledTasksReducerActionType.MODAL_ACTION_UPDATE
        | ScheduledTasksReducerActionType.MODAL_ACTION_DELETE;
    isOpen: boolean;
    selectedScheduledTask?: ScheduledTask<any>;
}
interface IModalSuccessAction extends IBaseReducerAction {
    type:
        | ScheduledTasksReducerActionType.CREATE_SUCCESS
        | ScheduledTasksReducerActionType.UPDATE_SUCCESS
        | ScheduledTasksReducerActionType.DELETE_SUCCESS;
    scheduledTask: ScheduledTask<any>;
}

const initialState: IState = {
    isLoading: true,
    segments: [],
    locations: [],
    modules: [],
    selectedScheduledTask: null,
    actionedScheduledTask: null,
    createScheduledTaskModalOpen: false,
    updateScheduledTaskModalOpen: false,
    deleteScheduledTaskModalOpen: false
};

function reducer(state: IState, action: IReducerAction): IState {
    switch (action.type) {
        case ScheduledTasksReducerActionType.START_REQUEST:
            return { ...state, isLoading: true };
        case ScheduledTasksReducerActionType.LOAD_SUCCESS: {
            const dataToMerge: Partial<IState> = {
                isLoading: false
            };
            if (action.locations) {
                dataToMerge.locations = action.locations;
            }
            if (action.segments) {
                dataToMerge.segments = action.segments;
            }
            if (action.modules) {
                dataToMerge.modules = action.modules;
            }
            return {
                ...state,
                ...dataToMerge
            };
        }
        case ScheduledTasksReducerActionType.LOAD_ERROR:
            return {
                ...state,
                isLoading: false,
                locations: [],
                segments: [],
                modules: []
            };
        case ScheduledTasksReducerActionType.MODAL_ACTION_CREATE:
            return {
                ...state,
                isLoading: false,
                createScheduledTaskModalOpen: action.isOpen,
                selectedScheduledTask: action.selectedScheduledTask
            };
        case ScheduledTasksReducerActionType.MODAL_ACTION_UPDATE:
            return {
                ...state,
                isLoading: false,
                updateScheduledTaskModalOpen: action.isOpen,
                selectedScheduledTask: action.selectedScheduledTask
            };
        case ScheduledTasksReducerActionType.MODAL_ACTION_DELETE:
            return {
                ...state,
                isLoading: false,
                deleteScheduledTaskModalOpen: action.isOpen,
                selectedScheduledTask: action.selectedScheduledTask
            };
        case ScheduledTasksReducerActionType.CREATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                createScheduledTaskModalOpen: false,
                actionedScheduledTask: { id: action.scheduledTask._id, status: 'created' },
                selectedScheduledTask: null
            };
        case ScheduledTasksReducerActionType.UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                updateScheduledTaskModalOpen: false,
                actionedScheduledTask: { id: action.scheduledTask._id, status: 'updated' },
                selectedScheduledTask: null
            };
        case ScheduledTasksReducerActionType.DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                deleteScheduledTaskModalOpen: false,
                actionedScheduledTask: { id: action.scheduledTask._id, status: 'deleted' },
                selectedScheduledTask: null
            };
        default:
            logger.warn(
                `Expected reducer action type, but got: ${JSON.stringify(
                    action.type
                )}. ScheduledTasksPage.tsx-reducer`
            );
            return state;
    }
}

export default { reducer, initialState, ActionType: ScheduledTasksReducerActionType };
