/* eslint-disable @typescript-eslint/naming-convention */
import { Button, Grid } from '@mui/material';
import { Form } from 'formik';
import { MuiForm } from 'lib/Form';
import logger from 'lib/logger';
import React from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';
import { Dispute } from '../model/PepperPay';
import {
    DefaultEvidenceFormData,
    removeEmptyFields,
    removeNulls,
    useDefaultEvidenceFields
} from './hooks/useDefaultEvidenceFields';
import {
    ReasonBasedEvidenceFormData,
    useReasonBasedEvidenceFields
} from './hooks/useReasonBasedEvidenceFields';
import merge from 'deepmerge';
import { pepperPayApi } from '../pepperPayApi';
import { QueryParameter } from 'lib/HttpClient';
import { isDefined } from 'lib/typeguards';

interface EvidenceFormData extends ReasonBasedEvidenceFormData, DefaultEvidenceFormData {}

const emptyValues: EvidenceFormData = {
    billing_address: '',
    customer_name: '',
    customer_email_address: '',
    customer_purchase_ip: '',
    product_description: '',
    customer_signature: '',
    customer_communication: '',
    receipt: ''
};

interface DisputeEvidenceFormProps {
    onCancel: () => void;
    onSuccess: (data: Dispute) => void;
    dispute?: Dispute;
    locationId?: string;
}

export const DisputeEvidenceForm: React.FC<DisputeEvidenceFormProps> = ({
    onCancel,
    dispute,
    locationId,
    onSuccess
}) => {
    const dispatch = useDispatch();
    const { renderOptionalFields, getReasonBasedData } = useReasonBasedEvidenceFields(
        dispute?.reason,
        locationId
    );
    const { renderFields, getDefaultData } = useDefaultEvidenceFields(locationId);
    const [loading, setLoading] = React.useState(false);
    const initialValues = React.useMemo(() => (dispute ? dispute.evidence : emptyValues), [dispute]);
    const handleSubmit = React.useCallback(
        async (data: EvidenceFormData) => {
            setLoading(true);
            try {
                const defaultData = await getDefaultData(data);
                const reasonBasedData = await getReasonBasedData(data);
                const prepearedData = { ...defaultData, ...reasonBasedData };
                const evidence = merge(removeNulls(initialValues), removeEmptyFields(prepearedData));
                const requestBody = { evidence, metadata: dispute?.metadata, submit: true };
                const queryParameters: QueryParameter[] = [];
                if (isDefined(locationId)) {
                    queryParameters.push({ value: locationId, key: 'locationId' });
                }
                const result = await pepperPayApi.disputes.update(dispute?.id, {
                    body: requestBody,
                    queryParameters
                });
                if (!result.ok) {
                    throw new Error(result.body.message);
                }
                onSuccess(result.body);
            } catch (e) {
                logger.error(e);
                dispatch(enqueueSnackbar(e.message, { variant: 'error' }));
            } finally {
                setLoading(false);
            }
        },
        [
            getDefaultData,
            getReasonBasedData,
            initialValues,
            dispute?.metadata,
            dispute?.id,
            locationId,
            onSuccess,
            dispatch
        ]
    );
    return (
        <MuiForm onSubmit={handleSubmit} initialValues={initialValues}>
            {({ submitForm }) => (
                <Form>
                    <Grid container spacing={2}>
                        {renderFields()}
                        {renderOptionalFields()}
                        <Grid item xs={12}>
                            <Row align="flex-end" gutter>
                                <Button onClick={onCancel}>Cancel</Button>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    onClick={submitForm}
                                    loading={loading}
                                    disabled={loading}
                                >
                                    Submit
                                </LoadingButton>
                            </Row>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </MuiForm>
    );
};
