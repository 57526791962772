import React from 'react';
import { useToggle } from 'lib/hooks/useToggle';

export const useExternalPerksModals = ({
    handleResetEditSelection,
    handleResetDuplicateSelection,
    setIndexToDelete
}: {
    handleResetEditSelection: () => void;
    handleResetDuplicateSelection: () => void;
    setIndexToDelete: React.Dispatch<React.SetStateAction<number>>;
}) => {
    const [isCreateOpen, setIsCreateOpen, setIsCreateOpenState] = useToggle(false);
    const [isCreateConfirmationOpen, setIsCreateConfirmationOpen] = useToggle();
    const [isUpdateConfirmationOpen, setIsUpdateConfirmationOpen] = useToggle();

    const handleCloseCreateDialog = React.useCallback(() => {
        setIsCreateConfirmationOpen();
        handleResetDuplicateSelection();
        setIsCreateOpenState(false);
    }, [setIsCreateConfirmationOpen, setIsCreateOpenState, handleResetDuplicateSelection]);

    const handleCloseUpdateDialog = React.useCallback(() => {
        setIsUpdateConfirmationOpen();
        handleResetEditSelection();
    }, [handleResetEditSelection, setIsUpdateConfirmationOpen]);

    const handleCloseDeleteDialog = React.useCallback(() => {
        setIndexToDelete(-1);
    }, [setIndexToDelete]);

    return {
        isCreateOpen,
        isCreateConfirmationOpen,
        isUpdateConfirmationOpen,
        setIsCreateOpen,
        setIsCreateConfirmationOpen,
        setIsUpdateConfirmationOpen,
        handleCloseCreateDialog,
        handleCloseUpdateDialog,
        handleCloseDeleteDialog
    };
};
