import { Box, TablePagination, styled, Typography, TablePaginationProps, Paper, Button } from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { RadarListItem } from './models';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { BLOCK_LIST_PAGE_LIMIT } from './hooks/usePepperBlockList';
import { formatDateTime } from 'lib/timeHelpers';

interface IProps {
    isLoading?: boolean;
    data: RadarListItem[];
    count: number;
    page: number;
    toolbarItems: JSX.Element[];
    onDelete: (item: RadarListItem) => void;
    onPageChange: (pageNumber: number) => void;
}

const StyledPagination = styled((props: TablePaginationProps) => (
    <TablePagination component="div" {...props} />
))(({ theme }) => ({
    '&:last-child': {
        paddingRight: theme.spacing(6)
    }
}));

const StyledButton = styled(Button)(({ theme }) => ({
    minWidth: 60,
    color: theme.palette.divider,
    '.MuiDataGrid-row:hover &': {
        color: theme.palette.primary.main
    }
}));

export const BlockListTable: React.FC<IProps> = ({
    data,
    isLoading,
    count,
    page,
    toolbarItems,
    onPageChange,
    onDelete
}) => {
    const handleDelete = React.useCallback(
        (item: RadarListItem) => () => {
            onDelete(item);
        },
        [onDelete]
    );

    const renderDeleteCell = React.useCallback(
        (item: GridCellParams<RadarListItem, RadarListItem>) => (
            <StyledButton variant="text" size="small" onClick={handleDelete(item.row)}>
                <DeleteIcon />
            </StyledButton>
        ),
        [handleDelete]
    );

    const gridColumns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'Value',
                field: 'value',
                disableReorder: true,
                disableExport: true,
                flex: 1,
                sortable: false
            },
            {
                headerName: 'Date Added',
                field: 'created',
                disableReorder: true,
                disableExport: true,
                flex: 1,
                sortable: false,
                renderCell: (item: GridCellParams) => (
                    <Typography variant="caption" align="center">
                        {formatDateTime(new Date((item.value as number) * 1000))}
                    </Typography>
                )
            },
            {
                headerName: 'Remove',
                field: 'actions',
                disableReorder: true,
                disableExport: true,
                sortable: false,
                width: 150,
                renderCell: renderDeleteCell
            }
        ],
        [renderDeleteCell]
    );

    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            onPageChange(page);
        },
        [onPageChange]
    );
    const Pagination = React.useCallback(
        () => (
            <StyledPagination
                count={count}
                page={page}
                rowsPerPage={BLOCK_LIST_PAGE_LIMIT}
                rowsPerPageOptions={[BLOCK_LIST_PAGE_LIMIT]}
                onPageChange={handlePageChange}
            />
        ),
        [count, handlePageChange, page]
    );

    return (
        <>
            <Box marginX={-1} display="flex" justifyContent="flex-end">
                {toolbarItems}
            </Box>
            <Paper sx={{ flex: 1, display: 'flex', minHeight: 0 }}>
                <MuiGrid
                    rows={data}
                    columns={gridColumns}
                    disableMultipleRowSelection
                    hideFooter={false}
                    hideFooterRowCount
                    pagination
                    disableColumnFilter
                    paginationMode="server"
                    loading={isLoading}
                    slots={{
                        pagination: Pagination
                    }}
                />
            </Paper>
        </>
    );
};
