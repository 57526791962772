import React from 'react';
import { useRecordSetting } from './hooks/useRecordSetting';
import { Box, TextField, IconButton, Fab } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';

interface KeyValuePair {
    key: string;
    value: string;
}

interface KeyValueSettingsProps {
    value: KeyValuePair[];
    keyPlaceholder: string;
    valuePlaceholder: string;
    onChange: (update: KeyValuePair[]) => void;
}

export const KeyValueSettings: React.FC<KeyValueSettingsProps> = ({
    value,
    keyPlaceholder,
    valuePlaceholder,
    onChange
}) => {
    const { handleAdd, handleRemove, handleChange } = useRecordSetting(value, onChange);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
            {value.map((pair, index) => (
                <Box
                    key={index}
                    display="flex"
                    gap={1}
                    alignItems="center"
                    marginBottom={1}
                    sx={{ width: '100%', flex: 1 }}
                >
                    <TextField
                        placeholder={keyPlaceholder}
                        size="small"
                        value={pair.key}
                        onChange={handleChange(index, 'key')}
                        sx={{ flex: 1 }}
                    />
                    <TextField
                        placeholder={valuePlaceholder}
                        size="small"
                        value={pair.value}
                        onChange={handleChange(index, 'value')}
                        sx={{ flex: 1 }}
                    />
                    <IconButton color="error" onClick={handleRemove(index)} size="small">
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ))}
            <Fab color="primary" size="small" onClick={handleAdd} sx={{ marginLeft: 'auto', marginRight: 4 }}>
                <AddIcon />
            </Fab>
        </Box>
    );
};
