import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import {
    CategoryChangeUpdate,
    DefaultVariantStrategy,
    MenuChange,
    MenuChangeSchema
} from '@pepperhq/menu-sdk';
import { menuApi } from 'components/menu/MenuApi';
import {
    ColorPickerColumn,
    ImagePickerColumn,
    MultiSelectGridColumn,
    NumberIdSortComparator,
    SelectGridColumn
} from 'lib/MuiGrid/Columns';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React, { useContext } from 'react';
import {
    BaseMenuTab,
    dayLabel,
    getCategoriesData,
    getMenuChangeDataToCopy,
    getTaxIdToTitle,
    menuChannelLabel,
    menuChannelsArrayToFlags,
    scenarioLabel,
    useMenuChange
} from '../model/menu';
import * as Yup from 'yup';
import { useLeavePageBlock } from 'lib/hooks/useLeavePageBlock';
import { useDispatch, useSelector } from 'react-redux';
import logger from 'lib/logger';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_CATEGORIES_UPDATE_ERROR, MESSAGE_CATEGORIES_UPDATE_SUCCESS } from 'config/messages';
import { ApplicationState } from 'store/store';
import { isDefined, isEmptyString } from 'lib/typeguards';
import { ZonesArrayColumn } from '../availability/ZonesColumn';
import { CategoryTimesColumn } from '../availability/CategoryTimesColumn';
import { CategoryDaysColumn } from '../availability/CategoryDaysColumn';
import { useMenuGridSearch } from '../model/useMenuGridSearch';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { LocationsContext, useTabCopy } from '../useCopyTab';
import { ActionsHeader } from '../ActionsHeader';
import { useMenuSelection } from '../useMenuSelection';
import { useMenuDeleteControls } from '../model/useMenuDeleteControls';
import { EPaymentProvider } from '../../settings/PaymentsSettings';

type CategoriesTabProps = BaseMenuTab;

const validationSchema = {
    sort: Yup.number().integer('Whole number only').max(999999, '6 or less digits')
};

const HIDE_DESCRIPTION =
    "Set this to true to exclude the category from the menu completely.  If a category is excluded, 'Show in App' will have no effect";
const VISIBLE_DESCRIPTION = 'Whether or not to show this category in the app. This is not editable.';
const ZONES_TOOLTIP =
    'You cannot set an available zone on this category due to an existing weekly availability. Please review in Weekly Availability section.';
const HIDE_UNAVAILABLE_PRODUCTS_DESCRIPTION =
    'Determines whether unavailable products that are in this category are hidden.';

const defaultVariantLabel: Record<DefaultVariantStrategy, string> = {
    [DefaultVariantStrategy.HIGHEST_PRICE]: 'Highest Price',
    [DefaultVariantStrategy.LOWEST_PRICE]: 'Lowest Price',
    [DefaultVariantStrategy.NONE]: 'None'
};

export const CategoriesTab: React.FC<CategoriesTabProps> = ({
    menu,
    menuChangeId,
    loadFreshMenu,
    change: externalChange,
    search,
    onSearchChange
}) => {
    const locations = useContext(LocationsContext);
    const dispatch = useDispatch();
    const { codeSpecificEntries } = useSelector((state: ApplicationState) => state.perks);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const {
        change: [change, setChange],
        readyToSave,
        handleCancel,
        handleStatusChange,
        gridRef
    } = useMenuChange(externalChange);
    const {
        selectedIds,
        resetSelection,
        selectedCell,
        handleSelectChange,
        handleCellSelection,
        handleCellBlur,
        copyEnabled
    } = useMenuSelection(!!locations.length);

    const {
        editedOrDeleted,
        handleCancelEditing,
        handleDelete,
        deleted,
        deleteEnabled,
        resetDeleted,
        isCellEditable
    } = useMenuDeleteControls(selectedIds, readyToSave, handleCancel, resetSelection);

    const { toggleIsCopyModalOpen, setMenuChangeToCopy, activeOperationId, renderLocationPickerModal } =
        useTabCopy(resetSelection, selectedCell, [
            'visible',
            'title',
            'shortDescription',
            'sort',
            'availableTimes',
            'availableDays',
            'availableScenarios',
            'perkMatchCodes',
            'hide',
            'visible',
            'imageUrl',
            'availableZones',
            'defaultVariantStrategy',
            'hideUnavailableProducts',
            'terminalListingTextColour',
            'terminalListingBackgroundColour'
        ]);

    const handleRefreshMenu = React.useCallback(() => {
        loadFreshMenu();
        gridRef.current.reset();
    }, [gridRef, loadFreshMenu]);

    const categories = React.useMemo(() => {
        if (!menu || !change) {
            return [];
        }
        return getCategoriesData(menu, change);
    }, [change, menu]);

    const taxIdToTitle = React.useMemo(() => getTaxIdToTitle(menu, change), [change, menu]);

    const configuredZones = React.useMemo(() => {
        const zones = new Set<string>();
        categories.forEach(category => {
            if (isDefined(category.availableTimesByDayByZone)) {
                zones.add(category.id);
            }
        });
        return zones;
    }, [categories]);

    const configuredAdvancedAvailability = React.useMemo(() => {
        const advancedAvailability = new Set<string>();
        categories.forEach(category => {
            if (configuredZones.has(category.id) || isDefined(category.availableTimesByDay)) {
                advancedAvailability.add(category.id);
            }
        });
        return advancedAvailability;
    }, [categories, configuredZones]);

    useLeavePageBlock(editedOrDeleted);
    const gridSearchOptions = React.useMemo(
        () => [{ column: 'id' }, { column: 'title' }, { column: 'shortDescription' }],
        []
    );
    const { filterModel, renderInput } = useMenuGridSearch(
        gridSearchOptions,
        search,
        onSearchChange,
        'categories',
        'Search by ID, title or description'
    );

    const editable = !settings?.accessMenuManagerEnabled;

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'id',
                headerName: 'ID',
                width: 110,
                sortComparator: NumberIdSortComparator
            },
            {
                field: 'title',
                headerName: 'Title',
                editable,
                width: 200
            },
            {
                field: 'shortDescription',
                headerName: 'Description',
                editable,
                width: 200
            },
            {
                field: 'categoryGroups',
                headerName: 'Category Groups',
                width: 200
            },
            {
                field: 'visible',
                headerName: 'Show in App',
                width: 210,
                editable,
                headerAlign: 'left',
                type: 'boolean',
                description: VISIBLE_DESCRIPTION
            },
            {
                field: 'hide',
                headerName: 'Exclude from Menu',
                width: 230,
                editable,
                headerAlign: 'left',
                type: 'boolean',
                description: HIDE_DESCRIPTION
            },
            {
                field: 'hideUnavailableProducts',
                headerName: 'Hide Unavailable Products',
                width: 230,
                editable,
                headerAlign: 'left',
                type: 'boolean',
                description: HIDE_UNAVAILABLE_PRODUCTS_DESCRIPTION
            },
            {
                field: 'channels',
                headerName: 'Available Channels',
                width: 230,
                editable,
                ...MultiSelectGridColumn(menuChannelLabel, 'All')
            },
            {
                field: 'sort',
                headerName: 'Sort',
                editable,
                type: 'number',
                width: 120,
                headerAlign: 'left'
            },
            {
                field: 'availableDays',
                headerName: 'Available Days',
                width: 230,
                editable,
                ...CategoryDaysColumn(configuredAdvancedAvailability, dayLabel)
            },
            {
                field: 'availableTimes',
                headerName: 'Available Time',
                width: 230,
                editable,
                ...CategoryTimesColumn(configuredAdvancedAvailability)
            },
            {
                field: 'availableZones',
                headerName: 'Available Zones',
                width: 230,
                description: 'Seperate each zone with a comma.',
                editable,
                ...ZonesArrayColumn(configuredZones, ZONES_TOOLTIP)
            },
            {
                field: 'availableScenarios',
                headerName: 'Available Scenarios',
                width: 230,
                editable,
                ...MultiSelectGridColumn(scenarioLabel, 'All')
            },
            {
                field: 'perkMatchCodes',
                headerName: 'Perks',
                width: 300,
                editable,
                ...MultiSelectGridColumn(codeSpecificEntries, undefined, true)
            },
            {
                field: 'imageUrl',
                headerName: 'Image',
                width: 300,
                ...ImagePickerColumn('category-', editable, 388 / 130)
            },
            {
                field: 'defaultVariantStrategy',
                headerName: 'Product Groups',
                width: 200,
                editable,
                ...SelectGridColumn(defaultVariantLabel)
            },
            ...(settings?.paymentProvider === EPaymentProvider.Stripe && !!settings.stripe?.connectEnabled
                ? [
                      {
                          field: 'terminalListingTextColour',
                          headerName: 'QuickPad Menu Listing Text Colour',
                          width: 240,
                          ...ColorPickerColumn()
                      },
                      {
                          field: 'terminalListingBackgroundColour',
                          headerName: 'QuickPad Menu Listing Background Colour',
                          width: 240,
                          ...ColorPickerColumn()
                      }
                  ]
                : []),
            {
                field: 'taxIds',
                headerName: 'Taxes',
                width: 200,
                ...MultiSelectGridColumn(taxIdToTitle),
                editable: false
            }
        ],
        [
            configuredAdvancedAvailability,
            configuredZones,
            codeSpecificEntries,
            taxIdToTitle,
            editable,
            settings?.paymentProvider,
            settings?.stripe?.connectEnabled
        ]
    );
    const handleSubmit = React.useCallback(async () => {
        try {
            if (!gridRef.current) {
                throw new Error(MESSAGE_CATEGORIES_UPDATE_ERROR);
            }
            const errors = gridRef.current.validate();
            if (errors) {
                return;
            }
            const { changes } = gridRef.current.getState();
            const categoryChanges = Object.entries(changes).reduce<MenuChange['categories']>(
                (acc, [id, { _overriden, ...item }]) => {
                    acc[id] = { ...change?.categories?.[id], ...item };
                    if (isDefined(item.availableTimes)) {
                        acc[id].availableTimes = !item.availableTimes ? null : item.availableTimes;
                    }
                    if (isDefined(item.availableZones)) {
                        acc[id].availableZones = !item.availableZones ? null : item.availableZones;
                    }
                    if (isDefined(item.availableDays)) {
                        acc[id].availableDays = !item.availableDays ? null : item.availableDays;
                    }
                    if (isDefined(item.availableScenarios)) {
                        acc[id].availableScenarios = !item.availableScenarios
                            ? null
                            : item.availableScenarios;
                    }
                    if (isDefined(item.perkMatchCodes)) {
                        acc[id].perkMatchCodes = !item.perkMatchCodes ? null : item.perkMatchCodes;
                    }
                    if (isDefined(item.defaultVariantStrategy)) {
                        acc[id].defaultVariantStrategy = !item.defaultVariantStrategy
                            ? null
                            : item.defaultVariantStrategy;
                    }
                    if (isEmptyString(item.hide)) {
                        acc[id].hide = null;
                    }
                    if (isEmptyString(item.visible)) {
                        acc[id].visible = null;
                    }
                    if (isEmptyString(item.title)) {
                        acc[id].title = null;
                    }
                    if (isEmptyString(item.shortDescription)) {
                        acc[id].shortDescription = null;
                    }
                    if (isEmptyString(item.hideUnavailableProducts)) {
                        acc[id].hideUnavailableProducts = null;
                    }
                    if (isEmptyString(item.imageUrl)) {
                        acc[id].imageUrl = null;
                    }
                    if (isEmptyString(item.terminalListingTextColour)) {
                        acc[id].terminalListingTextColour = null;
                    }
                    if (isEmptyString(item.terminalListingBackgroundColour)) {
                        acc[id].terminalListingBackgroundColour = null;
                    }
                    if (isDefined(item.channels)) {
                        if (isEmptyString(item.channels)) {
                            acc[id].channels = null;
                        } else {
                            acc[id].channels = menuChannelsArrayToFlags(item.channels);
                        }
                    }
                    return acc;
                },
                {}
            );
            if (deleted) {
                deleted.forEach(deletedCategory => {
                    categoryChanges[deletedCategory] = null;
                });
            }
            const result = await menuApi.updateMenuChange(menuChangeId, {
                categories: categoryChanges,
                schema: MenuChangeSchema.v5
            });
            if (!result.ok) {
                throw new Error(MESSAGE_CATEGORIES_UPDATE_ERROR);
            }
            setChange(result.body);
            resetDeleted();
            dispatch(enqueueSnackbar(MESSAGE_CATEGORIES_UPDATE_SUCCESS, { variant: 'success' }));
            gridRef.current.reset();
        } catch (e) {
            logger.error(e);
            dispatch(enqueueSnackbar(e.message, { variant: 'error' }));
        }
    }, [gridRef, deleted, menuChangeId, setChange, resetDeleted, dispatch, change.categories]);

    const handleCopyTo = React.useCallback(() => {
        toggleIsCopyModalOpen();
        const menuChange = getMenuChangeDataToCopy<CategoryChangeUpdate>(
            change,
            selectedIds,
            'categories',
            selectedCell,
            row => row.id
        );
        setMenuChangeToCopy(menuChange);
    }, [toggleIsCopyModalOpen, change, selectedIds, selectedCell, setMenuChangeToCopy]);

    return (
        <React.Fragment>
            <Box pb={1}>
                <ActionsHeader
                    menu={menu}
                    onSubmit={handleSubmit}
                    activeOperationId={activeOperationId}
                    onCopy={editable ? handleCopyTo : undefined}
                    onRefreshMenu={handleRefreshMenu}
                    isEditedOrDeleted={editedOrDeleted}
                    copyEnabled={editable ? copyEnabled : false}
                    onCancelEditing={handleCancelEditing}
                    onDelete={handleDelete}
                    deleteEnabled={deleteEnabled}
                />
                {renderInput()}
            </Box>
            <MuiGrid
                rows={categories}
                columns={columns}
                stateRef={gridRef}
                filterModel={filterModel}
                onStatusChange={handleStatusChange}
                validationScheme={validationSchema}
                storageName={GridStorageName.Categories}
                disableColumnFilter
                checkboxSelection
                deleted={deleted}
                isCellEditable={isCellEditable}
                checkboxVisibility
                onRowSelectionModelChange={handleSelectChange}
                rowSelectionModel={selectedIds}
                onCellClick={handleCellSelection}
                handleCellBlur={handleCellBlur}
            />
            {renderLocationPickerModal()}
        </React.Fragment>
    );
};
