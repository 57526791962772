/* eslint-disable */
import { Box, Button, Grid, Typography } from '@mui/material';
import { AwardTemplate } from 'components/customers/models/Award';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { Form } from 'formik';
import { MuiForm } from 'lib/Form';
import { ButtonGroupInputFormField } from 'lib/form/fields/ButtonGroupInputFormField';
import { CheckboxFormField } from 'lib/form/fields/CheckboxFormField';
import { PrefixInputFormField } from 'lib/form/fields/PrefixInputFormField';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { Option } from 'lib/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { EPointOfSaleProvider } from 'components/settings/PointOfSaleSettings';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';
import * as Yup from 'yup';
import { PerkFormStep } from '../PerksEditStepper';
import { PerkSettingsTypeHandler } from './PerkSettingsTypeHandler';

export const perkTypeToLabelOptions: Record<AwardTemplate | string, string> = {
    [AwardTemplate.AD_HOC]: 'Adhoc',
    [AwardTemplate.PSEUDO_CURRENCY]: 'Pseudo Currency',
    [AwardTemplate.STAMP_CARD]: 'Stamp Card',
    [AwardTemplate.CLUB]: 'Audience',
    [AwardTemplate.LEVEL]: 'Level'
};

const initialPointsFormLabel: Record<AwardTemplate, { label: string; description: string }> = {
    [AwardTemplate.AD_HOC]: {
        label: 'Granted By Default',
        description: 'The number of awards granted to the user for the first time.'
    },
    [AwardTemplate.STAMP_CARD]: {
        label: 'Stamps Granted By Default',
        description: 'The number of stamps granted to the user for the first time.'
    },
    [AwardTemplate.PSEUDO_CURRENCY]: {
        label: 'Points Granted By Default',
        description: 'The number of points granted to the user for the first time.'
    },
    [AwardTemplate.CLUB]: {
        label: 'Granted By Default',
        description: 'The number of awards granted to the user for the first time.'
    },
    [AwardTemplate.LEVEL]: {
        label: 'Granted By Default',
        description: 'The number of awards granted to the user for the first time.'
    }
};

const typeOptions: Option[] = [
    { value: AwardTemplate.AD_HOC, label: 'Adhoc' },
    { value: AwardTemplate.PSEUDO_CURRENCY, label: 'Pseudo Currency' },
    { value: AwardTemplate.STAMP_CARD, label: 'Stamp Card' },
    { value: AwardTemplate.CLUB, label: 'Audience' }
];

export interface PerkSettingsFormData {
    auto?: boolean;
    terms: string;
    global: boolean;
    enabled?: boolean;
    redemption: string;
    locationIds?: string[];
    perkType: AwardTemplate;
    multi?: boolean;
    impact?: string;
    initial?: number;
    step?: string;
}

interface PerkSettingsFormProps {
    onSubmit: (data: PerkSettingsFormData) => void;
    clickPrevious: () => void;
    isEdit?: boolean;
    open: boolean;
    title?: string;
    initialValues?: PerkSettingsFormData;
    isLoading?: boolean;
    onPrevious?: (isValid?: boolean, values?: PerkSettingsFormData) => void;
    onUpdate?: (values: { type: PerkFormStep.CONFIGURE; values: PerkSettingsFormData }) => void;
    onPerkTypeChange?: (perkType: AwardTemplate) => void;
    currencySymbol: string;
    primaryActionBackgroundColour: string;
}

const createValidationSchema = Yup.object().shape({
    terms: Yup.string().required('This field is required.'),
    redemption: Yup.string().required('This field is required.'),
    initial: Yup.number()
        .required('This field is required.')
        .min(1, 'This field must be higher than 0.')
        .max(10000, 'This field must be lower than 10000.'),
    step: Yup.string().when('perkType', {
        is: AwardTemplate.PSEUDO_CURRENCY,
        then: Yup.string().required('This field is required.'),
        otherwise: undefined
    }),
    impact: Yup.string().when('perkType', {
        is: AwardTemplate.PSEUDO_CURRENCY,
        then: Yup.string().required('This field is required.'),
        otherwise: undefined
    })
});

const editValidationSchema = Yup.object().shape({
    redemption: Yup.string().required('This field is required.'),
    terms: Yup.string().required('This field is required.')
});

export const PerkSettingsForm: React.FC<PerkSettingsFormProps> = props => {
    const {
        onSubmit,
        clickPrevious,
        initialValues,
        open,
        title,
        isEdit,
        onPrevious,
        onUpdate,
        isLoading,
        currencySymbol,
        onPerkTypeChange
    } = props;
    const { allLocations } = useLocationHelpers();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const isIbsOrSimphony = React.useMemo(
        () =>
            settings.posProvider === EPointOfSaleProvider.IBS ||
            settings.posProvider === EPointOfSaleProvider.OMNIVORE_MICROS_SIMPHONY,
        [settings]
    );
    const awardsScreenShapeEnabled = React.useMemo(
        () => settings?.app?.awardsScreenBarcodePosition && settings.app.awardsScreenBarcodePosition !== '',
        [settings]
    );

    const initialData = React.useMemo<PerkSettingsFormData>(
        () => ({
            auto: (!isEdit && isIbsOrSimphony) || undefined,
            terms: '',
            global: false,
            enabled: true,
            redemption: '',
            locationIds: [],
            perkType: AwardTemplate.AD_HOC,
            multi: false,
            initial: 1,
            step: '',
            impact: '1'
        }),
        [awardsScreenShapeEnabled]
    );

    const locationOptions: Option[] = React.useMemo(() => {
        if (allLocations) {
            return allLocations
                .filter(location => location.state === 'ACTIVE')
                .map(location => ({ value: location._id, label: location.title }));
        }

        return [];
    }, [allLocations]);

    if (!open) {
        return null;
    }

    return (
        <MuiForm
            onSubmit={onSubmit}
            validationSchema={isEdit ? editValidationSchema : createValidationSchema}
            initialValues={initialValues ? { ...initialData, ...initialValues } : initialData}
        >
            {({ submitForm, values, isValid, setFieldValue }) => {
                const { perkType, enabled, multi } = values;

                // Handles an edge case where the form might be stuck if initial field is not set/invalid and Multi is ticked
                React.useEffect(() => {
                    if (isEdit) {
                        return;
                    }
                    if (multi && (perkType === AwardTemplate.AD_HOC || perkType === AwardTemplate.CLUB)) {
                        setFieldValue('initial', 1);
                    }
                }, [multi, perkType, setFieldValue]);

                if (!open) {
                    return null;
                }
                const handlePreviousStep = () => {
                    if (isEdit) {
                        onPrevious(isValid, values);
                    }
                    clickPrevious();
                };
                const handleUpdate = () => {
                    if (isValid) {
                        onUpdate({ type: PerkFormStep.CONFIGURE, values });
                    }
                };
                return (
                    <Form>
                        <PerkSettingsTypeHandler
                            isEdit={isEdit}
                            perkType={perkType}
                            onPerkTypeChange={onPerkTypeChange}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box padding={2} maxWidth={600}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h4">2. Configure</Typography>
                                            <Typography variant="body2">
                                                Set up how the Perk will behave for your users.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFormField
                                                name="terms"
                                                multiline
                                                maxRows={2}
                                                label={
                                                    <MuiFormLabel required>Terms & Conditions</MuiFormLabel>
                                                }
                                                description="The terms and conditions associated with this perk for your customers to see in the app."
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ButtonGroupInputFormField
                                                disabled={isEdit}
                                                name="perkType"
                                                label="Perk Type"
                                                options={typeOptions}
                                                description="Choose which type of perk you would like to configure."
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFormField
                                                name="redemption"
                                                placeholder="123abc"
                                                label={<MuiFormLabel required>Discount Code</MuiFormLabel>}
                                                description="Enter a code that wil be visible to the cashier at the POS to let them know what discount to apply to the order."
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SelectFormField
                                                name="locationIds"
                                                placeholder="123abc"
                                                multiple
                                                hasAllOption
                                                selectAllOptionLabel="Select All Locations"
                                                options={locationOptions}
                                                label={<MuiFormLabel>Locations</MuiFormLabel>}
                                                description="If no locations are selected, the perk will be available and shown for all locations."
                                            />
                                        </Grid>
                                        {!isEdit && perkType === AwardTemplate.PSEUDO_CURRENCY && (
                                            <Grid item xs={12}>
                                                <Row wrap="wrap" valign="center">
                                                    <Typography variant="caption" color="textSecondary">
                                                        User has to spend
                                                    </Typography>
                                                    <Box width={80} marginLeft={1} marginRight={1}>
                                                        <PrefixInputFormField
                                                            prefix={currencySymbol}
                                                            name="step"
                                                            placeholder="£1.00"
                                                        />
                                                    </Box>
                                                    <Typography variant="caption" color="textSecondary">
                                                        to receive
                                                    </Typography>
                                                    <Box width={80} marginLeft={1} marginRight={1}>
                                                        <TextFormField
                                                            name="impact"
                                                            type="number"
                                                            placeholder="10"
                                                            readOnly
                                                        />
                                                    </Box>
                                                    <Typography
                                                        variant="caption"
                                                        color="textSecondary"
                                                        style={{ lineHeight: 3 }}
                                                    >
                                                        <MuiFormLabel required>{title}s.</MuiFormLabel>
                                                    </Typography>
                                                </Row>
                                            </Grid>
                                        )}
                                        {isIbsOrSimphony && (
                                            <Grid item xs={12}>
                                                <CheckboxFormField
                                                    disabled={isEdit}
                                                    name="auto"
                                                    label="Auto Apply"
                                                    description="Automatically apply this award to your user's basket"
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <CheckboxFormField
                                                name="enabled"
                                                label="Enable & Push Live"
                                                description={
                                                    enabled
                                                        ? 'This award will be granted to your users and displayed in app. Users will be able to redeem on app and/or POS.'
                                                        : 'This award will not be granted to your users. Users who already have the award will be hidden in app nor available to redeem on app and/or POS.'
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckboxFormField
                                                name="global"
                                                label="Global"
                                                description="Automatically grant this award to all of your users."
                                            />
                                        </Grid>
                                        {(perkType === AwardTemplate.AD_HOC ||
                                            perkType === AwardTemplate.CLUB) && (
                                            <Grid item xs={12}>
                                                <CheckboxFormField
                                                    disabled={isEdit}
                                                    name="multi"
                                                    label="Multi"
                                                    description="Allow your users to redeem this perk multiple times."
                                                />
                                            </Grid>
                                        )}

                                        {perkType === AwardTemplate.PSEUDO_CURRENCY ||
                                        perkType === AwardTemplate.STAMP_CARD ? (
                                            <Grid item xs={12}>
                                                <TextFormField
                                                    label={initialPointsFormLabel[perkType].label}
                                                    description={initialPointsFormLabel[perkType].description}
                                                    disabled={isEdit}
                                                    name="initial"
                                                    type="number"
                                                    placeholder="0"
                                                />
                                            </Grid>
                                        ) : (
                                            !multi && (
                                                <Grid item xs={12}>
                                                    <TextFormField
                                                        label={initialPointsFormLabel[perkType].label}
                                                        description={
                                                            initialPointsFormLabel[perkType].description
                                                        }
                                                        disabled={isEdit}
                                                        name="initial"
                                                        type="number"
                                                        placeholder="0"
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Row flex={1} gutter align="flex-end">
                                    {isEdit && (
                                        <LoadingButton
                                            loading={isLoading}
                                            disabled={isLoading || !isValid}
                                            onClick={handleUpdate}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            Update & Close
                                        </LoadingButton>
                                    )}
                                    <Button onClick={handlePreviousStep} color="primary" variant="outlined">
                                        Previous
                                    </Button>
                                    <Button onClick={submitForm} color="primary" variant="contained">
                                        Next
                                    </Button>
                                </Row>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </MuiForm>
    );
};
