import React from 'react';
import { AudienceMetric, getMetricFormatting } from './models/metrics';
import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import { CardViewTextRow } from 'ui/viewComponents';

interface SegmentMetricsOverviewProps {
    metrics: AudienceMetric[];
}

export const SegmentMetricsOverview: React.FC<SegmentMetricsOverviewProps> = ({ metrics }) => {
    const renderMetric = React.useCallback((metric: AudienceMetric) => {
        const { title, valueFormatter } = getMetricFormatting(metric.metricId);
        return (
            <Grid item xs={12} sm={3} xl={2} key={metric.metricId}>
                <CardViewTextRow noTransform title={title} value={valueFormatter(metric.value)} />
            </Grid>
        );
    }, []);
    return (
        <Paper sx={{ mb: 2 }}>
            <Box padding={2}>
                <Typography>Metrics</Typography>
            </Box>
            <Divider />
            <Grid container spacing={2} sx={{ p: 2 }}>
                {metrics.map(renderMetric)}
            </Grid>
        </Paper>
    );
};
