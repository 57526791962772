import React from 'react';
import logger from 'lib/logger';
import { EPremiumLoyaltyState, UserMetricDefinition } from 'components/metrics/model';
import { metricsApi } from 'components/metrics/metricsApi';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { useHistory } from 'react-router-dom';

export enum EPremiumLoyaltyScheme {
    Tier = 'tiered_loyalty',
    Birthday = 'birthday_loyalty'
}

const getValidationFn = (scheme: EPremiumLoyaltyScheme) => {
    switch (scheme) {
        case EPremiumLoyaltyScheme.Birthday:
            return (metricDefinition: UserMetricDefinition) => !!metricDefinition?.query?.birthdate;
        case EPremiumLoyaltyScheme.Tier:
            return (metricDefinition: UserMetricDefinition) =>
                !!metricDefinition &&
                !!metricDefinition?.effects?.length &&
                !!Object.keys(metricDefinition?.metrics).length;
        default:
            throw new Error('This scheme is not supported yet');
    }
};

export function useUserMetricDefinition(scheme: EPremiumLoyaltyScheme) {
    const { settings, loading: settingsLoading } = useSelector((state: ApplicationState) => state.settings);
    const history = useHistory();
    const [loading, setLoading] = React.useState(true);
    const [metricDefinition, setMetricDefinition] = React.useState<UserMetricDefinition>();
    const [isSetup, setIsSetup] = React.useState(false);
    const loadPremiumLoyalty = React.useCallback(() => {
        setLoading(true);
        metricsApi
            .getList({ queryParameters: [{ key: 'tag', value: scheme }] })
            .then(result => {
                if (!result.ok) {
                    throw new Error(result.body.message);
                }
                const [definition] = result.body.items;
                setMetricDefinition(definition);
                if (!definition) {
                    setIsSetup(true);
                }
            })
            .catch(e => {
                logger.error(e.message, e);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [scheme]);
    React.useEffect(() => {
        loadPremiumLoyalty();
    }, [loadPremiumLoyalty]);
    const updateMetricDefinition = React.useCallback((value: UserMetricDefinition) => {
        setMetricDefinition(value);
    }, []);
    const finishSetup = React.useCallback(() => {
        setIsSetup(false);
    }, []);
    const state = React.useMemo(() => {
        if (!metricDefinition) {
            return undefined;
        }
        if (scheme === EPremiumLoyaltyScheme.Birthday) {
            if (metricDefinition.realtime || metricDefinition.scheduled) {
                return EPremiumLoyaltyState.ACTIVE;
            }
        } else {
            if (metricDefinition.realtime) {
                if (metricDefinition.scheduled) {
                    return EPremiumLoyaltyState.SCHEDULED_AND_REALTIME;
                }
                return EPremiumLoyaltyState.REALTIME;
            }
            if (metricDefinition.scheduled) {
                return EPremiumLoyaltyState.SCHEDULED;
            }
        }
        return EPremiumLoyaltyState.INACTIVE;
    }, [metricDefinition, scheme]);
    const isValidDefinition = React.useMemo(
        () => getValidationFn(scheme)(metricDefinition),
        [metricDefinition, scheme]
    );
    React.useEffect(() => {
        if (settings && !settingsLoading && !settings?.premiumLoyaltyEnabled) {
            history.replace('/');
        }
    }, [history, settings, settings?.premiumLoyaltyEnabled, settingsLoading]);
    return {
        loading,
        metricDefinition,
        isSetup,
        setMetricDefinition: updateMetricDefinition,
        finishSetup,
        state,
        isValidMetricDefinition: isValidDefinition,
        loadPremiumLoyalty
    };
}
