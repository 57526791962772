import React from 'react';
import { Crud } from './HttpClient';
import logger from './logger';
import { Typography } from '@mui/material';

interface ResourceTextProps {
    crud: Crud;
    id: string;
    // Literally any resource could be here
    title: (resource: any) => string;
}

export const ResourceText: React.FC<ResourceTextProps> = ({ id, crud, title }) => {
    const [resource, setResource] = React.useState();
    const renderTitle = React.useMemo(() => {
        if (!resource) {
            return undefined;
        }
        return title(resource);
    }, [resource, title]);
    React.useEffect(() => {
        crud.get(id, { headers: { 'Cache-Control': 'max-age=600' } })
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.body.message);
                }
                setResource(response.body);
            })
            .catch(e => {
                logger.error(e);
            });
    }, [crud, id]);
    return <Typography>{renderTitle || id}</Typography>;
};
