import React from 'react';
import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { SvgIconProps } from '@mui/material/SvgIcon';

export type TableActionButtonProps = ButtonProps & {
    Icon?: React.ComponentType<SvgIconProps>;
};

export const TableActionButton = ({
    onClick,
    Icon,
    children,
    disabled,
    color = 'primary',
    variant = 'contained'
}: TableActionButtonProps) => (
    <Button disabled={disabled} onClick={onClick} sx={{ m: 1, p: 1, pr: 2 }} variant={variant} color={color}>
        {Icon && <Icon sx={{ mr: 1 }} />}
        <span>{children}</span>
    </Button>
);
