/* eslint-disable react/jsx-no-bind */
import { MuiForm } from 'lib/Form';
import React, { CSSProperties } from 'react';
import { PerkFormStep } from 'components/perks/PerksEditStepper';
import {
    AwardExpiryLabelDetailStyles,
    AwardsScreenShapes,
    AwardTemplate
} from 'components/customers/models/Award';
import { Form } from 'formik';
import { Box, Button, Grid, Stack, styled, Typography } from '@mui/material';
import { Column, Row } from 'ui/Flex';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import {
    PerkPreviewEditable,
    perkPreviewWidthByType
} from 'components/perks/forms/visualisation/PerkPreviewEditable';
import { MuiTheme } from 'config/theme';
import { ButtonGroupInputFormField } from 'lib/form/fields/ButtonGroupInputFormField';
import { ImageFormField } from 'lib/form/fields/ImageFormField';
import { ColorFormField, RGBAValue } from 'lib/form/fields/ColorFormField';
import { CheckboxFormField } from 'lib/form/fields/CheckboxFormField';
import clsx from 'clsx';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { TextAlignment, VerticalAlignment } from 'components/loyalty/models/PerkModel';
import { Option } from 'lib/types';
import {
    BottomFullWidthBarIcon,
    BottomMiddleChipIcon,
    TopFullWidthBarIcon,
    TopMiddleChipIcon
} from 'components/perks/ExpiryLabelDetailIcons';
import * as Yup from 'yup';
import FormatAlignLeft from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenter from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRight from '@mui/icons-material/FormatAlignRight';
import VerticalAlignTop from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignCenter from '@mui/icons-material/VerticalAlignCenter';
import VerticalAlignBottom from '@mui/icons-material/VerticalAlignBottom';
import FormatAlignJustify from '@mui/icons-material/FormatAlignJustify';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { isNonEmptyString } from 'lib/typeguards';

export const alignOptions: Option[] = [
    { value: TextAlignment.LEFT, label: <FormatAlignLeft /> },
    { value: TextAlignment.CENTER, label: <FormatAlignCenter /> },
    { value: TextAlignment.RIGHT, label: <FormatAlignRight /> }
];

export const valignOptions: Option[] = [
    { value: VerticalAlignment.TOP, label: <VerticalAlignTop /> },
    { value: VerticalAlignment.CENTER, label: <VerticalAlignCenter /> },
    { value: VerticalAlignment.BOTTOM, label: <VerticalAlignBottom /> }
];

export const expiryLabelAlignOptions: Option[] = [
    { value: TextAlignment.LEFT, label: <FormatAlignLeft /> },
    { value: TextAlignment.CENTER, label: <FormatAlignCenter /> },
    { value: TextAlignment.RIGHT, label: <FormatAlignRight /> },
    { value: TextAlignment.JUSTIFY, label: <FormatAlignJustify /> }
];

export const expiryLabelVAlignOptions: Option[] = [
    { value: VerticalAlignment.TOP, label: <VerticalAlignTop /> },
    { value: VerticalAlignment.BOTTOM, label: <VerticalAlignBottom /> }
];

const expiryLabelDetailStyleOptions: Option[] = [
    { value: AwardExpiryLabelDetailStyles.TOP_MIDDLE_CHIP, label: <TopMiddleChipIcon /> },
    { value: AwardExpiryLabelDetailStyles.TOP_FULL_WIDTH_BAR, label: <TopFullWidthBarIcon /> },
    { value: AwardExpiryLabelDetailStyles.BOTTOM_MIDDLE_CHIP, label: <BottomMiddleChipIcon /> },
    { value: AwardExpiryLabelDetailStyles.BOTTOM_FULL_WIDTH_BAR, label: <BottomFullWidthBarIcon /> }
];

export const awardBackgroundSizes = (awardShape?: AwardsScreenShapes) => {
    switch (awardShape) {
        case AwardsScreenShapes.SINGLE_RECTANGLE:
            return {
                ratio: 3 / 1,
                description: '600px by 200px. Max. file size is 1M.'
            };
        case AwardsScreenShapes.LARGE_SQUARE:
        case AwardsScreenShapes.SMALL_SQUARE:
            return {
                ratio: 1 / 1,
                description: '400px by 400px. Max. file size is 1M.'
            };
        case AwardsScreenShapes.DOUBLE_RECTANGLE:
        default:
            return {
                ratio: 3 / 2,
                description: '600px by 400px. Max. file size is 1M.'
            };
    }
};

const awardsScreenShapeOptions: Option[] = [
    {
        value: AwardsScreenShapes.DOUBLE_RECTANGLE,
        label: 'Double Rectangle'
    },
    {
        value: AwardsScreenShapes.SINGLE_RECTANGLE,
        label: 'Single Rectangle'
    },
    {
        value: AwardsScreenShapes.LARGE_SQUARE,
        label: 'Large Square'
    },
    {
        label: 'Small Square',
        value: AwardsScreenShapes.SMALL_SQUARE
    }
];

const PREFIX = 'PerkVisualisationForm';

const classes = {
    colours: `${PREFIX}-colours`,
    stampFields: `${PREFIX}-stampFields`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.colours}`]: {
        height: '100%',
        alignItems: 'start',
        display: 'flex',
        gap: theme.spacing(2)
    },
    [`& .${classes.stampFields}`]: {
        marginLeft: theme.spacing(2)
    }
}));

export interface PerkVisualisationFormData {
    perkType: AwardTemplate;
    subtitle?: string;
    subtitle2?: string;
    image?: string;
    textColor: RGBAValue;
    randomizeStampCard?: boolean;
    backgroundColor: RGBAValue;
    nextCardProgressCopy?: string;
    align: TextAlignment;
    stampsPerCard?: string;
    stampImageUrl?: string;
    stampColor: RGBAValue;
    valign: VerticalAlignment;
    availableCardCopy?: string;
    titleText?: string;
    showExpiry?: boolean;
    expiryLabelAnchorStyleAlign?: TextAlignment;
    expiryLabelAnchorStyleVAlign?: VerticalAlignment.TOP | VerticalAlignment.BOTTOM;
    expiryLabelDetailStyle?: AwardExpiryLabelDetailStyles;
    walletEnabled?: boolean;
    walletTitle?: string;
    walletPriority?: number;
    walletDescription?: string;
    walletTerms?: string;
    walletLink?: string;
    walletAppleImageUrl?: string;
    walletGoogleImageUrl?: string;
    walletHidden?: boolean;
    walletDisplayPoints?: boolean;
    awardsScreenShape?: AwardsScreenShapes;
}

export interface PerkVisualisationFormProps {
    onSubmit: (data: PerkVisualisationFormData) => void;
    isEdit?: boolean;
    open: boolean;
    title?: string;
    initialValues?: Partial<PerkVisualisationFormData>;
    isLoading?: boolean;
    onPrevious?: (isValid?: boolean, values?: PerkVisualisationFormData) => void;
    onUpdate?: (values: { type: PerkFormStep.VISUALISATION; values: PerkVisualisationFormData }) => void;
    primaryActionBackgroundColour: string;
}

const createValidationSchema = Yup.object().shape({
    stampsPerCard: Yup.number().when('perkType', {
        is: AwardTemplate.STAMP_CARD,
        then: Yup.number()
            .min(2, 'This field must be higher than 2.')
            .max(12, 'This field must be lower than 12.'),
        otherwise: undefined
    }),
    walletTitle: Yup.string().when('walletEnabled', {
        is: true,
        then: Yup.string().required('This field is required.')
    }),
    walletPriority: Yup.number().when('walletEnabled', {
        is: true,
        then: Yup.number().required('This field is required.').min(0, 'This field must be higher than 0.')
    })
});

const editValidationSchema = Yup.object().shape({
    stampsPerCard: Yup.number().when('perkType', {
        is: AwardTemplate.STAMP_CARD,
        then: Yup.number()
            .min(2, 'This field must be higher than 2.')
            .max(12, 'This field must be lower than 12.'),
        otherwise: undefined
    }),
    walletTitle: Yup.string().when('walletEnabled', {
        is: true,
        then: Yup.string().required('This field is required.')
    }),
    walletPriority: Yup.number().when('walletEnabled', {
        is: true,
        then: Yup.number().required('This field is required.').min(0, 'This field must be higher than 0.')
    })
});
export const PerkVisualisationForm: React.FC<PerkVisualisationFormProps> = props => {
    const {
        onSubmit,
        isEdit,
        open,
        title,
        initialValues,
        isLoading,
        onPrevious,
        onUpdate,
        primaryActionBackgroundColour
    } = props;

    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const awardsScreenShapeEnabled = React.useMemo(
        () => settings?.app?.awardsScreenBarcodePosition && settings.app.awardsScreenBarcodePosition !== '',
        [settings]
    );

    const initialData = React.useMemo<PerkVisualisationFormData>(
        () => ({
            perkType: AwardTemplate.AD_HOC,
            subtitle: '',
            subtitle2: '',
            availableCardCopy: '',
            nextCardProgressCopy: '',
            image: '',
            textColor: '#000000',
            stampsPerCard: '6',
            backgroundColor: '#ffffff00',
            randomizeStampCard: false,
            stampColor: '#000000',
            stampImageUrl: '',
            align: TextAlignment.LEFT,
            valign: VerticalAlignment.BOTTOM,
            titleText: '',
            showExpiry: false,
            expiryLabelAnchorStyleAlign: TextAlignment.RIGHT,
            expiryLabelAnchorStyleVAlign: VerticalAlignment.BOTTOM,
            expiryLabelDetailStyle: AwardExpiryLabelDetailStyles.TOP_MIDDLE_CHIP,
            walletEnabled: false,
            walletTitle: '',
            walletHidden: false,
            walletDisplayPoints: true,
            awardsScreenShape: awardsScreenShapeEnabled ? AwardsScreenShapes.DOUBLE_RECTANGLE : undefined
        }),
        [awardsScreenShapeEnabled]
    );

    if (!open) {
        return null;
    }

    return (
        <MuiForm
            onSubmit={onSubmit}
            validationSchema={isEdit ? editValidationSchema : createValidationSchema}
            initialValues={initialValues ? { ...initialData, ...initialValues } : initialData}
        >
            {({ submitForm, values, isValid, initialValues }) => {
                const {
                    perkType,
                    valign,
                    align,
                    image,
                    backgroundColor,
                    textColor,
                    stampsPerCard,
                    stampColor,
                    stampImageUrl,
                    randomizeStampCard,
                    showExpiry,
                    expiryLabelAnchorStyleAlign,
                    expiryLabelAnchorStyleVAlign,
                    walletEnabled,
                    awardsScreenShape
                } = values;

                const textAlign: CSSProperties['textAlign'] =
                    align.toLowerCase() as CSSProperties['textAlign'];

                const handlePreviousStep = () => {
                    onPrevious(isValid, values);
                };

                const handleUpdate = () => {
                    if (isValid) {
                        onUpdate({ type: PerkFormStep.VISUALISATION, values });
                    }
                };

                const eligiblePerkTypeForExpiryUI =
                    perkType === AwardTemplate.AD_HOC || perkType === AwardTemplate.PSEUDO_CURRENCY;

                const showExpiryLabelFields =
                    eligiblePerkTypeForExpiryUI &&
                    (!awardsScreenShapeEnabled || awardsScreenShape === AwardsScreenShapes.DOUBLE_RECTANGLE);

                const showTextAlignmentField = awardsScreenShapeEnabled
                    ? perkType !== AwardTemplate.STAMP_CARD &&
                      awardsScreenShape === AwardsScreenShapes.DOUBLE_RECTANGLE
                    : perkType !== AwardTemplate.STAMP_CARD;

                // eslint-disable-next-line no-nested-ternary
                const showTextColorField = awardsScreenShapeEnabled
                    ? perkType === AwardTemplate.AD_HOC || perkType === AwardTemplate.CLUB
                        ? awardsScreenShape === AwardsScreenShapes.DOUBLE_RECTANGLE
                        : true
                    : true;

                const showAwardsScreenShapeField =
                    awardsScreenShapeEnabled &&
                    (perkType === AwardTemplate.AD_HOC || perkType === AwardTemplate.CLUB);

                const showPerkImageTextFields = awardsScreenShapeEnabled
                    ? (perkType === AwardTemplate.AD_HOC || perkType === AwardTemplate.CLUB) &&
                      awardsScreenShape === AwardsScreenShapes.DOUBLE_RECTANGLE
                    : true;

                const showWalletVisualisationFields =
                    initialValues.walletEnabled ||
                    isNonEmptyString(settings.wallet?.column1) ||
                    isNonEmptyString(settings.wallet?.column2) ||
                    settings.wallet?.detail?.length > 0;

                // eslint-disable-next-line no-nested-ternary
                const getBackgroundImageSizes = awardsScreenShapeEnabled
                    ? perkType === AwardTemplate.CLUB || perkType === AwardTemplate.AD_HOC
                        ? awardBackgroundSizes(awardsScreenShape)
                        : awardBackgroundSizes(undefined)
                    : awardBackgroundSizes(undefined);

                return (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={showWalletVisualisationFields ? 7 : 10}>
                                <Box padding={2} maxWidth={866}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h4">3. Visualisation</Typography>
                                            <Typography variant="body2">
                                                Set up how the Perk will look in your app and wallet.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">Award Visualisation</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Row gutter valign="flex-end">
                                                <PerkPreviewEditable
                                                    label={
                                                        perkType === AwardTemplate.STAMP_CARD
                                                            ? 'No purchase available'
                                                            : undefined
                                                    }
                                                    title={title}
                                                    editable
                                                    perkType={perkType}
                                                    randomizeStampCard={randomizeStampCard}
                                                    stampsPerCard={stampsPerCard ? Number(stampsPerCard) : 6}
                                                    stampColor={stampColor}
                                                    valign={valign}
                                                    stampImageUrl={stampImageUrl}
                                                    textAlign={textAlign}
                                                    image={image}
                                                    backgroundColor={backgroundColor}
                                                    textColor={textColor}
                                                    showExpiry={showExpiry}
                                                    primaryActionBackgroundColour={
                                                        primaryActionBackgroundColour
                                                    }
                                                    expiryLabelAnchorStyleAlign={expiryLabelAnchorStyleAlign}
                                                    expiryLabelAnchorStyleVAlign={
                                                        expiryLabelAnchorStyleVAlign
                                                    }
                                                    showPerkImageTextFields={showPerkImageTextFields}
                                                    awardsScreenShape={
                                                        awardsScreenShapeEnabled
                                                            ? awardsScreenShape
                                                            : undefined
                                                    }
                                                />
                                                {perkType === AwardTemplate.STAMP_CARD && (
                                                    <PerkPreviewEditable
                                                        label="Purchase available"
                                                        cardAvailable
                                                        editable
                                                        title={title}
                                                        perkType={perkType}
                                                        randomizeStampCard={randomizeStampCard}
                                                        stampColor={stampColor}
                                                        stampsPerCard={
                                                            stampsPerCard ? Number(stampsPerCard) : 6
                                                        }
                                                        valign={valign}
                                                        stampImageUrl={stampImageUrl}
                                                        textAlign={textAlign}
                                                        image={image}
                                                        backgroundColor={backgroundColor}
                                                        textColor={textColor}
                                                    />
                                                )}
                                                <Column
                                                    style={{
                                                        width: `calc(100% - ${MuiTheme.spacing(
                                                            perkPreviewWidthByType[perkType]
                                                        )}px)`,
                                                        flexGrow: 1,
                                                        flexShrink: 1,
                                                        maxWidth: '100%'
                                                    }}
                                                >
                                                    {showExpiryLabelFields && showExpiry && (
                                                        <React.Fragment>
                                                            <Typography fontSize={14}>
                                                                Expiry Label Anchor Style
                                                            </Typography>
                                                            <ButtonGroupInputFormField
                                                                name="expiryLabelAnchorStyleAlign"
                                                                options={expiryLabelAlignOptions}
                                                            />
                                                            <ButtonGroupInputFormField
                                                                name="expiryLabelAnchorStyleVAlign"
                                                                options={expiryLabelVAlignOptions}
                                                            />
                                                        </React.Fragment>
                                                    )}
                                                    {showTextAlignmentField && (
                                                        <React.Fragment>
                                                            <Box marginTop={1}>
                                                                <Typography fontSize={14}>
                                                                    Text Alignment
                                                                </Typography>
                                                            </Box>
                                                            <ButtonGroupInputFormField
                                                                name="align"
                                                                options={alignOptions}
                                                            />
                                                            <ButtonGroupInputFormField
                                                                name="valign"
                                                                options={valignOptions}
                                                            />
                                                        </React.Fragment>
                                                    )}
                                                </Column>
                                            </Row>
                                        </Grid>
                                        {showAwardsScreenShapeField && (
                                            <Grid item xs={12} paddingBottom={2}>
                                                <SelectFormField
                                                    name="awardsScreenShape"
                                                    label={
                                                        <MuiFormLabel>Shape on Awards Screen</MuiFormLabel>
                                                    }
                                                    options={awardsScreenShapeOptions}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12} sm={6}>
                                            <ImageFormField
                                                name="image"
                                                label="Background"
                                                aspect={getBackgroundImageSizes.ratio}
                                                description={getBackgroundImageSizes.description}
                                            />
                                        </Grid>
                                        <StyledGrid item xs={12} sm={6}>
                                            <Column className={classes.colours}>
                                                {showTextColorField && (
                                                    <ColorFormField name="textColor" label="Text Colour" />
                                                )}
                                                <ColorFormField
                                                    opacity
                                                    name="backgroundColor"
                                                    label="Background Overlay Colour"
                                                />
                                            </Column>
                                        </StyledGrid>
                                        {showExpiryLabelFields && (
                                            <Grid item xs={12}>
                                                <CheckboxFormField
                                                    name="showExpiry"
                                                    label="Show Expiry Label"
                                                    /* eslint-disable-next-line max-len */
                                                    description="A label on the perk is presented telling the customer when the perk is due to expire based on configured date availability."
                                                />
                                            </Grid>
                                        )}
                                        {showExpiryLabelFields && showExpiry && (
                                            <Stack paddingX={2} marginTop={2}>
                                                <Typography fontSize={14}>
                                                    Expiry Label Description Style
                                                </Typography>

                                                <ButtonGroupInputFormField
                                                    name="expiryLabelDetailStyle"
                                                    options={expiryLabelDetailStyleOptions}
                                                />
                                            </Stack>
                                        )}
                                        {perkType === AwardTemplate.STAMP_CARD && (
                                            <React.Fragment>
                                                <StyledGrid item xs={12}>
                                                    <Row
                                                        flex={1}
                                                        valign="space-around"
                                                        className={classes.colours}
                                                    >
                                                        <ImageFormField
                                                            fullWidth={false}
                                                            name="stampImageUrl"
                                                            label="Stamp"
                                                            aspect={1}
                                                            description="200px by 200px. Max. file size is 1M."
                                                        />
                                                        <Column
                                                            valign="space-around"
                                                            className={clsx(
                                                                classes.colours,
                                                                classes.stampFields
                                                            )}
                                                        >
                                                            <TextFormField
                                                                max={12}
                                                                name="stampsPerCard"
                                                                type="number"
                                                                disabled={isEdit}
                                                                label={
                                                                    <MuiFormLabel required>
                                                                        Stamps per Card
                                                                    </MuiFormLabel>
                                                                }
                                                            />
                                                            <ColorFormField
                                                                name="stampColor"
                                                                label="Stamp Colour"
                                                            />
                                                        </Column>
                                                    </Row>
                                                </StyledGrid>
                                                {stampImageUrl && (
                                                    <Grid item xs={12}>
                                                        <CheckboxFormField
                                                            name="randomizeStampCard"
                                                            label="Randomise Stamp Position"
                                                        />
                                                    </Grid>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                </Box>
                            </Grid>
                            {showWalletVisualisationFields && (
                                <Grid item xs={12} sm={5}>
                                    <Box padding={2} maxWidth={600}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="h5">Wallet</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CheckboxFormField
                                                    name="walletEnabled"
                                                    label="Use Perk Details on Wallet Pass"
                                                />
                                            </Grid>
                                            {walletEnabled && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <TextFormField
                                                            name="walletTitle"
                                                            label={
                                                                <MuiFormLabel required>Title</MuiFormLabel>
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextFormField
                                                            name="walletPriority"
                                                            label={
                                                                <MuiFormLabel required>Priority</MuiFormLabel>
                                                            }
                                                            type="number"
                                                            placeholder="0"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextFormField
                                                            name="walletDescription"
                                                            label={<MuiFormLabel>Description</MuiFormLabel>}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextFormField
                                                            name="walletTerms"
                                                            label={
                                                                <MuiFormLabel>
                                                                    Terms & Conditions
                                                                </MuiFormLabel>
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextFormField
                                                            name="walletLink"
                                                            label={<MuiFormLabel>Web Link</MuiFormLabel>}
                                                            type="url"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ paddingTop: 2 }}>
                                                        <ImageFormField
                                                            fullWidth={false}
                                                            name="walletAppleImageUrl"
                                                            label="Apple Wallet Banner"
                                                            accept={['image/png', 'image/jpeg']}
                                                            aspect={1125 / 339}
                                                            description="1125px by 339px. Max. file size is 1M."
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ paddingTop: 2 }}>
                                                        <ImageFormField
                                                            fullWidth={false}
                                                            name="walletGoogleImageUrl"
                                                            label="Google Wallet Banner"
                                                            accept={['image/png', 'image/jpeg']}
                                                            aspect={1032 / 336}
                                                            description="1032px by 336px. Max. file size is 1M."
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <CheckboxFormField
                                                            name="walletHidden"
                                                            label="Exclude this award from the user's available awards"
                                                        />
                                                    </Grid>
                                                    {[
                                                        AwardTemplate.STAMP_CARD,
                                                        AwardTemplate.PSEUDO_CURRENCY
                                                    ].includes(perkType) && (
                                                        <Grid item xs={12}>
                                                            <CheckboxFormField
                                                                name="walletDisplayPoints"
                                                                label={`Use this award to display the number of ${
                                                                    perkType === AwardTemplate.STAMP_CARD
                                                                        ? 'stamps'
                                                                        : 'points'
                                                                } a user has`}
                                                            />
                                                        </Grid>
                                                    )}
                                                </>
                                            )}
                                        </Grid>
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Row flex={1} gutter align="flex-end">
                                    {isEdit && (
                                        <LoadingButton
                                            loading={isLoading}
                                            disabled={isLoading || !isValid}
                                            onClick={handleUpdate}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            Update & Close
                                        </LoadingButton>
                                    )}
                                    <Button onClick={handlePreviousStep} color="primary" variant="outlined">
                                        Previous
                                    </Button>
                                    <Button onClick={submitForm} color="primary" variant="contained">
                                        Next
                                    </Button>
                                </Row>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </MuiForm>
    );
};
