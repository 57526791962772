import * as React from 'react';
import { useFormikContext } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, Box, Typography, Divider, IconButton, FormControlLabel, styled, Switch } from '@mui/material';

import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { Option } from 'lib/types';
import { isDefined } from 'lib/typeguards';
import { LoyaltyScope } from 'components/loyalty/enums';
import { AdjustmentSchemeRedemptionFullFormData } from './AdjustmentSchemeRedemptionForm';
import { PerkDiscountScopeType } from 'components/loyalty/models/PerkModel';

const basketScopeOptions: Option[] = [
    {
        value: 'ITEM',
        label: 'a specific product'
    },
    {
        value: 'CATEGORY',
        label: 'a specific category'
    },
    {
        value: 'ITEM_OR_CATEGORY',
        label: 'either a product or category'
    },
    {
        value: 'BASKET',
        label: 'nothing specific'
    }
];

const scopeOptionsLabels: Record<PerkDiscountScopeType, string> = {
    ITEM: 'product',
    CATEGORY: 'category',
    BASKET: 'entire basket',
    ITEM_OR_CATEGORY: 'category and/or product',
    CHILD_ITEM: 'modifier product',
    ITEM_AND_CHILD_ITEM: 'product and modifier product'
};

const StyledSelectFormField = styled(SelectFormField)(() => ({
    width: 'auto'
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}));

interface AdjustmentSchemeBasketFormProps {
    isEdit: boolean;
    currencySymbol?: string;
    toggledFields?: string[];
    discountValueString: () => string;
    handleToggleField: (field: string) => () => void;
}

export const AdjustmentSchemeBasketForm: React.FC<AdjustmentSchemeBasketFormProps> = ({
    isEdit,
    currencySymbol,
    toggledFields,
    discountValueString,
    handleToggleField
}) => {
    const { values, setFieldValue } = useFormikContext<AdjustmentSchemeRedemptionFullFormData>();

    const handleBasketScopeSelect = React.useCallback(
        (value: any) => {
            if (value === LoyaltyScope.BASKET) {
                setFieldValue('basketQualification.matchCode', undefined);
            }
        },
        [setFieldValue]
    );

    return (
        <>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" flexDirection="column" marginBottom={1.5}>
                    <StyledTitle>2.2 Setting up the basket qualification</StyledTitle>
                    <Typography variant="caption">
                        This section controls what items you must have in your basket, alongside the
                        discountable items to be able to qualify for the adjustment scheme
                    </Typography>
                </Box>
                <Grid container spacing={1.5}>
                    {!isEdit ? (
                        <Grid item xs={12}>
                            <SwitchFormField
                                name="basketQualification.disableBasketQualification"
                                disabled={isEdit}
                                label={<MuiFormLabel>Do not set basket qualification</MuiFormLabel>}
                            />
                        </Grid>
                    ) : (
                        values.basketQualification?.disableBasketQualification && (
                            <Grid item xs={12}>
                                <SwitchFormField
                                    name="basketQualification.disableBasketQualification"
                                    disabled={isEdit}
                                    label={<MuiFormLabel>Do not set basket qualification</MuiFormLabel>}
                                />
                            </Grid>
                        )
                    )}
                    {!values.basketQualification?.disableBasketQualification && (
                        <>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center" columnGap="4px">
                                    <Typography variant="body2">
                                        {discountValueString()} will only be redeemable if you have
                                    </Typography>
                                    <Box>
                                        <StyledSelectFormField
                                            options={basketScopeOptions}
                                            name="basketQualification.scope"
                                            onSelect={handleBasketScopeSelect}
                                        />
                                    </Box>
                                    <Typography variant="body2">in the basket</Typography>
                                </Box>
                            </Grid>

                            {isDefined(values.basketQualification?.scope) &&
                                values.basketQualification.scope !== LoyaltyScope.BASKET && (
                                    <Grid item xs={12}>
                                        <Box display="inline" lineHeight="2">
                                            <Typography variant="body2" display="inline">
                                                Assign the adjustment scheme code &ldquo;
                                            </Typography>
                                            <Box width={250} display="inline-block">
                                                <TextFormField
                                                    name="basketQualification.matchCode"
                                                    placeholder={'e.g. buy 2 mains, buy 2 coffees'}
                                                    multiline={true}
                                                    maxRows={4}
                                                />
                                            </Box>
                                            <Typography variant="body2" display="inline">
                                                &rdquo; to the{' '}
                                                {scopeOptionsLabels[values.basketQualification.scope]} in your
                                                menu via the menu manager page under the Perks column in the
                                                table
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                            {(isDefined(values.basketQualification?.matchCode) ||
                                (isDefined(values.basketQualification?.scope) &&
                                    values.basketQualification.scope === LoyaltyScope.BASKET)) && (
                                <>
                                    <Grid item xs={12}>
                                        {toggledFields?.includes('basketQualification.minimumValue') ? (
                                            <Box display="flex">
                                                <Box display="flex" alignItems="center" columnGap="4px">
                                                    <Typography variant="body2">
                                                        The{' '}
                                                        {values.basketQualification.scope ===
                                                        LoyaltyScope.BASKET
                                                            ? 'basket'
                                                            : 'product(s)'}{' '}
                                                        must have a minimum cost of {currencySymbol}
                                                    </Typography>
                                                    <Box width={44}>
                                                        <TextFormField
                                                            name="basketQualification.minimumValue"
                                                            type="number"
                                                        />
                                                    </Box>
                                                </Box>
                                                <IconButton
                                                    onClick={handleToggleField(
                                                        'basketQualification.minimumValue'
                                                    )}
                                                    aria-label="delete"
                                                    size="small"
                                                >
                                                    <DeleteIcon fontSize="inherit" />
                                                </IconButton>
                                            </Box>
                                        ) : (
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        onChange={handleToggleField(
                                                            'basketQualification.minimumValue'
                                                        )}
                                                    />
                                                }
                                                label="Set a minimum value on the product or basket"
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {toggledFields?.includes('basketQualification.minimumItems') ? (
                                            <Box display="flex" alignItems="center">
                                                <Box display="flex" alignItems="center" columnGap="4px">
                                                    <Typography variant="body2">
                                                        There must be a minimum of
                                                    </Typography>
                                                    <Box width={44}>
                                                        <TextFormField
                                                            name="basketQualification.minimumItems"
                                                            type="number"
                                                        />
                                                    </Box>
                                                    <Typography variant="body2">
                                                        product(s) in the basket
                                                    </Typography>
                                                </Box>
                                                <IconButton
                                                    onClick={handleToggleField(
                                                        'basketQualification.minimumItems'
                                                    )}
                                                    aria-label="delete"
                                                    size="small"
                                                >
                                                    <DeleteIcon fontSize="inherit" />
                                                </IconButton>
                                            </Box>
                                        ) : (
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        onChange={handleToggleField(
                                                            'basketQualification.minimumItems'
                                                        )}
                                                    />
                                                }
                                                label="Set a minimum number of products"
                                            />
                                        )}
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );
};
