import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { tenantAccessApi } from 'components/merchant/tenantAccessApi';
import { isTenantAccessItems } from 'components/merchant/models/TenantAccessData';
import { Role } from 'components/merchant/models/Role';
import { ApplicationState } from 'store/store';
import { TenantAccess, TenantAccessState } from 'components/merchant/models/TenantAccess';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { getAccess } from 'store/auth/authActions';
import { permission, Resource, Permission } from '@pepperhq/auth-client';
import { ANALYTICS, LOCATIONS, TERMINAL } from 'config/routes';
import logger from 'lib/logger';
import { notifyOtherTabsMerchantChange } from 'app/multi-tab-bus';

export const TENANT_ACCESS_ITEMS_PER_PAGE = 100;

export const useTenantAccess = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { claims } = useSelector((state: ApplicationState) => state.auth);

    const [tenantAccess, setTenantAccess] = React.useState<TenantAccess[]>([]);
    const [nextKey, setNextKey] = React.useState<string | undefined>(undefined);
    const [page, setPage] = React.useState(0);
    const [isTenantsLoading, setIsTenantsLoading] = React.useState(true);

    const [isTenantSelected, setIsTenantSelected] = React.useState(false);
    const [hasAttemptedSoloAccess, setHasAttemptedSoloAccess] = React.useState(false);
    const [isAccessLoading, setIsAccessLoading] = React.useState(false);

    const [roles, setRoles] = React.useState<Role[]>([]);

    const updateTenantAccess = React.useCallback(
        (tenantAccess: TenantAccess[]) => {
            setTenantAccess(tenantAccess);
        },
        [setTenantAccess]
    );

    const updateNextKey = React.useCallback(
        (nextKey: string | undefined) => {
            setNextKey(nextKey);
        },
        [setNextKey]
    );

    const updatePage = React.useCallback(
        (page: number) => {
            setPage(page);
        },
        [setPage]
    );

    const handleAccessClick = React.useCallback(
        (id: string) => async () => {
            setIsAccessLoading(true);

            const result = await tenantAccessApi.createNewToken(id);
            if (result.ok) {
                await getAccess(result.body.token)(dispatch);
                setIsTenantSelected(true);
            } else {
                enqueueSnackbar(result.body.message, { variant: 'error' });
            }
            notifyOtherTabsMerchantChange();

            setIsAccessLoading(false);
        },
        [dispatch]
    );

    const handlePageChange = React.useCallback(
        async (_: never, newPage: number) => {
            if (!isTenantsLoading) {
                setPage(newPage);

                if (nextKey && (newPage + 1) * TENANT_ACCESS_ITEMS_PER_PAGE > tenantAccess?.length) {
                    setIsTenantsLoading(true);

                    const requestBody: Record<string, string | number> = {
                        startKey: nextKey,
                        limit: TENANT_ACCESS_ITEMS_PER_PAGE
                    };

                    const { tenantAccess: newtenantAccess, nextKey: newNextKey } = await getTenantAccessData(
                        requestBody
                    );

                    setTenantAccess([...tenantAccess, ...newtenantAccess]);
                    setNextKey(newNextKey);
                    setIsTenantsLoading(false);
                }
            }
        },
        [isTenantsLoading, nextKey, tenantAccess]
    );

    // Fetch initial data and roles
    React.useEffect(() => {
        const fetchData = async () => {
            const tenantAccessData = await getTenantAccessData({
                limit: TENANT_ACCESS_ITEMS_PER_PAGE,
                states: TenantAccessState.ACTIVE
            });

            const rolesData = await getRoles();

            if (tenantAccessData) {
                setTenantAccess(tenantAccessData.tenantAccess);
                setNextKey(tenantAccessData.nextKey);
            }

            if (rolesData) {
                setRoles(rolesData.roles);
            }

            setIsTenantsLoading(false);
        };

        fetchData();
    }, [handleAccessClick]);

    // If there is only one tenant, automatically access it
    React.useEffect(() => {
        const accessSoloMerchant = async () => {
            if (!hasAttemptedSoloAccess && tenantAccess.length) {
                setHasAttemptedSoloAccess(true);

                if (tenantAccess.length === 1) {
                    await handleAccessClick(tenantAccess[0].id)();
                }
            }
        };

        accessSoloMerchant();
    }, [hasAttemptedSoloAccess, handleAccessClick, tenantAccess]);

    // Upon selection of a tenant, redirect to the appropriate page
    React.useEffect(() => {
        if (isTenantSelected) {
            setIsTenantSelected(false);

            if (claims.hasPermissions(permission(Resource.Location, Permission.read))) {
                history.push(LOCATIONS);
            } else if (claims.hasPermissions(permission(Resource.Analytics, Permission.read))) {
                history.push(ANALYTICS);
            } else if (claims.hasPermissions(permission(Resource.TerminalAccess, Permission.read))) {
                history.push(TERMINAL);
            }
        }
    }, [claims, history, isTenantSelected]);

    return {
        tenantAccess,
        nextKey,
        page,
        isTenantsLoading,
        isAccessLoading,
        roles,
        updateTenantAccess,
        updateNextKey,
        updatePage,
        handleAccessClick,
        handlePageChange
    };
};

const getRoles = async (): Promise<{ roles: Role[]; error?: string }> => {
    try {
        const response = await tenantAccessApi.getRolesList();
        if (response.ok) {
            return { roles: response.body.items };
        }
        throw new Error(response.body.message);
    } catch (error) {
        return { error, roles: [] };
    }
};

export const getTenantAccessData = async (options?: { [key: string]: string | number }) => {
    try {
        const response = await tenantAccessApi.getList(options);
        if (response.ok) {
            if (isTenantAccessItems(response.body)) {
                return { tenantAccess: response.body.items, nextKey: response.body.page.nextKey };
            }
            throw new Error(`Expected TenantAccess but got ${response.body}`);
        }
        throw new Error(response.body.message);
    } catch (error) {
        logger.error(error);
        return error;
    }
};
