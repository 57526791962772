import React from 'react';
import { FormControl, TextFieldVariants } from '@mui/material';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { useLocalDateTimeFormat } from 'lib/hooks/useLocalDateTimeFormat';

interface DateTimePickerFormFieldProps {
    label?: React.ReactNode;
    description?: string;
    variant?: TextFieldVariants;
    readOnly?: boolean;
    disabled?: boolean;
    disableOpenPicker?: boolean;
    disablePast?: boolean;
    minutesStep?: number;
    disableIgnoringDatePartForTimeValidation?: boolean;
}

export const DateTimePickerFormField = ({
    label,
    description,
    variant,
    readOnly,
    disabled,
    disableOpenPicker,
    disablePast,
    minutesStep,
    disableIgnoringDatePartForTimeValidation,
    ...props
}: FieldAttributes<DateTimePickerFormFieldProps>): JSX.Element => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <DateTimePickerFormFieldComponent
                label={label}
                description={description}
                variant={variant}
                readOnly={readOnly}
                disabled={disabled}
                disableOpenPicker={disableOpenPicker}
                disablePast={disablePast}
                minutesStep={minutesStep}
                disableIgnoringDatePartForTimeValidation={disableIgnoringDatePartForTimeValidation}
                {...childProps}
            />
        )}
    </Field>
);

const DateTimePickerFormFieldComponent = ({
    field,
    meta,
    label,
    description,
    readOnly,
    variant,
    disabled,
    disableOpenPicker,
    disablePast,
    minutesStep,
    disableIgnoringDatePartForTimeValidation
}: FieldProps & DateTimePickerFormFieldProps): JSX.Element => {
    const { getUserDateFormat } = useLocalDateTimeFormat();
    const [value, setValue] = React.useState<Date | null>(
        meta.initialValue ? new Date(meta.initialValue) : null
    );
    const isError = meta.touched && !!meta.error;
    const handleChange = React.useCallback(
        (value: Date) => {
            setValue(value);
            field.onChange({ target: { name: field.name, value } });
        },
        [field]
    );
    return (
        <FormControl error={isError} fullWidth>
            <DateTimePicker
                label={label}
                onChange={handleChange}
                value={value}
                readOnly={readOnly}
                disabled={disabled}
                disableOpenPicker={disableOpenPicker}
                disablePast={disablePast}
                minutesStep={minutesStep}
                disableIgnoringDatePartForTimeValidation={disableIgnoringDatePartForTimeValidation}
                defaultValue={null}
                format={`${getUserDateFormat()} hh:mm aa`}
                viewRenderers={{
                    hours: null,
                    minutes: null,
                    seconds: null
                }}
                slotProps={{
                    field: {
                        label,
                        disabled,
                        clearable: true,
                        shouldRespectLeadingZeros: true
                    },
                    textField: {
                        helperText: isError ? meta.error : description,
                        error: isError,
                        variant
                    }
                }}
            />
        </FormControl>
    );
};
