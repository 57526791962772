import {
    NotificationReceiptsFilter,
    NotificationReceiptsFilterChange
} from 'components/notification/filters/NotificationReceiptFilters';
import { useHistoryFilters } from 'lib/hooks/useHistoryFilters';
import React from 'react';
import { QueryParameter } from 'lib/HttpClient';

export function useNotificationReceiptsFilter(
    showNotificationIdFilter: boolean,
    notificationId?: string
): [NotificationReceiptsFilter, NotificationReceiptsFilterChange, boolean] {
    const { search, onSearch } = useHistoryFilters();
    const [filter, setFilter] = React.useState<NotificationReceiptsFilter>(() => ({
        ...((notificationId || showNotificationIdFilter) && {
            notificationId: notificationId || search.notificationId
        }),
        userId: search.userId || undefined,
        status: search.status || undefined
    }));
    const onFilterChange = React.useCallback<NotificationReceiptsFilterChange>((field, value) => {
        setFilter(currentFilter => ({ ...currentFilter, [field]: value }));
    }, []);
    React.useEffect(() => {
        const { notificationId, ...rest } = filter;
        onSearch({
            ...(showNotificationIdFilter && { notificationId }),
            ...rest
        });
    }, [filter, onSearch, showNotificationIdFilter]);

    const isEmpty = React.useMemo(
        () => (!showNotificationIdFilter || !filter.notificationId) && !filter.userId && !filter.status,
        [filter, showNotificationIdFilter]
    );
    return [filter, onFilterChange, isEmpty];
}

export const getNotificationReceiptsFilterQuery = (filter: NotificationReceiptsFilter): QueryParameter[] => {
    const queryParameters = [];
    if (filter.userId) {
        queryParameters.push({ key: 'userId', value: filter.userId });
    }
    if (filter.status) {
        queryParameters.push({ key: 'status', value: filter.status });
    }
    return queryParameters;
};
