import React from 'react';
import { Audience, AudienceRow } from '../models/segmentModel';
import {
    AudienceMetricId,
    AudienceMetricTimeWindow,
    AudienceMetricType,
    getAudienceMetricProperties
} from '../models/metrics';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { getAllAudiences } from 'store/audiences/audiencesActions';

const metricIds: AudienceMetricId[] = (() => {
    const ids: AudienceMetricId[] = [];
    Object.values(AudienceMetricType).forEach(metricType => {
        Object.values(AudienceMetricTimeWindow).forEach(metricTimeWindow => {
            ids.push(`${metricType}_${metricTimeWindow}`);
        });
    });
    return ids;
})();

export function useAudiences() {
    const dispatch = useDispatch();
    const { audiences, isLoading } = useSelector((state: ApplicationState) => state.audiences);
    const itemFormatter = React.useCallback<(audience: Audience) => AudienceRow>(audience => {
        const metricProperties = getAudienceMetricProperties(audience);
        const formattedAudience: AudienceRow = {
            ...audience,
            id: audience._id,
            ...metricProperties
        };
        return formattedAudience;
    }, []);
    const formattedAudiences = React.useMemo(() => {
        if (!audiences) {
            return [];
        }
        return audiences.map(itemFormatter);
    }, [audiences, itemFormatter]);
    React.useEffect(() => {
        getAllAudiences({ count: true, metrics: true })(dispatch);
    }, [dispatch]);
    const presentMetrics = React.useMemo(
        () => metricIds.filter(item => formattedAudiences.some(row => !!row[item])),
        [formattedAudiences]
    );
    return { audiences: formattedAudiences, loading: isLoading, presentMetrics };
}
