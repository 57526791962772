import React from 'react';
import { Grid, Popover, PopoverVirtualElement, TextField } from '@mui/material';
import { DateRangePickerValue, MuiDateRangePicker, MuiDateRangePickerProps } from 'ui/MuiDateRangePicker';
import { RequiredStar } from './form/RequiredStar';
import { formatDate } from './timeHelpers';

export type DateRangePickerInputLabels = { startDate?: React.ReactNode; endDate?: React.ReactNode };
export type DateRangePickerData = { startDate?: Date; endDate?: Date };

type DateRangePickerInputProps = MuiDateRangePickerProps & {
    labels?: DateRangePickerInputLabels;
    required?: true;
    onChange: (value: DateRangePickerValue) => void;
};

export const DateRangePickerInput: React.FC<DateRangePickerInputProps> = props => {
    const { onChange, required, labels, ...rest } = props;
    const [dateRangePickerPopoverOpen, setDateRangePickerPopoverOpen] = React.useState(false);
    const [start, setStart] = React.useState(rest.value ? formatDate(rest.value.startDate) : '');
    const [end, setEnd] = React.useState(rest.value ? formatDate(rest.value.endDate) : '');
    const [anchorElement, setAnchorElement] = React.useState<HTMLElement>();

    const startDateLabel = labels && labels.startDate ? labels.startDate : 'Start Date';
    const endDateLabel = labels && labels.endDate ? labels.endDate : 'End Date';
    const handleClick = React.useCallback((event: React.MouseEvent<HTMLInputElement>) => {
        setAnchorElement(event.currentTarget);
        setDateRangePickerPopoverOpen(true);
    }, []);
    const handleClose = React.useCallback(() => setDateRangePickerPopoverOpen(false), []);
    const handleChange = React.useCallback(
        value => {
            if (value.startDate && value.endDate) {
                setDateRangePickerPopoverOpen(false);
                setStart(`${formatDate(value.startDate)}`);
                setEnd(`${formatDate(value.endDate)}`);
                value.startDate.setHours(0, 0, 0, 0);
                value.endDate.setHours(0, 0, 0, 0);
                onChange(value);
            }
        },
        [onChange]
    );

    return (
        <React.Fragment>
            <Grid container style={{ padding: 0 }} spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        label={
                            <React.Fragment>
                                {startDateLabel}
                                {required && <RequiredStar />}
                            </React.Fragment>
                        }
                        required={required}
                        InputLabelProps={{ shrink: !!start }}
                        value={start}
                        contentEditable={false}
                        onClick={handleClick}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={
                            <React.Fragment>
                                {endDateLabel}
                                {required && <RequiredStar />}
                            </React.Fragment>
                        }
                        required={required}
                        InputLabelProps={{ shrink: !!end }}
                        value={end}
                        contentEditable={false}
                        onClick={handleClick}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <DateRangePickerInputPopover
                anchorElement={anchorElement}
                open={dateRangePickerPopoverOpen}
                onClose={handleClose}
                onChange={handleChange}
                {...rest}
            />
        </React.Fragment>
    );
};

type DateRangePickerInputPopoverProps = MuiDateRangePickerProps & {
    open: boolean;
    onClose?: () => void;
    anchorElement: null | Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement);
};

const DateRangePickerInputPopover: React.FunctionComponent<DateRangePickerInputPopoverProps> = props => {
    const { anchorElement, open, onClose: _onClose, ...rest } = props;

    return (
        <Popover
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            anchorEl={anchorElement}
            open={open}
            onClose={props.onClose}
        >
            <MuiDateRangePicker {...rest} />
        </Popover>
    );
};
