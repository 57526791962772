import { GridCellParams } from '@mui/x-data-grid-pro';
import { MenuOverrideSchema } from '@pepperhq/menu-sdk';
import { menuOverridesApi } from 'components/menu/MenuOverridesApi';
import {
    MESSAGE_PRODUCT_AVAILABILITY_COPY_ERROR,
    MESSAGE_PRODUCT_AVAILABILITY_COPY_SUCCESS
} from 'config/messages';
import logger from 'lib/logger';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { locationApi } from '../LocationApi';
import { LocationsPickerModal } from '../modal/LocationsPickerModal';
import { Location } from '../models/LocationModel';
import { ProductOverrideEditConst, ProductOverrideEditKeys } from '../models/Overrides';

export function useOverrideCopyCell() {
    const dispatch = useDispatch();
    const { locationId } = useParams<{ locationId: string }>();
    const [locationsLoading, setLocationsLoading] = React.useState(true);
    const [allLocations, setAllLocations] = React.useState<Location[]>([]);
    const [selectedCell, setSelectedCell] = React.useState<GridCellParams | null>(null);
    const [cellToCopy, setCellToCopy] = React.useState<GridCellParams | null>(null);
    const [copyLoading, setCopyLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleCellSelection = React.useCallback((params: GridCellParams) => {
        setTimeout(() => {
            if (params.field === 'availableOverride' && params.row.type === 'PRODUCT') {
                setSelectedCell(params);
            } else {
                setSelectedCell(null);
            }
        }, 0);
    }, []);
    const handleCellBlur = React.useCallback(() => {
        setSelectedCell(null);
    }, []);
    const locationsExcludingCurrent = React.useMemo(
        () => allLocations.filter(locationItem => locationItem._id !== locationId),
        [allLocations, locationId]
    );
    const handleModalOpen = React.useCallback(() => {
        setOpen(true);
        setCellToCopy(selectedCell);
    }, [selectedCell]);
    const handleModalClose = React.useCallback(() => {
        setOpen(false);
        setSelectedCell(null);
    }, []);
    React.useEffect(() => {
        setLocationsLoading(true);
        locationApi
            .getList()
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to get merchant locations');
                }
                setAllLocations(response.body.locations);
            })
            .catch(e => {
                logger.error(e.message);
            })
            .finally(() => {
                setLocationsLoading(false);
            });
    }, []);
    const handleCopyTo = React.useCallback(
        async (locationIds: string[]) => {
            if (cellToCopy) {
                setCopyLoading(true);
                try {
                    const updateBody = {
                        update: {
                            products: {
                                [cellToCopy.row.id]: {
                                    availableOverride:
                                        cellToCopy.value === ''
                                            ? null
                                            : ProductOverrideEditConst.values[
                                                  cellToCopy.value as ProductOverrideEditKeys
                                              ]
                                }
                            },
                            schema: MenuOverrideSchema.v6
                        },
                        locationIds
                    };
                    const result = await menuOverridesApi.bulkUpdateMenuOverride(updateBody);
                    if (!result.ok) {
                        throw new Error(result.body.message);
                    }
                    dispatch(
                        enqueueSnackbar(MESSAGE_PRODUCT_AVAILABILITY_COPY_SUCCESS, { variant: 'success' })
                    );
                } catch (e) {
                    logger.error(e);
                    dispatch(enqueueSnackbar(MESSAGE_PRODUCT_AVAILABILITY_COPY_ERROR, { variant: 'error' }));
                }
                setCopyLoading(false);
                setOpen(false);
            }
        },
        [dispatch, cellToCopy]
    );
    const renderLocationPickerModal = React.useCallback(
        () => (
            <LocationsPickerModal
                locations={locationsExcludingCurrent}
                open={open}
                onClose={handleModalClose}
                onSubmit={handleCopyTo}
                loading={locationsLoading || copyLoading}
            />
        ),
        [copyLoading, handleCopyTo, handleModalClose, locationsExcludingCurrent, locationsLoading, open]
    );
    return {
        selectedCell,
        handleCellSelection,
        locationsExcludingCurrent,
        handleCellBlur,
        renderLocationPickerModal,
        openModal: handleModalOpen,
        copyLoading
    };
}
