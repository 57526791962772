import React from 'react';
import { Box, Button, Popover, PopoverVirtualElement, Typography } from '@mui/material';
import { Column, Row } from 'ui/Flex';
import { DateRangePickerValue, MuiDateRangePicker } from 'ui/MuiDateRangePicker';
import { add, isAfter, isBefore } from 'date-fns';
import { DateRange, PickersShortcutsItem } from '@mui/x-date-pickers-pro';

interface CustomersTimelineDateFilterProps {
    value: DateRangePickerValue;
    onSelect: (value?: DateRangePickerValue) => void;
    anchorElement: null | Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement);
    maxDate?: Date;
    minDate?: Date;
    onClose: () => void;
    shouldDisableDate?: (date: Date) => boolean;
    onChange?: (date: DateRangePickerValue) => void;
    disabled?: boolean;
    maxRangeMonths?: number;
    shortcuts?: PickersShortcutsItem<DateRange<Date>>[];
}

const CustomersTimelineDateFilterContent: React.FC<CustomersTimelineDateFilterProps> = ({
    value,
    onSelect,
    onChange,
    onClose,
    maxDate,
    minDate,
    shouldDisableDate,
    disabled,
    maxRangeMonths,
    shortcuts
}) => {
    const [date, setDate] = React.useState(value);
    const handleChange = React.useCallback(
        (date: DateRangePickerValue) => {
            setDate(date);
            if (onChange) {
                onChange(date);
            }
        },
        [onChange]
    );
    const handleConfirm = React.useCallback(() => {
        onClose();
        onSelect(date);
    }, [onSelect, onClose, date]);
    const isValidEndDate = React.useMemo(
        () => (maxDate ? isBefore(new Date(date?.endDate), new Date(maxDate)) : true),
        [date?.endDate, maxDate]
    );
    const isValidStartDate = React.useMemo(
        () => (minDate ? isAfter(new Date(date?.startDate), new Date(minDate)) : true),
        [minDate, date?.startDate]
    );
    const isRangeValid = React.useMemo(
        () =>
            maxRangeMonths
                ? isBefore(
                      new Date(date?.endDate),
                      add(new Date(date?.startDate), { months: maxRangeMonths })
                  )
                : true,
        [date?.endDate, date?.startDate, maxRangeMonths]
    );
    return (
        <Column>
            <MuiDateRangePicker
                maxDate={maxDate}
                minDate={minDate}
                shouldDisableDate={shouldDisableDate}
                disableFuture
                value={date}
                disabled={disabled}
                onChange={handleChange}
                shortcuts={shortcuts}
            />
            <Row flex={1} align="flex-end">
                {!isRangeValid && (
                    <Box px={1}>
                        <Typography color="error">
                            You cannot select more than {maxRangeMonths} months
                        </Typography>
                    </Box>
                )}
            </Row>
            <Row flex={1} align="flex-end">
                <Box padding={1}>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                </Box>
                <Box padding={1} paddingLeft={0}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirm}
                        disabled={disabled || !isValidEndDate || !isValidStartDate || !isRangeValid}
                    >
                        Confirm
                    </Button>
                </Box>
            </Row>
        </Column>
    );
};

export const CustomersTimelineDateFilter: React.FC<CustomersTimelineDateFilterProps> = props => (
    <Popover anchorEl={props.anchorElement} open={!!props.anchorElement} onClose={props.onClose}>
        <CustomersTimelineDateFilterContent {...props} />
    </Popover>
);
