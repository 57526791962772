import React from 'react';
import { BaseSelectProps, MenuItem, Select } from '@mui/material';

interface SearchOption {
    label: string;
    value: string;
}

export interface TableSearchOptionsProps extends Omit<BaseSelectProps<SearchOption>, 'value'> {
    items: SearchOption[];
    value: string;
}

export const TableSearchOptions: React.FC<TableSearchOptionsProps> = ({ items, onChange, value }) => (
    <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        value={value || items[0]}
        onChange={onChange}
        variant="standard"
        margin="none"
    >
        {items.map(({ value, label }) => (
            <MenuItem key={label} value={value}>
                {label}
            </MenuItem>
        ))}
    </Select>
);
