import * as React from 'react';
import { useFormikContext } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, Box, Typography, Divider, IconButton, FormControlLabel, styled, Switch } from '@mui/material';
import { DatePicker, DatePickerSlotProps } from '@mui/x-date-pickers-pro';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { weekdays } from 'components/perks/award-enrichments/consts';
import { AdjustmentSchemeRedemptionFullFormData } from './AdjustmentSchemeRedemptionForm';
import { useLocalDateTimeFormat } from 'lib/hooks/useLocalDateTimeFormat';
import { DateTimeOptionValue } from 'components/perks/forms/RedemptionForm/PerkRedemptionForm';

const StyledSelectFormField = styled(SelectFormField)(() => ({
    width: 'auto'
}));

const StyledText = styled(Typography)(() => ({
    whiteSpace: 'nowrap'
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}));

interface AdjustmentSchemeDateTimeFormProps {
    discountValueString: () => string;
}

export const AdjustmentSchemeDateTimeForm: React.FC<AdjustmentSchemeDateTimeFormProps> = ({
    discountValueString
}) => {
    const { values, touched, errors, setFieldValue } =
        useFormikContext<AdjustmentSchemeRedemptionFullFormData>();

    const { getUserDateFormat } = useLocalDateTimeFormat();

    const inputFormat = React.useMemo(getUserDateFormat, [getUserDateFormat]);

    const getDatepickerSlots = React.useCallback(
        (error: string): DatePickerSlotProps<Date, false> => ({
            textField: { variant: 'standard', helperText: error, error: !!error, sx: { mr: 0.5 } }
        }),
        []
    );

    const handleDateFieldChange = React.useCallback(
        (fieldName: string) => (date: Date) => {
            setFieldValue(fieldName, date ? new Date(date) : undefined);
        },
        [setFieldValue]
    );

    const toggleDateOption = React.useCallback(
        (option: DateTimeOptionValue) => () => {
            const newDateTimeRestriction = { ...values.dateTimeRestriction };

            if (newDateTimeRestriction.dateTimeRestrictionOption?.includes(option)) {
                switch (option) {
                    case 'date':
                        newDateTimeRestriction.startDate = undefined;
                        newDateTimeRestriction.endDate = undefined;
                        break;
                    case 'time':
                        newDateTimeRestriction.startTime = undefined;
                        newDateTimeRestriction.endTime = undefined;
                        break;
                    case 'day':
                        newDateTimeRestriction.daysOfWeek = [];
                        break;
                    default:
                        break;
                }
                newDateTimeRestriction.dateTimeRestrictionOption =
                    newDateTimeRestriction.dateTimeRestrictionOption.filter(
                        (value: DateTimeOptionValue) => value !== option
                    );
            } else {
                newDateTimeRestriction.dateTimeRestrictionOption = [
                    ...(newDateTimeRestriction.dateTimeRestrictionOption ?? []),
                    option
                ];
            }

            setFieldValue('dateTimeRestriction', newDateTimeRestriction);
        },
        [values.dateTimeRestriction, setFieldValue]
    );

    return (
        <>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" flexDirection="column" marginBottom={1.5}>
                    <StyledTitle>2.3 Setting up specific time / date availability</StyledTitle>
                    <Typography variant="caption">
                        This section controls when an adjustment scheme will be available to the user to
                        redeem in app. If nothing is set, this adjustment scheme will always be available
                    </Typography>
                </Box>
                <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                        {values.dateTimeRestriction.dateTimeRestrictionOption.includes('date') ? (
                            <Box display="flex" alignItems="center">
                                <Box display="flex" alignItems="center" columnGap="4px">
                                    <StyledText variant="body2">
                                        {discountValueString()} will be available between{''}
                                    </StyledText>
                                    <Box flexShrink={0} width="128px">
                                        <DatePicker
                                            value={
                                                values.dateTimeRestriction.startDate
                                                    ? new Date(values.dateTimeRestriction.startDate)
                                                    : null
                                            }
                                            onChange={handleDateFieldChange('dateTimeRestriction.startDate')}
                                            slotProps={getDatepickerSlots(
                                                touched?.dateTimeRestriction?.startDate &&
                                                    errors?.dateTimeRestriction?.startDate
                                            )}
                                            format={inputFormat}
                                        />
                                    </Box>
                                    <StyledText variant="body2"> and </StyledText>
                                    <Box flexShrink={0} width="128px">
                                        <DatePicker
                                            value={
                                                values.dateTimeRestriction.endDate
                                                    ? new Date(values.dateTimeRestriction.endDate)
                                                    : null
                                            }
                                            onChange={handleDateFieldChange('dateTimeRestriction.endDate')}
                                            slotProps={getDatepickerSlots(
                                                touched?.dateTimeRestriction?.endDate &&
                                                    errors?.dateTimeRestriction?.endDate
                                            )}
                                            format={inputFormat}
                                        />
                                    </Box>
                                </Box>
                                <IconButton
                                    onClick={toggleDateOption('date')}
                                    aria-label="delete"
                                    size="small"
                                >
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                            </Box>
                        ) : (
                            <FormControlLabel
                                control={<Switch onChange={toggleDateOption('date')} />}
                                label="Add date range"
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {values.dateTimeRestriction.dateTimeRestrictionOption.includes('time') ? (
                            <Box display="flex" alignItems="center">
                                <Box display="flex" alignItems="center" columnGap="4px">
                                    <StyledText variant="body2">
                                        {discountValueString()} will be available between{''}
                                    </StyledText>
                                    <Box flexShrink={0} width="110px">
                                        <TextFormField
                                            name="dateTimeRestriction.startTime"
                                            type="time"
                                            shrink
                                        />
                                    </Box>
                                    <StyledText variant="body2"> and </StyledText>
                                    <Box flexShrink={0} width="110px">
                                        <TextFormField
                                            name="dateTimeRestriction.endTime"
                                            type="time"
                                            shrink
                                        />
                                    </Box>
                                </Box>
                                <IconButton
                                    onClick={toggleDateOption('time')}
                                    aria-label="delete"
                                    size="small"
                                >
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                            </Box>
                        ) : (
                            <FormControlLabel
                                control={<Switch onChange={toggleDateOption('time')} />}
                                label="Add time range"
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {values.dateTimeRestriction.dateTimeRestrictionOption.includes('day') ? (
                            <Box display="flex" alignItems="center">
                                <Box display="flex" alignItems="center" columnGap="4px">
                                    <StyledText variant="body2">
                                        {discountValueString()} will be available between{''}
                                    </StyledText>
                                    <Box flexShrink={0}>
                                        <StyledSelectFormField
                                            options={weekdays}
                                            multiple
                                            name="dateTimeRestriction.daysOfWeek"
                                        />
                                    </Box>
                                </Box>
                                <IconButton
                                    onClick={toggleDateOption('day')}
                                    aria-label="delete"
                                    size="small"
                                >
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                            </Box>
                        ) : (
                            <FormControlLabel
                                control={<Switch onChange={toggleDateOption('day')} />}
                                label="Add specific day(s)"
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
