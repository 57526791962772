import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { Option } from 'lib/types';
import { goodtillPriceSourceOptions } from './goodtill';

export function GoodtillSettingsEdit({ locationId, posLocations }: IPointOfSaleSettingsProps) {
    const { perks } = useSelector((state: ApplicationState) => state.perks);

    const posLocationOptions = React.useMemo(
        () =>
            posLocations?.map(posLocation => ({
                label: `${posLocation.name} (${posLocation.externalLocationId})`,
                value: posLocation.externalLocationId
            })) ?? [],
        [posLocations]
    );
    const perkOptions: Option[] = React.useMemo(
        () =>
            perks.map(perk => ({
                label: perk.title,
                value: perk._id
            })),
        [perks]
    );

    return (
        <>
            {/* Common Only */}
            {locationId === undefined && (
                <>
                    <SettingDisplay label="Verification Token">
                        <TextFormField name="goodtillVerificationToken" />
                    </SettingDisplay>
                    <SettingDisplay label="Loyalty At POS Perk">
                        <SelectFormField options={perkOptions} name="goodtillLoyaltyAtPOSPerkId" />
                    </SettingDisplay>
                </>
            )}
            {/* Location Only */}
            {locationId !== undefined && (
                <SettingDisplay label="Outlet Id">
                    <SelectFormField options={posLocationOptions} name="goodtillOutletId" />
                </SettingDisplay>
            )}
            {/* Both */}
            <SettingDisplay label="Subdomain">
                <TextFormField name="goodtillSubdomain" />
            </SettingDisplay>
            <SettingDisplay label="Username">
                <TextFormField name="goodtillUsername" />
            </SettingDisplay>
            <SettingDisplay label="Password">
                <TextFormField name="goodtillPassword" />
            </SettingDisplay>
            <SettingDisplay label="Pre-Order Price Source">
                <SelectFormField options={goodtillPriceSourceOptions} name="goodtillPreorderPriceSource" />
            </SettingDisplay>
            <SettingDisplay label="Order to Table Price Source">
                <SelectFormField
                    options={goodtillPriceSourceOptions}
                    name="goodtillOrderToTablePriceSource"
                />
            </SettingDisplay>
        </>
    );
}
