import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { isTagValue, TagValue } from '@pepperhq/menu-sdk';
import { isArrayOf } from 'lib/typeguards';
import React from 'react';
import { MuiGridTextView } from '../../../lib/MuiGrid/MuiGridTextView';

const TagValueCell: React.FC<GridRenderCellParams> = ({ value }) => {
    const renderString = React.useMemo(() => {
        const getValuesString = (values: TagValue[]) =>
            values.map(item => `${item.id} - ${item.title}`).join(', ');
        if (isArrayOf(isTagValue, value)) {
            return getValuesString(value);
        }
        return '';
    }, [value]);
    return <MuiGridTextView>{renderString}</MuiGridTextView>;
};

export const TagsViewCell = (props: GridRenderCellParams) => <TagValueCell {...props} />;
