import React from 'react';
import { Box, Button, styled } from '@mui/material';
import { SystemRole } from '@pepperhq/auth-client';
import { Form } from 'formik';
import * as Yup from 'yup';
import { Location } from 'components/location/models/LocationModel';
import { MuiForm } from 'lib/Form';
import { MultiSelectFormField } from 'lib/form/fields/MultiSelectFormField';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { Option } from 'lib/types';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Role } from '../models/Role';

export interface IChangeAccessValues {
    roleId: SystemRole;
    locationIds: Option[];
}

interface IChangeAccessLevelFormProps {
    onClose: () => void;
    isLoading: boolean;
    onSubmit: (values: IChangeAccessValues) => void;
    roles: Role[];
    locations: Location[];
    initialValues?: IChangeAccessValues;
}

const StyledSelectFormField = styled(SelectFormField)(({ theme }) => ({
    marginBottom: theme.spacing(1.5)
}));

export const ChangeAccessLevelForm: React.FC<IChangeAccessLevelFormProps> = props => {
    const { onClose, isLoading, onSubmit, roles, locations, initialValues } = props;

    const validationSchema = React.useMemo(
        () =>
            Yup.object().shape({
                roleId: Yup.string().required('This field is required.')
            }),
        []
    );

    const roleOptions = React.useMemo(
        () =>
            roles.map((role: Role) => ({
                value: role.id,
                label: role.name
            })),
        [roles]
    );
    const locationOptions = React.useMemo(
        () =>
            locations.map((location: Location) => ({
                value: location._id,
                label: location.title
            })),
        [locations]
    );

    return (
        <MuiForm onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
            {({ submitForm, isSubmitting }) => (
                <Form>
                    <Box paddingTop={2}>
                        <StyledSelectFormField name="roleId" options={roleOptions} label="Role" />
                        <MultiSelectFormField
                            name="locationIds"
                            options={locationOptions}
                            label="Locations"
                            description="The user will only be limited to some locations if any are provided"
                        />
                        <Box display="flex" flexDirection="row" justifyContent="flex-end" marginTop={1.5}>
                            <Button onClick={onClose} variant="outlined" sx={{ mr: 1 }}>
                                Cancel
                            </Button>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting || isLoading}
                                loading={isSubmitting || isLoading}
                                onClick={submitForm}
                            >
                                Update
                            </LoadingButton>
                        </Box>
                    </Box>
                </Form>
            )}
        </MuiForm>
    );
};
