import React from 'react';
import { Box, IconButton, Link, Typography } from '@mui/material';
import Download from '@mui/icons-material/Download';
import { SettingDisplay } from 'lib/SettingDisplay';
import { isString } from 'lib/typeguards';

interface SettingDisplayFileProps {
    title?: string;
    noFileText?: React.ReactNode;
    description?: string;
    crop?: boolean;
    buildtimeIOS?: boolean;
    buildtimeAndroid?: boolean;
    variant?: 'body2' | 'body1';
    fileName?: string;
    url?: string;
    showDownloadButton?: boolean;
    handleDownload?: () => void;
}

export const SettingDisplayFile: React.FC<SettingDisplayFileProps> = ({
    noFileText,
    title,
    description,
    crop,
    buildtimeIOS,
    buildtimeAndroid,
    variant = 'body2',
    fileName,
    url,
    showDownloadButton = false,
    handleDownload
}) => {
    const displayValue = React.useMemo(() => {
        if (crop && isString(noFileText) && noFileText.length > 50) {
            return `${noFileText.substring(0, 50)}...`;
        }
        return noFileText;
    }, [crop, noFileText]);
    return (
        <SettingDisplay
            label={title}
            description={description}
            buildtimeIOS={buildtimeIOS}
            buildtimeAndroid={buildtimeAndroid}
        >
            {fileName ? (
                <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" gap={1}>
                    <Link href={url} download variant={variant}>
                        {fileName}
                    </Link>
                    {showDownloadButton && (
                        <IconButton onClick={handleDownload}>
                            <Download />
                        </IconButton>
                    )}
                </Box>
            ) : (
                <Typography variant={variant}>{displayValue}</Typography>
            )}
        </SettingDisplay>
    );
};
