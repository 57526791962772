import React from 'react';
import { IAwardEnrichment } from 'components/loyalty/models/AwardEnrichment';
import { useGridSingleSelection } from 'lib/MuiGrid/useGridSingleSelection';

export const useExternalPerksUtils = ({ awardEnrichments }: { awardEnrichments: IAwardEnrichment[] }) => {
    const [indexToDelete, setIndexToDelete] = React.useState(-1);
    const [selectedToDuplicate, setSelectedToDuplicate] = React.useState<IAwardEnrichment | null | undefined>(
        null
    );
    const [selectedToEdit, setSelectedToEdit] = React.useState<IAwardEnrichment>();

    const [selectedAward, rowSelectionModel, handleSelectRow] = useGridSingleSelection(awardEnrichments);

    const handleReviewAndEditClick = React.useCallback(() => {
        setSelectedToEdit(selectedAward);
    }, [selectedAward]);

    const handleDuplicateClick = React.useCallback(() => {
        const award = awardEnrichments.find(award => award._id === selectedAward?._id);

        setSelectedToDuplicate(award);
    }, [awardEnrichments, selectedAward]);

    const handleDeleteClick = React.useCallback(() => {
        const awardIndex = awardEnrichments.findIndex(({ _id }) => _id === selectedAward?._id);

        setIndexToDelete(awardIndex);
    }, [awardEnrichments, selectedAward]);

    const handleResetEditSelection = React.useCallback(() => setSelectedToEdit(undefined), []);

    const handleResetDuplicateSelection = React.useCallback(() => {
        setSelectedToDuplicate(undefined);
    }, []);

    return {
        selectedAward,
        rowSelectionModel,
        selectedToEdit,
        selectedToDuplicate,
        indexToDelete,
        setIndexToDelete,
        handleSelectRow,
        handleReviewAndEditClick,
        handleDuplicateClick,
        handleDeleteClick,
        handleResetEditSelection,
        handleResetDuplicateSelection
    };
};
