import { updateArrayItem } from 'lib/helpers';
import { DEFAULT_PAGE_LIMIT } from 'lib/hooks/usePagedData';
import logger from 'lib/logger';
import { notificationApi } from '../notificationApi';
import React from 'react';
import { Notification } from 'components/notification/models/Notification';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { NotificationsFilter } from 'components/notification/filters/NotificationsFilters';
import { getNotificationsFilterQuery } from 'components/notification/hooks/useNotificationsFilter';

export function useNotifications(filter: NotificationsFilter, emptyFilter: boolean) {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [endOfData, setEndOfData] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(0);
    const [pages, setPages] = React.useState([undefined]);
    const [notifications, setNotifications] = React.useState<Notification<string>[]>([]);
    const [internalFilter, setInternalFilter] = React.useState<NotificationsFilter>();
    const [noData, setNoData] = React.useState<boolean>(false);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'createdAt',
            sort: 'desc'
        }
    ]);
    const reset = React.useCallback(() => {
        setNotifications([]);
        setPages([undefined]);
        setPage(0);
        setLoading(true);
        setEndOfData(false);
    }, []);
    const generateSortString = React.useCallback((model: GridSortModel) => {
        if (model && model[0]) {
            const sortDirection = model[0]?.sort ?? 'desc';
            const sortField = model[0]?.field ?? 'createdAt';
            return `${sortDirection === 'asc' ? '' : '-'}${sortField}`;
        }
        return undefined;
    }, []);
    React.useEffect(() => {
        const getNotifications = async () => {
            if (!internalFilter || endOfData) {
                return;
            }
            setLoading(true);
            try {
                const queryParameters = getNotificationsFilterQuery(internalFilter);
                queryParameters.push({ key: 'limit', value: DEFAULT_PAGE_LIMIT.toString() });
                queryParameters.push({ key: 'include', value: 'userFullName' });
                if (pages[page]) {
                    queryParameters.push({ key: 'startKey', value: pages[page] });
                }
                if (sortModel?.length) {
                    queryParameters.push({ key: 'sort', value: generateSortString(sortModel) });
                }
                const { body, ok } = await notificationApi.getList({
                    queryParameters,
                    headers: { 'x-api-version': 2 }
                });
                if (!ok) {
                    throw new Error(body.message);
                }
                if (!body.page.nextKey) {
                    setEndOfData(true);
                }
                setNotifications(currentNotifications => [...currentNotifications, ...body.items]);
                setPages(currentPages => updateArrayItem(currentPages, page + 1, body.page.nextKey));
                setNoData(emptyFilter && !notifications.length && body.items.length === 0);
            } catch (error) {
                logger.error(error);
                setEndOfData(true);
                setNotifications([]);
            } finally {
                setLoading(false);
            }
        };
        if (
            (page === 0 && notifications.length === 0) ||
            (!pages[page + 1] && notifications.length < pages.length * DEFAULT_PAGE_LIMIT)
        ) {
            getNotifications();
        }
    }, [
        endOfData,
        page,
        pages,
        notifications.length,
        sortModel,
        generateSortString,
        internalFilter,
        emptyFilter
    ]);
    React.useEffect(() => {
        setInternalFilter(filter);
        reset();
    }, [filter, reset]);
    const onPageChange = React.useCallback(
        (pageNumber: number) => {
            if (!loading) {
                setPage(pageNumber);
            }
        },
        [loading]
    );
    const onSortModelChange = React.useCallback(
        (model: GridSortModel) => {
            setSortModel(model);
            reset();
        },
        [reset]
    );
    const count = React.useMemo(() => {
        if (endOfData) {
            return notifications.length;
        }
        return -1;
    }, [endOfData, notifications.length]);
    const formattedNotifications = React.useMemo(() => {
        if (!Array.isArray(notifications)) {
            return [];
        }
        return notifications
            .slice(page * DEFAULT_PAGE_LIMIT, page * DEFAULT_PAGE_LIMIT + DEFAULT_PAGE_LIMIT)
            .map(notification => ({
                id: notification._id,
                status: notification.status,
                createdAt: notification.createdAt,
                title: notification.title,
                message: notification.message,
                tag: notification.tag,
                locationIds: notification.locationIds,
                segmentIds: notification.segmentIds,
                deepLink: notification.deepLink
            }));
    }, [notifications, page]);
    return {
        notifications,
        page,
        onPageChange,
        loading,
        count,
        formattedNotifications,
        sortModel,
        onSortModelChange,
        reset,
        noData
    };
}
