import { DateTime } from 'luxon';
import { roundToDecimal } from './helpers';
import { format, isValid } from 'date-fns';
import { isString } from './typeguards';

export enum TimeMeasure {
    HOUR = 'HOUR',
    DAY = 'DAY',
    WEEK = 'WEEK'
}

const SECONDS_IN_HOUR = 3600;

const timeMeasureToSeconds = {
    [TimeMeasure.HOUR]: SECONDS_IN_HOUR,
    [TimeMeasure.DAY]: SECONDS_IN_HOUR * 24,
    [TimeMeasure.WEEK]: SECONDS_IN_HOUR * 24 * 7
};

export function getSecondsToTimeUnits(value: number, to: TimeMeasure) {
    return value / timeMeasureToSeconds[to];
}

export function getTimeUnitsToSeconds(value: number, from: TimeMeasure) {
    return value * timeMeasureToSeconds[from];
}

export function getSecondsToDaysHours(value: number) {
    const days = Math.floor(roundToDecimal(value / timeMeasureToSeconds[TimeMeasure.DAY], 2));
    const remainingSeconds = value % timeMeasureToSeconds[TimeMeasure.DAY];
    if (remainingSeconds === 0) {
        return `${days} days`;
    }
    return `${days} days ${Math.round(remainingSeconds / timeMeasureToSeconds[TimeMeasure.HOUR])} hours`;
}

export const getNormalizedDateTime = (date: string | Date, merchantTimezone?: string): DateTime =>
    DateTime.fromJSDate(typeof date === 'string' ? new Date(date) : date).setZone(merchantTimezone || 'utc', {
        keepLocalTime: true
    });

const MINUTES_IN_DAY = 1440;

export const getDaysFromMinutes = (minutes?: number) => {
    if (!minutes) {
        return minutes;
    }
    return Math.round(minutes / MINUTES_IN_DAY);
};
export const getMinutesFromDays = (days: number) => {
    if (!days) {
        return days;
    }
    return days * MINUTES_IN_DAY;
};

export const formatDateTime = (date: string | Date) => {
    if (isValid(isString(date) ? new Date(date) : date)) {
        return format(new Date(date), 'dd/MM/yyyy h:mmaa');
    }
};

export const formatDate = (date: string | Date) => {
    if (isValid(isString(date) ? new Date(date) : date)) {
        return format(new Date(date), 'dd/MM/yyyy');
    }
};
