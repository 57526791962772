import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getAuthToken } from 'components/auth/getAuthToken';
import { tenantAccessApi } from 'components/merchant/tenantAccessApi';
import { JOIN, SIGN_UP, USER_MERCHANTS } from 'config/routes';
import { httpClient } from 'lib/HttpClient';
import { signOut } from 'store/auth/authActions';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { Throbber } from 'ui/Throbber';
import logger from 'lib/logger';
import { ELocalStorageKey } from 'config/storageKeys';

function JoinPage() {
    const [error, setError] = React.useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const queryParams = new URLSearchParams(useLocation().search);
    const queryToken =
        queryParams.get(ELocalStorageKey.JoinTokenQueryParameter) ||
        localStorage.getItem(ELocalStorageKey.JoinTokenQueryParameter);
    if (!queryToken) {
        history.push(SIGN_UP);
    }

    function handleError() {
        localStorage.setItem(ELocalStorageKey.JoinTokenQueryParameter, queryToken);
        signOut()(dispatch);
        history.push(`${SIGN_UP}?redirectTo=${JOIN}`);
        dispatch(enqueueSnackbar(error, { variant: 'warning' }));
    }

    if (error) {
        handleError();
    }

    async function activateTenant() {
        const authToken = await getAuthToken();
        httpClient.updateToken(authToken);
        if (authToken) {
            try {
                const result = await tenantAccessApi.createNewToken(queryToken);
                if (result.ok) {
                    localStorage.removeItem(ELocalStorageKey.JoinTokenQueryParameter);
                    history.push(USER_MERCHANTS);
                } else {
                    setError(result.body.message);
                }
            } catch (e) {
                logger.error(e);
            }
        } else {
            localStorage.setItem(ELocalStorageKey.JoinTokenQueryParameter, queryToken);
            history.push(`${SIGN_UP}?redirectTo=${JOIN}`);
        }
    }

    if (!error && queryToken) {
        activateTenant();
    }

    return <Throbber text="Verifying your account" />;
}

export default JoinPage;
