import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import {
    fromModifierProductKey,
    MenuChange,
    MenuChangeSchema,
    ModifierOptionChangeUpdate,
    toModifierProductKey
} from '@pepperhq/menu-sdk';
import { menuApi } from 'components/menu/MenuApi';
import { ImagePickerColumn, SelectGridColumn } from 'lib/MuiGrid/Columns';
import { EMuiGridStatus, MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React, { useContext } from 'react';
import { BaseMenuTab, getMenuChangeDataToCopy, useMenuChange } from '../model/menu';
import * as Yup from 'yup';
import { useLeavePageBlock } from 'lib/hooks/useLeavePageBlock';
import { useDispatch } from 'react-redux';
import logger from 'lib/logger';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_MODIFIER_PRODUCTS_UPDATE_ERROR,
    MESSAGE_MODIFIER_PRODUCTS_UPDATE_SUCCESS
} from 'config/messages';
import { getCurrencyString } from 'lib/helpers';
import { isEmptyString, isNumber } from 'lib/typeguards';
import { useMenuGridSearch } from '../model/useMenuGridSearch';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { LocationsContext, useTabCopy } from '../useCopyTab';
import { ActionsHeader } from '../ActionsHeader';
import { useMenuSelection } from '../useMenuSelection';
import { getModifierProductsData } from '../model/modifierProduct';
import { CreateModifierProductDialog } from '../CreateModifierProductDialog';
import { useCreateModifierProduct } from '../model/useCreateModifierProduct';
import merge from 'deepmerge';
import { useMenuDeleteControls } from '../model/useMenuDeleteControls';

type ModifierProductsTabProps = BaseMenuTab;

const validationSchema = {
    sort: Yup.number().integer('Whole number only')
};

export const ModifierProductsTab: React.FC<ModifierProductsTabProps> = ({
    menu,
    settings,
    menuChangeId,
    loadFreshMenu,
    onUpdate,
    change: externalChange,
    search,
    onSearchChange
}) => {
    const locations = useContext(LocationsContext);
    const dispatch = useDispatch();
    const {
        change: [change, setChange],
        readyToSave,
        handleCancel,
        handleStatusChange,
        gridRef
    } = useMenuChange(externalChange);
    const {
        selectedIds,
        resetSelection,
        selectedCell,
        handleSelectChange,
        handleCellSelection,
        copyEnabled,
        handleCellBlur
    } = useMenuSelection(!!locations.length);

    const { toggleIsCopyModalOpen, setMenuChangeToCopy, activeOperationId, renderLocationPickerModal } =
        useTabCopy(resetSelection, selectedCell, ['title', 'selected', 'imageUrl', 'sort', 'hide']);

    const {
        editedOrDeleted,
        handleCancelEditing,
        handleDelete,
        deleted,
        deleteEnabled,
        resetDeleted,
        isCellEditable
    } = useMenuDeleteControls(selectedIds, readyToSave, handleCancel, resetSelection);

    const handleRefreshMenu = React.useCallback(() => {
        loadFreshMenu();
        gridRef.current.reset();
    }, [gridRef, loadFreshMenu]);

    const [modifierProducts, categoryIdToTitle] = React.useMemo(() => {
        if (!menu || !change) {
            return [];
        }
        return getModifierProductsData(menu, change);
    }, [change, menu]);

    const initialState = React.useMemo(
        () => ({
            columns: {
                columnVisibilityModel: {
                    categoryId: settings.menu?.productByCategoryIdEditingEnabled
                }
            }
        }),
        [settings.menu?.productByCategoryIdEditingEnabled]
    );

    useLeavePageBlock(readyToSave);
    const gridSearchOptions = React.useMemo(
        () => [{ column: 'id' }, { column: 'title' }, { column: 'modifierTitle' }],
        []
    );
    const { filterModel, renderInput } = useMenuGridSearch(
        gridSearchOptions,
        search,
        onSearchChange,
        'modifierProducts',
        'Search by ID, title or modifier'
    );

    const priceValueFormatter = React.useCallback(
        (value: number | string) =>
            isNumber(value) ? getCurrencyString(value, settings?.region?.currencyCode || 'GBP') : value,
        [settings?.region?.currencyCode]
    );

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'id',
                headerName: 'ID',
                width: 140
            },
            {
                field: 'title',
                headerName: 'Title',
                width: 250,
                editable: true
            },
            {
                field: 'price',
                headerName: 'Price',
                type: 'number',
                width: 130,
                headerAlign: 'left',
                valueFormatter: priceValueFormatter
            },
            {
                field: 'modifierTitle',
                headerName: 'Modifier',
                width: 280
            },
            {
                field: 'categoryTitle',
                headerName: 'Categories',
                width: 280
            },
            {
                field: 'categoryId',
                headerName: 'Product Category',
                width: 250,
                editable: settings?.menu?.productByCategoryIdEditingEnabled,
                description: 'The category to select the modifier product from',
                ...SelectGridColumn(categoryIdToTitle)
            },
            {
                field: 'sort',
                headerName: 'Sort',
                editable: true,
                type: 'number',
                width: 120,
                headerAlign: 'left'
            },
            {
                field: 'hide',
                headerName: 'Exclude from Menu',
                width: 230,
                editable: true,
                headerAlign: 'left',
                type: 'boolean'
            },
            {
                field: 'imageUrl',
                headerName: 'Image',
                width: 300,
                ...ImagePickerColumn('modifier-product-', true, 200 / 200)
            }
        ],
        [categoryIdToTitle, priceValueFormatter, settings?.menu?.productByCategoryIdEditingEnabled]
    );
    const handleSubmit = React.useCallback(async () => {
        try {
            if (!gridRef.current) {
                throw new Error(MESSAGE_MODIFIER_PRODUCTS_UPDATE_ERROR);
            }
            const errors = gridRef.current.validate();
            if (errors) {
                return;
            }
            const { changes } = gridRef.current.getState();
            const modifierProductChanges = Object.entries(changes).reduce<MenuChange['modifierProducts']>(
                (acc, [id, { _overriden, ...item }]) => {
                    if (!change?.modifierProducts || !change?.modifierProducts[id]) {
                        acc[id] = item;
                    } else {
                        acc[id] = { ...change.modifierProducts[id], ...item };
                    }
                    if (isEmptyString(acc[id].title)) {
                        acc[id].title = null;
                    }
                    if (isEmptyString(acc[id].hide)) {
                        acc[id].hide = null;
                    }
                    if (isEmptyString(acc[id].imageUrl)) {
                        acc[id].imageUrl = null;
                    }
                    if (isEmptyString(acc[id].categoryId)) {
                        acc[id].categoryId = null;
                    }
                    return acc;
                },
                {}
            );
            if (deleted && deleted.size) {
                deleted.forEach(deletedProduct => {
                    modifierProductChanges[deletedProduct] = null;
                });
            }
            const result = await menuApi.updateMenuChange(menuChangeId, {
                modifierProducts: modifierProductChanges,
                schema: MenuChangeSchema.v5
            });
            if (!result.ok) {
                throw new Error(result.body.message);
            }
            setChange(result.body);
            onUpdate(result.body);
            dispatch(enqueueSnackbar(MESSAGE_MODIFIER_PRODUCTS_UPDATE_SUCCESS, { variant: 'success' }));
            gridRef.current.reset();
            resetDeleted();
        } catch (e) {
            logger.error(e);
            dispatch(enqueueSnackbar(e.message, { variant: 'error' }));
        }
    }, [
        gridRef,
        deleted,
        menuChangeId,
        setChange,
        onUpdate,
        dispatch,
        resetDeleted,
        change.modifierProducts
    ]);

    const handleCopyTo = React.useCallback(() => {
        toggleIsCopyModalOpen();
        const menuChange = getMenuChangeDataToCopy<ModifierOptionChangeUpdate>(
            change,
            selectedIds,
            'modifierProducts',
            selectedCell,
            row => {
                const { id } = fromModifierProductKey(row.id);
                return id;
            }
        );
        setMenuChangeToCopy(menuChange);
    }, [toggleIsCopyModalOpen, change, selectedIds, selectedCell, setMenuChangeToCopy]);

    const {
        open: createDialogOpen,
        modifierProductIdToDuplicate,
        categoryOptions,
        modifierOptions,
        productOptions,
        productsByModifier,
        handleCreateCancel,
        handleCreate
    } = useCreateModifierProduct(menu, externalChange, selectedIds);

    const handleCreateSubmit = React.useCallback(
        (categoryId: string, modifierId: string, modifierProductId: string) => {
            const { changes } = gridRef.current?.getState() ?? {};
            const modifierProductChanges = merge(change?.modifierProducts || {}, changes);
            const id = toModifierProductKey(modifierProductId, modifierId, categoryId);
            setChange({
                ...change,
                modifierProducts: {
                    ...modifierProductChanges,
                    [id]: {}
                }
            });
            gridRef.current?.createItem(id, undefined, {}, 'id');
            handleStatusChange(EMuiGridStatus.CHANGED);
            handleCreateCancel();
        },
        [change, gridRef, handleCreateCancel, handleStatusChange, setChange]
    );

    const handleHidableCreate = React.useMemo(
        () => (settings.menu?.productByCategoryIdEditingEnabled ? handleCreate : undefined),
        [handleCreate, settings.menu?.productByCategoryIdEditingEnabled]
    );

    return (
        <React.Fragment>
            <Box pb={1}>
                <ActionsHeader
                    menu={menu}
                    onCancelEditing={handleCancelEditing}
                    onSubmit={handleSubmit}
                    activeOperationId={activeOperationId}
                    onCopy={handleCopyTo}
                    onRefreshMenu={handleRefreshMenu}
                    isEditedOrDeleted={editedOrDeleted}
                    copyEnabled={copyEnabled}
                    onDelete={handleDelete}
                    deleteEnabled={deleteEnabled}
                    onCreate={handleHidableCreate}
                />
                {renderInput()}
            </Box>
            <MuiGrid
                rows={modifierProducts}
                columns={columns}
                stateRef={gridRef}
                filterModel={filterModel}
                onStatusChange={handleStatusChange}
                validationScheme={validationSchema}
                storageName={GridStorageName.ModifiersProducts}
                disableColumnFilter
                checkboxSelection
                initialState={initialState}
                checkboxVisibility
                onRowSelectionModelChange={handleSelectChange}
                rowSelectionModel={selectedIds}
                onCellClick={handleCellSelection}
                deleted={deleted}
                isCellEditable={isCellEditable}
                handleCellBlur={handleCellBlur}
            />
            {renderLocationPickerModal()}
            {createDialogOpen && (
                <CreateModifierProductDialog
                    open={createDialogOpen}
                    categories={categoryOptions}
                    modifiers={modifierOptions}
                    modifierProducts={productOptions}
                    productByModifier={productsByModifier}
                    deafultId={modifierProductIdToDuplicate}
                    onCancel={handleCreateCancel}
                    onSubmit={handleCreateSubmit}
                />
            )}
        </React.Fragment>
    );
};
