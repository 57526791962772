import * as React from 'react';
import { LocationServicesCardComponent } from 'components/location/cards/LocationServicesCardComponent';
import { LocationServicesCardEditComponent } from 'components/location/cards/LocationServicesCardEditComponent';
import { createLocationServicesForm } from 'components/location/forms/createLocationServicesForm';
import { Location } from 'components/location/models/LocationModel';
import { LegacyForm } from 'lib/LegacyForm';
import { Panel } from 'ui/Panel';
import { locationApi } from '../LocationApi';

export interface LocationServicesCardProps {
    currentLocation: Location;
    setCurrentLocation: (location: Location) => void;
}

export class LocationServicesCard extends React.Component<LocationServicesCardProps, { isEditing: boolean }> {
    state = {
        isEditing: false
    };
    handleSubmit = async (data: any) => {
        const locationId = this.props.currentLocation._id;
        const response = await locationApi.update(locationId, {
            body: {
                services: data
            }
        });
        if (response.ok) {
            this.updateForm(response.body);
            this.props.setCurrentLocation(response.body);
        }
        this.setState({ isEditing: false });
    };
    // eslint-disable-next-line @typescript-eslint/member-ordering
    form = new LegacyForm(createLocationServicesForm(), this.handleSubmit);
    componentDidMount() {
        this.updateForm(this.props.currentLocation);
    }
    updateForm = ({ services }: Location) => this.form.update(services);
    toggleState = () => {
        this.setState(state => ({ isEditing: !state.isEditing }));
        this.updateForm(this.props.currentLocation);
    };
    render() {
        const { isEditing } = this.state;
        return (
            <Panel title="Services" showAction={!isEditing} onClick={this.toggleState} divider>
                {isEditing ? (
                    <LocationServicesCardEditComponent
                        onClose={this.toggleState}
                        form={this.form}
                        services={this.props.currentLocation.services}
                    />
                ) : (
                    <LocationServicesCardComponent services={this.props.currentLocation.services} />
                )}
            </Panel>
        );
    }
}
