import React from 'react';
import { shallowEqual } from 'react-redux';
import { TableCell, Typography } from '@mui/material';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { MuiTable, MuiTableColumnItem, MuiTableScrollItems } from 'ui/table/MuiTable';
import { ExportEnrichmentProgressCell } from '../ExportEnrichmentProgressCell';
import { EnrichmentOperation } from '../model/EnrichmentOperation';
import { OperationStatusChip } from '../OperationStatusChip';
import { formatDateTime } from 'lib/timeHelpers';

interface ExportEnrichmentsTableProps {
    operations: EnrichmentOperation[];
    nextKey?: string;
    onScroll: () => void;
    onClick: (item: EnrichmentOperation) => void;
}

export const ExportEnrichmentsTable: React.FC<ExportEnrichmentsTableProps> = ({
    operations,
    nextKey,
    onScroll,
    onClick
}) => {
    const columns = React.useMemo<MuiTableColumnItem[]>(
        () => [
            {
                key: 'createdAt',
                label: 'Date',
                render: (item: EnrichmentOperation) => (
                    <TableCell key={`export-date-${item.nonce}`}>{formatDateTime(item.createdAt)}</TableCell>
                )
            },
            {
                key: 'status',
                label: 'State',
                render: (item: EnrichmentOperation) => (
                    <TableCell key={`export-status-${item.nonce}`} align="center" size="small">
                        <OperationStatusChip status={item.status} />
                    </TableCell>
                ),
                headerProps: {
                    align: 'center'
                }
            },
            {
                key: 'result.message',
                label: 'Message',
                headerProps: {
                    align: 'center'
                },
                render: (item: EnrichmentOperation) => {
                    let message = item.result && item.result.message;
                    const isError = item.status === OperationStatus.ERROR;
                    if (isError) {
                        message = item?.error.message;
                    }
                    return (
                        <TableCell key={`export-message-${item.nonce}`} align="center" size="small">
                            <Typography
                                sx={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    display: 'block',
                                    width: '140px'
                                }}
                                variant="caption"
                                align="center"
                            >
                                {message}
                            </Typography>
                        </TableCell>
                    );
                }
            },
            {
                key: 'action',
                label: '',
                render: (item: EnrichmentOperation) => (
                    <ExportEnrichmentProgressCell key={`export-action-${item.nonce}`} operation={item} />
                )
            }
        ],
        []
    );
    const scroll = React.useMemo<MuiTableScrollItems>(
        () => ({ isMoreItems: !!nextKey, key: nextKey, onScroll }),
        [onScroll, nextKey]
    );
    const getRowKey = React.useCallback((item: EnrichmentOperation) => item.nonce, []);
    return (
        <MuiTable
            alwaysShowHead
            data={operations}
            scroll={scroll}
            columns={columns}
            getRowKey={getRowKey}
            onClick={onClick}
            isLoading={!!nextKey}
        />
    );
};

export const ExportEnrichmentsTablePure = React.memo(ExportEnrichmentsTable, (prev, next) =>
    shallowEqual(prev, next)
);
