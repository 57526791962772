import DownloadIcon from '@mui/icons-material/Download';
import { Box, FormControl, FormHelperText, IconButton, InputLabel } from '@mui/material';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { useDownloadAsset } from 'lib/hooks/useDownloadAsset';
import { IAdditionalUpload, MuiImagePicker } from 'lib/image-picker';
import { MuiImageDropZoneUpload } from 'lib/image-picker/MuiImageDropZoneUpload';
import React from 'react';
import { Row } from 'ui/Flex';

interface ImageFormFieldProps {
    label?: React.ReactNode;
    labelStyle?: React.CSSProperties;
    description?: React.ReactNode;
    aspect?: number;
    fullWidth?: boolean;
    fileName?: string;
    disableCrop?: boolean;
    uploadFolder?: string;
    additionalUploads?: IAdditionalUpload[];
    unremovable?: boolean;
    smallText?: boolean;
    croppable?: boolean;
    accept?: string | string[];
    downloadable?: boolean;
}

export const ImageFormField: React.FC<FieldAttributes<ImageFormFieldProps>> = ({
    label,
    labelStyle,
    placeholder: _placeholder,
    description,
    aspect,
    fullWidth,
    fileName,
    uploadFolder,
    additionalUploads,
    unremovable,
    smallText,
    accept,
    croppable,
    downloadable,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <ImageFormFieldComponent
                label={label}
                labelStyle={labelStyle}
                accept={accept}
                description={description}
                aspect={aspect}
                fullWidth={fullWidth}
                fileName={fileName}
                uploadFolder={uploadFolder}
                additionalUploads={additionalUploads}
                unremovable={unremovable}
                smallText={smallText}
                croppable={croppable}
                downloadable={downloadable}
                {...childProps}
            />
        )}
    </Field>
);

const ImageFormFieldComponent: React.FC<FieldProps & ImageFormFieldProps> = ({
    description,
    field,
    meta,
    aspect,
    label,
    labelStyle,
    disableCrop,
    fullWidth,
    fileName,
    uploadFolder,
    additionalUploads,
    smallText,
    unremovable,
    downloadable,
    croppable = true,
    accept
}) => {
    const isError = meta.touched && !!meta.error;
    const handleChange = React.useCallback(
        (value: string) => {
            field.onChange({ target: { value, name: field.name } });
        },
        [field]
    );
    const { loading, handleDownload } = useDownloadAsset({
        src: field.value,
        fileName
    });
    return (
        <FormControl fullWidth={fullWidth} error={meta.touched && !!meta.error}>
            <InputLabel style={labelStyle} shrink>
                {label}
            </InputLabel>
            <Row style={{ flexWrap: 'wrap', alignItems: 'center', gap: 4 }}>
                {croppable ? (
                    <MuiImagePicker
                        url={field.value}
                        onSelect={handleChange}
                        aspect={aspect}
                        uploadFileName={fileName}
                        uploadFolder={uploadFolder}
                        disableCrop={disableCrop}
                        additionalUploads={additionalUploads}
                        unremovable={unremovable}
                        smallText={smallText}
                        accept={accept}
                    />
                ) : (
                    <MuiImageDropZoneUpload
                        uploadFolder={uploadFolder}
                        accept={accept}
                        aspect={aspect}
                        fileName={fileName}
                        url={field.value}
                        fullWidth={fullWidth}
                        onChange={handleChange}
                        smallText={smallText}
                    />
                )}
                {downloadable && (
                    <Box>
                        <IconButton disabled={loading} onClick={handleDownload}>
                            <DownloadIcon />
                        </IconButton>
                    </Box>
                )}
            </Row>

            {(isError || description) && (
                <FormHelperText error={isError}>{isError ? meta.error : description}</FormHelperText>
            )}
        </FormControl>
    );
};

ImageFormFieldComponent.defaultProps = {
    fullWidth: true
};
