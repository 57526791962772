import { Button, Grid, Typography } from '@mui/material';
import { Form } from 'formik';
import { MuiForm } from 'lib/Form';
import { TextFormField } from 'lib/form/fields/TextFormField';
import React from 'react';
import { Row } from 'ui/Flex';
import { MuiModal } from 'ui/MuiModal';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { EditPremiumLoyaltyForm, UserMetricDefinition } from '../model';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import * as Yup from 'yup';
import { EPremiumLoyaltyScheme } from '../hooks/useUserMetricDefinition';

interface EditPremiumLoyaltyModalProps {
    metricDefinition?: UserMetricDefinition;
    open: boolean;
    onClose: () => void;
    onSubmit: (data: EditPremiumLoyaltyForm) => void;
    loading?: boolean;
    scheme: EPremiumLoyaltyScheme;
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required.')
});

export const EditPremiumLoyaltyModal: React.FC<EditPremiumLoyaltyModalProps> = ({
    open,
    onClose,
    onSubmit,
    loading,
    metricDefinition,
    scheme
}) => {
    const initialData = React.useMemo(
        () => ({
            name: metricDefinition?.name ?? ''
        }),
        [metricDefinition]
    );
    const handleSubmit = React.useCallback(
        (data: { name: string }) => {
            onSubmit({ ...data, type: scheme });
        },
        [onSubmit, scheme]
    );
    const title = React.useMemo(() => {
        let schemeName = 'Scheme';
        switch (scheme) {
            case EPremiumLoyaltyScheme.Birthday:
                schemeName = 'Birthday scheme';
                break;
            case EPremiumLoyaltyScheme.Tier:
                schemeName = 'Tier scheme';
                break;
        }
        return !metricDefinition ? `Create a ${schemeName}` : `Update ${schemeName}`;
    }, [metricDefinition, scheme]);
    return (
        <MuiModal open={open} onClose={onClose}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <MuiForm
                        onSubmit={handleSubmit}
                        initialValues={initialData}
                        validationSchema={validationSchema}
                    >
                        {({ submitForm, isSubmitting, isValid }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextFormField
                                            name="name"
                                            label={<MuiFormLabel required>Name</MuiFormLabel>}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Row gutter align="flex-end">
                                            <Button
                                                variant="outlined"
                                                onClick={onClose}
                                                disabled={loading || isSubmitting}
                                            >
                                                Cancel
                                            </Button>
                                            <LoadingButton
                                                variant="contained"
                                                onClick={submitForm}
                                                disabled={!isValid || isSubmitting || loading}
                                                loading={isSubmitting || loading}
                                            >
                                                Submit
                                            </LoadingButton>
                                        </Row>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </MuiForm>
                </Grid>
            </Grid>
        </MuiModal>
    );
};
