import React from 'react';
import { EnrichedProduct } from '../model/product';
import { MenuChange, MenuV } from '@pepperhq/menu-sdk';
import { Option } from 'lib/types';

export function useCreateProduct(
    menu?: MenuV<6>,
    externalChange?: MenuChange,
    selectedIds?: string[],
    productOptions?: Option[],
    products?: EnrichedProduct[]
) {
    const [open, setOpen] = React.useState(false);
    const isProductRemoveable = React.useCallback(
        (id: string) => {
            const product = products.find(product => id === product.id);
            if (product && product._overriden?.has('productId')) {
                return true;
            }
            return false;
        },
        [products]
    );
    const [productToDuplicate, setProductToDuplicate] = React.useState<EnrichedProduct>();
    const categoryOptions = React.useMemo(() => {
        if (Array.isArray(menu?.categories)) {
            return menu.categories.map(item => {
                const title =
                    (externalChange?.categories ? externalChange.categories[item.id]?.title : item.title) ??
                    item.title;
                return {
                    value: item.id,
                    label: `${title} [${item.id}]`
                };
            });
        }
        return [];
    }, [externalChange?.categories, menu?.categories]);
    const productOptionsWithId = React.useMemo(
        () => productOptions.map(item => ({ label: `${item.label} [${item.value}]`, value: item.value })),
        [productOptions]
    );

    const handleCreateCancel = React.useCallback(() => {
        setOpen(false);
    }, []);

    const handleCreate = React.useCallback(() => {
        if (selectedIds && selectedIds.length === 1 && !isProductRemoveable(selectedIds[0])) {
            const [selectedProductId] = selectedIds;
            setProductToDuplicate(products.find(item => item.id === selectedProductId));
        } else {
            setProductToDuplicate(undefined);
        }
        setOpen(true);
    }, [isProductRemoveable, products, selectedIds]);

    return {
        open,
        productToDuplicate,
        categoryOptions,
        productOptionsWithId,
        handleCreateCancel,
        handleCreate
    };
}
