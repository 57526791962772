import React from 'react';
import { Audience } from '../models/segmentModel';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_MARKETING_AUDIENCE_ADD_TRIGGERS_ERROR,
    MESSAGE_MARKETING_AUDIENCE_ADD_TRIGGERS_SUCCESS
} from 'config/messages';

interface IAddTriggersData {
    open: boolean;
    audienceData: Partial<Audience>;
}

const INITIAL_ADD_TRIGGERS_DATA: IAddTriggersData = {
    open: false,
    audienceData: {
        title: ''
    }
};

export function useTriggersModal() {
    const dispatch = useDispatch();
    const [addTriggersData, setAddTriggersData] = React.useState<IAddTriggersData>({
        ...INITIAL_ADD_TRIGGERS_DATA
    });
    const initTriggersModal = React.useCallback((audienceData: Audience) => {
        setAddTriggersData({
            open: true,
            audienceData: { ...audienceData }
        });
    }, []);
    const handleAddTriggersClose = React.useCallback(() => {
        setAddTriggersData({ ...INITIAL_ADD_TRIGGERS_DATA });
    }, []);
    const handleAddTriggersSuccess = React.useCallback(() => {
        setAddTriggersData({ ...INITIAL_ADD_TRIGGERS_DATA });
        dispatch(enqueueSnackbar(MESSAGE_MARKETING_AUDIENCE_ADD_TRIGGERS_SUCCESS, { variant: 'success' }));
    }, [dispatch]);
    const handleAddTriggersError = React.useCallback(() => {
        dispatch(enqueueSnackbar(MESSAGE_MARKETING_AUDIENCE_ADD_TRIGGERS_ERROR, { variant: 'error' }));
    }, [dispatch]);
    return {
        handleAddTriggersClose,
        handleAddTriggersSuccess,
        handleAddTriggersError,
        initTriggersModal,
        addTriggersData
    };
}
