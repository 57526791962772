import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Card, Chip, Divider, IconButton, Typography, styled } from '@mui/material';
import Done from '@mui/icons-material/Done';
import Close from '@mui/icons-material/Close';
import { IPublicAndPrivateSettings } from '@pepperhq/location-sdk';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { ImageFormField } from 'lib/form/fields/ImageFormField';
import { NumberFormField } from 'lib/form/fields/NumberFormField';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { MuiCardForm } from 'lib/form/MuiCardForm';
import { parseFloatOrUndefined, parseIntOrUndefined } from 'lib/helpers';
import logger from 'lib/logger';
import { SettingDisplay } from 'lib/SettingDisplay';
import { Option } from 'lib/types';
import { ApplicationState } from 'store/store';
import { ILocationScopedSettingsComponentProps } from './LocationScopedSettings';
import { SettingDisplaySwitch } from './SettingDisplaySwitch';
import { SettingDisplayText } from './SettingDisplayText';
import { ChipInputFormField } from 'lib/form/fields/ChipInputFormField';
import { MuiForm } from 'lib/Form';
import Download from '@mui/icons-material/Download';
import { useDownloadAsset } from 'lib/hooks/useDownloadAsset';

const PREFIX = 'LocationSettings';

const classes = {
    settingsCard: `${PREFIX}-settingsCard`,
    displayClassName: `${PREFIX}-displayClassName`,
    autoCheckoutOnChip: `${PREFIX}-autoCheckoutOnChip`,
    image: `${PREFIX}-image`,
    titleWrapper: `${PREFIX}-titleWrapper`
};

const StyledMuiCardForm = styled(MuiCardForm)(({ theme }) => ({
    [`&.${classes.settingsCard}`]: {
        marginBottom: theme.spacing(2)
    },
    [`& .${classes.displayClassName}`]: {
        padding: theme.spacing(2)
    },
    [`& .${classes.autoCheckoutOnChip}`]: {
        marginRight: theme.spacing(0.5)
    },
    [`& .${classes.image}`]: {
        width: 'auto',
        height: 'auto',
        maxHeight: '400px',
        maxWidth: '100%'
    },
    [`& .${classes.titleWrapper}`]: {
        marginLeft: theme.spacing(1),
        minHeight: theme.spacing(5.25)
    }
}));

const autoCheckoutOnOptions: Option[] = [{ label: 'Order Completed', value: 'ORDER_COMPLETED' }];

export const LocationsAndFavouritesSettings: React.FC<ILocationScopedSettingsComponentProps> = ({
    settings,
    onSettingsSaved,
    locationId
}) => {
    const { tenantId } = useSelector((state: ApplicationState) => state.auth.tenant);

    const handleSubmitLocationAccess = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};
                // Common Settings
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        checkins: {
                            autoCheckoutOn:
                                values.autoCheckoutOn?.map((option: Option) => option.value) ?? [],
                            autoCheckoutDelayMins: parseFloatOrUndefined(values.autoCheckoutDelayMins),
                            limitLocationRange: values.limitLocationRange,
                            suggestOnAnchor: values.suggestOnAnchor,
                            requireProfilePhoto: values.requireProfilePhoto
                        }
                    };
                }
                if (!Object.keys(settingsToUpdate).length) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);
                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [onSettingsSaved, locationId]
    );

    const handleSubmitFavourites = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};

                // Common Settings
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        app: {
                            maximumFavouriteLocations: parseIntOrUndefined(values.maximumFavouriteLocations)
                        },
                        multipleLocationsEnabled: values.multipleLocationsEnabled
                    };
                }
                if (!Object.keys(settingsToUpdate).length) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);
                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [onSettingsSaved, locationId]
    );

    const handleMediaSubmit = React.useCallback(async (_: any) => {}, []);

    const renderAutoCheckoutChip = React.useCallback((label: string) => {
        const option = autoCheckoutOnOptions.find((option: Option) => option.value === label);
        return <Chip label={option?.label ?? label} color="primary" className={classes.autoCheckoutOnChip} />;
    }, []);

    const autoCheckoutOnSelectedOptions = React.useMemo(
        () =>
            autoCheckoutOnOptions.filter(option =>
                settings?.checkins?.autoCheckoutOn?.includes(option.value)
            ),
        [settings]
    );

    const { loading: favouriteImageLoading, handleDownload: handleDownloadFavourite } = useDownloadAsset({
        src: `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/add_fav_location`,
        fileName: 'add_fav_location'
    });

    const { loading: anotherFavouriteImageLoading, handleDownload: handleDownloadAnotherFavourite } =
        useDownloadAsset({
            src: `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/add_another_fav_location`,
            fileName: 'add_another_fav_location'
        });

    return (
        <Box minWidth="500px">
            {/* Cards for each settings block */}
            {locationId === undefined && (
                <>
                    <Card sx={{ marginBottom: 2 }}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            padding={1}
                            justifyContent="space-between"
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                paddingLeft={1}
                                minHeight={5.25}
                            >
                                <Typography variant="h6">Location Access</Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <Box padding={2}>
                            <MuiForm
                                onSubmit={handleMediaSubmit}
                                initialValues={{
                                    mapPin: `${
                                        process.env.MEDIA_SERVICE_URL
                                    }/tenants/${tenantId}/app_assets/map_pin?${new Date().toTimeString()}`
                                }}
                            >
                                <SettingDisplay
                                    label="Map Pin"
                                    description="41px by 63px PNG only"
                                    buildtimeAndroid
                                    buildtimeIOS
                                >
                                    <ImageFormField
                                        name="mapPin"
                                        aspect={41 / 63}
                                        fileName="map_pin"
                                        uploadFolder="app_assets"
                                        accept="image/png"
                                        unremovable
                                        downloadable
                                    />
                                </SettingDisplay>
                            </MuiForm>
                        </Box>
                    </Card>

                    <StyledMuiCardForm
                        title="Checkin"
                        initialValues={{
                            requireProfilePhoto: settings?.checkins?.requireProfilePhoto !== false,
                            suggestOnAnchor: settings?.checkins?.suggestOnAnchor ?? false,
                            limitLocationRange: settings?.checkins?.limitLocationRange !== false,
                            autoCheckoutOn: autoCheckoutOnSelectedOptions,
                            autoCheckoutDelayMins: settings?.checkins?.autoCheckoutDelayMins
                        }}
                        onSubmit={handleSubmitLocationAccess}
                        childrenWhileDisplaying={
                            <Box display="flex" flexDirection="column">
                                <SettingDisplaySwitch
                                    title="Require Profile Photo"
                                    checked={
                                        settings?.checkins?.requireProfilePhoto ||
                                        settings?.checkins?.requireProfilePhoto === undefined
                                    }
                                />
                                <SettingDisplaySwitch
                                    title="Suggest Checkin Prompt On Anchor"
                                    checked={settings?.checkins?.suggestOnAnchor}
                                />
                                <SettingDisplaySwitch
                                    title="Restrict Locations Available Based On Distance"
                                    checked={settings?.checkins?.limitLocationRange !== false}
                                />
                                <SettingDisplayText
                                    title="Auto Checkout On"
                                    text={settings?.checkins?.autoCheckoutOn?.map(renderAutoCheckoutChip)}
                                />
                                <SettingDisplayText
                                    title="Auto Checkout Delay Mins"
                                    text={settings?.checkins?.autoCheckoutDelayMins}
                                />
                            </Box>
                        }
                        childrenWhileEditing={
                            <Box display="flex" flexDirection="column">
                                <SettingDisplay label="Require Profile Photo">
                                    <SwitchFormField name="requireProfilePhoto" />
                                </SettingDisplay>
                                <SettingDisplay label="Suggest Checkin Prompt On Anchor">
                                    <SwitchFormField name="suggestOnAnchor" />
                                </SettingDisplay>
                                <SettingDisplay label="Restrict Locations Available Based On Distance">
                                    <SwitchFormField name="limitLocationRange" />
                                </SettingDisplay>
                                <SettingDisplay label="Auto Checkout On">
                                    <ChipInputFormField
                                        name="autoCheckoutOn"
                                        options={autoCheckoutOnOptions}
                                        multiple
                                        isCreatable={false}
                                    />
                                </SettingDisplay>
                                <SettingDisplay label="Auto Checkout Delay Mins">
                                    <NumberFormField name="autoCheckoutDelayMins" />
                                </SettingDisplay>
                            </Box>
                        }
                        className={classes.settingsCard}
                        boxClassName={classes.displayClassName}
                    />
                    <StyledMuiCardForm
                        title="Favourites"
                        initialValues={{
                            multipleLocationsEnabled: settings?.multipleLocationsEnabled ?? false,
                            maximumFavouriteLocations: settings?.app?.maximumFavouriteLocations,
                            favouriteImagePlaceholder: `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/add_fav_location`,
                            // eslint-disable-next-line max-len
                            anotherFavouriteImagePlaceholder: `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/add_another_fav_location`
                        }}
                        onSubmit={handleSubmitFavourites}
                        childrenWhileDisplaying={
                            <Box display="flex" flexDirection="column">
                                <SettingDisplay label="Multiple Locations Enabled">
                                    {settings?.multipleLocationsEnabled && <Done />}
                                    {!settings?.multipleLocationsEnabled && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Maximum Favourite Locations">
                                    <Typography variant="body2">
                                        {settings?.app?.maximumFavouriteLocations}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Favourite Image Placeholder"
                                    buildtimeAndroid
                                    buildtimeIOS
                                    description="600px by 400px PNG or JPEG"
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        flexWrap="wrap"
                                        alignItems="center"
                                        gap={1}
                                    >
                                        <img
                                            src={`${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/add_fav_location`}
                                            className={classes.image}
                                        />
                                        <IconButton
                                            disabled={favouriteImageLoading}
                                            onClick={handleDownloadFavourite}
                                        >
                                            <Download />
                                        </IconButton>
                                    </Box>
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Another Favourite Image Placeholder"
                                    description="300px by 400px PNG or JPEG"
                                    buildtimeAndroid
                                    buildtimeIOS
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        flexWrap="wrap"
                                        alignItems="center"
                                        gap={1}
                                    >
                                        <img
                                            src={`${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_assets/add_another_fav_location`}
                                            className={classes.image}
                                        />
                                        <IconButton
                                            disabled={anotherFavouriteImageLoading}
                                            onClick={handleDownloadAnotherFavourite}
                                        >
                                            <Download />
                                        </IconButton>
                                    </Box>
                                </SettingDisplay>
                            </Box>
                        }
                        childrenWhileEditing={
                            <Box display="flex" flexDirection="column">
                                <SettingDisplay label="Multiple Locations Enabled">
                                    <SwitchFormField name="multipleLocationsEnabled" />
                                </SettingDisplay>
                                <SettingDisplay label="Maximum Favourite Locations">
                                    <NumberFormField name="maximumFavouriteLocations" />
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Favourite Image Placeholder"
                                    description="600px by 400px PNG or JPEG"
                                    buildtimeAndroid
                                    buildtimeIOS
                                >
                                    <ImageFormField
                                        name="favouriteImagePlaceholder"
                                        aspect={600 / 400}
                                        fileName="add_fav_location"
                                        uploadFolder="app_assets"
                                        unremovable
                                    />
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Another Favourite Image Placeholder"
                                    description="300px by 400px PNG or JPEG"
                                    buildtimeAndroid
                                    buildtimeIOS
                                >
                                    <ImageFormField
                                        name="anotherFavouriteImagePlaceholder"
                                        aspect={300 / 400}
                                        fileName="add_another_fav_location"
                                        uploadFolder="app_assets"
                                        unremovable
                                    />
                                </SettingDisplay>
                            </Box>
                        }
                        className={classes.settingsCard}
                        boxClassName={classes.displayClassName}
                    />
                </>
            )}
        </Box>
    );
};
