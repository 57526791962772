import * as React from 'react';
import { SortableContainer, SortableElement, SortableHandle as sortableHandle } from 'react-sortable-hoc';
import { Box, Button, Grid, Menu, MenuItem, styled, Typography, Chip, IconButton } from '@mui/material';
import DragHandle from '@mui/icons-material/DragHandle';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { OrderScenario, OrderScenarioLabels } from 'components/order/model/Order';
import { arrayMove, removeArrayItem } from 'lib/helpers';
import { CardViewTextRowValue } from 'ui/viewComponents';
import {
    CreateOrderNoteModal,
    EditLocationOrderNote,
    ILocationOrderNoteForm
} from '../modal/LocationOrderNoteModal';
import { LocationOrderNote } from '../models/LocationModel';
import { LocationOrderNoteMap } from './LocationOrderNotes';
import { menuChannelLabel } from 'components/menu/model/menu';
import { MenuChannel, MenuChannelFlags } from '@pepperhq/menu-sdk';

interface IProps {
    notes: LocationOrderNoteMap;
    setNotes: React.Dispatch<React.SetStateAction<Record<string, LocationOrderNote[]>>>;
    isEditing: boolean;
}

const PREFIX = 'LocationOrderNotesView';

const classes = {
    cell: `${PREFIX}-cell`,
    dragHandle: `${PREFIX}-dragHandle`
};

const StyledBox = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    [`& .${classes.cell}`]: {
        border: 'none',
        paddingX: theme.spacing(1)
    },
    [`& .${classes.dragHandle}`]: {
        cursor: 'pointer',
        height: '1em',
        width: '1em'
    }
}));

const scenarios = {
    [OrderScenario.PREORDER]: OrderScenarioLabels[OrderScenario.PREORDER],
    [OrderScenario.ORDER_TO_TABLE]: 'Order to Table & Pay at Table'
};

interface LocationOrderNoteProps {
    note: LocationOrderNote;
    sortIndex: number;
    scenario: string;
    isEditing: boolean;
    onNoteEditClick: (index: number, scenario: string) => void;
    onNoteDeleteClick: (index: number, scenario: string) => void;
}
const LocationOrderNoteRow = SortableElement<LocationOrderNoteProps>(
    ({
        note,
        sortIndex,
        scenario,
        isEditing,
        onNoteEditClick,
        onNoteDeleteClick
    }: LocationOrderNoteProps) => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

        const handleClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        }, []);

        const handleClose = React.useCallback(() => {
            setAnchorEl(null);
        }, []);
        const handleEditClick = React.useCallback(() => {
            setAnchorEl(null);
            onNoteEditClick(sortIndex, scenario);
        }, [onNoteEditClick, scenario, sortIndex]);

        const handleNoteDeleteClick = React.useCallback(() => {
            setAnchorEl(null);
            onNoteDeleteClick(sortIndex, scenario);
        }, [onNoteDeleteClick, scenario, sortIndex]);

        return (
            <StyledBox key={note.title} display="flex" alignItems="center" gap={1}>
                {isEditing && (
                    <Box className={classes.cell}>
                        <CustomDragHandle className={classes.dragHandle} />
                    </Box>
                )}
                <Box flex={1} className={classes.cell}>
                    <Typography>
                        <b>{note.title}</b>
                    </Typography>
                    <Typography>{note.description}</Typography>
                    {note.channels && (
                        <Box mt={1} display="flex" flexWrap="wrap" gap={1}>
                            {Object.entries(note.channels)
                                .filter(([_, value]) => !!value)
                                .map(([channel]) => (
                                    <Chip
                                        key={channel}
                                        color="secondary"
                                        label={`${menuChannelLabel[channel as MenuChannel]}`}
                                        size="small"
                                    />
                                ))}
                        </Box>
                    )}
                    {note.values && (
                        <Box mt={1} display="flex" flexWrap="wrap" gap={1}>
                            {note.values.slice(0, 5).map(value => (
                                <Chip key={value} label={value} size="small" />
                            ))}
                            {note.values.length > 5 && (
                                <Chip
                                    label={`+${note.values.length - 5} more`}
                                    size="small"
                                    variant="outlined"
                                />
                            )}
                        </Box>
                    )}
                </Box>
                {isEditing && (
                    <Box>
                        <IconButton
                            aria-haspopup="true"
                            aria-controls={`edit-menu-${scenario}-${sortIndex}`}
                            size="small"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            open={!!anchorEl}
                            id={`edit-menu-${scenario}-${sortIndex}`}
                            keepMounted={false}
                            onClose={handleClose}
                            anchorEl={anchorEl}
                        >
                            <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                            <MenuItem onClick={handleNoteDeleteClick}>Delete</MenuItem>
                        </Menu>
                    </Box>
                )}
            </StyledBox>
        );
    }
);

interface LocationOrderNoteListProps {
    notes: LocationOrderNote[];
    disabled: boolean;
    scenario: string;
    isEditing: boolean;
    onNoteEditClick: (index: number, scenario: string) => void;
    onNoteDeleteClick: (index: number, scenario: string) => void;
}
const LocationOrderNoteList = SortableContainer<LocationOrderNoteListProps>(
    ({
        notes,
        disabled,
        scenario,
        isEditing,
        onNoteEditClick,
        onNoteDeleteClick
    }: LocationOrderNoteListProps) => {
        const renderRow = React.useCallback(
            (note: LocationOrderNote, index) => (
                <LocationOrderNoteRow
                    disabled={disabled}
                    key={`item-${note.title}`}
                    index={index}
                    sortIndex={index}
                    note={note}
                    scenario={scenario}
                    isEditing={isEditing}
                    onNoteEditClick={onNoteEditClick}
                    onNoteDeleteClick={onNoteDeleteClick}
                />
            ),
            [disabled, onNoteDeleteClick, onNoteEditClick, isEditing, scenario]
        );

        return <div>{notes.map(renderRow)}</div>;
    }
);

const CustomDragHandle = sortableHandle(({ className }: { className: string }) => (
    <DragHandle className={className} />
));

export const LocationOrderNotesView: React.FC<IProps> = ({ notes, setNotes, isEditing }) => {
    const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);
    const [editNote, setEditNote] = React.useState({ index: -1, scenario: null });

    const handleNoteEditClick = React.useCallback((index: number, scenario: string) => {
        setEditNote({ index, scenario });
    }, []);
    const handleNoteDelete = React.useCallback(
        (index: number, scenario: string) => {
            setNotes(notes => {
                const newNotes = { ...notes };
                newNotes[scenario] = removeArrayItem(newNotes[scenario], index);
                return newNotes;
            });
        },
        [setNotes]
    );

    const handleSortEnd = React.useCallback(
        (scenario: string) =>
            ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
                setNotes({ ...notes, [scenario]: [...arrayMove(notes[scenario], oldIndex, newIndex)] });
            },
        [notes, setNotes]
    );
    const renderNoteList = React.useCallback(
        ([scenario, notes], index: number, array: []) => (
            <Box
                marginBottom={index !== array.length - 1 && 2}
                display="flex"
                flexDirection="column"
                gap={1}
                key={`order-note-${index}`}
            >
                <Typography>{scenarios[scenario as unknown as keyof typeof scenarios]}</Typography>
                <Box>
                    <LocationOrderNoteList
                        onSortEnd={handleSortEnd(scenario)}
                        disabled={!isEditing}
                        lockAxis="y"
                        notes={notes}
                        scenario={scenario}
                        isEditing={isEditing}
                        useDragHandle
                        onNoteEditClick={handleNoteEditClick}
                        onNoteDeleteClick={handleNoteDelete}
                        lockToContainerEdges
                    />
                </Box>
            </Box>
        ),
        [handleNoteDelete, handleNoteEditClick, handleSortEnd, isEditing]
    );
    const toggleIsCreateModalOpen = React.useCallback(
        () => setIsCreateModalOpen(!isCreateModalOpen),
        [isCreateModalOpen]
    );
    const handleCreateNewNote = React.useCallback(
        (note: LocationOrderNote, scenario: OrderScenario) => {
            setNotes((notesState: LocationOrderNoteMap) => {
                const newNotes = { ...notesState };

                if (Array.isArray(newNotes[scenario])) {
                    newNotes[scenario] = [...newNotes[scenario], note];
                } else {
                    newNotes[scenario] = [note];
                }

                return newNotes;
            });
            toggleIsCreateModalOpen();
        },
        [toggleIsCreateModalOpen, setNotes]
    );
    const editNoteInitialValues = React.useMemo(() => {
        if (editNote.index !== -1) {
            const { index, scenario } = editNote;
            const defaultChannels = Object.keys(menuChannelLabel).map(channel => channel as MenuChannel);
            const channels = Object.entries(notes[scenario][index]?.channels ?? {})
                ?.filter(([_, value]) => value)
                .map(([channel]) => channel as MenuChannel);

            const values =
                notes[scenario][index]?.values?.map(value => ({
                    label: value,
                    value
                })) ?? [];
            const noteToEdit: ILocationOrderNoteForm = {
                ...notes[scenario][index],
                scenario,
                channels: channels.length === 0 ? defaultChannels : channels,
                values
            };
            return noteToEdit;
        }

        return null;
    }, [editNote, notes]);
    const handleEditClose = React.useCallback(() => setEditNote({ index: -1, scenario: null }), []);
    const handleEdit = React.useCallback(
        (formValues: ILocationOrderNoteForm) => {
            setNotes(notes => {
                const newNotes = { ...notes };

                const channels = Object.values(MenuChannel).reduce((acc, channel) => {
                    acc[channel] = formValues.channels?.includes(channel) ?? false;
                    return acc;
                }, {} as MenuChannelFlags);

                const values = formValues.values.map(value => value.value);

                const noteData: LocationOrderNote = {
                    title: formValues.title,
                    description: formValues.description,
                    channels,
                    values
                };

                if (formValues.scenario === editNote.scenario) {
                    newNotes[formValues.scenario][editNote.index] = noteData;
                } else {
                    newNotes[editNote.scenario] = removeArrayItem(
                        newNotes[editNote.scenario],
                        editNote.index
                    );

                    newNotes[formValues.scenario] = [
                        ...(Array.isArray(newNotes[formValues.scenario])
                            ? newNotes[formValues.scenario]
                            : []),
                        noteData
                    ];
                }

                return newNotes;
            });

            handleEditClose();
        },
        [editNote.index, editNote.scenario, handleEditClose, setNotes]
    );

    const hasNotes = React.useMemo(
        () => Object.values(notes).some(noteArray => noteArray?.length > 0),
        [notes]
    );

    return (
        <>
            <Grid container>
                {hasNotes ? (
                    <Grid item xs={12}>
                        {Object.entries(notes)
                            .filter(([, notes]) => notes?.length > 0)
                            .map(renderNoteList)}
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <CardViewTextRowValue value="You have no notes configured" />
                    </Grid>
                )}
                {isEditing && (
                    <Grid item xs={12}>
                        <Box width="100%" display="flex" marginTop={2}>
                            <Button variant="outlined" onClick={toggleIsCreateModalOpen}>
                                <AddIcon />
                            </Button>
                        </Box>
                    </Grid>
                )}
            </Grid>
            <CreateOrderNoteModal
                open={isCreateModalOpen}
                title="Create a new note"
                onClose={toggleIsCreateModalOpen}
                onAddition={handleCreateNewNote}
            />
            {!!editNoteInitialValues && (
                <EditLocationOrderNote
                    onEdit={handleEdit}
                    onClose={handleEditClose}
                    open={editNote.index !== -1}
                    initialValues={editNoteInitialValues}
                />
            )}
        </>
    );
};
