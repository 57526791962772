import { DateRange, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import { add, startOfMonth, sub } from 'date-fns';

export const defaultRangeShortcutsItems: PickersShortcutsItem<DateRange<Date>>[] = [
    {
        label: 'Today',
        getValue: () => {
            const today = new Date();
            return [today, today];
        }
    },
    {
        label: 'Yesterday',
        getValue: () => {
            const yesterday = sub(new Date(), { days: 1 });
            return [yesterday, yesterday];
        }
    },
    {
        label: 'Last 7 Days',
        getValue: () => {
            const today = new Date();
            return [sub(today, { days: 7 }), today];
        }
    },
    {
        label: 'Current Month',
        getValue: () => {
            const today = new Date();
            return [startOfMonth(today), today];
        }
    },
    {
        label: 'Last 30 Days',
        getValue: () => {
            const today = new Date();
            return [sub(today, { days: 30 }), today];
        }
    },
    {
        label: 'Last 3 Months',
        getValue: () => {
            const today = new Date();
            const threeMonthsAgo = sub(today, { months: 3 });
            const threeMonthsAgoEx = add(threeMonthsAgo, { days: 1 });
            return [threeMonthsAgoEx, today];
        }
    },
    { label: 'Reset', getValue: () => [null, null] }
];
