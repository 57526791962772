import React from 'react';
import { GridCellParams, GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { ScheduledTaskType } from 'components/scheduled-tasks/scheduledTasks.types';
import { Box, Button, Paper, Stack, styled, TablePagination } from '@mui/material';
import { DEFAULT_PAGE_LIMIT } from 'lib/hooks/usePagedData';
import { MuiTheme } from 'config/theme';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { CAMPAIGNS_TRIGGERED_VIEW } from 'config/routes';
import { RuleResource } from 'components/rules/rules.types';
import { useRules } from 'components/rules/hooks/useRules';
import { useRulesFilter } from 'components/rules/hooks/useRulesFilter';
import { getActionTypeOptions } from 'components/actions/utils';
import { SelectGridColumn } from 'lib/MuiGrid/Columns';
import { ScheduledTaskTypeLabelMap } from 'components/scheduled-tasks/helpers';
import { defaultDateTimeValueFormatter } from 'lib/MuiGrid/helpers';
import clsx from 'clsx';
import { green, grey } from '@mui/material/colors';
import { relativeTimeStringToDisplay } from 'lib/relativeTimeString';
import { ActionType } from 'components/actions/models/Action';
import { deepLinkValueFormatter } from 'components/notification/helpers';
import { Row } from 'ui/Flex';
import { RuleFilters } from 'components/rules/filters/RuleFilters';
import { useGridSingleSelection } from 'lib/MuiGrid/useGridSingleSelection';
import { useHistory } from 'react-router-dom';

const PREFIX = 'RulesTable';

const classes = {
    green: `${PREFIX}-green`,
    grey: `${PREFIX}-grey`
};

const StyledDataGrid = styled(MuiGrid)({
    [`& .${classes.green}`]: { color: green[500] },
    [`& .${classes.grey}`]: { color: grey[500] }
});

interface RulesTableProps {
    isLoading: boolean;
    onCreateClick: (rule?: RuleResource) => void;
    onPauseUnpauseClick: (rule: RuleResource, active: boolean) => void;
    onUpdateClick: (rule: RuleResource) => void;
    onDeleteClick: (rule: RuleResource) => void;
    actionedRule: { id: string; status: 'created' | 'updated' | 'deleted' } | null;
}

export const RulesTable: React.FC<RulesTableProps> = ({
    isLoading,
    onCreateClick,
    onPauseUnpauseClick,
    onUpdateClick,
    onDeleteClick,
    actionedRule
}) => {
    const history = useHistory();
    const [filter, onFilterChange, isEmptyFilter] = useRulesFilter(
        'scheduledTask',
        'createScheduledTask',
        ScheduledTaskType.CREATE_PUSH_NOTIFICATION
    );
    const {
        rules,
        page,
        onPageChange,
        loading,
        count,
        formattedRules,
        sortModel,
        onSortModelChange,
        reset,
        noData
    } = useRules(filter, isEmptyFilter);

    const activeCellClassName = React.useCallback(
        (params: GridCellParams<RuleResource, boolean>) =>
            clsx({
                [classes.green]: params.value,
                [classes.grey]: !params.value
            }),
        []
    );
    const activeCellFormatter = React.useCallback<GridColDef['valueFormatter']>(
        (value: boolean) => (value ? 'Running' : 'Paused'),
        []
    );
    const actionTypeValueGetter = React.useCallback<GridColDef['valueFormatter']>((value: ActionType) => {
        const option = getActionTypeOptions().find(n => n.value === value);
        return option?.label || 'Unknown';
    }, []);
    const relativeDateValueFormatter = React.useCallback<GridColDef['valueFormatter']>(
        (value: string) => relativeTimeStringToDisplay(value, { symbols: false }),
        []
    );
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'ID',
                field: 'id',
                disableReorder: true,
                disableExport: true,
                sortable: false,
                width: 240
            },
            {
                headerName: 'Created',
                field: 'created',
                sortable: true,
                type: 'dateTime',
                width: 180,
                valueGetter: defaultDateTimeValueFormatter
            },
            {
                headerName: 'Updated',
                field: 'updated',
                sortable: true,
                type: 'dateTime',
                width: 180,
                valueGetter: defaultDateTimeValueFormatter
            },
            {
                headerName: 'Type',
                field: 'effect.impact.parameters.type',
                sortable: false,
                width: 180,
                ...SelectGridColumn(ScheduledTaskTypeLabelMap)
            },
            {
                headerName: 'Status',
                field: 'active',
                sortable: false,
                width: 90,
                cellClassName: activeCellClassName,
                valueFormatter: activeCellFormatter
            },
            {
                headerName: 'Send On',
                field: 'conditions.0.object',
                sortable: false,
                width: 180,
                valueFormatter: actionTypeValueGetter
            },
            {
                headerName: 'Delay By',
                field: 'effect.impact.parameters.triggerAt',
                sortable: false,
                width: 180,
                valueFormatter: relativeDateValueFormatter
            },
            {
                headerName: 'Title',
                field: 'effect.impact.parameters.parameters.title',
                sortable: false,
                width: 180
            },
            {
                headerName: 'Message',
                field: 'effect.impact.parameters.parameters.message',
                sortable: false,
                width: 360
            },
            {
                headerName: 'Tag',
                field: 'effect.impact.parameters.parameters.tag',
                sortable: true,
                width: 180
            },
            {
                headerName: 'Deep Link',
                field: 'effect.impact.parameters.parameters.deepLink',
                sortable: false,
                width: 90,
                valueGetter: deepLinkValueFormatter
            }
        ],
        [actionTypeValueGetter, activeCellClassName, activeCellFormatter, relativeDateValueFormatter]
    );
    const [selectedRule, rowSelectionModel, handleSelectionChange] = useGridSingleSelection(rules);
    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            handleSelectionChange([]);
            onPageChange(page);
        },
        [handleSelectionChange, onPageChange]
    );
    const handleSortChange = React.useCallback(
        (model: GridSortModel) => {
            handleSelectionChange([]);
            onSortModelChange(model);
        },
        [handleSelectionChange, onSortModelChange]
    );

    React.useEffect(() => {
        handleSelectionChange([]);
        reset();
    }, [actionedRule, handleSelectionChange, reset]);

    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={count}
                onPageChange={handlePageChange}
                page={page}
                disabled={isLoading || loading}
                rowsPerPage={DEFAULT_PAGE_LIMIT}
                rowsPerPageOptions={[DEFAULT_PAGE_LIMIT]}
                component="div"
                sx={{
                    '&:last-child': {
                        paddingRight: MuiTheme.spacing(1)
                    }
                }}
            />
        ),
        [count, handlePageChange, page, isLoading, loading]
    );
    const NoRowsOverlay = React.useCallback(
        () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
                {isEmptyFilter && !isLoading && !loading && !count
                    ? 'No triggers created'
                    : 'No notifications found. Please adjust your filter'}
            </Stack>
        ),
        [count, isEmptyFilter, isLoading, loading]
    );

    React.useEffect(() => {
        handleSelectionChange([]);
        reset();
    }, [actionedRule, handleSelectionChange, reset]);

    const handleCreateClick = React.useCallback(() => {
        onCreateClick(selectedRule);
    }, [selectedRule, onCreateClick]);
    const handlePauseUnpauseClick = React.useCallback(() => {
        onPauseUnpauseClick(selectedRule, !selectedRule.active);
    }, [selectedRule, onPauseUnpauseClick]);
    const handleUpdateClick = React.useCallback(() => {
        onUpdateClick(selectedRule);
    }, [selectedRule, onUpdateClick]);
    const handleDeleteClick = React.useCallback(() => {
        onDeleteClick(selectedRule);
    }, [selectedRule, onDeleteClick]);
    const handleView = React.useCallback(() => {
        history.push(CAMPAIGNS_TRIGGERED_VIEW.replace(':triggerId', selectedRule?.id));
    }, [history, selectedRule?.id]);

    React.useEffect(() => {
        if (noData) {
            handleCreateClick();
        }
    }, [handleCreateClick, noData]);

    return (
        <React.Fragment>
            <Box paddingY={1}>
                <Row align="space-between" valign="flex-end" gutter>
                    <RuleFilters filter={filter} onChange={onFilterChange} />
                    <LoadingButton
                        variant="contained"
                        onClick={handleCreateClick}
                        loading={isLoading}
                        disabled={isLoading || !!selectedRule}
                        size="small"
                    >
                        Create
                    </LoadingButton>
                    <Button
                        variant="contained"
                        onClick={handleView}
                        size="small"
                        disabled={isLoading || !selectedRule}
                    >
                        View
                    </Button>
                    <LoadingButton
                        variant="outlined"
                        onClick={handlePauseUnpauseClick}
                        loading={isLoading}
                        disabled={isLoading || !selectedRule}
                        size="small"
                        sx={{ minWidth: 80 }}
                    >
                        {selectedRule?.active ? 'Pause' : 'Resume'}
                    </LoadingButton>
                    <LoadingButton
                        variant="outlined"
                        onClick={handleUpdateClick}
                        loading={isLoading}
                        disabled={isLoading || !selectedRule}
                        size="small"
                    >
                        Update
                    </LoadingButton>
                    <LoadingButton
                        variant="outlined"
                        color="error"
                        onClick={handleDeleteClick}
                        loading={isLoading}
                        disabled={isLoading || !selectedRule}
                        size="small"
                    >
                        Delete
                    </LoadingButton>
                </Row>
            </Box>
            <Paper sx={{ flex: 1, display: 'flex', minHeight: 0 }}>
                <StyledDataGrid
                    rows={formattedRules}
                    columns={columns}
                    hideFooter={false}
                    rowCount={count}
                    pagination
                    storageName={GridStorageName.Rules}
                    disableColumnFilter
                    paginationMode="server"
                    loading={loading}
                    sortModel={sortModel}
                    onSortModelChange={handleSortChange}
                    sortingMode="server"
                    disableMultipleRowSelection
                    disableMultipleColumnsSorting
                    disableRowSelectionOnClick={false}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={handleSelectionChange}
                    slots={{
                        pagination: Pagination,
                        noRowsOverlay: NoRowsOverlay
                    }}
                />
            </Paper>
        </React.Fragment>
    );
};
