import { GridColTypeDef } from '@mui/x-data-grid-pro';
import { isString } from 'lib/typeguards';
import { Option } from 'lib/types';
import { renderMuiGridImage, renderMuiGridImageEdit } from './image/MuiGridImage';
import { renderMuiGridMultiselectView } from './select/MuiGridMultiselectView';
import { renderMuiGridMultiSelect, renderMuiGridSelect } from './select/MuiGridSelect';
import { renderMuiGridSelectView } from './select/MuiGridSelectView';
import { renderMuiGridTextView } from './text/MuiGridTextView';
import { GridTimeRangeCell } from './time-range/GridTimeRange';
import { renderColorPickerCell, renderColorPickerEditCell } from './color/MuiGridColorPicker';

export const MUI_GRID_CUSTOM_CELL_CLASSES = {
    image: 'MuiGrid-image',
    cellOnlyHover: 'MuiGrid-cell-only-hover',
    cellOnlyHoverParent: 'MuiGrid-cell-only-hover-parent'
};

export function SelectGridColumn(labels: Record<string, string>): GridColTypeDef {
    const options = Object.entries(labels).map(([value, label]) => ({ value, label }));
    return {
        renderCell: props => renderMuiGridTextView(props, isString(props.value) ? labels[props.value] : ''),
        renderEditCell: props => renderMuiGridSelect(props, options)
    };
}

export function SelectTitleColumn(
    labels: Record<string, string> | Map<string, string>,
    emptyOption?: boolean
): GridColTypeDef {
    const options: Option[] = [];
    if (emptyOption) {
        options.push({ label: 'None', value: '' });
    }
    if (labels instanceof Map) {
        for (const [value, label] of labels.entries()) {
            options.push({ label, value });
        }
    } else {
        Object.entries(labels).forEach(([value, label]) => {
            options.push({ label, value });
        });
    }
    const getName = (id: string) => {
        if (labels instanceof Map) {
            return labels.get(id) ?? id;
        }
        return labels[id] ?? id;
    };
    const sort = (v1: string | number, v2: string | number) =>
        getName('' + v1).localeCompare(getName('' + v2));
    return {
        renderCell: props => renderMuiGridSelectView(props, labels),
        renderEditCell: props => renderMuiGridSelect(props, options),
        sortComparator: sort
    };
}

export function MultiSelectGridColumn(
    labels: Record<string, string> | Map<string, string>,
    emptyLabel?: string,
    verify?: boolean
): GridColTypeDef {
    const options: Option[] = [];
    if (labels instanceof Map) {
        for (const [value, label] of labels.entries()) {
            options.push({ label, value });
        }
    } else {
        Object.entries(labels).forEach(([value, label]) => {
            options.push({ label, value });
        });
    }
    const getName = (id: string) => {
        if (labels instanceof Map) {
            return labels.get(id) ?? id;
        }
        return labels[id] ?? id;
    };
    const getValuesName = (value: string | number) => {
        if (!Array.isArray(value) || !value.length) {
            return '';
        }
        return value.map((item: string) => getName(item)).join(', ');
    };
    const sort = (v1: string | number, v2: string | number) =>
        getValuesName(v1).localeCompare(getValuesName(v2));
    return {
        sortComparator: sort,
        renderCell: props => renderMuiGridMultiselectView(props, labels, emptyLabel),
        renderEditCell: props =>
            !props.value || Array.isArray(props.value)
                ? renderMuiGridMultiSelect(props, options, verify)
                : 'Something went wrong'
    };
}

export function ImagePickerColumn(filePrefix?: string, editable?: boolean, aspect = 1): GridColTypeDef {
    return {
        renderCell: props => renderMuiGridImage(props, aspect, editable),
        renderEditCell: props => renderMuiGridImageEdit(props, filePrefix, aspect),
        editable,
        cellClassName: MUI_GRID_CUSTOM_CELL_CLASSES.image
    };
}

export function TimeRangeGridColumn(): GridColTypeDef {
    const sort = (v1: string | number, v2: string | number) =>
        ('' + (Array.isArray(v1) && v1[0])).localeCompare('' + (Array.isArray(v2) && v2[0]));
    return {
        renderEditCell: GridTimeRangeCell,
        sortComparator: sort
    };
}

export function ColorPickerColumn(editable?: boolean): GridColTypeDef {
    return {
        renderCell: renderColorPickerCell,
        renderEditCell: renderColorPickerEditCell,
        editable: editable ?? true
    };
}

export function ProductIdSortComparator(v1: string | number, v2: string | number) {
    const [compoundId, subId] = ('' + v1).split('/');
    const [compoundId2, subId2] = ('' + v2).split('/');
    const [_catId, id] = compoundId.split('^');
    const [_catId2, id2] = compoundId2.split('^');
    if (id === id2) {
        return Number(subId) - Number(subId2);
    }
    return Number(id) - Number(id2);
}

export const NumberIdSortComparator = (v1: string | number, v2: string | number) => Number(v1) - Number(v2);
