/* eslint-disable @typescript-eslint/await-thenable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { AudienceCreateRequestData, createAudience } from 'store/audiences/audiencesActions';
import { ApplicationState } from 'store/store';
import { MuiModal } from 'ui/MuiModal';
import { AudienceCreateForm, AudienceCreateFormData } from '../forms/AudienceCreateForm';
import { Audience, AudienceType, isAudience } from '../models/segmentModel';
import { getTimeUnitsToSeconds } from 'lib/timeHelpers';

interface AudienceCreateModalProps {
    open: boolean;
    onClose: () => void;
    initialData?: Partial<AudienceCreateFormData>;
    onSuccess?: (audience: Audience) => void;
}

const AudienceCreateModalContent: React.FC<AudienceCreateModalProps> = ({
    onSuccess,
    initialData,
    onClose
}) => {
    const { isLoading } = useSelector((state: ApplicationState) => state.audiences);
    const dispatch = useDispatch();
    const handleSubmit = React.useCallback(
        async ({ expires, measure, ...rest }: AudienceCreateFormData) => {
            const createBody: AudienceCreateRequestData = {
                ...rest
            };
            if (rest.type !== AudienceType.PERSISTENT) {
                createBody.expiryHorizon = getTimeUnitsToSeconds(Number(expires), measure);
            }
            const audience = await createAudience(createBody)(dispatch);
            if (!!audience && isAudience(audience) && !!onSuccess) {
                onSuccess(audience);
            }
        },
        [dispatch, onSuccess]
    );
    return (
        <Box width={60 * 8}>
            <AudienceCreateForm
                initialData={initialData}
                onSubmit={handleSubmit}
                isLoading={isLoading}
                onClose={onClose}
            />
        </Box>
    );
};

export const AudienceCreateModal = (props: AudienceCreateModalProps) => (
    <MuiModal open={props.open} onClose={props.onClose}>
        <AudienceCreateModalContent {...props} />
    </MuiModal>
);
