import React from 'react';
import { Box, InputAdornment } from '@mui/material';
import { Row } from 'ui/Flex';
import Search from '@mui/icons-material/Search';
import { DebouncedGridFilterInput } from 'lib/grid/DebouncedGridFilterInput';

export interface NotificationsFilter {
    search?: string;
}
export type NotificationsFilterChange = (field: keyof NotificationsFilter, value: string) => void;

interface NotificationsFiltersProps {
    filter: NotificationsFilter;
    onChange: NotificationsFilterChange;
}

export const NotificationsFilters: React.FC<NotificationsFiltersProps> = ({ filter, onChange }) => {
    const handleSearchChange = React.useCallback(
        (value?: string) => {
            onChange('search', value);
        },
        [onChange]
    );

    return (
        <Row flex={1} wrap="wrap" valign="center">
            <Box marginTop={0.5} marginRight={1}>
                <DebouncedGridFilterInput
                    value={filter.search}
                    onChange={handleSearchChange}
                    name="search"
                    placeholder="Search by title, message or tag"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        )
                    }}
                />
            </Box>
        </Row>
    );
};
