import React, { useCallback, useState } from 'react';
import { Typography, styled } from '@mui/material';
import { GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { ChromePicker, ColorResult } from 'react-color';
import { isEmptyString } from 'lib/typeguards';

const PREFIX = 'ColorPicker';
const classes = {
    color: `${PREFIX}-color`,
    swatch: `${PREFIX}-swatch`,
    popover: `${PREFIX}-popover`,
    cover: `${PREFIX}-cover`
};

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    '& p': {
        lineHeight: 'unset',
        fontSize: 'unset'
    },
    [`& .${classes.color}`]: {
        width: theme.spacing(6),
        borderRadius: theme.shape.borderRadius,
        border: `1px ${theme.palette.divider} solid`,
        cursor: 'pointer'
    },
    [`& .${classes.swatch}`]: {
        width: '100%',
        height: '100%',
        padding: theme.spacing(0.5),
        paddingLeft: 0,
        paddingRight: 0,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',
        '&.edit': {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1)
        }
    },
    [`& .${classes.popover}`]: {
        position: 'absolute',
        zIndex: 2
    },
    [`& .${classes.cover}`]: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
    }
}));

interface MuiGridColorPickerProps extends GridRenderEditCellParams {}

const Cell = ({ value, id, field, api }: MuiGridColorPickerProps) => {
    const handleClick = useCallback(() => {
        api.startCellEditMode({ id, field });
    }, [api, field, id]);

    return (
        <Root>
            <div className={classes.swatch}>
                <div
                    className={classes.color}
                    style={{ backgroundColor: value ? String(value) : undefined }}
                    onClick={handleClick}
                />
                <Typography>{value}</Typography>
            </div>
        </Root>
    );
};

const EditCell = ({ value, id, field, api }: MuiGridColorPickerProps) => {
    const [color, setColor] = useState(value);

    const handleClose = useCallback(() => {
        if (api.getCellMode(id, field) === 'edit') {
            api.stopCellEditMode({ id, field });
        }
    }, [api, field, id]);

    const handleChangeComplete = useCallback(
        (newColor: ColorResult) => {
            if (isEmptyString(value)) {
                setColor(null);

                api.setEditCellValue({ id, field, value: null });
                api.stopCellEditMode({ id, field });

                handleClose();
                return;
            }

            const newColorHex = newColor.hex;

            setColor(newColorHex);
            api.setEditCellValue({ id, field, value: newColorHex });
            api.stopCellEditMode({ id, field });
        },
        [api, field, handleClose, id, value]
    );

    return (
        <Root>
            <div className={`${classes.swatch} edit`}>
                <div
                    className={classes.color}
                    style={{ backgroundColor: color ? String(color) : undefined }}
                />
                <Typography>{color}</Typography>
            </div>
            <div className={classes.popover}>
                <div className={classes.cover} onClick={handleClose} />
                <ChromePicker disableAlpha color={color} onChangeComplete={handleChangeComplete} />
            </div>
        </Root>
    );
};

export const renderColorPickerCell = (params: GridRenderCellParams) => <Cell {...params} />;
export const renderColorPickerEditCell = (params: GridRenderEditCellParams) => <EditCell {...params} />;
