import React from 'react';
import { Grid, Hidden } from '@mui/material';
import { FallbackSkeleton } from 'ui/skeleton/FallbackSkeleton';
import { SkeletonProps } from 'ui/skeleton/SkeletonComponent';
import { Notification } from 'components/notification/models/Notification';
import { RuleResource } from 'components/rules/rules.types';
import { NotificationReceiptsCard } from 'components/notification/cards/NotificationReceiptsCard';
import { NotificationResultsCard } from 'components/notification/cards/NotificationResultsCard';
import { RuleScheduledTaskNotificationDetailsCard } from 'components/rules/scheduled-notification/cards/RuleScheduledTaskNotificationDetailsCard';

export interface RuleScheduledTaskNotificationViewProps {
    rule: RuleResource;
    notifications: Notification<string>[];
    metrics: { sent: number; skipped: number; failures: number };
    loading: boolean;
}

const fallbackProps: Partial<SkeletonProps> = {
    height: '320px'
};

export const RuleScheduledTaskNotificationView: React.FC<RuleScheduledTaskNotificationViewProps> = ({
    rule,
    notifications,
    metrics,
    loading
}) => {
    const isLoading = React.useMemo(() => loading || !rule?._id, [loading, rule]);
    const isUnsent = React.useMemo(() => !rule?.active, [rule]);
    return (
        <>
            <Hidden lgDown>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps}>
                                    <RuleScheduledTaskNotificationDetailsCard rule={rule} />
                                </FallbackSkeleton>
                            </Grid>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps}>
                                    <NotificationResultsCard
                                        isLoading={isLoading}
                                        isOngoing
                                        metrics={metrics}
                                    />
                                </FallbackSkeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps}>
                                    <NotificationReceiptsCard
                                        notifications={notifications}
                                        extraFilters={[{ key: 'ruleId', value: rule?._id }]}
                                        isLoading={isLoading}
                                        isUnsent={isUnsent}
                                        showNotificationIdFilter
                                    />
                                </FallbackSkeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdDown lgUp>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps}>
                                    <RuleScheduledTaskNotificationDetailsCard rule={rule} />
                                </FallbackSkeleton>
                            </Grid>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps}>
                                    <NotificationResultsCard
                                        isLoading={isLoading}
                                        isOngoing
                                        metrics={metrics}
                                    />
                                </FallbackSkeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps}>
                                    <NotificationReceiptsCard
                                        notifications={notifications}
                                        extraFilters={[{ key: 'ruleId', value: rule?._id }]}
                                        isLoading={isLoading}
                                        isUnsent={isUnsent}
                                        showNotificationIdFilter
                                    />
                                </FallbackSkeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FallbackSkeleton loading={loading} {...fallbackProps}>
                            <RuleScheduledTaskNotificationDetailsCard rule={rule} />
                        </FallbackSkeleton>
                    </Grid>
                    <Grid item xs={12}>
                        <FallbackSkeleton loading={loading} {...fallbackProps}>
                            <NotificationResultsCard isLoading={isLoading} isOngoing metrics={metrics} />
                        </FallbackSkeleton>
                    </Grid>
                    <Grid item xs={12}>
                        <FallbackSkeleton loading={loading} {...fallbackProps}>
                            <NotificationReceiptsCard
                                notifications={notifications}
                                extraFilters={[{ key: 'ruleId', value: rule?._id }]}
                                isLoading={isLoading}
                                isUnsent={isUnsent}
                                showNotificationIdFilter
                            />
                        </FallbackSkeleton>
                    </Grid>
                </Grid>
            </Hidden>
        </>
    );
};
