import { useHistoryFilters } from 'lib/hooks/useHistoryFilters';
import React from 'react';
import { QueryParameter } from 'lib/HttpClient';
import { RulesFilter, RulesFilterChange } from 'components/rules/filters/RuleFilters';
import { isBooleanString } from 'lib/typeguards';

export function useRulesFilter(
    controller?: string,
    method?: string,
    parameterData?: any
): [RulesFilter, RulesFilterChange, boolean, boolean] {
    const { search, onSearch } = useHistoryFilters();
    const [filterTouched, setFilterTouched] = React.useState<boolean>(false);
    const [filter, setFilter] = React.useState<RulesFilter>(() => ({
        controller: controller || undefined,
        method: method || undefined,
        parameterData: parameterData || undefined,
        active: search.active || undefined,
        search: search.search || undefined
    }));
    const onFilterChange = React.useCallback<RulesFilterChange>((field, value) => {
        setFilterTouched(true);
        setFilter(currentFilter => ({ ...currentFilter, [field]: value }));
    }, []);
    React.useEffect(() => {
        const { controller: _, method: __, parameterData: ___, ...rest } = filter;
        onSearch({ ...rest });
    }, [filter, onSearch]);
    const isEmpty = React.useMemo(() => !filter.active && !filter.search, [filter]);
    return [filter, onFilterChange, isEmpty, filterTouched];
}

export const getRulesFilterQuery = (filter: RulesFilter): QueryParameter[] => {
    const queryParameters = [];
    if (filter.controller) {
        queryParameters.push({ key: 'controller', value: filter.controller });
    }
    if (filter.method) {
        queryParameters.push({ key: 'method', value: filter.method });
    }
    if (filter.parameterData) {
        queryParameters.push({ key: 'parameterData', value: filter.parameterData });
    }
    if (isBooleanString(filter.active)) {
        queryParameters.push({ key: 'active', value: filter.active });
    }
    if (filter.search) {
        queryParameters.push({ key: 'search', value: filter.search });
    }
    return queryParameters;
};
