import { styled } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { titleize } from 'lib/helpers';
import React from 'react';

import { NotificationStatus } from 'components/notification/models/Notification';

const PREFIX = 'NotificationStatusCell';

const classes = {
    green: `${PREFIX}-green`,
    yellow: `${PREFIX}-yellow`,
    red: `${PREFIX}-red`
};

const Root = styled('span')({
    [`&.${classes.green}`]: { color: green[500] },
    [`&.${classes.yellow}`]: { color: orange[500] },
    [`&.${classes.red}`]: { color: red[500] }
});

interface NotificationStatusCellProps {
    status: NotificationStatus;
}

const NotificationStatusCell: React.FC<NotificationStatusCellProps> = ({ status }) => {
    const className = React.useMemo(() => {
        if (status === NotificationStatus.ERROR) {
            return classes.red;
        }
        if (status === NotificationStatus.PENDING || status === NotificationStatus.DRAFT) {
            return classes.yellow;
        }
        return classes.green;
    }, [status]);
    const customStatus = React.useMemo(() => {
        if (status === NotificationStatus.DONE) {
            return 'SENT';
        }
        return status.toUpperCase();
    }, [status]);

    return <Root className={className}>{titleize(customStatus).replace('_', ' ')}</Root>;
};

export const renderNotificationStatusCell = (params: GridRenderCellParams) => (
    <NotificationStatusCell status={params.value} />
);
