import React from 'react';
import { FormControl, TextFieldVariants } from '@mui/material';
import { Field, FieldAttributes, FieldProps } from 'formik';
import type {} from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useLocalDateTimeFormat } from 'lib/hooks/useLocalDateTimeFormat';

interface DatePickerFormFieldProps {
    label?: React.ReactNode;
    description?: string;
    variant?: TextFieldVariants;
    readOnly?: boolean;
    disabled?: boolean;
    disableOpenPicker?: boolean;
}

export const DatePickerFormField = ({
    label,
    description,
    variant,
    readOnly,
    disabled,
    disableOpenPicker,
    ...props
}: FieldAttributes<DatePickerFormFieldProps>): JSX.Element => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <DatePickerFormFieldComponent
                label={label}
                description={description}
                variant={variant}
                readOnly={readOnly}
                disabled={disabled}
                disableOpenPicker={disableOpenPicker}
                {...childProps}
            />
        )}
    </Field>
);

const DatePickerFormFieldComponent = ({
    field,
    meta,
    label,
    description,
    readOnly,
    variant,
    disabled,
    disableOpenPicker,
    form
}: FieldProps & DatePickerFormFieldProps): JSX.Element => {
    const isError = meta.touched && !!meta.error;
    const { getUserDateFormat } = useLocalDateTimeFormat();
    const inputFormat = React.useMemo(getUserDateFormat, [getUserDateFormat]);
    const handleChange = React.useCallback(
        (value: Date) => {
            field.onChange({ target: { name: field.name, value } });
        },
        [field]
    );
    const handleBlur = React.useCallback(() => {
        // Remove this after updating mui/date-pickers, we need this in order to correctly validate the field
        setTimeout(() => {
            form.validateField(field.name);
            form.setFieldTouched(field.name);
        }, 0);
    }, [field.name, form]);
    return (
        <FormControl error={isError} fullWidth>
            <DatePicker
                onChange={handleChange}
                value={field.value ? new Date(field.value) : null}
                slotProps={{
                    textField: {
                        error: isError,
                        label,
                        helperText: isError ? meta.error : description,
                        variant,
                        disabled,
                        onBlur: handleBlur
                    }
                }}
                readOnly={readOnly}
                format={inputFormat}
                disabled={disabled}
                disableOpenPicker={disableOpenPicker}
                onClose={handleBlur}
            />
        </FormControl>
    );
};
