import { CustomerSegment } from 'components/customers/models/Customer';
import { Location } from 'components/location/models/LocationModel';
import logger from 'lib/logger';
import { Notification } from '../models/Notification';
import { ContentModule } from 'components/content/models/ContentModule';

enum NotificationsReducerActionType {
    START_REQUEST = 'START_REQUEST',
    LOAD_SUCCESS = 'LOAD_SUCCESS',
    LOAD_ERROR = 'LOAD_ERROR',
    MODAL_ACTION = 'MODAL_ACTION',
    CREATE_SUCCESS = 'CREATE_SUCCESS',
    UNKNOWN = 'UNKNOWN'
}

interface IState {
    isLoading: boolean;
    segments: CustomerSegment[];
    locations: Location[];
    modules: ContentModule[];
    notificationToResend: Notification<string>;
    createNotificationModalOpen: boolean;
    actionedNotification: { id: string; status: 'created' } | null;
}
type IReducerAction = IUnknownAction | IProgressAction | ILoadingAction | IModalAction | ICreateAction;
interface IBaseReducerAction {
    type: NotificationsReducerActionType;
}
interface IUnknownAction extends IBaseReducerAction {
    type: NotificationsReducerActionType.UNKNOWN;
}
interface IProgressAction extends IBaseReducerAction {
    type: NotificationsReducerActionType.START_REQUEST;
}
interface ILoadingAction extends IBaseReducerAction {
    type: NotificationsReducerActionType.LOAD_SUCCESS | NotificationsReducerActionType.LOAD_ERROR;
    locations?: Location[];
    segments?: CustomerSegment[];
    modules?: ContentModule[];
    nextKey?: string;
}
interface IModalAction extends IBaseReducerAction {
    type: NotificationsReducerActionType.MODAL_ACTION;
    isOpen: boolean;
    notificationToResend?: Notification<string>;
}
interface ICreateAction extends IBaseReducerAction {
    type: NotificationsReducerActionType.CREATE_SUCCESS;
    notification: Notification<string>;
}

const initialState: IState = {
    isLoading: true,
    segments: [],
    locations: [],
    modules: [],
    notificationToResend: null,
    createNotificationModalOpen: false,
    actionedNotification: null
};

function reducer(state: IState, action: IReducerAction): IState {
    switch (action.type) {
        case NotificationsReducerActionType.START_REQUEST:
            return { ...state, isLoading: true };
        case NotificationsReducerActionType.LOAD_SUCCESS: {
            const dataToMerge: Partial<IState> = {
                isLoading: false
            };
            if (action.locations) {
                dataToMerge.locations = action.locations;
            }
            if (action.segments) {
                dataToMerge.segments = action.segments;
            }
            if (action.modules) {
                dataToMerge.modules = action.modules;
            }
            return {
                ...state,
                ...dataToMerge
            };
        }
        case NotificationsReducerActionType.LOAD_ERROR:
            return {
                ...state,
                isLoading: false,
                locations: [],
                segments: [],
                modules: []
            };
        case NotificationsReducerActionType.MODAL_ACTION:
            return {
                ...state,
                isLoading: false,
                createNotificationModalOpen: action.isOpen,
                notificationToResend: action.notificationToResend
            };
        case NotificationsReducerActionType.CREATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                createNotificationModalOpen: false,
                actionedNotification: { status: 'created', id: action.notification._id }
            };
        default:
            logger.warn(
                `Expected reducer action type, but got: ${JSON.stringify(
                    action.type
                )}. NotificationsPage.tsx-reducer`
            );
            return state;
    }
}

export default { reducer, initialState, ActionType: NotificationsReducerActionType };
