import {
    DataGridProProps,
    GridColDef,
    gridColumnFieldsSelector,
    GridColumnResizeParams,
    GridColumnVisibilityModel,
    GridPinnedColumnFields
} from '@mui/x-data-grid-pro';
import {
    isBoolean,
    isDefined,
    isNumber,
    isOptional,
    isOptionalArrayOf,
    isString,
    isUndefined
} from 'lib/typeguards';
import React from 'react';
import { debounce } from 'debounce';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { isRecordOf } from '@pepperhq/menu-sdk/dist/libs/typeUtils';

const GRID_STORAGE_VERSION_KEY = 'grid-state-version';
function getStorageVersion() {
    const version = localStorage.getItem(GRID_STORAGE_VERSION_KEY);
    return version;
}

// Versions:
// 1 - Initial version
export function checkAndUpdateGlobalStorageVersion() {
    const currentVersion = getStorageVersion();
    if (currentVersion !== '1') {
        localStorage.clear();
        localStorage.setItem(GRID_STORAGE_VERSION_KEY, '1');
    }
}

export enum GridStorageName {
    Categories = 'grid-state-categories',
    CategoryGroups = 'grid-state-category-group',
    Products = 'grid-state-products',
    CategoryProducts = 'grid-state-category-products',
    Modifiers = 'grid-state-modifiers',
    ModifierOptions = 'grid-state-modifier-options',
    ModifiersProducts = 'grid-state-modifier-products',
    Notifications = 'grid-state-notifications',
    NotificationReceipts = 'grid-state-notification-receipts',
    Taxes = 'grid-state-taxes',
    Tags = 'grid-state-tags',
    DynamicImages = 'grid-state-dynamic-images',
    WeeklyAvailability = 'grid-state-weekly',
    ProductAvailability = 'grid-state-product-availability',
    Orders = 'grid-state-orders',
    Vouchers = 'grid-state-vouchers',
    ExternalPerks = 'grid-state-external-perks',
    Perks = 'grid-state-perks',
    Rules = 'grid-state-rules',
    ScheduledTasks = 'grid-state-scheduled-tasks',
    Clerks = 'grid-state-clerks',
    Menus = 'grid-state-menus',
    OrderingAvailability = 'grid-state-ordering-availability',
    Tiers = 'grid-state-tiers',
    BirthdaySchemes = 'grid-state-birthday-schemes',
    PowercardGifting = 'grid-state-powercard-gifting',
    PowercardLiability = 'grid-state-powercard-liability',
    Transactions = 'grid-state-transactions',
    Disputes = 'grid-state-disputes',
    Audiences = 'grid-state-audiences',
    AdjustmentSchemes = 'grid-state-adjustment-schemes'
}

const versions: Record<GridStorageName, string> = {
    [GridStorageName.Categories]: '1',
    [GridStorageName.CategoryGroups]: '1',
    [GridStorageName.Products]: '1',
    [GridStorageName.CategoryProducts]: '1',
    [GridStorageName.Modifiers]: '1',
    [GridStorageName.ModifierOptions]: '1',
    [GridStorageName.ModifiersProducts]: '1',
    [GridStorageName.Notifications]: '1',
    [GridStorageName.NotificationReceipts]: '1',
    [GridStorageName.Taxes]: '1',
    [GridStorageName.Tags]: '1',
    [GridStorageName.DynamicImages]: '1',
    [GridStorageName.WeeklyAvailability]: '1',
    [GridStorageName.ProductAvailability]: '1',
    [GridStorageName.Orders]: '1',
    [GridStorageName.Vouchers]: '1',
    [GridStorageName.ExternalPerks]: '1',
    [GridStorageName.Perks]: '1',
    [GridStorageName.Rules]: '1',
    [GridStorageName.ScheduledTasks]: '1',
    [GridStorageName.Clerks]: '1',
    [GridStorageName.Menus]: '1',
    [GridStorageName.OrderingAvailability]: '1',
    [GridStorageName.Tiers]: '1',
    [GridStorageName.BirthdaySchemes]: '1',
    [GridStorageName.PowercardGifting]: '1',
    [GridStorageName.PowercardLiability]: '1',
    [GridStorageName.Transactions]: '1',
    [GridStorageName.Disputes]: '1',
    [GridStorageName.Audiences]: '1',
    [GridStorageName.AdjustmentSchemes]: '1'
};

interface LocalStoredData {
    orderedFields?: string[];
    columnVisibilityModel?: GridColumnVisibilityModel;
    widths?: Record<string, number>;
    pinnedColumns?: GridPinnedColumnFields;
    version: string;
}

function isGridColumnVisibilityModel(data: any): data is GridColumnVisibilityModel {
    return isRecordOf(isBoolean, data);
}

function isGridColumnWidthsModel(data: any): data is Record<string, number> {
    return isRecordOf(isNumber, data);
}

function isGridPinnedColumnsModel(data: any): data is GridPinnedColumnFields {
    return isOptionalArrayOf(isString, data.left) && isOptionalArrayOf(isString, data.right);
}

function isLocalStoredData(data: any): data is LocalStoredData {
    return (
        !!data &&
        isString(data.version) &&
        isOptionalArrayOf(isString, data.orderedFields) &&
        isOptional(isGridColumnVisibilityModel, data.columnVisibilityModel) &&
        isOptional(isGridColumnWidthsModel, data.widths) &&
        isOptional(isGridPinnedColumnsModel, data.pinnedColumns)
    );
}

function getValidColumnVisibilityModel(
    model: GridColumnVisibilityModel | undefined,
    columns: readonly GridColDef[]
): GridColumnVisibilityModel {
    if (model) {
        if (columns.some(column => model[column.field] || isUndefined(model[column.field]))) {
            return model;
        }
    }
    return {};
}

export function useGridStateStorage(
    apiRef: React.MutableRefObject<GridApiPro>,
    storageName: GridStorageName,
    columns: readonly GridColDef[],
    groupingColDef?: DataGridProProps['groupingColDef']
) {
    const initialState = React.useMemo<LocalStoredData | Record<string, never>>(() => {
        if (!isDefined(storageName)) {
            return {};
        }
        const storedItems = JSON.parse(localStorage.getItem(storageName));
        if (isLocalStoredData(storedItems)) {
            if (storedItems.version !== versions[storageName]) {
                return {};
            }
            return storedItems;
        }
        return {};
    }, [storageName]);

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>(
        getValidColumnVisibilityModel(initialState.columnVisibilityModel, columns)
    );
    const [widths, setWidths] = React.useState<Record<string, number>>(() => {
        if (initialState.widths) {
            return initialState.widths;
        }
        if (!columns) {
            return {};
        }
        return columns.reduce<Record<string, number>>((acc, column) => {
            if (column.width) {
                acc[column.field] = column.width;
            }
            return acc;
        }, {});
    });
    const [orderedFields, setOrderedFields] = React.useState<GridColDef['field'][]>(
        () => initialState.orderedFields ?? columns.map(column => column.field)
    );
    const [pinnedColumns, setPinnedColumns] = React.useState<GridPinnedColumnFields>(
        initialState.pinnedColumns ?? {}
    );

    React.useEffect(() => {
        if (!isDefined(storageName)) {
            return;
        }
        const debouncedWrite = debounce(() => {
            localStorage.setItem(
                storageName,
                JSON.stringify({
                    orderedFields,
                    columnVisibilityModel,
                    widths,
                    pinnedColumns,
                    version: versions[storageName] ?? '1'
                })
            );
        }, 500);
        debouncedWrite();
        return () => debouncedWrite.clear();
    }, [columnVisibilityModel, orderedFields, pinnedColumns, storageName, widths]);
    const handleColumnVisibilityModelChange = React.useCallback((newModel: GridColumnVisibilityModel) => {
        setColumnVisibilityModel(newModel);
    }, []);
    const handleColumnWidthChange = React.useCallback(({ colDef, width }: GridColumnResizeParams) => {
        setWidths(prev => ({ ...prev, [colDef.field]: width }));
    }, []);
    const handleColumnOrderChange = React.useCallback(() => {
        setOrderedFields(gridColumnFieldsSelector(apiRef));
    }, [apiRef]);
    const handlePinnedColumnsChange = React.useCallback((pinnedColumnsUpdate: GridPinnedColumnFields) => {
        setPinnedColumns(pinnedColumnsUpdate);
    }, []);
    const computedColumns = React.useMemo(() => {
        // Find any columns that are missing from the orderedFields
        const missingIndexes: [GridColDef, number][] = [];
        columns.forEach((column, index) => {
            if (!orderedFields.includes(column.field)) {
                missingIndexes.push([column, index]);
            }
        });
        missingIndexes.sort((a, b) => a[1] - b[1]);

        // First we need to go through the orderedFields and create the columns in the correct order
        const colDef = orderedFields.reduce<GridColDef[]>((acc, field, index) => {
            // If we encounter missing columns, we need to add them before adding the ordered column
            const missingColumnIndexed = missingIndexes.find(([_, missingIndex]) => missingIndex === index);
            if (missingColumnIndexed) {
                acc.push(missingColumnIndexed[0]);
                // If we added a missing column we need to remove it from the list of missing columns
                missingIndexes.splice(missingColumnIndexed[1], 1);
            }
            const column = columns.find(col => col.field === field);
            if (!column) {
                return acc;
            }
            if (widths[field]) {
                acc.push({
                    ...column,
                    width: widths[field]
                });
                return acc;
            }
            acc.push(column);
            return acc;
        }, []);
        // If we still somehow have missing columns, we just add them to the end
        if (missingIndexes.length > 0) {
            missingIndexes.forEach(([column]) => {
                colDef.push(column);
            });
        }
        return colDef;
    }, [columns, widths, orderedFields]);
    const formattedGroupingColDef = React.useMemo(() => {
        if (groupingColDef) {
            if (widths.__tree_data_group__) {
                return {
                    ...groupingColDef,
                    width: widths.__tree_data_group__
                };
            }
            return groupingColDef;
        }
        return undefined;
    }, [groupingColDef, widths.__tree_data_group__]);
    return {
        computedColumns,
        columnVisibilityModel,
        pinnedColumns,
        formattedGroupingColDef,
        handleColumnVisibilityModelChange,
        handleColumnWidthChange,
        handleColumnOrderChange,
        handlePinnedColumnsChange
    };
}
