import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { DynamicImagery, MenuChangeSchema, ProductChange } from '@pepperhq/menu-sdk';
import { menuApi } from 'components/menu/MenuApi';
import { ImagePickerColumn, SelectTitleColumn } from 'lib/MuiGrid/Columns';
import { EMuiGridStatus, MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React, { useContext } from 'react';
import {
    BaseMenuTab,
    EnrichedDynamicImagery,
    getDynamicImageryToCopy,
    getDynamicImagesData,
    useMenuChange
} from '../model/menu';
import { useLeavePageBlock } from 'lib/hooks/useLeavePageBlock';
import { useDispatch } from 'react-redux';
import logger from 'lib/logger';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_DYNAMIC_IMAGES_UPDATE_ERROR, MESSAGE_DYNAMIC_IMAGES_UPDATE_SUCCESS } from 'config/messages';
import { useEditModal } from '../model/useMenuEditCell';
import { DynamicImageryEditModal } from '../dynamicImagery/DynamicImageryEditModal';
import { useMenuGridSearch } from '../model/useMenuGridSearch';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { getProductsData } from '../model/product';
import { LocationsContext, useTabCopy } from '../useCopyTab';
import { ActionsHeader } from '../ActionsHeader';
import { useMenuSelection } from '../useMenuSelection';
import { useMenuDeleteControls } from '../model/useMenuDeleteControls';

type DynamicImagesTabProps = BaseMenuTab;

export const DynamicImagesTab: React.FC<DynamicImagesTabProps> = ({
    menu,
    menuChangeId,
    loadFreshMenu,
    onUpdate,
    change: externalChange,
    search,
    onSearchChange
}) => {
    const locations = useContext(LocationsContext);
    const dispatch = useDispatch();
    const {
        change: [change, setChange],
        readyToSave,
        handleCancel,
        handleStatusChange,
        gridRef
    } = useMenuChange(externalChange);

    const {
        selectedIds,
        resetSelection,
        selectedCell,
        handleSelectChange,
        handleCellSelection,
        copyEnabled,
        handleCellBlur
    } = useMenuSelection(!!locations.length);

    const { toggleIsCopyModalOpen, setMenuChangeToCopy, activeOperationId, renderLocationPickerModal } =
        useTabCopy(resetSelection, selectedCell);

    const handleRefreshMenu = React.useCallback(() => {
        loadFreshMenu();
        gridRef.current.reset();
    }, [gridRef, loadFreshMenu]);

    const images = React.useMemo(() => {
        if (!menu || !change) {
            return [];
        }
        return getDynamicImagesData(change);
    }, [change, menu]);

    const [products, productOptions] = React.useMemo(
        () => getProductsData(menu, change, false),
        [change, menu]
    );
    const productIdToTitle = React.useMemo(() => {
        const newMap = new Map<string, string>();
        productOptions.forEach(item => {
            newMap.set(item.value, String(item.label));
        });
        return newMap;
    }, [productOptions]);

    const {
        editedOrDeleted,
        handleCancelEditing,
        handleDelete,
        deleted,
        deleteEnabled,
        resetDeleted,
        isCellEditable
    } = useMenuDeleteControls(selectedIds, readyToSave, handleCancel, resetSelection);

    const { renderEditCell, handleClose, handleCreate, open, selectedItem } = useEditModal(
        gridRef,
        images,
        deleted
    );

    useLeavePageBlock(editedOrDeleted);
    const gridSearchOptions = React.useMemo(
        () => [{ column: 'id' }, { column: 'productId', labels: productIdToTitle }],
        [productIdToTitle]
    );
    const { filterModel, renderInput, filterOperators } = useMenuGridSearch(
        gridSearchOptions,
        search,
        onSearchChange,
        'dynamicImages',
        'Search by ID or product title'
    );

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'id',
                headerName: 'Product ID',
                width: 200
            },
            {
                field: 'productId',
                headerName: 'Product Title',
                width: 200,
                filterOperators: [filterOperators.productId],
                ...SelectTitleColumn(productIdToTitle)
            },
            {
                field: 'type',
                headerName: 'Type',
                width: 200
            },
            {
                field: 'baseImageUrl',
                headerName: 'Banner Image',
                width: 300,
                ...ImagePickerColumn('dynamic-imagery-', false)
            },
            {
                field: 'edit',
                headerName: ' ',
                width: 84,
                renderCell: renderEditCell
            }
        ],
        [filterOperators.productId, productIdToTitle, renderEditCell]
    );
    const handleCreateSubmit = React.useCallback(
        ({ id, ...newImagery }: EnrichedDynamicImagery) => {
            handleClose();
            let idToDelete: string;
            if (id !== selectedItem?.id) {
                idToDelete = selectedItem?.id;
            }
            if (idToDelete) {
                setChange(currentChange => ({
                    ...currentChange,
                    products: {
                        ...(currentChange.products || {}),
                        [id]:
                            currentChange?.products && currentChange.products[id]
                                ? { ...currentChange.products[id], dynamicImagery: newImagery }
                                : { dynamicImagery: newImagery },
                        [idToDelete]:
                            currentChange?.products && currentChange.products[idToDelete]
                                ? { ...currentChange.products[idToDelete], dynamicImagery: null }
                                : null
                    }
                }));
            } else {
                setChange(currentChange => ({
                    ...currentChange,
                    products: {
                        ...(currentChange.products || {}),
                        [id]:
                            currentChange?.products && currentChange.products[id]
                                ? { ...currentChange.products[id], dynamicImagery: newImagery }
                                : { dynamicImagery: newImagery }
                    }
                }));
            }
            gridRef.current?.createItem(id, undefined, {});
            gridRef.current?.applyChanges(id, { id, ...newImagery });
            handleStatusChange(EMuiGridStatus.CHANGED);
        },
        [gridRef, handleClose, handleStatusChange, selectedItem?.id, setChange]
    );
    const handleSubmit = React.useCallback(async () => {
        try {
            if (!gridRef.current) {
                throw new Error(MESSAGE_DYNAMIC_IMAGES_UPDATE_ERROR);
            }
            const { changes } = gridRef.current.getState();
            const mappedProducts = Object.entries(changes).reduce<Record<string, Partial<ProductChange>>>(
                (acc, [id, { productId: _productId, id: _id, _overriden, ...item }]) => {
                    acc[id] = {
                        dynamicImagery: item as DynamicImagery
                    };
                    return acc;
                },
                {}
            );
            if (deleted) {
                deleted.forEach(deletedImage => {
                    mappedProducts[deletedImage] = { dynamicImagery: null };
                });
            }
            const result = await menuApi.updateMenuChange(menuChangeId, {
                products: mappedProducts,
                schema: MenuChangeSchema.v5
            });
            if (!result.ok) {
                throw new Error(result.body.message);
            }
            setChange(result.body);
            onUpdate(result.body);
            resetDeleted();
            dispatch(enqueueSnackbar(MESSAGE_DYNAMIC_IMAGES_UPDATE_SUCCESS, { variant: 'success' }));
            gridRef.current.reset();
        } catch (e) {
            logger.error(e);
            dispatch(enqueueSnackbar(e.message, { variant: 'error' }));
        }
    }, [gridRef, deleted, menuChangeId, setChange, onUpdate, resetDeleted, dispatch]);

    const handleCopyTo = React.useCallback(() => {
        toggleIsCopyModalOpen();
        const productsToUpdate = getDynamicImageryToCopy(images, selectedIds);
        setMenuChangeToCopy({
            products: productsToUpdate,
            schema: MenuChangeSchema.v5
        });
    }, [selectedIds, images, setMenuChangeToCopy, toggleIsCopyModalOpen]);

    return (
        <React.Fragment>
            <Box pb={1}>
                <ActionsHeader
                    onCreate={handleCreate}
                    menu={menu}
                    onCancelEditing={handleCancelEditing}
                    onSubmit={handleSubmit}
                    activeOperationId={activeOperationId}
                    onCopy={handleCopyTo}
                    onRefreshMenu={handleRefreshMenu}
                    isEditedOrDeleted={editedOrDeleted}
                    copyEnabled={copyEnabled}
                    onDelete={handleDelete}
                    deleteEnabled={deleteEnabled}
                />
                {renderInput()}
            </Box>
            <MuiGrid
                rows={images}
                columns={columns}
                stateRef={gridRef}
                onStatusChange={handleStatusChange}
                deleted={deleted}
                isCellEditable={isCellEditable}
                storageName={GridStorageName.DynamicImages}
                filterModel={filterModel}
                disableColumnFilter
                checkboxSelection
                checkboxVisibility
                onRowSelectionModelChange={handleSelectChange}
                rowSelectionModel={selectedIds}
                onCellClick={handleCellSelection}
                handleCellBlur={handleCellBlur}
            />
            <DynamicImageryEditModal
                onClose={handleClose}
                open={open}
                onSubmit={handleCreateSubmit}
                products={products}
                menu={menu}
                dynamicImagery={selectedItem}
                productIdToTitle={productIdToTitle}
            />
            {renderLocationPickerModal()}
        </React.Fragment>
    );
};
