/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import React, { CSSProperties } from 'react';
import { Box, Button, Grid, Stack, Typography, styled } from '@mui/material';
import { Form } from 'formik';
import * as Yup from 'yup';
import { AwardExpiryLabelDetailStyles, AwardTemplate } from 'components/customers/models/Award';
import { MuiForm } from 'lib/Form';
import { ColorFormField, RGBAValue } from 'lib/form/fields/ColorFormField';
import { ImageFormField } from 'lib/form/fields/ImageFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Column, Row } from 'ui/Flex';
import {
    PerkPreviewEditable,
    perkPreviewWidthByType
} from 'components/perks/forms/visualisation/PerkPreviewEditable';
import { TextAlignment, VerticalAlignment } from 'components/loyalty/models/PerkModel';
import { ButtonGroupInputFormField } from 'lib/form/fields/ButtonGroupInputFormField';
import { MuiTheme } from 'config/theme';
import { alignOptions, valignOptions } from 'components/perks/forms/PerkVisualisationForm';
import { AwardFormStep, StepUpdateMetadata } from '../AwardEnrichmentEditStepper';
import { Option } from 'lib/types';
import { CheckboxFormField } from 'lib/form/fields/CheckboxFormField';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { titleize } from 'lib/helpers';
import {
    BottomFullWidthBarIcon,
    BottomMiddleChipIcon,
    TopFullWidthBarIcon,
    TopMiddleChipIcon
} from 'components/perks/ExpiryLabelDetailIcons';
import FormatAlignJustify from '@mui/icons-material/FormatAlignJustify';
import FormatAlignCenter from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeft from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRight from '@mui/icons-material/FormatAlignRight';
import VerticalAlignBottom from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTop from '@mui/icons-material/VerticalAlignTop';

export interface AwardConfigureFormData {
    terms: string;
    subtitle?: string;
    subtitle2?: string;
    image?: string;
    textColor: RGBAValue;
    backgroundColor: RGBAValue;
    align: TextAlignment;
    valign: VerticalAlignment;
    perkType: AwardTemplate;
    randomizeStampCard?: boolean;
    stampsPerCard?: string;
    stampImageUrl?: string;
    stampColor: RGBAValue;
    nextCardProgressCopy?: string;
    availableCardCopy?: string;
    auto?: boolean;
    titleText?: string;
    redemptionStrategy: 'none' | 'single' | 'multi';
    showExpiry?: boolean;
    expiryLabelAnchorStyleAlign?: TextAlignment;
    expiryLabelAnchorStyleVAlign?: VerticalAlignment.TOP | VerticalAlignment.BOTTOM;
    expiryLabelDetailStyle?: AwardExpiryLabelDetailStyles;
}

interface AwardConfigureFormProps {
    onSubmit: (data: AwardConfigureFormData) => void;
    isEdit?: boolean;
    open: boolean;
    title?: string;
    initialValues?: AwardConfigureFormData;
    isLoading?: boolean;
    onPrevious?: (isValid?: boolean, values?: AwardConfigureFormData) => void;
    clickPrevious: () => void;
    onUpdate?: (values: StepUpdateMetadata) => void;
    currencySymbol: string;
    primaryActionBackgroundColour: string;
}

const typeOptions: Option[] = [
    { value: AwardTemplate.AD_HOC, label: 'Adhoc' },
    { value: AwardTemplate.PSEUDO_CURRENCY, label: 'Pseudo Currency' },
    { value: AwardTemplate.STAMP_CARD, label: 'Stamp Card' },
    { value: AwardTemplate.CLUB, label: 'Audience' }
];

export const expiryLabelAlignOptions: Option[] = [
    { value: TextAlignment.LEFT, label: <FormatAlignLeft /> },
    { value: TextAlignment.CENTER, label: <FormatAlignCenter /> },
    { value: TextAlignment.RIGHT, label: <FormatAlignRight /> },
    { value: TextAlignment.JUSTIFY, label: <FormatAlignJustify /> }
];

export const expiryLabelVAlignOptions: Option[] = [
    { value: VerticalAlignment.TOP, label: <VerticalAlignTop /> },
    { value: VerticalAlignment.BOTTOM, label: <VerticalAlignBottom /> }
];

const expiryLabelDetailStyleOptions: Option[] = [
    { value: AwardExpiryLabelDetailStyles.TOP_MIDDLE_CHIP, label: <TopMiddleChipIcon /> },
    { value: AwardExpiryLabelDetailStyles.TOP_FULL_WIDTH_BAR, label: <TopFullWidthBarIcon /> },
    { value: AwardExpiryLabelDetailStyles.BOTTOM_MIDDLE_CHIP, label: <BottomMiddleChipIcon /> },
    { value: AwardExpiryLabelDetailStyles.BOTTOM_FULL_WIDTH_BAR, label: <BottomFullWidthBarIcon /> }
];

const PREFIX = 'AwardConfigureForm';

const classes = {
    colours: `${PREFIX}-colours`,
    stampFields: `${PREFIX}-stampFields`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.colours}`]: {
        height: '100%',
        alignItems: 'center',
        display: 'flex'
    },
    [`& .${classes.stampFields}`]: {
        marginLeft: theme.spacing(2)
    }
}));

const getInitialData = (auto?: boolean): AwardConfigureFormData => ({
    terms: '',
    subtitle: '',
    subtitle2: '',
    image: '',
    backgroundColor: '#ffffff00',
    align: TextAlignment.LEFT,
    valign: VerticalAlignment.BOTTOM,
    perkType: AwardTemplate.AD_HOC,
    textColor: '#000000',
    availableCardCopy: '',
    nextCardProgressCopy: '',
    randomizeStampCard: false,
    stampColor: '#000000',
    stampImageUrl: '',
    auto: !!auto,
    redemptionStrategy: 'none',
    showExpiry: false,
    expiryLabelAnchorStyleAlign: TextAlignment.RIGHT,
    expiryLabelAnchorStyleVAlign: VerticalAlignment.BOTTOM,
    expiryLabelDetailStyle: AwardExpiryLabelDetailStyles.TOP_MIDDLE_CHIP
});

const getRedemptionStrategyDescription = (value: AwardConfigureFormData['redemptionStrategy']) => {
    if (value === 'multi') {
        return 'Allow your users to redeem this award multiple times.';
    }
    if (value === 'single') {
        return 'Allow your users to redeem this award once only.';
    }
    return undefined;
};

const createValidationSchema = Yup.object().shape({
    terms: Yup.string()
});

const editValidationSchema = Yup.object().shape({
    terms: Yup.string()
});

export const AwardConfigureForm: React.FC<AwardConfigureFormProps> = props => {
    const {
        onSubmit,
        clickPrevious,
        initialValues,
        open,
        title,
        isEdit,
        onPrevious,
        onUpdate,
        isLoading,
        primaryActionBackgroundColour
    } = props;
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const isIbs = React.useMemo(() => settings.posProvider === 'IBS', [settings]);

    const redemptionStrategies: Option[] = React.useMemo(
        () => [
            { value: 'none', label: `Use redemption set by ${titleize(settings?.loyaltyProvider)}` },
            { value: 'single', label: 'Single redemption' },
            { value: 'multi', label: 'Multiple redemption' }
        ],
        [settings?.loyaltyProvider]
    );

    if (!open) {
        return null;
    }

    return (
        <MuiForm
            onSubmit={onSubmit}
            validationSchema={isEdit ? editValidationSchema : createValidationSchema}
            initialValues={
                initialValues
                    ? { ...getInitialData(isIbs && !isEdit), ...initialValues }
                    : getInitialData(isIbs && !isEdit)
            }
        >
            {({ submitForm, values, isValid }) => {
                const {
                    image,
                    backgroundColor,
                    perkType,
                    textColor,
                    align,
                    valign,
                    stampsPerCard,
                    stampColor,
                    stampImageUrl,
                    randomizeStampCard,
                    redemptionStrategy,
                    showExpiry,
                    expiryLabelAnchorStyleAlign,
                    expiryLabelAnchorStyleVAlign
                } = values;

                const textAlign: CSSProperties['textAlign'] =
                    align.toLowerCase() as CSSProperties['textAlign'];
                const handlePreviousStep = () => {
                    if (isEdit) {
                        onPrevious(isValid, values);
                    }
                    clickPrevious();
                };
                const handleUpdate = () => {
                    if (isValid) {
                        onUpdate({ values, type: AwardFormStep.CONFIGURE });
                    }
                };
                const redemptionDescription = getRedemptionStrategyDescription(redemptionStrategy);

                return (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={5}>
                                <Box padding={3} maxWidth={600}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h4">2. Configure</Typography>
                                            <Typography variant="body2">
                                                Set up how the award will behave for your users and how it
                                                will look in your app.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFormField
                                                name="terms"
                                                multiline
                                                maxRows={2}
                                                label="Terms & Conditions"
                                                description="The terms and conditions associated with this award for your customers to see in the app."
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ButtonGroupInputFormField
                                                disabled={isEdit}
                                                name="perkType"
                                                label="Perk Type"
                                                options={typeOptions}
                                                description="Choose which type of perk you would like to configure."
                                            />
                                        </Grid>
                                        {isIbs && (
                                            <Grid item xs={12}>
                                                <CheckboxFormField
                                                    disabled={isEdit}
                                                    name="auto"
                                                    label="Auto Apply"
                                                    description="Automatically apply this award to your user's basket"
                                                />
                                            </Grid>
                                        )}
                                        {(perkType === AwardTemplate.AD_HOC ||
                                            perkType === AwardTemplate.CLUB) && (
                                            <Grid item xs={12}>
                                                <ButtonGroupInputFormField
                                                    label="Redemption Strategy"
                                                    name="redemptionStrategy"
                                                    options={redemptionStrategies}
                                                    description={redemptionDescription}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <Box padding={3} maxWidth={866}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography>Preview</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Row gutter valign="flex-end">
                                                <PerkPreviewEditable
                                                    label={
                                                        perkType === AwardTemplate.STAMP_CARD
                                                            ? 'No purchase available'
                                                            : undefined
                                                    }
                                                    title={title}
                                                    editable
                                                    perkType={perkType}
                                                    image={image}
                                                    backgroundColor={backgroundColor}
                                                    textColor={textColor}
                                                    textAlign={textAlign}
                                                    valign={valign}
                                                    stampImageUrl={stampImageUrl}
                                                    stampColor={stampColor}
                                                    randomizeStampCard={randomizeStampCard}
                                                    stampsPerCard={stampsPerCard ? Number(stampsPerCard) : 6}
                                                    showExpiry={showExpiry}
                                                    primaryActionBackgroundColour={
                                                        primaryActionBackgroundColour
                                                    }
                                                    expiryLabelAnchorStyleAlign={expiryLabelAnchorStyleAlign}
                                                    expiryLabelAnchorStyleVAlign={
                                                        expiryLabelAnchorStyleVAlign
                                                    }
                                                />
                                                {perkType === AwardTemplate.STAMP_CARD && (
                                                    <PerkPreviewEditable
                                                        label="Purchase available"
                                                        cardAvailable
                                                        editable
                                                        title={title}
                                                        perkType={perkType}
                                                        randomizeStampCard={randomizeStampCard}
                                                        stampColor={stampColor}
                                                        stampsPerCard={
                                                            stampsPerCard ? Number(stampsPerCard) : 6
                                                        }
                                                        valign={valign}
                                                        stampImageUrl={stampImageUrl}
                                                        textAlign={textAlign}
                                                        image={image}
                                                        backgroundColor={backgroundColor}
                                                        textColor={textColor}
                                                    />
                                                )}
                                                <Column
                                                    style={{
                                                        width: `calc(100% - ${MuiTheme.spacing(
                                                            perkPreviewWidthByType[perkType]
                                                        )}px)`,
                                                        flexGrow: 1,
                                                        flexShrink: 1,
                                                        maxWidth: '100%'
                                                    }}
                                                >
                                                    {(perkType === AwardTemplate.AD_HOC ||
                                                        perkType === AwardTemplate.PSEUDO_CURRENCY) &&
                                                        showExpiry && (
                                                            <React.Fragment>
                                                                <Typography>
                                                                    Expiry Label Anchor Style
                                                                </Typography>
                                                                <ButtonGroupInputFormField
                                                                    name="expiryLabelAnchorStyleAlign"
                                                                    options={expiryLabelAlignOptions}
                                                                />
                                                                <ButtonGroupInputFormField
                                                                    name="expiryLabelAnchorStyleVAlign"
                                                                    options={expiryLabelVAlignOptions}
                                                                />
                                                            </React.Fragment>
                                                        )}

                                                    {perkType !== AwardTemplate.STAMP_CARD && (
                                                        <React.Fragment>
                                                            <Box marginTop={1}>
                                                                <Typography>Text Alignment</Typography>
                                                            </Box>
                                                            <ButtonGroupInputFormField
                                                                name="align"
                                                                options={alignOptions}
                                                            />
                                                            <ButtonGroupInputFormField
                                                                name="valign"
                                                                options={valignOptions}
                                                            />
                                                        </React.Fragment>
                                                    )}
                                                </Column>
                                            </Row>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <ImageFormField
                                                aspect={1.5}
                                                name="image"
                                                label="Background"
                                                description="600px by 400px. Max. file size is 1M."
                                            />
                                        </Grid>
                                        <StyledGrid item xs={12} sm={6}>
                                            <Column valign="space-around" className={classes.colours}>
                                                <ColorFormField name="textColor" label="Text Colour" />
                                                <ColorFormField
                                                    opacity
                                                    name="backgroundColor"
                                                    label="Background Overlay Colour"
                                                />
                                            </Column>
                                        </StyledGrid>
                                        {(perkType === AwardTemplate.AD_HOC ||
                                            perkType === AwardTemplate.PSEUDO_CURRENCY) && (
                                            <StyledGrid item xs={12}>
                                                <CheckboxFormField
                                                    name="showExpiry"
                                                    label="Show Expiry Label"
                                                    description="A label on the award is presented telling the customer when the award is due to expire based on configured date availability."
                                                />
                                            </StyledGrid>
                                        )}

                                        {(perkType === AwardTemplate.AD_HOC ||
                                            perkType === AwardTemplate.PSEUDO_CURRENCY) &&
                                            showExpiry && (
                                                <Stack paddingX={2} marginTop={2}>
                                                    <Typography fontSize={14}>
                                                        Expiry Label Description Style
                                                    </Typography>

                                                    <ButtonGroupInputFormField
                                                        name="expiryLabelDetailStyle"
                                                        options={expiryLabelDetailStyleOptions}
                                                    />
                                                </Stack>
                                            )}
                                        {perkType === AwardTemplate.STAMP_CARD && (
                                            <React.Fragment>
                                                <StyledGrid item xs={12}>
                                                    <Row
                                                        flex={1}
                                                        valign="space-around"
                                                        className={classes.colours}
                                                    >
                                                        <ImageFormField
                                                            fullWidth={false}
                                                            name="stampImageUrl"
                                                            label="Stamp"
                                                            aspect={1}
                                                            description="200px by 200px. Max. file size is 1M."
                                                        />
                                                        <Column
                                                            valign="space-around"
                                                            className={clsx(
                                                                classes.colours,
                                                                classes.stampFields
                                                            )}
                                                        >
                                                            <ColorFormField
                                                                name="stampColor"
                                                                label="Stamp Colour"
                                                            />
                                                        </Column>
                                                    </Row>
                                                </StyledGrid>
                                                {stampImageUrl && (
                                                    <Grid item xs={12}>
                                                        <CheckboxFormField
                                                            name="randomizeStampCard"
                                                            label="Randomise Stamp Position"
                                                        />
                                                    </Grid>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Row flex={1} gutter align="flex-end">
                                    {isEdit && (
                                        <LoadingButton
                                            loading={isLoading}
                                            disabled={isLoading || !isValid}
                                            onClick={handleUpdate}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            Update & Close
                                        </LoadingButton>
                                    )}
                                    <Button onClick={handlePreviousStep} color="primary" variant="outlined">
                                        Previous
                                    </Button>
                                    <Button onClick={submitForm} color="primary" variant="contained">
                                        Next
                                    </Button>
                                </Row>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </MuiForm>
    );
};
