import { MenuOverridesFilter } from 'components/location/models/Overrides';
import { format, isValid, setHours, setMinutes } from 'date-fns';
import { Crud, HttpClient, httpClient, QueryParameter } from 'lib/HttpClient';

class MenusApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.MENU_SERVICE_URL, 'menus', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
            'x-api-version': 6
        });
    }

    getMenus(locationId?: string) {
        const queryParameters: QueryParameter[] = [];

        if (locationId) {
            queryParameters.push({ key: 'locationId', value: locationId });
        }

        return this.getList({ url: '/summary', queryParameters });
    }

    getMenuOptionsByLocation(locationId: string) {
        return this.getList({
            url: '/menuoptions',
            queryParameters: [{ key: 'locationId', value: locationId }]
        });
    }

    getMenu(menuId: string, locationId: string, queryParameters: QueryParameter[] = []) {
        return this.get(menuId, {
            queryParameters: [{ key: 'locationId', value: locationId }, ...queryParameters]
        });
    }

    refreshMenus(locationId: string) {
        return this.create({
            url: '/refresh',
            body: { locationId },
            skipResponseBody: true
        });
    }

    getInfo(locationId: string, filter?: MenuOverridesFilter) {
        const formatter = (date: Date) => format(date, "yyyy-MM-dd'T'HH:mm:ssxxx");
        const filterMerged: Omit<MenuOverridesFilter, 'time'> = {
            zone: filter.zone,
            scenario: filter.scenario,
            date: filter.date && isValid(new Date(filter.date)) && formatter(new Date(filter.date))
        };

        if (filter.time && isValid(new Date(filter.time))) {
            if (filter.date && isValid(new Date(filter.date))) {
                filterMerged.date = setHours(
                    new Date(filter.date),
                    new Date(filter.time).getHours()
                ).toString();
                filterMerged.date = setMinutes(
                    new Date(filterMerged.date),
                    new Date(filter.time).getMinutes()
                ).toString();
                filterMerged.date = formatter(new Date(filterMerged.date));
            } else {
                filterMerged.date = formatter(new Date(filter.time));
            }
        }
        const queryParameters: QueryParameter[] = Object.entries(filterMerged || {})
            .map(([key, value]: [keyof MenuOverridesFilter, string]) => ({ key, value }))
            .filter(({ value }) => !!value);
        queryParameters.push({ key: 'locationId', value: locationId });
        queryParameters.push({ key: 'include', value: 'updated' });

        return this.getList({
            url: '/info',
            queryParameters
        });
    }
}

export const menusApi = new MenusApi(httpClient);
