import React from 'react';
import { Box, IconButton, styled } from '@mui/material';
import { isDefined } from 'lib/typeguards';
import { SkeletonComponent } from 'ui/skeleton/SkeletonComponent';
import DownloadIcon from '@mui/icons-material/Download';
import { useDownloadAsset } from 'lib/hooks/useDownloadAsset';

interface InputAnimationPickerProps {
    path: string;
    name: string;
    accept?: string;
    uploadFolder: string;
    loading: boolean;
    downloadable?: boolean;
    postAnimationFile: (values: { file: File; fileName: string }) => void;
}

const PREFIX = 'InputAnimationPicker';

const classes = {
    image: `${PREFIX}-image`,
    input: `${PREFIX}-input`
};

const StyledBox = styled(Box)(({ theme }) => ({
    width: '200px',
    height: '200px',
    border: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    [`& .${classes.image}`]: {
        width: '100%'
    },
    [`& .${classes.input}`]: {
        display: 'none'
    }
}));

export const InputAnimationPicker: React.FC<InputAnimationPickerProps> = ({
    path,
    name,
    accept,
    loading,
    downloadable,
    postAnimationFile
}) => {
    const ref = React.useRef(null);

    const openFileSelector = React.useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        ref.current.click();
    }, []);

    const handleChange = React.useCallback(
        event => {
            if (event.target.files?.length) {
                const fileList: FileList = event.target.files;

                if (fileList.item(0) instanceof Blob) {
                    postAnimationFile({ file: fileList.item(0), fileName: name });
                }
            }
        },
        [postAnimationFile, name]
    );

    const { loading: assetDownloading, handleDownload } = useDownloadAsset({
        src: path,
        fileName: name
    });

    if (loading) {
        return <SkeletonComponent width={200} height={200} />;
    }

    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <StyledBox display="flex">
                <input
                    ref={ref}
                    type="file"
                    name={name}
                    onChange={handleChange}
                    accept={isDefined(accept) ? accept : '.svg, .gif'}
                    className={classes.input}
                />
                <img src={path} onClick={openFileSelector} className={classes.image} />
            </StyledBox>
            {downloadable && (
                <Box pl={1}>
                    <IconButton disabled={assetDownloading} onClick={handleDownload}>
                        <DownloadIcon />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};
