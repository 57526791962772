import React from 'react';
import { DateRange } from '@mui/lab';
import {
    DateRangePickerSlotProps,
    PickersShortcutsItem,
    StaticDateRangePicker
} from '@mui/x-date-pickers-pro';
import { format, isAfter, isBefore } from 'date-fns';

export interface DateRangePickerValue {
    startDate: Date;
    endDate: Date;
}

export interface MuiDateRangePickerProps {
    value?: DateRangePickerValue;
    disableFuture?: boolean;
    disablePast?: boolean;
    onChange: (value: DateRangePickerValue) => void;
    maxDate?: Date;
    minDate?: Date;
    shouldDisableDate?: (date: Date) => boolean;
    disabled?: boolean;
    shortcuts?: PickersShortcutsItem<DateRange<Date>>[];
}

export const MuiDateRangePicker: React.FC<MuiDateRangePickerProps> = props => {
    const {
        disableFuture,
        disablePast,
        value,
        onChange,
        maxDate,
        minDate,
        shouldDisableDate,
        disabled,
        shortcuts
    } = props;
    const [dateRange, setDateRange] = React.useState<DateRange<Date>>(
        value ? [value.startDate, value.endDate] : [null, null]
    );
    const handleChange = React.useCallback(
        ([start, end]: DateRange<Date>) => {
            setDateRange([start, end]);
            onChange({ startDate: start, endDate: end });
        },
        [onChange]
    );
    const isValidDate = React.useMemo(
        () =>
            (maxDate ? isBefore(new Date(value?.endDate), new Date(maxDate)) : true) &&
            (minDate ? isAfter(new Date(value?.startDate), new Date(minDate)) : true),
        [maxDate, minDate, value?.endDate, value?.startDate]
    );
    const helperText = React.useMemo(
        () =>
            minDate && maxDate
                ? `Please enter the value from ${format(minDate, 'dd MMM yyyy')} to ${format(
                      maxDate,
                      'dd MMM yyyy'
                  )}`
                : undefined,
        [maxDate, minDate]
    );
    const slotProps = React.useMemo<DateRangePickerSlotProps<Date, false>>(
        () => ({
            textField: { variant: 'outlined', helperText, error: !isValidDate },
            shortcuts: { items: shortcuts },
            actionBar: { actions: [] }
        }),
        [helperText, isValidDate, shortcuts]
    );
    return (
        <StaticDateRangePicker
            shouldDisableDate={shouldDisableDate}
            disableFuture={disableFuture}
            disablePast={disablePast}
            onChange={handleChange}
            value={dateRange}
            maxDate={maxDate}
            minDate={minDate}
            slotProps={slotProps}
            disabled={disabled}
        />
    );
};
