import {
    MESSAGE_MARKETING_ADJUSTMENT_SCHEME_DELETE_ERROR,
    MESSAGE_MARKETING_ADJUSTMENT_SCHEME_DELETE_SUCCESS
} from 'config/messages';
import logger from 'lib/logger';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { adjustmentSchemesApi } from '../adjustmentSchemesApi';
import { AdjustmentScheme } from '../adjustmentSchemeModel';

interface MutationProps {
    selectedScheme: AdjustmentScheme | undefined;
    handleDeleteSuccess: (scheme: AdjustmentScheme) => void;
}

export const useAdjustmentSchemesMutation = ({ selectedScheme, handleDeleteSuccess }: MutationProps) => {
    const dispatch = useDispatch();

    const [isProcessing, setIsProcessing] = React.useState(false);

    const handleConfirmDelete = React.useCallback(async () => {
        if (selectedScheme) {
            setIsProcessing(true);
            try {
                const res = await adjustmentSchemesApi.delete(selectedScheme._id, {
                    skipResponseBody: true
                });

                if (!res.ok) {
                    throw new Error(res.body.message);
                }

                dispatch(
                    enqueueSnackbar(MESSAGE_MARKETING_ADJUSTMENT_SCHEME_DELETE_SUCCESS, {
                        variant: 'success'
                    })
                );
                handleDeleteSuccess(selectedScheme);
            } catch (error) {
                logger.error(error.message);
                dispatch(
                    enqueueSnackbar(MESSAGE_MARKETING_ADJUSTMENT_SCHEME_DELETE_ERROR, { variant: 'error' })
                );
            }
            setIsProcessing(false);
        }
    }, [selectedScheme, dispatch, setIsProcessing, handleDeleteSuccess]);

    return {
        isProcessing,
        handleConfirmDelete
    };
};
