import React from 'react';
import { Box, Button, List } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { removeArrayItem, updateArrayItem } from 'lib/helpers';
import { Option } from 'lib/types';
import { MuiModal } from 'ui/MuiModal';
import { DynamicImageryBoxContainerDialog } from './DynamicImageryBoxContainerDialog';
import { DynamicImageryContainerEditItem } from './DynamicImageryContainerEditItem';
import { Container } from '@pepperhq/menu-sdk';

interface DynamicImageryContainersEditProps {
    containers: Container[];
    onChange: (containers: Container[]) => void;
    colors?: string[];
    options: Option[];
}

export const DynamicImageryContainersEdit: React.FC<DynamicImageryContainersEditProps> = ({
    onChange,
    colors,
    options,
    containers
}) => {
    const [open, setOpen] = React.useState(false);
    const [editingContainerIndex, setEditingContainerIndex] = React.useState<number>(undefined);
    const handleCancel = React.useCallback(() => {
        setOpen(false);
        setEditingContainerIndex(-1);
    }, []);
    const handleEditClick = React.useCallback((index: number) => {
        setEditingContainerIndex(index);
    }, []);
    const handleAddClick = React.useCallback(() => {
        setOpen(true);
    }, []);
    const handleAdd = React.useCallback(
        (container: Container) => {
            onChange([...containers, container]);
            handleCancel();
        },
        [containers, handleCancel, onChange]
    );
    const handleUpdate = React.useCallback(
        (container: Container) => {
            onChange(updateArrayItem(containers, editingContainerIndex, container));
            handleCancel();
        },
        [containers, editingContainerIndex, handleCancel, onChange]
    );
    const handleRemove = React.useCallback(
        (index: number) => {
            onChange(removeArrayItem(containers, index));
        },
        [onChange, containers]
    );
    const renderContainerItem = React.useCallback(
        (container: Container, index: number) => (
            <DynamicImageryContainerEditItem
                index={index}
                color={colors[index]}
                container={container}
                onEdit={handleEditClick}
                onRemove={handleRemove}
                key={`${container.x}:${container.y}:${index}`}
            />
        ),
        [handleEditClick, handleRemove, colors]
    );
    return (
        <React.Fragment>
            <List>{containers.map(renderContainerItem)}</List>
            <Box flex={1}>
                <Button onClick={handleAddClick} variant="outlined" startIcon={<AddIcon />} fullWidth>
                    Add New Container
                </Button>
            </Box>
            {open && (
                <MuiModal open={open} onClose={handleCancel}>
                    <DynamicImageryBoxContainerDialog
                        onCancel={handleCancel}
                        onSubmit={handleAdd}
                        options={options}
                    />
                </MuiModal>
            )}
            {editingContainerIndex > -1 && (
                <MuiModal open={editingContainerIndex > -1} onClose={handleCancel}>
                    <DynamicImageryBoxContainerDialog
                        options={options}
                        onCancel={handleCancel}
                        onSubmit={handleUpdate}
                        container={containers[editingContainerIndex]}
                    />
                </MuiModal>
            )}
        </React.Fragment>
    );
};
