import React from 'react';
import {
    Box,
    Button,
    Card,
    ClickAwayListener,
    List,
    ListItem,
    Popover,
    TextField,
    Typography,
    styled
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useInputState } from 'lib/hooks/useInputState';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

const PREFIX = 'PerkLocationsPopover';

const classes = {
    content: `${PREFIX}-content`,
    actions: `${PREFIX}-actions`,
    searchBar: `${PREFIX}-searchBar`
};

const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: 100,
    maxHeight: 320,
    minWidth: 320,
    maxWidth: 640,
    ...theme.typography.body1,
    [`& .${classes.content}`]: {
        flex: 1,
        overflow: 'auto',
        paddingLeft: theme.spacing(0.5)
    },
    [`& .${classes.actions}`]: {
        display: 'flex',
        padding: theme.spacing(1, 2),
        justifyContent: 'flex-end'
    },
    [`& .${classes.searchBar}`]: {
        padding: theme.spacing(1.5, 2, 1, 2)
    }
}));

const StyledButton = styled(Button)(({ theme }) => ({
    height: '100%',
    margin: theme.spacing(0, -1)
}));

const LocationsCell: React.FC<GridRenderCellParams> = params => {
    const { locationIdToLocationTitle } = useSelector((state: ApplicationState) => state.locations);

    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<null | HTMLElement>(null);

    const [search, onSearchChange] = useInputState('');

    const [firstLocationId] = params.value;
    const locationCount = params.value.length;

    const locationOptions = React.useMemo(
        () =>
            (params.value as string[])
                .map(id => ({
                    label: locationIdToLocationTitle.get(id) || '',
                    value: id
                }))
                .filter(({ label }) => label.toLowerCase().includes(search.toLowerCase())),
        [params.value, locationIdToLocationTitle, search]
    );
    const renderLocationItem = React.useCallback(
        (option: { value: string; label: string }) => (
            <ListItem key={option.value}>
                {option.label} ({option.value})
            </ListItem>
        ),
        []
    );

    const handleOpenPopover = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setPopoverAnchorEl(event.currentTarget);
        setIsPopoverOpen(true);
    }, []);

    const handleClosePopover = React.useCallback(() => {
        setIsPopoverOpen(false);
    }, []);

    if (params.value.length) {
        return (
            <>
                <StyledButton
                    sx={{
                        color: 'inherit',
                        font: 'inherit',
                        textTransform: 'initial',
                        justifyContent: 'flex-start'
                    }}
                    variant="text"
                    onClick={handleOpenPopover}
                >
                    {locationCount > 1
                        ? `${locationCount} Locations`
                        : locationIdToLocationTitle.get(firstLocationId)}
                </StyledButton>
                <Popover
                    anchorEl={popoverAnchorEl}
                    open={isPopoverOpen}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <ClickAwayListener onClickAway={handleClosePopover}>
                        <StyledCard>
                            <Box className={classes.searchBar}>
                                <TextField
                                    variant="outlined"
                                    value={search}
                                    onChange={onSearchChange}
                                    fullWidth
                                    label="Search locations"
                                    size="small"
                                />
                            </Box>
                            <Box className={classes.content}>
                                {locationOptions.length > 0 ? (
                                    <List>{locationOptions.map(renderLocationItem)}</List>
                                ) : (
                                    <Box paddingY={1.5} paddingX={2}>
                                        <Typography variant="body1">{`No locations found for "${search}"`}</Typography>
                                    </Box>
                                )}
                            </Box>
                            <Box className={classes.actions}>
                                <Button onClick={handleClosePopover} variant="contained" color="primary">
                                    Close
                                </Button>
                            </Box>
                        </StyledCard>
                    </ClickAwayListener>
                </Popover>
            </>
        );
    }

    return <>All Locations</>;
};

export const renderLocationsCell = (props: GridRenderCellParams) => <LocationsCell {...props} />;
