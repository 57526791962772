import React from 'react';
import { styled } from '@mui/material';
import { MuiModal } from 'ui/MuiModal';
import { PerkGeneralFormData } from '../forms/PerkGeneralForm';
import { PerksCreationStepper, PerksCreationStepperProps } from '../PerksCreationStepper';
import { Perk } from 'components/loyalty/models/PerkModel';

interface PerksCreationModalProps extends PerksCreationStepperProps {
    open: boolean;
    currencySymbol: string;
    duplicatePerk?: Perk;
    primaryActionBackgroundColour: string;
    initialGeneralData?: Partial<PerkGeneralFormData>;
}

const StyledMuiModal = styled(MuiModal)(({ theme }) => ({
    width: `calc(100% - ${theme.spacing(4)})`,
    maxWidth: theme.spacing(160)
}));

export const PerksCreationModal: React.FC<PerksCreationModalProps> = props => {
    const { open, onClose, ...rest } = props;
    return (
        <StyledMuiModal open={open} onClose={onClose}>
            <PerksCreationStepper onClose={onClose} {...rest} />
        </StyledMuiModal>
    );
};
