import React, { CSSProperties } from 'react';
import { Box, Button, Grid, InputBase, Typography, styled } from '@mui/material';
import { valignStyle } from 'components/loyalty/models/PerkModel';
import { rgbaToHex } from 'lib/helpers';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Column, Row } from 'ui/Flex';
import { AwardGeneralFormData } from './AwardGeneralForm';
import { AwardConfigureFormData } from './AwardConfigureForm';
import { PerkPreviewEditable } from 'components/perks/forms/visualisation/PerkPreviewEditable';
import { AwardTemplate } from 'components/customers/models/Award';
import { ExpiryLabelPreview } from 'components/perks/ExpiryLabelPreview';

interface AwardPreviewFormProps {
    data: AwardGeneralFormData & AwardConfigureFormData;
    open: boolean;
    label?: string;
    clickPrevious: () => void;
    submit: () => void;
    isLoading: boolean;
    primaryActionBackgroundColour?: string;
}

const PREFIX = 'AwardReviewForm';

const classes = {
    box: `${PREFIX}-box`,
    background: `${PREFIX}-background`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.box}`]: {
        padding: theme.spacing(1.5),
        border: `1px solid ${theme.palette.divider}`,
        position: 'relative',
        height: theme.spacing(40),
        width: theme.spacing(60),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    [`& .${classes.background}`]: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        left: 0,
        right: 0,
        bottom: 0
    }
}));

const getReviewData = (data: AwardGeneralFormData & AwardConfigureFormData) => {
    switch (data.perkType) {
        case AwardTemplate.PSEUDO_CURRENCY:
            return { subtitle: '123 points', title: data.titleText, subtitleTwo: '£1.23 worth' };
        default:
            return { subtitle: data.subtitle, title: data.titleText, subtitleTwo: data.subtitle2 };
    }
};

export const AwardPreviewForm: React.FC<AwardPreviewFormProps> = props => {
    const { data, open, isLoading, submit, clickPrevious, label, primaryActionBackgroundColour } = props;

    if (!open) {
        return null;
    }
    const textAlign: CSSProperties['textAlign'] = data.align.toLowerCase() as CSSProperties['textAlign'];
    const { title, subtitle, subtitleTwo } = getReviewData(data);
    const stampsPerCard: number = !!data.stampsPerCard ? Number(data.stampsPerCard) : 6;
    const showExpiryLabelPreview =
        (data.perkType === AwardTemplate.AD_HOC || data.perkType === AwardTemplate.PSEUDO_CURRENCY) &&
        data.showExpiry;

    const renderBox = () => (
        <Column className={classes.box} valign={valignStyle[data.valign]}>
            {data.image && <img className={classes.background} src={data.image} alt="" />}
            {data.backgroundColor && (
                <div
                    className={classes.background}
                    style={{ backgroundColor: rgbaToHex(data.backgroundColor) }}
                />
            )}
            <InputBase
                fullWidth
                disabled
                multiline
                maxRows={2}
                style={{ fontSize: 45 }}
                inputProps={{
                    style: {
                        textAlign: textAlign || 'start',
                        color: rgbaToHex(data.textColor),
                        WebkitTextFillColor: rgbaToHex(data.textColor)
                    }
                }}
                value={title}
            />
            {subtitle && (
                <InputBase
                    fullWidth
                    disabled
                    multiline
                    maxRows={2}
                    style={{ fontSize: 29 }}
                    inputProps={{
                        style: {
                            textAlign: textAlign || 'start',
                            color: rgbaToHex(data.textColor),
                            WebkitTextFillColor: rgbaToHex(data.textColor)
                        }
                    }}
                    value={subtitle}
                />
            )}
            {subtitleTwo && (
                <InputBase
                    fullWidth
                    multiline
                    maxRows={2}
                    disabled
                    style={{ fontSize: 21 }}
                    inputProps={{
                        style: {
                            textAlign: textAlign || 'start',
                            color: rgbaToHex(data.textColor),
                            WebkitTextFillColor: rgbaToHex(data.textColor)
                        }
                    }}
                    value={subtitleTwo}
                />
            )}
            {showExpiryLabelPreview && (
                <ExpiryLabelPreview
                    vertical={data.expiryLabelAnchorStyleVAlign}
                    horizontal={data.expiryLabelAnchorStyleAlign}
                    primaryActionBackgroundColour={primaryActionBackgroundColour}
                />
            )}
        </Column>
    );

    return (
        <StyledBox>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box paddingX={2}>
                        <Typography variant="h4">4. Review</Typography>
                        <Typography variant="body2">
                            Review your award, you can come back and change how it looks if you need to later.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Row flex={1} align="center">
                        {data.perkType === AwardTemplate.STAMP_CARD ? (
                            <Row gutter valign="flex-end">
                                <PerkPreviewEditable
                                    label={
                                        data.perkType === AwardTemplate.STAMP_CARD
                                            ? 'No purchase available'
                                            : undefined
                                    }
                                    {...data}
                                    title={title}
                                    stampsPerCard={stampsPerCard}
                                    textAlign={textAlign}
                                />
                                {data.perkType === AwardTemplate.STAMP_CARD && (
                                    <PerkPreviewEditable
                                        label="Purchase available"
                                        cardAvailable
                                        {...data}
                                        title={title}
                                        stampsPerCard={stampsPerCard}
                                        textAlign={textAlign}
                                    />
                                )}
                            </Row>
                        ) : (
                            renderBox()
                        )}
                    </Row>
                </Grid>
                <Grid item xs={12}>
                    <Row flex={1} gutter align="flex-end">
                        <Button onClick={clickPrevious} color="primary" variant="outlined">
                            Previous
                        </Button>
                        {!!label && (
                            <LoadingButton
                                onClick={submit}
                                color="primary"
                                variant="contained"
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                {label || 'Create'}
                            </LoadingButton>
                        )}
                    </Row>
                </Grid>
            </Grid>
        </StyledBox>
    );
};
