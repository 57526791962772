import React from 'react';

import logger from 'lib/logger';
import { PremiumLoyaltyAudience, UserMetricDefinition } from 'components/metrics/model';
import { metricsApi } from 'components/metrics/metricsApi';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_PREMIUM_LOYALTY_AUDIENCE_DELETE_ERROR,
    MESSAGE_PREMIUM_LOYALTY_AUDIENCE_DELETE_SUCCESS
} from 'config/messages';
import { titleize } from 'lib/helpers';

export function usePremiumLoyaltyAudienceDeleteModal<T extends PremiumLoyaltyAudience>(
    metricDefinition: UserMetricDefinition | undefined,
    premiumAudience: T | undefined,
    loadPremiumLoyalty: () => void,
    instanceName: string
) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();
    const handleOpen = React.useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    const effects = React.useMemo(() => {
        if (!metricDefinition || !metricDefinition.effects) {
            return [];
        }
        return metricDefinition.effects.filter(
            item =>
                (item.type === 'ADD_TO_SEGMENT' || item.type === 'REMOVE_FROM_SEGMENT') &&
                item.segmentId === premiumAudience?.id
        );
    }, [metricDefinition, premiumAudience?.id]);
    const handleSubmit = React.useCallback(async () => {
        setLoading(true);
        const requests = effects.map(item => metricsApi.deleteEffect(metricDefinition?._id, item._id));
        const results = await Promise.all(requests);
        if (results.some(item => !item.ok)) {
            setLoading(false);
            logger.error(`Failed to remove a ${instanceName}`, results);
            dispatch(
                enqueueSnackbar(
                    MESSAGE_PREMIUM_LOYALTY_AUDIENCE_DELETE_ERROR.replace('{{instance}}', instanceName),
                    { variant: 'error' }
                )
            );
            return;
        }
        dispatch(
            enqueueSnackbar(
                MESSAGE_PREMIUM_LOYALTY_AUDIENCE_DELETE_SUCCESS.replace(
                    '{{instance}}',
                    titleize(instanceName)
                ),
                { variant: 'success' }
            )
        );
        loadPremiumLoyalty();
        setLoading(false);
        setOpen(false);
    }, [dispatch, instanceName, effects, loadPremiumLoyalty, metricDefinition?._id]);
    const title = React.useMemo(() => `Delete ${instanceName}?`, [instanceName]);
    const content = React.useMemo(
        () =>
            `Are you sure you want to delete this ${instanceName} associated with ${premiumAudience?.name}. This action is irreversible. `,
        [instanceName, premiumAudience?.name]
    );
    return {
        tiersDeleteModalOpen: open,
        handleTierDeleteOpen: handleOpen,
        handleTierDeleteClose: handleClose,
        handleTierDeleteSubmit: handleSubmit,
        loading,
        title,
        content
    };
}
