import React from 'react';
import {
    getImageAspect,
    getImagePickerDescriptionByDisplay,
    MarketingMessageProps
} from 'components/content/forms/marketing/base';
import { OrderScenario } from '@pepperhq/menu-sdk';
import { EStartOrderDestination } from 'components/content/models/ContentModule';
import { Formik } from 'formik';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { MuiImagePicker } from 'lib/image-picker';
import { Box, Button, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { locationApi } from 'components/location/LocationApi';
import { ITagGroup } from 'components/location/models/LocationModel';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { TagGroupsSelectionModal } from 'components/content/forms/marketing/modals/TagGroupsSelectionModal';
import * as Yup from 'yup';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { getFileName } from 'lib/helpers';
import { tagFilterObjectToString, TagFilter } from 'components/location/models/LocationTagModel';
import { isDefined, isEmptyString } from 'lib/typeguards';
import { StyledImagePickerDescription } from './DefaultMarketingMessageForm';

interface IForm {
    imageUrl: string;
    selectableScenarios: OrderScenario[];
    destination: EStartOrderDestination;
    categoryId: string;
    productId?: string;
    productGroupId?: string;
}

enum EFormField {
    NAME = 'name',
    SELECTABLE_SCENARIOS = 'selectableScenarios',
    DESTINATION = 'destination',
    CATEGORY_ID = 'categoryId',
    PRODUCT_ID = 'productId',
    PRODUCT_GROUP_ID = 'productGroupId',
    IMAGE_URL = 'imageUrl'
}

const selectableScenariosOptions = [
    {
        value: OrderScenario.PREORDER,
        label: 'Preorder'
    },
    {
        value: OrderScenario.ORDER_TO_TABLE,
        label: 'Order To Table'
    }
];

const destinationOptions = [
    {
        value: EStartOrderDestination.MENU,
        label: 'Menu'
    },
    {
        value: EStartOrderDestination.PRODUCT,
        label: 'Product'
    }
];

const REQUIRED_ERROR_MESSAGE = 'This field is required.';

const requiredStringSchema = Yup.string().required(REQUIRED_ERROR_MESSAGE);

const validationSchema = Yup.object().shape({
    [EFormField.IMAGE_URL]: requiredStringSchema.nullable(true),
    [EFormField.NAME]: requiredStringSchema,
    [EFormField.SELECTABLE_SCENARIOS]: Yup.array()
        .of(Yup.string())
        .required(REQUIRED_ERROR_MESSAGE)
        .min(1, REQUIRED_ERROR_MESSAGE),
    [EFormField.DESTINATION]: requiredStringSchema,
    [EFormField.CATEGORY_ID]: Yup.string().when(EFormField.DESTINATION, {
        is: EStartOrderDestination.PRODUCT,
        then: schema => schema.required(REQUIRED_ERROR_MESSAGE)
    }),
    [EFormField.PRODUCT_ID]: Yup.string().when(EFormField.DESTINATION, {
        is: EStartOrderDestination.PRODUCT,
        then: schema =>
            schema.when(EFormField.PRODUCT_GROUP_ID, {
                is: (value?: string) => isEmptyString(value) || !isDefined(value),
                then: newSchema => newSchema.required('Product ID or Product Group ID is required.')
            })
    })
});

export const StartOrderMessageProperties = (props: MarketingMessageProps<any>) => {
    const [isTagGroupsLoading, setTagGroupsLoading] = React.useState(false);
    const [isTagGroupModalOpen, setTagGroupModalOpen] = React.useState(false);
    const [tagFilter, setTagFilter] = React.useState<TagFilter>({});
    const [tagGroups, setTagGroups] = React.useState<ITagGroup[]>([]);
    const dispatch = useDispatch();

    const handleSubmit = React.useCallback(
        ({
            imageUrl,
            name,
            selectableScenarios,
            destination,
            categoryId,
            productId,
            productGroupId
        }: IForm & { name: string }) => {
            const tagsValue = tagFilterObjectToString(tagFilter);

            const properties = {
                imageUrl,
                name,
                selectableScenarios,
                ...(destination === EStartOrderDestination.PRODUCT && {
                    focus: {
                        type: destination,
                        properties: {
                            categoryId,
                            productId: isEmptyString(productId) ? undefined : productId,
                            productGroupId: isEmptyString(productGroupId) ? undefined : productGroupId
                        }
                    }
                }),

                ...(tagsValue &&
                    tagsValue.length && {
                        selectableLocations: {
                            tags: tagsValue
                        }
                    })
            };

            props.onSubmit(properties);
        },
        [props, tagFilter]
    );

    const handleTagGroupsOpen = React.useCallback(() => {
        setTagGroupModalOpen(true);
    }, []);

    const initialFormValues = React.useMemo(() => {
        const { imageUrl, selectableScenarios, focus } = props.properties ?? {};

        return {
            imageUrl: imageUrl ?? '',
            name: props.name ?? '',
            selectableScenarios: selectableScenarios ?? [],
            destination: focus?.type || EStartOrderDestination.MENU,
            categoryId: focus?.properties?.categoryId ?? '',
            productId: focus?.properties?.productId ?? '',
            productGroupId: focus?.properties?.productGroupId ?? ''
        };
    }, [props.name, props.properties]);

    const makeImageChangeHandler = React.useCallback(
        (changeHandler: (changeEvent: any) => void) => (imageUrl: string) => {
            changeHandler({ target: { value: imageUrl, name: EFormField.IMAGE_URL } });
        },
        []
    );

    const handleTagGroupModalClose = React.useCallback(() => {
        setTagGroupModalOpen(false);
    }, []);

    const tagFilterValue = React.useMemo(() => {
        const tagFilterEntries = Object.entries(tagFilter);

        if (!tagFilterEntries.length) {
            return 'No tags selected';
        }

        const tagStrings: (string | undefined)[] = [];

        tagFilterEntries.forEach(([tagGroupId, tags]) => {
            const selectedTagIds = Object.entries(tags)
                .filter(([_, value]) => value)
                .map(([tagId]) => tagId);

            if (selectedTagIds.length) {
                const tagGroup = tagGroups.find(t => t.id === tagGroupId);

                if (tagGroup) {
                    tagStrings.push(
                        `${tagGroup.title} - ${selectedTagIds
                            .map(tagId => tagGroup.tags[tagId]?.title)
                            .join(', ')}`
                    );
                }
            }
        });

        return tagStrings.join(', ');
    }, [tagFilter, tagGroups]);

    React.useEffect(() => {
        const { selectableLocations } = props.properties ?? {};
        const { tags } = selectableLocations ?? {};
        if (tags && tags.length) {
            const resultTagFilter: TagFilter = {};
            tags.forEach((tag: string) => {
                const [groupId, tagId] = tag.split('/');
                resultTagFilter[groupId] = { ...resultTagFilter[groupId], [tagId]: true };
            });

            setTagFilter(resultTagFilter);
        }
    }, [props.properties]);

    React.useEffect(() => {
        setTagGroupsLoading(true);
        locationApi
            .getTagGroups()
            .then(res => {
                setTagGroups(res.body.items);
            })
            .catch(() => {
                dispatch(
                    enqueueSnackbar('Unexpected error occurred while getting tag groups', {
                        variant: 'error'
                    })
                );
            })
            .finally(() => {
                setTagGroupsLoading(false);
            });
    }, [dispatch]);

    return (
        <>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialFormValues}
                validationSchema={validationSchema}
            >
                {({ handleSubmit: onSubmit, values, handleChange: onChange, errors, isValid }) => {
                    const imageChangeHandler = makeImageChangeHandler(onChange);
                    return (
                        <form onSubmit={onSubmit}>
                            <MuiFormLabel required>Image</MuiFormLabel>
                            <MuiImagePicker
                                url={values.imageUrl}
                                onSelect={imageChangeHandler}
                                uploadFileName={getFileName(values.imageUrl)}
                                aspect={getImageAspect(props.display)}
                                accept={['image/jpeg', 'image/png', 'image/gif']}
                            />
                            <StyledImagePickerDescription variant="caption">
                                {getImagePickerDescriptionByDisplay(props.display)}
                            </StyledImagePickerDescription>
                            {!!errors.imageUrl && (
                                <InputLabel shrink error sx={{ mt: 1.5 }}>
                                    {errors.imageUrl}
                                </InputLabel>
                            )}
                            <Box mt={1}>
                                <TextFormField
                                    name={EFormField.NAME}
                                    label={<MuiFormLabel required>Reporting Name</MuiFormLabel>}
                                    description="This code is used to track the number of marketing module item taps on anchor screen"
                                />
                            </Box>
                            <Box mt={3}>
                                <SelectFormField
                                    name={EFormField.SELECTABLE_SCENARIOS}
                                    options={selectableScenariosOptions}
                                    label={<MuiFormLabel required>Ordering Scenario Available</MuiFormLabel>}
                                    multiple
                                />
                            </Box>

                            <Box mt={3}>
                                <InputLabel sx={{ mb: 1 }}>Filter Locations by Tags</InputLabel>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    disabled={isTagGroupsLoading}
                                    value={tagFilterValue || 'No tags selected'}
                                    onClick={handleTagGroupsOpen}
                                    InputProps={{
                                        readOnly: true,
                                        sx: {
                                            cursor: 'pointer'
                                        }
                                    }}
                                    inputProps={{ style: { cursor: 'pointer' } }}
                                />
                            </Box>

                            <Box mt={2.5}>
                                <SelectFormField
                                    name={EFormField.DESTINATION}
                                    options={destinationOptions}
                                    label={<MuiFormLabel required>Destination</MuiFormLabel>}
                                />
                            </Box>

                            {values.destination === EStartOrderDestination.PRODUCT && (
                                <Box mt={2.5}>
                                    <Typography variant="h6" color="primary">
                                        Deep Link to Product
                                    </Typography>
                                    <Typography variant="body2">
                                        Configure the product you want your users to be directed to.
                                    </Typography>

                                    <Box mt={2}>
                                        <TextFormField
                                            name={EFormField.CATEGORY_ID}
                                            label={<MuiFormLabel required>Category ID</MuiFormLabel>}
                                        />
                                    </Box>
                                    <Box mt={1.5}>
                                        <TextFormField
                                            name={EFormField.PRODUCT_GROUP_ID}
                                            label={<MuiFormLabel>Product Group ID</MuiFormLabel>}
                                        />
                                    </Box>
                                    <Box mt={1.5}>
                                        <TextFormField
                                            name={EFormField.PRODUCT_ID}
                                            label={<MuiFormLabel>Product ID</MuiFormLabel>}
                                        />
                                    </Box>
                                </Box>
                            )}
                            <Box mt={2}>
                                <Grid container spacing={2}>
                                    {props.contextForm()}
                                </Grid>
                            </Box>
                            <Box display="flex" flex={1} justifyContent="flex-end" mt={1.5}>
                                <Button variant="contained" color="primary" type="submit" disabled={!isValid}>
                                    Submit
                                </Button>
                            </Box>
                        </form>
                    );
                }}
            </Formik>
            <TagGroupsSelectionModal
                open={isTagGroupModalOpen}
                tagGroups={tagGroups}
                onSubmit={setTagFilter}
                tagFilter={tagFilter}
                onClose={handleTagGroupModalClose}
                closeOnSubmit
            />
        </>
    );
};
