import React from 'react';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { MuiModal } from 'ui/MuiModal';
import { isDefined } from 'lib/typeguards';

interface DeleteDialogProps extends DeleteDialogContentProps {
    open: boolean;
    protectionWord?: string;
}

export function DeleteDialog(props: DeleteDialogProps) {
    const { open, protectionWord, ...rest } = props;
    if (isDefined(protectionWord)) {
        return (
            <MuiModal open={open} onClose={rest.onClose} paperMaxWidth={720}>
                <ProtectedDeleteDialogContent protectionWord={protectionWord} {...rest} />
            </MuiModal>
        );
    }
    return (
        <MuiModal open={open} onClose={rest.onClose}>
            <DeleteDialogContent {...rest} />
        </MuiModal>
    );
}

interface DeleteDialogContentProps {
    title: React.ReactNode;
    content: React.ReactNode;
    submitLabel?: string;
    discardLabel?: string;
    onClose: () => void;
    loading?: boolean;
    onDelete: () => void;
}
function DeleteDialogContent({
    title,
    content,
    onClose,
    loading,
    onDelete,
    submitLabel,
    discardLabel
}: DeleteDialogContentProps) {
    return (
        <React.Fragment>
            <Typography variant="h5" color="primary">
                {title}
            </Typography>
            <Divider sx={{ my: 2, mx: 0 }} />
            <Typography variant="body1">{content}</Typography>
            <Divider sx={{ my: 2, mx: 0 }} />
            <Box justifyContent="flex-end" sx={{ width: '100%', display: 'flex' }}>
                <Button sx={{ mr: 2.25 }} variant="outlined" color="primary" onClick={onClose}>
                    {discardLabel || 'Cancel'}
                </Button>
                <LoadingButton
                    loading={loading}
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    onClick={onDelete}
                >
                    {submitLabel || 'Delete'}
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
}

interface ProtectedDeleteDialogProps extends DeleteDialogContentProps {
    protectionWord: string;
}

// TODO: why do we have such a component? Cannot we reduce the amount of code?
function ProtectedDeleteDialogContent({
    title,
    content,
    onClose,
    loading,
    onDelete,
    submitLabel,
    discardLabel,
    protectionWord
}: ProtectedDeleteDialogProps) {
    const [deleteInputValue, setDeleteInputValue] = React.useState('');

    const handleDeleteCustomerInput = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteInputValue(event.target.value);
    }, []);

    return (
        <React.Fragment>
            <Typography variant="h5" color="primary">
                {title}
            </Typography>
            <Divider sx={{ my: 2, mx: 0 }} />
            <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                {content}
            </Typography>
            <TextField fullWidth value={deleteInputValue} onChange={handleDeleteCustomerInput} />
            <Divider sx={{ my: 2, mx: 0 }} />
            <Box justifyContent="flex-end" sx={{ width: '100%', display: 'flex' }}>
                <Button sx={{ mr: 2.25 }} variant="outlined" color="primary" onClick={onClose}>
                    {discardLabel || 'Cancel'}
                </Button>
                <LoadingButton
                    loading={loading}
                    disabled={loading || deleteInputValue !== protectionWord}
                    variant="contained"
                    color="primary"
                    onClick={onDelete}
                >
                    {submitLabel || 'Delete'}
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
}
