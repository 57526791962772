import { ScheduledTaskStatus, ScheduledTaskType } from 'components/scheduled-tasks/scheduledTasks.types';
import React from 'react';
import { Row } from 'ui/Flex';
import { Box, InputAdornment } from '@mui/material';
import { ScheduledTaskStatusFilter } from './ScheduledTaskStatusFilter';
import Search from '@mui/icons-material/Search';
import { DebouncedGridFilterInput } from 'lib/grid/DebouncedGridFilterInput';

export interface ScheduledTasksFilter {
    status?: ScheduledTaskStatus;
    type?: ScheduledTaskType;
    search?: string;
}
export type ScheduledTasksFilterChange = (field: keyof ScheduledTasksFilter, value: string) => void;

interface ScheduledTaskFiltersProps {
    filter: ScheduledTasksFilter;
    onChange: ScheduledTasksFilterChange;
}

export const ScheduledTaskFilters: React.FC<ScheduledTaskFiltersProps> = ({ filter, onChange }) => {
    const handleStatusChange = React.useCallback(
        (value?: string) => {
            onChange('status', value);
        },
        [onChange]
    );
    const handleSearchChange = React.useCallback(
        (value?: string) => {
            onChange('search', value);
        },
        [onChange]
    );

    return (
        <Row flex={1} wrap="wrap" valign="center">
            <Box marginRight={1}>
                <DebouncedGridFilterInput
                    value={filter.search}
                    onChange={handleSearchChange}
                    name="search"
                    placeholder="Search by title, message or tag"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        )
                    }}
                />
            </Box>
            <Box marginRight={1}>
                <ScheduledTaskStatusFilter value={filter.status} onChange={handleStatusChange} />
            </Box>
        </Row>
    );
};
