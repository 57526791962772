import React from 'react';
import { OrderScenario } from 'components/order/model/Order';
import { CUSTOMERS_VIEW, LOCATIONS } from 'config/routes';
import { titleize } from 'lib/helpers';
import { Link } from 'ui/Link';
import {
    ActionType,
    IAction,
    isActionAudienceMetadata,
    isActionOrderCompletedMetadataOC1,
    isActionOrderCreated,
    isActionOrderPaymentReceived,
    isActionOrderUserJoined,
    isActionReferalClaimedRefereeMetadata,
    isActionReferalClaimedRefererMetadata,
    isActionRewardBurnedMetadata,
    isActionRedeemPerkMetadata,
    isActionRewardEarnedMetadata,
    isActionVoucherRedeemed,
    isCheckinAction,
    isOrderCompletedOC1Action,
    isOrderCompletedOC2Action,
    isOrderUserBilledAction,
    isActionRewardResetMetadata
} from '../models/Action';

const stopEventPropagation = (event: React.MouseEvent) => event.stopPropagation();

function buildLink(href: string, text: string): JSX.Element {
    return (
        <Link onClick={stopEventPropagation} href={href}>
            {text ?? 'N/A'}
        </Link>
    );
}

function mapActionTypeToTimelineTitleRewardEarned(action: IAction): React.ReactNode {
    // TODO: should hyperlink to award page when it exists
    // TODO: should we get perk/award title?
    if (!isActionRewardEarnedMetadata(action.metadata)) {
        return 'Awarded point(s)';
    }
    return `Awarded ${action.metadata.pointsAdded} point${action.metadata.pointsAdded > 1 ? 's' : ''}`;
}

function mapActionTypeToTimelineTitleRewardReset(action: IAction): React.ReactNode {
    // TODO: should hyperlink to award page when it exists
    // TODO: should we get perk/award title?
    if (!isActionRewardResetMetadata(action.metadata)) {
        return 'Awarded points were reset';
    }
    return `Awarded points were reset from ${action.metadata.pointsAvailableBefore} point${
        action.metadata.pointsAvailableBefore > 1 ? 's' : ''
    } to ${action.metadata.pointsAvailableAfter} point${action.metadata.pointsAvailableAfter > 1 ? 's' : ''}`;
}

function mapActionTypeToTimelineTitleRewardBurned(action: IAction): React.ReactNode {
    // TODO: should hyperlink to award page when it exists
    // TODO: should we get perk/award title?
    if (!isActionRewardBurnedMetadata(action.metadata)) {
        return 'Redeemed award point(s)';
    }
    return `Redeemed ${action.metadata.loyaltyBurned} point${action.metadata.loyaltyBurned > 1 ? 's' : ''}`;
}

function mapActionTypeToTimelineTitleRedeemPerk(action: IAction): React.ReactNode {
    if (!isActionRedeemPerkMetadata(action.metadata)) {
        return 'Redeemed award point(s)';
    }
    return `Redeemed ${action.metadata.pointsRedeemed} point${action.metadata.pointsRedeemed > 1 ? 's' : ''}`;
}

function mapActionTypeToTimelineTitleMemberJoined(action: IAction): React.ReactNode {
    // TODO: should hyperlink to audience page when it exists
    if (!isActionAudienceMetadata(action.metadata)) {
        return 'Joined an audience';
    }
    return `Joined an audience: ${action.metadata.audienceTitle}`;
}

function mapActionTypeToTimelineTitleMemberRemoved(action: IAction): React.ReactNode {
    // TODO: should hyperlink to audience page when it exists
    if (!isActionAudienceMetadata(action.metadata)) {
        return 'Left an audience';
    }
    return `Left an audience: ${action.metadata.audienceTitle}`;
}

function mapActionTypeToTimelineTitleReferalClaimedReferee(action: IAction): React.ReactNode {
    if (!isActionReferalClaimedRefereeMetadata(action.metadata)) {
        return 'Referred by a customer';
    }
    return (
        <>
            Referred by{' '}
            {buildLink(
                `${CUSTOMERS_VIEW.replace(/:customerId/g, action.metadata.refererId)}`,
                action.metadata.refererFullName
            )}
        </>
    );
}

function mapActionTypeToTimelineTitleReferalClaimedReferer(action: IAction): React.ReactNode {
    if (!isActionReferalClaimedRefererMetadata(action.metadata)) {
        return 'Referred a customer';
    }
    return (
        <>
            Referred{' '}
            {buildLink(
                `${CUSTOMERS_VIEW.replace(/:customerId/g, action.metadata.refereeId)}`,
                action.metadata.refereeFullName
            )}
        </>
    );
}

function mapActionTypeToTimelineTitleCheckin(action: IAction): React.ReactNode {
    if (!isCheckinAction(action)) {
        return 'Checked in at a location';
    }
    return (
        <>
            Checked in at{' '}
            {buildLink(`${LOCATIONS}/${action.context.location._id}`, action.context.location.title)}
        </>
    );
}

function mapActionTypeToTimelineTitleOrderCompleted(action: IAction): React.ReactNode {
    if (!isOrderCompletedOC2Action(action) && !isOrderCompletedOC1Action(action)) {
        return 'Completed an order';
    }
    const { scenario } = isActionOrderCompletedMetadataOC1(action.metadata)
        ? action.metadata
        : action.metadata.order;
    // TODO: should hyperlink to order page when it exists
    return (
        <>
            {scenario === OrderScenario.PREORDER && 'Completed a pre-order at'}
            {(scenario === OrderScenario.TAB || scenario === OrderScenario.TABLE) && 'Closed a tab at'}
            {scenario === OrderScenario.PAYATPOS && 'Completed a POS order at'}
            {scenario === OrderScenario.ORDER_TO_TABLE && 'Finished ordering to table at'}{' '}
            {buildLink(`${LOCATIONS}/${action.context.location._id}`, action.context.location.title)}
        </>
    );
}

function mapActionTypeToTimelineTitleOrderCreated(action: IAction): React.ReactNode {
    if (!isActionOrderCreated(action)) {
        return 'Opened an order';
    }
    const { scenario } = action.metadata;
    // TODO: should hyperlink to order page when it exists
    return (
        <>
            {scenario === OrderScenario.PREORDER && 'Placed a pre-order at'}
            {(scenario === OrderScenario.TAB || scenario === OrderScenario.TABLE) && 'Opened a tab at'}
            {scenario === OrderScenario.PAYATPOS && 'Opened an order via POS at'}
            {scenario === OrderScenario.ORDER_TO_TABLE && 'Ordered to table at'}{' '}
            {buildLink(`${LOCATIONS}/${action.context.location._id}`, action.context.location.title)}
        </>
    );
}

function mapActionTypeToTimelineTitleOrderPaymentReceived(action: IAction): React.ReactNode {
    if (!isActionOrderPaymentReceived(action)) {
        return 'Paid towards an order';
    }
    const { scenario } = action.metadata.order;
    // TODO: should hyperlink to order page when it exists
    return (
        <>
            {scenario === OrderScenario.PREORDER && 'Paid off a pre-order at'}
            {(scenario === OrderScenario.TAB || scenario === OrderScenario.TABLE) && 'Paid towards a tab at'}
            {scenario === OrderScenario.PAYATPOS && 'Paid a POS order at'}
            {scenario === OrderScenario.ORDER_TO_TABLE && 'Paid for an order to table at'}{' '}
            {buildLink(`${LOCATIONS}/${action.context.location._id}`, action.context.location.title)}
        </>
    );
}

function mapActionTypeToTimelineTitleOrderUserBilled(action: IAction): React.ReactNode {
    if (!isOrderUserBilledAction(action)) {
        return 'Received a receipt for an order';
    }
    const { scenario } = action.metadata.order;
    // TODO: should hyperlink to order page when it exists
    return (
        <>
            {scenario === OrderScenario.PREORDER && 'Received a receipt for a pre-order at'}
            {(scenario === OrderScenario.TAB || scenario === OrderScenario.TABLE) &&
                'Received a receipt for a tab at'}
            {scenario === OrderScenario.PAYATPOS && 'Received a receipt for a POS order at'}
            {scenario === OrderScenario.ORDER_TO_TABLE && 'Received a receipt for an order to table at'}{' '}
            {buildLink(`${LOCATIONS}/${action.context.location._id}`, action.context.location.title)}
        </>
    );
}

function mapActionTypeToTimelineTitleOrderUserJoined(action: IAction): React.ReactNode {
    if (!isActionOrderUserJoined(action)) {
        return 'Joined a tab';
    }
    const { scenario } = action.metadata.order;
    // TODO: should hyperlink to order page when it exists
    return (
        <>
            {scenario === OrderScenario.PREORDER && 'Joined a pre-order at'}
            {(scenario === OrderScenario.TAB || scenario === OrderScenario.TABLE) && 'Joined a tab at'}
            {scenario === OrderScenario.PAYATPOS && 'Joined an order via POS at'}
            {scenario === OrderScenario.ORDER_TO_TABLE && 'Joined a table at'}{' '}
            {buildLink(`${LOCATIONS}/${action.context.location._id}`, action.context.location.title)}
        </>
    );
}

function mapActionTypeToTimelineTitleVoucherRedeemed(action: IAction): React.ReactNode {
    if (!isActionVoucherRedeemed(action)) {
        return 'Redeemed a voucher';
    }
    // TODO: should hyperlink to voucher page when it exists
    return `Redeemed a voucher: ${action.metadata.voucherTitle}`;
}

export function lightFormatActionTypeToTitle(value: string) {
    return titleize(value.replace(/_/g, ' ').toLowerCase());
}

export function mapActionTypeToTimelineTitle(action: IAction): React.ReactNode {
    switch (action.type) {
        case ActionType.ORDER_COMPLETED:
            return mapActionTypeToTimelineTitleOrderCompleted(action);
        case ActionType.ORDER_CREATED:
            return mapActionTypeToTimelineTitleOrderCreated(action);
        case ActionType.ORDER_PAYMENT_RECEIVED:
            return mapActionTypeToTimelineTitleOrderPaymentReceived(action);
        case ActionType.ORDER_USER_BILLED:
            return mapActionTypeToTimelineTitleOrderUserBilled(action);
        case ActionType.ORDER_PAYMENT_REFUNDED:
            return 'Payment refunded';
        case ActionType.ORDER_USER_JOINED:
            return mapActionTypeToTimelineTitleOrderUserJoined(action);
        case ActionType.PURCHASE:
            // TODO: should hyperlink to order page when it exists
            return 'Paid for an order';
        case ActionType.REFUND:
            // TODO: should hyperlink to award page when it exists
            return 'Received a refund';
        case ActionType.REDEEM_PERK:
            return mapActionTypeToTimelineTitleRedeemPerk(action);
        case ActionType.REWARD_BURNED:
            return mapActionTypeToTimelineTitleRewardBurned(action);
        case ActionType.REWARD_EARNED:
            return mapActionTypeToTimelineTitleRewardEarned(action);
        case ActionType.AWARD_POINTS_REFUNDED:
            // TODO: should hyperlink to award page when it exists
            return 'Received an award points refund';
        case ActionType.AWARD_POINTS_RESET:
            return mapActionTypeToTimelineTitleRewardReset(action);
        case ActionType.VOUCHER_REDEEMED:
            return mapActionTypeToTimelineTitleVoucherRedeemed(action);
        case ActionType.MEMBER_ADDED:
            return mapActionTypeToTimelineTitleMemberJoined(action);
        case ActionType.MEMBER_REMOVED:
            return mapActionTypeToTimelineTitleMemberRemoved(action);
        case ActionType.REFERAL_CLAIMED_REFEREE:
            return mapActionTypeToTimelineTitleReferalClaimedReferee(action);
        case ActionType.REFERAL_CLAIMED_REFERER:
            return mapActionTypeToTimelineTitleReferalClaimedReferer(action);
        case ActionType.CHECKIN:
            return mapActionTypeToTimelineTitleCheckin(action);
        case ActionType.SURVEY_RESPONDED:
            return 'Responded to a survey';
        case ActionType.USER_ACTIVATED:
            return 'Activated their account';
        case ActionType.USER_ACTIVATION_RESENT:
            return 'Resent another activation email';
        case ActionType.CREDENTIAL_VERIFIED:
            return 'Verified their credentials';
        case ActionType.USER_CHANGED:
            return 'Updated their details';
        case ActionType.USER_CREATED:
            return 'Created their account';
        case ActionType.USER_CREDITED:
            return 'Credited';
        case ActionType.USER_DELETED:
            return 'Deleted their account';
        case ActionType.CARD_CREATED:
            return 'Added a card';
        case ActionType.CARD_DELETED:
            return 'Deleted a card';
        case ActionType.TOPUP:
            return 'Topped up their balance';
        case ActionType.TOPUP_REFUNDED:
            return 'Refunded some of their balance';
        case ActionType.CAUSE_GIFT:
            return 'Donated to a cause';
        case ActionType.GIFT_CARD_ACTIVATED:
            return 'Activated a gift card';
        case ActionType.GIFT_CARD_TOPUP:
            return 'Topped up a gift card';
        case ActionType.ORDER_ITEMS_ADDED:
            return 'Items Added to Order';
        case ActionType.COUPON_REDEEMED:
            return 'Redeemed a coupon';
        default:
            return 'Unknown action';
    }
}
