import React from 'react';
import { Box, Grid, Skeleton, styled } from '@mui/material';
import { segmentsApi } from 'components/audience/segmentsApi';
import { locationApi } from 'components/location/LocationApi';
import { Panel } from 'ui/Panel';
import { CardViewChipArrayRow, CardViewChipRow, CardViewTextRow, CardViewTitle } from 'ui/viewComponents';
import { formatDateTime } from 'lib/timeHelpers';
import { contentApi } from 'components/content/contentApi';
import { ResourceText } from 'lib/ResourceText';
import {
    IScheduledTaskCreatePushNotification,
    ScheduledTaskStatus
} from 'components/scheduled-tasks/scheduledTasks.types';
import { mapScheduledTaskStatusToStyles } from 'components/scheduled-tasks/helpers';
import { renderDeepLinkTitle } from 'components/notification/helpers';

const PREFIX = 'ScheduledTaskNotificationDetailsCard';

const classes = {
    stateChip: `${PREFIX}-stateChip`
};

const StyledBox = styled(Box)<{ status: ScheduledTaskStatus }>(({ theme, status }) => ({
    [`& .${classes.stateChip}`]: {
        ...mapScheduledTaskStatusToStyles(status, theme),
        fontSize: '0.8rem'
    }
}));

interface ScheduledTaskNotificationDetailsCardProps {
    scheduledTask: IScheduledTaskCreatePushNotification;
}

export const ScheduledTaskNotificationDetailsCard: React.FC<
    ScheduledTaskNotificationDetailsCardProps
> = props => {
    const { scheduledTask } = props;
    const [locations, setLocations] = React.useState(null);
    const [audiences, setAudiences] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        async function getData() {
            if (
                Array.isArray(scheduledTask.parameters.locationIds) &&
                scheduledTask.parameters.locationIds.length > 0
            ) {
                const result = await Promise.all(
                    scheduledTask.parameters.locationIds.map(item => locationApi.get(item))
                );
                const locationNames: string[] = [];
                result.forEach(item => {
                    if (item.ok) {
                        locationNames.push(item.body.title);
                    }
                });
                if (locationNames.length) {
                    setLocations(locationNames);
                }
            }
            // test segments
            if (
                Array.isArray(scheduledTask.parameters.segmentIds) &&
                scheduledTask.parameters.segmentIds.length > 0
            ) {
                const result = await Promise.all(
                    scheduledTask.parameters.segmentIds.map(item => segmentsApi.get(item))
                );
                const audienceNames: string[] = [];
                result.forEach(item => {
                    if (item.ok) {
                        audienceNames.push(item.body.title);
                    }
                });
                if (audienceNames.length) {
                    setAudiences(audienceNames);
                }
            }
            setIsLoading(false);
        }
        getData();
    }, [scheduledTask.parameters.locationIds, scheduledTask.parameters.segmentIds]);
    return (
        <Panel title="Details" divider>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <StyledBox status={scheduledTask.status}>
                        <CardViewChipRow
                            {...props}
                            title="State"
                            value={scheduledTask.status}
                            chipClasses={{ root: classes.stateChip }}
                        />
                    </StyledBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CardViewTextRow
                        title="Created On"
                        noTransform
                        value={formatDateTime(scheduledTask.createdAt)}
                        fallback="Processing"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CardViewTextRow
                        title="Updated On"
                        noTransform
                        value={formatDateTime(scheduledTask.updatedAt)}
                        fallback="Processing"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CardViewTextRow
                        title="Sent On"
                        noTransform
                        value={formatDateTime(scheduledTask.triggerAt)}
                        fallback="Processing"
                    />
                </Grid>
                {scheduledTask.parameters.title && (
                    <Grid item xs={12} lg={6}>
                        <CardViewTextRow title="Title" value={scheduledTask.parameters.title} />
                    </Grid>
                )}
                <Grid item xs={12} lg={6}>
                    <CardViewTextRow title="Message" value={scheduledTask.parameters.message} />
                </Grid>
                {scheduledTask.parameters.imageUrl && (
                    <Grid item xs={12} lg={6}>
                        <Grid item xs={12}>
                            <CardViewTitle title="Image" />
                            <img
                                src={scheduledTask.parameters.imageUrl}
                                alt="Image"
                                style={{
                                    aspectRatio: 'initial',
                                    maxWidth: '100%',
                                    maxHeight: '200px'
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
                {scheduledTask.parameters.deepLink && (
                    <Grid item xs={12} lg={6}>
                        <Grid item xs={12}>
                            <CardViewTitle title="Deep Link" noTransform />
                            <ResourceText
                                id={scheduledTask.parameters.deepLink?.moduleId}
                                crud={contentApi.modules}
                                title={renderDeepLinkTitle(scheduledTask.parameters.deepLink)}
                            />
                        </Grid>
                    </Grid>
                )}
                {scheduledTask.parameters.tag && (
                    <Grid item xs={12} lg={6}>
                        <CardViewTextRow title="Tag" value={scheduledTask.parameters.tag} />
                    </Grid>
                )}
                {scheduledTask.parameters.global && (
                    <Grid item xs={12} lg={6}>
                        <CardViewTextRow title="Sent To" value="All Users" />
                    </Grid>
                )}
                {isLoading && (
                    <Grid item xs={12} lg={6}>
                        <Box marginBottom={1} flex={1}>
                            <Skeleton height={10} width="40%" />
                            <Skeleton variant="rectangular" height={24} width="80%" />
                        </Box>
                    </Grid>
                )}
                {Array.isArray(locations) && (
                    <Grid item xs={12} lg={6}>
                        <CardViewChipArrayRow title="Locations" value={locations} />
                    </Grid>
                )}
                {Array.isArray(audiences) && (
                    <Grid item xs={12} lg={6}>
                        <CardViewChipArrayRow title="Audiences" value={audiences} />
                    </Grid>
                )}
            </Grid>
        </Panel>
    );
};
