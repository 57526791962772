import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Stepper, Step, StepButton, StepLabel, IconButton } from '@mui/material';

import { ApplicationState } from 'store/store';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { Column, Row } from 'ui/Flex';
import {
    MESSAGE_MARKETING_ADJUSTMENT_SCHEME_CREATE_SUCCESS,
    MESSAGE_MARKETING_ADJUSTMENT_SCHEME_CREATE_ERROR
} from 'config/messages';
import { AdjustmentSchemeGeneralForm, ConfigureSchemeGeneralFormData } from './AdjustmentSchemeGeneralForm';
import {
    AdjustmentSchemeRedemptionForm,
    AdjustmentSchemeRedemptionFormData
} from './redemption-form/AdjustmentSchemeRedemptionForm';
import { AdjustmentScheme } from '../adjustmentSchemeModel';
import { getAdjustmentSchemeMutationBody } from '../utils/adjustment-schemes-utils';
import { adjustmentSchemesApi } from '../adjustmentSchemesApi';

const steps = ['Define', 'Redemption'];

export interface AdjustmentSchemeCreateStepperProps {
    currencySymbol: string;
    existingCodes: string[];
    onClose: () => void;
    onSuccess: (scheme: AdjustmentScheme) => void;
}

export const AdjustmentSchemeCreateStepper: React.FC<AdjustmentSchemeCreateStepperProps> = ({
    currencySymbol,
    existingCodes,
    onClose,
    onSuccess
}) => {
    const dispatch = useDispatch();
    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const [activeStep, setActiveStep] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [generalData, setGeneralData] = React.useState<ConfigureSchemeGeneralFormData>();
    const [redemptionData, setRedemptionData] = React.useState<AdjustmentSchemeRedemptionFormData>();

    const createScheme = React.useCallback(
        async (data: {
            general: ConfigureSchemeGeneralFormData;
            redemption: AdjustmentSchemeRedemptionFormData;
        }) => {
            setIsLoading(true);
            const body = getAdjustmentSchemeMutationBody(data, settings?.region?.timezone);
            try {
                const result = await adjustmentSchemesApi.create({ body });
                if (!result.ok) {
                    throw new Error(result.body.message);
                }
                setIsLoading(false);
                dispatch(
                    enqueueSnackbar(MESSAGE_MARKETING_ADJUSTMENT_SCHEME_CREATE_SUCCESS, {
                        variant: 'success'
                    })
                );
                return onSuccess(result.body);
            } catch (error) {
                setIsLoading(false);
                dispatch(
                    enqueueSnackbar(MESSAGE_MARKETING_ADJUSTMENT_SCHEME_CREATE_ERROR, { variant: 'error' })
                );
            }
        },
        [dispatch, onSuccess, settings?.region?.timezone]
    );

    const handleGeneralSubmit = React.useCallback((data: ConfigureSchemeGeneralFormData) => {
        setGeneralData(data);
        setActiveStep(1);
    }, []);

    const handlePrevious = React.useCallback(() => {
        setActiveStep(0);
    }, []);

    const handleRedemptionPrevious = React.useCallback((_, values: AdjustmentSchemeRedemptionFormData) => {
        setRedemptionData(values);
        setActiveStep(0);
    }, []);

    const handleRedemptionSubmit = React.useCallback(
        (data: AdjustmentSchemeRedemptionFormData) => {
            setRedemptionData(data);
            setActiveStep(1);
            createScheme({ general: generalData, redemption: data });
        },
        [generalData, createScheme]
    );

    const handleStepClick = React.useCallback(
        (index: number) => () => {
            setActiveStep(index);
        },
        [setActiveStep]
    );

    return (
        <Box width="100%" maxWidth={1600}>
            <Column>
                <Row valign="flex-start" align="center">
                    <Box flex={1} maxWidth={500}>
                        <Stepper nonLinear alternativeLabel activeStep={activeStep}>
                            {steps.map((item, index) => (
                                <Step key={`step-button-${item}`} completed={activeStep > index}>
                                    <StepButton
                                        disableRipple
                                        disabled={activeStep < index}
                                        onClick={handleStepClick(index)}
                                        color="inherit"
                                    >
                                        <StepLabel>{item}</StepLabel>
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <Box position="absolute" top={0} right={0}>
                        <IconButton size="large" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Row>
            </Column>
            <AdjustmentSchemeGeneralForm
                open={activeStep === 0}
                onSubmit={handleGeneralSubmit}
                initialValues={generalData}
                existingCodes={existingCodes}
            />
            <AdjustmentSchemeRedemptionForm
                open={activeStep === 1}
                isEdit={false}
                currencySymbol={currencySymbol}
                initialData={redemptionData}
                clickPrevious={handlePrevious}
                onPrevious={handleRedemptionPrevious}
                isLoading={isLoading}
                onSubmit={handleRedemptionSubmit}
            />
        </Box>
    );
};
