import { Box, Divider, Tooltip, Typography } from '@mui/material';
import { TimelineConnector, TimelineContent, TimelineDot, TimelineSeparator } from '@mui/lab';
import React from 'react';
import { useCurrencyString } from 'lib/hooks/useCurrencyString';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { IPaymentTimelineItem } from '../hooks/useOrderTimelinePayments';
import { Row } from 'ui/Flex';
import { EOrderPaymentType } from '../model/Order';
import { BasicStringReplace } from 'lib/BasicStringReplace';
import { Link } from 'react-router-dom';
import { CUSTOMERS_VIEW, PEPPER_PAY_TRANSACTIONS_VIEW } from 'config/routes';
import { isDefined, isString } from 'lib/typeguards';
import { LoadingTypography } from 'lib/LoadingTypoghraphy';
import Info from '@mui/icons-material/InfoOutlined';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { formatDateTime } from 'lib/timeHelpers';
import { LinkIdCell } from 'lib/MuiGrid/LinkIdCell';

interface OrderPaymentTimelineItemProps {
    item: IPaymentTimelineItem;
    onRefund: (id: number) => void;
    loading?: boolean;
    isRefundEnabled?: boolean;
    isClerkNameLoading?: boolean;
    currencyCode?: string;
}

// template params are {{amount}} and {{user}}
const getTextTemplate = (item: IPaymentTimelineItem): string => {
    switch (item.type) {
        case EOrderPaymentType.PosCard: {
            return 'POS card payment of {{amount}}';
        }
        case EOrderPaymentType.Cash: {
            return 'POS cash payment of {{amount}}';
        }
        case EOrderPaymentType.Applepay: {
            if (item.amount < 0) {
                return '{{user}} apple pay payment of {{amount}} has been refunded';
            }
            return '{{user}} made an apple pay payment of {{amount}}';
        }
        case EOrderPaymentType.Googlepay: {
            if (item.amount < 0) {
                return '{{user}} google pay payment of {{amount}} has been refunded';
            }
            return '{{user}} made a google pay payment of {{amount}}';
        }
        case EOrderPaymentType.Card: {
            if (item.amount < 0) {
                return '{{user}} card payment of {{amount}} has been refunded';
            }
            return '{{user}} made a card payment of {{amount}}';
        }
        case EOrderPaymentType.Terminal: {
            if (item.amount < 0) {
                return 'Quickpad card payment of {{amount}} has been refunded';
            }
            return 'Quickpad made a card payment of {{amount}}';
        }
        case EOrderPaymentType.Giftcard: {
            if (item.amount < 0) {
                if (!item.user) {
                    return 'Quickpad giftcard payment of {{amount}} has been refunded';
                }
                return '{{user}} giftcard payment of {{amount}} has been refunded';
            }
            if (!item.user) {
                return 'Quickpad made a giftcard payment of {{amount}}';
            }
            return '{{user}} made a giftcard payment of {{amount}}';
        }
        default:
            return 'Other payment of {{amount}}';
    }
};

export const OrderPaymentTimelineItem: React.FC<OrderPaymentTimelineItemProps> = ({
    item,
    onRefund,
    loading,
    isRefundEnabled,
    isClerkNameLoading,
    currencyCode
}) => {
    const getCurrencyString = useCurrencyString(currencyCode);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const date = React.useMemo(() => formatDateTime(new Date(item.date)), [item.date]);
    const text = React.useMemo(
        () => getTextTemplate(item).replace('{{amount}}', getCurrencyString(Math.abs(item.amount))),
        [getCurrencyString, item]
    );
    const renderUser = React.useCallback(() => {
        if (isString(item.user?.userId) && isString(item.user?.name)) {
            return <Link to={CUSTOMERS_VIEW.replace(':customerId', item.user.userId)}>{item.user.name}</Link>;
        }
        return null;
    }, [item.user?.name, item.user?.userId]);
    const handleRefundClick = React.useCallback(() => {
        onRefund(item.id);
    }, [item.id, onRefund]);
    const hasRefundReason = React.useMemo(() => isString(item.refundReason), [item.refundReason]);
    const formattedReason = React.useMemo(
        () => (
            <Box>
                <Typography align="center" variant="body2">
                    {item.refundReason}
                </Typography>
                <Typography align="center" variant="caption">
                    {item.refundNote}
                </Typography>
            </Box>
        ),
        [item.refundNote, item.refundReason]
    );
    const renderPaymentReceipt = React.useCallback(() => {
        if (isString(item.receiptId)) {
            if (settings?.paymentProvider === 'STRIPE' && item.type !== EOrderPaymentType.Giftcard) {
                return (
                    <span>
                        Receipt ID:{' '}
                        <LinkIdCell
                            id={item.receiptId}
                            param="transactionId"
                            path={PEPPER_PAY_TRANSACTIONS_VIEW}
                            variant="caption"
                        />
                    </span>
                );
            }
            return `Receipt ID: ${item.receiptId}`;
        }
        return '';
    }, [item.receiptId, item.type, settings?.paymentProvider]);
    return (
        <React.Fragment>
            <Divider sx={{ my: 0.75 }} />
            <Row valign="center" align="space-between" flex={1}>
                <Row valign="center" flex={1}>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                        sx={{ display: 'flex', flexDirection: 'column', flex: 1, maxWidth: '100%' }}
                    >
                        <Typography sx={{ textDecoration: item.deleted ? 'line-through' : 'none' }}>
                            <BasicStringReplace param="user" renderFunction={renderUser}>
                                {text}
                            </BasicStringReplace>
                        </Typography>
                        <Typography
                            variant="caption"
                            sx={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            <b>{date}</b> {renderPaymentReceipt()}
                        </Typography>
                        {isClerkNameLoading && item.clerkId && <LoadingTypography width={300} loading />}
                        {isDefined(item.clerkName) && !isClerkNameLoading && (
                            <Typography
                                variant="caption"
                                sx={{
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                Payment taken by <b>{item.clerkName ?? item.clerkId}</b>
                            </Typography>
                        )}
                    </TimelineContent>
                    {hasRefundReason && (
                        <Tooltip title={formattedReason}>
                            <Info />
                        </Tooltip>
                    )}
                </Row>
                {item.refundable && isRefundEnabled && (
                    <LoadingButton
                        sx={{ width: '90px' }}
                        size="small"
                        onClick={handleRefundClick}
                        disabled={item.refunded || loading}
                        loading={loading}
                        variant="contained"
                        color="primary"
                    >
                        {item.refunded ? 'Refunded' : 'Refund'}
                    </LoadingButton>
                )}
            </Row>
        </React.Fragment>
    );
};
