import { useHistoryFilters } from 'lib/hooks/useHistoryFilters';
import React from 'react';
import {
    NotificationsFilter,
    NotificationsFilterChange
} from 'components/notification/filters/NotificationsFilters';
import { QueryParameter } from 'lib/HttpClient';

export function useNotificationsFilter(): [NotificationsFilter, NotificationsFilterChange, boolean, boolean] {
    const { search, onSearch } = useHistoryFilters();
    const [filterTouched, setFilterTouched] = React.useState(false);

    const [filter, setFilter] = React.useState<NotificationsFilter>(() => ({
        search: search.search
    }));
    const onFilterChange = React.useCallback<NotificationsFilterChange>((field, value) => {
        setFilterTouched(true);
        setFilter(currentFilter => ({ ...currentFilter, [field]: value }));
    }, []);
    React.useEffect(() => {
        const { ...rest } = filter;
        onSearch({ ...rest });
    }, [filter, onSearch]);

    const isEmpty = React.useMemo(() => !filter.search, [filter]);
    return [filter, onFilterChange, isEmpty, filterTouched];
}

export const getNotificationsFilterQuery = (filter: NotificationsFilter): QueryParameter[] => {
    const queryParameters = [];
    if (filter.search) {
        queryParameters.push({ key: 'search', value: filter.search });
    }
    return queryParameters;
};
