import { Box, Button, Paper, Tooltip, Typography } from '@mui/material';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React from 'react';
import { UserMetricDefinition } from '../model';
import { Row } from 'ui/Flex';
import { METRICS_HEIGHT } from '../model/loyalty-tier';
import { TieredLoyaltyMetricModal } from '../modals/TieredLoyaltyMetricModal';
import { useTieredLoyaltyMetricDeleteModal } from './hooks/useTieredLoyaltyMetricDeleteModal';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { useTieredLoyaltyMetrics } from './hooks/useTieredLoyaltyMetrics';
import { useTieredLoyaltyMetricEdit } from './hooks/useTieredLoyaltyMetricEdit';
import { getDaysFromMinutes } from 'lib/timeHelpers';
import { useGridSingleSelection } from 'lib/MuiGrid/useGridSingleSelection';

interface TieredLoyaltyAudiencesProps {
    metricDefinition: UserMetricDefinition;
    loading?: boolean;
    onDefinitionUpdate: (value: UserMetricDefinition) => void;
}

const getDaysString = (minutes: number) => {
    const days = getDaysFromMinutes(minutes);
    return `${days} days`;
};
const getTimerRangeString = (gt: Date, lt?: Date) => {
    if (!lt) {
        return `${new Date(gt).toLocaleDateString()}`;
    }
    return `${new Date(gt).toLocaleDateString()} - ${new Date(lt).toLocaleDateString()}`;
};

export const TieredLoyaltyMetrics: React.FC<TieredLoyaltyAudiencesProps> = ({
    metricDefinition,
    loading,
    onDefinitionUpdate
}) => {
    const metrics = React.useMemo(() => {
        if (!metricDefinition || loading) {
            return [];
        }
        const metricsList = [];
        for (const metricId in metricDefinition.metrics) {
            const metric = metricDefinition.metrics[metricId];
            metricsList.push({
                id: metricId,
                name: metric.name,
                type: metric.type,
                timeRange:
                    'windowStartOffsetMins' in metric.timeRange
                        ? getDaysString(metric.timeRange.windowStartOffsetMins)
                        : getTimerRangeString(metric.timeRange.gt, metric.timeRange.lt)
            });
        }
        return metricsList;
    }, [metricDefinition, loading]);
    const [selectedMetricItem, rowSelectionModel, handleSelectRow] = useGridSingleSelection(metrics);
    const columns = React.useMemo(
        () => [
            {
                headerName: 'Name',
                field: 'name',
                width: 280
            },
            {
                headerName: 'Time Range',
                field: 'timeRange',
                width: 220
            },
            {
                headerName: 'Type',
                field: 'type',
                width: 200
            }
        ],
        []
    );
    const isMetricSelected = React.useMemo(() => !!selectedMetricItem, [selectedMetricItem]);
    const { metricDeletable, metricEditable, selectedMetric } = useTieredLoyaltyMetrics(
        metricDefinition,
        selectedMetricItem?.id
    );
    const {
        metricsModalOpen,
        handleMetricEditClose,
        handleMetricEditOpen,
        handleMetricEditSubmit,
        loading: metricLoading
    } = useTieredLoyaltyMetricEdit(metricDefinition, onDefinitionUpdate);
    const existingMetricNames = React.useMemo(() => metrics.map(item => item.name) ?? [], [metrics]);
    const {
        metricsDeleteModalOpen,
        handleMetricDeleteClose,
        handleMetricDeleteOpen,
        handleMetricDeleteSubmit,
        loading: metricDeleteLoading,
        title,
        content
    } = useTieredLoyaltyMetricDeleteModal(
        metricDefinition,
        selectedMetricItem?.id,
        selectedMetric,
        onDefinitionUpdate
    );
    // maximum time range is 1 year
    return (
        <Paper sx={{ p: 1, pb: 2, height: METRICS_HEIGHT }}>
            <Row align="space-between" flex={1} valign="center">
                <Typography variant="h6" sx={{ ml: 1 }}>
                    Metrics
                </Typography>
                <Row gutter>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isMetricSelected || !metricDefinition}
                        size="small"
                        onClick={handleMetricEditOpen}
                    >
                        Add
                    </Button>
                    <Button
                        variant="contained"
                        disabled={!isMetricSelected}
                        size="small"
                        onClick={handleMetricEditOpen}
                    >
                        View
                    </Button>
                    {!metricDeletable && isMetricSelected && (
                        // eslint-disable-next-line max-len
                        <Tooltip title="This metric is being used in one of the tiers. To remove the selected metric, please first remove it from the tier.">
                            <Box>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    disabled
                                    size="small"
                                    sx={{ mr: 1 }}
                                    onClick={handleMetricDeleteOpen}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </Tooltip>
                    )}
                    {(!!metricDeletable || !isMetricSelected) && (
                        <Button
                            variant="outlined"
                            color="error"
                            disabled={!isMetricSelected}
                            size="small"
                            sx={{ mr: 1 }}
                            onClick={handleMetricDeleteOpen}
                        >
                            Delete
                        </Button>
                    )}
                </Row>
            </Row>
            <Box height="180px" mt={1}>
                <MuiGrid
                    loading={loading}
                    columns={columns}
                    rows={metrics}
                    disableMultipleRowSelection
                    disableRowSelectionOnClick={false}
                    onRowSelectionModelChange={handleSelectRow}
                    rowSelectionModel={rowSelectionModel}
                />
            </Box>
            <TieredLoyaltyMetricModal
                open={metricsModalOpen}
                onClose={handleMetricEditClose}
                onSubmit={handleMetricEditSubmit}
                loading={metricLoading}
                readOnly={!metricEditable}
                metric={selectedMetric}
                metricNames={existingMetricNames}
            />
            <DeleteDialog
                open={metricsDeleteModalOpen}
                onClose={handleMetricDeleteClose}
                onDelete={handleMetricDeleteSubmit}
                title={title}
                content={content}
                loading={metricDeleteLoading}
            />
        </Paper>
    );
};
