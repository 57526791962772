import { ActionCreator, Dispatch } from 'redux';
import { contentApi } from 'components/content/contentApi';
import { ContentLayout } from 'components/content/models/ContentLayout';
import { ContentModule, ContentModuleType, EHeightOption } from 'components/content/models/ContentModule';
import { ContentAnchorProperties, ContentTemplate } from 'components/content/models/ContentTemplate';
import { generateLayoutFromTemplate } from 'components/content/templates';
import {
    MESSAGE_MOBILE_CONTENT_CREATE_LAYOUT_ERROR,
    MESSAGE_MOBILE_CONTENT_CREATE_LAYOUT_SUCCESS,
    MESSAGE_MOBILE_CONTENT_DELETE_MODULE_ERROR,
    MESSAGE_MOBILE_CONTENT_DELETE_MODULE_SUCCESS
} from 'config/messages';
import { MOBILE_CONTENT_LAYOUT_CONFIG } from 'config/routes';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { ApplicationState } from 'store/store';
import { contentActionTypes } from './contentActionTypes';

export const NEW_MODULE_ID = 'NEW_MODULE_ID';

interface BaseContentAction {
    type: string;
    error?: string;
}

export interface CurrentLayoutAction extends BaseContentAction {
    layout: ContentLayout;
}

export interface CurrentModuleAction extends BaseContentAction {
    id?: string;
    module: ContentModule;
}

export interface CurrentLayoutPropertiesAction extends BaseContentAction {
    property: { [name in keyof ContentAnchorProperties]: any };
}

export interface LayoutTemplatesAction extends BaseContentAction {
    templates: ContentTemplate;
}

export interface CurrentModulePropertyAction extends BaseContentAction {
    id: string;
    property: { [key: string]: any };
}

export interface CurrentModuleDeleteAction extends BaseContentAction {
    id: string;
}

export type ContentAction =
    | CurrentLayoutAction
    | LayoutTemplatesAction
    | CurrentModuleAction
    | CurrentLayoutPropertiesAction
    | CurrentModulePropertyAction
    | CurrentModuleDeleteAction;

const startRequest = {
    type: contentActionTypes.START_REQUEST
};

const endRequest = {
    type: contentActionTypes.END_REQUEST
};

export const setCurrentLayoutSuccess = (layout: ContentLayout) => ({
    layout,
    type: contentActionTypes.SET_CURRENT_LAYOUT_SUCCESS
});

export const setCurrentLayoutError = (error: string) => ({
    error,
    type: contentActionTypes.SET_CURRENT_LAYOUT_ERROR
});

const clearCurrentModulesAction = {
    type: contentActionTypes.CLEAR_CURRENT_MODULES
};

const clearCurrentLayoutAction = {
    type: contentActionTypes.CLEAR_CURRENT_LAYOUT
};

const addCurrentModuleSuccess = (newModule: ContentModule) => ({
    module: newModule,
    type: contentActionTypes.ADD_CURRENT_MODULE_SUCCESS
});

export const updateCurrentModuleSuccess = (id: string, module: ContentModule) => ({
    id,
    module,
    type: contentActionTypes.UPDATE_CURRENT_MODULE_SUCCESS
});

export const deleteSelectedModule = (id: string) => ({
    id,
    type: contentActionTypes.DELETE_SELECTED_MODULE
});

export const createAppLayoutFromTemplate =
    (template: ContentTemplate, push: (path: string) => void) => (dispatch: Dispatch) => {
        dispatch(startRequest);
        dispatch(clearCurrentModulesAction);
        const promises = Promise.all(
            template.anchor.modules.map(item => {
                const tmpItem = { ...item };
                delete tmpItem.required;
                // tmp solution, this stuff will be removed
                if (tmpItem.type === ContentModuleType.PRIMARY_ACTION) {
                    delete tmpItem.display;
                }
                if (
                    tmpItem.type !== ContentModuleType.MARKETING &&
                    tmpItem.type !== ContentModuleType.PRIMARY_ACTION
                ) {
                    delete tmpItem.items;
                }
                return contentApi.modules.create({ body: tmpItem });
            })
        );
        const moduleIds: string[] = [];
        promises
            .then(result => {
                result.forEach(item => {
                    if (!item.ok) {
                        throw new Error(item.body.message);
                    }
                    dispatch(addCurrentModuleSuccess(item.body));
                    moduleIds.push(item.body.id);
                });
                return contentApi.layouts.create({ body: generateLayoutFromTemplate(template, moduleIds) });
            })
            .then(result => {
                if (!result.ok) {
                    throw new Error(result.body.message);
                }
                dispatch(setCurrentLayoutSuccess(result.body));
                dispatch(
                    enqueueSnackbar(MESSAGE_MOBILE_CONTENT_CREATE_LAYOUT_SUCCESS, { variant: 'success' })
                );
                push(`${MOBILE_CONTENT_LAYOUT_CONFIG.replace(/:layoutId/g, result.body.id)}`);
                dispatch(endRequest);
            })
            .catch(error => {
                dispatch(setCurrentLayoutError(error.message));
                dispatch(clearCurrentModulesAction);
                dispatch(enqueueSnackbar(MESSAGE_MOBILE_CONTENT_CREATE_LAYOUT_ERROR, { variant: 'error' }));
                dispatch(endRequest);
            });
    };

export const getLayoutFromId = (layoutId: string) => async (dispatch: Dispatch) => {
    const layoutResult = await contentApi.layouts.get(layoutId);
    if (!layoutResult.ok) {
        dispatch(setCurrentLayoutError(layoutResult.body.message));
        return;
    }
    const layout: ContentLayout = layoutResult.body;
    dispatch(setCurrentLayoutSuccess(layout));
    const result = Promise.all(layout.anchor.moduleIds.map(item => contentApi.modules.get(item)));
    await result.then(promises => {
        promises.forEach(item => {
            if (!item.ok) {
                dispatch(clearCurrentModulesAction);
                dispatch(setCurrentLayoutError(layoutResult.body.message));
            }
            dispatch(addCurrentModuleSuccess(item.body));
        });
    });
};

export const updateCurrentLayoutProperties =
    (fieldName: string, value: any) => (dispatch: ActionCreator<CurrentLayoutPropertiesAction>) => {
        dispatch({
            property: { [fieldName]: value },
            type: contentActionTypes.UPDATE_CURRENT_LAYOUT_PROPERTIES
        });
    };

export const updateCurrentModuleProperties =
    (fieldName: string, value: any, id: string) => (dispatch: ActionCreator<CurrentModulePropertyAction>) => {
        dispatch({
            id,
            property: { [fieldName]: value },
            type: contentActionTypes.UPDATE_CURRENT_MODULE_PROPERTIES_SUCCESS
        });
    };

export const updateSelectedModule = (id: string) => (dispatch: ActionCreator<CurrentModuleAction>) => {
    dispatch({ id, type: contentActionTypes.UPDATE_SELECTED_MODULE });
};

export const clearCurrentLayout = () => (dispatch: Dispatch) => {
    dispatch(clearCurrentLayoutAction);
};

export const clearCurrentModules = () => (dispatch: Dispatch) => {
    dispatch(clearCurrentModulesAction);
};

export const getContentState = () => (_: never, getState: () => ApplicationState) => getState().content;

export const deleteContentModule =
    (id: string, layout: ContentLayout) => async (dispatch: ActionCreator<CurrentModuleDeleteAction>) => {
        dispatch(startRequest);
        try {
            const moduleIds = layout.anchor.moduleIds?.filter(item => item !== id);
            const layoutBody = { anchor: { ...layout.anchor, moduleIds } };
            const layoutResult = await contentApi.layouts.update(layout.id, {
                body: layoutBody,
                skipResponseBody: true
            });
            if (!layoutResult.ok) {
                throw new Error(MESSAGE_MOBILE_CONTENT_DELETE_MODULE_ERROR);
            }
            const moduleResult = await contentApi.modules.delete(id, { skipResponseBody: true });
            if (!moduleResult.ok) {
                throw new Error(MESSAGE_MOBILE_CONTENT_DELETE_MODULE_ERROR);
            }
            dispatch(deleteSelectedModule(id));
            dispatch(enqueueSnackbar(MESSAGE_MOBILE_CONTENT_DELETE_MODULE_SUCCESS, { variant: 'success' }));
        } catch (e) {
            dispatch(enqueueSnackbar(e.message, { variant: 'error' }));
        }
        return dispatch(endRequest);
    };

export const addNewModule = (moduleType: ContentModuleType) => (dispatch: Dispatch) => {
    const module: Partial<ContentModule> = { id: NEW_MODULE_ID, type: moduleType };
    if (moduleType !== ContentModuleType.PRIMARY_ACTION) {
        module.display = { height: EHeightOption.SINGLE_HEIGHT_RECTANGLE };
    }
    dispatch({
        module,
        type: contentActionTypes.ADD_CURRENT_MODULE_SUCCESS
    });
    updateSelectedModule(NEW_MODULE_ID)(dispatch);
};
