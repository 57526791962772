import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { isDefined } from 'lib/typeguards';
import React from 'react';

export function useGridSingleSelection<T extends { id: string }>(
    items?: T[]
): [T | undefined, GridRowSelectionModel, (newSelectedRows: GridRowSelectionModel) => void] {
    const [rowSelectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const handleSelectRow = React.useCallback((newSelectedRows: GridRowSelectionModel) => {
        setSelectionModel(selectionModel =>
            selectionModel.at(-1) === newSelectedRows.at(-1) ? [] : newSelectedRows
        );
    }, []);
    const selectedItem = React.useMemo(
        () => items?.find(item => isDefined(rowSelectionModel?.[0]) && item.id === rowSelectionModel?.[0]),
        [items, rowSelectionModel]
    );
    return [selectedItem, rowSelectionModel, handleSelectRow];
}
