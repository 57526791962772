import { ScheduledTaskStatus, ScheduledTaskType } from 'components/scheduled-tasks/scheduledTasks.types';
import { Theme } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';

export function mapScheduledTaskStatusToStyles(
    status: ScheduledTaskStatus,
    theme: Theme
): { backgroundColor: string; color: string } {
    switch (status) {
        case ScheduledTaskStatus.ERROR:
            return { backgroundColor: red['500'], color: theme.palette.primary.contrastText };
        case ScheduledTaskStatus.SCHEDULED:
        case ScheduledTaskStatus.QUEUED:
            return { backgroundColor: orange['500'], color: theme.palette.primary.contrastText };
        case ScheduledTaskStatus.DRAFT:
        case ScheduledTaskStatus.PROCESSING:
            return { backgroundColor: theme.palette.grey[500], color: theme.palette.primary.contrastText };
        case ScheduledTaskStatus.PROCESSED:
        default:
            return { backgroundColor: green['A700'], color: theme.palette.primary.contrastText };
    }
}

export const ScheduledTaskTypeLabelMap: Record<ScheduledTaskType, string> = {
    [ScheduledTaskType.NOOP]: 'No Operation',
    [ScheduledTaskType.FORCE_ERROR]: 'Force Error',
    [ScheduledTaskType.CREATE_PUSH_NOTIFICATION]: 'Push Notification',
    [ScheduledTaskType.RESET_AWARD_POINTS]: 'Reset Points'
};

export function scheduledTaskTypeToString({ value }: { value: ScheduledTaskType | string }) {
    return ScheduledTaskTypeLabelMap[value as ScheduledTaskType] ?? 'Unknown';
}
