import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { isNutritionInfo, NutrientValue, NutritionInfo } from '@pepperhq/menu-sdk';
import { isArrayOf } from 'lib/typeguards';
import React from 'react';
import { MuiModal } from 'ui/MuiModal';
import { NutritionForm } from './NutritionForm';

const NutritionValue: React.FC<GridRenderCellParams> = ({ value, api, id, field }) => {
    const renderString = React.useMemo(() => {
        const getValuesString = (values: NutrientValue[]) =>
            values.map(item => `${item.nutrient} - ${item.value}`).join(', ');
        if (isArrayOf(isNutritionInfo, value)) {
            return value.map(item => `${item.title}: ${getValuesString(item.values)}`).join('; ');
        }
        return '';
    }, [value]);
    const handleModalClose = React.useCallback(() => {
        api.stopCellEditMode({ id, field });
    }, [api, field, id]);
    const handleSave = React.useCallback(
        (nutrition: NutritionInfo) => {
            api.setEditCellValue({ id, field, value: [nutrition] });
            api.stopCellEditMode({ id, field });
        },
        [api, field, id]
    );
    const handleClear = React.useCallback(() => {
        api.setEditCellValue({ id, field, value: null });
        api.stopCellEditMode({ id, field });
    }, [api, field, id]);
    return (
        <>
            <span>{renderString}</span>
            <MuiModal open={true} onClose={handleModalClose}>
                <NutritionForm
                    nutrition={Array.isArray(value) ? value[0] : undefined}
                    onClose={handleModalClose}
                    onSave={handleSave}
                    onClear={handleClear}
                />
            </MuiModal>
        </>
    );
};

export const NutritionEditCell = (props: GridRenderCellParams) => <NutritionValue {...props} />;
