import React from 'react';
import { Grid, Hidden } from '@mui/material';
import { FallbackSkeleton } from 'ui/skeleton/FallbackSkeleton';
import { SkeletonProps } from 'ui/skeleton/SkeletonComponent';
import { NotificationDetailsCard } from './cards/NotificationDetailsCard';
import { NotificationResultsCard } from './cards/NotificationResultsCard';
import { Notification } from './models/Notification';
import { NotificationReceiptsCard } from 'components/notification/cards/NotificationReceiptsCard';

interface NotificationsViewProps {
    notification: Notification<string>;
    loading: boolean;
}

const fallbackProps: Partial<SkeletonProps> = {
    height: '320px'
};

export const NotificationsView: React.FC<NotificationsViewProps> = ({ notification, loading }) => {
    const isLoading = React.useMemo(() => loading || !notification?._id, [loading, notification]);
    const isUnsent = React.useMemo(
        () => notification?.status === 'PENDING' || notification?.status === 'DRAFT',
        [notification?.status]
    );
    return (
        <React.Fragment>
            <Hidden lgDown>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps}>
                                    <NotificationDetailsCard notification={notification} />
                                </FallbackSkeleton>
                            </Grid>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps}>
                                    <NotificationResultsCard isLoading={isLoading} metrics={notification} />
                                </FallbackSkeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps} height="400px">
                                    <NotificationReceiptsCard
                                        notificationId={notification?._id}
                                        isLoading={isLoading}
                                        isUnsent={isUnsent}
                                    />
                                </FallbackSkeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>

            <Hidden mdDown lgUp>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps}>
                                    <NotificationDetailsCard notification={notification} />
                                </FallbackSkeleton>
                            </Grid>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps}>
                                    <NotificationResultsCard isLoading={isLoading} metrics={notification} />
                                </FallbackSkeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FallbackSkeleton loading={loading} {...fallbackProps} height="400px">
                                    <NotificationReceiptsCard
                                        notificationId={notification?._id}
                                        isLoading={isLoading}
                                        isUnsent={isUnsent}
                                    />
                                </FallbackSkeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FallbackSkeleton loading={loading} {...fallbackProps}>
                            <NotificationDetailsCard notification={notification} />
                        </FallbackSkeleton>
                    </Grid>
                    <Grid item xs={12}>
                        <FallbackSkeleton loading={loading} {...fallbackProps}>
                            <NotificationResultsCard isLoading={isLoading} metrics={notification} />
                        </FallbackSkeleton>
                    </Grid>
                    <Grid item xs={12}>
                        <FallbackSkeleton loading={loading} {...fallbackProps} height="400px">
                            <NotificationReceiptsCard
                                notificationId={notification?._id}
                                isLoading={isLoading}
                                isUnsent={isUnsent}
                            />
                        </FallbackSkeleton>
                    </Grid>
                </Grid>
            </Hidden>
        </React.Fragment>
    );
};
