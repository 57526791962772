import { Theme } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { Notification, NotificationDeeplink } from './models/Notification';
import {
    ContentModule,
    ContentModuleType,
    MarketingMessageItem,
    PrimaryActionItem
} from 'components/content/models/ContentModule';

export function mapNotificationStatusToStyles(
    status: Notification<string>['status'],
    theme: Theme
): { backgroundColor: string; color: string } {
    switch (status) {
        case 'DONE':
            return { backgroundColor: green['A700'], color: theme.palette.primary.contrastText };
        case 'DRAFT':
        case 'PENDING':
            return { backgroundColor: orange['500'], color: theme.palette.primary.contrastText };
        case 'ERROR':
            return { backgroundColor: red['500'], color: theme.palette.primary.contrastText };
    }
}

export function renderDeepLinkTitle(deepLink: NotificationDeeplink) {
    return (module: ContentModule) => {
        const item: PrimaryActionItem | MarketingMessageItem | null =
            'items' in module
                ? (module.items as any[]).find(item => item.id === deepLink.moduleItemId)
                : null;

        let title = 'Unknown Module';
        if (item) {
            const filterBy: string =
                module.type === ContentModuleType.MARKETING
                    ? (item as MarketingMessageItem).action
                    : (item as PrimaryActionItem).type;
            switch (filterBy) {
                case 'VOUCHER':
                    title = `Voucher${'voucher' in deepLink ? ` ${deepLink.voucher}` : ''}`;
                    break;
                case 'PREORDER':
                    title = 'Preorder';
                    break;
                case 'START_ORDER':
                    title = 'Start Order';
                    break;
                case 'TABLE_ORDER':
                    title = 'Table Order';
                    break;
                case 'PAY_AT_TABLE':
                    title = 'Pay at Table';
                    break;
                case 'TABS':
                    title = 'Tabs';
                    break;
                case 'BROWSER':
                    title = 'Browser';
                    break;
                case 'NATIVE':
                    title = 'Native Marketing Details';
                    break;
                default:
                    break;
            }
        }

        return `${title} (${deepLink.moduleId}, ${deepLink.moduleItemId})`;
    };
}

export const NOTIFICATION_IMAGE_WIDTH = 520;
export const NOTIFICATION_IMAGE_HEIGHT = 289;
export const NOTIFICATION_IMAGE_ASPECT = NOTIFICATION_IMAGE_WIDTH / NOTIFICATION_IMAGE_HEIGHT;

export function deepLinkValueFormatter(value?: NotificationDeeplink | string) {
    return !!value ? 'Yes' : 'No';
}
