import React from 'react';
import { Box, Divider, Grid, IconButton, List, Typography, styled } from '@mui/material';
import { blue, green, orange, red } from '@mui/material/colors';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import clsx from 'clsx';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { Column, Row } from 'ui/Flex';
import { ActionExportErrorMessage } from './ActionExportErrorMessage';
import { IActionOperation } from 'components/analytics/export/models';
import { OperationStatusChip } from 'components/overwrites/OperationStatusChip';
import { OperationStatusBar } from 'components/overwrites/drawer/OperationStatusBar';
import { formatDateTime } from 'lib/timeHelpers';

const PREFIX = 'ActionExportDrawerContent';

const classes = {
    container: `${PREFIX}-container`,
    smallContainer: `${PREFIX}-smallContainer`,
    noPadding: `${PREFIX}-noPadding`,
    icon: `${PREFIX}-icon`,
    resultRow: `${PREFIX}-resultRow`,
    resultSubtitle: `${PREFIX}-resultSubtitle`,
    success: `${PREFIX}-success`,
    skip: `${PREFIX}-error`,
    error: `${PREFIX}-error`,
    removedUnlinked: `${PREFIX}-removedUnlinked`,
    list: `${PREFIX}-list`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.container}`]: {
        padding: theme.spacing(2)
    },
    [`& .${classes.smallContainer}`]: {
        padding: theme.spacing(0, 2)
    },
    [`& .${classes.noPadding}`]: {
        padding: 0
    },
    [`& .${classes.icon}`]: {
        fontSize: '1.2em'
    },
    [`& .${classes.resultRow}`]: {
        borderBottom: `1px dotted ${theme.palette.divider}`,
        flex: 1,
        padding: theme.spacing(1, 1, 0, 1),
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    },
    [`& .${classes.resultSubtitle}`]: {
        paddingTop: 0,
        paddingBottom: theme.spacing(1)
    },
    [`& .${classes.success}`]: {
        color: green['500']
    },
    [`& .${classes.skip}`]: {
        color: orange['500']
    },
    [`& .${classes.error}`]: {
        color: red['500']
    },
    [`& .${classes.removedUnlinked}`]: {
        color: blue['500']
    },
    [`& .${classes.list}`]: {
        boxShadow:
            'inset 0px 2px 1px -1px rgba(0,0,0,0.2), inset 0px 1px 1px 0px rgba(0,0,0,0.14), inset 0px 1px 3px 0px rgba(0,0,0,0.12)',
        overflowY: 'auto',
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
        flex: 1
    }
}));

interface IActionExportDrawerContentProps {
    operation?: IActionOperation;
    onClose: () => void;
}

export const ActionExportDrawerContent = ({
    operation,
    onClose
}: IActionExportDrawerContentProps): JSX.Element => {
    const renderErrorListItem = React.useCallback(
        (item: string, index: number) => (
            <ActionExportErrorMessage key={`error-item-${index}`} message={item} />
        ),
        []
    );
    if (!operation) {
        return null;
    }
    let message = operation.result && operation.result.message;
    const isError = operation.status === OperationStatus.ERROR;
    if (isError) {
        message = operation?.error.message;
    }
    return (
        <StyledGrid container spacing={2}>
            <Grid item xs={12}>
                <Row flex={1} align="flex-start" valign="center" className={classes.noPadding}>
                    <IconButton onClick={onClose} size="medium">
                        <KeyboardArrowRightIcon className={classes.icon} />
                    </IconButton>
                </Row>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Row flex={1} align="flex-start" valign="center" className={classes.smallContainer}>
                    <Typography color="primary" variant="h6">
                        Export - {formatDateTime(operation.createdAt)}
                    </Typography>
                </Row>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Column flex={1} className={classes.smallContainer}>
                    <Typography variant="subtitle2" color="textSecondary">
                        State
                    </Typography>
                    <Box paddingBottom={1}>
                        <OperationStatusChip status={operation.status} />
                    </Box>
                    <Typography variant="subtitle2" color="textSecondary">
                        Message
                    </Typography>
                    <Typography>{message}</Typography>
                </Column>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Column>
                    <Typography className={clsx(classes.container, classes.resultSubtitle)}>
                        Result
                    </Typography>
                    <OperationStatusBar operation={operation} />
                    {operation.result ? (
                        <Column className={classes.smallContainer}>
                            <Row className={classes.resultRow}>
                                <Typography>Total</Typography>
                                <Typography>
                                    {operation.result?.skipped +
                                        operation.result?.errors +
                                        (operation.result?.imported || 0) +
                                        (operation.result?.exported || 0) +
                                        (operation.result?.removedUnlinked || 0)}{' '}
                                    / {operation.result?.total}
                                </Typography>
                            </Row>
                            <Row className={classes.resultRow}>
                                <Typography>Errors</Typography>
                                <Typography>
                                    <span className={classes.error}>{operation.result?.errors}</span> /{' '}
                                    {operation.result?.total}
                                </Typography>
                            </Row>
                            <Row className={classes.resultRow}>
                                <Typography>Skipped</Typography>
                                <Typography>
                                    <span className={classes.skip}>{operation.result?.skipped}</span> /{' '}
                                    {operation.result?.total}
                                </Typography>
                            </Row>

                            <Row className={classes.resultRow}>
                                <Typography>Exported</Typography>
                                <Typography>
                                    <span className={classes.success}>{operation.result?.exported}</span> /{' '}
                                    {operation.result?.total}
                                </Typography>
                            </Row>
                        </Column>
                    ) : (
                        <Typography align="center" variant="caption">
                            You will see detailed information about the current operation soon...
                        </Typography>
                    )}
                </Column>
            </Grid>
            {!!operation.result?.errorMessages?.length && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Column flex={1} className={classes.smallContainer}>
                            <Typography>Errors</Typography>
                            <Typography variant="caption">
                                Click on the item in the box below to copy the error message
                            </Typography>
                            <Column className={classes.list}>
                                <List>{operation.result?.errorMessages?.map(renderErrorListItem)}</List>
                            </Column>
                        </Column>
                    </Grid>
                </React.Fragment>
            )}
        </StyledGrid>
    );
};
