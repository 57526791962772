import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { isTagValue, MenuChangeSchema, TagGroup, TagGroupOptionalId, TagValue } from '@pepperhq/menu-sdk';
import { menuApi } from 'components/menu/MenuApi';
import { TagsGridColumn } from '../Columns';
import { EMuiGridStatus, MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React, { useContext } from 'react';
import { BaseMenuTab, EnrichedTag, getTagsData, getTagsToCopy, useMenuChange } from '../model/menu';
import merge from 'deepmerge';
import * as Yup from 'yup';
import { useLeavePageBlock } from 'lib/hooks/useLeavePageBlock';
import { useDispatch } from 'react-redux';
import logger from 'lib/logger';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_TAGS_UPDATE_ERROR, MESSAGE_TAGS_UPDATE_SUCCESS } from 'config/messages';
import { isArrayOf, isDefined, isEmptyString } from 'lib/typeguards';
import { useMenuGridSearch } from '../model/useMenuGridSearch';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { LocationsContext, useTabCopy } from '../useCopyTab';
import { ActionsHeader } from '../ActionsHeader';
import { useMenuSelection } from '../useMenuSelection';
import { useMenuDeleteControls } from '../model/useMenuDeleteControls';

const formatValue = (value: TagGroup[]) => {
    const getValuesString = (values: TagValue[]) =>
        values.map((item: TagValue) => `${item?.id} - ${item?.title}`).join(', ');
    if (isArrayOf(isTagValue, value)) {
        return getValuesString(value);
    }
    return '';
};

type TagsTabProps = BaseMenuTab;

const validationSchema = {
    title: Yup.string().required('Required'),
    sort: Yup.number().integer('Whole number only').required('Required'),
    values: Yup.array().nullable().required('Required')
};

export const TagsTab: React.FC<TagsTabProps> = ({
    menu,
    menuChangeId,
    loadFreshMenu,
    onUpdate,
    change: externalChange,
    search,
    onSearchChange
}) => {
    const locations = useContext(LocationsContext);
    const dispatch = useDispatch();
    const {
        change: [change, setChange],
        readyToSave,
        handleCancel,
        handleStatusChange,
        gridRef
    } = useMenuChange(externalChange);

    const {
        selectedIds,
        resetSelection,
        selectedCell,
        handleSelectChange,
        handleCellSelection,
        copyEnabled,
        handleCellBlur
    } = useMenuSelection(!!locations.length);

    const { toggleIsCopyModalOpen, setMenuChangeToCopy, activeOperationId, renderLocationPickerModal } =
        useTabCopy(resetSelection, selectedCell, ['selected']);

    const {
        editedOrDeleted,
        handleCancelEditing,
        handleDelete,
        deleted,
        deleteEnabled,
        resetDeleted,
        isCellEditable
    } = useMenuDeleteControls(selectedIds, readyToSave, handleCancel, resetSelection);

    const handleRefreshMenu = React.useCallback(() => {
        loadFreshMenu();
        gridRef.current.reset();
    }, [gridRef, loadFreshMenu]);

    const tags = React.useMemo(() => {
        if (!menu || !change) {
            return [];
        }
        return getTagsData(menu, change);
    }, [change, menu]);

    useLeavePageBlock(editedOrDeleted);
    const gridSearchOptions = React.useMemo(
        () => [{ column: 'id' }, { column: 'title' }, { column: 'values', formatValue }],
        []
    );
    const { filterModel, filterOperators, renderInput } = useMenuGridSearch(
        gridSearchOptions,
        search,
        onSearchChange,
        'tags',
        'Search by id, title or value'
    );

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'id',
                headerName: 'ID',
                width: 120
            },
            {
                field: 'title',
                headerName: 'Title',
                editable: true,
                width: 200
            },
            {
                field: 'sort',
                headerName: 'Sort',
                editable: true,
                type: 'number',
                width: 120,
                headerAlign: 'left'
            },
            {
                field: 'isFilter',
                headerName: 'Filter in Menu',
                type: 'boolean',
                width: 200,
                editable: true,
                headerAlign: 'left'
            },
            {
                field: 'values',
                headerName: 'Values',
                width: 300,
                editable: true,
                filterOperators: [filterOperators.values],
                filterable: false,
                ...TagsGridColumn()
            }
        ],
        [filterOperators.values]
    );
    const handleCreate = React.useCallback(() => {
        const { changes } = gridRef.current?.getState() ?? {};
        const tagChanges: Record<string, TagGroupOptionalId> = merge(
            change?.options?.tags || {},
            changes as Record<string, TagGroup>
        );
        let newId: string;
        let i = 1;
        while (!newId) {
            if (!tagChanges[`${100 + i}`]) {
                newId = `${100 + i}`;
            } else {
                i++;
            }
        }
        setChange({
            ...change,
            options: {
                ...(change.options || {}),
                tags: {
                    ...tagChanges,
                    [newId]: { id: newId, title: '', sort: 99999, values: null, isFilter: false }
                }
            }
        });
        gridRef.current?.createItem(newId, 'title', {
            title: '',
            sort: 99999,
            values: null,
            isFilter: false
        });
        handleStatusChange(EMuiGridStatus.CHANGED);
    }, [change, gridRef, handleStatusChange, setChange]);

    const handleSubmit = React.useCallback(async () => {
        try {
            if (!gridRef.current) {
                throw new Error(MESSAGE_TAGS_UPDATE_ERROR);
            }
            const errors = gridRef.current.validate();
            if (errors) {
                return;
            }

            const { changes } = gridRef.current.getState();
            const mappedTags = Object.entries(changes).reduce<Record<string, EnrichedTag>>(
                (acc, [id, { _overriden, ...item }]) => {
                    if (!change?.options || !change.options.tags || !change.options.tags[id]) {
                        acc[id] = { ...item } as EnrichedTag;
                    } else {
                        acc[id] = { ...(change.options.tags[id] as EnrichedTag), ...item };
                    }
                    if (isDefined(acc[id].values) && !acc[id].values) {
                        acc[id].values = null;
                    } else {
                        acc[id].values = acc[id].values.map(item => ({
                            ...item,
                            sort: Number.isInteger(item.sort) ? item.sort : undefined,
                            imageUrl: item.imageUrl ? item.imageUrl : undefined
                        }));
                    }
                    if (isEmptyString(acc[id].isFilter)) {
                        acc[id].isFilter = false;
                    }
                    return acc;
                },
                {}
            );
            if (deleted) {
                deleted.forEach(deletedTag => {
                    mappedTags[deletedTag] = null;
                });
            }
            const result = await menuApi.updateMenuChange(menuChangeId, {
                options: {
                    ...(externalChange?.options || {}),
                    tags: mappedTags
                },
                schema: MenuChangeSchema.v5
            });
            if (!result.ok) {
                throw new Error(result.body.message);
            }
            setChange(result.body);
            onUpdate(result.body);
            resetDeleted();
            dispatch(enqueueSnackbar(MESSAGE_TAGS_UPDATE_SUCCESS, { variant: 'success' }));
            gridRef.current.reset();
        } catch (e) {
            logger.error(e);
            dispatch(enqueueSnackbar(e.message, { variant: 'error' }));
        }
    }, [
        gridRef,
        deleted,
        menuChangeId,
        externalChange?.options,
        setChange,
        onUpdate,
        resetDeleted,
        dispatch,
        change.options
    ]);

    const handleCopyTo = React.useCallback(() => {
        toggleIsCopyModalOpen();
        const tagChanges = getTagsToCopy(change, selectedIds, selectedCell, row => row.id);
        setMenuChangeToCopy({
            options: {
                tags: tagChanges
            },
            schema: MenuChangeSchema.v5
        });
    }, [toggleIsCopyModalOpen, change, selectedIds, selectedCell, setMenuChangeToCopy]);

    return (
        <React.Fragment>
            <Box pb={1}>
                <ActionsHeader
                    onCreate={handleCreate}
                    menu={menu}
                    onCancelEditing={handleCancelEditing}
                    onSubmit={handleSubmit}
                    activeOperationId={activeOperationId}
                    onCopy={handleCopyTo}
                    onRefreshMenu={handleRefreshMenu}
                    isEditedOrDeleted={editedOrDeleted}
                    copyEnabled={copyEnabled}
                    deleteEnabled={deleteEnabled}
                    onDelete={handleDelete}
                />
                {renderInput()}
            </Box>
            <MuiGrid
                rows={tags}
                columns={columns}
                stateRef={gridRef}
                onStatusChange={handleStatusChange}
                validationScheme={validationSchema}
                filterModel={filterModel}
                deleted={deleted}
                disableColumnFilter
                storageName={GridStorageName.Tags}
                isCellEditable={isCellEditable}
                checkboxSelection
                checkboxVisibility
                onRowSelectionModelChange={handleSelectChange}
                rowSelectionModel={selectedIds}
                onCellClick={handleCellSelection}
                handleCellBlur={handleCellBlur}
            />
            {renderLocationPickerModal()}
        </React.Fragment>
    );
};
