import { Add, InfoOutlined } from '@mui/icons-material';
import { PerksCreationModal } from 'components/perks/modals/PerksCreationModal';
import { PerksEditModal } from 'components/perks/modals/PerksEditModal';
import { PerksGrid } from 'components/perks/PerksGrid';
import { EmptyState } from 'components/utils/emptyState';
import React from 'react';
import { useSelector } from 'react-redux';
import EmptyImage from 'static/perks-empty-state.svg';
import { ApplicationState } from 'store/store';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { Box, Button, TextField, Tooltip, styled } from '@mui/material';
import { usePepperPerksData } from 'components/perks/hooks/pepper-perks/usePepperPerksData';
import { usePepperPerksMutation } from 'components/perks/hooks/pepper-perks/usePepperPerksMutation';
import { usePepperPerksModals } from 'components/perks/hooks/pepper-perks/usePepperPerksModals';
import { usePepperPerksUtils } from 'components/perks/hooks/pepper-perks/usePepperPerksUtils';
import { Permission, Resource } from '@pepperhq/auth-client';
import { PerksSearchInfoTooltip } from 'components/perks/PerksSearchInfoTooltip';
import { MuiTheme } from 'config/theme';
import { GridStorageName } from 'lib/MuiGrid/StateController';
const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
    alignItems: 'flex-end'
}));

export const PepperPerksView = () => {
    const {
        createModalOpen,
        closeCreateModalOpen,
        stepClub,
        resetModalLoading,
        deleteModalLoading,
        indexOpen,
        indexToDuplicate,
        closeUpdateModalOpen,
        perkAwardCount
    } = useSelector((state: ApplicationState) => state.perks);

    const { perks, loading, searchValue, indexToReset, indexToDelete, handleSearchValueChange } =
        usePepperPerksData();
    const { handleCreateSuccess, handleUpdateSuccess, resetPerk, deletePerk } = usePepperPerksMutation();
    const {
        selectedRow,
        selectedPerk,
        handleSelectRow,
        handleReviewAndEditClick,
        handleResetClick,
        handleDeleteClick,
        handleDuplicateClick
    } = usePepperPerksUtils();
    const {
        toggleCreateModal,
        toggleCloseCreateModal,
        toggleUpdateCreateModal,
        closeModals,
        closeResetPopUp,
        closeDeletePopUp
    } = usePepperPerksModals();

    const { claims } = useSelector((state: ApplicationState) => state.auth);
    const currencySymbol = useSelector(
        (state: ApplicationState) => state.settings.settings.region?.currencySymbol
    );
    const primaryActionBackgroundColour = useSelector(
        (state: ApplicationState) =>
            state.content.currentLayout?.anchor.properties.primaryActionBackgroundColour
    );

    const columnsToExclude = React.useMemo(() => ['loyaltyRewardId'], []);

    const isResetButtonVisible = React.useMemo(
        () => claims.hasPermission(Resource.PerkReset, Permission.all),
        [claims]
    );

    const resetDialogPerkTitle = React.useMemo(() => {
        if (indexToReset >= 0 && perks.length > 0) {
            return `Remove all awards? (${perks[indexToReset]?.title})`;
        }
        return 'Remove all awards?';
    }, [indexToReset, perks]);

    const deleteDialogPerkTitle = React.useMemo(() => {
        if (indexToDelete >= 0 && perks.length > 0) {
            return `Delete "${perks[indexToDelete]?.title}"?`;
        }
        return 'Delete perk?';
    }, [indexToDelete, perks]);

    const closeDialogDescription = React.useMemo(
        () =>
            stepClub
                ? // eslint-disable-next-line max-len
                  'Are you sure you want to cancel this club perk configuration process? All changes made to triggers and audiences associated with this club perk will be lost'
                : 'Are you sure you want to cancel this perk creation process? All changes you made will be lost.',
        [stepClub]
    );

    const renderSearchInfoButton = React.useCallback(
        () => (
            <Tooltip title={<PerksSearchInfoTooltip excludedColumns={columnsToExclude} />}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: MuiTheme.palette.grey[600],
                        cursor: 'default'
                    }}
                >
                    <InfoOutlined />
                </Box>
            </Tooltip>
        ),
        [columnsToExclude]
    );

    return (
        <>
            {!loading && perks.length === 0 && (
                <EmptyState
                    headerText="You haven't created any perks yet"
                    paragraphText="Perks such as stamp cards can be awarded to users to increase app usage & encourage repeat purchases."
                    buttonText="Create a Perk"
                    imageUrl={EmptyImage}
                    onClick={toggleCreateModal}
                />
            )}
            {(loading || perks.length > 0) && (
                <>
                    <StyledBox>
                        <TextField
                            value={searchValue}
                            label={'Search by field'}
                            InputProps={{ endAdornment: renderSearchInfoButton() }}
                            onChange={handleSearchValueChange}
                            sx={{ width: 350 }}
                        />
                        <Button
                            variant="contained"
                            startIcon={<Add />}
                            sx={{ marginLeft: 'auto' }}
                            onClick={toggleCreateModal}
                            size="small"
                        >
                            Create
                        </Button>
                        <Button
                            variant="outlined"
                            disabled={!selectedPerk?._id}
                            onClick={handleReviewAndEditClick}
                            size="small"
                        >
                            Review & Edit
                        </Button>
                        {isResetButtonVisible && (
                            <Button
                                variant="outlined"
                                disabled={!selectedPerk?._id || selectedPerk?.enabled !== false}
                                onClick={handleResetClick}
                                size="small"
                            >
                                Remove All Awards
                            </Button>
                        )}
                        {selectedPerk?._id && (
                            <Button
                                variant="outlined"
                                disabled={!selectedPerk?._id}
                                onClick={handleDuplicateClick}
                                size="small"
                            >
                                Duplicate
                            </Button>
                        )}
                        <Button
                            variant="outlined"
                            disabled={!selectedPerk?._id}
                            onClick={handleDeleteClick}
                            size="small"
                        >
                            Delete
                        </Button>
                    </StyledBox>
                    <PerksGrid
                        data={perks}
                        isLoading={loading}
                        searchValue={searchValue}
                        columnsToExclude={columnsToExclude}
                        selectedRow={selectedRow}
                        handleSelectRow={handleSelectRow}
                        storageName={GridStorageName.Perks}
                    />
                </>
            )}

            <PerksCreationModal
                open={createModalOpen || indexToDuplicate >= 0}
                onClose={toggleCloseCreateModal}
                onSubmit={handleCreateSuccess}
                currencySymbol={currencySymbol}
                primaryActionBackgroundColour={primaryActionBackgroundColour}
                duplicatePerk={perks[indexToDuplicate]}
            />
            <PerksEditModal
                open={indexOpen >= 0}
                onClose={toggleUpdateCreateModal}
                onSubmit={handleUpdateSuccess}
                perk={perks[indexOpen]}
                currencySymbol={currencySymbol}
                primaryActionBackgroundColour={primaryActionBackgroundColour}
            />
            <DeleteDialog
                submitLabel="Yes, Cancel"
                discardLabel="No, don't cancel"
                title="Hold On!"
                content={closeDialogDescription}
                open={closeCreateModalOpen}
                onDelete={closeModals}
                onClose={toggleCloseCreateModal}
            />
            <DeleteDialog
                submitLabel="Yes, Cancel"
                discardLabel="No, don't cancel"
                title="Hold On!"
                content={closeDialogDescription}
                open={closeUpdateModalOpen}
                onDelete={closeModals}
                onClose={toggleUpdateCreateModal}
            />
            <DeleteDialog
                title={deleteDialogPerkTitle}
                content="If you delete the perk, you won't be able to recover it and the awards associated with it. To confirm deletion,
                type 'DELETE' in the text input field."
                open={indexToDelete >= 0}
                onDelete={deletePerk}
                onClose={closeDeletePopUp}
                loading={deleteModalLoading}
                protectionWord="DELETE"
            />
            <DeleteDialog
                title={resetDialogPerkTitle}
                content={
                    <>
                        You will be removing{' '}
                        <strong>{perkAwardCount === -1 ? 'loading...' : perkAwardCount}</strong> awards. There
                        is no way to reverse this action.
                        <br />
                        To confirm removal of{' '}
                        <strong>{perkAwardCount === -1 ? 'loading...' : perkAwardCount}</strong> awards, type{' '}
                        <strong>REMOVE</strong> in the text input field.
                    </>
                }
                submitLabel="Remove"
                open={indexToReset > -1}
                onDelete={resetPerk}
                onClose={closeResetPopUp}
                loading={resetModalLoading}
                protectionWord="REMOVE"
            />
        </>
    );
};
