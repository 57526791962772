import {
    Badge,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    styled,
    Typography
} from '@mui/material';
import React from 'react';
import { IMenuOverrideEdit, MenuOverridesFilter } from '../models/Overrides';
import FilterListIcon from '@mui/icons-material/FilterList';
import { OrderingScenariosFilter } from './filters/OrderingScenarioFilter';
import { OrderingZonesFilter } from './filters/OrderingZonesFilter';
import { AvailabilityDateFilter } from './filters/AvailabilityDateFilter';
import { scenarioLabel } from 'components/menu/model/menu';
import { OrderScenario } from '@pepperhq/menu-sdk';
import { AvailabilityTimeFilter } from './filters/AvailabilityTimeFilter';
import { useLocalDateTimeFormat } from 'lib/hooks/useLocalDateTimeFormat';
import { isValid } from 'date-fns';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(1.5),
        minWidth: theme.spacing(400 / 8)
    },
    '& .MuiDialogActions-root ': {
        padding: theme.spacing(1, 3, 2)
    }
}));

const StyledDialogHeader = styled('div')(({ theme }) => ({
    padding: theme.spacing(2, 3, 1, 3)
}));
interface IProps {
    filter: MenuOverridesFilter;
    onFilterChange: (newFilter: MenuOverridesFilter) => void;
    zones: IMenuOverrideEdit['zones'];
}

export const ProductAvailabilityFilter: React.FC<IProps> = ({ filter, zones, onFilterChange }) => {
    const handleSubmit = React.useCallback(
        (filter: MenuOverridesFilter) => {
            onFilterChange(filter);
            setIsFilterDialogOpen(false);
        },
        [onFilterChange]
    );
    const formik = useFormik<MenuOverridesFilter>({
        initialValues: filter,
        onSubmit: handleSubmit,
        validationSchema: yup.object().shape({
            scenario: yup
                .mixed()
                .oneOf([
                    OrderScenario.ORDER_TO_TABLE,
                    OrderScenario.PREORDER,
                    OrderScenario.TAB,
                    OrderScenario.TABLE
                ])
                .notRequired()
                .nullable(),
            zone: yup.string().notRequired().nullable(),
            date: yup
                .string()
                .test('date-validation', 'Please, enter valid date.', value => {
                    if (!!value && !isValid(new Date(value))) {
                        return false;
                    }

                    return true;
                })
                .notRequired()
                .nullable(),
            time: yup
                .string()
                .test('time-validation', 'Please, enter valid time.', value => {
                    if (!!value && !isValid(new Date(value))) {
                        return false;
                    }

                    return true;
                })
                .notRequired()
                .nullable()
        })
    });
    const { format } = useLocalDateTimeFormat();
    const [isFilterDialogOpen, setIsFilterDialogOpen] = React.useState(false);
    const handleToggle = React.useCallback(() => {
        setIsFilterDialogOpen(val => !val);
    }, []);

    const handleClose = React.useCallback(() => {
        setIsFilterDialogOpen(false);
    }, []);
    const handleDeleteFilter = React.useCallback(() => {
        const newFilter = { ...filter };
        newFilter.date = undefined;
        newFilter.time = undefined;
        onFilterChange(newFilter);
    }, [filter, onFilterChange]);
    const chipValueFormatter: Record<keyof MenuOverridesFilter, (value: any) => string> = React.useMemo(
        () => ({
            date: value => value && isValid(new Date(value)) && format(new Date(value), 'DATE'),
            zone: value => (value === 'ALL_ZONES' ? '' : value),
            scenario: value => scenarioLabel[value as OrderScenario],
            time: value => value && isValid(new Date(value)) && format(new Date(value), 'TIME')
        }),
        [format]
    );
    const counter = React.useMemo(() => {
        let counter = 0;
        if (filter.scenario) {
            counter++;
        }
        if (filter.zone) {
            counter++;
        }
        return counter;
    }, [filter]);

    const handleFieldChange = React.useCallback(
        (field: string) => (value: string) => {
            formik.setFieldValue(field, value);
        },
        [formik]
    );

    return (
        <Box display="flex" alignItems="center" justifyContent="flex-end" flex="1" marginRight={1}>
            <Box display="flex" columnGap={0.25}>
                {!!filter.date && !!filter.time && (
                    <Chip
                        label={`${chipValueFormatter.date(filter.date)} ${chipValueFormatter.time(
                            filter.time
                        )}`}
                        variant="filled"
                        color="primary"
                        onDelete={handleDeleteFilter}
                    />
                )}
                {!!filter.date && !filter.time && (
                    <Chip
                        label={chipValueFormatter.date(filter.date)}
                        variant="filled"
                        color="primary"
                        onDelete={handleDeleteFilter}
                    />
                )}
                {!filter.date && !!filter.time && (
                    <Chip
                        label={chipValueFormatter.date(filter.time)}
                        variant="filled"
                        color="primary"
                        onDelete={handleDeleteFilter}
                    />
                )}
            </Box>
            <Badge badgeContent={counter} color="primary">
                <IconButton onClick={handleToggle}>
                    <FilterListIcon />
                </IconButton>
            </Badge>
            <StyledDialog open={isFilterDialogOpen} onClose={handleToggle}>
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                        <StyledDialogHeader>
                            <Typography variant="h6">Apply Menu Options</Typography>
                            <Typography variant="caption">
                                View the visibility status for a specific date, time, zone & scenario
                            </Typography>
                        </StyledDialogHeader>
                        <DialogContent>
                            <OrderingScenariosFilter
                                onChange={handleFieldChange('scenario')}
                                value={formik.getFieldMeta('scenario').value}
                            />
                            <OrderingZonesFilter
                                onChange={handleFieldChange('zone')}
                                value={formik.getFieldMeta('zone').value}
                                zones={zones}
                            />
                            <AvailabilityDateFilter
                                onChange={handleFieldChange('date')}
                                value={formik.getFieldMeta('date').value}
                                error={formik.getFieldMeta('date').error}
                            />
                            <AvailabilityTimeFilter
                                onChange={handleFieldChange('time')}
                                value={formik.getFieldMeta('time').value}
                                error={formik.getFieldMeta('time').error}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button color="primary" variant="contained" type="submit">
                                Submit
                            </Button>
                        </DialogActions>
                    </Form>
                </FormikProvider>
            </StyledDialog>
        </Box>
    );
};
